import React from 'react'
import { observer } from 'mobx-react'
import WelcomeMobileStore from './welcome-mobile-store'
import styles from './style.css'
import { ButtonDefault } from '~/components'
import res from './res'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import imgBase from '~/assets/img/welcome-page-mobile/base.png'
import imgAndMore from '~/assets/img/welcome-page-mobile/and-more.png'
import imgSlide1 from '~/assets/img/welcome-page-mobile/screen-1.png'
import imgSlide2 from '~/assets/img/welcome-page-mobile/screen-2.png'
import imgSlide3 from '~/assets/img/welcome-page-mobile/screen-3.png'
import imgSlide4 from '~/assets/img/welcome-page-mobile/screen-4.png'
import imgSlide5 from '~/assets/img/welcome-page-mobile/screen-5.png'
import AppStore from '~/app-store'
import InteractiveHint from '~/utils/interactive-hint'
const slideImgPaths = [
    imgSlide1,
    imgSlide2,
    imgSlide3,
    imgSlide4,
    imgSlide5,
    null
]

@observer
export default class WelcomeMobile
    extends React.Component<{ store: WelcomeMobileStore, appStore: typeof AppStore }, any> {

    public render() {
        const { store, appStore } = this.props

        return (
            <div className={styles.page}>
                {!store.isSkipModalVisible &&
                    <div className={styles.content}>
                        <ButtonDefault
                            type="link"
                            size="small"
                            className={styles.skipBtn}
                            onClick={() => store.showSkipModal()}>
                            {res().skipButton}
                        </ButtonDefault>
                        <div className={styles.body}>
                            <div className={styles.bodyImg}>
                                {store.LAST_INDEX !== store.currentSlideIndex &&
                                    <div className={styles.baseImgContainer}>
                                        <img className={styles.baseImg} src={imgBase} />
                                        <img className={styles.slideImg} src={slideImgPaths[store.currentSlideIndex]} />
                                    </div>
                                }
                                {store.isLastSlide &&
                                    <img src={imgAndMore} />
                                }
                            </div>
                            <div>
                                <h2>{res().slides[store.currentSlideIndex].title}</h2>
                                <p>{res().slides[store.currentSlideIndex].description}</p>
                                <ButtonDefault type="primary"
                                    className={`${store.isLastSlide ? '' : 'hidden'}`}
                                    onClick={() => appStore.redirectAfterLogin()}>
                                    {res().startButton}
                                </ButtonDefault>
                            </div>
                        </div>


                        <div className={styles.controlFooter}>
                            <ButtonDefault
                                // className={styles.prevButton}
                                type="default"
                                onClick={() => store.goPrevSlide()}
                                size="default"
                                disabled={store.isFirstSlide}
                            >
                                <ArrowLeftOutlined />
                            </ButtonDefault>
                            <div className={styles.bullets}>
                                {
                                    slideImgPaths.map((slide, index) =>
                                        <div className={`${styles.bullet} ${store.currentSlideIndex === index ? 'active' : ''}`} key={index} />
                                    )
                                }
                            </div>
                            <ButtonDefault
                                // className={styles.nextButton}
                                type="default"
                                onClick={() => store.goNextSlide()}
                                size="default"
                                disabled={store.isLastSlide}
                            >
                                <ArrowRightOutlined />
                            </ButtonDefault>
                        </div>
                    </div>
                }

                {store.isSkipModalVisible &&
                    <div className={styles.modal}>
                        <p className={styles.title}>{res().modal.title}</p>
                        <p className={styles.description}>{res().modal.description}</p>
                        <div className={styles.buttons}>
                            <ButtonDefault
                                type="default"
                                onClick={() => store.hideSkipModal()}
                            >
                                {res().modal.cancelButton}
                            </ButtonDefault>
                            <ButtonDefault
                                type="default"
                                onClick={() => {
                                    InteractiveHint.cancelAllFlags()
                                    appStore.redirectAfterLogin()
                                    store.hideSkipModal()
                                }}
                            >
                                {res().modal.okButton}
                            </ButtonDefault>
                        </div>
                    </div>
                }
            </div>
        )
    }
}