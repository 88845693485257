import React from 'react'

import { action, observable, ObservableMap, remove, set, toJS } from 'mobx'

import { observer } from 'mobx-react'

import moment from 'moment'

import { AFTPayment, Installment, AFTStatus } from '~/api'

import {
    AutoComplete,
    Button,
    DatePicker,
    Form,
    IconButton,
    Input,
    Modal,
    Radio,
    Select
} from '~/components'
const Option = Select.Option

import { FilterValue, FilterValues } from '~/components/data-filter'
import { CloseModalIcon } from '~/components/icon'
import { RangePopoverValue } from '~/components/range-popover'

import layoutStyles from '~/layouts/main/styles.css'

import globalRes from '~/res'

import constants from '../../constants'

import currencies from '~/constants/currencies'

import fieldsRes from '../../res'

import res from './res'

import { map } from 'lodash'
import { createFormItem } from '~/components/form-item-factory'
import ModalContainer from '~/components/modal/modal-container'
import styles from './styles.css'

import PeriodSelector from '~/components/date-selector/period-selector'
import MobileHeader from '~/components/modal/mobile-header'
import { ModalContainerContext } from '~/components/modal/modal-context'
import StatusSelect from '~/pages/payment-aft/components/status-select'
import BrowserStore from '~/services/browser/browser-state'
import { EPaymentFiltersInterface } from '../../aft-payments-store'
import { StatusesOptions } from '../base-filters/index'

interface FullFiltersFormProps {
    values: FilterValues<AFTPayment>
    dateRangeValue: RangePopoverValue
    dateRangePresets: RangePopoverValue[]
    onRangeChange?: (value: RangePopoverValue) => void
    installmentsList: Installment[]
    installments: string[]
    onFiltersChange?: (
        filterParams: EPaymentFiltersInterface,
        overwrite?: boolean
    ) => void
    setStatusFilter?: (value: AFTStatus) => void
    shopsList: string[]
    code?: AFTStatus
    onStatusChange?: (value: AFTStatus) => void
    visible: boolean
    onClose: (applyResult?: boolean) => any
    renderAsForm?: boolean
    onInit?: (applyFunc: () => void) => void
    modalContext?: ModalContainerContext
}

type PostLinkStatusOption = 'all' | 'ok' | 'fail'

const postLinkStatusOptions: PostLinkStatusOption[] = ['all', 'ok', 'fail']

const FormItem = createFormItem({
    labelCol: {
        xs: { span: 28 },
        sm: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 28 },
        sm: { span: 16 }
    }
})

@observer
export default class FullFiltersForm extends React.Component<
FullFiltersFormProps,
{}
> {
    constructor(props: FullFiltersFormProps) {
        super(props)

        this.state = {}

        this.initValues()
    }

    @action
    public componentDidMount() {
        this.prepareValues(this.props)
        if (this.props.onInit) {
            this.props.onInit(this.applyFilters)
        }
        if (this.props.modalContext) {
            this.props.modalContext.modalController.setTitle(
                <MobileHeader
                    onOk={() => this.applyFilters()}
                    title={res().title}
                    rightControl={
                        <a onClick={() => this.clearFilters()}>
                            ({res().clearButton})
                        </a>
                    }
                    onCancel={this.props.onClose}
                />
            )
        }
    }

    public componentWillUnmount() {
        if (this.props.onInit) {
            this.props.onInit(undefined)
        }
    }

    @action
    public componentWillReceiveProps(nextProps: FullFiltersFormProps) {
        this.prepareValues(nextProps)
    }

    @action
    public prepareValues(props: FullFiltersFormProps) {
        this.values = observable.map(toJS(props.values))
        this.dateRange = props.dateRangeValue
        this.installments = props.installments

        const postLinkStatusValue =
            props.values.postLinkStatus && props.values.postLinkStatus.equals

        if (typeof postLinkStatusValue !== 'undefined') {
            this.postLinkStatusOptionKey = postLinkStatusValue ? 'ok' : 'fail'
        } else {
            this.postLinkStatusOptionKey = 'all'
        }
    }

    public render() {
        const { values, visible, onClose, renderAsForm } = this.props

        if (!values) {
            return <span></span>
        }
        if (renderAsForm) {
            return this.renderForm()
        }

        return (
            <React.Fragment>
                {!BrowserStore.isMobile && (
                    <div data-semantic-id="modal">
                        <div>
                            <div className={layoutStyles.contentHeader}>
                                <div className={layoutStyles.title}>
                                    {res().title}
                                </div>
                                <IconButton
                                    className={`${layoutStyles.closeButton}`}
                                    icon={CloseModalIcon}
                                    dataSemanticId="close-form"
                                    tooltipTitle={globalRes().close}
                                    onClick={onClose}
                                />
                            </div>
                            {/* <IconButton
                            className={`${layoutStyles.closeButton}`}
                            icon={CloseModalIcon}
                            onClick={onClose}
                        /> */}
                        </div>
                        <div className={layoutStyles.contentMain}>
                            {this.renderForm()}
                        </div>
                        <div className={layoutStyles.contentFooter}>
                            <Button
                                type="primary"
                                size="large"
                                onClick={() => this.applyFilters()}
                            >
                                {res().applyButton}
                            </Button>
                            <Button size="large" onClick={() => onClose()}>
                                {res().cancelButton}
                            </Button>
                        </div>
                    </div>
                )}
                {BrowserStore.isMobile && (
                    <React.Fragment>
                        <div className="fullScroll">{this.renderForm()}</div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }

    private renderForm() {
        const { values, props } = this
        const { installments } = this
        const labels = fieldsRes().dataFields.short
        const placehoders = fieldsRes().dataFields.full

        /*
        if (values.has('installment_id')) {
            const installmentsValues = this.values.get('installment_id').in
                ? this.values.get('installment_id').in
                : []

            installments = map(
                this.props.installmentsList.filter(item =>
                    installmentsValues.includes(item.name)
                ),
                item => {
                    return item.id
                }
            )
        }
        */

        const rangeClassName = BrowserStore.isMobile
            ? styles.mobileFormItem
            : ''
        const selectedInstallments =
            installments.length !== 0 ? Array.from(installments) : []
        return (
            <div>
                <Form
                    className={`${styles.fields} ${BrowserStore.isMobile ? styles.mobile : ''
                        } `}
                    onSubmit={this.handleFormSubmit}
                >
                    <div className={styles.section}>
                        {BrowserStore.isMobile && (
                            <React.Fragment>
                                <FormItem label={labels.code}>
                                    <StatusSelect
                                        id="status"
                                        options={StatusesOptions}
                                        onChange={this.setStatusFilter}
                                        value={
                                            this.statusFilter ||
                                            AFTStatus.any
                                        }
                                    />
                                </FormItem>
                                <FormItem label={res().periodTitle}>
                                    <div>
                                        <PeriodSelector
                                            selectedValue={this.dateRange}
                                            values={this.props.dateRangePresets}
                                            onClose={this.setRangeFilter}
                                            renderLabel={true}
                                        ></PeriodSelector>
                                        {this.dateRange.key ===
                                            'customPeriod' &&
                                            this.dateRange.range[0] &&
                                            this.dateRange.range[1] && (
                                                <div>
                                                    (
                                                    {moment(
                                                        this.dateRange.range[0]
                                                    ).format('L')}{' '}
                                                    -{' '}
                                                    {moment(
                                                        this.dateRange.range[1]
                                                    ).format('L')}
                                                    )
                                                </div>
                                            )}
                                    </div>
                                </FormItem>
                            </React.Fragment>
                        )}
                        {/* <FormItem label={labels.shop}>
                            <AutoComplete
                                value={
                                    values.has('shop')
                                        ? values.get('shop').equals
                                        : ''
                                }
                                onSearch={(value: string) =>
                                    this.handleFieldValueChange(
                                        'shop',
                                        'equals',
                                        value
                                    )
                                }
                                onSelect={(value: string) =>
                                    this.handleFieldValueChange(
                                        'shop',
                                        'equals',
                                        value
                                    )
                                }
                                placeholder={placehoders.shop}
                                showSearch
                                dataSource={props.shopsList}
                            />
                        </FormItem> */}

                        <FormItem label={labels.invoiceID}>
                            <Input
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'invoiceID',
                                        'endsWith',
                                        e.target.value
                                    )
                                }
                                placeholder={placehoders.invoiceID}
                                value={
                                    values.has('invoiceID')
                                        ? values.get('invoiceID').endsWith
                                        : ''
                                }
                            />
                        </FormItem>

                        {/* <FormItem label={labels.accountID}>
                            <Input
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'accountID',
                                        'equals',
                                        e.target.value
                                    )
                                }
                                placeholder={placehoders.invoiceID}
                                value={
                                    values.has('accountID')
                                        ? values.get('accountID').equals
                                        : ''
                                }
                            />
                        </FormItem> */}

                        {/* <FormItem label={labels.phone}>
                            <Input
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'phone',
                                        'endsWith',
                                        e.target.value
                                    )
                                }
                                placeholder={placehoders.phone}
                                value={
                                    values.has('phone')
                                        ? values.get('phone').endsWith
                                        : ''
                                }
                            />
                        </FormItem> */}

                        <FormItem label={labels.email}>
                            <Input
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'email',
                                        'startsWith',
                                        e.target.value
                                    )
                                }
                                placeholder={placehoders.email}
                                value={
                                    values.has('email')
                                        ? values.get('email').startsWith
                                        : ''
                                }
                            />
                        </FormItem>

                        <FormItem label={labels.reference}>
                            <Input
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'reference',
                                        'equals',
                                        e.target.value
                                    )
                                }
                                placeholder={placehoders.reference}
                                value={
                                    values.has('reference')
                                        ? values.get('reference').equals
                                        : ''
                                }
                            />
                        </FormItem>

                        <FormItem label={labels.postLinkStatus}>
                            <Radio.Group
                                buttonStyle="solid"
                                className={styles.radioGroupContainer}
                                value={this.postLinkStatusOptionKey}
                                onChange={e =>
                                    this.handlePostLinkStatusValueChange(
                                        e.target.value
                                    )
                                }
                            >
                                {postLinkStatusOptions.map(item => (
                                    <Radio.Button
                                        key={`postlink_option_${item}`}
                                        value={item}
                                    >
                                        {res().postLinkStatusOptions[item]}
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                        </FormItem>
                    </div>
                    <div className={styles.section}>
                        <FormItem
                            label={labels.amount}
                            className={styles.currencyAmount}
                        >
                            <Input
                                value={
                                    values.has('amount')
                                        ? values.get('amount').greaterOrEqual
                                        : ''
                                }
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'amount',
                                        'greaterOrEqual',
                                        e.target.value
                                    )
                                }
                                placeholder={globalRes().valueFrom}
                            />
                            <Input
                                value={
                                    values.has('amount')
                                        ? values.get('amount').lessOrEqual
                                        : ''
                                }
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'amount',
                                        'lessOrEqual',
                                        e.target.value
                                    )
                                }
                                placeholder={globalRes().valueTo}
                            />
                            {this.renderCurrencySelect()}
                        </FormItem>

                        <FormItem label={labels.senderCardMask}>
                            <Input
                                value={
                                    values.has('senderCardMask')
                                        ? values.get('senderCardMask').startsWith
                                        : ''
                                }
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'senderCardMask',
                                        'startsWith',
                                        e.target.value
                                    )
                                }
                                placeholder={res().cardsNumberStartsWith}
                            />
                            <Input
                                value={
                                    values.has('senderCardMask')
                                        ? values.get('senderCardMask').endsWith
                                        : ''
                                }
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'senderCardMask',
                                        'endsWith',
                                        e.target.value
                                    )
                                }
                                placeholder={res().cardsNumberEndsWith}
                            />
                        </FormItem>

                        {/* @Dos AFT */}
                        {/* <FormItem label={labels.receiverCardMask}>
                            <Input
                                value={
                                    values.has('receiverCardMask')
                                        ? values.get('receiverCardMask').startsWith
                                        : ''
                                }
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'receiverCardMask',
                                        'startsWith',
                                        e.target.value
                                    )
                                }
                                placeholder={res().cardsNumberStartsWith}
                            />
                            <Input
                                value={
                                    values.has('receiverCardMask')
                                        ? values.get('receiverCardMask').endsWith
                                        : ''
                                }
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'receiverCardMask',
                                        'endsWith',
                                        e.target.value
                                    )
                                }
                                placeholder={res().cardsNumberEndsWith}
                            />
                        </FormItem> */}

                        <FormItem label={labels.name}>
                            <Input
                                value={
                                    values.has('name')
                                        ? values.get('name').startsWith
                                        : ''
                                }
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'name',
                                        'startsWith',
                                        e.target.value
                                    )
                                }
                                placeholder={placehoders.name}
                            />
                        </FormItem>

                        {/* <FormItem label={labels.installment_id}>
                            <Select
                                mode="multiple"
                                placeholder={placehoders.installment_id}
                                value={selectedInstallments}
                                onChange={value =>
                                    this.handleInstallmentChecked(value)
                                }
                            >
                                {props.installmentsList.map(item => (
                                    <Option key={item.id} value={item.id}>
                                        {
                                            fieldsRes().installments.full[
                                            item.name + 'm'
                                            ]
                                        }
                                    </Option>
                                ))}
                            </Select>
                        </FormItem> */}


                        {/* <FormItem label={labels.payoutDate}>
                            <DatePicker
                                value={
                                    values.has('payoutDate') &&
                                        values.get('payoutDate').greaterOrEqual
                                        ? moment(
                                            values.get('payoutDate')
                                                .greaterOrEqual
                                        )
                                        : null
                                }
                                onChange={date =>
                                    this.setFieldValue(
                                        'payoutDate',
                                        'greaterOrEqual',
                                        date ? date.startOf('day') : date
                                    )
                                }
                                placeholder={globalRes().valueFrom}
                            />
                            <DatePicker
                                value={
                                    values.has('payoutDate') &&
                                        values.get('payoutDate').lessOrEqual
                                        ? moment(
                                            values.get('payoutDate')
                                                .lessOrEqual
                                        )
                                        : null
                                }
                                onChange={date =>
                                    this.setFieldValue(
                                        'payoutDate',
                                        'lessOrEqual',
                                        date ? date.endOf('day') : date
                                    )
                                }
                                placeholder={globalRes().valueTo}
                            />
                        </FormItem> */}

                        {/* <FormItem
                            label={labels.payoutAmount}
                            className={styles.currencyAmount}
                        >
                            <Input
                                value={
                                    values.has('payoutAmount')
                                        ? values.get('payoutAmount')
                                            .greaterOrEqual
                                        : ''
                                }
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'payoutAmount',
                                        'greaterOrEqual',
                                        e.target.value
                                    )
                                }
                                placeholder={globalRes().valueFrom}
                            />
                            <Input
                                value={
                                    values.has('payoutAmount')
                                        ? values.get('payoutAmount').lessOrEqual
                                        : ''
                                }
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'payoutAmount',
                                        'lessOrEqual',
                                        e.target.value
                                    )
                                }
                                placeholder={globalRes().valueTo}
                            />
                            {this.renderCurrencySelect()}
                        </FormItem> */}
                    </div>
                </Form>
            </div>
        )
    }

    private renderCurrencySelect() {
        const { values } = this
        const placehoders = fieldsRes().dataFields.full
        const isAutoComplete = false
        if (!isAutoComplete) {
            return (
                <Select
                    value={
                        values.has('currency')
                            ? values.get('currency').equals
                            : ''
                    }
                    onChange={(value: string) =>
                        this.handleFieldValueChange('currency', 'equals', value)
                    }
                >
                    {currencies.map(curr => (
                        <Option value={curr} key={curr + '_key'}>
                            {curr}
                        </Option>
                    ))}
                </Select>
            )
        }
        return (
            <AutoComplete
                value={
                    values.has('currency') ? values.get('currency').equals : ''
                }
                onSearch={(value: string) =>
                    this.handleFieldValueChange('currency', 'equals', value)
                }
                onSelect={(value: string) =>
                    this.handleFieldValueChange('currency', 'equals', value)
                }
                placeholder={placehoders.currency}
                showSearch
                dataSource={currencies}
            />
        )
    }

    private handleFormSubmit(e) {
        e.preventDefault()
    }

    @action.bound
    private setStatusFilter(value: AFTStatus) {
        this.statusFilter = value !== AFTStatus.any ? value : undefined
    }

    @action.bound
    private setRangeFilter(value: RangePopoverValue) {
        if (value) {
            this.dateRange = value
        }
    }

    @action.bound
    private applyFilters() {
        this.props.onFiltersChange(
            {
                values: this.values,
                installments: this.installments,
                dateRange: this.dateRange,
                postLinkStatusOptionKey: this.postLinkStatusOptionKey
            },
            true
        )
        if (this.props.setStatusFilter) {
            this.props.setStatusFilter(
                this.statusFilter !== AFTStatus.any
                    ? this.statusFilter
                    : undefined
            )
        }
        if (this.props.onRangeChange && this.dateRange) {
            this.props.onRangeChange(this.dateRange)
        }

        this.props.onClose()
    }

    @action.bound
    private clearFilters() {
        this.initValues()
        this.statusFilter = AFTStatus.any
    }

    private handleFieldValueChange(
        field: keyof AFTPayment,
        operator: keyof FilterValue<AFTPayment>,
        value: string
    ) {
        const option = constants.filterOptions
            .get()
            .find(item => item.field === field)
        const type = option && option.type
        let parsedValue = value.trim()

        if (!parsedValue.length) {
            this.removeFieldValue(field, operator)
        } else {
            if (type) {
                if (!type.isValid(value)) {
                    return
                }
                parsedValue = type.parse(value)
            }
            this.setFieldValue(field, operator, parsedValue)
        }
    }

    /*
    @action.bound
    private handleInstallmentChecked(installments) {
        this.installments = installments
        const { values } = this

        const filterData = values.get('installment_id')

        const value = map(
            this.props.installmentsList.filter(item =>
                installments.includes(item.id)
            ),
            item => {
                return item.name
            }
        )

        values.set('installment_id', {
            ...filterData,
            ['in']: value.length > 0 ? value : undefined
        })
    }
    */

    @action.bound
    private handlePostLinkStatusValueChange(value: PostLinkStatusOption) {
        this.postLinkStatusOptionKey = value
    }

    @action
    private initValues() {
        this.values = observable.map()
        this.statusFilter = this.props.code
        this.dateRange = this.props.dateRangeValue
        this.installments = []
    }

    @action
    private setFieldValue(
        field: keyof AFTPayment,
        operator: keyof FilterValue<AFTPayment>,
        value: any
    ) {
        const { values } = this
        const filterData = values.get(field)

        values.set(field, {
            ...filterData,
            [operator]: value
        })
    }

    @action
    private removeFieldValue(
        field: keyof AFTPayment,
        operator: keyof FilterValue<AFTPayment>
    ) {
        const { values } = this
        const filterData = values.get(field)

        if (filterData) {
            remove(filterData, operator)

            if (!Object.keys(filterData).length) {
                values.delete(field)
            }
        }
    }

    @observable
    private values: ObservableMap<
        keyof AFTPayment,
        { [key in keyof FilterValue<AFTPayment>]: any }
    >

    @observable
    private statusFilter: AFTStatus

    @observable
    private dateRange: RangePopoverValue

    @observable
    private postLinkStatusOptionKey: PostLinkStatusOption

    @observable
    private installments: string[]
}
