import React from 'react'

import { observer } from 'mobx-react'

import { Input } from 'antd'

import Page from '~/layouts/main/page'

import layoutStyles from '~/layouts/main/styles.css'

import { Button, Form, FormItem } from '~/components'
import { CheckedIcon, InfoIcon } from '~/components/icon'

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 12 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
    }
}

export default observer(() => {
    const handleSubmit = e => {
        e.preventDefault()
    }

    return (
        <Page
            title="Заявка на активацию платежной системы ePay"
            subtitle="Для дальнейшего использования системы, пожалуйста, заполните заявку на активацию"
            footer={
                <Button type="primary" size="large">
                    Отправить заявку
                </Button>
            }
        >
            <div>
                <Form onSubmit={handleSubmit}>
                    <div className={layoutStyles.info}>
                        * Все поля обязательны для заполнения
                    </div>
                    <FormItem
                        label="Регион"
                        {...formItemLayout}
                        validateStatus="success"
                    >
                        <Input defaultValue="Алматинская область" />
                        <CheckedIcon className={layoutStyles.successIcon} />
                    </FormItem>
                    <FormItem label="Контактное лицо" {...formItemLayout}>
                        <Input />
                    </FormItem>
                    <FormItem label="Номер телефона" {...formItemLayout}>
                        <Input />
                    </FormItem>
                    <FormItem label="Название предприятия" {...formItemLayout}>
                        <Input />
                    </FormItem>
                    <FormItem label="БИН" {...formItemLayout}>
                        <Input />
                        <InfoIcon className={layoutStyles.infoIcon} />
                    </FormItem>
                </Form>
            </div>
        </Page>
    )
})
