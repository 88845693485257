import res from './res'

res.add('kk', {
    title: 'Дүкенге арналған терминал параметрлері ',
    confirmTitle: 'Сіз деректерді жүктегіңіз келетініне сенімдісіз бе?',
    labels: {
        isActive: 'Белсенді',
        saveCard: 'Картаны сақтау',
        singleMessage: '1 қадамдық схема',
        terminalId: 'Терминал Id ',
        save: 'Сақтау'
    },
    messages: {
        errorTitle: 'Қате',
        cannotLoadShops: 'Дүкендер тізімін жүктеу мүмкін емес',
        unsavedChanges: 'Егер сіз операцияны растасаңыз, барлық өзгерістер жоғалады',
        saveSuccess: 'Өзгерістер сақталды'
    }
})
