import React from 'react'

import { action, observable } from 'mobx'

import config from '~/config'

import storage from '~/services/storage'

import { isNil } from 'lodash'

class LayoutStore {
    @observable
    public isSiderVisible: boolean = storage.get({
        key: config.settings.ui.siderVisibility,
        defaultValue: true
    })

    @observable
    public isMenuInitilazed: boolean = false

    @observable
    public openMainMenuKeys: string[] = [
        `sub_menu_${document.location.pathname.split('/')[1]}`
    ]

    @action
    public toggleSider(isOpening?: boolean) {
        this.isMenuInitilazed = true
        const isControlled = !isNil(isOpening)
        this.isSiderVisible = isControlled ? isOpening : !this.isSiderVisible
        storage.set(config.settings.ui.siderVisibility, this.isSiderVisible)
        setTimeout(this.triggerResizeEvent, 300)
    }

    @action
    public toggleSubMenu(openKeys: string[]) {
        this.openMainMenuKeys = openKeys.filter(
            key => !this.openMainMenuKeys.includes(key)
        )
    }

    @action
    public openSubMenu(openKeys: string[]) {
        this.openMainMenuKeys = openKeys
    }

    @action
    public setHeaderComponent(component: any) {
        this.headerComponent = component
    }

    private triggerResizeEvent() {
        const event = document.createEvent('HTMLEvents')
        event.initEvent('resize', true, false)
        window.dispatchEvent(event)
    }

    private headerComponent: HTMLElement
}

export default new LayoutStore()
