import { action, observable, runInAction } from 'mobx'

import config from '~/config'

import { check, FlowResult, noThrow, throwOnFail } from '~/utils/control-flow'

import Bowser from 'bowser'

export class BrowserStore {

    constructor() {
        this.initialize()
    }
    public browserInformation: any

    public isIE9: boolean

    public isIE: boolean

    @observable
    public initialized: boolean = false

    @observable
    public windowWidth: number = window.innerWidth

    @observable
    public windowHeight: number = window.innerHeight

    @observable
    public isMobile: boolean = false

    @action.bound
    public onWindowResize(evnt) {
        if (this.windowWidth !== window.innerWidth) {
            this.windowWidth = window.innerWidth
        }
        if (this.windowHeight !== window.innerHeight) {
            this.windowHeight = window.innerHeight
        }
    }

    public raiseResize() {
        const el = document
        const event = document.createEvent('HTMLEvents')
        event.initEvent('resize', true, false)
        el.dispatchEvent(event)
    }

    @action.bound
    private initialize() {
        window.removeEventListener('resize', this.onWindowResize)
        window.addEventListener('resize', this.onWindowResize)
        const browser = Bowser.getParser(window.navigator.userAgent)
        this.isIE9 = browser.satisfies({ 'internet explorer': '<10' })
        this.isIE = browser.satisfies({ 'internet explorer': '<60' })
        const browserInfo = browser.getBrowser()
        const result = browser.getResult()
        this.browserInformation = browserInfo
        this.isMobile = result.platform.type === 'mobile'
        this.onWindowResize(null)
    }

}
const store: BrowserStore = new BrowserStore()

export default store
