// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyAbQCTy9HtVQl98vMQVMYr2GTt_lhEVrVw',
    authDomain: 'halyk-mp-prod.firebaseapp.com',
    projectId: 'halyk-mp-prod',
    storageBucket: 'halyk-mp-prod.appspot.com',
    messagingSenderId: '610273684609',
    appId: '1:610273684609:web:318844404674df52b9f180',
    measurementId: 'G-25005FQTJ4'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

export const logEventFirebase = (eventName: string, eventParams?: object) => {
    if (eventParams) {
        logEvent(analytics, eventName, eventParams)
    } else {
        logEvent(analytics, eventName)
    }
}