import React from 'react'

import res from './res'

import styles from '../styles.css'

function actionPayments(
    action: string,
    paymentsCount: number,
    amounts: React.ReactNode
) {
    return (
        <>
            <span className={styles.noWrap}>
                {action} {paymentsCount} {getPaymentCountLabel(paymentsCount)}
            </span>
            <br />
            на сумму {amounts}?
        </>
    )
}

res.add('ru', {
    noSelection: 'Для подтверждения, отмены или возврата отметьте выплаты',
    multiStatusSelection:
        'Для выполнения действий выберите выплаты с одинаковым статусом',
    fullAction: 'Провести полностью',
    partialAction: 'Провести частично',

    chargeAction: 'Провести',
    chargeFullForm: (paymentsCount, amounts) =>
        actionPayments('Списать', paymentsCount, amounts),
    chargePartialTitle: 'Частичное списание',
    chargePartialDisable:
        'Частичное проведение возможно при выборе одной выплаты ',
    chargePartialOkButton: 'Провести',

    cancelAction: 'Отменить оплату',
    cancelForm: (paymentsCount, amounts) =>
        actionPayments('Отменить', paymentsCount, amounts),

    refundAction: 'Оформить возврат',
    refundFullForm: (paymentsCount, amounts) =>
        actionPayments('Вернуть', paymentsCount, amounts),
    refundPartialTitle: 'Частичный возврат',
    refundPartialDisable:
        'Частичный возврат возможен при выборе одной выплаты',
    refundPartialOkButton: 'Вернуть',

    noActionsForCancelled: (
        <>
            Действия над выплатами со статусом{' '}
            <span className={styles.status}>“Отменён”</span> невозможны
        </>
    ),
    noActionsForRefunded: (
        <>
            Действия над выплатами со статусом{' '}
            <span className={styles.status}>“Возврат”</span> невозможны
        </>
    ),
    confirmActions: {
        mainText: 'Подтвердить ',
        simpleText: 'Подтвердите оплату:',
        simpleCancelText: 'Подтвердите отмену выплаты:',
        actions: {
            CHARGE: 'выплата ',
            PARTIALCHARGE: 'частичное проведение ',
            CANCEL: 'отмену  ',
            REFUND: 'возврат ',
            PARTIALREFUND: 'частичный возврат '
        },
        sumText: 'на сумму ',
        and: 'и '
    },
    confirmAction: (
        actionType: string,
        amount: number,
        partialAmount?: number
    ) => {
        let confirmLabel = `${res().confirmActions.mainText}
                            ${res().confirmActions.actions[actionType]}
                            ${res().confirmActions.sumText} ${amount}`
        if (partialAmount) {
            confirmLabel += `${res().confirmActions.and}
            ${res().confirmActions.actions.PARTIALCHARGE}
            ${res().confirmActions.sumText} ${partialAmount}`
        }
        return confirmLabel
    },
    amountForm: {
        paymentAmount: 'Сумма выплаты',
        chargeAmount: 'Сумма списания',
        payoutAmount: 'Сумма возврата'
    },
    okButton: 'Да',
    cancelButton: 'Нет',
    partialCancelButton: 'Отменить',
    actionInProgress: 'Проводится операция'
})

const getPaymentCountLabel = (paymentsCount: number) => {
    if (paymentsCount >= 5 && paymentsCount <= 20) {
        return 'выплаты'
    }

    const lastDigit = paymentsCount % 10

    if (lastDigit === 1) {
        return 'выплата'
    }

    if (lastDigit >= 2 && lastDigit <= 4) {
        return 'выплаты'
    }

    return 'выплаты'
}
