import res from './res'

res.add('ru', {
    title: 'Настройка магазина ',
    confirmTitle: 'Вы уверены, что хотите загрузить данные?',

    labels: {
        shop_name: 'Название магазина',
        shop_name_placeholder: 'Введите название магазина',
        logo: 'Логотип',
        lang: 'Язык',
        email: 'Email',
        email_placeholder: 'Введите email',
        phone: 'Телефон',
        phone_placeholder: 'Введите телефон',
        colorScheme: 'Цветовая схема',

        bg: 'Общий фон',
        btn_color: 'Цвет кнопок',
        card_bg: 'Фон карты',
        card_txt_color: 'Цвет текста на карте',
        txt_color: 'Цвет текста общий',

        required: 'Обязательно',

        fileDescription: 'Допустимые форматы только jpg, png, gif или svg размером до 1 Mb. Логотип отображается вверху платежной страницы',
        gradientDescription: '(Установите два цвета для градиентной заливки)',

        reset: 'Сбросить',
        save: 'Сохранить',
        load: 'Загрузить',
        custom: 'Задать цвета вручную'
    },

    sampleForm: {
        Order: 'Заказ',
        PaymentStore: 'Оплата в интернет магазине',
        Commission: 'Комиссия',
        Seller: 'Продавец',
        cardNumber: 'Номер карты',
        expires: 'Действует до',
        cardName: 'Имя на карте',
        email: 'Ваш email',
        phoneNumber: 'Ваш телефон',
        pay: 'Оплатить',
        back: 'Назад'
    },

    messages: {
        epayTemplateUpdated: 'Бизнес настройки сохранены',
        errorTitle: 'Ошибка',
        cannotLoadShops: 'Не могу загрузить список магазинов',
        invalidFile: 'Неверный тип файла или размер превышает 1Mb',
        unsavedChanges: 'Все изменения будут утеряны, если вы подтвердите операцию',
        invalidEmail: 'Неверный email',
        valueRequired: 'Необходимо задать значение',
        invalidPhone: 'Неверный номер телефона'
    }

})
