import React from 'react'

import res from './res'

res.add('ru', {
    dataFilterPlaceholder: 'Фильтр по атрибутам',
    loading: 'Загрузка оплаты...',
    payment: 'Оплаты',
    pageInfo: (info: { from: number; to: number; total: number }) => (
        <>
            {info.from}-{info.to}&nbsp;&nbsp;из&nbsp;&nbsp;{info.total}
        </>
    ),
    operationsResult: (totalCount: number, successCount: number) =>
        `${successCount} из ${totalCount} операций выполнено успешно`,
    actions: 'Действия',
    gridControlsTooltips: {
        reload: 'Обновить данные',
        settings: 'Настройки таблицы',
        firstPage: 'Первая страница',
        prevPage: 'Предыдущая страница',
        nextPage: 'Следующая страница',
        lastPage: 'Последняя страница'
    },
    filters: 'Фильтры'
})
