import CardType from '~/constants/card-systems'

import res from '~/res'

export function log(...args: any[]) {
    // tslint:disable-next-line
    console.log(...args)
}

export function camelCaseToKebabCase(value: string) {
    return value.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

export function camelCaseToSnakeCase(value: string) {
    return value.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()
}

export function snakeCaseToPascalCase(value: string) {
    return upperCaseFirstLetter(snakeCaseToCamelCase(value))
}

export function snakeCaseToCamelCase(value: string) {
    return value.replace(/(-[a-z])/g, $1 => $1.toUpperCase().replace('-', ''))
}

export function upperCaseFirstLetter(value: string) {
    return value ? value.charAt(0).toUpperCase() + value.substr(1) : value
}

export function lowerCaseFirstLetter(value: string) {
    return value ? value.charAt(0).toLowerCase() + value.substr(1) : value
}

export function renameProperties<T>(
    obj: T,
    renamer: (name: string) => string
): T {
    if (obj === undefined || obj === null) return obj

    if (obj instanceof Array) {
        return (obj.map(value =>
            typeof value === 'object' ? renameProperties(value, renamer) : value
        ) as any) as T
    }

    if (typeof obj !== 'object') return obj

    const result = {}

    for (const [key, value] of Object.entries(obj)) {
        result[renamer(key)] = renameProperties(value, renamer)
    }

    return result as T
}

export function camelCaseObject<T>(obj: T): T {
    return renameProperties(obj, lowerCaseFirstLetter)
}

export function formatSum(value: number, sep: string = ' ') {
    const valueString = '' + (value || 0)

    return (
        valueString &&
        valueString.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1' + sep)
    )
}

export function formatMoneySum(value: number, sep: string = ' ') {
    const valueString = '' + (value || 0).toFixed(2)

    return (
        valueString &&
        valueString.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1' + sep)
    )
}

export function formatLongNumber(
    num: number,
    units: string[] = ['', 'K', 'M', 'B', 'T'],
    bases: number[] = [1, 1000, 1000000, 1000000000]
) {
    let i = 0

    for (; num >= bases[i + 1] && i !== units.length; ++i) {
        continue
    }

    num /= bases[i]

    return num.toFixed(num < 100 ? (num < 10 ? 2 : 1) : 0) + units[i]
}

export function isNumber(value: string) {
    return !isNaN(parseFloat(value)) && isFinite(+value)
}

export function move<T>(arr: T[], from: number, to: number) {
    arr.splice(to, 0, arr.splice(from, 1)[0])
}

// export function getCardSystemType(cardMask: string): CardType {
//     const digits = +cardMask.substr(0, 2)

//     if (isNaN(digits) || digits < 10) return 'other'

//     if (digits >= 40 && digits <= 49) {
//         return 'visa'
//     }

//     if (digits >= 50 && digits <= 55) {
//         return 'masterCard'
//     }

//     if (digits >= 34 && digits <= 37) {
//         return 'amex'
//     }

//     return 'other'
// }
