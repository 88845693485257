import React from 'react'

const SvgMail = props => (
    <svg width="1em" height="1em" viewBox="0 0 16 13" {...props}>
        <path d="M.303 0v11.143h7.275A4.225 4.225 0 0011.085 13c1.462 0 2.75-.738 3.506-1.857h.713V0h-15zm13.195.929L7.803 5.44 2.109.929h11.39zM1.241 10.214V1.43l6.358 5.038a4.134 4.134 0 00-.488 3.746H1.24zm9.844 1.803c-1.782-.004-3.223-1.432-3.228-3.196.005-1.764 1.446-3.192 3.228-3.196 1.78.004 3.222 1.432 3.226 3.196-.004 1.764-1.445 3.192-3.226 3.196zm0-7.374c-.308 0-.608.035-.897.096l4.178-3.309v4.767a4.222 4.222 0 00-3.281-1.554z" />
        <path d="M10.616 6.964v3.714l1.875-1.857-1.875-1.857z" />
    </svg>
)

export default SvgMail
