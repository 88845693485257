import res from './res'

res.add('ru', {
    dataFields: {
        full: {
            createdAt: 'Дата формирования',
            statusName: 'Статус',
            emails: 'Получатели',
            shopIds: 'Магазины',
            moderatorComment: 'Комментарии',
            creditConditions: 'Условия кредитования',
            checkingAccount: 'Расчетный счет'
        },
        short: {
            createdAt: 'Дата',
            statusName: 'Статус',
            emails: 'Получатели',
            shopIds: 'Магазины',
            moderatorComment: 'Комментарии',
            creditConditions: 'Условия',
            checkingAccount: 'Расчетный счет'
        }
    },
    creditOrderStatus: {
        NEW: 'Заявка подана',
        IN_PROGRESS: 'В обработке',
        MERCHANT_CONFIRMATION: 'Необходимо подтверждение',
        ACCEPT: 'Заявка утверждена',
        REJECT: 'Отклонена'
    },
    tooltips: {
        noValues: 'Данные не указаны'
    },
    installment: 'Рассрочка',
    credit: 'Кредит',
    conditionLabel: (val: string) => {
        const value = parseInt(val, null)

        if (value >= 5 && value <= 20) return `${value} месяцев`

        const lastDigit = value % 10

        if (lastDigit === 1) return `${value} месяц`

        if (lastDigit >= 2 && lastDigit <= 4) return `${value} месяца`

        return `${value} месяцев`
    }
})
