import { ReactNode } from 'react'
import { ModalContainerInterface } from './modal-container'

export class ModalContainerContext {
    constructor(container?: ModalContainerInterface) {
        this.modalInstance = container
    }
    public modalInstance: ModalContainerInterface
    public modalController = {
        setTitle: (title: string | ReactNode) => {
            if (this.modalInstance) {
                this.modalInstance.setTitle(title)
            }
        }
    }
}
