import dataFields, { detailedFields } from './data-fields'
import filterOptions from './filters-options'
import paymentStatuses from './p2p-statuses'
import sortOptions from './sort-options'

export default {
    dataFields,
    detailedFields,
    paymentStatuses,
    filterOptions,
    sortOptions
}
