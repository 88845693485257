import { PaymentLinkStatus } from '~/api/payment-links'
import res from './res'

res.add('en', {
    paymentLinksTitle: 'Payment Links',
    generateButtonTitle: 'Generate link',
    sortBy: 'Sort by',
    ranges: {
        today: 'today',
        yesterday: 'yesterday',
        thisWeek: 'this week',
        priorWeek: 'prior week',
        thisMonth: 'this month',
        priorMonth: 'prior month',
        thisQuarter: 'this quarter',
        customPeriod: 'custom period'
    },
    linkStatuses: {
        [PaymentLinkStatus.any]: 'Any',
        [PaymentLinkStatus.active]: 'Active',
        [PaymentLinkStatus.annulled]: 'Canceled',
        [PaymentLinkStatus.expired]: 'Expired',
        [PaymentLinkStatus.charged]: 'Charged'
    },
    dateFilterLabel: 'From period ',
    savedText: 'Hoo-Ray. the link is saved in clipboard'
})

