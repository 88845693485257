import config from '~/config'
import { get, post, put } from '~/utils/ajax'
import message from '~/utils/message'
import { ListRequest } from '../contracts'
import { PaymentLinkRequest, PaymentLinkResponse } from './contracts'
import amplitude from 'amplitude-js'

export * from './contracts'

export const getPaymentLinks = (request: PaymentLinkRequest) =>
    post<PaymentLinkResponse>('invoice-links', request)

export const cancelPaymentLink = async (request: { id: string }) => {
    const { data } = JSON.parse(localStorage.getItem('auth'))

    try {
        const response = await fetch(
            `${config.api.baseUrl}deactivate/invoice-links/${request.id}`,
            {
                method: 'PUT',
                headers: new Headers({
                    Authorization: `Bearer ${data.access_token}`
                })
            }
        )
        amplitude
            .getInstance()
            .logEvent('user_cancelled_invoice_link_with_id', { id: request.id })
        return response
    } catch (error) {
        message.error(error)
        amplitude
            .getInstance()
            .logEvent('failed_to_cancel_invoice_link_with_id', {
                id: request.id,
                ...error
            })
        return error
    }
}
