import { computed } from 'mobx'

import { AFTPayment } from '~/api'

import { FilterOption, types } from '~/components/data-filter'

import strict from '~/utils/strict'

import res from '../res'

const currency = {
    isValid: (str: string) => /^[A-Za-z]{1,3}$/.test(str),
    parse: (str: string) => str.toUpperCase()
}

const options = computed(() => {

    const titles = res().dataFields.short

    return strict<Array<FilterOption<AFTPayment> & { fullOnly?: boolean }>>([
        {
            field: 'invoiceID',
            title: titles.invoiceID,
            type: types.string,
            operators: ['endsWith']
        },
        // {
        //     field: 'phone',
        //     title: titles.phone,
        //     operators: ['endsWith']
        // },
        {
            field: 'email',
            title: titles.email,
            operators: ['startsWith']
        },
        {
            field: 'name',
            title: titles.name,
            operators: ['startsWith']
        },
        /*
        {
            field: 'shop',
            title: titles.shop,
            type: types.string,
            operators: ['equals']
        },
        */
        {
            field: 'amount',
            title: titles.amount,
            type: types.number,
            operators: ['lessOrEqual', 'greaterOrEqual']
        },
        {
            field: 'currency',
            title: titles.currency,
            type: currency,
            operators: ['equals']
        },
        /*
        {
            field: 'installment_id',
            title: titles.installment_id,
            type: types.string,
            operators: ['in']
        },
        */
        {
            field: 'reference',
            title: titles.reference,
            type: types.string,
            operators: ['equals']
        },
        // {
        //     field: 'senderTransferType',
        //     title: titles.senderTransferType,
        //     type: types.string,
        //     operators: ['equals']
        // },
        {
            field: 'senderCardMask',
            title: titles.senderCardMask,
            type: types.number,
            operators: ['startsWith', 'endsWith']
        },
        // @Dos aft
        // {
        //     field: 'receiverCardMask',
        //     title: titles.receiverCardMask,
        //     type: types.number,
        //     operators: ['startsWith', 'endsWith']
        // },
        // {
        //     field: 'accountID',
        //     title: titles.accountID,
        //     operators: ['equals']
        // },

        {
            field: 'postLinkStatus',
            title: titles.postLinkStatus,
            operators: ['equals'],
            valueGetter: (value: string) => {
                const posibleValues = [
                    { value: true, comparison: ['успешно', 'да', 'есть', 'success', 'exist', 'true', 'yes'] },
                    { value: false, comparison: ['неуспешно', 'нет', 'ошибка', 'error', 'failed', 'not exist', 'false', 'no'] }
                ]

                const existedValue = posibleValues.find(pValue => pValue.comparison.includes(value.toLocaleLowerCase()))
                return existedValue ? existedValue.value : undefined
            }

        }

        /*
         {
             field: 'payoutDate',
             title: titles.payoutDate,
             type: types.date,
             operators: ['greaterOrEqual', 'lessOrEqual']
         },
         */
        /*
        {
            field: 'payoutAmount',
            title: titles.payoutAmount,
            type: types.number,
            operators: ['lessOrEqual', 'greaterOrEqual']
        }
        */
    ])
})

export default options
