import locale from '~/utils/locale'

export default locale.create<{
    errors: {
        146: string
        148: string
        151: string
        275: string
        267: string
        912: string
    }
}>()
