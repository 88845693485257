import res from './res'

res.add('ru', {
    title: 'Настройки терминалов для магазина ',
    confirmTitle: 'Вы уверены, что хотите загрузить данные?',
    labels: {
        isActive: 'Активен',
        saveCard: 'Сохранять карту',
        singleMessage: '1-шаговая схема',
        terminalId: 'Id терминала',
        save: 'Сохранить'
    },
    messages: {
        errorTitle: 'Ошибка',
        cannotLoadShops: 'Не могу загрузить список магазинов',
        unsavedChanges: 'Все изменения будут утеряны, если вы подтвердите операцию',
        saveSuccess: 'Изменения сохранены'
    }
})
