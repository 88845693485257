import { observer } from 'mobx-react'
import React from 'react'
import NewsStore from '../news-store'
import AppStore from '~/app-store'
import Page from '~/layouts/main/page'
import NewsRenderer from '../components/newsCard/index'
import { Row } from 'antd'
import res from '../res'
import BrowserStore from '~/services/browser/browser-state'
import styles from '../styles.css'
import { RightOutlined } from '@ant-design/icons'
import { InfinitiveList } from '~/components/list/infinitive-list'
import { NewsCard } from '../news-store'
import { NoDataIcon } from '~/components/icon'

export default observer(({ store, appStore }: { store: NewsStore, appStore: typeof AppStore }) => {
    return (
        <Page title={res().newsTitle} controls={
            !BrowserStore.isMobile && <a href="/news-all" className={styles.linkToAll}>Показать все <RightOutlined className={styles.arrowRight} color={'#1C9771'} /></a>
        }>
            <Row>
                {
                    store.mainNewsList ?
                        <>
                            {!BrowserStore.isMobile &&
                                store.mainNewsList?.map(news =>
                                    <NewsRenderer
                                        isMobile={BrowserStore.isMobile}
                                        date={news.publish_time}
                                        tags={[news.tag, news.seen_status]}
                                        image={news.image_url}
                                        title={news.title}
                                        text={news.description}
                                        period={news.promotion_periodstring}
                                        link={news.id}
                                    />
                                )
                            }
                            {BrowserStore.isMobile &&
                                <div
                                    className={styles.listInfiniteContainer}
                                    style={{ height: BrowserStore.windowHeight - 101 + 'px' }}
                                >
                                    <InfinitiveList<NewsCard>
                                        dataSource={store.newsList}
                                        loaded={!store.loadingNews}
                                        dataLoader={store.handleShowMore}
                                        itemRenderer={(news) => <NewsRenderer
                                            isMobile={BrowserStore.isMobile}
                                            date={news.publish_time}
                                            tags={[news.tag, news.seen_status]}
                                            image={news.image_url}
                                            title={news.title}
                                            text={news.description}
                                            period={news.promotion_periodstring}
                                            link={news.id}
                                        />}
                                        pageSize={store.pageSize}
                                        scrollTopTrigger={3}
                                    />
                                </div>
                            }
                        </> :
                        <div className={styles.noData}>
                            <NoDataIcon className={styles.noDataIcon} />
                            <span>{res().noDataLable}</span>
                        </div>
                }
            </Row>
        </Page>
    )
})

// 