import { action, computed, observable, runInAction } from 'mobx'
import moment from 'moment'
import page from 'page'
import actions from '~/actions'
import BrowserStore from '~/services/browser/browser-state'
import { apiCheck } from '~/api'
import {
    ContractStatementHistory,
    ContractStatementInfo,
    ContractStatementSchedule,
    createStatement,
    CreateStatementRequest,
    downloadStatement,
    FrequencyType,
    generateStatement,
    GenerateStatementRequest,
    getStatementHistory,
    getStatements,
    getStatementStatus,
    HistoryItemStatus,
    ManualStatement,
    updateStatement,
    UpdateStatementRequest
} from '~/api/statements-transfer'

import { Column } from '~/components/grid'

import { RangePopoverValue } from '~/components/range-popover'

import { noThrow } from '~/utils/control-flow'
import message from '~/utils/message'
import { strict } from '~/utils/strict'

import constants, { StatementFormats } from './constants'

import { PageSize } from '~/api/contracts'
import res from './res'

export default class StatementsTransferStore {
    constructor(statementId?: string) {
        this.sortField = 'name'
        this.sortDirection = 'desc'
        this.showStatementConfigForm = false
        if (!this.statements || this.statements.length === 0) {
            this.loadStatements().then(() => {
                this.setSort('name', 'asc')
                if (statementId) this.setStatement(statementId)
            })
        }
    }

    @computed
    public get rangePresets() {
        const localization = res().dateRanges

        const today = moment().startOf('day')

        const yesterday = today.clone().add(-1, 'days')

        const thisWeek = today.clone().startOf('isoWeek')

        const prevWeek = thisWeek.clone().add(-1, 'week')

        const thisMonth = today.clone().startOf('month')

        const prevMonth = thisMonth.clone().add(-1, 'month')

        return strict<RangePopoverValue[]>([
            {
                key: 'yesterday',
                label: localization.yesterday,
                range: [yesterday, yesterday.clone().endOf('day')]
            },
            {
                key: 'priorWeek',
                label: localization.priorWeek,
                range: [prevWeek, prevWeek.clone().endOf('week')]
            },
            {
                key: 'priorMonth',
                label: localization.priorMonth,
                range: [prevMonth, prevMonth.clone().endOf('month')]
            },
            {
                key: 'customPeriod',
                label: localization.customPeriod,
                range: [null, null]
            }
        ])
    }

    public get statementsGridColumns(): Array<Column<ContractStatementInfo>> {
        return constants.fields.get()
    }

    public get statementHistoryGridColumns(): Array<
        Column<ContractStatementHistory>
    > {
        return constants.historyFields(this).get()
    }

    @computed
    private get inProgressStatements() {
        return !this.statementHistory
            ? []
            : this.statementHistory.filter(
                x => x.status === HistoryItemStatus.inProgress
            )
    }

    public statementKey = 'shopId'
    public statementHistoryKey = 'id'

    public statementFormats: StatementFormats[] = constants.statementFormats

    @observable
    public statements: ContractStatementInfo[]

    @observable
    public statement: ContractStatementInfo

    @observable
    public statementHistory: ContractStatementHistory[]

    @observable
    public curentSchedule: FrequencyType

    @observable
    public pageInfo: {
        from: number
        to: number
        total: number
    }

    @observable
    public statementsLoading: boolean

    @observable
    public statementUpdating: boolean = false

    @observable
    public statementHistoryLoading: boolean = false

    @observable
    public showSortingMobile: boolean

    @observable
    public sortField: keyof ContractStatementInfo

    @observable
    public sortDirection: 'asc' | 'desc'

    @observable
    public showStatementConfigForm: boolean

    @observable
    public hideTerminals: boolean = true

    @observable
    public pageSize: PageSize = 20

    public async loadStatements() {
        this.statementsLoading = true

        const { value, error } = await noThrow(apiCheck(getStatements()))

        runInAction(() => {
            this.statementsLoading = false
            if (error) {
                return message.error(error, res().errors.statementsLoadingError)
            }

            this.statements = value
            this.setSort(this.sortField, this.sortDirection)

            this.pageInfo = {
                from: 1,
                to: value?.length,
                total: value?.length
            }
        })
    }

    public disableHistoryWatch() {
        if (this._historyWatchContext) {
            this._historyWatchContext.cancelled = true
        }
    }

    public async loadHistory(id: string) {
        this.statementHistoryLoading = true

        const { value, error } = await noThrow(
            apiCheck(getStatementHistory(id))
        )

        await runInAction(() => {
            this.statementHistoryLoading = false

            if (error) {
                return message.error(
                    error,
                    res().errors.statementHistoryLoadingError
                )
            }

            value.sort(
                (a, b) =>
                    new Date(b.create).valueOf() - new Date(a.create).valueOf()
            )

            this.statementHistory = value
        })

        this.watchHistory()
    }

    @action.bound
    public setStatement(statementId: string) {
        if (this.statement && this.statement.shopId !== statementId) {
            this.statementHistory = []
        }

        this.statement = this.statements.find(x => x.shopId === statementId)

        if (this.statement) this.loadHistory(statementId)
    }

    @action.bound
    public async reload() {
        await this.loadStatements()
    }

    @action.bound
    public setSort(
        field: keyof ContractStatementInfo,
        direction: 'asc' | 'desc'
    ) {
        this.sortField = field
        this.sortDirection = direction
        if (this.statements && this.statements.length > 0) {
            const compare = (a, b) => {
                if (a[field] < b[field]) return direction === 'asc' ? -1 : 1
                if (a[field] > b[field]) return direction === 'asc' ? 1 : -1
                return 0
            }

            this.statements = this.statements.sort(compare)
        }
    }

    @action.bound
    public async applyScheduleStatementChanges(
        statementSchedule: ContractStatementSchedule,
        frequency: string
    ) {
        const schedule = statementSchedule

        this.statementUpdating = true

        if (!schedule.schedulerId) {
            const request: CreateStatementRequest = {
                name: this.statement.name,
                type: this.statement.type,
                frequency: frequency.toUpperCase(),
                emails: schedule.emails,
                format: schedule.format,
                fileFormat: schedule.fileFormat
            }

            const { value, error } = await noThrow(
                apiCheck(createStatement(request))
            )
            runInAction(() => {
                this.statementUpdating = false
                if (error) {
                    return message.error(
                        error,
                        res().errors.statementCreatingError
                    )
                }

                if (value) {
                    this.statement[frequency] = value
                    this.statement[frequency].schedulerId = value.id
                }

                this.toggleStatementConfigForm()
            })
        } else {
            const request: UpdateStatementRequest = {
                id: schedule.schedulerId,
                emails:
                    schedule.emails && schedule.emails.length !== 0
                        ? schedule.emails
                        : [],
                format: schedule.format,
                enable: schedule.enable
            }

            const { value, error } = await noThrow(
                apiCheck(updateStatement(request))
            )

            runInAction(() => {
                this.statementUpdating = false
                if (error) {
                    return message.error(
                        error,
                        res().errors.statementUpdatingError
                    )
                }

                if (value) {
                    this.statement[frequency] = value
                    this.statement[frequency].schedulerId = value.id
                }
                this.toggleStatementConfigForm()
            })
        }
    }

    @action.bound
    public async generateStatementApply(statement: ManualStatement) {
        this.statementUpdating = true

        const request: GenerateStatementRequest = {
            fromDate: statement.range.range[0].utcOffset(0, true).toDate(),
            toDate: statement.range.range[1].utcOffset(0, true).toDate(),
            shopID: statement.shopID,
            contract: this.statement.contract,
            format: statement.format as StatementFormats,
            fileFormat: statement.fileFormat
        }

        const { error } = await noThrow(apiCheck(generateStatement(request)))

        runInAction(() => {
            this.statementUpdating = false

            if (error) {
                return message.error(error, res().errors.statementCreatingError)
            }

            this.toggleStatementConfigForm()

            this.loadHistory(this.statement.shopId)
        })
    }

    public openStatementDetail = (record: ContractStatementInfo): void =>
        actions.openStatementTransferPage(record.shopId)

    public getBack = () => actions.openStatements()

    @action.bound
    public async statementDownload(historyItem: ContractStatementHistory) {
        const { value: response, error } = await noThrow(
            apiCheck(downloadStatement(historyItem.id))
        )

        if (error) {
            return message.error(
                error,
                res().errors.statementHistoryLoadingError
            )
        }

        const period =
            historyItem.period[0] === historyItem.period[1]
                ? moment(historyItem.period[0]).format('YYYY-MM-DD')
                : `${moment(historyItem.period[0]).format(
                    'YYYY-MM-DD'
                )}_${moment(historyItem.period[1]).format('YYYY-MM-DD')}`

        const fileName = `${this.statement.name.replace(
            new RegExp('[\\s|/]+', 'gm'),
            '_'
        )}_${period}`

        const header = response.headers.get('Content-Disposition')

        const pattern = /\.[0-9a-zA-Z]+$/i
        const format = header.match(pattern)

        const file = `${fileName}${format}`

        if (BrowserStore.isIE9) {
            const frame = document.createElement('iframe')
            document.body.appendChild(frame)

            frame.contentWindow.document.open('text/html', 'replace')
            frame.contentWindow.document.write('sep=,\r\n' + response.text())
            frame.contentWindow.document.close()
            frame.contentWindow.focus()
            frame.contentWindow.document.execCommand('SaveAs', true, file)

            document.body.removeChild(frame)
        } else {
            response.blob().then(b => {
                const a = document.createElement('a')
                const href = URL.createObjectURL(b)
                a.setAttribute('download', file)
                a.setAttribute('href', href)
                a.click()
            })
        }
    }

    @action.bound
    public showTerminals = () => (this.hideTerminals = !this.hideTerminals)

    @action.bound
    public toggleSortingMobile = () =>
        (this.showSortingMobile = !this.showSortingMobile)

    @action.bound
    public toggleStatementConfigForm(schedule?: FrequencyType) {
        window.onpopstate = !this.showStatementConfigForm
            ? e => {
                e.state.path = `/statements/${this.statement.shopId}`
                this.toggleStatementConfigForm()
                window.onpopstate = null
            }
            : null

        this.curentSchedule = schedule
        this.showStatementConfigForm = !this.showStatementConfigForm
    }

    private async watchHistory() {
        const context = { cancelled: false }

        this._historyWatchContext = context

        while (this.inProgressStatements.length !== 0) {
            await new Promise(resolve => setTimeout(resolve, 10000))

            if (context.cancelled) break

            await this.updateHistory()
        }
    }

    private async updateHistory() {
        const statusesUpdating = []

        for (const item of this.inProgressStatements) {
            statusesUpdating.push(
                noThrow(apiCheck(getStatementStatus(item.id))).then(
                    ({ value, error }) => {
                        if (error) {
                            return message.error(
                                error,
                                res().errors.statementHistoryLoadingError
                            )
                        }

                        if (value.status !== HistoryItemStatus.inProgress) {
                            runInAction(() => {
                                const indexOfHistoryItem = this.statementHistory.findIndex(
                                    x => x.id === value.id
                                )
                                if (indexOfHistoryItem < 0) return
                                this.statementHistory[
                                    indexOfHistoryItem
                                ] = value
                            })
                        }
                    }
                )
            )
        }

        await Promise.all(statusesUpdating)
    }

    private _historyWatchContext: { cancelled: boolean }
}
