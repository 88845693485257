import res from '~/res'

import { isNumber } from '~/utils'
import { mobileCodes } from '~/utils/mobile-codes'

export type Rule<T> = (value: T) => boolean

export type MessageType = string | (() => string)

export interface Validation<T> {
    rule: Rule<T>
    message: MessageType
}

export function create<T>(rule: Rule<T>, message: MessageType) {

    const result = (newMessage: MessageType) => ({
        rule,
        message: newMessage
    })

    result.rule = rule
    result.message = message

    return result
}

export const required = create((value) => !!value, () => res().required)

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const email = create<string>((value) => !value || emailRegex.test(value), () => res().invalidEmail)

export function phone(errMessage: string) {

    return create<string>(value => {

        value = value || ''
        const phone = value.replace(/[^0-9]/, '')
        if (!phone) return false

        for (const rule of mobileCodes) {
            if (rule[2].test(phone)) return true
        }
        return false

    }, () => errMessage)

}

export const numeric = create<string>((value) => !value || isNumber(value), res().invalidNumber)

export function minLength(minLengthValue: number, errMessage: string) {
    return create<string>((value) => value.length >= minLengthValue, errMessage)
}

export function needToContainsNumeric(errMessage: string) {
    return create<string>((value) => /\d/.test(value), errMessage)
}

export function needToNonAlphabeticalCharacters(errMessage: string) {
    return create<string>((value) => /[^a-zA-Zа-яёА-ЯЁ0-9]/.test(value), errMessage)
}

export function needToCapitalCharacters(errMessage: string) {
    return create<string>((value) => /[A-ZА-ЯЁ]/.test(value), errMessage)
}

export function needToLowercaseCharacters(errMessage: string) {
    return create<string>((value) => /[a-zа-яё]/.test(value), errMessage)
}
