import locale from '~/utils/locale'

export default locale.create<{
    orderText: string
    creditOrderTitle: string
    newOrderTitle: string
    confirmButton: string
    cancelButton: string
    accept: string
    acceptConfirmationTitle: string
    acceptNotificationTitle: string
    acceptFailedNotificationTitle: string
    acceptNotificationDescription: string
    rejectReason: string
    noDataLabel: string
}>()
