import { action, computed, observable, reaction, runInAction } from 'mobx'
import { PageSize } from '~/api/contracts'
import config from '~/config'
import { GenerateOrderBody } from '~/pages/products/credit/components/create-modal-config'
import { confirmCreditOrder, CreditOrder, CreditOrderStatus, UpdateCreditOrder } from '~/api/products'
import { ReactNode } from 'react'
import constants from './constants'
import message from '~/utils/message'
import { Modal, notification } from '~/components'
import res from './res'
import { OrderRejectBody } from '~/pages/products/credit/components/reject-modal-config'
import { getProfile } from '~/api'
import BrowserStore from '~/services/browser/browser-state'
import InteractiveMobileHintsStore from '~/components/interactive-mobile-hints/interactive-mobile-hints-store'
import InteractiveHint from '~/utils/interactive-hint'

interface ColumnPref {
    field: keyof CreditOrder
    visible: boolean
    width?: number
    title: ReactNode
}

const lastDefaultVisibleColumn = 6

export default class CreditProductsStore {
    constructor() {
        this.setup()

        reaction(
            () => this.creditOrdersGridColumn.map(col => col.field),
            () => this.normalizeWidths(),
            {
                fireImmediately: true
            }
        )

        setTimeout(() => {
            this.showHintOnMobile()
        }, 500)
    }

    @observable
    public creditOrders = []

    @observable
    public profile: any

    @observable
    public updateCreditOrder: CreditOrder

    @observable
    public rejectStatusId: string

    @observable
    public creditOrderStatuses = []

    @observable
    public creditOrdersLoading: boolean

    @observable
    public creditOrderUpdated: boolean

    @observable
    public pageInfo: {
        from: number
        to: number
        total: number
    }

    @observable
    public pageSize = 5

    @observable
    public pageStart = 5

    @observable
    public showOrderConfig: boolean

    @observable
    public showOrderRejectConfig: boolean

    @observable
    public showOrderConfirmation: boolean

    @observable
    public showOrderRejectConfirmation: boolean

    @observable
    public orderUpdating: boolean = false

    @observable
    public columns: ColumnPref[]

    @observable
    public pageIndex = 0

    @observable.ref
    public mobileHintStore: InteractiveMobileHintsStore

    @action
    public showHints(sectionKeys: string[]) {
        this.mobileHintStore = new InteractiveMobileHintsStore(sectionKeys)
    }

    @action
    public hideHints() {
        this.mobileHintStore = null
    }

    @action.bound
    public async rejectOrder(
        updatedOrderBody: OrderRejectBody
    ): Promise<UpdateCreditOrder> {

        const confirmCreditOrderRequest = await confirmCreditOrder(updatedOrderBody)

        if (confirmCreditOrderRequest) {
            runInAction(() => {
                this.getCreditOrdersFromAPI()
            })
        }

        const resJSON = await confirmCreditOrderRequest.json()
        return resJSON
    }

    @action.bound
    public goFirstPage() {
        this.pageIndex = 0

        runInAction(() => {
            this.getCreditOrdersFromAPI()
        })
    }

    @action.bound
    public goPrevPage() {
        if (this.pageIndex > 0) {
            this.pageIndex--

            runInAction(() => {
                this.getCreditOrdersFromAPI()
            })
        }
    }

    @action.bound
    public goNextPage() {
        if (!this.pageInfo || this.pageIndex >= this.lastPage) return

        this.pageIndex++

        runInAction(() => {
            this.getCreditOrdersFromAPI()
        })
    }

    @action.bound
    public goLastPage() {
        if (!this.pageInfo) return

        this.pageIndex = this.lastPage

        runInAction(() => {
            this.getCreditOrdersFromAPI()
        })
    }

    @action.bound
    public async getCreditOrdersFromAPI() {

        this.creditOrdersLoading = true
        const { data } = JSON.parse(localStorage.getItem('auth'))
        const response = await fetch(`${config.api.baseUrl}application?page=${this.pageIndex}&size=${this.pageSize}`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${data.access_token}`
            })
        })
        if (response.ok) {
            const body = await response.json()
            if (body) {
                runInAction(() => {
                    if (body.count > 0) {
                        this.creditOrders = body.data.map((order, index) => {
                            if (order.applications.length > 0) {
                                order.applications.map((application, index) => {
                                    if (application.shops?.length > 0) {
                                        application.creditConditions = application.shops[0].creditConditions
                                    }

                                })
                            }
                            return order
                        })
                        this.setPageInfo(body)
                        this.creditOrdersLoading = false
                    }
                })
            } else {
                message.error({ error: 'Error' })
            }
        } else {
            message.error({ error: 'Error' })
        }
    }

    @action.bound
    public async getCreditOrdersScroll() {

        this.creditOrdersLoading = true
        const { data } = JSON.parse(localStorage.getItem('auth'))
        const response = await fetch(`${config.api.baseUrl}application?size=${this.pageStart}`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${data.access_token}`
            })
        })
        if (response.ok) {
            const body = await response.json()
            if (body) {
                runInAction(() => {
                    if (body.count > 0) {
                        this.creditOrders = body.data.map((order, index) => {
                            if (order.applications.length > 0) {
                                order.applications.map((application, index) => {
                                    if (application.shops?.length > 0) {
                                        application.creditConditions = application.shops[0].creditConditions
                                    }

                                })
                            }
                            return order
                        })
                        this.pageStart = this.pageStart + 5
                        this.setPageInfo(body)
                        this.creditOrdersLoading = false
                    }
                })
            } else {
                message.error({ error: 'Error' })
            }
        } else {
            message.error({ error: 'Error' })
        }
    }

    @action
    public async loadProfile() {
        const response = await getProfile()
        if (response.result?.merchant?.companyName) {
            this.profile = response.result.merchant.companyName
        }
    }

    @action.bound
    public async getCreditOrderStatusFromAPI() {
        const { data } = JSON.parse(localStorage.getItem('auth'))
        const response = await fetch(`${config.api.baseUrl}application/statuses`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${data.access_token}`
            })
        })
        if (response.ok) {
            const data = await response.json()
            if (data) {
                runInAction(() => {
                    this.creditOrderStatuses = data
                })
            } else {
                message.error({ error: 'Error' })
            }
        } else {
            message.error({ error: 'Error' })
        }
    }


    public get creditOrdersGridColumn() {
        return this.columns.filter(x => x.visible)
    }

    @action
    public setPageInfo(value) {
        let toValue: number = 0
        let fromValue: number
        const skipValue = this.pageIndex * this.pageSize

        if (this.pageIndex === 0) {
            fromValue = 1
        } else {
            fromValue = skipValue + 1
        }

        if (value.count > skipValue + this.pageSize) {
            toValue = skipValue + this.pageSize
        } else {
            toValue = value.count
        }

        this.pageInfo = {
            from: fromValue,
            to: toValue,
            total: value.count
        }
    }

    @action.bound
    public toggleOrderConfigForm = () => {
        this.showOrderConfig = !this.showOrderConfig
        if (!this.showOrderConfig) {
            this.showOrderConfirmation = false
            runInAction(() => this.reload())
        }
    }

    @action
    public setUpdateOrder(creditOrders) {
        this.updateCreditOrder = creditOrders[creditOrders.length - 1]
        this.rejectStatusId = this.creditOrderStatuses.filter(status => status.name === CreditOrderStatus.IN_PROGRESS)[0].id
        this.toggleOrderRejectConfigForm()

    }
    @action.bound
    public toggleOrderRejectConfigForm = () => {
        this.showOrderRejectConfig = !this.showOrderRejectConfig
        if (!this.showOrderRejectConfig) {
            this.showOrderRejectConfirmation = false
            runInAction(() => this.reload())
        }
    }

    @action.bound
    public async reload() {
        await this.getCreditOrdersFromAPI()
        runInAction(() => this.pageStart = 5)
    }

    @action.bound
    public setColumnsOrder(
        fields: Array<{ field: keyof CreditOrder; visible: boolean }>
    ) {
        const map = this.columnMap

        this.columns = fields.map(col => {
            const column = map.get(col.field)

            column.visible = col.visible

            return column
        })
    }

    @action.bound
    public setPageSize(size: PageSize) {
        this.pageIndex = Math.floor((this.pageSize * this.pageIndex) / size)

        this.pageSize = size
    }

    @action.bound
    public showOrderAccept = (
        creditOrders: CreditOrder[]
    ) => {
        const updateOrder = {
            ...creditOrders[creditOrders.length - 1],
            shopIds: []
        }
        const shops = updateOrder.shops
        updateOrder.statusId = this.creditOrderStatuses.filter(status => status.name === CreditOrderStatus.ACCEPT)[0].id

        if (shops.length > 0) {
            updateOrder.shops.map(shop => {
                updateOrder.shopIds.push(shop.id)
            })

            if (shops[0].creditConditions.length > 0) {
                updateOrder.creditConditions = shops[0].creditConditions
            }

            delete updateOrder.shops
        }

        Modal.confirm({
            okText: res().accept,
            title: res().acceptConfirmationTitle,
            async onOk() {
                const confirmCreditOrderRequest = await confirmCreditOrder(updateOrder)

                if (confirmCreditOrderRequest) {
                    notification.success({
                        message: res().acceptNotificationTitle,
                        description: res().acceptNotificationDescription
                    })
                } else {
                    notification.error({
                        message: res().acceptFailedNotificationTitle
                    })
                }
            },
            onCancel() {
                return
            }
        })
    }

    @action.bound
    public async generateOrder(
        generatedOrderBody: GenerateOrderBody
    ): Promise<CreditOrder> {
        const { data } = JSON.parse(localStorage.getItem('auth'))

        if (this.profile) {
            generatedOrderBody.merchantName = this.profile
        }
        const response = await fetch(`${config.api.baseUrl}application`, {
            method: 'POST',
            headers: new Headers({
                Authorization: `Bearer ${data.access_token}`,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(generatedOrderBody)
        })

        const resJSON = await response.json()

        if (!response.ok) {
            throw new Error(resJSON.message)
        }

        return resJSON
    }

    @computed
    private get lastPage() {
        const n = this.pageInfo.total / this.pageSize
        return Math.floor(n) - (n % 1 === 0 ? 1 : 0)
    }

    private async setup() {
        this.columns = constants
            .fields(this)
            .get()
            .map((item, index) => {
                return {
                    field: item.field,
                    width: +item.width,
                    visible:
                        index <= lastDefaultVisibleColumn,
                    title: item.title
                }
            })
    }

    private normalizeWidths() {
        const width = this.creditOrdersGridColumn.reduce(
            (sum, col) => sum + (col.width || 10),
            0
        )

        if (width !== 100) {
            this.creditOrdersGridColumn.forEach(
                col => (col.width = (100 * (col.width || 10)) / width)
            )
        }
    }

    @computed
    private get columnMap() {
        return new Map(
            this.columns.map((col): [keyof CreditOrder, ColumnPref] => [
                col.field,
                col
            ])
        )
    }

    private showHintOnMobile() {
        if (BrowserStore.isMobile) {
            const hintSections = InteractiveHint.getPageFlags('credit')

            if (hintSections) {
                this.showHints(hintSections)
            }
        }
    }
}
