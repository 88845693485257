import { IdleTimer } from 'multitab_idle_timer'

export interface IdleTimerConfig {
    heartbeat?: number
    warnBefore?: number
    timeout: number
    store?: string
    onActive?: (timerObject?: any) => void
    onwarn?: (timerObject?: any) => void
    onIdle?: (timerObject?: any) => void
}

export interface IdleTimerManagerConfig {
    onActive?: (timerObject?: any) => void
    onWarn?: (timerObject?: any) => void
    onIdle?: (timerObject?: any) => void
    onHeartBeat?: (countDown: number) => void
}

export default class IdleTimerManager {

    public static getInstance(managerConfig?: IdleTimerManagerConfig, config?: IdleTimerConfig): IdleTimerManager {

        if (this.instance == null) {
            this.instance = new IdleTimerManager(managerConfig, config)
        } else {
            if (managerConfig) {
                this.instance.managerConfig = managerConfig
            }
            if (config) {
                this.instance.config = config
            }
        }
        return this.instance
    }

    private constructor(managerConfig: IdleTimerManagerConfig, config?: IdleTimerConfig) {

        this.config = config ? config : {} as IdleTimerConfig

        if (managerConfig) {
            this.managerConfig = managerConfig
        }

        this.config.onActive = this.onActive
        this.config.onwarn = this.onWarn
        this.config.onIdle = this.onIdle

        this.createIdleTimer()
        this.stop()
    }

    public get isExpired(): boolean {
        return this.idleTimer.isExpired()
    }

    public reset() {
        this.createIdleTimer()
    }

    public stop() {
        this.idleTimer.stop()
    }

    private get onActive(): (timerObject?: any) => void {

        if (this.managerConfig && this.managerConfig.onActive) {
            return this.managerConfig.onActive
        }

        // tslint:disable-next-line:no-console
        return () => { console.log('empty action onActive') }
    }

    private get onWarn(): (timerObject?: any) => void {

        if (this.managerConfig && this.managerConfig.onWarn) {
            return (timerObject?: any) => {
                this.idleTimer._warned = true
                return this.managerConfig.onWarn(timerObject)
            }
        }

        // tslint:disable-next-line:no-console
        return () => { console.log('empty action onWarn') }
    }

    private get onIdle(): (timerObject?: any) => void {

        if (this.managerConfig && this.managerConfig.onIdle) {
            return this.managerConfig.onIdle
        }

        // tslint:disable-next-line:no-console
        return () => { console.log('empty action onIdle') }
    }

    private createIdleTimer(): void {

        if (this.idleTimer) {
            this.idleTimer.stop()
        }

        this.idleTimer = new IdleTimer(this.config)

        this.idleTimer.init()
    }

    private static instance: IdleTimerManager = null

    private config: IdleTimerConfig

    private managerConfig: IdleTimerManagerConfig

    private idleTimer: IdleTimer
}
