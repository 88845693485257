import React from 'react'

import { action, observable } from 'mobx'

import { observer } from 'mobx-react'

import { Button, IconButton, Modal, Radio } from '../index'

import layoutStyles from '~/layouts/main/styles.css'

import styles from './styles.css'

import globalRes from '~/res'
import res from './res'

import { Icon } from 'antd'

import BrowserStore from '~/services/browser/browser-state'

import EditableFieldsList, { ItemField } from '../editable-fields-list'

import { ArrowLeftIcon, CheckIcon, CloseModalIcon } from '../icon'

import ModalContainer from '../modal/modal-container'

import MobileHeader from '~/components/modal/mobile-header'

interface FieldsConfigFormProps {
    title?: React.ReactNode
    values: ItemField[]
    pageSize?: number
    visible: boolean
    onClose: () => void
    fieldsSettingsSubtitle?: React.ReactNode
    sortable?: boolean
    customizablePageSize?: boolean
    onSetPageSize?: (size: number) => void
    onSetFields: (fields: Array<{ field: string; visible: boolean }>) => void
    comtainerRef?: any
    isMobile?: boolean
    fieldsSettingsVisable?: boolean
}

@observer
class FieldsConfigForm extends React.Component<FieldsConfigFormProps, {}> {
    public modalContentRef

    @action
    public componentWillReceiveProps(nextProps: FieldsConfigFormProps) {
        if (!this.props.visible && nextProps.visible) {
            this.fields = [...nextProps.values]
            this.fieldsOrder = this.fields.map(item => item.field)
            this.pageSize = nextProps.pageSize
        }
    }
    public getHeader = (title, onClose) => {
        return (
            <React.Fragment>
                <div className={layoutStyles.title}>{title || res().title}</div>
                {!BrowserStore.isMobile && (
                    <IconButton
                        className={`${layoutStyles.closeButton}`}
                        icon={CloseModalIcon}
                        onClick={onClose}
                        dataSemanticId="close-form"
                        tooltipTitle={globalRes().close}
                    />
                )}
            </React.Fragment>
        )
    }
    public render() {
        const {
            visible,
            title,
            onClose,
            customizablePageSize,
            sortable,
            fieldsSettingsSubtitle,
            fieldsSettingsVisable = true
        } = this.props

        const sizes = [20, 50, 100]

        const headerControl = this.getHeader(title, onClose)

        return (
            <ModalContainer
                onClose={onClose}
                className={`${layoutStyles.modal} ${styles.container}`}
                visible={visible}
                closable={false}
                footer={null}
                headerControl={
                    <MobileHeader
                        title={title}
                        onOk={() => this.applyChanges()}
                        onCancel={onClose}
                    ></MobileHeader>
                }
                contentRef={this.modalContentRef}
                modalClass="hint-section-4-step-3"
            >
                <div ref={ref => (this.modalContentRef = ref)}>
                    <div data-semantic-id="modal">
                        {!BrowserStore.isMobile && (
                            <div>
                                <div className={layoutStyles.contentHeader}>
                                    {headerControl}
                                </div>
                            </div>
                        )}
                        <div className={layoutStyles.contentMain}>
                            {customizablePageSize
                                ? [
                                    <div key="page_size_pane">
                                        {res().rowsCount}
                                        <Radio.Group
                                            value={this.pageSize}
                                            onChange={e =>
                                                this.handlePageSizeChange(
                                                    e.target.value
                                                )
                                            }
                                            buttonStyle="solid"
                                            className={
                                                styles.rowsCountRadioGroup
                                            }
                                        >
                                            {sizes.map(size => (
                                                <Radio.Button
                                                    value={size}
                                                    key={`size_${size}`}
                                                >
                                                    {size}
                                                </Radio.Button>
                                            ))}
                                        </Radio.Group>
                                    </div>,
                                    <hr key="page_size_separator" />
                                ]
                                : null}
                            {fieldsSettingsVisable &&
                                <>
                                    <div className={layoutStyles.contentSubtitle}>
                                        {fieldsSettingsSubtitle || res().fieldsSettings}
                                    </div>
                                    <EditableFieldsList
                                        fields={this.fields}
                                        className={styles.fields}
                                        fieldsOrder={this.fieldsOrder}
                                        onFieldsOrderChange={
                                            this.handleFieldsOrderChange
                                        }
                                        sortable={sortable}
                                    />
                                </>
                            }

                        </div>
                        <div
                            className={`${layoutStyles.contentFooter} ${styles.fieldConfigFooter}`}
                        >
                            {!BrowserStore.isMobile && (
                                <React.Fragment>
                                    <Button
                                        type="primary"
                                        size="large"
                                        onClick={() => this.applyChanges()}
                                    >
                                        {res().applyButton}
                                    </Button>
                                    <Button size="large" onClick={onClose}>
                                        {res().cancelButton}
                                    </Button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </ModalContainer>
        )
    }

    @action.bound
    private handlePageSizeChange(pageSize: number) {
        this.pageSize = pageSize
    }

    @action.bound
    private handleItemVisibilityChange(field, isVisible: boolean) {
        this.fields.find(item => item.field === field).visible = isVisible
    }

    @action.bound
    private handleFieldsOrderChange(order: string[]) {
        this.fieldsOrder = order
    }

    @action.bound
    private applyChanges() {
        const { props } = this

        this.fields = this.fieldsOrder.map(itemKey =>
            this.fields.find(item => item.field === itemKey)
        )

        props.onSetFields(this.fields)
        props.customizablePageSize && props.onSetPageSize(this.pageSize)

        this.props.onClose()
    }

    @observable
    private pageSize?: number

    @observable
    private fields?: ItemField[]

    @observable
    private fieldsOrder?: string[]
}

export default FieldsConfigForm
