import React from 'react'

import { observer } from 'mobx-react'

import { IconButton } from '~/components'
import { CloseModalIcon } from '~/components/icon'
import layoutStyles from '~/layouts/main/styles.css'

import { DetailedField, DetailedFieldsGroup } from '../../types'

import styles from './styles.css'

import globalRes from '~/res'
import BrowserStore from '~/services/browser/browser-state'
import res from './res'

interface PaymentDetailedFormProps<T> {
    visible: boolean
    onClose: () => void
    payment: T
    paymentReference?: string
    actionsPane?: React.ReactNode
    fields: Array<DetailedFieldsGroup<T>>
}

export default observer(function PaymentDetailedForm<T>(
    props: PaymentDetailedFormProps<T>
) {
    const { payment } = props

    const renderField = (fieldInfo: DetailedField<T>, isLast: boolean) => {
        const { field } = fieldInfo
        const value =
            field !== 'geolocationText' && field !== 'geolocationMap'
                ? props.payment[field]
                : null

        const showSeparator = field !== 'geolocationMap'

        if (
            !fieldInfo ||
            (typeof fieldInfo.visibility !== 'undefined' &&
                !fieldInfo.visibility(value, payment))
        ) {
            return null
        }

        return [
            <tr
                key={`field_${field}`}
                className={`${styles.field} ${fieldInfo.withoutBorder ? styles.withoutBorder : ''
                    }`}
            >
                {!fieldInfo.wide && (
                    <td className={styles.label}>{fieldInfo.title}:</td>
                )}
                <td colSpan={fieldInfo.wide ? 2 : 1}>
                    {value || field === 'geolocationMap'
                        ? renderFieldValue(fieldInfo, value)
                        : '-'}
                </td>
            </tr>,
            isLast && showSeparator ? (
                <tr
                    key={'subgroup_separator'}
                    className={styles.subgroupSeparator}
                >
                    <td colSpan={2}>&nbsp;</td>
                </tr>
            ) : null
        ]
    }

    const renderFieldValue = (fieldInfo: DetailedField<T>, value: any) => {
        if (typeof fieldInfo.render !== 'undefined') {
            return fieldInfo.render(value, payment)
        }

        return value
    }
    return (
        <React.Fragment>
            {!BrowserStore.isMobile && (
                <div className={styles.headerWrapper}>
                    <div className={styles.header}>
                        <div
                            className={styles.title}
                            data-semantic-id="title-payment-datails"
                        >
                            {res().title(props.paymentReference)}
                        </div>
                        {props.actionsPane &&
                            <div className={styles.actionsContainer}>
                                {props.actionsPane}
                            </div>
                        }
                        <IconButton
                            className={layoutStyles.closeButton}
                            icon={CloseModalIcon}
                            onClick={props.onClose}
                            id="close-payment-datails-icon"
                            tooltipTitle={globalRes().close}
                            tooltipPlacement="bottom"
                        />
                    </div>
                </div>
            )}
            <div className={styles.fields}>
                <table>
                    <tbody>
                        {props.visible &&
                            props.fields.map(group => [
                                group.title ? (
                                    <tr
                                        className={styles.groupTitle}
                                        key={`fields_group_${group.id}`}
                                    >
                                        <td colSpan={2}>{group.title}</td>
                                    </tr>
                                ) : null,
                                group.subgroups.map(subgroup =>
                                    subgroup.map((field, index) => {
                                        return renderField(
                                            field,
                                            index === subgroup.length - 1
                                        )
                                    })
                                )
                            ])}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
})
