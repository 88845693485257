import React from 'react'

import { formatLongNumber, formatSum } from '~/utils'

import _ from 'lodash'
import moment from 'moment'
import { UnsuccessfulTransactionsDataItem } from '~/pages/dashboard/types'
import res from '../../../res'
import styles from '../../../styles.css'
interface TransactionDataItem {
    payload: UnsuccessfulTransactionsDataItem
    stroke: string
    value: number
    name: string
    id: string
}

const TooltipContent = ({ payload }: { payload: any }) => {
    if (!payload || payload.length === 0) {
        return null
    }

    const title = moment(payload[0].payload.date).format('DD.MM.YYYY')
    return <UnsuccessfulTransactionsChartTooltip title={title} data={payload} />
}

const UnsuccessfulTransactionsChartTooltip = ({
    title,
    data
}: {
    title: string
    data: TransactionDataItem
    showTotal?: boolean
}) => {

    const totalAmount = data[0].payload.totalAmount
    const totalCount = data[0].payload.totalCount

    return (
        <div className={styles.tooltip}>
            <table>
                <tbody>
                    <tr className={styles.title}>
                        <td>{res().tooltip.date}:</td>
                        <td className={styles.countColumn}>
                            {title}
                        </td>
                    </tr>
                    <tr className={styles.title}>
                        <td>{res().tooltip.count}:</td>
                        <td className={styles.countColumn}>
                            {formatSum(totalCount)}
                        </td>
                    </tr>
                    <tr className={styles.title}>
                        <td>{res().tooltip.sum}:</td>
                        <td className={styles.countColumn}>
                            {formatLongNumber(totalAmount)}
                        </td>
                    </tr>
                </tbody>
                {/*<tbody>*/}
                {/*    <tr className={styles.title}>*/}
                {/*        <td className={styles.headColumn}>{title}</td>*/}
                {/*        <td className={styles.buff}></td>*/}
                {/*    </tr>*/}

                {/*    <tr>*/}
                {/*        <td className={styles.buff}></td>*/}
                {/*    </tr>*/}
                {/*    {data.map((item, key) => {*/}
                {/*        return (*/}
                {/*            <tr key={key} className={styles.field}>*/}
                {/*                <td className={styles.titleColumn}>*/}
                {/*                    <li style={{ color: item.stroke }}>*/}
                {/*                        <span className={styles.fieldName}>*/}
                {/*                            {res()[item.name]}*/}
                {/*                        </span>*/}
                {/*                    </li>*/}
                {/*                </td>*/}
                {/*                <td className={styles.countColumn}>*/}
                {/*                    {formatSum(item.value)}*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*        )*/}
                {/*    })}*/}
                {/*</tbody>*/}
            </table>
        </div>
    )
}

export default UnsuccessfulTransactionsChartTooltip

export { TooltipContent }
