import { action, observable, reaction, runInAction, toJS } from 'mobx'
import React from 'react'
import res from './res'
import { faqs } from './data/faqs'
import FieldStore from '~/utils/field-store'
import { required } from '~/utils/validation'
import locale from '~/utils/locale'
import { Question } from '~/api/faq'

export default class FaqStore {

    constructor() {
        this.loadData()
        reaction(() => locale.lang, () => this.loadData())
    }

    @observable
    public keyword = new FieldStore<string>([], this.searchByKeyword)

    @observable
    public data: Question[]

    @observable
    public filteredData: any

    @observable
    public isVisibleHintsModal: boolean = false

    @observable
    public interactiveHint: any = null

    public async loadData() {
        runInAction(() => {
            this.data = toJS(faqs[locale.lang])
            this.filteredData = this.data
        }
        )
    }

    @action.bound
    public searchByKeyword(e) {

        const res = this.data.filter((q) => {
            return q.title.toLowerCase().includes(e.target.value)
        })

        runInAction(() => {
            this.keyword.value = e.target.value
            this.filteredData = res
        })

    }

    @action.bound
    public showHintModal() {
        this.isVisibleHintsModal = true
    }

    @action.bound
    public hideHintModal() {
        this.isVisibleHintsModal = false
    }

    @action
    public setInteractiveHint(hint: any) {
        this.interactiveHint = hint
    }

}
