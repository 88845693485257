import { PaymentLinkStatus } from '~/api/payment-links'
import locale from '~/utils/locale'

export default locale.create<{
    paymentLinksTitle: string
    generateButtonTitle: string
    sortBy: string
    ranges: {
        today: string
        yesterday: string
        thisWeek: string
        priorWeek: string
        thisMonth: string
        priorMonth: string
        thisQuarter: string
        customPeriod: string
    }
    linkStatuses: {
        [key in PaymentLinkStatus]: string
    }
    dateFilterLabel: string
    savedText: string
}>()
