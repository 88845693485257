import React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import { Avatar } from 'antd'

import { Dropdown, Layout, Menu, NavLink } from '~/components'
import {
    LogoutIcon,
    MenuFoldIcon,
    MenuUnfoldIcon,
    UserIcon
} from '~/components/icon'

import actions from '~/actions'
import AppStore from '~/app-store'
import auth from '~/services/auth'

import LayoutStore from './layout-store'

import res from './res'

import BrowserStore from '~/services/browser/browser-state'
import styles from './header.css'
import LanguageSelectAuthorized from '~/components/language-select-authorized'

export const userMenu = computed(() => (
    <Menu>
        <Menu.Item key="profile" onClick={() => actions.openProfile()}>
            <UserIcon />
            {res().profile}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="signOut" onClick={() => auth.logout()}>
            <LogoutIcon />
            {res().logOut}
        </Menu.Item>
    </Menu>
))

const Header = observer(
    (props: { appStore: typeof AppStore; layoutStore: typeof LayoutStore }) => {
        const { layoutStore } = props

        return (
            <Layout.Header className={styles.header}>
                <div
                    className={styles.wrapper}
                    ref={el => layoutStore.setHeaderComponent(el)}
                >
                    {!BrowserStore.isMobile && (
                        <div>
                            <div
                                className={`${styles.button} ${styles.menu}`}
                                onClick={() => {
                                    layoutStore.toggleSider()
                                }}
                            >
                                {layoutStore.isSiderVisible ? (
                                    <MenuFoldIcon />
                                ) : (
                                    <MenuUnfoldIcon />
                                )}
                            </div>
                            <NavLink href="/" className={styles.logo} />
                        </div>
                    )}
                    <div>
                        <span className={`${styles.button} ${styles.mr0}`}>
                            <LanguageSelectAuthorized />
                        </span>
                        <span className={`${styles.button} hint-section-1-step-3`}>
                            <Dropdown
                                overlay={userMenu.get()}
                                placement="bottomRight"
                                trigger={['click']}
                            >
                                <Avatar icon="user" />
                            </Dropdown>
                        </span>
                    </div>
                </div>
            </Layout.Header>
        )
    }
)

export default Header
