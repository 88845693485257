import crc32 from 'crc/lib/crc32'

import config from '~/config'

import {
    ClientTokenResponse,
    LoginResponse,
    RefreshTokenRequest
} from '~/api/auth/contracts'

import { noThrow } from '~/utils/control-flow'

import { get, setWithMutex, StorageType } from '~/services/storage'

import { debounce } from 'lodash'
import { THREAD_ID } from '~/app-store'
import authStore from '~/services/auth'
import { UserInfo } from './auth-store'
import * as errors from './errors'

export interface AuthData {
    data: LoginResponse
    expiresAt: number
}

export function hashCode(str: string) {
    return crc32(str).toString(36)
}

export function saveClientAuth(appRegistrationResponse: ClientTokenResponse) {
    return setWithMutex(
        config.auth.client.storageKey,
        appRegistrationResponse,
        { useUserHash: false }
    )
}

export function getClientAuth() {
    return get<ClientTokenResponse>(config.auth.client.storageKey, {
        useUserHash: false
    })
}

export function saveAuth(auth: AuthData) {
    return setWithMutex(config.auth.user.storageKey, auth, {
        useUserHash: false
    })
}

export function saveUserInfo(userInfo: UserInfo) {
    const uInfo: UserInfo = {
        ...userInfo,
        userHash: hashCode(userInfo.userLogin)
    }
    return setWithMutex(config.auth.user.userInfoKey, uInfo, {
        useUserHash: false
    })
}

export function getUserInfo() {
    const userInfo = get<UserInfo>(config.auth.user.userInfoKey, {
        useUserHash: false
    })
    return userInfo ? userInfo : { userLogin: 'anonymous', userHash: 0 }
}

export function getAuth() {
    const result = get<AuthData>(config.auth.user.storageKey, {
        useUserHash: false
    })

    if (!result) throw errors.authNotExisted

    const { data } = result

    if (
        !data ||
        !data.token_type ||
        !data.access_token ||
        !data.refresh_token ||
        !result.expiresAt
    ) {
        throw errors.authDataCorrupted
    }
    return result
}

export function cleanAuth() {
    return setWithMutex(config.auth.user.storageKey, undefined, {
        useUserHash: false
    })
}

export function isExpired(auth: AuthData) {
    return new Date().valueOf() > auth.expiresAt
}

export function isPasswordExpired(errorObj: Error) {
    // @ts-ignore
    return errorObj.hasOwnProperty('code') && errors.codePasswordExpired === errorObj.code
}

export function isAuthorizationValid() {
    return !noThrow(() => {
        if (isExpired(getAuth())) throw errors.tokenExpired
    }).error
}

export interface InstanceInfoInterface {
    instanceId: string
}

export function isExistActiveInstance(
    currentThreadId: string
): Promise<boolean> {
    const threadId = currentThreadId

    let eventHandler: (event: StorageEvent) => void

    return Promise.race<boolean>([
        new Promise<boolean>((resolve, reject) => {
            let eventRecieved = false

            eventHandler = (event: StorageEvent) => {
                if (event.key === 'instanceWatcher') {
                    eventRecieved = event.newValue !== threadId
                    if (eventRecieved) {
                        window.removeEventListener('storage', eventHandler)
                        resolve(true)
                    }
                }
            }

            window.addEventListener('storage', eventHandler)

            localStorage.setItem('instanceCaller', threadId)
        }),

        new Promise<boolean>((resolve, reject) => {
            setTimeout(() => {
                window.removeEventListener('storage', eventHandler)
                resolve(false)
            }, 1000)
        })
    ])
}
