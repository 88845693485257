import { observer } from 'mobx-react'
import { Component, Validator } from 'react'
import React from 'react'
import { Button, IconButton, message, Spin } from '~/components'

import { action, observable, when } from 'mobx'
import { CloseModalIcon } from '~/components/icon'

import { ModalContainerContext } from '~/components/modal/modal-context'
import layoutStyles from '~/layouts/main/styles.css'
import globalRes from '~/res'
import BrowserStore from '~/services/browser/browser-state'
import res from './res'
import styles from './styles.css'
import CreateOrderForm from './CreateOrderForm/'
import { CreditOrder } from '~/api/products'
import ConfirmationPage from './ConfirmationPage'

export interface GenerateOrderBody {
    shopIds: any[]
    emails: any[]
    statusId: string
    statusName: string
    moderatorComment: string
    accountVerified: boolean
    verifiedBy: string
    checkingAccount: string
    shops: any[]
    merchantName: string
}

export interface GenerateOrderResponse {
    code: string
    message: string
}

export interface MerchantShop {
    id: string
    name: string
    contractNumber: string
}

interface GenerationFormProps {
    title?: React.ReactNode
    onClose: () => void
    onApply: (requestBody: GenerateOrderBody) => Promise<CreditOrder>
    modalContext?: ModalContainerContext
    orderUpdating?: boolean
}

interface GenerationFormState {
    order?: GenerateOrderBody
    createdCreditOrder: CreditOrder | null
    isGenerated: boolean
    hasError: boolean,
    formValid: boolean
    errorMessage: string
}


@observer
export default class GenerationForm extends Component<
GenerationFormProps,
GenerationFormState
> {
    constructor(props: GenerationFormProps) {
        super(props)
        this.state = {
            createdCreditOrder: null,
            formValid: false,
            order: {
                shopIds: [],
                emails: [],
                statusId: '',
                statusName: '',
                moderatorComment: '',
                accountVerified: false,
                verifiedBy: '',
                checkingAccount: '',
                shops: [],
                merchantName: ''
            },
            isGenerated: false,
            hasError: false,
            errorMessage: ''
        }
    }

    @action
    public async componentDidMount() {
        try {
            if (this.props.modalContext) {
                const { title, onClose } = this.props
                const component = this
                this.props.modalContext.modalController.setTitle(
                    <div className={styles.mobileHeader}>
                        <div className={layoutStyles.title}>
                            {title || res().title}
                        </div>
                        <IconButton
                            className={`${styles.closeButton}`}
                            icon={CloseModalIcon}
                            onClick={onClose}
                            id="close-statement-config-icon"
                            tooltipTitle={globalRes().close}
                        />
                    </div>
                )
            }
        } catch (error) {
            message.error(error)
        }
    }

    public render() {
        const { title, onClose, orderUpdating } = this.props
        const { createdCreditOrder } = this.state

        return (
            <>
                <Spin spinning={orderUpdating}>
                    {!BrowserStore.isMobile ? (
                        <div data-semantic-id="modal">
                            {this.state.isGenerated ? (
                                <ConfirmationPage
                                    onClose={onClose}
                                    createdCreditOrder={createdCreditOrder}
                                />
                            ) : (
                                <div>
                                    <div>
                                        <div className={layoutStyles.contentHeader}>
                                            <div className={layoutStyles.title}>
                                                {title || res().title}
                                            </div>

                                            <IconButton
                                                className={`${layoutStyles.closeButton} ${styles.closeButton}`}
                                                icon={CloseModalIcon}
                                                onClick={onClose}
                                                id="close-statement-config-icon"
                                                tooltipTitle={globalRes().close}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.subtitle}>
                                            {title || res().description}
                                        </div>
                                        <CreateOrderForm
                                            onApply={this.props.onApply}
                                            onClose={onClose}
                                            showConfirmation={this.showConfirmation}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div data-semantic-id="modal">
                            {this.state.isGenerated ? (
                                <ConfirmationPage
                                    onClose={onClose}
                                    createdCreditOrder={createdCreditOrder}
                                />
                            ) : (
                                <div>
                                    <div>
                                        <div className={styles.subtitle}>
                                            {title || res().description}
                                        </div>
                                        <CreateOrderForm
                                            onApply={this.props.onApply}
                                            onClose={onClose}
                                            showConfirmation={this.showConfirmation}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Spin>
            </>
        )
    }

    @action.bound
    public async showConfirmation(newOrder: CreditOrder) {
        this.setState({
            isGenerated: true,
            createdCreditOrder: newOrder
        })
    }

    @action.bound
    private setCanvasRef(ref) {
        this.canvasRef = ref
    }

    @observable
    private canvasRef: HTMLCanvasElement
}
