import { post } from '~/utils/ajax'
import { Channel, TransactionsDataItem } from '../../pages/dashboard/types'
import {
    BaseDashboardFields,
    DashboardByBanksRequest, DashboardByBanksResponse, DashboardByCartTypeResponse,
    DashboardByChannelsRequest,
    DashboardByChannelsResponse,
    DashboardTransactionByStatusData,
    DashboardTransactionsByStatusRequest,
    DashboardTransactionsByStatusResponse
} from './contracts'

export * from './contracts'
import _ from 'lodash'
import moment from 'moment'

export const getTransactionsByStatusStatistic = (request: BaseDashboardFields) => post<DashboardTransactionByStatusData>('dashboard/transaction/reports/by-status', request)
    .then(response => {

        const { result, error } = response

        if (error) return { error }

        const transactionsData = []

        if (result.statDataByStatus) {
            for (const timeline of result.statDataByStatus) {

                const date = moment(timeline.day).toDate()
                const totalCount = timeline.transactionCount
                const totalAmount = timeline.transactionAmountSum

                transactionsData.push({
                    date,
                    totalCount,
                    totalAmount
                })
            }
        }
        return { result: transactionsData }
    })

export const getChannelsStatistic = (request: DashboardByChannelsRequest) => post<DashboardByChannelsResponse>('dashboard/transaction/bychannels', request)
    .then(response => {
        // if (response.result && response.result.channels && response.result.channels.length > 0) {
        //     response.result.channels.forEach((item, index) => {
        //         if (item.channel === Channel.epay.toLowerCase()) {
        //             response.result.channels[index].channel = Channel.epay
        //         }
        //     })
        // }

        return response
    })

export const getTransactionsByBanksStatistic = (request: BaseDashboardFields) => post<DashboardByBanksResponse>('dashboard/transaction/reports/by-banks', request)
    .then(response => {
        const { result, error } = response

        return { result: result.statDataByBanks }
    })

export const getTransactionsByCartTypeStatistic = (request: BaseDashboardFields) => post<DashboardByCartTypeResponse>('dashboard/transaction/reports/by-card-type', request)
    .then(response => {
        const { result, error } = response

        return { result: result.statDataByCardType }
    })
