import React from 'react'

import styles from '~/layouts/auth/styles.css'

import res from './res'

res.add('ru', {
    signupTitle: 'Регистрация в системе',
    signupLink: 'Регистрация',
    setPasswordTitle: 'Установить пароль',
    loginTitle: 'Вход в систему',
    loginLink: 'Вход',
    passwordRecoveryTitle: 'Восстановление пароля',
    signUpAction: 'Зарегистрироваться',
    setPasswordAction: 'Установить',
    logInAction: 'Войти',
    sendAction: 'Отправить',
    cancel: 'Отменить',
    signupEmailPrompt: 'Ваш рабочий email адрес',
    noActivationEmail: 'Не получили письмо с подтверждением?',
    email: 'Email',
    password: 'Пароль',
    newPassword: 'Ваш новый пароль',
    passwordRepeat: 'Повторите пароль',
    passwordRestoreLink: 'Забыли пароль?',
    noAccount: 'Еще не зарегистрированы?',
    hasAccount: 'Уже зарегистрированы?',
    didNotReceiveEmail: 'Не пришло письмо?',
    repeatSignUpLink: 'Повторить регистрацию',
    emailRequired: 'Пожалуйста, введите ваш email адрес',
    passwordRequired: 'Пожалуйста, введите ваш пароль',
    passwordRepeatRequired: 'Пожалуйста, введите ваш пароль повторно',
    passwordsDoNotMatch: 'Введенные пароли не совпадают',
    successSignUp: 'Письмо с подтверждением было отправлено на ваш email адрес',
    passwordRecoveryInfo:
        'Введите email адрес, который вы указали при регистрации, на него будет отправлено письмо со ссылкой для смены пароля',
    accountCreatedTitle: 'Учетная запись создана',
    accountCreatedInfo: (email: string) => (
        <span>
            Для завершения регистрации, перейдите на{' '}
            <span className={styles.email}>{email}</span> и подтвердите свою
            почту
        </span>
    ),
    passwordSetTitle: 'Пароль успешно установлен',
    passwordRecoveredTitle:
        'Письмо с инструкциями по восстановлению пароля отправлено на ваш email адрес',
    passwordHelp: {
        title: 'Пароль должен содержать как минимум:',
        notContainsErrors: {
            numbers: '1 цифру',
            nonAlphabeticalCharacters: '1 неалфавитный символ',
            capitalCharacters: '1 заглавную букву',
            lowercaseCharacters: '1 строчную букву',
            minLength: 'символов',
            minLength8: '8 символов'
        }
    },
    invalidPassword: 'Неправильный формат пароля',
    continue: 'Продолжить',
    idleTimerTitle: 'Автоматическое завершение работы',
    idleTimerWarning: 'Вы не активны более 10 минут. Сессия завершиется через',
    idleTimerButtonContinue: 'Продолжить работу',
    idleTimerButtonStop: 'Завершить работу',
    passwordExpired: {
        title: 'Ваш пароль устарел',
        message: 'Вам необходимо восстановить пароль'
    }
})
