import React from 'react'
import { PaymentLinkStatus } from '~/api/payment-links'
import res from './res'

res.add('ru', {
    paymentLinksTitle: 'Ссылки на оплату',
    generateButtonTitle: 'Сформировать ссылку',
    sortBy: 'Сортировать по',
    ranges: {
        today: 'сегодня',
        yesterday: 'вчера',
        thisWeek: 'текущую неделю',
        priorWeek: 'прошлую неделю',
        thisMonth: 'текущий месяц',
        priorMonth: 'прошлый месяц',
        thisQuarter: 'текущий квартал',
        customPeriod: 'указанный период'
    },
    linkStatuses: {
        [PaymentLinkStatus.any]: 'Любой',
        [PaymentLinkStatus.active]: 'Активная',
        [PaymentLinkStatus.annulled]: 'Отменена',
        [PaymentLinkStatus.expired]: 'Просрочена',
        [PaymentLinkStatus.charged]: 'Списана'
    },
    dateFilterLabel: 'За период',
    savedText: 'Ура. ссылка сохранена в буфере обмена'
})
