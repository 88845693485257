import { ReactNode } from 'react'
import locale from '~/utils/locale'

export default locale.create<{
    title: string
    description: string
    confirmationTitle: (orderNumber: string) => ReactNode
    cancelButton: string
    sendButton: string
    closeButton: string
    commentPlaceholder: string
}>()
