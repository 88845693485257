import React from 'react'

import { Card } from 'antd'

import styles from './styles.css'

export { noDefaultStyles } from './styles.css'

import MobileToolbar from '~/components/toolbar/mobile-toolbar'

import BrowserStore from '~/services/browser/browser-state'

export interface PageProps {
    title?: React.ReactNode
    subtitle?: React.ReactNode
    controls?: React.ReactNode
    children: React.ReactNode
    footer?: React.ReactNode
    onBackClick?: () => void
    toolbar?: React.ReactNode
    toolbarClassName?: string
    contentClassName?: string
    contentHeaderClassName?: string
    titleClassName?: string
    onRef?: (element: HTMLElement) => void
    pageClassName?: string
}

export default (props: PageProps) => (
    <Card
        data-semantic-id="page"
        className={`${styles.pageContent} ${props.pageClassName ? props.pageClassName : ''
            } hint-page`}
        bordered={false}
    >
        <div ref={props.onRef}>
            <MobileToolbar
                controls={props.toolbar}
                onBackClick={props.onBackClick}
                className={props.toolbarClassName}
            ></MobileToolbar>
            {(props.title || props.subtitle || props.controls) && (
                <div
                    className={`${styles.contentHeader} ${props.contentHeaderClassName
                        ? props.contentHeaderClassName
                        : ''
                        }`}
                >
                    <div className={styles.titleCnt}>
                        <div
                            className={`${styles.title} ${props.titleClassName ? props.titleClassName : ''
                                }`}
                        >
                            {props.title}
                        </div>
                        {!props.subtitle ? (
                            undefined
                        ) : (
                            <div className={styles.subtitle}>
                                {props.subtitle}
                            </div>
                        )}
                    </div>
                    {!props.controls ? (
                        undefined
                    ) : (
                        <div className={styles.controls}>{props.controls}</div>
                    )}
                </div>
            )}
            <div
                className={`${styles.contentMain} ${props.contentClassName ? props.contentClassName : ''} mobile-hint-page-content`}
            >
                {props.children}
            </div>
            {!props.footer ? (
                undefined
            ) : (
                <div className={styles.contentFooter}>{props.footer}</div>
            )}
        </div>
    </Card>
)
