import styles from '~/pages/orders/create-order/styles.css'
import { Form, Radio, Tooltip } from 'antd'
import res from '~/pages/orders/create-order/res/res'
import { Button, InputItem, Select, Modal, Input } from '~/components'

const { Option } = Select
import { DeleteOutlined, ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import React from 'react'
import { observer } from 'mobx-react'
import CreateOrderStore from '~/pages/orders/create-order/create-order-store'
import actions from '~/actions'
import PhoneInput from 'react-phone-input-2'
import { FormComponentProps } from 'antd/lib/form'

interface CreateOrderStoreProps extends FormComponentProps {
    store: CreateOrderStore
}

const GeneralInformation = observer(({ store, form }: CreateOrderStoreProps) => {

    const { getFieldDecorator } = form

    return (
        <>
            {store.step === 1 && (
                <div className={`${styles.step} ${styles.stepActive}`}>
                    <Form onSubmit={(e) => store.submit(e, form)}>
                        <div className={styles.section}>
                            <div className={styles.subtitle}>
                                {res().labels.bin}
                                <Tooltip title="БИН/ИИН - Поле должно содержать 12 знаков"
                                    overlayClassName={styles.tooltipStyle}
                                    overlayStyle={{ maxWidth: '500px' }}>
                                    <span className={styles.questionIcon}> <QuestionCircleOutlined /> </span>
                                </Tooltip>
                            </div>
                            <div className={styles.binField}>
                                <Form.Item>
                                    {getFieldDecorator('bin', {
                                        initialValue: store.bin.value,
                                        rules: [{ required: true, message: 'Введите ваш БИН' }]
                                    })(
                                        <Input
                                            id="bin"
                                            onChange={(e) => store.bin.set(e.target.value)}
                                            className={styles.inputShort}
                                            placeholder={'012345678901'}
                                            maxLength={12}
                                            onBlur={() => store.updateData()}
                                        />
                                    )}
                                    <div className={styles.companyName}> {store.merchantData.companyName} </div>
                                </Form.Item>

                                <div className={styles.checkBtn}>
                                    <Button
                                        size="large"
                                        type={'primary'}
                                        onClick={() => store.checkByBin(form)}
                                        ref={store.btnRef}
                                    >
                                        {res().labels.check}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.subtitle}>
                                {res().labels.city}
                                <Tooltip title="Филиал - Укажите в каком городе Вы будете обслуживаться?"
                                    overlayClassName={styles.tooltipStyle}
                                    overlayStyle={{ maxWidth: '500px' }}>
                                    <span className={styles.questionIcon}> <QuestionCircleOutlined /> </span>
                                </Tooltip>
                            </div>
                            <Form.Item>
                                <div className={styles.inputShort}>
                                    {getFieldDecorator('cityService', {
                                        initialValue: store.cityService ? store.cityService : undefined,
                                        rules: [{ required: true, message: 'Выберите филиал' }]
                                    })(
                                        <Select
                                            id={'cityService'}
                                            onChange={(e) => store.onCityChange(e)}
                                            placeholder="Выберите значение">
                                            {store.cities.map((city, id) => <Option key={city.key} value={city.value}>{city.text}</Option>)}
                                        </Select>
                                    )}
                                </div>
                            </Form.Item>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.contactItem}>
                                <div className={styles.subtitle}>
                                    {res().labels.contact_faces}
                                </div>
                                <div className={styles.subtitleMd}> Основные контактные данные</div>
                                <div className={styles.rowItem}>
                                    <div className={`${styles.marginMd} ${styles.inputShort}`}>
                                        <div className={styles.labelSm}> {res().labels.name} </div>
                                        <Form.Item>
                                            {getFieldDecorator('name', {
                                                initialValue: store.name.value,
                                                rules: [{ required: true, message: 'Обязательно для заполнения' }]
                                            })(
                                                <Input
                                                    id="name"
                                                    onChange={(e) => store.name.set(e.target.value)}
                                                    placeholder={'Имя'}
                                                    onBlur={() => store.updateData()}
                                                />
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div className={`${styles.marginMd} ${styles.inputShort}`}>
                                        <div className={styles.labelSm}> {res().labels.surname} </div>
                                        <Form.Item>
                                            {getFieldDecorator('surname', {
                                                initialValue: store.surname.value,
                                                rules: [{ required: true, message: 'Обязательно для заполнения' }]
                                            })(
                                                <Input
                                                    id="surname"
                                                    onChange={(e) => store.surname.set(e.target.value)}
                                                    placeholder={'Фамилия'}
                                                    onBlur={() => store.updateData()}
                                                />
                                            )}
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className={styles.rowItem}>
                                    <div className={`${styles.marginMd} ${styles.inputShort}`}>
                                        <div className={styles.labelSm}> {res().labels.middlename}</div>
                                        <Form.Item>
                                            {getFieldDecorator('middleName', {
                                                initialValue: store.middleName.value
                                            })(
                                                <Input
                                                    id="middleName"
                                                    onChange={(e) => store.middleName.set(e.target.value)}
                                                    placeholder={'Отчество'}
                                                    onBlur={() => store.updateData()}
                                                />
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div className={`${styles.marginMd} ${styles.inputShort}`}>
                                        <div className={styles.labelSm}> {res().labels.phone} </div>
                                        <Form.Item>
                                            {getFieldDecorator('phone', {
                                                initialValue: store.phone.value,
                                                rules: [{ required: true, message: 'Обязательно для заполнения' }]
                                            })(
                                                <PhoneInput
                                                    onBlur={_ => store.phone.validate()}
                                                    placeholder={'+7 (xxx) xxx-xx-xx'}
                                                    specialLabel={null}
                                                    priority={{ kz: 0, ru: 1 }}
                                                    inputClass="ant-input"
                                                    onChange={phone =>
                                                        store.phone.set(phone)
                                                    }
                                                />
                                            )}
                                        </Form.Item>
                                    </div>
                                </div>
                                <div>
                                    <div className={`${styles.marginMd} ${styles.inputFull}`}>
                                        <div className={styles.labelSm}>
                                            {res().labels.emails}
                                        </div>
                                        <Form.Item>
                                            {getFieldDecorator('email', {
                                                initialValue: store.email.value,
                                                rules: [{ required: true, message: 'Обязательно для заполнения' }]
                                            })(
                                                <Input
                                                    id="email"
                                                    onChange={(e) => store.email.set(e.target.value)}
                                                    placeholder={'Email'}
                                                    onBlur={() => store.updateData()}
                                                />
                                            )}
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.subtitle}> {res().subtitles.service_bank} </div>
                            <Form.Item>
                                {getFieldDecorator('service_bank', {
                                    initialValue: store.serviceBank,
                                    rules: [{ required: true, message: 'Выберите один из вариантов' }]
                                })(
                                    <Radio.Group name="service_bank"
                                        buttonStyle="solid"
                                        onChange={store.onChangeService}>
                                        <Radio value={true}> Народный банк Казахстана </Radio>
                                        <Radio value={false}> Другой банк </Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>

                            {store.serviceBank === true && (
                                <div className={styles.infoText}>
                                    <span>Внимание!</span> Для клиентов Народного банка Казахстана требуется только анкета
                                </div>
                            )}

                            {store.serviceBank === false && (
                                <div className={styles.infoText}>
                                    <span>Внимание!</span> Вы не являетесь клиентом Народного банка Казахстана. Необходимо будет загрузить пакет документов.
                                </div>
                            )}

                        </div>
                        <div className={styles.ctaContainer}>
                            <Button
                                size="large"
                                type={'default'}
                                style={{ visibility: 'hidden' }}
                            >
                                {res().labels.back}
                            </Button>
                            <Button
                                htmlType="submit"
                                size="large"
                                type={'primary'}
                                onClick={() => store.changeStep(2)}
                            >
                                {res().labels.continue}
                            </Button>
                        </div>
                    </Form>
                    <Modal visible={store.isModalOpen}
                        bodyStyle={{ paddingTop: 50, paddingBottom: 50, paddingLeft: 40, paddingRight: 40 }}
                        footer={null}
                        width={640}
                        closeIcon={<></>}
                    >
                        <div className={`${styles.subtitle} ${styles.modalTitle}`}> Информация по Вашему БИН/ ИИН ещё в обработке</div>
                        <div className={styles.modalContent}>
                            <Form>
                                <div className={styles.modalText}>Укажите, пожалуйста, форму регистрации предприятия</div>
                                <div className={styles.radioGroup}>
                                    <Radio.Group name="form_company"
                                        value={store.companyForm || 'ip'}
                                        onChange={(e) => store.onChangeCompanyForm(e)}>
                                        <Radio key={1} className={styles.radioItem} value="ip"> ИП </Radio>
                                        <Radio key={2} className={styles.radioItem} value="too"> ТОО </Radio>
                                    </Radio.Group>
                                </div>
                                <div className={styles.modalButton}>
                                    <Button type="primary" size="large" onClick={() => store.setCompanyForm()}> Продолжить </Button>
                                </div>
                            </Form>
                        </div>
                    </Modal>
                </div>
            )}
        </>

    )
})

export default GeneralInformation
