import locale from '~/utils/locale'

export default locale.create<{
    title: string
    cardsNumberStartsWith: string
    cardsNumberEndsWith: string
    endsWith: string
    applyButton: string
    cancelButton: string
    clearButton: string
    periodTitle: string
    postLinkStatusOptions: {
        all: string
        ok: string
        fail: string
    }
}>()
