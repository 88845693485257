import { get, post, del, put } from '~/utils/ajax'
import message from '~/utils/message'
import { ApiResponse, MerchantDataAPI } from '../index'
import { PaymentSettingsData } from './contracts'
import { toJS } from 'mobx'

export * from './contracts'

export const getPaymentSettings = (date: string) =>
    get<PaymentSettingsData[]>('visadelivery/prices', { date }).then(response => {
        return response
    })

export const getPaymentSettingsHistory = (start: string, end: string) =>
    get<PaymentSettingsData[]>('visadelivery/history', { date_from: start, date_to: end }).then(response => {
        return response
    })

export const updateSettings = (data: any) => post(`visadelivery/settings`, data).then((response) => response)