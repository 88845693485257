import 'moment/locale/ru'
import React from 'react'
import res from './res'
import instructionFile from '~/assets/files/instruction-en.pdf'

res.add('en', {
    faqTitle: 'FAQ:',
    instructionMerchantAccount: 'Instructions for managing your merchant account',
    searchPlaceholder: 'Search',
    download: 'Download',
    messages: {
        required: 'Required'
    },
    hintsModal: {
        title: 'Interactive learning',
        question: 'Select the section for which you have questions or complete the full training.',
        all: 'Complete training',
        common: 'General information',
        statistics: 'Statistics',
        transactions: 'Transactions',
        terminals: 'ePay payments',
        statements: 'Statements',
        link: 'Payment links',
        banksProducts: 'Bank products',
        credit: 'Installment and credit',
        settings: 'Settings',
        template: 'ePay template',
        terminalSettings: 'Terminal settings',
        team: 'Team',
        cancel: 'Cancel',
        start: 'Start'
    },
    instructionText: (handleClick) => <>
        For a more detailed acquaintance with the work in the portal, you can <a href={instructionFile} download>download</a> the instruction
        {handleClick ? <> or <a onClick={handleClick}>launch an interactive training</a></> : null}
    </>
})
