import {
    Button,
    Col,
    Form,
    Input,
    Row
} from 'antd'
import React, { useEffect, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import res from '../res'
import styles from '../styles.css'
import { LoadingOutlined } from '@ant-design/icons'
import config from '~/config'
import message from '~/utils/message'
import layoutStyles from '~/layouts/main/styles.css'
import { CreditOrder } from '~/api/products'
import { GenerateOrderBody, MerchantShop } from '~/pages/products/credit/components/create-modal-config'
import SelectField from './SelectField'
import { BinIcon, PlusIcon } from '~/components/icon'
import { IconButton } from '~/components'
import store from '~/services/browser/browser-state'

interface FormInput {
    shopIds: any[]
    emails: any[]
    statusId: string
    statusName: string
    moderatorComment: string
    accountVerified: boolean
    verifiedBy: string
    checkingAccount: string
    shops: any[],
    merchantName: string
}

function CreateOrderForm({
    onApply,
    onClose,
    showConfirmation
}: {
    onApply: (requestBody: GenerateOrderBody) => Promise<CreditOrder>
    onClose: () => void
    showConfirmation: (newOrder: CreditOrder) => void
}) {
    const formItemLayout = {
        labelCol: {
            xs: { span: 12 },
            sm: { span: 24 }
        },
        wrapperCol: {
            xs: { span: 12 },
            sm: { span: 24 }
        }
    }
    const errorStyle = { color: 'red' }

    const [shops, setShops] = useState<MerchantShop[]>([])
    const { handleSubmit, control, errors, reset, getValues, setValue, setError, clearErrors } = useForm<FormInput>()
    const [sendingRequest, setSendingRequest] = useState(false)
    const [emails, setEmail] = useState([])
    const [shopInfo, setShopInfo] = useState([])
    const [shopIds, setShopIds] = useState([])

    const onSubmit: SubmitHandler<FormInput> = async (orderBody: FormInput) => {
        const creditOrder = orderBody
        creditOrder.emails = emails
        creditOrder.shopIds = shopIds

        if (getValues('emails')) {
            creditOrder.emails.push(getValues('emails'))
        }
        if (getValues('shopIds')) {
            creditOrder.shopIds.push(getValues('shopIds'))
        }

        setSendingRequest(true)
        let requestBody: GenerateOrderBody
        try {
            requestBody = {
                ...creditOrder
            }
            const newCreditOrder = await onApply(requestBody)
            setSendingRequest(false)
            if (newCreditOrder && newCreditOrder.id) {
                showConfirmation(newCreditOrder)
            }
        } catch (error) {
            setSendingRequest(false)
            message.error(error)
        }
    }

    const prefillShopsFromApi = async () => {
        const { data } = JSON.parse(localStorage.getItem('auth'))

        const response = await fetch(`${config.api.baseUrl}dictionary/shop`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${data.access_token}`
            })
        })
        if (response.ok) {
            const shops = await response.json()
            if (shops) {
                setShops(shops)
                shops &&
                    reset({
                        shopIds: shops[0].id,
                        emails: []
                    })
            } else {
                message.error({ error: 'Error' })
            }
        } else {
            // to do server error
        }
    }

    const addShop = () => {
        const newShop: any = getValues('shopIds')
        if (newShop.length !== 0 && shops.length > 1) {
            setShopIds([...shopIds, newShop])
            const newShopInfo = shops.filter((item, index) => item.id === newShop)[0].name
            setShopInfo([...shopInfo, newShopInfo])
            setValue('shopIds', '')
        }
    }

    const handleChange = (value) => {
        if (!value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setError('emails', { type: 'email' })
        } else {
            clearErrors('emails')
            setEmail([...emails, value])
            setValue('emails', '')
        }
    }

    const deleteShop = (id) => {
        const items = shopInfo
        if (items.length > 0) {
            setShopInfo(items.filter((item, index) => index !== id))
        }
    }

    const addEmail = () => {
        const newEmail = getValues('emails')

        if (newEmail) {
            handleChange(newEmail)
        }
    }

    const deleteEmail = (id) => {
        const items = emails
        if (items.length > 0) {
            setEmail(items.filter((item, index) => index !== id))
        }
    }

    useEffect(() => {
        prefillShopsFromApi()
    }, [reset])

    return (
        <>
            <Form
                {...formItemLayout}
                className={styles.form}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Row>
                    {shops !== [] ? (
                        <Col span={store.isMobile ? 24 : 12}>
                            <Form.Item
                                label={
                                    <div>
                                        {res().formItemTitles.shop}
                                        <IconButton
                                            icon={PlusIcon}
                                            dataSemanticId="save-icon"
                                            id="open-statement-settings-icon"
                                            onClick={() => addShop()}
                                        />
                                    </div>
                                }
                            >
                                <div className={styles.actionField}>
                                    <Controller
                                        style={{
                                            borderColor: errors.shopIds ? 'red' : ''
                                        }}
                                        rules={{ required: true }}
                                        control={control}
                                        name="shopIds"
                                        placeholder={res().shopPlaceholder}
                                        as={SelectField(shops[0], shops)}
                                    />
                                </div>
                            </Form.Item>
                            {errors.shopIds && (
                                <span style={errorStyle}>
                                    {res().invalidShop}
                                </span>
                            )}
                            <div>
                                {shopInfo.map((shop, index) => (
                                    <div className={styles.actionField}>
                                        <div key={index} className={styles.actionFieldValue}>{shop}</div>
                                        <IconButton
                                            icon={BinIcon}
                                            key="button_cancel"
                                            dataSemanticId="delete-icon"
                                            onClick={() => deleteShop(index)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </Col>

                    ) : (
                        <LoadingOutlined />
                    )}
                    <Col span={store.isMobile ? 24 : 12}>
                        <Form.Item label={
                            <>
                                {res().formItemTitles.email}
                                <IconButton
                                    icon={PlusIcon}
                                    dataSemanticId="save-icon"
                                    id="open-statement-settings-icon"
                                    onClick={() => addEmail()}
                                />
                            </>
                        }>
                            <div className={styles.actionField}>
                                <Controller
                                    style={{
                                        borderColor: errors.emails
                                            ? 'red'
                                            : ''
                                    }}
                                    control={control}
                                    type="text"
                                    as={<Input
                                        className="form-input"
                                    />}
                                    name="emails"
                                    defaultValue=""
                                    placeholder={res().emailPlaceholder}
                                    rules={{
                                        required: true,
                                        validate: {
                                            emails: value =>
                                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) ||
                                                res().invalidEmail
                                        }
                                    }}
                                />
                            </div>
                        </Form.Item>
                        {errors.emails && (
                            <span style={errorStyle}>{res().invalidEmail}</span>
                        )}
                        <div>
                            {emails.map((email, index) => (
                                <div key={index} className={styles.actionField}>
                                    <div className={styles.actionFieldValue}>{email}</div>
                                    <IconButton
                                        icon={BinIcon}
                                        key="button_cancel"
                                        dataSemanticId="delete-icon"
                                        onClick={() => deleteEmail(index)}
                                    />
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>


                <div
                    className={`${layoutStyles.contentFooter} ${styles.footer}`}
                >
                    <Button htmlType="submit" size="large" type="primary">
                        {sendingRequest ? (
                            <LoadingOutlined />
                        ) : (
                            res().sendOrder
                        )}
                    </Button>
                </div>
            </Form>
        </>
    )
}

export default CreateOrderForm
