import locale from '~/utils/locale'

export default locale.create<{
    dataFilterPlaceholder: string
    loading: string
    payment: string
    pageInfo: (info: { from: number, to: number, total: number }) => React.ReactNode
    operationsResult: (totalCount: number, successCount: number) => string
    actions: string
    gridControlsTooltips: {
        reload: string
        settings: string
        firstPage: string
        prevPage: string
        nextPage: string
        lastPage: string
    }
    filters: string
}>()
