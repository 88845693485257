import React from 'react'

import styles from './styles.css'

import BrowserStore from '~/services/browser/browser-state'

import {
    ArrowLeftIcon,
    ArrowTopIcon,
    CheckIcon,
    CloseModalIcon,
    RefreshIcon,
    ReloadIcon
} from '../icon'

import { Button } from '../index'

import layoutStyles from '~/layouts/main/styles.css'

export interface ListToolbarProps {
    controls?: React.ReactNode
    onRefresh?: () => void
    onScrollTop?: () => void
    scrollTargetClassName?: string
}
const hideConditions = {
    isScrolling: { state: false, canceler: undefined },
    isTouching: { state: false, canceler: undefined }
}
const setVisibility = (targetClassName: string, visible: boolean) => {
    const elements = document.getElementsByClassName(targetClassName)
    if (elements && elements.length > 0) {
        const elem = elements[0]
        if (visible) {
            elem.classList.remove(styles.hiddenControl)
        } else {
            hideConditions.isScrolling.state = false
            elem.classList.add(styles.hiddenControl)
        }
    }
}
// className={`${layoutStyles.drawerButton}`}
export default class ListToolbar extends React.Component<
    ListToolbarProps,
    any
> {
    public componentDidMount() {
        this.setUpEvent()
    }
    public componentWillUnmount() {
        this.setUpEvent(true)
    }
    public render() {
        const { props } = this
        return (
            <React.Fragment>
                {BrowserStore.isMobile && (
                    <React.Fragment>
                        <div className={`${styles.mobileListToolbar}`}>
                            {props.onRefresh && (
                                <Button
                                    size="large"
                                    shape="circle"
                                    className={`${styles.controlButton} ${styles.scrollButton}`}
                                    onClick={props.onRefresh}
                                >
                                    <RefreshIcon></RefreshIcon>
                                </Button>
                            )}
                            {props.onScrollTop && (
                                <Button
                                    size="large"
                                    className={`${styles.controlButton} ${styles.refreshButton}`}
                                    shape="circle"
                                    onClick={props.onScrollTop}
                                >
                                    <ArrowTopIcon></ArrowTopIcon>
                                </Button>
                            )}
                            {props.controls}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
    private setUpEvent(clear?: boolean) {
        const elements = document.getElementsByClassName(
            this.props.scrollTargetClassName
        )
        if (elements && elements.length > 0) {
            const element = window // elements[0].parentElement
            const operation = clear
                ? element.removeEventListener
                : element.addEventListener
            if (BrowserStore.isMobile) {
                operation(
                    'touchstart',
                    () => setVisibility(styles.mobileListToolbar, false),
                    true
                )
                operation(
                    'touchcancel',
                    () => setVisibility(styles.mobileListToolbar, true),
                    true
                )
                operation(
                    'touchend',
                    () => setVisibility(styles.mobileListToolbar, true),
                    true
                )
            } else {
                operation('scroll', this.onScroll, true)
            }
        }
    }
    private onScroll(evnt) {
        window.clearTimeout(hideConditions.isScrolling.canceler)
        setVisibility(styles.mobileListToolbar, false)
        hideConditions.isScrolling.canceler = setTimeout(() => {
            setVisibility(styles.mobileListToolbar, true)
        }, 100)
    }
}
