import { Col, Dropdown, Menu, Row, Tooltip } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import { CreditOrder, CreditOrderStatus } from '~/api/products/contracts'
import { title } from '../../styles.css'
import moment from 'moment'
import { renderOrderStatus } from '../../constants/data-fields'
import dataRes from '../../constants/res'
import statusStyles from '../../constants/order-statuses'
import { DownOutlined } from '@ant-design/icons'
import styles from './styles.css'

interface ProductProps {
    data: []
    loading: boolean
    title: string

}

const ProductCard = observer((props: ProductProps) => {
    const { title, data }: ProductProps = props

    const MenuItem: React.ReactNode = (
        <Menu
            data-semantic-id="Status_select_dropdown"
        >
            {data.map((status: any, iter: number) =>
                <Menu.Item
                    data-semantic-id="Status_menuitem"
                    className={styles.menuItem}
                    key={`status ${status.id}`}
                >
                    <span>{`${moment(status.createdAt).format('MM-DD-YYYY/HH-MM')}`}</span>
                    {renderOrderStatus(iter, dataRes().creditOrderStatus[status.statusName], statusStyles[status.statusName])}
                </Menu.Item>
            )}
        </Menu>
    )

    const card: CreditOrder = data[data.length - 1]

    return (
        <Row gutter={[16, 16]} key={`id_${card.id}`}>
            <Col span={8}>
                <Col span={8}>
                    {title}
                </Col>
                <Col span={14}>
                    <Dropdown overlay={MenuItem} trigger={['click']} placement="bottomRight">
                        <div className={styles.dropDown}>
                            {
                                renderOrderStatus(
                                    1, dataRes().creditOrderStatus[card.statusName], statusStyles[card.statusName]
                                )
                            }
                            <DownOutlined
                                color={statusStyles[card.statusName].color}
                                className={styles.iconAnimation}
                            />
                        </div>
                    </Dropdown>
                </Col>
            </Col>
            <Col span={4}>
                <div className={styles.productSubTitle}>{dataRes().dataFields.short.emails}</div>
                <div>
                    {card.emails.map((mail: string, iter: number) =>
                        <Tooltip key={`${card.id}_${100 + iter}`} placement="topLeft" title={mail}>
                            <div className={`${styles.productItem} ${styles.textWraper}`}>{mail}</div>
                        </Tooltip>
                    )}
                </div>
            </Col>
            <Col span={3}>
                <div className={styles.productSubTitle}>{dataRes().dataFields.short.shopIds}</div>
                <div>
                    {card.shops.map((shop: any) =>
                        <Tooltip key={`${card.id}_${shop.name}`} placement="topLeft" title={shop.name}>
                            <div className={styles.productItem}>{shop.name}</div>
                        </Tooltip>
                    )}
                </div>
            </Col>
            <Col span={4}>
                <div className={styles.productSubTitle}>{dataRes().dataFields.short.checkingAccount}</div>
                <Tooltip key={`${card.id}_${card.checkingAccount}`} placement="topLeft" title={card.statusName === CreditOrderStatus.ACCEPT && card.checkingAccount}>
                    <div className={styles.productItem}>
                        {card.statusName === CreditOrderStatus.ACCEPT && card.checkingAccount}
                    </div>
                </Tooltip>
            </Col>
            <Col span={5}>
                <div className={styles.productSubTitle}>{dataRes().dataFields.short.creditConditions}</div>
                <div>
                    {card.creditConditions.map((credit: any) =>
                        <Tooltip key={`${card.id}_${credit.monthCount}`} placement="topLeft" title={`${credit.interestRate ? dataRes().credit : dataRes().installment}:  
                    ${credit.interestRate}% - ${dataRes().conditionLabel(credit.monthCount)}`}>
                            <div>
                                <span>{credit.interestRate ? dataRes().credit : dataRes().installment}</span>:
                                <span className={styles.productItem}> {credit.interestRate}% - {dataRes().conditionLabel(credit.monthCount)}</span>
                            </div>
                        </Tooltip>
                    )}
                </div>
            </Col>
        </Row>
    )
})

export default ProductCard