import React from 'react'

import {
    action,
    computed,
    entries,
    keys,
    observable,
    remove,
    set,
    values as mobxValues
} from 'mobx'
import { observer } from 'mobx-react'

import { LabeledValue, SelectValue } from 'antd/lib/select'

import { Select } from '~/components'
import { SearchIcon } from '~/components/icon'

import fieldRes from '../../pages/payments/epay/res'
import res from './res'
import styles from './styles.css'

import moment from 'moment'
import { DataFilterProps, FilterOperator, FilterOption } from './props'

import isNil from 'lodash/isNil'

export * from './props'

const Option = Select.Option

const keySep = '.'

@observer
export class DataFilter<T> extends React.Component<DataFilterProps<T>, {}> {
    public render() {
        let className = styles.filters
        let suffix: React.ReactNode = null

        if (this.props.action) {
            suffix = <div className={styles.suffix}>{this.props.action}</div>
            className += ' ' + styles.customAction
        }

        if (typeof this.props.query !== 'undefined') {
            this.setQuery(this.props.query)
        }

        return (
            <React.Fragment>
                <div className={`${styles.wrapper} hint-section-4-step-5`}>
                    <div className={styles.prefix}>
                        <div>
                            <SearchIcon />
                        </div>
                    </div>
                    <Select<SelectValue>
                        data-semantic-id="attributes_filter_field"
                        mode="multiple"
                        size="large"
                        className={className}
                        filterOption={false}
                        value={this.values}
                        onSelect={this.onSelect}
                        onDeselect={this.onDeselect}
                        onChange={this.onFiltersChange}
                        onSearch={this.setQuery}
                        labelInValue={true}
                        allowClear={true}
                        dropdownClassName={`${styles.selectDropdown} hint-section-4-step-5-2`}
                        placeholder={this.props.placeholder}
                        open={!!this.query}
                        onDropdownVisibleChange={this.onDropdownVisibleChange}
                    >
                        {this.options}
                    </Select>
                    {suffix}
                </div>
            </React.Fragment>
        )
    }

    private getKey = (
        field: keyof T | string,
        operator: FilterOperator | string
    ) => `${field}${keySep}${operator}`

    private renderValue = (
        title: string,
        operator: FilterOperator | string,
        value: any
    ) => (
        <span>
            {title} {res().operators[operator]}{' '}
            <span className={styles.filterValue}>{value}</span>
        </span>
    )

    @action
    private setQuery = (value: string) => {
        this.query = value
    }

    @action
    private onSelect = ({ key }: LabeledValue) => {
        const {
            option: { field, type, valueGetter },
            operator
        } = this.flattenOptions.get(key)

        const { values } = this.props

        if (!values[field]) {
            set(values, field as string, {})
        }
        let parsedValue
        if (valueGetter) {
            parsedValue = valueGetter(this.query)
        } else {
            parsedValue = !type ? this.query : type.parse(this.query)
        }
        set(values[field], operator, parsedValue)

        this.query = ''
    }

    @action
    private onDeselect = ({ key }: LabeledValue) => {
        key = key.substr(1)

        const { option, operator } = this.flattenOptions.get(key)

        remove(this.props.values[option.field], operator)
    }

    @action
    private onFiltersChange = (values: LabeledValue[]) => {
        if (values.length === 0) {
            mobxValues(this.props.values).forEach(field => {
                if (!field) return

                keys(field).forEach(operator => remove(field, operator))
            })
        }
    }

    @action
    private onDropdownVisibleChange = (visible: boolean) => {
        if (!visible) this.query = ''
    }

    @observable
    private query: string

    @computed
    private get flattenOptions() {
        const { options } = this.props

        const result = new Map<
            string,
            { option: FilterOption<T>; operator: FilterOperator }
        >()

        for (const option of options) {
            for (const operator of option.operators) {
                result.set(this.getKey(option.field, operator), {
                    option,
                    operator
                })
            }
        }

        return result
    }

    @computed
    private get options() {
        const result: React.ReactNode[] = []

        this.flattenOptions.forEach(({ option, operator }, key) => {
            const disabled =
                !this.query || (option.type && !option.type.isValid(this.query))

            if (disabled) return

            result.push(
                <Option key={key} disabled={disabled}>
                    {this.renderValue(
                        option.title,
                        operator,
                        disabled ? '' : this.query
                    )}
                </Option>
            )
        })

        return result
    }

    @computed
    private get values() {
        const map = new Map<string, React.ReactNode>()

        const result: LabeledValue[] = []

        const flatten = this.flattenOptions

        for (const [field, operators] of entries(this.props.values)) {
            if (!operators) continue
            for (let [operator, value] of entries(operators)) {
                if (isNil(value)) continue
                if (field === 'postLinkStatus') {
                    value = value
                        ? fieldRes().postLinkStatus.ok
                        : fieldRes().postLinkStatus.fail
                } else if (field === 'payoutDate' && value) {
                    value = moment(value).format('YYYY-MM-DD')
                } else if (field === 'installment_id' && value) {
                    value =
                        value instanceof Array
                            ? value
                                .map(
                                    installment =>
                                        fieldRes().installments.full[
                                        installment + 'm'
                                        ]
                                )
                                .join(', ')
                            : value
                }
                const key = this.getKey(field, operator)
                if (!flatten.has(key)) continue
                const { option } = flatten.get(key)
                map.set(
                    keySep + key,
                    this.renderValue(option.title, operator, value)
                )
            }
        }

        this.order.forEach(key => {
            const label = map.get(key)
            if (!label) return
            result.push({ key, label })
            map.delete(key)
        })

        map.forEach((label, key) => result.push({ key, label }))

        this.order = result.map(({ key }) => key)

        return result
    }

    private order: string[] = []
}

export default DataFilter
