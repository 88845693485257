import React from 'react'

import { observer } from 'mobx-react'

import {
    Cell,
    ChartTooltip,
    Pie,
    PieChart,
    ResponsiveContainer
} from '~/components'
import styles from './styles.css'
import { StatisticsPieChartProps } from '../../types'


const colors = ['#8884d8', '#82ca9d', '#FFBB28', '#FF8042']

const capitalizeFirstLetter = (text: string): string => {
    const lowerText = text.toLowerCase()
    if (lowerText.length > 0) {
        return lowerText[0]!.toUpperCase()! + lowerText.slice(1)
    }
    return ''
}

export default observer(function StatisticsPieChart(
    props: StatisticsPieChartProps
) {
    const { data, chartTooltip } = props
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => data[index]!.percentage!

    return (
        <div className={styles.staticWrapper} >
            {/* <div>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart >
                        <Pie
                            data={[...data]}
                            dataKey="transactionCount"
                            cx="50%"
                            cy="50%"
                            outerRadius={80}
                            labelLine={false}
                            fill="#8884d8"
                            paddingAngle={3}
                            label={renderCustomizedLabel}
                        >
                            {data.map((entry, index) => <Cell key={`cell-${entry.issuerId}`} fill={colors[index % colors.length]} />)}
                        </Pie>
                        <ChartTooltip
                            content={chartTooltip ? chartTooltip : null}
                            position={{ y: 10 }}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div> */}
            <div >
                {data.sort((a, b) => a.percentage - b.percentage).reverse().map((entry, index) => {
                    return <div key={entry.issuerId} className={styles.staticWrapperList}>
                        {/* <span style={{ background: `${colors[index % colors.length]}` }} /> */}
                        <span>
                            {index + 1}. {capitalizeFirstLetter(entry.issuerName)}
                        </span>
                        <b>{entry.percentage}%</b>
                        <div className={styles.progressBar} style={{ width: `${entry.percentage}%`, background: colors[Math.floor(Math.random() * 3)] }}></div>
                    </div>
                })}
            </div>
        </div>


    )
})
