import React, { RefObject } from 'react'

import { Button } from '~/components'

import styles from './collapsible.css'

export interface CollapsibleProps {
    collapseLabel: string
    expanded?: boolean
    onToggle?: () => void
    children?: React.ReactNode
}

export default class Collapsible extends React.Component<CollapsibleProps, {}> {
    constructor(props: CollapsibleProps, context: any) {
        super(props, context)

        this.content = React.createRef()
        this.children = React.createRef()
    }

    public componentDidMount() {
        this.updateHeight()
    }

    public render() {
        const { props } = this

        if (this.content.current && this.children.current) {
            setTimeout(this.updateHeight, 0)
        }

        const expandedClass = props.expanded ? ' ' + styles.expanded : ''

        return (
            <>
                <div className={styles.buttonContainer + expandedClass}>
                    <Button className={`${styles.button} hint-section-4-step-4`} onClick={props.onToggle}>
                        {props.collapseLabel}
                        <div className={styles.arrow + expandedClass} />
                    </Button>
                </div>
                <div
                    className={styles.content + expandedClass}
                    ref={this.content}
                >
                    <div ref={this.children}>{props.children}</div>
                </div>
            </>
        )
    }

    private updateHeight = () =>
    (this.content.current.style.height = this.props.expanded
        ? this.children.current.getBoundingClientRect().height + 'px'
        : '0')

    private readonly content: RefObject<HTMLDivElement>

    private readonly children: RefObject<HTMLDivElement>
}
