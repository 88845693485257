import locale from '~/utils/locale'

import { P2PTransfer, P2PStatus } from '~/api'

export default locale.create<{
    title: string
    ranges: {
        today: string
        yesterday: string
        thisWeek: string
        priorWeek: string
        thisMonth: string
        priorMonth: string
        thisQuarter: string
        customPeriod: string
    },
    anyPaymentStatus: string
    paymentStatuses: {
        [key in P2PStatus]: string
    },
    sortBy: string
    filtersPlaceholder: string
    dataFields: {
        full: {
            [key in keyof P2PTransfer]?: string
        },
        short: {
            [key in keyof P2PTransfer]?: string
        },
        groups: {
            location: string,
            paymentPage: string
        },
        geolocation: string
    }
    sortOptions: {
        date: string
        postLink: string
        code: string
        type: string
        asc: {
            date: string
            postLink: string
            code: string
        },
        desc: {
            date: string
            postLink: string
            code: string
        }
    }
    installments: {
        full: {
            '3m': string
            '6m': string
            '9m': string
            '12m': string
        },
        short: {
            '3m': string
            '6m': string
            '9m': string
            '12m': string
        }
    }
    secureValues: {
        true: string
        false: string
    }
    partialSum: {
        isRequired: string
        constraints: string
    }
    operations: {
        fullCharge: {
            title: string
            itemError: (paymentId) => string
        },
        partialCharge: {
            title: string
            itemError: (paymentId) => string
        },
        cancel: {
            title: string
            itemError: (paymentId) => string
        },
        fullRefund: {
            title: string
            itemError: (paymentId) => string
        },
        partialRefund: {
            title: string
            itemError: (paymentId) => string
        }
    }
    postLinkStatus: {
        all: string
        ok: string
        fail: string
    }
    collapseSummary: (range: string | (() => string)) => string
    errors: {
        summaryLoadError: string
        paymentsLoadError: string
    }
}>()
