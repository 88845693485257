import React from 'react'

import BrowserStore from '~/services/browser/browser-state'

import { ArrowLeftIcon } from '../icon'

import { Button } from '../index'

import layoutStyles from '~/layouts/main/styles.css'

export interface MobileToolbarProps {
    controls?: React.ReactNode
    onBackClick?: () => void
    className?: string
}

export default (props: MobileToolbarProps) => (
    <React.Fragment>
        {BrowserStore.isMobile && props.controls && (
            <React.Fragment>
                <div
                    className={`${layoutStyles.mobileHeaderContainer} ${props.className ? props.className : ''
                        }`}
                >
                    {props.onBackClick && (
                        <Button
                            className={`${layoutStyles.drawerButton}`}
                            size="large"
                            ghost
                            onClick={props.onBackClick}
                        >
                            <ArrowLeftIcon></ArrowLeftIcon>
                        </Button>
                    )}
                    {!props.onBackClick && <div></div>}
                    {props.controls}
                    <div className={`${layoutStyles.floatBlock}`}></div>
                </div>
            </React.Fragment>
        )}
    </React.Fragment>
)
