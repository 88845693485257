import React from 'react'

const SvgArrow = props => (
    <svg width="1em" height="1em" viewBox="0 0 9 5" fill="none" {...props}>
        <path
            d="M8 1L4.5 4 1 1"
            stroke="#7E8194"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default SvgArrow
