import React from 'react'

import { observer } from 'mobx-react'

import { Tooltip } from '~/components'

import styles from './styles.css'

interface StatusIconProps {
    title?: string
    color?: string
    className?: string
}

export default observer((props: StatusIconProps) => {
    const { title, color, className } = props
    const style = !color ? undefined : { backgroundColor: color }

    return (
        <Tooltip title={title}>
            <span className={`${styles.icon}`}>
                <span className={`${styles.glow} ${className}`} style={style} />
                <span
                    className={`${styles.balloon} ${className}`}
                    style={style}
                />
            </span>
        </Tooltip>
    )
})
