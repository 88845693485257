import React from 'react'

const SvgReload = props => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" {...props}>
        <path
            d="M512 0C337.067 0 179.2 89.601 85.334 230.4V42.666H0V384h341.334v-85.334H145.066C221.866 166.4 358.4 85.334 512 85.334c234.665 0 426.665 192 426.665 426.667 0 234.67-192 426.664-426.665 426.664-204.8 0-384-145.065-418.132-349.866-4.267-21.333-25.6-38.399-51.2-34.133-21.333 4.268-38.399 25.598-34.132 51.198C51.2 849.07 264.534 1024 512 1024c281.6 0 512-230.4 512-512S793.6 0 512 0z"
            fillRule="nonzero"
        />
    </svg>
)

export default SvgReload
