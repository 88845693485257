import res from './res'

res.add('ru', {
    dashboard: 'Статистика',
    // dashboardLabel: 'Статистика за сегодня',

    /*****************************************/
    transactions: 'Транзакции',
    payments: 'ePay платежи',
    transfers: 'ePay переводы',
    aftTransactions: 'Пополнения AFT',
    octTransactions: 'Выплаты',
    cashByCode: 'Cash by code',

    /*****************************************/
    banksProducts: 'Продукты банка',

    epay: 'ePay-платежи',
    pos: 'POS-платежи',
    goBonuses: 'Go!-бонусы',
    orange: 'Orange-платежи',
    homebank: 'Homebank',
    QRPayments: 'QR-оплаты',
    credit: 'Рассрочка и кредитование',

    /*****************************************/
    p2p: 'Транзакции',
    oct: 'Выплаты',

    /*****************************************/
    paymentAft: 'Оплаты AFT',
    aft: 'Транзакции AFT',


    /*****************************************/

    statements: 'Выписки',
    payment: 'По платежам',
    transfer: 'По переводам',
    chargeback: 'Chargeback',

    /*****************************************/
    news: 'Новостная лента',

    increaseBonuses: 'Увеличить бонусы',
    VIPMerchants: 'VIP-коммерсантам',
    /*****************************************/
    paymentLinks: 'Ссылки на оплату',

    /*****************************************/
    qrCodes: 'QR-коды',

    staticQRCode: 'Статический QR-код',
    dynamicQRCodes: 'Динамические QR-коды',
    labelGenerator: 'QR-коды для ценников',

    /*****************************************/
    settings: 'Настройки',

    // paymentSystemActivation: 'Активация платежной системы',
    // ePayPaymentSystemActivation: 'Активация платежной системы',
    configureEpayTemplate: 'Адаптировать шаблон ePay',
    terminalSettings: 'Настройки терминалов',
    certificates: 'Сертификаты',
    managingMerchants: 'Управление мерчантами',
    team: 'Сотрудники',
    faq: 'FAQ',
    forDevelopers: 'Разработчикам',
    documentation: 'Документация',
    notifications: 'Сообщения',
    profile: 'Профиль',
    logOut: 'Выйти',
    applyButton: 'Сохранить',
    cancelButton: 'Отменить',
    setValue: 'Указать значение',
    orders: 'Мои заявки',
    currentOrders: 'Текущие заявки',
    createOrder: 'Подача новой заявки',
    paymentSettings: 'Настройка оплаты'
})
