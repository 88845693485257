import React from 'react'

import { observer } from 'mobx-react'

import { languages } from '~/constants/laguages'

import { Dropdown, Menu } from '~/components'

import { CaretDownIcon } from '~/components/icon'
import res from '~/pages/profile/res'
import locale, { Language } from '~/utils/locale'
import styles from './styles.css'

export interface LanguageSelectProps {
    selectedItemRender?: React.ReactNode
    selectedValue?: Language
}

export default observer((props: LanguageSelectProps) => {
    const menu = (
        <Menu
            data-semantic-id="language_select_dropdown"
            selectedKeys={[locale.lang]}
            onClick={param => locale.setLang(param.key)}
            className={styles.languageSelectMenu}
        >
            <Menu.Item data-semantic-id="en_language_menuitem" key="en">
                {languages.en}
            </Menu.Item>
            <Menu.Item data-semantic-id="kk_language_menuitem" key="kk">
                {languages.kk}
            </Menu.Item>
            <Menu.Item data-semantic-id="ru_language_menuitem" key="ru">
                {languages.ru}
            </Menu.Item>
        </Menu>
    )

    return (
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
            {props.selectedItemRender ? (
                props.selectedItemRender
            ) : (
                <div className={styles.languageSelectContainer}>
                    {res().labels.language}:{' '}
                    <a className={styles.languageSelect}>
                        {' '}
                        {languages[locale.lang]}&nbsp;
                        <CaretDownIcon data-semantic-id="caret_down" />
                    </a>
                </div>
            )}
        </Dropdown>
    )
})
