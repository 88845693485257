import { observer } from 'mobx-react'
import React from 'react'
import styles from './styles.css'
import { RightOutlined, LeftOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { ButtonDefault } from '~/components'
import BrowserStore from '~/services/browser/browser-state'
import { HintType, interactiveHintId } from './constants'
import { action, observable } from 'mobx'
import { calculateHintPlacement } from './utils'
import WelcomeModal from './components/modals/welcome'
import WantToContinueModal from './components/modals/want-to-continue'
import FirstSuccessTransaction from './components/modals/first-success-transaction'
import CongratulationModal from './components/modals/congratulation'
import SkipSectionModal from './components/modals/skip-section'
import SkipAllModal from './components/modals/skip-all'
import TransactionsWarningModal from './components/modals/transactions-warning'
import InteractiveHintsStore from './interactive-hints-store'
import LayoutStore from '~/layouts/main/layout-store'
import AppStore from '~/app-store'
import res from './res'

interface Props {
    store: InteractiveHintsStore
    layoutStore: typeof LayoutStore
    appStore: typeof AppStore
}

@observer
export default class InteractiveHints extends React.Component<Props, {}> {

    public constructor(props) {
        super(props)
        if (this.props.store.sections.length === 0) {
            this.props.appStore.hideInteractiveHints()
        }
    }

    public componentDidMount() {
        window.addEventListener('resize', this.updateViewportDimensions)
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.updateViewportDimensions)
        // InteractiveHint.setHintSectionFlags(this.props.store.flagsFromBackend)
    }

    public componentDidUpdate() {
        this.scrollViewToCurrentHint()
    }

    public render() {
        const store = this.props.store
        const appStore = this.props.appStore
        const { isShowMode } = store

        // if (store.sections.length === 0) {
        //     appStore.hideInteractiveHints()
        //     return null
        // }

        return (
            <>
                {isShowMode && this.renderInteractiveMode()}
                <SkipAllModal store={store} appStore={appStore} />
                <SkipSectionModal store={store} />
                <CongratulationModal store={store} appStore={appStore} />
                <TransactionsWarningModal store={store} appStore={appStore} />
                <WelcomeModal store={store} appStore={appStore} />
                <WantToContinueModal store={store} appStore={appStore} />
                <FirstSuccessTransaction store={store} appStore={appStore} />
            </>
        )
    }

    private renderInteractiveMode() {
        const store = this.props.store
        const { currentStep: { target: targetClassName } } = store

        const targetElement = document.querySelector(targetClassName)
        if (!targetElement) {
            store.goNextStep()
            return null
        }

        const targetParameters = targetElement.getBoundingClientRect()
        const pageWidth = document.querySelector('.hint-page').getBoundingClientRect().width
        if (targetParameters.width > pageWidth) {
            targetParameters.width = pageWidth
        }

        return (
            <>
                {this.renderHint(targetParameters)}
                {this.renderBackgroundMask(targetParameters)}
            </>
        )
    }

    private renderHint(parameters: any) {
        const store = this.props.store
        const { isStepChanging, isManySections, currentStep: { placement } } = store
        const { isCurrentStepTypeModal, isCurrentStepTypeSiderOnTablet } = store
        // const [styleHint, styleHintArrow] = this.calculateHintPlacement(parameters, placement)
        const [styleHint, styleHintArrow] = calculateHintPlacement(parameters, placement, (isCurrentStepTypeModal || isCurrentStepTypeSiderOnTablet))

        const hintClass = `${styles.interactiveHint} ${placement} ${isStepChanging ? styles.hidden : ''} ${BrowserStore.isMobile ? styles.mobile : ''}`

        return (
            <div
                className={hintClass}
                style={styleHint}
                id={interactiveHintId}
                aria-label={`${this.viewportHeight}-${this.viewportWidth}`}
            >
                <div className={styles.hintArrow} style={styleHintArrow}></div>
                {isManySections &&
                    <div className={styles.hintHeader}>
                        {!store.isFirstSection &&
                            <span className={styles.next}>
                                <LeftOutlined className={styles.nextIcon} />
                                <span
                                    className={styles.nextText}
                                    onClick={() => store.goPrevSection()}>
                                    {res().prevSectionBtn}
                                </span>
                            </span>
                        }
                        {!store.isLastSection &&
                            <span className={`${styles.next} ${styles.nextRight}`}>
                                <span
                                    className={styles.nextText}
                                    onClick={() => store.showSkipSectionModal()}>
                                    {res().nextSectionBtn}
                                </span>
                                <RightOutlined className={styles.nextIcon} />
                            </span>
                        }
                    </div>
                }
                <p className={styles.title}>{store.currentSection.title}</p>
                <div className={styles.hintBody}>
                    <div className={styles.bullets}>
                        {store.currentSection.steps.map(
                            (item, index) => {
                                const activeClass = index === store.currentStepIndex ? styles.active : ''
                                return <div className={`${styles.bullet} ${activeClass}`} key={index}></div>
                            }
                        )}
                    </div>
                    <p className={styles.hintText}>
                        {store.currentStep.description}
                    </p>
                </div>
                <div className={styles.hintFooter}>
                    <div className={styles.prevNextContainer}>
                        <ButtonDefault
                            className={styles.nextButton}
                            type="default"
                            onClick={() => store.goPrevStep()}
                            size="default"
                            disabled={store.isSectionFirstStep}
                        >
                            <ArrowLeftOutlined />
                        </ButtonDefault>
                        <ButtonDefault
                            className={styles.nextButton}
                            type="default"
                            onClick={() => store.goNextStep()}
                            size="default"
                        >
                            <ArrowRightOutlined />
                        </ButtonDefault>
                    </div>
                    <span
                        className={styles.skipHint}
                        onClick={() => store.showSkipAllModal()}
                    >
                        {res().skipBtn}
                    </span>
                </div>
            </div>
        )
    }

    private renderBackgroundMask(parameters: any) {
        const store = this.props.store
        const { isStepChanging, isCurrentStepTypeSiderOnTablet } = store

        const styleHighlight: Record<string, any> = {
            top: parameters.top + window.scrollY,
            left: parameters.left,
            width: parameters.width,
            height: parameters.height
        }

        if (isCurrentStepTypeSiderOnTablet) {
            styleHighlight.position = 'fixed'
            styleHighlight.top = parameters.top
        }

        return (
            <div>
                <div className={styles.interactiveHintsMask}></div>
                {!isStepChanging && <div className={styles.interactiveHighlight} style={styleHighlight}></div>}
            </div>
        )
    }

    private scrollViewToCurrentHint() {
        const store = this.props.store
        const { isShowMode, isStepChanging, currentStep: { type } } = store

        if (isShowMode && type !== HintType.modal && !isStepChanging) {
            document.getElementById(interactiveHintId)
                .scrollIntoView({
                    behavior: 'smooth', block: 'center', inline: 'center'
                })
        }
    }

    // Properties & functions for listening window resize event
    private updateViewportDimensions = () => {
        action(() => {
            this.viewportHeight = window.innerHeight
            this.viewportWidth = window.innerWidth
        })()
    }

    @observable
    private viewportHeight = 0

    @observable
    private viewportWidth = 0
}
