import { observer } from 'mobx-react'
import React from 'react'
import NewsStore from '../news-store'
import AppStore from '~/app-store'
import Page from '~/layouts/main/page'
import NewsRenderer from '../components/newsCard/index'
import { Row } from 'antd'
import res from '../res'
import BrowserStore from '~/services/browser/browser-state'
import styles from '../styles.css'
import { DownOutlined } from '@ant-design/icons'
import { InfinitiveList } from '~/components/list/infinitive-list'
import { NewsCard } from '../news-store'
import GridControls from '~/components/grid-controls'
import { NoDataIcon } from '~/components/icon'

export default observer(({ store, appStore }: { store: NewsStore, appStore: typeof AppStore }) => {
    return (
        <Page title={res().newsTitle} controls={
            !BrowserStore.isMobile && <GridControls store={store} />
        }>
            <Row>
                {
                    store.newsList ?
                        <>
                            {!BrowserStore.isMobile &&
                                store.newsList?.map(news =>
                                    <NewsRenderer
                                        isMobile={BrowserStore.isMobile}
                                        date={news.publish_time}
                                        tags={[news.tag, news.seen_status]}
                                        image={news.image_url}
                                        title={news.title}
                                        text={news.description}
                                        period={news.promotion_periodstring}
                                        link={news.id}
                                    />
                                )
                            }
                            {
                                BrowserStore.isMobile &&
                                <div
                                    className={styles.listInfiniteContainer}
                                    style={{ height: BrowserStore.windowHeight - 101 + 'px' }}
                                >
                                    <InfinitiveList<NewsCard>
                                        dataSource={store.newsList}
                                        loaded={!store.loadingNews}
                                        dataLoader={store.handleShowMore}
                                        itemRenderer={(news) => <NewsRenderer
                                            isMobile={BrowserStore.isMobile}
                                            date={news.publish_time}
                                            tags={[news.tag, news.seen_status]}
                                            image={news.image_url}
                                            title={news.title}
                                            text={news.description}
                                            period={news.promotion_periodstring}
                                            link={news.id}
                                        />}
                                        pageSize={store.pageSize}
                                        scrollTopTrigger={3}
                                    />
                                </div>
                            }
                        </> :
                        <div className={styles.noData}>
                            <NoDataIcon className={styles.noDataIcon} />
                            <span>{res().noDataLable}</span>
                        </div>
                }


            </Row>
            {!BrowserStore.isMobile &&
                <Row className={styles.newsFooter}>
                    {store.pageSize !== store.pageInfo?.total &&
                        <div onClick={() => store.handleShowMore()} className={styles.showMore}>Показать больше <DownOutlined /></div>
                    }
                    <GridControls store={store} />
                </Row>
            }


        </Page>
    )
})