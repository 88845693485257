import React from 'react'

import { action, computed, observable } from 'mobx'

import { observer } from 'mobx-react'

import { CashByCodeStatus } from '~/api'

import { Badge, List } from '~/components'

import { PaymentsPage } from '../components/payments-page'

import SortConfigSelect from '../components/sort-config-select'

import constants from './constants'

import BaseFilters from './components/base-filters'
import FullFiltersForm from './components/full-filters-form'

import CashByCodeStore from './cash-by-code-store'

import paymentsRes from '../res'
import res from './res'

import {
    ClockIcon, DocumentIcon,
    FilterIcon,
    SortByDescAIcon
} from '~/components/icon'
import MobileHeader from '~/components/modal/mobile-header'
import ModalContainer, {
    ModalContext
} from '~/components/modal/modal-container'
import BrowserStore from '~/services/browser/browser-state'

import fullFormStyles from './components/full-filters-form/styles.css'
import styles from './styles.css'

import ListToolbar from '~/components/mobile-contols/list-toolbar'

import MobileMenu, {
    MobileMenuConfig
} from '~/components/mobile-menu/mobile-menu'
import { CashByCodePayment } from '~/api/cash-by-code'
import appStyles from '~/app.css'
import { renderCurrencyAmountLabel, renderStatusLabel } from '../cash-by-code/constants/data-fields'
import moment from 'moment'

@observer
export default class CashByCode extends PaymentsPage<
CashByCodePayment,
CashByCodeStore
> {
    @observable
    public menuVisible: boolean = false

    protected get pageHeaderControls() {
        return <div></div>
    }

    protected get title() {
        return res().title
    }

    protected get baseControls() {
        const { store } = this.props

        return BrowserStore.isMobile ? (
            undefined
        ) : (
            <div className={`${styles.baseControls}`}>
                <BaseFiltersContainer store={store} />
                <div>
                    <label htmlFor="sort">{res().sortBy}&nbsp;&nbsp;</label>
                    <SortContainer id="sort" store={store} />
                </div>
            </div>
        )
    }

    protected get toolbar() {
        const { store } = this.props

        return (
            <React.Fragment>
                <div>
                    <MobileMenu config={this.mobileMenuConfig}></MobileMenu>
                    <ModalContainer
                        visible={store.showSortingMobile}
                        title={paymentsRes().sorting}
                        onClose={store.toggleSortingMobile}
                        headerControl={
                            <MobileHeader
                                onCancel={store.toggleSortingMobile}
                            ></MobileHeader>
                        }
                        closable={true}
                    >
                        <SortContainer
                            id="sort"
                            store={store}
                            asMenu={true}
                            onChange={(field, direction) => {
                                store.toggleSortingMobile()
                                store.setSort(field, direction)
                            }}
                        />
                    </ModalContainer>
                    <DataFilterFormContainer
                        store={store}
                        visible={store.showFilteringMobile}
                        onClose={store.toggleFilteringMobile}
                    />
                    <ListToolbar
                        onRefresh={this.props.store.refresh}
                        onScrollTop={this.scrollToTop}
                        scrollTargetClassName={this.scrollDependedControls[0]}
                    />
                </div>
            </React.Fragment>
        )
    }

    @action.bound
    public toggleMenu(selectAction?: () => void) {
        this.menuVisible = !this.menuVisible
        if (selectAction) {
            selectAction()
        }
    }
    @computed
    protected get dataFilterForm() {
        return BrowserStore.isMobile ? (
            undefined
        ) : (
            <DataFilterFormContainer
                store={this.props.store}
                visible={this.showDataFilterForm}
                onClose={this.closeDataFilterForm}
            />
        )
    }

    protected get dataFilterOptions() {
        return constants.filterOptions.get().filter(item => !item.fullOnly)
    }

    protected get columns() {
        return constants.dataFields.get()
    }

    protected get dataFields() {
        return constants.dataFields.get()
    }

    protected get paymentDetailedFields() {
        return constants.detailedFields.get()
    }

    protected get detailedPaymentActions() {
        return undefined
    }

    @computed
    protected get isActionVisible() {
        const selectedPayment = this.props.store.selectedPayments[0]
        const isActionlessStatus =
            !selectedPayment ||
            (selectedPayment.status !== CashByCodeStatus.auth &&
                selectedPayment.status !== CashByCodeStatus.charged)
        return !isActionlessStatus
    }

    @computed
    protected get mobileMenuConfig(): MobileMenuConfig {
        const store = this.props.store
        const filterIcon = (
            <Badge count={store.activeFiltersCount}>
                <FilterIcon className={styles.strokedIcon}></FilterIcon>
            </Badge>
        )

        const menuConfig: MobileMenuConfig = {
            buttons: [
                // {
                //     clickAction: () =>
                //         this.toggleMenu(store.toggleSummaryMobile),
                //     icon: <ChartIcon />,
                //     className: 'mobile-btn-open-summary'
                // },
                {
                    clickAction: () =>
                        this.toggleMenu(store.toggleSortingMobile),
                    icon: <SortByDescAIcon />,
                    className: 'mobile-btn-open-sorting'
                },
                {
                    clickAction: () =>
                        this.toggleMenu(store.toggleFilteringMobile),
                    icon: filterIcon,
                    className: 'mobile-btn-open-filter'
                }
            ]
        }
        menuConfig.visible = this.menuVisible
        menuConfig.disabled = false
        return menuConfig
    }

    protected renderListItem = (item: CashByCodePayment) => {
        return (
            <List.Item
                key={item.id}
                onClick={() => this.props.store.openPaymentDetailedForm(item)}
            >
                <div className={styles.cardElement}>
                    <div className={appStyles.floatTwoCellRow}>
                        <div>{renderStatusLabel(item.status, true)}</div>
                        <div>
                            <ClockIcon />
                            &nbsp;
                            <span className={appStyles.fatText}>
                                {moment(item.createdAt).format('DD.MM.YY')}
                                &nbsp;
                            </span>
                            <span>
                                {moment(item.createdAt).format('/ HH:mm')}
                            </span>
                        </div>
                    </div>
                    <div className={appStyles.floatTwoCellRow}>
                        <div>
                            <DocumentIcon /> {item.invoiceId}
                        </div>
                        <div>
                            <span>
                                {renderCurrencyAmountLabel(item.amount, item)}
                            </span>
                        </div>
                    </div>
                    {/*<div className={styles.contactInfo}>*/}
                    {/*    <span className={styles.contactInfoName}>*/}
                    {/*        {item.payerName}*/}
                    {/*    </span>*/}
                    {/*</div>*/}
                    {/*<div className={styles.contactInfoSecondary}>*/}
                    {/*    <span>{item.payerEmail}</span>*/}
                    {/*</div>*/}
                    {/*{item.payerPhone && (*/}
                    {/*    <div className={styles.contactInfoSecondary}>*/}
                    {/*        <span>*/}
                    {/*            {item.payerPhone.includes('+') ? '' : '+'}*/}
                    {/*            {item.payerPhone}*/}
                    {/*        </span>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
            </List.Item>
        )
    }
}

const BaseFiltersContainer = observer(
    ({ store }: { store: CashByCodeStore }) => (
        <BaseFilters
            rangePresets={store.rangePresets}
            range={store.range}
            onRangeChange={store.setRange}
            status={store.statusFilter}
            onStatusChange={store.setStatusFilter}
            className={styles.baseFilters}
        />
    )
)

const SortContainer = observer(
    ({
        store,
        id,
        onChange,
        asMenu
    }: {
        store: CashByCodeStore
        id?: string
        onChange?: (field: keyof CashByCodePayment, direction: 'asc' | 'desc') => void
        asMenu?: boolean
    }) => (
        <SortConfigSelect
            options={constants.sortOptions.get()}
            id={id}
            asMenu={asMenu}
            selectedOption={{
                field: store.sortField,
                direction: store.sortDirection
            }}
            onChange={onChange ? onChange : store.setSort}
        />
    )
)

const DataFilterFormContainer = observer(
    ({
        store,
        ...rest
    }: {
        store: CashByCodeStore
        visible: boolean
        onClose: () => any
    }) => {
        return (
            <ModalContainer
                visible={rest.visible}
                title={BrowserStore.isMobile ? paymentsRes().filter : undefined}
                onClose={rest.onClose}
                modalClass={fullFormStyles.fullModal}
                headerControl={
                    <MobileHeader
                        onCancel={store.toggleFilteringMobile}
                        title={paymentsRes().filter}
                    />
                }
                closable={true}
            >
                <ModalContext.Consumer>
                    {modalContext => (
                        <FullFiltersForm
                            values={store.filters}
                            dateRangeValue={store.range}
                            dateRangePresets={store.rangePresets}
                            onRangeChange={store.setRange}
                            modalContext={modalContext}
                            onFiltersChange={store.applyFilters}
                            setStatusFilter={store.setStatusFilter}
                            status={store.statusFilter}
                            onStatusChange={store.setStatusFilter}
                            {...rest}
                        />
                    )}
                </ModalContext.Consumer>
            </ModalContainer>
        )
    }
)
