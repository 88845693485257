import res from './res'
import React from 'react'

res.add('ru', {
    title: 'Chargeback',
    infoTitle: 'Мы добавили новую функцию, которая позволяет вам полностью настраивать URL для процедуры возврата средств (chargeback) и устанавливать собственный общий секретный ключ (shared secret) для повышения безопасности',
    infoText: <>
        <p>Теперь вы можете указать собственные URL-адреса, по которым будут отправляться запросы на возврат средств, что предоставляет вам больше гибкости и контроля над процессом возврата средств. А установка собственного общего секретного ключа обеспечит дополнительный уровень безопасности при взаимодействии вашего приложения с нашей системой.
        </p>
        <p>Shared Secret - это секретный код, который устанавливается мерчантом и используется для подтверждения подлинности операции chargeback. Мерчант должен включать Shared Secret в каждый запрос chargeback. Сопоставляя Shared Secret мерчанта с тем, что хранится у платежной системы, гарантирует легитимность chargeback и защищает от мошенничества.</p>
    </>,
    settingTitle: 'Настройка Chargeback',
    messages: {
        valueRequired: 'Обязательно для заполнения',
        successUpdate: 'Данные успешно обновлены'
    },
    labels: {
        activeUrl: 'Активный url chargeback',
        activeSecret: 'Активный shared secret',
        chargebackUrl: 'url chargeback',
        chargebackUrlPlaceholder: 'https://yourwebsite.com/chargeback',
        sharedSecret: 'shared secret',
        sharedSecretPlaceholder: 'Y0urSh@r3dS3cr3tK3y',
        save: 'Сохранить',
        edit: 'Редактировать'
    }
})
