import locale from '~/utils/locale'

export default locale.create<{
    title: string
    subTitle: string
    email: string
    emailRequired: string
    invalidEmail: string
    roleTitle: string
    manager: string
    managerDescription: string
    accountant: string
    accountantDescription: string
    view: string
    viewDescription: string
    accessLevelChanges: string
    applyButton: string
    cancelButton: string
}>()
