import React from 'react'

import moment from 'moment'

import { observer } from 'mobx-react'

import RangeCalendar from 'rc-calendar/lib/RangeCalendar'

import { Popover, Tag } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'

import { action, observable, toJS } from 'mobx'

import styles from './styles.css'

import mainStyles from '~/layouts/main/commonStyles.css'

import locale from '~/utils/locale'

import enLocale from 'rc-calendar/lib/locale/en_US'

import ruLocale from 'rc-calendar/lib/locale/ru_RU'

import kzLocale from 'rc-calendar/lib/locale/ru_RU'

export type Range = [moment.Moment, moment.Moment]

export interface RangePopoverValue {
    key?: string
    label?: string | (() => string)
    range: Range
}

export interface RangePopoverProps {
    children?: React.ReactNode
    placement?: TooltipPlacement
    presets?: RangePopoverValue[]
    value?: RangePopoverValue
    id?: string
    onChange?: (value: RangePopoverValue) => void
}

@observer
export class RangePopover extends React.Component<RangePopoverProps, {}> {
    constructor(props, context) {
        super(props, context)

        this.showRangeCalendar = false
    }
    public get calendarLocale() {
        const currentLocale = locale.lang
        switch (currentLocale) {
            case 'ru':
                return ruLocale
            case 'en':
                return enLocale
            case 'kk':
                return ruLocale
            default:
                return ruLocale
        }
    }
    public render() {
        const { children, placement, presets, value } = this.props

        const range = toJS(this.range)

        const content = (
            <RangeCalendar
                showToday={false}
                showOk={false}
                locale={this.calendarLocale}
                prefixCls="ant-calendar"
                className={`ant-calendar-range-with-ranges ${styles.container} ${styles.noShadow}`}
                defaultValue={range}
                selectedValue={range}
                onSelect={this.onSelect}
                renderSidebar={this.renderPresets(presets)}
            />
        )

        return (
            <Popover
                placement={placement}
                trigger="click"
                content={content}
                onVisibleChange={this.visible}
                visible={this.showRangeCalendar}
            >
                {children || this.renderLabel(value)}
            </Popover>
        )
    }

    private renderLabel(value: RangePopoverValue) {
        if (value.key) {
            value = this.props.presets.find(p => p.key === value.key)
        }

        const label =
            (value.label && value.label instanceof Function
                ? value.label()
                : value.label) ||
            `${value.range[0].format('L')} - ${value.range[1].format('L')}`

        return (
            <a
                id={this.props.id}
                href="#"
                onClick={e => e.preventDefault()}
                className={styles.text}
            >
                {label}
            </a>
        )
    }

    @action.bound
    private visible(value?: boolean) {
        if (value) {
            this.range = this.props.value.range
        }

        this.showRangeCalendar = value
    }

    @action.bound
    private onSelect(value: Range) {
        this.select({
            range: value
        })
    }

    @action.bound
    private select(value: RangePopoverValue) {
        this.showRangeCalendar = false

        this.range = value.range

        if (!this.props.onChange) return

        this.props.onChange(value)
    }

    private renderPresets(presets: RangePopoverValue[]) {
        if (!presets) return undefined

        return () => (
            <div className={styles.presets}>
                {presets
                    .filter(preset => preset.key !== 'customPeriod')
                    .map(value => (
                        <Tag
                            key={value.key}
                            className={`${mainStyles.standartButton} ${mainStyles.white
                                } ${this.props.value.key === value.key
                                    ? mainStyles.selected
                                    : ''
                                }`}
                            onClick={() => this.select(value)}
                        >
                            {value.label && value.label instanceof Function
                                ? value.label()
                                : value.label}
                        </Tag>
                    ))}
            </div>
        )
    }

    @observable
    private showRangeCalendar: boolean

    @observable
    private range: Range
}

export default RangePopover
