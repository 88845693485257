import { CeoShortInfo, ProductInquiry, RegistrationAddress, TraderType } from '~/api/products/contracts'
import { FormFieldInterface } from '~/components/form-builder/form-control-fields'
import res from '../res'

export const ePayInquiryLegalAdressScheme: FormFieldInterface = {
    fieldName: 'registerCompanyAddress',
    order: 1,
    localizationScheme: res,
    dataObjectType: RegistrationAddress,
    localizationSchemeSubKey: 'inquiryFields.registrationAddress',
    localizationTitleKey: 'inquiryFields.registerCompanyAddress',
    childFields: [
        { fieldName: 'type', localizationScheme: res },
        { fieldName: 'countryCode', localizationScheme: res },
        { fieldName: 'zip', localizationScheme: res },
        { fieldName: 'regionCode', localizationScheme: res },
        { fieldName: 'districtCode', localizationScheme: res },
        { fieldName: 'cityCode', localizationScheme: res },
        { fieldName: 'townCode', localizationScheme: res },
        { fieldName: 'microdistrictCode', localizationScheme: res },
        { fieldName: 'street', localizationScheme: res },
        { fieldName: 'buildingNumber', localizationScheme: res },
        { fieldName: 'apartmentNumber', localizationScheme: res },
        { fieldName: 'fullAddress', localizationScheme: res }
    ],
    dataObjectGetter: (rootObject: ProductInquiry) => rootObject.registerCompanyAddress
}
export const ePayInquiryActualAdressScheme: FormFieldInterface = {
    fieldName: 'realCompanyAddress',
    order: 2,
    localizationScheme: res,
    dataObjectType: RegistrationAddress,
    localizationSchemeSubKey: 'inquiryFields.registrationAddress',
    localizationTitleKey: 'inquiryFields.realCompanyAddress',
    childFields: [
        { fieldName: 'type', localizationScheme: res },
        { fieldName: 'countryCode', localizationScheme: res },
        { fieldName: 'zip', localizationScheme: res },
        { fieldName: 'regionCode', localizationScheme: res },
        { fieldName: 'districtCode', localizationScheme: res },
        { fieldName: 'cityCode', localizationScheme: res },
        { fieldName: 'townCode', localizationScheme: res },
        { fieldName: 'microdistrictCode', localizationScheme: res },
        { fieldName: 'street', localizationScheme: res },
        { fieldName: 'buildingNumber', localizationScheme: res },
        { fieldName: 'apartmentNumber', localizationScheme: res },
        { fieldName: 'fullAddress', localizationScheme: res }
    ],
    dataObjectGetter: (rootObject: ProductInquiry) => rootObject.realCompanyAddress
}
export const ePayTraderTypeScheme: FormFieldInterface = {
    fieldName: 'traderType',
    order: 1,
    localizationScheme: res,
    dataObjectType: TraderType,
    localizationSchemeSubKey: 'inquiryFields.traderType',
    localizationTitleKey: 'inquiryFields.traderTypeLabel',
    childFields: [
        { fieldName: 'code', localizationScheme: res },
        { fieldName: 'id', localizationScheme: res },
        { fieldName: 'longname', localizationScheme: res }
    ],
    dataObjectGetter: (rootObject: ProductInquiry) => rootObject.realCompanyAddress
}
export const ePayCeoShortInfoScheme: FormFieldInterface = {
    fieldName: 'ceoShortInfo',
    order: 3,
    localizationScheme: res,
    dataObjectType: CeoShortInfo,
    localizationSchemeSubKey: 'inquiryFields.traderType',
    localizationTitleKey: 'inquiryFields.ceoShortInfoLabel',
    childFields: [
        // {fieldName: 'registrationAddress: RegistrationAddress = new RegistrationAddress()
        // {fieldName: 'residenceAddress: RegistrationAddress = new RegistrationAddress()
        // tslint:disable-next-line:array-type
        { fieldName: 'iin', localizationScheme: res },
        { fieldName: 'firstName', localizationScheme: res },
        { fieldName: 'lastName', localizationScheme: res },
        { fieldName: 'middleName', localizationScheme: res },
        { fieldName: 'fullName', localizationScheme: res },
        { fieldName: 'shortName', localizationScheme: res },
        { fieldName: 'birthDateString', localizationScheme: res },
        { fieldName: 'birthDate', localizationScheme: res },
        { fieldName: 'document', localizationScheme: res },
        { fieldName: 'isNotResident', localizationScheme: res },
        { fieldName: 'citizenship', localizationScheme: res },
        { fieldName: 'phoneNumbers', localizationScheme: res },
        {
            ...ePayInquiryActualAdressScheme,
            fieldName: 'registrationAddress',
            localizationTitleKey: 'inquiryFields.personLegalAddressLabel'
        },
        {
            ...ePayInquiryActualAdressScheme,
            fieldName: 'residenceAddress',
            localizationTitleKey: 'inquiryFields.personRealAddressLabel'
        }
    ],
    dataObjectGetter: (rootObject: ProductInquiry) => rootObject.ceoShortInfo
}

export const ePayChiefAccountantShortInfoScheme: FormFieldInterface = {
    ...ePayCeoShortInfoScheme,
    fieldName: 'chiefAccountantShortInfo',
    order: 3,
    localizationTitleKey: 'inquiryFields.chiefAccountantShortInfoLabel',
    dataObjectGetter: (rootObject: ProductInquiry) => rootObject.chiefAccountantShortInfo,
    disabled: (rootObject: ProductInquiry) => rootObject.cheifAccountantDoesNotExists === true
}

export const ePayInquiryScheme: FormFieldInterface = {
    fieldName: 'companyInfo',
    order: 0,
    localizationScheme: res,
    dataObjectType: ProductInquiry,
    localizationSchemeSubKey: 'inquiryFields',
    localizationTitleKey: 'inquiryFields.companyInfo',
    childFields: [
        { fieldName: 'iinBin', localizationScheme: res },
        ePayTraderTypeScheme,
        /*
        {fieldName: 'traderType', localizationScheme: res}
        {fieldName: 'realCompanyAddress', localizationScheme: res},
        {fieldName: 'registerCompanyAddress', localizationScheme: res},
        */
        { fieldName: 'traderName', localizationScheme: res },
        { fieldName: 'traderNameLt', localizationScheme: res },
        { fieldName: 'mainActivity', localizationScheme: res },
        { fieldName: 'createdDate', localizationScheme: res },
        { fieldName: 'serviceBank', localizationScheme: res },
        { fieldName: 'servicePeriodOfBank', localizationScheme: res },
        { fieldName: 'hasCredits', localizationScheme: res },
        { fieldName: 'realPhone', localizationScheme: res },
        { fieldName: 'realFax', localizationScheme: res },
        { fieldName: 'contactPhone', localizationScheme: res },
        { fieldName: 'premisesOwnership', localizationScheme: res },
        { fieldName: 'lessorName', localizationScheme: res },
        { fieldName: 'leaseTerm', localizationScheme: res },
        { fieldName: 'hasEncumbranceOnPropert', localizationScheme: res },
        { fieldName: 'cheifAccountantDoesNotExists', localizationScheme: res }
    ]
}

export const ePayInquirySchemeSteps: FormFieldInterface[] = [
    ePayInquiryScheme,
    ePayInquiryLegalAdressScheme,
    ePayInquiryActualAdressScheme,
    ePayCeoShortInfoScheme,
    ePayChiefAccountantShortInfoScheme
]

/*
'ceoShortInfo',
'chiefAccountantShortInfo',
'serviceBank',
'servicePeriodOfBank',
'hasCredits',
'contactPhone',
'contracts',
'email',
'traderType',
'traderName',
'traderNameLt',
'mainActivity',
'createdDate',
'registerCompanyAddress',
'realCompanyAddress',
'registerPhone',
'registerFax',
'realPhone',
'realFax',
'premisesOwnership',
'lessorName',
'leaseTerm',
'hasEncumbranceOnProperty',
'cheifAccountantDoesNotExists' */
