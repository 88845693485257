import React from 'react'

const SvgChange = props => (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
        <path
            d="M15.4 11.128V15.4a1.6 1.6 0 01-1.6 1.6H2.6A1.6 1.6 0 011 15.4V4.2a1.6 1.6 0 011.6-1.6h4.272"
            stroke="#7E8194"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.8 1L17 4.2l-8 8H5.8V9l8-8z"
            stroke="#7E8194"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default SvgChange
