import React, { FormEvent } from 'react'

import { observer } from 'mobx-react'

import { Button, Form, FormItem, InputItem, NavLink } from '~/components'
import { EyeIcon } from '~/components/icon'

import styles from '~/layouts/auth/styles.css'

import res from '../res'

import LoginStore from './login-store'

export default observer(({ store }: { store: LoginStore }) => {
    function submit(e: FormEvent) {
        store.login()
        e.preventDefault()
    }

    const { isPasswordShown } = store

    return (
        <div data-semantic-id="login_form">
            <div className={styles.header}>
                <span data-semantic-id="login_title" className={styles.title}>
                    {res().loginTitle}
                </span>
            </div>
            <div className={styles.main}>
                <Form onSubmit={submit}>
                    <InputItem
                        store={store.email}
                        placeholder={res().email}
                        inputProps={{ name: 'email_field', size: 'large' }}
                    />
                    <InputItem
                        store={store.password}
                        type={isPasswordShown ? 'text' : 'password'}
                        placeholder={res().password}
                        inputProps={{
                            name: 'password_field',
                            suffix: (
                                <span
                                    data-semantic-id="show_password_button"
                                    className={`${styles.showPasswordButton} ${isPasswordShown ? styles.active : ''
                                        }`}
                                    onClick={() =>
                                        store.togglePasswordVisibility()
                                    }
                                >
                                    <EyeIcon theme="filled" />
                                </span>
                            ),
                            size: 'large'
                        }}
                    />
                    {store.error && (
                        <div className={styles.error}>{store.errorMessage}</div>
                    )}
                    <FormItem>
                        <Button
                            data-semantic-id="login_button"
                            disabled={store.isSending}
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className={styles.mainButton}
                        >
                            {res().logInAction}
                        </Button>
                    </FormItem>
                    <FormItem className={styles.passwordRestoreContainer}>
                        <NavLink
                            href="/password-recovery"
                            className={styles.passwordRestoreLink}
                        >
                            {res().passwordRestoreLink}
                        </NavLink>
                    </FormItem>
                    <FormItem className={styles.centered}>
                        {res().noAccount}{' '}
                        <NavLink href={'/signup'} className={styles.signupLink}>
                            {res().signupLink}
                        </NavLink>
                    </FormItem>
                </Form>
            </div>
        </div>
    )
})
