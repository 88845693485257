import res from './res'

res.add('en', {
    dataFields: {
        full: {
            createdAt: 'Created date',
            statusName: 'Status',
            emails: 'Receivers',
            shopIds: 'Shops',
            moderatorComment: 'Comment',
            creditConditions: 'Credit conditions',
            number: 'Order number',
            checkingAccount: 'Payment number'
        },
        short: {
            createdAt: 'Date',
            statusName: 'Status',
            emails: 'Receivers',
            shopIds: 'Shops',
            moderatorComment: 'Comment',
            creditConditions: 'Conditions',
            number: 'Order number',
            checkingAccount: 'Payment number'
        }
    },
    creditOrderStatus: {
        NEW: 'Application submitted',
        IN_PROGRESS: 'In processing',
        MERCHANT_CONFIRMATION: 'Necessary the confirmation',
        ACCEPT: 'Application approved',
        REJECT: 'Rejected'
    },
    tooltips: {
        noValues: 'No data specified'
    },
    installment: 'Installment',
    credit: 'Loan',
    conditionLabel: (val: string) => {
        const value = parseInt(val, null)

        return `${value} month${value === 1 ? '' : 's'}`
    }
})
