import React from 'react'
import { observer } from 'mobx-react'

import { languageCodes, languages } from '~/constants/laguages'
import { Dropdown, Menu } from '~/components'
import locale, { Language } from '~/utils/locale'
import { DownOutlined } from '@ant-design/icons'
import styles from './styles.css'
import { showErrorOnThrow } from '~/utils/user-error'
import { updateProfile } from '~/api'

export interface LanguageSelectProps {
    labelStyle?: React.CSSProperties
    placement?: 'bottomCenter' | 'bottomLeft'
}

export default observer(({ labelStyle = { color: '#fff' }, placement = 'bottomCenter' }: LanguageSelectProps) => {

    const setLang = (lang: Language) => {
        locale.setLang(lang)
        showErrorOnThrow(updateProfile({ lang: locale.lang }))
    }

    const menu = (
        <Menu
            selectedKeys={[locale.lang]}
            onClick={param => setLang(param.key as Language)}
            className={styles.languageSelectMenu}
        >
            <Menu.Item key="en">
                {languages.en} ({languageCodes.en})
            </Menu.Item>
            <Menu.Item key="kk">
                {languages.kk} ({languageCodes.kk})
            </Menu.Item>
            <Menu.Item key="ru">
                {languages.ru} ({languageCodes.ru})
            </Menu.Item>
        </Menu>
    )

    return (
        <Dropdown
            overlay={menu}
            trigger={['click']}
            placement={placement as any}>
            <div
                className={styles.languageSelectContainer}
            >
                <div className={styles.languageSelect} style={labelStyle}>
                    {languageCodes[locale.lang]}
                    <DownOutlined className={styles.languageDownIcon} />
                </div>
            </div>
        </Dropdown>
    )
})
