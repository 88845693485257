import React from 'react'

import { observer } from 'mobx-react'

import { Tooltip } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'
import styles from './styles.css'

interface IconButtonProps {
    icon: any
    title?: React.ReactNode
    className?: string
    dataSemanticId?: string
    onClick?: () => any
    tooltipTitle?: string
    tooltipPlacement?: TooltipPlacement
    id?: string
    disabled?: boolean
}

export default observer((props: IconButtonProps) => {
    const {
        className,
        id,
        onClick,
        title,
        dataSemanticId,
        tooltipTitle,
        tooltipPlacement,
        disabled
    } = props

    const Icon = props.icon

    return (
        <span
            data-semantic-id={dataSemanticId}
            id={id}
            className={`${styles.iconButton} ${disabled ? styles.disabled : ''
                } ${className ? className : ''} ${title ? styles.withTitle : ''}`}
            // tslint:disable-next-line:no-empty
            onClick={!disabled ? onClick : () => { }}
        >
            {!disabled && (
                <Tooltip
                    getPopupContainer={() =>
                        id ? document.getElementById(id) : document.body
                    }
                    placement={tooltipPlacement}
                    title={tooltipTitle}
                >
                    <Icon className={styles.icon} />
                </Tooltip>
            )}
            {disabled && <Icon className={styles.icon} />}
            {title ? <span className={styles.title}>{title}</span> : null}
        </span>
    )
})
