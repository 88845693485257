import res from './res'

import { OCTStatus } from '~/api'

res.add('en', {
    title: 'ePay payments',
    ranges: {
        today: 'today',
        yesterday: 'yesterday',
        thisWeek: 'this week',
        priorWeek: 'prior week',
        thisMonth: 'this month',
        priorMonth: 'prior month',
        thisQuarter: 'this quarter',
        customPeriod: 'custom period'
    },
    anyPaymentStatus: 'Any',
    paymentStatuses: {
        // [OCTStatus.auth]: 'Pending',
        [OCTStatus.charged]: 'Charged',
        // [P2PStatus.cancelled]: 'Cancelled',
        [OCTStatus.rejected]: 'Cancelled',
        // [OCTStatus.refunded]: 'Refunded',
        // [OCTStatus.failed]: 'Failed',
        [OCTStatus.new]: 'New',
        [OCTStatus.any]: 'Any'
    },
    sortBy: 'Sort by',
    filtersPlaceholder: 'Payment attributes filter',
    dataFields: {
        full: {
            accountID: 'Customer identifier',
            amount: 'Payment amount',
            currency: 'Currency',
            invoiceID: 'Order No.',
            reference: 'Retrieval reference number',
            description: 'Payment details',
            fee: 'Fee',
            language: 'Language',
            createdDate: 'Created on',
            dateTime: 'Created on',

            ipCity: 'City',
            ipCountry: 'Country',
            ipDistrict: 'District',
            ipLatitude: 'Latitude',
            ipLongitude: 'Longitude',
            ipRegion: 'Region',

            email: 'Customer email',
            ip: 'Customer IP',
            name: 'Card owner name',
            phone: 'Customer phone',

            secure: 'Is 3D secure',
            code: 'Payment status',
            reason: 'Result',

            senderCardMask: 'Sender card mask',
            // senderCardType: 'Sender card type',
            // senderIssuer: 'Sender issuer',
            // senderCardID: 'Sender card id', 
            // senderOwID: 'Sender ow id', 
            // senderTransferType: 'Sender Transfer method', 

            receiverCardMask: 'Receiver card mask',
            receiverCardType: 'Receiver card type',
            receiverIssuer: 'Receiver issuer',
            // receiverCardID: 'Receiver card id',
            // receiverOwID: 'Receiver ow id',
            // receiverTransferType: 'Receiver transfer type',

            data: 'Additional fields',

            //            amountBonus: 'Go!-bonuses amount',
            //            chargeDate: 'Charged on',
            //            client: 'Customer',
            //            installment_id: 'Installment',
            //            issuerBankCountry: 'Issuer country',
            //            shop: 'Shop name',
            //            merchant: 'Merchant reference',
            //            postLink: 'PostLink address',
            postLinkStatus: 'PostLink'
            //            payoutAmount: 'Refund amount',
            //            payoutDate: 'Refund date',
            //            template: 'Billing page template',
            //            orgAmount: 'Initial amount',
            //            transactionTypes: 'Transaction types',

        },
        short: {
            accountID: 'Customer ID',
            amount: 'Amount',
            currency: 'Currency',
            invoiceID: 'Order #',
            reference: 'RRN',
            description: 'Description',
            fee: 'Fee',
            language: 'Language',
            createdDate: 'Date',
            dateTime: 'Created On',

            ipCity: 'City',
            ipCountry: 'Country',
            ipDistrict: 'District',
            ipLatitude: 'Latitude',
            ipLongitude: 'Longitude',
            ipRegion: 'Region',

            email: 'Email',
            ip: 'Payer IP',
            name: 'Payer',
            phone: 'Phone',

            secure: 'Is 3D secure',
            code: 'Status',
            reason: 'Result',

            senderCardMask: 'Sender card mask',
            // senderCardType: 'Sender card type',
            // senderIssuer: 'Sender issuer',
            // senderCardID: 'Sender card id', 
            // senderOwID: 'Sender ow id', 
            // senderTransferType: 'Sender Transfer method',

            receiverCardMask: 'Receiver card mask',
            receiverCardType: 'Receiver card type',
            receiverIssuer: 'Receiver issuer',
            // receiverCardID: 'Receiver card id',
            // receiverOwID: 'Receiver ow id',
            // receiverTransferType: 'Receiver transfer type',

            data: 'Additional fields',

            //            chargeDate: 'Charged On',
            //            client: 'Customer',
            //            shop: 'Shop',
            //            installment_id: 'Installment',
            //            amountBonus: 'Go!bonus',
            //            issuerBankCountry: 'Issuer country',
            //            merchant: 'Merchant reference',
            //            postLink: 'PostLink address',
            postLinkStatus: 'PostLink'
            //            payoutAmount: 'Refund amount',
            //            payoutDate: 'Refunded On',
            //            template: 'Payment page template',
            //            orgAmount: 'Initial amount',
            //            transactionTypes: 'Transaction types',

        },
        groups: {
            location: 'Location',
            paymentPage: 'Payment Page'
        },
        geolocation: 'Geolocation'
    },
    sortOptions: {
        date: 'Date',
        postLink: 'PostLink',
        code: 'Status',
        type: 'Type',
        asc: {
            date: 'old first',
            postLink: 'successful first',
            code: 'pending first'
        },
        desc: {
            date: 'new first',
            postLink: 'failed first',
            code: 'refund first'
        }
    },
    installments: {
        full: {
            '3m': '3 months',
            '6m': '6 months',
            '9m': '9 months',
            '12m': '12 months'
        },
        short: {
            '3m': '3m',
            '6m': '6m',
            '9m': '9m',
            '12m': '12m'
        }
    },
    secureValues: {
        true: 'Yes',
        false: 'No'
    },
    partialSum: {
        isRequired: 'Sum field is required',
        constraints: 'Sum must be a positive number not greater than the Payment sum'
    },
    operations: {
        fullCharge: {
            title: 'Full charge',
            itemError: paymentReference => `Error full charge on payment #${paymentReference}`
        },
        partialCharge: {
            title: 'Partial charge',
            itemError: paymentReference => `Error partial charge on payment #${paymentReference}`
        },
        cancel: {
            title: 'Cancel',
            itemError: paymentReference => `Error cancellation on payment #${paymentReference}`
        },
        fullRefund: {
            title: 'Full refund',
            itemError: paymentReference => `Error full refund on payment #${paymentReference}`
        },
        partialRefund: {
            title: 'Partial refund',
            itemError: paymentReference => `Error partial refund on payment #${paymentReference}`
        }
    },
    postLinkStatus: {
        all: 'All',
        ok: 'OK',
        fail: 'Failed'
    },
    collapseSummary: range => `Statistics for ${range instanceof Function ? range() : range || 'period'}`,
    errors: {
        summaryLoadError: 'Summary load error',
        paymentsLoadError: 'Payments load error'
    }
})
