import React from 'react'
import { Modal } from 'antd'
import res from '../../res'
import { observer } from 'mobx-react'
import FooterLinks from '../footer-links'
import styles from '../../styles.css'

interface ContactModalProps {
    store: FooterLinks
}

const ContactsModal = observer(({ store }: ContactModalProps) => {

    return (
        <Modal
            visible={store.isOpenContactsModal}
            onCancel={store.toggleContactsModal}
            footer={null}
            bodyStyle={{ padding: 0 }}
            centered
        >
            <div className={styles.contactsModalBody}>
                <h4>{res().contactsModal.title}</h4>
                <p><a href="tel:9595">9595</a> – {res().contactsModal.forLegals}</p>
                <p><a href="tel:7111">7111</a> – {res().contactsModal.forIndividuals}</p>
                <p>{res().contactsModal.acquiring} <a href="mailto:ecommerce@halykbank.kz">ecommerce@halykbank.kz</a></p>
                <p>{res().contactsModal.cabinet} <a href="mailto:epay@halykbank.kz">epay@halykbank.kz</a></p>
            </div>
        </Modal>
    )
})

export default ContactsModal