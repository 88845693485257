import React from 'react'

import res from './res'

res.add('en', {
    contacts: 'Contacts',
    docs: 'Documentation',
    help: 'Help',
    termsOfUse: 'Terms of Use',
    contactsModal: {
        title: 'Contacts',
        close: 'Close',
        forLegals: 'for legals',
        forIndividuals: 'for individuals',
        acquiring: 'For acquiring questions',
        cabinet: 'For personal account questions'
    }
})
