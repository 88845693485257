import React from 'react'

const SvgUnderConstruction = props => (
    <svg width="1em" height="1em" viewBox="0 0 89 84" fill="none" {...props}>
        <path d="M66.75 61.6H22.251v11.2h44.5V61.6z" fill="#A5A7B4" />
        <path
            d="M11.126 78.4c0 3.093 2.49 5.6 5.563 5.6 3.072 0 5.562-2.507 5.562-5.6v-70H11.126v70zm55.624-70v70c0 3.093 2.49 5.6 5.563 5.6 3.072 0 5.562-2.507 5.562-5.6v-70H66.75z"
            fill="#D8D8D8"
        />
        <path
            d="M84.055 22.399H4.945C2.214 22.399 0 24.653 0 27.434V39.74c0 2.78 2.214 5.036 4.945 5.036h79.111c2.73 0 4.944-2.254 4.944-5.036V27.434c-.001-2.781-2.215-5.035-4.945-5.035z"
            fill="#E6E6E6"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.907 22.4L.001 36.4v3.353c.007 2.773 2.217 5.022 4.944 5.022h3.426L30.595 22.4H13.907zm70.162 0h-3.413L58.433 44.773H75.12L89 30.801v-3.367c0-2.777-2.207-5.026-4.93-5.034zm-36.787 0l-22.25 22.4H41.72l22.25-22.4H47.282zM16.689 0c-4.607 0-8.344 3.76-8.344 8.4 0 4.638 3.737 8.4 8.344 8.4 4.607 0 8.343-3.762 8.343-8.4 0-4.64-3.736-8.4-8.343-8.4zm55.624 0c-4.607 0-8.344 3.76-8.344 8.4 0 4.638 3.737 8.4 8.344 8.4 4.607 0 8.343-3.762 8.343-8.4 0-4.64-3.736-8.4-8.343-8.4z"
            fill="#F7981D"
        />
    </svg>
)

export default SvgUnderConstruction
