import locale from '~/utils/locale'

export default locale.create<{
    operators: {
        less: string
        lessOrEqual: string
        equals: string
        greaterOrEqual: string
        greater: string
        startsWith: string
        endsWith: string
        contains: string
    }
}>()
