import { PaymentStatus } from '~/api'

import locale from '~/utils/locale'

export default locale.create<{
    loading: string,
    failed: string,
    statuses: {
        [key in PaymentStatus]: string;
    }
}>()
