export * from './contracts'
import { get, post } from '~/utils/ajax'
import { Question, Swagger } from './contracts'

import { DocumentNodeInterface } from '~/pages/documentation/document-node'

export const getFaq = () => get<Question[]>('faq')
    .then(response => {
        return response
    })
export const getSwagger = () => get<Swagger>('swagger.json', undefined, { baseUrl: 'static/', anonymous: true })
    .then(response => {
        return response
    })

export const getDocumentaionStructure = () => get<DocumentNodeInterface>(
    'structure.json?' + Date.now().toString(),
    undefined,
    {
        baseUrl: '/static/documentation/',
        anonymous: true
    })
    .then(response => {
        return response
    })
