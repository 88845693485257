import { observer } from 'mobx-react'
import React from 'react'
import NewsStore from '../news-store'
import AppStore from '~/app-store'
import Page from '~/layouts/main/page'
import { Row } from 'antd'
import res from '../res'
import { LeftOutlined } from '@ant-design/icons'
import styles from '../styles.css'
import FullCard from '../components/fullCard'
import BrowserStore from '~/services/browser/browser-state'


export default observer(({ store, appStore, newsId }: { store?: NewsStore, appStore: typeof AppStore, newsId: string }) => {
    return (
        <>
            <Page title={<div className={styles.title}><a onClick={() => store.GoBack()}><LeftOutlined className={styles.arrowLeft} color={'#1C9771'} /> </a>{res().newsTitle}</div>}>
                <Row>
                    <FullCard
                        isMobile={BrowserStore.isMobile}
                        date={store.newsInfo?.publish_time}
                        tags={[store.newsInfo?.tag, store.newsInfo?.seen_status]}
                        image={store.newsInfo?.image_url}
                        title={store.newsInfo?.title}
                        text={store.newsInfo?.description}
                        period={store.newsInfo?.promotion_periodstring}
                        link={store.newsInfo?.id}
                    />
                </Row>
            </Page>
        </>
    )
})