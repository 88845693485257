import React from 'react'
import { HintModal } from '../modal'
import InteractiveHintsStore from '../../../interactive-hints-store'
import { observer } from 'mobx-react'
import res from '../../../res'

interface Props {
    store: InteractiveHintsStore
}

const SkipSectionModal = observer(({ store }: Props) => {
    const { isVisibleSkipSectionModal } = store

    return (
        <HintModal
            visible={isVisibleSkipSectionModal}
            onCancel={() => store.cancelSkipSectionModal()}
            onOk={async () => {
                store.hideSkipSectionModal()
                // await store.cancelSkipSectionModal()
                await store.goNextSection()
            }}
            title={res().modals.skipSection.title}
            description={res().modals.skipSection.description}
            okText={res().modals.okBtn}
            cancelText={res().modals.cancelBtn} />
    )
})

export default SkipSectionModal