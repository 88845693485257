'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.Store = exports.MemoryStore = undefined

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i]
      descriptor.enumerable = descriptor.enumerable || false
      descriptor.configurable = true
      if ('value' in descriptor) descriptor.writable = true
      Object.defineProperty(target, descriptor.key, descriptor)
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps)
    if (staticProps) defineProperties(Constructor, staticProps)
    return Constructor
  }
})()
/**
 * Allow to store data in memory or Local Storage
 *
 */

var _find = require('lodash/find')

var _find2 = _interopRequireDefault(_find)

var _isEmpty = require('lodash/isEmpty')

var _isEmpty2 = _interopRequireDefault(_isEmpty)

var _isNumber = require('lodash/isNumber')

var _isNumber2 = _interopRequireDefault(_isNumber)

var _isObject = require('lodash/isObject')

var _isObject2 = _interopRequireDefault(_isObject)

var _isString = require('lodash/isString')

var _isString2 = _interopRequireDefault(_isString)

var _merge = require('lodash/merge')

var _merge2 = _interopRequireDefault(_merge)

var _remove = require('lodash/remove')

var _remove2 = _interopRequireDefault(_remove)

var _moment = require('moment')

var _moment2 = _interopRequireDefault(_moment)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function')
  }
}

var MemoryStore = (exports.MemoryStore = (function() {
  function MemoryStore() {
    _classCallCheck(this, MemoryStore)

    this._data = {}
    this.type = 'MemoryStore'
  }

  _createClass(MemoryStore, [
    {
      key: 'setItem',
      value: function setItem(key, value) {
        this._data[key] = value
      },
    },
    {
      key: 'getItem',
      value: function getItem(key) {
        return this._data[key]
      },
    },
    {
      key: 'removeItem',
      value: function removeItem(key) {
        this._data[key] = null
        delete this._data[key]
      },
    },
    {
      key: 'clear',
      value: function clear() {
        this._data = {}
      },
    },
  ])

  return MemoryStore
})())

var Store = (exports.Store = (function() {
  function Store() {
    var options =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}

    _classCallCheck(this, Store)

    var stores = {
        session: window.sessionStorage,
        local: window.localStorage,
        memory: new MemoryStore(),
      },
      type = options.type || 'local'

    this.storage = stores[type]
  }

  /**
   * reset storage
   */

  _createClass(Store, [
    {
      key: 'reset',
      value: function reset() {
        this.storage.clear()
      },

      /**
       * return data base on key
       */
    },
    {
      key: 'find',
      value: function find(key, defaultValue) {
        var data = this.read(key)
        if ((0, _isEmpty2.default)(data) && defaultValue) {
          return defaultValue
        } else {
          return data
        }
      },
    },
    {
      key: 'insert',
      value: function insert(key, data, expireAfter) {
        return this.write(key, data, expireAfter)
      },

      /**
       * set data
       * @param options
       * @return {data}
       */
    },
    {
      key: 'set',
      value: function set(options) {
        var expireAfter = options.expire_after
        delete options.expire_after
        return this.write(options.key, options, expireAfter)
      },

      /**
       * find the store data with key
       * @param key
       * @return Object
       */
    },
    {
      key: 'read',
      value: function read(key) {
        var object = this.toJSON(this.storage.getItem(key))

        if (object._expires_at) {
          //check if data has expire_at

          var expiresAt = object._expires_at
          if ((0, _isString2.default)(expiresAt)) {
            expiresAt = new Date(expiresAt)
          }

          if (new Date() <= expiresAt) {
            // check if date  is less then  or equal to now then return data
            return object
          } else {
            // else nullify the key and delete the reference
            this.clear(key)
            return {}
          }
        }
        return object
      },

      /**
       *  store with data with key
       * @param key
       * @param {string} [expireAfter] expire in minutes
       * @return data
       * @param object
       */
    },
    {
      key: 'write',
      value: function write(key, object, expireAfter) {
        object = (0, _isObject2.default)(object) ? object : { data: object } // check if data is an object otherwise create data set as object
        if (expireAfter) {
          // if the  options has expire_after create the date object and assigns to object
          this.setExpire(object, expireAfter)
        }
        this.storage.setItem(key, JSON.stringify(object))

        return object
      },

      /**
       * set Expire date to an object
       * @param object
       * @param expireAfter
       */
    },
    {
      key: 'setExpire',
      value: function setExpire(object, expireAfter) {
        if ((0, _isNumber2.default)(expireAfter)) {
          object._expires_at = (0, _moment2.default)()
            .add(expireAfter, 'minutes')
            .toDate()
        } else {
          var expire = expireAfter.split('.')
          object._expires_at = (0, _moment2.default)()
            .add(parseInt(expire[0]), expire[1])
            .toDate()
        }
      },

      /**
       * remove store item by key
       * @param key
       */
    },
    {
      key: 'clear',
      value: function clear(key) {
        this.storage.removeItem(key)
      },

      /**
       * add item to a colleciton of items
       * @param key
       * @param data
       * @returns {{}|*}
       */
    },
    {
      key: 'addItem',
      value: function addItem(key, data) {
        var object = this.read(key)
        object.items = object.items || []
        object.items.push(data)
        this.insert(key, object)
        return object
      },

      /**
       * Remove Item from collection
       * @param key
       * @param callback
       * @returns {{}|*}
       */
    },
    {
      key: 'removeItem',
      value: function removeItem(key, callback) {
        var object = this.read(key)
        ;(0, _remove2.default)(object.items, callback)
        this.insert(key, object)
        return object
      },

      /**
       * Update Or Insert item to the collection
       * @param key
       * @param data
       * @param callback
       * @returns {{}}
       */
    },
    {
      key: 'updateItem',
      value: function updateItem(key, data, callback) {
        var object = this.read(key),
          item = (0, _find2.default)(object.items, callback)
        var update = {}

        if (item) {
          update = (0, _merge2.default)(item, data)
        }
        this.insert(key, object)
        return update
      },

      /**
       * Find item from keys
       * @param key
       * @param callback
       */
    },
    {
      key: 'findItem',
      value: function findItem(key, callback) {
        var object = this.read(key)
        return (0, _find2.default)(object.items, callback)
      },

      /**
       *  return data form the object
       * @param key
       * @return json data
       */
    },
    {
      key: 'get',
      value: function get(key) {
        return this.find(key).data
      },

      /**
       * get list
       * @returns  (Storage)
       */
    },
    {
      key: 'list',
      value: function list() {
        return this.storage
      },
    },
    {
      key: 'toJSON',
      value: function toJSON() {
        var data =
          arguments.length > 0 && arguments[0] !== undefined
            ? arguments[0]
            : '{}'

        data = data || '{}'
        return JSON.parse(data)
      },
    },
  ])

  return Store
})())
