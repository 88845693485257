import { ProductRequestStatus } from '~/api/products'
import locale from '~/utils/locale'

export default locale.create<{
    title: string
    contracts: string
    contract: string
    sortBy: string
    sortOptions: {
        date: string
        name: string
        contract: string
        type: string
        asc: {
            date: string
            name: string
            contract: string
            type: string
        },
        desc: {
            date: string
            name: string
            contract: string
            type: string
        }
    }
    errors: {
        productsLoadingError: string
        productCreatingError: string
        productUpdatingError: string
        productHistoryLoadingError: string
    }
    createInquiry: string
    inquiryFields: {
        companyInfo: string
        iinBin: string
        traderTypeLabel: string
        traderType: string
        traderName: string
        traderNameLt: string
        mainActivity: string
        createdDate: string
        serviceBank: string
        servicePeriodOfBank: string
        hasCredits: string
        registerCompanyAddress: string
        registerCompanyAddressZip: string
        realCompanyAddress: string
        realCompanyAddressZip: string
        realPhone: string
        realFax: string
        contactPhone: string
        premisesOwnership: string
        lessorName: string
        leaseTerm: string
        hasEncumbranceOnProperty: string
        registrationAddress: {
            type: string
            countryCode: string
            zip: string
            regionCode: string
            districtCode: string
            cityCode: string
            townCode: string
            microdistrictCode: string
            street: string
            buildingNumber: string
            apartmentNumber: string
            fullAddress: string
        }
        ceoShortInfoLabel: string
        personLegalAddressLabel: string
        personRealAddressLabel: string
        chiefAccountantShortInfoLabel: string
        cheifAccountantDoesNotExists: string
    },
    tooltips: {
        enabledStatus: string
        disabledStatus: string
        noEmails: string
        downloadproduct: string
        productCreating: string
        reload: string
        productSettings: string
    }
    dateRanges: {
        yesterday: string
        priorWeek: string
        priorMonth: string
        customPeriod: string
    }
    statusType: {
        [key in ProductRequestStatus]: string
    }
    statusSteps: {
        [key in ProductRequestStatus]: string
    }
    statusAction: {
        [key in ProductRequestStatus]: string
    }
    pageInfo: (info: { from: number, to: number, total: number }) => React.ReactNode
    nextButton: string
    previousButton: string
    automaticproducts: string
    productHistory: string
    productsFor: string
    active: string
    inactive: string
    product: string
}>()
