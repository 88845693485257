import res from './res'

res.add('en', {
    title: 'Employees',
    role: {
        admin: 'Admin',
        manager: 'Manager',
        accountant: 'Accountant',
        view: 'Viewer'
    },
    status: {
        active: 'Active',
        waitingForRegistration: 'Waiting for registration'
    },
    cols: {
        email: 'Email',
        role: 'Role',
        status: 'status',
        lastConnections: 'Last activity',
        control: 'Control',
        controlsTooltip: {
            edit: 'Edit',
            delete: 'Delete'
        }
    },
    invite: 'Submit your application',
    okText: 'Ok',
    cancelText: 'Cancel',
    deleteConfirmContent:
        'This action will disable the team member. They will not be able to access any of the content on this portal.',
    deleteConfirmTitle: 'Do you want to disable this team member?',
    deleteNotificationDescription:
        'Team member is now unable to access this portal.',
    deleteNotificationTitle: 'Done',
    emailExists: 'A team member with given email already exists.',
    emailExistsDescription:
        'Please, provide a different email to add a new team member.',
    newEmployeeCreatedNotificationDescription: (email: string) =>
        `The invitation was successfully sent to ${email}`,
    newEmployeeCreatedNotificationTitle: 'Sent!',
    employeeRoleUpdatedDescription:
        'The role of a team member has been updated',
    employeeRoleUpdatedTitle: 'Done!'
})
