import React from 'react'

const SvgFaq = props => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" {...props}>
        <g fillRule="nonzero">
            <ellipse cx={509.692} cy={765.844} rx={59.077} ry={58.834} />
            <path d="M512 0C229.032 0 0 228.053 0 509.896c0 281.805 228.994 509.896 512 509.896 282.968 0 512-228.053 512-509.896C1024 228.09 795.006 0 512 0zm-7.04 926.098C270.097 926.098 80 736.814 80 502.885 80 268.987 270.065 79.67 504.96 79.67c234.863 0 424.96 189.284 424.96 423.214 0 233.898-190.065 423.213-424.96 423.213z" />
            <path d="M504 228.56c-86.867 0-157.538 75.732-157.538 166.361 0 22.695 17.632 41.09 39.384 41.09s39.385-18.395 39.385-41.09c0-45.314 35.336-82.18 78.769-82.18 43.433 0 78.77 36.866 78.77 82.18 0 45.315-35.337 82.181-78.77 82.181-21.752 0-39.385 18.397-39.385 41.09V620.92c0 22.694 17.633 41.09 39.385 41.09s39.385-18.396 39.385-41.09v-66.836c67.871-18.291 118.153-82.715 118.153-159.162 0-90.629-70.671-166.362-157.538-166.362z" />
        </g>
    </svg>
)

export default SvgFaq
