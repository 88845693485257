import React from 'react'

import res from './res'

res.add('en', {
    dataFilterPlaceholder: 'Attributes filter',
    loading: 'Loading payments...',
    payment: 'Payment',
    pageInfo: (info: { from: number; to: number; total: number }) => (
        <>
            {info.from}-{info.to}&nbsp;&nbsp;of&nbsp;&nbsp;{info.total}
        </>
    ),
    operationsResult: (totalCount: number, successCount: number) =>
        `${successCount} out of ${totalCount} operations completed successfully`,
    actions: 'Actions',
    gridControlsTooltips: {
        reload: 'Reload data',
        settings: 'Table customization',
        firstPage: 'First page',
        prevPage: 'Previous page',
        nextPage: 'Next page',
        lastPage: 'Last page'
    },
    filters: 'Filters'
})
