import { Radio } from 'antd'
import React from 'react'
import styles from '../../styles.css'

interface Item {
    title: string
    value: string
}
interface RadioMiniGroupProp {
    currentValue: string
    title: string
    value: string
    items?: Item[]
}

export const RadioMiniGroup = ({ title, value, currentValue, items }: RadioMiniGroupProp) => {
    const isActive = value === currentValue || items?.find(({ value }) => value === currentValue)
    return (
        <div className={`${styles.radioMiniBtn} ${isActive ? 'active' : ''}`}>
            <Radio value={value} className={`${styles.radioMini} ${isActive ? 'active' : ''}`}>
                {title}
            </Radio>
            {isActive && items?.map(({ title, value }) =>
                <Radio value={value} className={styles.radioMiniSub} key={value}>{title}</Radio>
            )}
        </div>
    )
}