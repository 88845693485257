import res from './res'

res.add('en', {
    title: 'Terminal Settings for shop ',
    confirmTitle: 'Are you sure you want to load data?',

    labels: {
        isActive: 'Active',
        saveCard: 'Save Card',
        singleMessage: 'Single Message Scheme',
        terminalId: 'Terminal Id',
        save: 'Save'
    },
    messages: {
        errorTitle: 'Error',
        cannotLoadShops: 'Cannot load merchant shops',
        unsavedChanges: 'All unsaved changes will be lost, if you proceed with operation',
        saveSuccess: 'Changes are saved successfully'
    }
})
