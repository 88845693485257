import { get, post } from '~/utils/ajax'

// import { getCardSystemType } from '~/utils'

import {
    EpayPaymentRequest,
    EpayPaymentResponse,
    EpaySummary,
    EpaySummaryRequest
} from './contracts'

export * from './contracts'

export const getEpayPayments = (request: EpayPaymentRequest) =>
    post<EpayPaymentResponse>('operations', request).then(response => {
        // if (!response.error && !!response.result && !!response.result.records) {
        //     response.result.records.forEach(p => {
        //         p.cardType = p.cardMask
        //             ? getCardSystemType(p.cardMask)
        //             : undefined
        //         p.fee = undefined
        //         p.ipCountry = 'Казахстан'
        //         p.ipCity = 'г. Алматы'
        //         p.ipRegion = 'Алматинская обл.'
        //     })
        // }
        return response
    })

export const getEpaySummary = (request: EpaySummaryRequest) =>
    get<EpaySummary>('operations/summary', {
        from: request.from.format('YYYY-MM-DD'),
        to: request.to.format('YYYY-MM-DD')
    }).then(data => {
        if (!data.error) {
            data.result.timeline.forEach(item => {
                item.time = new Date(item.time)
            })
        }

        return data
    })

export const cancelPayment = (paymentId: string, amount?: number, purpose?: string) =>
    post<void>(`operation/${paymentId}/cancel`, { purpose })

export const chargePayment = (paymentId: string, amount?: number, purpose?: string) =>
    post<void>(
        `operation/${paymentId}/charge`, { amount, purpose }
    )

export const refundPayment = (paymentId: string, amount?: number, purpose?: string) =>
    post<void>(
        `operation/${paymentId}/refund`,
        amount === undefined ? undefined : { amount, purpose }
    )

export const refundCreditPayment = (creditOrderId, creditAmount, terminalId) =>
    post<void>('credit-cancel', { orderId: creditOrderId, amount: creditAmount, terminalId })
