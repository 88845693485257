import { notification } from 'antd'
import { remove } from 'lodash'
import {
    action,
    IObservableArray,
    IObservableValue,
    observable,
    runInAction,
    toJS
} from 'mobx'
import page from 'page'
import { apiCheck, getProfile } from '~/api'
import {
    deleteTeamMember,
    getMembers,
    inviteNewMember,
    NewTeamMemberRequest,
    TeamMember,
    TeamRoles,
    updateRole,
    UpdateRoleRequestBody
} from '~/api/team'
import { noThrow } from '~/utils/control-flow'
import message from '~/utils/message'
import res from './res'
import BrowserStore from '~/services/browser/browser-state'
import InteractiveMobileHintsStore from '~/components/interactive-mobile-hints/interactive-mobile-hints-store'
import InteractiveHint from '~/utils/interactive-hint'

export default class TeamStore {
    constructor() {
        this.loadMembers()

        setTimeout(() => {
            this.showHintOnMobile()
        }, 500)
    }

    @observable
    public notAllowed: boolean

    @observable
    public isInvitationModalVisible: boolean = false

    @observable
    public invitedEmployeeRole: TeamRoles = TeamRoles.manager

    @observable
    public invitedEmployee?

    @observable
    public isEmployeeNew: boolean

    @observable
    public teamLoading: boolean = true

    @observable
    public confirmDeleteShown: boolean = false

    @observable
    public teamMembers: TeamMember[] = []

    @observable.ref
    public mobileHintStore: InteractiveMobileHintsStore

    @action
    public showHints(sectionKeys: string[]) {
        this.mobileHintStore = new InteractiveMobileHintsStore(sectionKeys)
    }

    @action
    public hideHints() {
        this.mobileHintStore = null
    }

    @action
    public async loadMembers() {
        const profile = await getProfile()

        runInAction(() => {
            this.teamLoading = true
        })
        const {
            value,
            error
        }: { value?: TeamMember[]; error?: any } = await noThrow(
            apiCheck(getMembers(profile?.result?.merchantId))
        )

        runInAction(() => {
            if (error) {
                this.notAllowed = true
                // this.teamMembers = []
                // return message.error(error)
            } else {
                this.notAllowed = false
            }

            if (value) {
                this.teamMembers = value
            } else {
                this.teamMembers = []
            }

            this.teamLoading = false
        })
    }

    @action.bound
    public setInvitedEmployeeRole(role: TeamRoles) {
        this.invitedEmployeeRole = role
        /* if (this.invitedEmployeeRole.includes(role)) {
            this.invitedEmployeeRole.remove(role)
            remove(this.invitedEmployee.role, rl => rl === role)
        } else {
            this.invitedEmployeeRole.push(role)
            this.invitedEmployee.role.push(role)
        } */
    }

    @action.bound
    public setInvitedEmployeeField(field: string, value: string) {
        this.invitedEmployee[field] = value
    }

    @action.bound
    public toggleInvitationModal = () =>
        (this.isInvitationModalVisible = !this.isInvitationModalVisible)

    @action.bound
    public openInvitationModal(item?: TeamMember) {
        if (!item) {
            this.isEmployeeNew = true
            this.invitedEmployee = this.emptyEmployee
        } else {
            this.isEmployeeNew = false
            this.invitedEmployee = toJS(item)
            this.invitedEmployeeRole = toJS(item.role)
        }

        this.toggleInvitationModal()
    }

    @action.bound
    public async applyEmployeeConfig(newMemberInfo: NewTeamMemberRequest) {
        if (this.isEmployeeNew) {
            const {
                value,
                error
            }: { value?: any; error?: any } = await noThrow(
                apiCheck(inviteNewMember(newMemberInfo))
            )

            runInAction(() => {
                if (error) {
                    message.error(error)
                } else {
                    notification.success({
                        message: res().newEmployeeCreatedNotificationTitle,
                        description: res().newEmployeeCreatedNotificationDescription(
                            newMemberInfo.email
                        )
                    })
                }
                this.loadMembers()
            })
        } else {
            const updateRoleRequestBody: UpdateRoleRequestBody = {
                role: newMemberInfo.role
            }
            const { publicId } = this.invitedEmployee
            const {
                value,
                error
            }: { value?: any; error?: any } = await noThrow(
                apiCheck(updateRole(publicId, updateRoleRequestBody))
            )
            runInAction(() => {
                if (error) {
                    message.error(error)
                } else {
                    notification.success({
                        message: res().employeeRoleUpdatedTitle,
                        description: res().employeeRoleUpdatedDescription
                    })
                }
                this.loadMembers()
            })
        }
        // if (this.invitedEmployee.id) {
        //     const index = this.teamMembers.findIndex(
        //         x => x.id === this.invitedEmployee.id
        //     )
        //     this.teamMembers[index] = this.invitedEmployee
        // } else {
        //     this.teamMembers.push(this.invitedEmployee)
        // }

        this.toggleInvitationModal()
    }

    @action
    public async handleDeleteTeamMember(teamMemberPublicId: string) {
        this.confirmDeleteShown = true
        await deleteTeamMember(teamMemberPublicId)
        this.loadMembers()
    }

    private showHintOnMobile() {
        if (BrowserStore.isMobile) {
            const hintSections = InteractiveHint.getPageFlags('team')

            if (hintSections) {
                this.showHints(hintSections)
            }
        }
    }

    private emptyEmployee: NewTeamMemberRequest = {
        email: null,
        role: TeamRoles.manager
    }
}
