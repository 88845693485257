import { computed } from 'mobx'

import strict from '~/utils/strict'

import { SortOption } from '~/pages/payments/components/sort-config-select'

import { ContractStatementInfo } from '~/api/statements-oct'
import res from '../res'

const options = computed(() => {
    const titles = res().sortOptions

    return strict<
        Array<{
            title: string
            items: Array<SortOption<ContractStatementInfo>>
        }>
    >([
        {
            title: res().sortOptions.name,
            items: [
                {
                    field: 'name',
                    direction: 'desc',
                    title: titles.desc.name
                },
                {
                    field: 'name',
                    direction: 'asc',
                    title: titles.asc.name
                }
            ]
        },
        {
            title: res().sortOptions.contract,
            items: [
                {
                    field: 'contract',
                    direction: 'desc',
                    title: titles.desc.contract
                },
                {
                    field: 'contract',
                    direction: 'asc',
                    title: titles.asc.contract
                }
            ]
        },
        {
            title: res().sortOptions.type,
            items: [
                {
                    field: 'type',
                    direction: 'desc',
                    title: titles.desc.type
                },
                {
                    field: 'type',
                    direction: 'asc',
                    title: titles.asc.type
                }
            ]
        }
    ])
})

export default options
