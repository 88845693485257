import React, { ReactNode } from 'react'

import { List, Spin } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import styles from './styles.css'

export interface LoadRequestInterface {
    startIndex: number
    stopIndex: number
}

export interface InfinitiveListProps<T> {
    dataSource: T[]
    loaded: boolean
    dataLoader: (request: LoadRequestInterface) => void
    itemRenderer: (item: T) => ReactNode
    pageSize: number
    scrollTopTrigger?: number
}
interface InfinitiveListState {
    hasMore?: boolean
    loading?: boolean
    scrollTopTrigger?: number
    scrollToTop?: boolean
}

export class InfinitiveList<T> extends React.Component<
    InfinitiveListProps<T>,
    {}
> {
    public static getDerivedStateFromProps(
        nextProps: InfinitiveListProps<any>,
        prevState: InfinitiveListState
    ) {
        const stateChange: InfinitiveListState = { loading: !nextProps.loaded }
        if (nextProps.scrollTopTrigger !== prevState.scrollTopTrigger) {
            stateChange.scrollToTop = true
            stateChange.scrollTopTrigger = nextProps.scrollTopTrigger
        }
        return stateChange
    }

    public state: InfinitiveListState = {
        hasMore: true,
        loading: false,
        scrollToTop: false,
        scrollTopTrigger: 0
    }

    public loadedRowsMap = {}
    public scrollContainerRef: any

    public componentDidUpdate() {
        if (this.state.scrollToTop) {
            this.scrollToTop()
        }
    }

    public handleInfiniteOnLoad = ({ startIndex, stopIndex }) => {
        const data = this.props.dataSource
        this.setState({
            loading: true
        })
        if (data.length < this.props.pageSize) {
            this.setState({
                hasMore: false,
                loading: false
            })
            return
        }
        this.props.dataLoader({ startIndex, stopIndex })
    }

    public isRowLoaded = ({ index }) => !!this.loadedRowsMap[index]

    public renderItem = ({ index, key, style }) => {
        const data = this.props.dataSource
        const item = data[index]
        return this.props.itemRenderer(item)
    }
    public scrollToTop() {
        const elements = document.getElementsByClassName(
            styles.spinningContainer
        )
        if (elements.length > 0) {
            elements[0].parentElement.scrollTop = 0
            this.setState({ scrollToTop: false })
        }
    }
    public render() {
        const data = this.props.dataSource ? this.props.dataSource : []
        const spininig =
            !this.props.loaded || (this.state.loading && this.state.hasMore)
        return (
            <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={this.handleInfiniteOnLoad}
                hasMore={!this.state.loading && this.state.hasMore}
                useWindow={false}
                className={styles.spinningContainer}
            >
                <Spin spinning={spininig}>
                    <div ref={ref => (this.scrollContainerRef = ref)}>
                        <List
                            dataSource={this.props.dataSource}
                            renderItem={this.props.itemRenderer}
                        />
                    </div>
                </Spin>
            </InfiniteScroll>
        )
    }
}
