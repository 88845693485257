import React from 'react'

const SvgMenu = props => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" {...props}>
        <g fillRule="evenodd">
            <path d="M0 0h1024v128H0zM0 448h1024v128H0zM0 896h1024v128H0z" />
        </g>
    </svg>
)

export default SvgMenu
