import React, { ReactNode } from 'react'

import { observer } from 'mobx-react'
import Page from '~/layouts/main/page'
import CreateOrderStore from './create-order-store'
import { Progress, Form, Tooltip, Radio } from 'antd'


import res from './res'
import styles from './styles.css'

import GeneralInformation from './components/GeneralInformation'
import StoreDescription from './components/StoreDescription'
import Documents from './components/Documents'

import { Button, Input, InputItem, Select } from '~/components'

const { Option } = Select
import actions from '~/actions'
import { QuestionCircleOutlined } from '@ant-design/icons'
import PhoneInput from 'react-phone-input-2'
import { FormComponentProps } from 'antd/lib/form'
import TeamStore from '~/pages/settings/team/team-store'
import { ModalContainerContext } from '~/components/modal/modal-context'

interface CreateOrderProps extends FormComponentProps {
    store: CreateOrderStore
}

@observer
class CreateOrder extends React.Component<CreateOrderProps, {}> {

    public render() {

        const { store } = this.props
        const { form } = this.props

        return (
            <>
                {store.orderStatusName === 'INPROGRESS' &&
                    (<Page
                        title={
                            <div>
                                <div> {res().title} </div>
                            </div>
                        }
                    >
                        <div className={styles.container}>
                            <ul className={styles.menu}>
                                <li className={`${styles.menuItem}`}>
                                    <a href="" onClick={() => store.changeStep(1)}>
                                        <span className={`${styles.menuLink} ${store.step === 1 && styles.menuActive}`}> Общая информация </span>
                                    </a>
                                    {store.step === 1 && <Progress percent={store.firstStepPercent} strokeColor={'#3EA382'} strokeWidth={4} />}
                                </li>
                                <li className={`${styles.menuItem}`}>
                                    <a href="" onClick={() => store.firstStepPercent === 100 ? store.changeStep(2) : false}>
                                        <span className={`${styles.menuLink} ${store.step === 2 && styles.menuActive}`}> Описание интернет-магазина </span>
                                    </a>
                                    {store.step === 2 && <Progress percent={store.secondStepPercent} strokeColor={'#3EA382'} strokeWidth={4} />}
                                </li>
                                <li className={`${styles.menuItem}`}>
                                    <a href="" onClick={() => store.secondStepPercent === 100 ? store.changeStep(3) : false}>
                                        <span className={`${styles.menuLink} ${store.step === 3 && styles.menuActive}`}> Документы </span>
                                    </a>
                                    {/*{store.step === 3 && <Progress percent={store.thirdProgress} strokeColor={'#3EA382'} strokeWidth={4}/>}*/}
                                </li>
                            </ul>
                            <div className={styles.dataForm}>
                                <GeneralInformation store={store} form={form} />
                                <StoreDescription store={store} form={form} />
                                <Documents store={store} />
                            </div>
                        </div>

                    </Page>
                    )}

                {store.orderStatusName === 'SENT' &&
                    <Page>
                        <div>
                            <div className={styles.result}>
                                <h2 className={styles.title}> Заявка создана! </h2>
                                <div className={styles.text}>
                                    Спасибо за заполнение анкеты, наши менеджеры рассмотрят заявку и свяжутся <br /> с Вами в ближайшее время
                                </div>

                                <Button type="primary" size="large" onClick={() => actions.openCurrentOrder()}>
                                    Завершить
                                </Button>
                            </div>
                        </div>
                    </Page>
                }

                {store.orderStatusName === 'FAILED' &&
                    <Page>
                        <div>
                            <div className={styles.result}>
                                <h2 className={styles.title}> Ошибка при отправке! </h2>
                                <div className={styles.text}>
                                    Произошла ошибка при отправке заявки, <br /> повторите, пожалуйста, еще раз.
                                </div>

                                <Button type="primary" size="large" onClick={() => store.changeStatus()}>
                                    Повторить
                                </Button>
                            </div>
                        </div>
                    </Page>
                }

            </>
        )
    }
}

const CreateOrderForm = Form.create({})(CreateOrder) as any

export default CreateOrderForm