import React from 'react'
import { HintModal } from '../modal'
import InteractiveHintsStore from '../../../interactive-hints-store'
import { observer } from 'mobx-react'
import AppStore from '~/app-store'
import res from '../../../res'

interface Props {
    store: InteractiveHintsStore
    appStore: typeof AppStore
}

const FirstSuccessTransaction = observer(({ store, appStore }: Props) => {
    const { isVisibleFirstSuccessTransactionModal } = store

    return (
        <HintModal
            visible={isVisibleFirstSuccessTransactionModal}
            onCancel={async () => {
                store.hideFirstSuccessTransactionModal()
                await store.updateSectionFlag('transactions')
                setTimeout(() => appStore.hideInteractiveHints(), 250)
            }}
            onOk={() => {
                store.hideFirstSuccessTransactionModal()
                store.startShowing()
            }}
            title={res().modals.firstSuccessTransaction.title}
            description={res().modals.firstSuccessTransaction.description}
            okText={res().modals.firstSuccessTransaction.okBtn}
            cancelText={res().modals.firstSuccessTransaction.cancelBtn} />
    )
})

export default FirstSuccessTransaction