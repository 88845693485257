import React from 'react'

import res from './res'

const getPaymentCountLabel = (paymentsCount: number) => {
    if (paymentsCount >= 5 && paymentsCount <= 20) {
        return 'платежей'
    }

    const lastDigit = paymentsCount % 10

    if (lastDigit === 1) {
        return 'платеж'
    }

    if (lastDigit >= 2 && lastDigit <= 4) {
        return 'платежа'
    }

    return 'платежей'
}

const getSelectedText = (count: number) => {
    return count % 10 === 1 ? 'выбран' : 'выбрано'
}

res.add('ru', {
    selectedPaymentsLabel: (
        paymentCount: number,
        amountsContent: React.ReactNode
    ) => (
        <span>
            {paymentCount} {getPaymentCountLabel(paymentCount)} на сумму{' '}
            {amountsContent} {getSelectedText(paymentCount)}
        </span>
    ),
    clearButton: 'Снять выделение'
})
