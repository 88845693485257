import styles from '~/pages/orders/create-order/styles.css'
import { Button, notification } from '~/components'
import { Tooltip, Upload } from 'antd'
import { ExclamationCircleOutlined, FileTextOutlined } from '@ant-design/icons'
import res from '~/pages/orders/create-order/res/res'
import { RcFile } from 'antd/lib/upload'
import React from 'react'
import { observer } from 'mobx-react'
import CreateOrderStore from '~/pages/orders/create-order/create-order-store'

const { Dragger } = Upload

import documentsList from '~/assets/files/documents-list.pdf'
import applicationForm from '~/assets/files/application-form.pdf'
import { logEventFirebase } from '~/pages/orders/create-order/services/firebase'


const Documents = observer(({ store }: { store: CreateOrderStore }) => {
    return (
        <>
            {store.step === 3 && (
                <div className={`${styles.step} ${store.step === 3 && styles.stepActive}`}>

                    {store.serviceBank === true ? (
                        <div className={styles.section}>
                            <div className={styles.subtitle}> Ваши документы уже есть в системе, осталось заполнить заявку</div>
                            <div className={styles.attention}>
                                <div className={styles.attentionTitle}> Внимание!</div>
                                <ul className={styles.attentionList}>
                                    <li>Распечатайте заявку и заполните её, в ближайшее время с вами свяжется менеджер.</li>
                                    <li>Если документы обновлялись или вы не уверены, что они у нас есть, просим загрузить их.</li>
                                    <li>При загрузке документов, название загружаемого файла должно соответствовать наименованию документа.</li>
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.section}>
                            <div className={styles.subtitle}> Прикрепите документы, подтверждающие Ваше предприятие</div>
                            <div className={styles.attention}>
                                <div className={styles.attentionTitle}> Внимание!</div>
                                <ul className={styles.attentionList}>
                                    <li>Распечатайте заявку и заполните её, в ближайшее время с вами свяжется менеджер.</li>
                                    <li>Вы не являетесь клиентом Народного банка Казахстана. Прикрепите максимально возможное количество документов. (В случае отсутствия какого-либо документа, менеджер свяжется с вами)</li>
                                    <li>При загрузке документов, название загружаемого файла должно соответствовать наименованию документа.</li>
                                </ul>
                            </div>
                        </div>
                    )}

                    <table className={styles.documents}>
                        <thead>
                            <tr>
                                <td colSpan={2}>
                                    <div className={styles.head}>
                                        <div className={styles.subtitle}> Список документов</div>
                                        <a href={documentsList} className="ant-btn ant-btn-lg" download>
                                            Перечень всех документов
                                    </a>
                                    </div>
                                </td>
                            </tr>
                        </thead>
                        {store.companyForm === 'too' && (
                            <tbody>
                                <tr>
                                    <td className={styles.order}>
                                        <Tooltip title="Обязательное заполнение всех полей"
                                            overlayClassName={styles.tooltipStyle}
                                            overlayStyle={{ maxWidth: '500px' }}>
                                            <span className={styles.exclamationIcon}> <ExclamationCircleOutlined /> </span>
                                        </Tooltip>
                                    </td>
                                    <td className={styles.thead}>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}>
                                                <strong> Заявка на подключение </strong>
                                            (После загрузки документов необходимо скачать заявку и заполнить её,
                                            потребуется оригинал при подаче документов)
                                        </div>
                                            <a href={applicationForm} className="ant-btn ant-btn-lg" download>
                                                {res().buttons.download}
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.order}>1</td>
                                    <td>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}> {res().documents.stateRegister}</div>
                                            <span className={styles.exclamationIcon}>
                                                <Tooltip
                                                    placement="left"
                                                    title={res().tooltips.stateRegister}
                                                    overlayStyle={{ maxWidth: '580px' }}><ExclamationCircleOutlined />
                                                </Tooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.order}>2</td>
                                    <td>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}> {res().documents.statute}</div>
                                            <span className={styles.exclamationIcon}>
                                                <Tooltip
                                                    placement="left"
                                                    title={res().tooltips.statute}
                                                    overlayStyle={{ maxWidth: '500px' }}>
                                                    <ExclamationCircleOutlined /></Tooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.order}>3</td>
                                    <td>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}> {res().documents.decision}</div>
                                            <span className={styles.exclamationIcon}>
                                                <Tooltip
                                                    placement="left"
                                                    title={res().tooltips.decision}
                                                    overlayStyle={{ maxWidth: '580px' }}>
                                                    <ExclamationCircleOutlined /></Tooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.order}>4</td>
                                    <td>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}>
                                                {res().documents.identityCardAccountant}
                                            </div>
                                            <span className={styles.exclamationIcon}>
                                                <Tooltip
                                                    placement="left"
                                                    title={res().tooltips.identityCardLeader}
                                                    overlayStyle={{ maxWidth: '580px' }}>
                                                    <ExclamationCircleOutlined /></Tooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.order}>5</td>
                                    <td>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}>
                                                {res().documents.identityCardLeader}
                                            </div>
                                            <span className={styles.exclamationIcon}>
                                                <Tooltip
                                                    placement="left"
                                                    title={res().tooltips.identityCardLeader}
                                                    overlayStyle={{ maxWidth: '580px' }}>
                                                    <ExclamationCircleOutlined /></Tooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.order}>6</td>
                                    <td>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}>
                                                {res().documents.requisites}
                                            </div>
                                            <span className={styles.exclamationIcon}>
                                                <Tooltip
                                                    placement="left"
                                                    title={res().tooltips.requisites}
                                                    overlayStyle={{ maxWidth: '580px' }}>
                                                    <ExclamationCircleOutlined /></Tooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.order}>7</td>
                                    <td>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}>
                                                {res().documents.leaderSignatureToo}
                                            </div>
                                            <span className={styles.exclamationIcon}>
                                                <Tooltip
                                                    placement="left"
                                                    title={res().tooltips.leaderSignature}
                                                    overlayStyle={{ maxWidth: '580px' }}>
                                                    <ExclamationCircleOutlined /></Tooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.order}>8</td>
                                    <td>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}>
                                                {res().documents.constituentDocuments}
                                            </div>
                                            <span className={styles.exclamationIcon}>
                                                <Tooltip
                                                    placement="left"
                                                    title={res().tooltips.constituentDocuments}
                                                    overlayStyle={{ maxWidth: '580px' }}>
                                                    <ExclamationCircleOutlined /></Tooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.order}>9</td>
                                    <td>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}>
                                                {res().documents.powerOfAttorney}
                                            </div>
                                            <span className={styles.exclamationIcon}>
                                                <Tooltip
                                                    placement="left"
                                                    title={res().tooltips.powerOfAttorney}
                                                    overlayStyle={{ maxWidth: '580px' }}>
                                                    <ExclamationCircleOutlined /></Tooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.order}>10</td>
                                    <td>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}>
                                                {res().documents.identityCardAccountant}
                                            </div>
                                            <span className={styles.exclamationIcon}>
                                                <Tooltip
                                                    placement="left"
                                                    title={res().tooltips.stateRegister}
                                                    overlayStyle={{ maxWidth: '580px' }}>
                                                    <ExclamationCircleOutlined /></Tooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                        {store.companyForm === 'ip' && (
                            <tbody>
                                <tr>
                                    <td className={styles.order}>
                                        <Tooltip title="Обязательное заполнение всех полей"
                                            overlayClassName={styles.tooltipStyle}
                                            overlayStyle={{ maxWidth: '500px' }}>
                                            <span className={styles.exclamationIcon}> <ExclamationCircleOutlined /> </span>
                                        </Tooltip>
                                    </td>
                                    <td className={styles.thead}>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}>
                                                <strong> Заявка на подключение </strong>
                                            (После загрузки документов необходимо скачать заявку и заполнить её,
                                            потребуется оригинал при подаче документов)
                                        </div>
                                            <a href={applicationForm} className="ant-btn ant-btn-lg" download>
                                                {res().buttons.download}
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.order}>1</td>
                                    <td>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}> {res().documents.stateRegister}</div>
                                            <span className={styles.exclamationIcon}>
                                                <Tooltip
                                                    placement="left"
                                                    title={res().tooltips.stateRegister}
                                                    overlayStyle={{ maxWidth: '580px' }}><ExclamationCircleOutlined />
                                                </Tooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.order}>2</td>
                                    <td>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}>
                                                {res().documents.stateRegister}
                                            </div>
                                            <span className={styles.exclamationIcon}>
                                                <Tooltip
                                                    placement="left"
                                                    title={res().tooltips.stateRegister}
                                                    overlayStyle={{ maxWidth: '580px' }}>
                                                    <ExclamationCircleOutlined /></Tooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.order}>3</td>
                                    <td>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}>
                                                {res().documents.identityCardAccountant}
                                            </div>
                                            <span className={styles.exclamationIcon}>
                                                <Tooltip
                                                    placement="left"
                                                    title={res().tooltips.identityCardAccountant}
                                                    overlayStyle={{ maxWidth: '580px' }}>
                                                    <ExclamationCircleOutlined /></Tooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.order}>4</td>
                                    <td>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}>
                                                {res().documents.identityCardLeader}
                                            </div>
                                            <span className={styles.exclamationIcon}>
                                                <Tooltip
                                                    placement="left"
                                                    title={res().tooltips.identityCardLeader}
                                                    overlayStyle={{ maxWidth: '580px' }}>
                                                    <ExclamationCircleOutlined /></Tooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.order}>4</td>
                                    <td>
                                        <div className={styles.documentsItem}>
                                            <div className={styles.documentsTitle}>
                                                {res().documents.leaderSignatureIp}
                                            </div>
                                            <span className={styles.exclamationIcon}>
                                                <Tooltip
                                                    placement="left"
                                                    title={res().tooltips.identityCardLeader}
                                                    overlayStyle={{ maxWidth: '580px' }}>
                                                    <ExclamationCircleOutlined /></Tooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        )}

                    </table>

                    <div className={styles.upload}>
                        <div className={styles.subtitle}> Загрузка документов</div>
                        <Dragger
                            multiple={true}
                            fileList={store.files}
                            beforeUpload={(file: RcFile, fileList) => {

                                if (file.type !== 'application/pdf') {
                                    notification.error({
                                        message: res().messages.validateFile
                                    })
                                    return false
                                }

                                const fileSizeLimit = file.size / 1024 / 1024 < 2
                                if (!fileSizeLimit) {
                                    notification.error({
                                        message: res().messages.validateFileSize
                                    })
                                    return false
                                }

                                const data = new FormData()
                                data.append('file', file)
                                store.uploadFile(file)

                                return false
                            }}
                            onRemove={(file) => {
                                store.removeFile(file.uid)
                            }}
                            showUploadList={{
                                showDownloadIcon: false
                            }}
                            onPreview={async (file) => store.download(file)}>
                            <p className={styles.uploadIcon}>
                                <FileTextOutlined />
                            </p>
                            <span className={styles.uploadTitle}> Выберите файлы </span>
                            <span className={styles.uploadHint}>или перетащите сюда</span>
                        </Dragger>
                    </div>

                    <div className={styles.ctaContainer}>
                        <Button
                            size="large"
                            type={'default'}
                            onClick={() => {
                                logEventFirebase('step_3_go_back_btn', {
                                    uploaded_files_count: store.files.length
                                })
                                store.backStep()
                            }}
                        >
                            {res().labels.back}
                        </Button>
                        <Button
                            size="large"
                            type={'primary'}
                            disabled={!store.files.length || store.isLoading}
                            onClick={() => {
                                logEventFirebase('step_3_finish_btn', {
                                    uploaded_files_count: store.files.length
                                })
                                store.finish()
                            }}
                        >
                            {res().labels.finish}
                        </Button>
                    </div>
                </div>
            )}
        </>

    )
})

export default Documents