import { Select } from 'antd'
import React from 'react'
import { MerchantShop } from '..'

function SelectField(defaultValue: MerchantShop, values: MerchantShop[]) {
    return (
        <Select
            id="selectNode"
            defaultValue={defaultValue?.id}
            getPopupContainer={() => document.getElementById('selectNode')}
        >
            {values.map(value => {
                return (
                    <Select.Option key={value.id} value={value.id}>
                        {value.name}
                    </Select.Option>
                )
            })}
        </Select>
    )
}

export default SelectField
