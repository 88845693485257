import React from 'react'

import { action, observable, reaction, runInAction } from 'mobx'

import { apiCheck, recoverPassword, updateProfile } from '~/api'

import { getUserInfo } from '~/services/auth/auth-utils'

import locale, { Language } from '~/utils/locale'
import message from '~/utils/message'
import { showErrorOnThrow } from '~/utils/user-error'

import res from './res'

export default class UserProfileStore {
    constructor() {
        this.userLogin = getUserInfo().userLogin
        this.userRole = localStorage.getItem('role')?.toUpperCase() || ''
    }

    public userLogin: string

    public userRole: string

    @observable
    public disableChangePassword: boolean

    public setLang(lang: Language) {
        locale.setLang(lang)
        showErrorOnThrow(updateProfile({ lang: locale.lang }))
    }

    @action.bound
    public async changePassword() {
        this.disableChangePassword = true

        const result = await showErrorOnThrow(
            apiCheck(
                recoverPassword({
                    email: this.userLogin
                })
            )
        )

        runInAction(() => {
            this.disableChangePassword = false
            if (result.error) return
            message.info(
                res().changePasswordSuccess(this.userLogin),
                res().changePasswordInfo
            )
        })
    }
}
