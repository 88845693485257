export const languages = {
    en: 'English',
    kk: 'Қазақ',
    ru: 'Русский'
}

export const languageCodes = {
    en: 'EN',
    kk: 'KZ',
    ru: 'RU'
}