import LayoutStore from '~/layouts/main/layout-store'
import BrowserStore from '~/services/browser/browser-state'
import { HintPlacement, interactiveHintId, MEDIA_SCREEN_BREAKPOINT } from './constants'

export const openSiderOnTablets = () => {
    if (BrowserStore.windowWidth <= MEDIA_SCREEN_BREAKPOINT) {
        LayoutStore.toggleSider(true)
        // document.body.style.overflow = 'hidden'
    }
}

export const closeSiderOnTablets = () => {
    if (BrowserStore.windowWidth <= MEDIA_SCREEN_BREAKPOINT && LayoutStore.isSiderVisible) {
        LayoutStore.toggleSider()
        // document.body.style.overflow = 'unset'
    }
}

export const getRealScreenHeight = () => {
    if (BrowserStore.isMobile) {
        return Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight
        )
    }
    return document.querySelector('.main-layout').getBoundingClientRect().height
}

export const getRealScreenWidth = () => {
    return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.body.clientWidth,
        document.documentElement.clientWidth
    )
}

export const getRealViewportHeight = () => {
    return Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
}


const getHintHeight = () => {
    const hintCurrent = document.getElementById(interactiveHintId)
    if (hintCurrent) {
        return hintCurrent.getBoundingClientRect().height
    }
    return 0
}

const getHintWidth = () => {
    const hintCurrent = document.getElementById(interactiveHintId)
    if (hintCurrent) {
        return hintCurrent.getBoundingClientRect().width
    }
    return 0
}

export const calculateHintPlacement = (parameters: any, placement: HintPlacement, isPositionFixed?: boolean) => {
    const spaceFromViewport = 10
    const spaceBetweenHintAndTarget = 10
    const widthOfHint = getHintWidth()
    const heightOfCurrentHint = getHintHeight()
    let spaceViewportFromTop = window.scrollY
    let limitScreenTop = 0
    let limitScreenBottom = getRealScreenHeight()
    let styleHintArrow = {}
    const styleHint: Record<string, any> = {
        left: 0,
        top: 0
    }

    if (isPositionFixed) {
        styleHint.position = 'fixed'
        limitScreenTop = 0
        limitScreenBottom = getRealViewportHeight()
        spaceViewportFromTop = 0
    }
    // if (Object.values(HintType).includes(type)) {
    //     limitScreenTop = window.scrollY
    //     limitScreenBottom = window.scrollY + getRealViewportHeight()
    // }

    if (heightOfCurrentHint) {
        switch (placement) {
            case 'right':
                styleHint.left = parameters.left + parameters.width + spaceBetweenHintAndTarget
                styleHint.top = parameters.top + (parameters.height / 2) + spaceViewportFromTop

                if (styleHint.left > (getRealScreenWidth() - (widthOfHint + spaceFromViewport))) {
                    styleHint.left = getRealScreenWidth() - (widthOfHint + spaceFromViewport)
                }
                break
            case 'left':
                styleHint.left = parameters.left - widthOfHint - spaceBetweenHintAndTarget
                styleHint.top = parameters.top + (parameters.height / 2) + spaceViewportFromTop

                if (styleHint.left < spaceFromViewport) {
                    styleHint.left = spaceFromViewport
                }
                break
            case 'bottom':
                styleHint.left = parameters.left + (parameters.width / 2)
                styleHint.top = parameters.top + parameters.height + spaceBetweenHintAndTarget + spaceViewportFromTop

                if (styleHint.top > (limitScreenBottom - heightOfCurrentHint - spaceFromViewport)) {
                    styleHint.top = limitScreenBottom - heightOfCurrentHint - spaceFromViewport
                }
                break
            case 'top':
                styleHint.left = parameters.left + (parameters.width / 2)
                styleHint.top = parameters.top - (heightOfCurrentHint + spaceBetweenHintAndTarget) + spaceViewportFromTop

                if (styleHint.top < (spaceFromViewport + limitScreenTop)) {
                    styleHint.top = spaceFromViewport + limitScreenTop
                }
                break
        }

        if (['right', 'left'].includes(placement)) {
            if (styleHint.top > (limitScreenBottom - (spaceFromViewport + heightOfCurrentHint / 2))) {
                let arrowPosition = styleHint.top
                styleHint.top = limitScreenBottom - (spaceFromViewport + heightOfCurrentHint / 2)
                arrowPosition = arrowPosition - styleHint.top
                styleHintArrow = { top: `calc(50% + ${arrowPosition}px)` }
            }
            if (styleHint.top < (spaceFromViewport + limitScreenTop + heightOfCurrentHint / 2)) {
                let arrowPosition = styleHint.top
                styleHint.top = (spaceFromViewport + limitScreenTop + heightOfCurrentHint / 2)
                arrowPosition = styleHint.top - arrowPosition
                styleHintArrow = { top: `calc(50% - ${arrowPosition}px)` }
            }
        } else {
            if (styleHint.left > (getRealScreenWidth() - (spaceFromViewport + widthOfHint / 2))) {
                let arrowPosition = styleHint.left
                styleHint.left = getRealScreenWidth() - (spaceFromViewport + widthOfHint / 2)
                arrowPosition = arrowPosition - styleHint.left
                styleHintArrow = { left: `calc(50% - 8px + ${arrowPosition}px)` }
            }
            if (styleHint.left < (spaceFromViewport + widthOfHint / 2)) {
                let arrowPosition = styleHint.left
                styleHint.left = (spaceFromViewport + widthOfHint / 2)
                arrowPosition = styleHint.left - arrowPosition
                styleHintArrow = { left: `calc(50% - 8px - ${arrowPosition}px)` }
            }
        }
    }

    return [styleHint, styleHintArrow]
}