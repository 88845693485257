import { observer } from 'mobx-react'
import React from 'react'
import styles from './styles.css'
import BrowserStore from '~/services/browser/browser-state'
import { interactiveHintId } from '../interactive-hints/constants'
import { calculateHintPlacement } from '../interactive-hints/utils'
import InteractiveMobileHintsStore from './interactive-mobile-hints-store'

interface Props {
    store: InteractiveMobileHintsStore
}

@observer
export default class InteractiveMobileHints extends React.Component<Props, {}> {

    public render() {
        const { isShowMode, steps } = this.props.store

        if (!BrowserStore.isMobile || !isShowMode || steps.length === 0) {
            return null
        }

        return this.renderInteractiveMode()
    }

    private renderInteractiveMode() {
        const store = this.props.store
        const { currentStep: { target: targetClassName } } = store

        const targetElement = document.querySelector(targetClassName)

        if (!targetElement) {
            // store.goNextStep()
            return null
        }

        const targetParameters = targetElement.getBoundingClientRect()

        return (
            <div onClick={store.goNextStep}>
                {this.renderHint(targetParameters)}
                {this.renderBackgroundMask(targetParameters)}
            </div>
        )
    }

    private renderHint(parameters: any) {
        const store = this.props.store
        const { isStepChanging, currentStep: { placement, description } } = store
        const { isCurrentStepTypeSider } = store
        const [styleHint, styleHintArrow] = calculateHintPlacement(parameters, placement, isCurrentStepTypeSider)

        const hintClass = `${styles.interactiveHint} ${placement} ${isStepChanging ? styles.hidden : ''}`

        return (
            <div
                className={hintClass}
                style={styleHint}
                id={interactiveHintId}
            >
                <div className={styles.hintArrow} style={styleHintArrow}></div>
                <div>{description}</div>
            </div>
        )
    }

    private renderBackgroundMask(parameters: any) {
        const store = this.props.store
        const { isStepChanging, isCurrentStepTypeSider } = store

        const styleHighlight: Record<string, any> = {
            top: parameters.top + window.scrollY,
            left: parameters.left,
            width: parameters.width,
            height: parameters.height
        }

        if (isCurrentStepTypeSider) {
            styleHighlight.position = 'fixed'
            styleHighlight.top = parameters.top
        }

        return (
            <div>
                <div className={styles.interactiveHintsMask}></div>
                {!isStepChanging &&
                    <div className={styles.interactiveHighlight} style={styleHighlight}></div>
                }
            </div>
        )
    }
}
