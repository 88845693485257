import { action, observable, reaction, runInAction, toJS, computed } from 'mobx'
import { apiCheck } from '../../api/index'
import { getNewsData, getNews } from '~/api/news'
import { noThrow } from '~/utils/control-flow'

export interface NewsCard {
    id: string,
    client_id: string,
    title: string,
    description: string,
    status: string,
    seen_status: string,
    image_url: string,
    tag: string,
    promotion_periodstring,
    publish_time: string,
    delete_time: string,
}

export default class NewsStore {
    constructor(newsid?: string) {
        this.fetchNews('all', 7, 1)
        this.getMainNews('new', 3, 1)
        if (newsid) {
            this.loadingNews = true
            this.getSingleNews(newsid)
        }
        reaction(
            () => (
                this.pageIndex
            ),
            () => this.fetchNews('all', this.pageSize, this.pageIndex)
        )
        reaction(
            () => (
                this.pageSize
            ),
            () => this.fetchNews('all', this.pageSize, this.pageIndex)
        )
    }

    @observable
    public showAll: boolean = false

    @observable
    public newsList: NewsCard[] = []

    @observable
    public pageIndex: number = 1

    @observable
    public mainNewsList: NewsCard[] = []

    @observable
    public pageInfo: {
        from: number
        to: number
        total: number
    }

    @observable
    public loadingNews: boolean = false

    @observable
    public newsInfo: NewsCard

    @observable
    public pageSize: number = 4

    @action.bound
    public async getSingleNews(id: string) {
        // const value = this.newsList.find(val => val.id === id) 
        const { value } = await noThrow(apiCheck(getNews(id)))
        runInAction(() => {
            this.newsInfo = value
            this.loadingNews = false
        })
    }

    @action.bound
    public async fetchNews(type: string, size: number, page: number) {
        this.loadingNews = true
        const { value } = await noThrow(apiCheck(getNewsData(type, size, page)))
        runInAction(() => {
            this.loadingNews = false
            this.newsList = value.news
            this.pageInfo = {
                from: this.pageIndex,
                to: this.newsList?.length,
                total: value.count
            }
        })
    }

    @action.bound
    public async getMainNews(type: string, size: number, page: number) {
        const { value } = await noThrow(apiCheck(getNewsData(type, size, page)))
        runInAction(() => {
            this.mainNewsList = value.news
            this.pageInfo = {
                from: this.pageIndex,
                to: this.newsList.length,
                total: value.count
            }
        })
    }

    @action.bound
    public async setNewsId(id) {
        this.getSingleNews(id)
    }

    @action.bound
    public reload() {
        this.pageIndex = 1
        this.pageSize = 3
        this.fetchNews('all', 3, 1)
    }

    @action.bound
    public goFirstPage() {
        this.pageIndex = 1
    }

    @action.bound
    public goPrevPage() {
        if (this.pageIndex > 1) {
            this.pageIndex--
        }
    }

    @action.bound
    public goNextPage() {
        if (this.pageIndex > this.lastPage) {
            return
        }
        this.pageIndex++
    }

    @action.bound
    public goLastPage() {
        if (!this.pageInfo) return

        this.pageIndex = this.lastPage
    }

    @action.bound
    public handleShowMore() {
        this.pageSize += 3
    }

    @action.bound
    public GoBack() {
        window.history.back()
    }

    @computed
    private get lastPage() {
        const n = this.pageInfo.total / this.pageSize
        return (Math.floor(n) - (n % 1 === 0 ? 1 : 0))
    }

}