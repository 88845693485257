import React from 'react'

const SvgInfo = props => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" {...props}>
        <g fillRule="evenodd">
            <path d="M0 512.002C0 229.685 229.683 0 512 0s512 229.685 512 512.002C1024 794.324 794.317 1024 512 1024S0 794.324 0 512.002zm93.092.002c0 230.983 187.918 418.908 418.908 418.908 230.987 0 418.912-187.925 418.912-418.908 0-230.987-187.925-418.912-418.912-418.912-230.99 0-418.908 187.925-418.908 418.912z" />
            <path
                d="M512 204.8c-28.232 0-51.2 22.979-51.2 51.223 0 28.219 22.968 51.177 51.2 51.177 28.232 0 51.2-22.958 51.2-51.177 0-28.244-22.968-51.223-51.2-51.223zM512 409.6c-28.276 0-51.2 20.059-51.2 44.8v268.803c0 24.74 22.924 44.797 51.2 44.797 28.276 0 51.2-20.057 51.2-44.797V454.4c0-24.741-22.924-44.8-51.2-44.8z"
                fillRule="nonzero"
            />
        </g>
    </svg>
)

export default SvgInfo
