import React, { ReactNode } from 'react'
import Lottie from 'react-lottie'
import * as animationData from '~/assets/img/no-access.json'

import { observer } from 'mobx-react'

import moment from 'moment'

import Page from '~/layouts/main/page'

import {
    Button,
    IconButton,
    List,
    Modal,
    notification,
    Spin,
    Table,
    ButtonDefault
} from '~/components'
import { BinIcon, FormIcon } from '~/components/icon'

import Invitation from './components/invitation'

import TeamStore from './team-store'

import BrowserStore from '~/services/browser/browser-state'
import res from './res'

import { Moment } from 'moment'
import MobileHeader from '~/components/modal/mobile-header'
import ModalContainer, { ModalStyles } from '~/components/modal/modal-container'
import { TeamMember, TeamRoles } from '../../../api/team/contracts'
import styles from './styles.css'
import store from '~/services/browser/browser-state'
import InteractiveMobileHints from '~/components/interactive-mobile-hints'

const cols = [
    {
        key: 'email',
        cellClassName: styles.emailCell
    },
    {
        key: 'role',
        cellClassName: styles.roleCell
    },
    {
        key: 'lastConnections'
    },
    {
        key: 'control',
        cellClassName: styles.controlCell
    }
]

@observer
export default class Team extends React.Component<{ store: TeamStore }, {}> {
    public render() {
        const { store } = this.props
        const tableCols = cols.map(col => {
            return {
                title: res().cols[col.key],
                ...col
            }
        })

        return (
            <Page
                title={
                    <div className={styles.titleTable}>
                        <div>{res().title}</div>
                        {!BrowserStore.isMobile && (
                            <div className={styles.titleButton}>
                                <ButtonDefault
                                    type="primary"
                                    // disabled={true}
                                    onClick={() => store.openInvitationModal()}
                                    className="hint-section-9-step-3"
                                >
                                    {res().invite}
                                </ButtonDefault>
                            </div>
                        )}
                    </div>
                }
            >
                {BrowserStore.isMobile && (
                    <div className={styles.clearElement}></div>
                )}
                {BrowserStore.isMobile && (
                    <div className={styles.mobileButton}>
                        <Button
                            type="primary"
                            // disabled={true}
                            onClick={() => store.openInvitationModal()}
                        >
                            {res().invite}
                        </Button>
                    </div>
                )}
                <div className={`${styles.mobileContent} ${styles.content}`}>
                    {!BrowserStore.isMobile && (
                        <Table
                            columns={tableCols}
                            dataSource={this.buildDataSet(store.teamMembers)}
                            className={styles.employees}
                        />
                    )}
                    {BrowserStore.isMobile && (
                        <div className={styles.employeesCardContainer}>
                            {this.EmployeesCardsContainer()}
                        </div>
                    )}
                </div>
                {store.isInvitationModalVisible &&
                    this.invitationModalContainer()}
                {BrowserStore.isMobile && !store.teamLoading && store.mobileHintStore &&
                    <InteractiveMobileHints store={store.mobileHintStore} />
                }
            </Page>
        )
    }

    private showConfirmDelete = (
        teamMemberPublicId: string,
        store: TeamStore
    ) => {
        Modal.confirm({
            title: res().deleteConfirmTitle,
            content: res().deleteConfirmContent,
            async onOk() {
                await store.handleDeleteTeamMember(teamMemberPublicId)
                notification.success({
                    message: res().deleteNotificationTitle,
                    description: res().deleteNotificationDescription
                })
            },
            onCancel() {
                return
            }
        })
    }

    private getLastActivityLabelClass(value) {
        const dateValue = moment(value)
        let labelCss = styles.green
        switch (true) {
            case dateValue.isBefore(moment().add(-90, 'day')):
                labelCss = styles.red
                break
            case dateValue.isBefore(moment().add(-30, 'day')):
                labelCss = styles.yellow
        }
        return labelCss
    }

    private invitationModalContainer() {
        const { store } = this.props

        return (
            <ModalContainer
                visible={store.isInvitationModalVisible}
                onClose={store.toggleInvitationModal}
                headerControl={
                    <MobileHeader
                        onCancel={store.toggleInvitationModal}
                    ></MobileHeader>
                }
                closable={true}
                drawerClass={`${!store.isEmployeeNew ? '' : ModalStyles.fullDrawer
                    } ${styles.drawer}`}
                modalClass={`${styles.modalContainer} hint-section-9-step-4`}
            >
                <Invitation store={store} />
            </ModalContainer>
        )
    }

    private formatLastActivityDiff = (lastActivityDate: Date) => {
        if (!lastActivityDate) {
            return null
        }

        let text = moment(lastActivityDate).fromNow()

        if (isNaN(parseInt(text, 10)) && lastActivityDate.getSeconds() > 59) {
            text = '1 ' + text
        }

        return text
    }

    private renderEmployeeItem = (employee: TeamMember) => {
        return (
            <List.Item key={employee.id} className={styles.cardElement}>
                <div className={styles.employeeCardElement}>
                    <div className={styles.employeeInfo}>
                        <div className={styles.emailCell}>{employee.email}</div>
                        <div>{employee.role}</div>
                        <div>
                            {this.formatLastActivityDiff(
                                employee.lastConnections
                            )}
                        </div>
                    </div>
                    <div className={styles.actions}>
                        {this.controlContent(employee)}
                    </div>
                </div>
            </List.Item>
        )
    }

    private EmployeesCardsContainer = () => {
        const { store } = this.props
        // if (!store.teamLoading) {
        //     return (
        //         <div className={styles.spin}>
        //             <Spin spinning={store.teamLoading} />
        //         </div>
        //     )
        // }

        return (
            <div>
                {store.teamMembers.map(employee =>
                    this.renderEmployeeItem(employee)
                )}
            </div>
        )
    }

    private buildDataSet = (dataSource: TeamMember[]) => {
        const source = dataSource.map((item: TeamMember) => {
            return {
                email: <span>{item.email}</span>,
                role: <span>{res().role[item.role]}</span>,
                status: <span>{res().status[item.status.name]}</span>,
                lastConnections: (
                    <span
                        className={`${styles.activity
                            } ${this.getLastActivityLabelClass(
                                item.lastConnections
                            )}`}
                    >
                        {this.formatLastActivityDiff(item.lastConnections)}
                    </span>
                ),
                control: this.controlContent(item)
            }
        })

        return source
    }

    private controlContent = (item: TeamMember): ReactNode => {
        return (
            item.role !== TeamRoles.admin && (
                <span className={styles.actions}>
                    <IconButton
                        icon={FormIcon}
                        tooltipTitle={res().cols.controlsTooltip.edit}
                        dataSemanticId="edit-team-form"
                        id="open-statement-settings-icon"
                        onClick={() =>
                            this.props.store.openInvitationModal(item)
                        }
                    />
                    <IconButton
                        icon={BinIcon}
                        key="button_cancel"
                        onClick={() =>
                            this.showConfirmDelete(
                                item.publicId,
                                this.props.store
                            )
                        }
                        tooltipTitle={res().cols.controlsTooltip.delete}
                    />
                </span>
            )
        )
    }
}
