import res from './res'

res.add('kk', {
    title: 'Сүзгі',
    cardsNumberStartsWith: 'Алғашқы 6 Сан',
    cardsNumberEndsWith: 'Соңғы 4',
    endsWith: 'аяқталады',
    applyButton: 'Қолдану',
    cancelButton: 'Болдырмау',
    clearButton: 'Тазала сүзгі',
    periodTitle: 'Кезең',
    postLinkStatusOptions: {
        all: 'Барлық',
        ok: 'Сәтті',
        fail: 'Қате'
    }
})
