import locale from '~/utils/locale'

export default locale.create<{
    title: string
    infoTitle: string
    infoText: React.ReactNode
    settingTitle: string
    messages: {
        valueRequired: string
        successUpdate: string
    }
    labels: {
        activeUrl: string
        activeSecret: string
        chargebackUrl: string
        chargebackUrlPlaceholder: string
        sharedSecret: string
        sharedSecretPlaceholder: string
        save: string
        edit: string
    }
}>()
