import { action, computed, observable, toJS } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'

import appStyles from '~/app.css'
import { Button, IconButton, List, Steps } from '~/components'
import Page from '~/layouts/main/page'

import FormControl from '~/components/form-builder/form-control'

import { FormFieldInterface } from '~/components/form-builder/form-control-fields'

import { ProductRequest, ProductRequestStatus } from '~/api/products'
import ProductsStore from './products-store'
import res from './res'
import styles from './styles.css'

import get from 'lodash/get'
import set from 'lodash/set'

import {
    ePayInquiryScheme,
    ePayInquirySchemeSteps
} from '~/pages/products/constants/inquiry-form-scheme'
import { ProductInquiry } from '../../api/products/contracts'

const { Step } = Steps

const stepsStatuses = [
    { status: ProductRequestStatus.forming, title: res().statusType.forming },
    { status: ProductRequestStatus.created, title: res().statusType.created },
    {
        status: ProductRequestStatus.processing,
        title: res().statusType.processing
    },
    { status: ProductRequestStatus.approved, title: res().statusType.approved }
]
interface ProductsProps {
    store: ProductsStore
}

@observer
export default class ProductInquiryPage extends Component<ProductsProps, {}> {
    public state = {
        currentStepIndex: 0,
        steps: ePayInquirySchemeSteps,
        currentStep: ePayInquirySchemeSteps[0]
    }

    @observable
    public productInquiry = new ProductInquiry()

    public changeStep = (step: FormFieldInterface, index: number) => {
        this.setState({ currentStepIndex: index, currentStep: step })
    }

    @action.bound
    public onDataChange = changes => {
        const changeFieldName = Object.keys(changes)[0]
        const change = { [changeFieldName]: changes[changeFieldName].value }
        const changeObject = changes[changeFieldName]
        set(this.productInquiry, changeObject.path, changeObject.value)
    }

    public nextStep = () => {
        if (this.state.currentStepIndex < this.state.steps.length) {
            this.setState({
                currentStepIndex: this.state.currentStepIndex + 1,
                currentStep: this.state.steps[this.state.currentStepIndex + 1]
            })
        }
    }
    public previousStep = () => {
        if (this.state.currentStepIndex < this.state.steps.length) {
            this.setState({
                currentStepIndex: this.state.currentStepIndex - 1,
                currentStep: this.state.steps[this.state.currentStepIndex - 1]
            })
        }
    }

    public render() {
        const { store } = this.props
        const currentStepIndex = this.state.currentStepIndex
        return (
            <Page
                title={res().title}
                toolbarClassName={styles.mobileToolbar}
                contentClassName={styles.formContainer}
            >
                <div className={styles.listContainer}>
                    <FormControl
                        formTitle={
                            res().inquiryFields[
                            this.state.currentStep.localizationTitleKey
                            ]
                        }
                        dataObject={this.productInquiry}
                        dataObjectType={ProductInquiry}
                        rootObjectType={ProductInquiry}
                        field={this.state.currentStep}
                        onDataChange={this.onDataChange}
                    ></FormControl>
                    <div className={styles.buttonsContainer}>
                        {this.state.currentStepIndex > 0 && (
                            <Button
                                className={styles.previousButton}
                                type="primary"
                                onClick={() => this.previousStep()}
                            >
                                {res().previousButton}
                            </Button>
                        )}
                        {this.state.currentStepIndex !==
                            this.state.steps.length - 1 && (
                                <Button
                                    className={styles.nextButton}
                                    type="primary"
                                    onClick={() => this.nextStep()}
                                >
                                    {res().nextButton}
                                </Button>
                            )}
                    </div>
                </div>
                <div className={styles.stepsHolder}>
                    <Steps
                        current={currentStepIndex}
                        size="small"
                        direction="vertical"
                    >
                        {this.state.steps
                            .filter(step =>
                                step.disabled
                                    ? !step.disabled(this.productInquiry)
                                    : true
                            )
                            .map((step, index) => {
                                const localization = step.localizationScheme()
                                return (
                                    <Step
                                        onClick={() =>
                                            this.changeStep(step, index)
                                        }
                                        title={get(
                                            localization,
                                            step.localizationTitleKey
                                        )}
                                        status={
                                            index < currentStepIndex
                                                ? 'finish'
                                                : index === currentStepIndex
                                                    ? 'process'
                                                    : 'wait'
                                        }
                                    />
                                )
                            })}
                    </Steps>
                </div>
            </Page>
        )
    }
}
