import { Button, Input, message } from 'antd'
import React, { useEffect, useRef } from 'react'
import { IconButton } from '~/components'
import { CheckIcon, PaymentLinksIcon } from '~/components/icon'
import res from '../res'
import styles from '../styles.css'
import { UpdateCreditOrder } from '~/api/products'

interface ConfirmationPageProps {
    updatedCreditOrder: UpdateCreditOrder,
    onClose: () => void
}

function ConfirmationPage({ updatedCreditOrder, onClose }: ConfirmationPageProps) {
    return (
        <div className={styles.confirmation}>
            <div className={styles.confirmationHeader}>
                <CheckIcon className={styles.checkIcon} />
                <div className={styles.confirmationTitle}>
                    {res().confirmationTitle(updatedCreditOrder.number)}
                </div>
            </div>
            <Button size="large" onClick={() => onClose()}>
                {res().closeButton}
            </Button>
        </div>
    )
}

export default ConfirmationPage
