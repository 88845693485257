import { observer } from 'mobx-react'
import React, { ReactNode } from 'react'
import CreditsStore from './credit-products-store'
import Page from '~/layouts/main/page'
import res from './res'
import dataRes from './constants/res'
import BrowserStore from '~/services/browser/browser-state'
import { Button, ButtonDefault, FieldsConfigForm } from '~/components'
import ModalContainer, { ModalContext } from '~/components/modal/modal-container'
import MobileHeader from '~/components/modal/mobile-header'
import GenerationForm from './components/create-modal-config'
import styles from './styles.css'
import { CreditOrder, CreditOrderStatus } from '~/api/products'
import { Column, Grid } from '~/components/grid'
import { action, observable, toJS } from 'mobx'
import constants from './constants'
import GridControls from '~/components/grid-controls'
import ConfirmationPage from '~/pages/products/credit/components/create-modal-config/ConfirmationPage'
import RejectForm from '~/pages/products/credit/components/reject-modal-config'
import { NoDataIcon } from '~/components/icon'
import { InfinitiveList } from '~/components/list/infinitive-list'
import { List, Card, Row, Col } from 'antd'
import { renderOrderStatus } from './constants/data-fields'
import statusStyles from './constants/order-statuses'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from '~/components'
import moment from 'moment'
import ListToolbar from '~/components/mobile-contols/list-toolbar'
import ProductCard from './components/product-card'
import InteractiveMobileHints from '~/components/interactive-mobile-hints'

interface CreditOrdersProps {
    store: CreditsStore
}

@observer
export default class CreditProducts extends React.Component<CreditOrdersProps, {}> {
    protected scrollDependedControls: string[] = [
        styles.titleTable
    ]

    private get gridControls() {
        return (
            <GridControls
                store={this.props.store}
                customize={this.openCustomizeGridForm}
            />
        )
    }

    protected get columns() {
        return constants.fields(this.props.store).get()
    }

    private get grid() {
        if (BrowserStore.isMobile) {
            return <this.listContainer />
        }

        return <this.gridContainer />
    }

    private get customizeGridForm() {
        return <this.gridConfigContainer />
    }

    public state = { scrollTopTrigger: 0 }

    public componentDidMount() {
        this.props.store.getCreditOrdersFromAPI()
        this.props.store.getCreditOrderStatusFromAPI()
        this.props.store.loadProfile()
    }

    public render() {
        const { store } = this.props

        return (
            <Page
                title={
                    <div className={styles.titleTable}>
                        <div>{res().creditOrderTitle}</div>
                        {!BrowserStore.isMobile && (
                            <div className={styles.titleButton}>
                                <ButtonDefault
                                    type="primary"
                                    onClick={store.toggleOrderConfigForm}
                                    className="hint-section-7-step-3"
                                >
                                    {res().newOrderTitle}
                                </ButtonDefault>
                            </div>
                        )}
                    </div>
                }
            >
                {!BrowserStore.isMobile && (
                    <React.Fragment>
                        <div className={styles.gridControlsContainer}>
                            {this.gridControls}
                        </div>
                    </React.Fragment>
                )}
                {BrowserStore.isMobile && (
                    <div className={styles.mobileButton}>
                        <div className={styles.titleButton}>
                            <ButtonDefault
                                type="primary"
                                onClick={store.toggleOrderConfigForm}
                                className="mobile-hint-section-7-step-3"
                            >
                                {res().newOrderTitle}
                            </ButtonDefault>
                        </div>
                    </div>
                )}

                {(!BrowserStore.isMobile || !store.showOrderConfig || !store.showOrderRejectConfig) && (
                    <div className={styles.gridCnt}>
                        {this.grid}
                        {this.customizeGridForm}
                    </div>
                )}
                {
                    store.showOrderConfig &&
                    this.orderConfigContainer()}

                {!BrowserStore.isMobile &&
                    store.showOrderRejectConfig &&
                    this.orderRejectConfigContainer()}

                {/* {store.showOrderConfig && (
                   <GenerationForm
                       onClose={store.toggleOrderConfigForm}
                       onApply={store.generateOrder}
                       orderUpdating={store.orderUpdating}
                   />
                )} */}
                {BrowserStore.isMobile &&
                    <ListToolbar
                        onRefresh={this.props.store.reload}
                        onScrollTop={this.scrollToTop}
                        scrollTargetClassName={this.scrollDependedControls[0]}
                    />
                }
                {BrowserStore.isMobile && store.mobileHintStore &&
                    <InteractiveMobileHints store={store.mobileHintStore} />
                }
            </Page>
        )
    }

    @action.bound
    protected openCustomizeGridForm() {
        this.showCustomizeGridForm = true
    }

    @action.bound
    protected closeCustomizeGridForm() {
        this.showCustomizeGridForm = false
    }

    @action.bound
    protected closeConfirmation() {
        this.showConfirmation = false
    }

    protected scrollToTop = () => {
        this.setState({ scrollTopTrigger: this.state.scrollTopTrigger + 1 })
    }
    protected renderListItem = (item: any) => {
        const card = item.applications.lastItem
        const menu: ReactNode = (
            <Menu
                data-semantic-id="Status_select_dropdown"

            >
                {item.applications.map((status: any, iter: number) =>
                    <Menu.Item
                        data-semantic-id="Status_language_menuitem"
                        key="en"
                        className={styles.menuItem}
                    >
                        <span className={styles.mainColor}>{`${moment(status.createdAt).format('MM-DD-YYYY/HH-MM')}`}</span>
                        {renderOrderStatus(iter, dataRes().creditOrderStatus[status.statusName], statusStyles[status.statusName])}
                    </Menu.Item>
                )}
            </Menu>
        )

        return (
            <List
                grid={{
                    gutter: 16,
                    column: 1
                }}
                className={styles.creditMobileCard}
                key={`id_${item.id}`}
            >
                <List.Item>
                    <Card
                        title={`${res().orderText}${item.number}`}
                        extra={
                            <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                                <div className={styles.mobileSelect}>
                                    {
                                        renderOrderStatus(
                                            1, dataRes().creditOrderStatus[card.statusName], statusStyles[card.statusName]
                                        )
                                    }
                                    <DownOutlined
                                        color={statusStyles[card.statusName].color}
                                    />
                                </div>
                            </Dropdown>
                        }
                    >
                        <Row className={styles.cardRows}>
                            <Col span={8} className={styles.alterColor}>{dataRes().dataFields.short.emails}</Col>
                            <Col span={16} className={styles.mainColor}>
                                {card.emails.map((mail: string) =>
                                    <Col key={`${mail}_${item.id}`} span={24}>{mail}</Col>
                                )}
                            </Col>
                        </Row>
                        <Row className={styles.cardRows}>
                            <Col span={8} className={styles.alterColor}>{dataRes().dataFields.short.shopIds}</Col>
                            <Col span={16} className={styles.mainColor}>
                                {card.shops.map((shop: any) =>
                                    <Col key={`${shop.name}_${item.id}`} span={24}>{shop.name}</Col>
                                )}
                            </Col>
                        </Row>
                        {card.statusName === CreditOrderStatus.ACCEPT &&
                            <Row className={styles.cardRows}>
                                <Col span={8} className={styles.alterColor}>{dataRes().dataFields.short.checkingAccount}</Col>
                                <Col span={16} className={styles.mainColor}>
                                    {card.checkingAccount}
                                </Col>
                            </Row>
                        }
                        <Row className={styles.cardRows}>
                            <Col
                                span={8}
                                className={styles.alterColor}
                            >
                                {dataRes().dataFields.short.creditConditions}
                            </Col>
                            <Col span={16}>
                                {card.creditConditions.map((credit: any) =>
                                    <Col key={`${credit.monthCount}_${item.id}`} span={24} className={styles.mainColor}>
                                        <span className={styles.alterColor}>{credit.interestRate ? dataRes().credit : dataRes().installment}</span> :
                                        {dataRes().conditionLabel(credit.monthCount)}
                                    </Col>
                                )}
                            </Col>
                        </Row>
                    </Card>
                </List.Item>
            </List>
        )

    }

    private orderConfigContainer() {
        const { store } = this.props
        return (
            <ModalContainer
                modalClass={
                    `${!store.showOrderConfirmation ? styles.modal : ''} hint-section-7-step-4`
                }
                drawerClass="mobile-hint-section-7-step-4"
                visible={store.showOrderConfig}
                onClose={store.toggleOrderConfigForm}
                headerControl={
                    <MobileHeader
                        onCancel={store.toggleOrderConfigForm}
                    ></MobileHeader>
                }
                closable={true}
            >
                <ModalContext.Consumer>
                    {modalContext => (
                        <GenerationForm
                            onClose={store.toggleOrderConfigForm}
                            onApply={store.generateOrder}
                            orderUpdating={store.orderUpdating}
                            modalContext={modalContext}
                        />
                    )}
                </ModalContext.Consumer>
            </ModalContainer>
        )
    }

    private orderRejectConfigContainer() {
        const { store } = this.props
        return (
            <ModalContainer
                modalClass={
                    !store.showOrderRejectConfirmation ? styles.modal : ''
                }
                visible={store.showOrderRejectConfig}
                onClose={store.toggleOrderRejectConfigForm}
                headerControl={
                    <MobileHeader
                        onCancel={store.toggleOrderRejectConfigForm}
                    ></MobileHeader>
                }
                closable={true}
            >
                <ModalContext.Consumer>
                    {modalContext => (
                        <RejectForm
                            statusId={store.rejectStatusId}
                            updateOrder={store.updateCreditOrder}
                            onApply={store.rejectOrder}
                            onClose={store.toggleOrderRejectConfigForm}
                            orderUpdating={store.orderUpdating}
                            modalContext={modalContext}
                        />
                    )}
                </ModalContext.Consumer>
            </ModalContainer>
        )
    }
    private gridConfigContainer = observer(() => {
        const { store } = this.props

        const columns = store.columns
            .map(column => {
                const data = this.columns.find(
                    item => item.field === column.field
                )
                return {
                    field: column.field,
                    visible: column.visible,
                    title: data && data.title
                }
            })
        return (
            <FieldsConfigForm
                values={columns}
                visible={this.showCustomizeGridForm}
                onClose={this.closeCustomizeGridForm}
                onSetFields={store.setColumnsOrder}
                pageSize={store.pageSize}
                onSetPageSize={store.setPageSize}
                sortable={false}
                customizablePageSize={true}
                fieldsSettingsVisable={false}
            />
        )
    })

    private gridContainer = observer(() => {
        const { store } = this.props

        const columns: Array<Column<CreditOrder>> = []

        const colMap = new Map(
            this.columns.map((col): [
                keyof CreditOrder,
                Column<CreditOrder>
            ] => [col.field, col])
        )
        store.creditOrdersGridColumn.forEach(col => {
            const column = colMap.get(col.field)
            column.width = col.width
            columns.push(column)
        })

        return (
            <div>

                {store.creditOrders.length ? (
                    store.creditOrders.map((item, index) => ((
                        <div key={index}>
                            {store.creditOrderUpdated && (
                                <ConfirmationPage
                                    onClose={this.closeConfirmation}
                                    updatedCreditOrder={store.creditOrderUpdated}
                                />
                            )}
                            <div className={styles.creditOrderContainer}>
                                {/* <span className={styles.title}> Заявка №{item.number}</span> */}
                                {/* <Grid<CreditOrder>
                                        columns={columns}
                                        data={toJS(item.applications)}
                                        rowKey={'id'}
                                        loading={store.creditOrdersLoading}
                                        disableRowSelection={true} 
                                    /> */}
                                <ProductCard
                                    data={toJS(item.applications)}
                                    loading={store.creditOrdersLoading}
                                    title={`${res().orderText} ${item.number}`}
                                />
                                {(item.applications[item.applications.length - 1].statusName === CreditOrderStatus.MERCHANT_CONFIRMATION) && (
                                    <div className={styles.orderAction}>
                                        <Button
                                            size="large"
                                            onClick={() =>
                                                store.setUpdateOrder(item.applications)
                                            }
                                        >
                                            {res().cancelButton}
                                        </Button>
                                        <Button
                                            type="primary"
                                            size="large"
                                            onClick={() =>
                                                store.showOrderAccept(item.applications)
                                            }
                                        >
                                            {res().confirmButton}
                                        </Button>
                                    </div>
                                )}
                                {(item.applications[item.applications.length - 1].moderatorComment &&
                                    (item.applications[item.applications.length - 1].statusName === CreditOrderStatus.IN_PROGRESS ||
                                        item.applications[item.applications.length - 1].statusName === CreditOrderStatus.REJECT)) && (
                                        <div className={styles.orderComment}>
                                            <p>{res().rejectReason}: {item.applications[item.applications.length - 1].moderatorComment}</p>
                                        </div>
                                    )}
                            </div>
                        </div>
                    )))) : (
                    <>
                        <div className={styles.noData}>
                            <NoDataIcon className={styles.noDataIcon} />
                            <span>{res().noDataLabel}</span>
                        </div>
                    </>


                )}

            </div>
        )
    })

    private listContainer = observer(() => {
        const { store } = this.props
        return (
            <div
                className={styles.listInfiniteContainer}
                style={{ height: BrowserStore.windowHeight - 101 + 'px' }}
            >
                <InfinitiveList<CreditOrder>
                    dataSource={store.creditOrders}
                    loaded={!store.creditOrdersLoading}
                    dataLoader={store.getCreditOrdersScroll}
                    itemRenderer={(e) => this.renderListItem(e)}
                    pageSize={store.pageSize}
                    scrollTopTrigger={this.state.scrollTopTrigger}
                />
            </div>
        )
    })

    @observable
    private showCustomizeGridForm: boolean

    @observable
    private showConfirmation: boolean

}
