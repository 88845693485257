import { action, computed, observable } from 'mobx'

import { MessageType, Validation } from './validation'

export default class FieldStore<T> {

    constructor(private validations: Array<Validation<T>> = null, private onChange?: (value: T) => any) { }

    @observable
    public value: T

    @computed
    public get error() {
        return typeof this._error === 'function' ? this._error() : this._error
    }

    @action
    public set(value: T, validate = false) {

        this._error = undefined

        this.value = value

        if (validate) {
            this.validate()
        }

        this.onChange && this.onChange(value)
    }

    @action.bound
    public validate() {

        if (this.validations) {

            for (const validation of this.validations) {
                if (!validation.rule(this.value)) {
                    this._error = validation.message
                    return
                }
            }
        }

        this._error = null
    }

    @action
    public isValid() {

        if (this._error === undefined) {
            this.validate()
        }

        return this._error === null
    }

    @observable
    /* tslint:disable */
    private _error: MessageType
    /* tslint:enable */
}

export interface FieldStoreMap {
    [key: string]: FieldStore<any> | FieldStoreMap
}

export function setJS(fields: FieldStoreMap, js: any) {

    for (const [key, field] of Object.entries(fields)) {

        const value = js[key]

        if (typeof value === 'undefined') {
            continue
        }

        if (field instanceof FieldStore) {
            field.value = value
        } else {
            setJS(field, value)
        }
    }
}

export function getJS<T>(fields: FieldStoreMap, result: any = {}) {

    for (const [key, field] of Object.entries(fields)) {
        if (field instanceof FieldStore) {
            result[key] = field.value
        } else {
            result[key] = getJS(field)
        }
    }

    return result as T
}

export function isValidArr(...fields: Array<FieldStore<any>>) {
    return fields.reduce((result, store) => store.isValid() && result, true)
}

export function isValidMap(fields: FieldStoreMap | Array<FieldStore<any>>) {

    let result = true

    for (const field of Object.values(fields)) {
        if (field instanceof FieldStore) {
            result = field.isValid() && result
        } else {
            result = getJS(field) && result
        }
    }

    return result
}
