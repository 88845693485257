import React from 'react'

import { observer } from 'mobx-react'

import { ClickParam } from 'antd/es/menu'

import { Button, Dropdown, Icon, Menu } from '~/components'

import { lowerCaseFirstLetter } from '~/utils'

import isFunction from 'lodash/isFunction'
import styles from './styles.css'

interface Status {
    key: string
    title: () => string | string
}

interface StatusSelectProps {
    options: Status[]
    value?: string
    id?: string
    onChange: (value: string) => any
    asDropDown?: boolean
}

export default observer((props: StatusSelectProps) => {
    const { value, options, onChange, id } = props

    const renderOptions = () => {
        const selectedKeys = value ? [value] : null

        return (
            <Menu
                selectedKeys={selectedKeys}
                onClick={param => handleMenuClick(param)}
            >
                {options.map(item => (
                    <Menu.Item key={item.key}>
                        {lowerCaseFirstLetter(getTitle(item.title))}
                    </Menu.Item>
                ))}
            </Menu>
        )
    }

    const handleMenuClick = (param: ClickParam) => {
        onChange(param.key)
    }

    const selectedOption = value
        ? options.find(
            item => item.key.toLocaleLowerCase() === value.toLocaleLowerCase()
        )
        : null

    if (props.asDropDown) {
        return (
            <Dropdown
                placement="bottomLeft"
                trigger={['click']}
                overlay={renderOptions()}
            >
                <Button className={styles.dropDownButton}>
                    <span id={id} className={styles.selectedValue}>
                        {(selectedOption &&
                            lowerCaseFirstLetter(
                                getTitle(selectedOption.title)
                            )) ||
                            null}
                    </span>
                    <Icon type="down" className={styles.dropDownButtonIcon} />
                </Button>
            </Dropdown>
        )
    }
    return (
        <React.Fragment>
            <Dropdown
                placement="bottomLeft"
                trigger={['click']}
                overlay={renderOptions()}
            >
                <span id={id} className={styles.selectedValue}>
                    {(selectedOption &&
                        lowerCaseFirstLetter(getTitle(selectedOption.title))) ||
                        null}
                </span>
            </Dropdown>
        </React.Fragment>
    )
})

const getTitle = titleSource => {
    return isFunction(titleSource) ? titleSource() : titleSource
}
