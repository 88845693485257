import React from 'react'

const SvgCancel = props => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" {...props}>
        <path
            d="M1024 59.35L964.634 0 512 452.665 59.362 0 0 59.35l452.638 452.668L0 964.655 59.362 1024 512 571.367 964.634 1024 1024 964.655 571.33 512.018z"
            fillRule="nonzero"
        />
    </svg>
)

export default SvgCancel
