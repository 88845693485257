import res from './res'

res.add('kk', {
    errors: {
        146: 'Электрондық поштаны, құпия сөзді тексеріп, кіруді қайталаңыз',
        148: 'Пайдаланушы бұғатталған, жүйеге кіруді қалпына келтіру қажет',
        151: 'Электрондық поштаны, құпия сөзді тексеріп, кіруді қайталаңыз',
        275: 'Email деректері бар пайдаланушы тіркелген',
        267: 'Сіздің пароліңіз ескірген. Құпия сөзді қалпына келтіру керек',
        912: 'Пайдаланушыны іздеу кезіндегі қате'
    }
})
