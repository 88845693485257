import React from 'react'

import res from './res'

res.add('kk', {
    title: 'Статистика',
    dateSelectorLabel: ' үшін статистика',
    charts: {
        successfulTransactions: {
            title: 'Сәтті транзакциялар',
            fieldsSettings:
                'Диаграммада көрсетілуі керек транзакцияларды белгілеңіз'
        },
        issuingBanks: {
            title: 'Эмитент банктер',
            fieldsSettings: 'Диаграммада көрсетілуі керек банктерді белгілеңіз'
        },
        unsuccessfulTransactions: {
            title: 'Сәтсіз транзакциялар'
        },
        channels: {
            title: 'Арналар'
        },
        cartType: {
            title: 'Карта типтері'
        }
    },
    fieldsSettings: 'Шығару параметрі',
    dateRanges: {
        today: 'бүгін',
        yesterday: 'кеше',
        thisWeek: 'ағымдағы апта',
        priorWeek: 'өткен апта',
        thisMonth: 'ағымдағы ай',
        priorMonth: 'өткен ай',
        thisQuarter: 'ағымдағы тоқсан',
        threeMonths: '3 ай',
        sixMonths: 'жарты жыл',
        year: 'жыл',
        customPeriod: 'көрсетілген кезең'
    },
    tooltip: {
        count: 'Саны',
        cardType: 'Карта түрі',
        sum: 'Сомасы',
        issuer: 'Эмитент',
        date: 'Күні'
    },
    errors: {
        successfulTransactionLoadError:
            'Сәтті тразакция статистикасын жүктеу қатесі',
        unsuccessfulTransactionLoadError:
            'Сәтсіз тразакция статистикасын жүктеу қатесі',
        issuesBankLoadError: 'Эмитент банктердің статистикасын жүктеу қатесі',
        channelsDataLoadError: 'Арна статистикасын жүктеу қатесі',
        cartTypeLoadLoadError: 'Статистика жүктеу қатесі'
    },
    count: 'Саны',
    loading: 'Жүктеу',
    noDataLabel: 'Мәлімет жоқ'
})
