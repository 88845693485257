import { action, computed, observable, runInAction } from 'mobx'
import actions from '~/actions'
import BrowserStore from '~/services/browser/browser-state'

import { apiCheck, ApiResponse } from '~/api'
import {
    getProducts,
    ProductInquiry,
    ProductRequest,
    ProductResponse
} from '~/api/products'

import { noThrow } from '~/utils/control-flow'
import message from '~/utils/message'
import { strict } from '~/utils/strict'

import constants from './constants'

import { PageSize } from '~/api/contracts'
import res from './res'

export default class ProductsStore {
    constructor(statementId?: string) {
        if (!this.Products || this.Products.length === 0) {
            this.loadProducts().then(() => {
                // tslint:disable-next-line:no-console
                console.log('mock loaded')
            })
        }
    }

    @observable
    public Products: ProductRequest[]

    @observable
    public pageInfo: {
        from: number
        to: number
        total: number
    }

    @observable
    public ProductsLoading: boolean

    public openInquiryPage = (id: string): void =>
        actions.openProductInquiry(id)

    public async loadProducts() {
        this.ProductsLoading = true

        // const { value, error } = await noThrow<ProductResponse>(apiCheck<ProductResponse>())
        const value = getProducts()
        const error = undefined
        runInAction(() => {
            this.ProductsLoading = false
            if (error) {
                return message.error(error, res().errors.productsLoadingError)
            }

            this.Products = value.result

            this.pageInfo = {
                from: 1,
                to: this.Products.length,
                total: this.Products.length
            }
        })
    }

    @action.bound
    public async reload() {
        await this.loadProducts()
    }
}
