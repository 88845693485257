import { computed } from 'mobx'

import { CashByCodePayment } from '~/api'

import strict from '~/utils/strict'

import { SortOption } from '../../components/sort-config-select'

import res from '../res'

const options = computed(() => {
    const titles = res().sortOptions

    return strict<
        Array<{ title: string; items: Array<SortOption<CashByCodePayment>> }>
    >([
        {
            title: res().sortOptions.date,
            items: [
                {
                    field: 'createdAt',
                    direction: 'desc',
                    title: titles.desc.date
                },
                {
                    field: 'createdAt',
                    direction: 'asc',
                    title: titles.asc.date
                }
            ]
        },
        {
            title: res().sortOptions.postLink,
            items: [
                {
                    field: 'isPostLink',
                    direction: 'asc',
                    title: titles.asc.postLink
                },
                {
                    field: 'isPostLink',
                    direction: 'desc',
                    title: titles.desc.postLink
                }
            ]
        },
        {
            title: res().sortOptions.status,
            items: [
                {
                    field: 'status',
                    direction: 'asc',
                    title: titles.asc.status
                },
                {
                    field: 'status',
                    direction: 'desc',
                    title: titles.desc.status
                }
            ]
        }
    ])
})

export default options
