export default [
    {
        id: 'epay',
        color: '#00A8FF',
        title: 'ePay'
    },
    {
        id: 'QR',
        color: '#8483C5'
    },
    {
        id: 'POS',
        color: '#05B9A6'
    },
    {
        id: 'Orange',
        color: '#FF9D33'
    },
    {
        id: 'Homebank',
        color: '#F42B74'
    },
    {
        id: 'Go',
        color: '#EBCB4C'
    }
]
