import { Tooltip } from 'antd'
import { computed } from 'mobx'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import {
    ContractStatementHistory,
    ContractStatementInfo,
    ContractStatementSchedule,
    FrequencyType,
    HistoryItemStatus
} from '~/api/statements'
import { Icon, Spin } from '~/components'
import { Column } from '~/components/grid'
import { ContractIcon, DownloadIcon } from '~/components/icon'
import StatusIcon from '~/components/status-icon'

import flatMap from 'lodash/flatMap'
import strict from '~/utils/strict'
import res from '../res'
import StatementsTransferStore from '../statements-transfer-store'
import styles from '../styles.css'

export const renderDate = (value, hideHours?: boolean, inline?: boolean) =>
    value ? (
        <span className={styles.date}>
            <span>{moment(value).format('DD.MM.YYYY')}</span> {inline && '/ '}
            {!hideHours && (
                <span
                    className={`${styles.dateHours} ${inline ? styles.inline : ''
                        }`}
                >
                    {moment(value).format('hh:mm')}
                </span>
            )}
        </span>
    ) : null
export interface RenderEmailInterface {
    value: string[] | string
}

function RenderEmails(props: RenderEmailInterface) {
    const { value } = props
    const refContainer = useRef(null)
    const refContent = useRef(null)
    const [tooltipVisible, setTooltipVisible] = useState(false)
    const getLastChildWidth = elem => {
        let childWidth = elem.getBoundingClientRect().width
        if (elem.firstChild && elem.firstChild.getBoundingClientRect) {
            childWidth = getLastChildWidth(elem.firstChild)
        }
        return childWidth
    }
    const mailsTooltip =
        value instanceof Array
            ? value.map((mail, key) => <div key={key}>{mail}</div>)
            : [value]
    const multiEmail = value instanceof Array ? value.length > 1 : false
    const displayedElement = value instanceof Array ? value[0] : value

    const onVisibleChange = (show: boolean) => {
        if (!refContainer || !refContent) return

        if (show) {
            const container = refContainer.current.getBoundingClientRect().width
            const content = getLastChildWidth(refContent.current)
            if (container >= content && !multiEmail) return
        }

        setTooltipVisible(show)
    }
    if (!value) {
        return (
            <Tooltip title={res().tooltips.noEmails} placement="bottom">
                <span className={styles.emails}>-</span>
            </Tooltip>
        )
    }

    return (
        <Tooltip
            title={mailsTooltip}
            placement="bottom"
            onVisibleChange={onVisibleChange}
            visible={tooltipVisible}
        >
            <span
                className={`${styles.emails} ${multiEmail ? styles.multiEmail : ''
                    }`}
                ref={refContainer}
            >
                <span ref={refContent}>
                    <span className={styles.firstMail}>
                        <span>{displayedElement}</span>
                    </span>
                    {multiEmail && (
                        <span className={styles.moreMails}>
                            +{value.length - 1}
                        </span>
                    )}
                </span>
            </span>
        </Tooltip>
    )
}

export const renderPeriod = (value: [Date, Date]) => {
    const period =
        moment(value[0]).format('DD.MM.YYYY') ===
            moment(value[1]).format('DD.MM.YYYY')
            ? `${moment(value[0]).format('DD.MM.YYYY')}`
            : `${moment(value[0]).format('DD.MM.YYYY')} - ${moment(
                value[1]
            ).format('DD.MM.YYYY')}`

    return <span>{period}</span>
}

export const fields = computed(() => {
    const { titles } = res().dataFields

    const renderScheduleData = (value: ContractStatementSchedule) => {
        if (!value || !value.schedulerId) {
            return (
                <div>
                    <span>-</span>
                </div>
            )
        }

        const statusColor = value.enable ? '#05B9A6' : '#FFB600'
        const statusTooltip = value.enable
            ? res().tooltips.enabledStatus
            : res().tooltips.disabledStatus

        return (
            <div>
                <Tooltip title={statusTooltip}>
                    <span className={styles.middleAlign}>
                        <StatusIcon color={statusColor} />
                        &nbsp;
                        <span className={styles.format}>{value.format}</span>
                    </span>
                </Tooltip>
                <RenderEmails value={value.emails}></RenderEmails>
            </div>
        )
    }

    interface ColumnType extends ContractStatementInfo {
        id?: number
    }

    return strict<Array<Column<ColumnType>>>([
        {
            field: 'id',
            title: '#',
            width: 4,
            render: (value, statement, index) => (
                <span className={styles.textColorMain}>{index + 1}</span>
            )
        },
        {
            field: 'contract',
            title: titles.contract,
            width: 16,
            render: (value, statement) => {
                const { name, contract } = statement
                return (
                    <div className={styles.contract}>
                        <div>{name}</div>
                        <div>
                            <span>
                                <ContractIcon />
                                &nbsp;
                            </span>
                            <span>{contract}</span>
                        </div>
                    </div>
                )
            }
        },
        {
            field: 'type',
            // className: styles.centerAlign,
            title: <div>{titles.type}</div>
        },
        {
            field: 'daily',
            title: <div>{titles.daily}</div>,
            width: 18,
            render: value => renderScheduleData(value)
        },
        {
            field: 'weekly',
            title: <div>{titles.weekly}</div>,
            width: 18,
            render: value => renderScheduleData(value)
        },
        {
            field: 'monthly',
            title: <div>{titles.monthly}</div>,
            width: 18,
            render: value => renderScheduleData(value)
        },
        {
            field: 'terminals',
            title: titles.terminals,
            width: 18,
            render: (value: string[]) => (
                <span>{value ? value.length : '-'}</span>
            )
        }
    ])
})

export const historyFields = (store: StatementsTransferStore) =>
    computed(() => {
        const { historyTitles } = res().dataFields

        const renderStatus = (
            value: HistoryItemStatus,
            historyItem: ContractStatementHistory
        ) => {
            return (
                <span className={styles.statusColumn}>
                    {value === HistoryItemStatus.success && (
                        <Tooltip title={res().tooltips.downloadStatement}>
                            <DownloadIcon
                                className={styles.pointer}
                                onClick={() =>
                                    store.statementDownload(historyItem)
                                }
                            />
                        </Tooltip>
                    )}
                    {value === HistoryItemStatus.inProgress && (
                        <Tooltip title={res().tooltips.statementCreating}>
                            <Spin indicator={<Icon type="loading" spin />} />
                        </Tooltip>
                    )}
                    {value === HistoryItemStatus.error && (
                        <Tooltip title={res().errors.statementCreatingError}>
                            <Icon className={styles.errorIcon} type="warning" />
                        </Tooltip>
                    )}
                    {value === HistoryItemStatus.noData && (
                        <Tooltip title={res().tooltips.statementEmpty}>
                            <Icon
                                className={styles.noDataIcon}
                                type="file-unknown"
                            />
                        </Tooltip>
                    )}
                </span>
            )
        }

        return strict<Array<Column<ContractStatementHistory>>>([
            {
                field: 'create',
                title: historyTitles.create,
                width: 18,
                render: value => renderDate(value, false, true)
            },
            {
                field: 'period',
                title: historyTitles.period,
                width: 18,
                render: value => renderPeriod(value)
            },
            {
                field: 'emails',
                title: historyTitles.emails,
                width: 18,
                render: value => <RenderEmails value={value}></RenderEmails>
            },
            {
                field: 'initiationType',
                width: 18,
                title: historyTitles.initiateType,
                render: (value, historyItem) => {
                    return (
                        <span className={styles.initiation}>
                            <div>
                                <span>
                                    {
                                        res().initiationType[
                                        value.toLocaleLowerCase()
                                        ]
                                    }
                                </span>
                                {value.toLocaleLowerCase() ===
                                    FrequencyType.custom && (
                                        <Tooltip title={historyItem.initiator}>
                                            <Icon
                                                type="user"
                                                className={styles.initiator}
                                            />
                                        </Tooltip>
                                    )}
                            </div>
                        </span>
                    )
                }
            },
            {
                field: 'format',
                width: 18,
                title: historyTitles.format,
                render: (value, historyItem) => <span className={styles.format}> {historyItem.fileFormat === 'default' || historyItem.fileFormat === '' ? `${value}` : `${value} (ZIP)`} </span>
            },
            {
                field: 'status',
                width: 18,
                title: '',
                render: (
                    value: HistoryItemStatus,
                    historyItem: ContractStatementHistory
                ) => renderStatus(value, historyItem)
            }
        ])
    })

export function renderSchedulerCardRecord(
    value: ContractStatementSchedule,
    title: string
) {
    if (!value && !value.schedulerId) {
        return (
            <div className={styles.scheduleRecord}>
                <div>{title}</div>
                <div>-</div>
                <div>-</div>
            </div>
        )
    }

    return (
        <div className={styles.scheduleRecord}>
            <div>{title}</div>
            <div className={styles.scheduleFormat}>
                <StatusIcon
                    className={
                        value.enable
                            ? styles.enableStatus
                            : styles.disableStatus
                    }
                />
                &nbsp;
                <span className={styles.format}>{value.format}</span>
            </div>
            {value.emails && (
                <div className={styles.scheduleEmails}>
                    <div>
                        {flatMap(value.emails, x => x.split(';')).join(', ')}
                    </div>
                </div>
            )}
            {!value.emails && <div>-</div>}
        </div>
    )
}
