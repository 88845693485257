import locale from '~/utils/locale'

interface Slide {
    title: string
    description: string
}

export default locale.create<{
    slides: [Slide, Slide, Slide, Slide, Slide, Slide]
    skipButton: string
    startButton: string
    modal: {
        title: string
        description: string
        okButton: string
        cancelButton: string
    }
}>()
