import React from 'react'

import res from './res'

res.add('kk', {
    title: 'Менің профилім',
    labels: {
        language: 'Жүйе тілі',
        email: 'Email',
        password: 'Пароль',
        role: 'Ролi'
    },
    buttons: {
        changePassword: 'Парольді өзгерту'
    },
    lastUsage: {
        header: 'Қосылу тарихы',
        table: {
            headers: {
                geo: 'Орналасуы',
                recordDate: 'Уақыты',
                userSystem: 'ОС',
                ip: 'IP мекенжайы',
                status: 'Жағдайы'
            }
        }
    },
    changePasswordInfo: 'Парольді өзгерту',
    changePasswordSuccess: email => (
        <>
            Нұсқаулықтары бар хат <b>{email}</b> адреске жіберілді
        </>
    )
})
