import React from 'react'

import { observer } from 'mobx-react'

import { Spin } from 'antd'

import { AFTSummary } from '~/api/aft/contracts'

import SummaryChart from '../../../components/summary-chart-for-period'

import res from '../summary/res'

import styles from './styles.css'

import BrowserStore from '~/services/browser/browser-state'

interface SummaryProps {
    statistics: AFTSummary
    loading: boolean
    failed: boolean
}

function renderStats(data: AFTSummary) {
    const currency = data.currency

    const renderStatus = (
        title: string,
        value: { count: number; amount: number }
    ) => (
        <div className={styles.section}>
            <div className={styles.title}>{title}</div>
            <div className={styles.count}>{value.count}</div>
            <div className={styles.amount}>
                {value.amount}{' '}
                <span className={styles.currency}>{currency}</span>
            </div>
        </div>
    )
    const renderCurrencyBlock = (value: { count: number; amount: number }) => {
        return (
            <span>
                {value.amount}{' '}
                <span className={styles.currency}>{currency}</span>
            </span>
        )
    }

    const renderSystem = (
        className: string,
        value: { count: number; amount: number },
        includeSummary: boolean = true
    ) => (
        <div className={`${styles.item} ${className}`}>
            <span className={styles.count}>{value.count}</span>
            <span className={styles.amount}>
                {includeSummary && <span>({renderCurrencyBlock(value)})</span>}
            </span>
        </div>
    )

    const isMobile = BrowserStore.isMobile

    return (
        <React.Fragment>
            {isMobile && (
                <div className={`${styles.chart}`}>
                    <SummaryChart data={data.timeline} />
                </div>
            )}
            <div className={styles.content}>
                {renderStatus(res().statuses.AUTH, data.byStatus.authorized)}
                {renderStatus(res().statuses.CHARGE, data.byStatus.charged)}
                {renderStatus(res().statuses.CANCEL, data.byStatus.cancelled)}
                {renderStatus(res().statuses.REFUND, data.byStatus.refunded)}
                {isMobile && <div className={styles.divider}></div>}
                <div className={`${styles.section} ${styles.paymentSystems}`}>
                    {renderSystem(
                        styles.visa,
                        data.byPaymentSystem.visa,
                        !isMobile
                    )}
                    {renderSystem(
                        styles.masterCard,
                        data.byPaymentSystem.masterCard,
                        !isMobile
                    )}
                    {renderSystem(
                        styles.amex,
                        data.byPaymentSystem.amex,
                        !isMobile
                    )}
                    {renderSystem(
                        styles.otherCard,
                        data.byPaymentSystem.other,
                        !isMobile
                    )}
                </div>
                {isMobile && (
                    <div
                        className={`${styles.section} ${styles.paymentSystemsSummary}`}
                    >
                        {renderCurrencyBlock(data.byPaymentSystem.visa)}
                        {renderCurrencyBlock(data.byPaymentSystem.masterCard)}
                        {renderCurrencyBlock(data.byPaymentSystem.amex)}
                        {renderCurrencyBlock(data.byPaymentSystem.other)}
                    </div>
                )}
                {!isMobile && (
                    <div className={`${styles.section} ${styles.chart}`}>
                        <SummaryChart data={data.timeline} />
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export default observer((props: SummaryProps) => {
    if (props.failed) {
        return <div className={styles.failed}>{res().failed}</div>
    }
    return (
        <div
            className={`${styles.container} ${BrowserStore.isMobile ? styles.mobile : ''
                }`}
        >
            {props.statistics && !props.loading ? (
                renderStats(props.statistics)
            ) : (
                <div className={styles.loading}>
                    <Spin />
                    &nbsp;&nbsp;&nbsp;{res().loading}
                </div>
            )}
        </div>
    )
})
