import React from 'react'

import { observer } from 'mobx-react'

import { IconButton, Tooltip } from '~/components'
import {
    FirstPageIcon,
    LastPageIcon,
    NextPageIcon,
    ParamsIcon,
    PrevPageIcon,
    ReloadIcon
} from '~/components/icon'

import SelectedTransactionsPane from '../selected-transactions-pane'

import { AmountsByCurrencies } from '../../../types'

import res from '../res'

import styles from './styles.css'

interface GridControlsProps {
    store: {
        pageInfo: {
            from: number
            to: number
            total: number
        }
        selectedPaymentCount: number
        selectedPaymentAmounts: AmountsByCurrencies
        clearSelectedPayments: () => void
        reload(): void
        goFirstPage(): void
        goPrevPage(): void
        goNextPage(): void
        goLastPage(): void
    }
    actions?: React.ReactNode
    customize: () => void
}

export default observer(({ store, actions, customize }: GridControlsProps) => {
    const { gridControlsTooltips } = res()

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.leftCol}>{actions}</div>
                <div className={styles.rightCol}>
                    <IconButton
                        icon={ReloadIcon}
                        dataSemanticId="reload-data-icon"
                        className={styles.button}
                        onClick={store.reload}
                        tooltipTitle={gridControlsTooltips.reload}
                    />
                    <IconButton
                        icon={ParamsIcon}
                        id="params-icon"
                        dataSemanticId="table-customization-form"
                        className={styles.button}
                        onClick={customize}
                        tooltipTitle={gridControlsTooltips.settings}
                    />
                    <div className={styles.paging}>
                        <IconButton
                            icon={FirstPageIcon}
                            dataSemanticId="first-page-icon"
                            className={styles.button}
                            onClick={store.goFirstPage}
                            tooltipTitle={gridControlsTooltips.firstPage}
                        />
                        <IconButton
                            icon={PrevPageIcon}
                            dataSemanticId="prev-page-icon"
                            className={styles.button}
                            onClick={store.goPrevPage}
                            tooltipTitle={gridControlsTooltips.prevPage}
                        />
                        {store.pageInfo ? (
                            <span
                                className={styles.pageInfo}
                                data-semantic-id="page-numbers"
                            >
                                {res().pageInfo(store.pageInfo)}
                            </span>
                        ) : null}
                        <IconButton
                            icon={NextPageIcon}
                            dataSemanticId="next-page-icon"
                            className={styles.button}
                            onClick={store.goNextPage}
                            tooltipTitle={gridControlsTooltips.nextPage}
                        />
                        <IconButton
                            icon={LastPageIcon}
                            dataSemanticId="last-page-icon"
                            className={styles.button}
                            onClick={store.goLastPage}
                            tooltipTitle={gridControlsTooltips.lastPage}
                        />
                    </div>
                </div>
            </div>
            <SelectedTransactionsPane store={store} />
        </div>
    )
})
