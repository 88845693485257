import React from 'react'

import { notification } from 'antd'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import res from '~/res'
import { ApiError } from '../api/index'
import styles from './styles.css'
import { userError, UserError } from './user-error'

export default {
    success: (text: React.ReactNode, title?: React.ReactNode) =>
        notification.success({
            message: title || res().success,
            description: text
        }),
    info: (text: React.ReactNode, title?: React.ReactNode) =>
        notification.info({ message: title || res().info, description: text }),
    warn: (text: React.ReactNode, title?: React.ReactNode) =>
        notification.warn({ message: title || res().warn, description: text }),
    critical: (text: React.ReactNode, title?: React.ReactNode) =>
        notification.error({
            message: title || res().error,
            description: text,
            duration: null
        }),
    error: (err: any, title?: React.ReactNode, durationInSeconds?: number) => {
        const error = userError(err)
        let errorMessage: string | JSX.Element = error.message

        if (error.innerError && error.innerError.message) {
            errorMessage = <ErrorMessage error={error} />
        }
        const duration = durationInSeconds ? durationInSeconds : null

        notification.error({
            message: title || res().error,
            description: errorMessage,
            duration
        })
    },
    clearAll: () => {
        notification.destroy()
    }
}

interface ErrorMessageProps {
    error: UserError
}

interface ErrorMessageState {
    showDetails: boolean
}

@observer
class ErrorMessage extends React.Component<
ErrorMessageProps,
ErrorMessageState
> {
    public render() {
        return (
            <div>
                <div>{this.props.error.message}</div>
                <div
                    className={styles.technicalInfoLabel}
                    onClick={this.toggleDetailInfo}
                >
                    {res().techinalInfo}
                </div>
                {this.showDetails && (
                    <div className={styles.technicalInfo}>
                        {this.getInnerMessage()}
                    </div>
                )}
            </div>
        )
    }

    private getInnerMessage() {
        let error: Error = this.props.error
        const fullInnerError = []
        let key = 0
        while (error instanceof UserError) {
            fullInnerError.push(
                <div style={{ color: '#f5222d' }} key={key++}>
                    {error.innerError instanceof ApiError &&
                        error.innerError.code && (
                            <div>
                                {res().errorCode + ': ' + error.innerError.code}
                            </div>
                        )}
                    {error.innerError.message}
                    {error.innerError instanceof UserError &&
                        error.innerError.innerError && <div>---</div>}
                </div>
            )
            error = error.innerError
        }

        return fullInnerError
    }

    @action.bound
    private toggleDetailInfo() {
        this.showDetails = !this.showDetails
    }

    @observable
    private showDetails = true // @Dos false
}
