import res from './res'

res.add('kk', {
    title: 'Текущие заявки',
    cols: {
        id: '№',
        orderType: 'Тип заявки',
        orderStatus: 'Статус'
    }
})
