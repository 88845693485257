import React from 'react'

import res from './res'

import styles from '../styles.css'

function actionPayments(
    action: string,
    paymentsCount: number,
    amounts: React.ReactNode,
    reasonField: React.ReactNode
) {
    return (
        <>
            {action} {paymentsCount} {getPaymentCountLabel(paymentsCount)} {amounts} сомасына
            {reasonField}
        </>
    )
}

res.add('kk', {
    noSelection: 'Растау, жою немесе қайтару үшін төлемдерді белгілеңіз',
    multiStatusSelection:
        'Әрекеттерді орындау үшін бірдей мәртебесі бар төлемдерді таңдаңыз',
    fullAction: 'Толық жүргізу',
    partialAction: 'Ішінара жүргізу',

    chargeAction: 'Өткізу',
    chargeFullForm: (paymentsCount, amounts, reason) =>
        actionPayments('Есептен шығару', paymentsCount, amounts, reason),
    chargePartialTitle: 'Ішінара есептен шығару',
    chargePartialDisable: 'Бір төлемді таңдаған кезде ішінара жүргізуге болады',
    chargePartialOkButton: 'Өткізу',

    cancelAction: 'Төлемді болдырмау',
    cancelForm: (paymentsCount, amounts, reason) =>
        actionPayments('Болдырмау', paymentsCount, amounts, reason),

    refundAction: 'Қайтаруды рәсімдеу',
    refundFullForm: (paymentsCount, amounts, reason) =>
        actionPayments('Қайтару', paymentsCount, amounts, reason),
    refundPartialTitle: 'Ішінара қайтару',
    refundPartialDisable: 'Бір төлемді таңдаған кезде ішінара қайтаруға болады',
    refundPartialOkButton: 'Қайтару',

    noActionsForCancelled: (
        <>
            <span className={styles.status}>"Жойылды"</span> мәртебесі бар
            төлемдермен әрекет ету мүмкін емес
        </>
    ),
    noActionsForRefunded: (
        <>
            <span className={styles.status}>"Қайтару”</span> мәртебесі бар
            төлемдермен іс-қимыл жасау мүмкін емес
        </>
    ),
    confirmActions: {
        mainText: 'Растау ',
        simpleText: 'Төлемді растаңыз:',
        simpleCancelText: 'Төлем күшін жоюды растаңыз:',
        actions: {
            CHARGE: 'төлем ',
            PARTIALCHARGE: 'ішінара өткізу',
            CANCEL: 'жоюға  ',
            REFUND: 'қайтару ',
            PARTIALREFUND: 'ішінара қайтару '
        },
        sumText: 'сомасына ',
        and: 'және '
    },
    confirmAction: (
        actionType: string,
        amount: number,
        partialAmount?: number
    ) => {
        let confirmLabel = `${res().confirmActions.mainText}
                            ${res().confirmActions.actions[actionType]}
                            ${res().confirmActions.sumText} ${amount}`
        if (partialAmount) {
            confirmLabel += `${res().confirmActions.and}
            ${res().confirmActions.actions.PARTIALCHARGE}
            ${res().confirmActions.sumText} ${partialAmount}`
        }
        return confirmLabel
    },
    amountForm: {
        paymentAmount: 'Төлем сомасы',
        chargeAmount: 'Есептен шығару сомасы',
        payoutAmount: 'Қайтару сомасы'
    },
    reason: 'Қайтару себебі',
    reasonForReturn: 'Қайтару себебі',
    optional: 'міндетті емес',
    enterTheAmount: 'Cоманы енгізіңіз',
    okButton: 'Иә',
    cancelButton: 'Жоқ',
    partialCancelButton: 'Болдырмау',
    actionInProgress: 'Операция жүргізілуде'
})

const getPaymentCountLabel = (paymentsCount: number) => {
    if (paymentsCount >= 5 && paymentsCount <= 20) {
        return 'төлемдер'
    }

    const lastDigit = paymentsCount % 10

    if (lastDigit === 1) {
        return 'төлем'
    }

    if (lastDigit >= 2 && lastDigit <= 4) {
        return 'төлем'
    }

    return 'төлемдер'
}
