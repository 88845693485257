import React from 'react'

import { Button, Layout, Select } from 'antd'
import { observer } from 'mobx-react'

import AppStore from '~/app-store'
// import { CompanyIcon } from '~/components/icon'
import mainLayoutStore from '~/layouts/main/layout-store'

import MainMenu from './main-menu'

import styles from './styles.css'

import BrowserStore from '~/services/browser/browser-state'

import { Drawer } from 'antd'
import { LogoutIcon, MenuFoldIcon, MenuUnfoldIcon } from '~/components/icon'
import Header from '~/layouts/main/header'
import MobileHeader from '~/layouts/main/mobile-menu-header'
import auth from '~/services/auth'

import Footer from '../components/footer'
import res from './res'
import LanguageSelectAuthorized from '~/components/language-select-authorized'

const Option = Select.Option

const Sider = observer(
    (props: {
        layoutStore: typeof mainLayoutStore
        appStore: typeof AppStore
    }) => {
        const { layoutStore, appStore } = props

        const defaultCompanyName = 'Электроника'
        const isMobile = BrowserStore.isMobile
        if (!isMobile) {
            return (
                <Layout.Sider
                    className={`${styles.sider} ${layoutStore.isSiderVisible
                        ? styles.visible
                        : styles.hidden
                        }`}
                >
                    {/*<Select className={styles.companySelect}*/}
                    {/*defaultValue={defaultCompanyName}>*/}
                    {/*<Option value={defaultCompanyName}>*/}
                    {/*<span><CompanyIcon className={styles.icon} />{defaultCompanyName}</span>*/}
                    {/*</Option>*/}
                    {/*</Select>*/}
                    <MainMenu layoutStore={layoutStore} appStore={appStore} />
                </Layout.Sider>
            )
        } else {
            return (
                <div className={styles.siderMobileContainer}>
                    {layoutStore.isMenuInitilazed && (
                        <div className={styles.siderHandlerClosed}>
                            <Button
                                type="primary"
                                shape="circle"
                                onClick={() => layoutStore.toggleSider()}
                                className={`${styles.siderBtn} mobile-hint-section-1-step-1`}
                            >
                                <MenuUnfoldIcon></MenuUnfoldIcon>
                            </Button>
                        </div>
                    )}
                    <Drawer
                        placement="left"
                        visible={layoutStore.isSiderVisible}
                        closable={false}
                        className={styles.siderMobile}
                    >
                        <div className={styles.siderHandler}>
                            <Button
                                type="primary"
                                shape="circle"
                                onClick={() => layoutStore.toggleSider()}
                                className={`${styles.siderBtn} mobile-hint-section-1-step-1`}
                            >
                                {!layoutStore.isSiderVisible && (
                                    <MenuUnfoldIcon></MenuUnfoldIcon>
                                )}
                                {layoutStore.isSiderVisible && (
                                    <MenuFoldIcon></MenuFoldIcon>
                                )}
                            </Button>
                        </div>
                        <MobileHeader
                            layoutStore={layoutStore}
                            appStore={appStore}
                        ></MobileHeader>
                        <MainMenu
                            layoutStore={layoutStore}
                            appStore={appStore}
                        />
                        <div className={styles.langSelect}>
                            <LanguageSelectAuthorized labelStyle={{ color: 'var(--neutral-black)' }} placement="bottomLeft" />
                        </div>
                        {BrowserStore.isMobile && false && (
                            <div className={styles.mobileLogoutCountainer}>
                                <a key="signOut" onClick={() => auth.logout()}>
                                    <span className={styles.logoutIcon}>
                                        <LogoutIcon />
                                    </span>
                                    <span className={styles.logoutText}>
                                        {res().logOut}
                                    </span>
                                </a>
                            </div>
                        )}
                        <div className={styles.mobileFooterContainer}>
                            <Footer />
                        </div>
                    </Drawer>
                </div>
            )
        }
    }
)

export default Sider
