import res from './res'

res.add('kk', {
    generalInformation: [
        'Бүйірлік меню көмегімен жеке кабинетте барлық навигация жүзеге асырылады'
    ],
    dashboard: [
        'Статистикадан: Сәтті/Сәтсіз транзакцияларды, Эмитент банктерді, Арналарды бақылай аласыз'
    ],
    transactions: [
        'Статистиканың көмегімен сіз таңдалған кезеңдегі төлемдер туралы ақпаратты бақылай аласыз',
        'Төлемдерді күні немесе мәртебесі бойынша сұрыптауға болады',
        'Сүзгі көмегімен сіз бірнеше өрістер бойынша кеңейтілген іздеуді жүзеге асыра аласыз'
    ],
    statements: [
        'Мұнда Сіздің дүкендеріңіз (келісімшарттар) көрсетіледі. Мәлімдемелерді басқару үшін қажетті терминалды таңдаңыз',
        'Сіз күнделікті, апталық немесе ай сайынғы автоматты пошта хабарламаларын орната аласыз'
    ],
    paymentLinks: [
        'Бірегей сілтеме жасаңыз, ол арқылы сатып алушы сілтемені бір рет басып Apple Pay немесе Samsung Pay көмегімен төлейді'
    ],
    credit: [
        'Дүкенге клиенттерден төлемдерді бөліп төлеу немесе несие беру мүмкіндігін қосыңыз'
    ],
    configureEpayTemplate: [
        'Төлем беті үлгісін өз дүкеніңізге бейімдеңіз'
    ],
    terminalSettings: [
        '«Белсенді» тармағындағы қосқыш терминалдың өзін қосуға\\өшіруге жауап береді',
        '«Картаны сақтау» тармағындағы қосқыш сіздің клиенттеріңізге картаны сақтауға мүмкіндік беретін функцияны қосады\\өшіреді',
        'Егер «1-қадамдық схема» тармағындағы қосқыш белсендірілген күйде болса, онда төлем жасалған жағдайда клиенттің картасынан қаражат банктің соманы бастапқы ұстап қалуынсыз бірден есептен шығарылады. Егер бұл қосқыш өшірілген болса, онда соманы бастапқыда банк ұстап қалады және төлемді «Төлемдер\\ePay-терминалдар» бөлімінде дербес жүргізген кезде есептен шығарылады'
    ],
    team: [
        'Мұнда сіздің барлық қызметкерлеріңіз және оларға берілген рөлдер көрсетіледі. Сіз қызметкердің рөлін өзгерте аласыз немесе оны жоя аласыз'
    ]
})
