import React from 'react'

import res from './res'

res.add('kk', {
    dataFilterPlaceholder: 'Атрибуттар бойынша сүзгі',
    loading: 'Аудармаларды жүктеу...',
    payment: 'Аударым',
    pageInfo: (info: { from: number; to: number; total: number }) => (
        <>
            {info.from}-{info.to}&nbsp;&nbsp;из&nbsp;&nbsp;{info.total}
        </>
    ),
    operationsResult: (totalCount: number, successCount: number) =>
        `${successCount}дан ${totalCount} операциялар сәтті орындалды`,
    actions: 'Әрекеттер',
    gridControlsTooltips: {
        reload: 'Деректерді жаңарту',
        settings: 'Кесте параметрлері',
        firstPage: 'Бірінші бет',
        prevPage: 'Алдыңғы бет',
        nextPage: 'Келесі бет',
        lastPage: 'Соңғы бет'
    },
    filters: 'Сүзгілер'
})
