import { action, computed, observable } from 'mobx'

export default class WelcomeMobileStore {

    @observable
    public isSkipModalVisible: boolean = false

    @observable
    public currentSlideIndex: number = 0

    public LAST_INDEX = 5

    @action
    public hideSkipModal() {
        this.isSkipModalVisible = false
    }

    @action
    public showSkipModal() {
        this.isSkipModalVisible = true
    }

    @action
    public goNextSlide() {
        if (this.currentSlideIndex + 1 <= this.LAST_INDEX) {
            this.currentSlideIndex++
        }
    }

    @action
    public goPrevSlide() {
        if (this.currentSlideIndex - 1 >= 0) {
            this.currentSlideIndex--
        }
    }

    @computed
    public get isFirstSlide() {
        return this.currentSlideIndex === 0
    }

    @computed
    public get isLastSlide() {
        return this.currentSlideIndex === 5
    }
}
