import { observer } from 'mobx-react'
import { Component, useState } from 'react'
import React from 'react'

import {
    Button,
    Checkbox,
    Col,
    Form,
    IconButton,
    Input,
    InputNumber,
    List,
    Radio,
    RangePopover,
    Row,
    Spin,
    Switch,
    Table
} from '~/components'

import { FormComponentProps, FormProps } from 'antd/lib/form'

const Group = Radio.Group
const { TextArea } = Input

import { action, toJS } from 'mobx'
import moment from 'moment'
import {
    ContractStatementSchedule,
    FrequencyType,
    ManualStatement
} from '~/api/statements'
import PeriodSelector from '~/components/date-selector/period-selector'
import { createFormItem } from '~/components/form-item-factory'
import {
    ArrowLeftIcon,
    CancelIcon,
    CheckIcon,
    CloseModalIcon,
    PlusIcon,
    SaveIcon
} from '~/components/icon'
import { ModalContainerContext } from '~/components/modal/modal-context'
import { RangePopoverValue } from '~/components/range-popover'
import layoutStyles from '~/layouts/main/styles.css'

import styles from './styles.css'

import globalRes from '~/res'
import BrowserStore from '~/services/browser/browser-state'

import { BinIcon, FormIcon } from '~/components/icon'

import isNil from 'lodash/isNil'
import pullAt from 'lodash/pullAt'
import remove from 'lodash/remove'

import get from 'lodash/get'
import isObject from 'lodash/isObject'
import 'reflect-metadata'
import Page from '~/layouts/main/page'

import { Dictionary } from 'lodash'
import each from 'lodash/each'
import groupBy from 'lodash/groupBy'
import {
    FieldGroupInterface,
    FormFieldInterface
} from '~/components/form-builder/form-control-fields'

export interface ProperyFieldInterface {
    fieldName: string
    valueControl?: any
    valuePropName?: string
    isObject?: boolean
    propertyType?: any
    parentObjectKey?: string
    fieldSettings?: FormFieldInterface
}

interface FormControlProps extends FormComponentProps {
    formTitle?: React.ReactNode
    dataObject?: any
    dataObjectType?: any
    rootObjectType?: any
    field?: FormFieldInterface
    layoutOptions?: {
        labelCol?: { span: number }
        wrapperCol?: { span: number }
    }
    onDataChange?: (changes: any) => void
}
interface FormControlState {
    statementSchedule?: ContractStatementSchedule
    manualStatement?: ManualStatement
    isEmailInEdit?: boolean
}

const FormItem = createFormItem({
    labelCol: {
        xs: { span: 28 },
        sm: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 28 },
        sm: { span: 16 }
    }
})

class FormControl extends Component<FormControlProps, FormControlState> {
    public render() {
        // {this.getFields()}
        return (
            <Page title={this.props.formTitle}>
                <Form
                    className={styles.productForm}
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 12 }}
                >
                    <FormFieldsControl
                        className={styles.mainForm}
                        dataObject={this.props.dataObject}
                        form={this.props.form}
                        field={this.props.field}
                        layoutOptions={{
                            labelCol: { span: 10 },
                            wrapperCol: { span: 12 }
                        }}
                        dataObjectType={this.props.dataObjectType}
                    ></FormFieldsControl>
                </Form>
            </Page>
        )
    }
}

interface FormFieldsControlProps extends FormComponentProps {
    formTitle?: React.ReactNode
    field?: FormFieldInterface
    dataObject?: any
    dataObjectType?: any
    objectPath?: string
    fieldName?: string
    className?: string
    parentObjectKey?: string
    layoutOptions?: { labelCol: { span: number }; wrapperCol: { span: number } }
}

class FormFieldsControl extends Component<FormFieldsControlProps, any> {
    public render() {
        const localization = this.props.field.localizationScheme()
        const blockTitle = this.props.field.localizationTitleKey
            ? get(localization, this.props.field.localizationTitleKey)
            : ''
        return (
            <Page
                title={blockTitle}
                contentClassName={styles.subFormContent}
                pageClassName={
                    this.props.className ? this.props.className : styles.subPage
                }
                contentHeaderClassName={styles.contentHeader}
                titleClassName={styles.subFormTitle}
            >
                <div>
                    {this.getFields().map(group => (
                        <div>
                            {group.groupSettings &&
                                group.groupSettings.showGroupName && (
                                    <div className={styles.groupTitle}>
                                        {
                                            localization.formFields.groups[
                                            group.groupName
                                            ]
                                        }
                                    </div>
                                )}
                            {group.children.map(child => child.control)}
                        </div>
                    ))}
                </div>
            </Page>
        )
    }
    public getFormItemLabel = (element): string => {
        let elementLabel = element.fieldName
        const localizationScheme = this.props.field.localizationScheme()
        if (!element.isObject) {
            if (this.props.field) {
                const subKey = this.props.field.localizationSchemeSubKey
                if (
                    subKey &&
                    get(localizationScheme, subKey) &&
                    get(localizationScheme, subKey)[element.fieldName]
                ) {
                    elementLabel = get(localizationScheme, subKey)[
                        element.fieldName
                    ]
                } else if (localizationScheme[element.fieldName]) {
                    elementLabel =
                        localizationScheme.inquiryFields[element.fieldName]
                }
            }
        } else if (element.localizationTitleKey) {
            elementLabel = localizationScheme[element.localizationTitleKey]
        }
        return elementLabel
    }
    public getGroupLabel = (element): string => {
        let elementLabel = element.fieldName
        const localizationScheme = element
        if (!element.isObject) {
            if (this.props.field) {
                const subKey = this.props.field.localizationSchemeSubKey
                if (
                    subKey &&
                    get(localizationScheme, subKey) &&
                    get(localizationScheme, subKey)[element.fieldName]
                ) {
                    elementLabel = get(localizationScheme, subKey)[
                        element.fieldName
                    ]
                } else if (localizationScheme[element.fieldName]) {
                    elementLabel =
                        localizationScheme.inquiryFields[element.fieldName]
                }
            }
        } else if (element.localizationTitleKey) {
            elementLabel = localizationScheme[element.localizationTitleKey]
        }
        return elementLabel
    }

    public getControl = (
        element: ProperyFieldInterface,
        field: FormFieldInterface,
        dataObject: any,
        parentObjectKey: string
    ) => {
        const { getFieldDecorator } = this.props.form
        const fieldDisabled =
            element.fieldSettings && element.fieldSettings.disabled
                ? element.fieldSettings.disabled(dataObject)
                : false
        const rules =
            element.fieldSettings && element.fieldSettings.rules
                ? element.fieldSettings.rules
                : []
        const controlElement = element.isObject ? (
            <element.valueControl
                dataObject={dataObject[element.fieldName]}
                fieldName={element.fieldName}
                dataObjectType={element.propertyType}
                field={field}
                parentObjectKey={element.fieldName}
                layoutOptions={this.props.layoutOptions}
                form={this.props.form}
                disabled={fieldDisabled}
            ></element.valueControl>
        ) : (
            getFieldDecorator(`${element.fieldName}`, {
                initialValue: dataObject
                    ? dataObject[element.fieldName]
                    : undefined, // dataObject[element],
                valuePropName: element.valuePropName
                    ? element.valuePropName
                    : 'value',
                rules
            })(
                <element.valueControl
                    placeholder="placeholder"
                    disabled={fieldDisabled}
                />
            )
        )
        return controlElement
    }

    public getFields = (): Array<{
        groupName: string
        children: any[]
        showGroupName?: boolean
        groupSettings: FieldGroupInterface
    }> => {
        const fieldTypes = getFieldsTypeInfo(
            this.props.field && this.props.field.dataObjectType
                ? this.props.field.dataObjectType
                : this.props.dataObjectType,
            this.props.field
        )
        // let result = Reflect.GetOrCreateMetadataMap //Reflect.getMetadata('design:type', target)
        const groupsWithChildren: Array<{
            groupName: string
            children: any[]
            showGroupName?: boolean
            groupSettings: FieldGroupInterface
        }> = []
        const dataObject = this.props.dataObject
        const groups = ['unnamed']
        const namedGroups: Dictionary<ProperyFieldInterface[]> = groupBy(
            fieldTypes,
            'fieldSettings.group'
        )
        each(namedGroups, (groupValue, groupKey) => {
            const children: Array<{ groupName: string; control: any }> = []
            const groupSettings = this.props.field.groups
                ? this.props.field.groups.find(
                    group => group.groupName === groupKey
                )
                : undefined
            groupValue.forEach(element => {
                const field =
                    this.props.field && this.props.field.childFields
                        ? this.props.field.childFields.find(
                            fld => fld.fieldName === element.fieldName
                        )
                        : undefined
                if (
                    !field ||
                    !field.displayOptions ||
                    !field.displayOptions.groupWith
                ) {
                    const controlElement = this.getControl(
                        element,
                        field,
                        dataObject,
                        element.fieldName
                    )
                    const stackedFields = groupValue.filter(
                        elm =>
                            elm.fieldSettings &&
                            elm.fieldSettings.displayOptions &&
                            elm.fieldSettings.displayOptions.groupWith ===
                            element.fieldName
                    )
                    const childWidth = element.isObject
                        ? { span: 24 }
                        : this.props.layoutOptions.wrapperCol
                            ? this.props.layoutOptions.wrapperCol
                            : undefined

                    stackedFields.forEach(st => {
                        if (
                            st.fieldSettings &&
                            st.fieldSettings.displayOptions &&
                            st.fieldSettings.displayOptions.width
                        ) {
                            childWidth.span =
                                childWidth.span -
                                st.fieldSettings.displayOptions.width
                        }
                    })
                    const isLabelOnStacked = stackedFields.some(stacked => {
                        return (
                            stacked &&
                            stacked.fieldSettings &&
                            stacked.fieldSettings.displayOptions &&
                            stacked.fieldSettings.displayOptions.showLabel ===
                            true
                        )
                    })
                    const isStacked = stackedFields.length > 0
                    const child = {
                        groupName: element.fieldSettings
                            ? element.fieldSettings.group
                            : 'unnamed',
                        control: (
                            <Form.Item
                                label={
                                    element.isObject
                                        ? ''
                                        : this.getFormItemLabel(element)
                                }
                                labelCol={
                                    this.props.layoutOptions.labelCol
                                        ? this.props.layoutOptions.labelCol
                                        : undefined
                                }
                                wrapperCol={childWidth}
                                className={
                                    isLabelOnStacked
                                        ? styles.stakedFormInputWithLabel
                                        : ''
                                }
                            >
                                <div>
                                    {!isStacked && (
                                        <div
                                            className={
                                                isStacked
                                                    ? styles.stakedFormInput
                                                    : ''
                                            }
                                        >
                                            {controlElement}
                                        </div>
                                    )}
                                    {isStacked && (
                                        <div className={styles.stakedChild}>
                                            {controlElement}
                                            {stackedFields.map(stacked => {
                                                return (
                                                    <span>
                                                        {stacked &&
                                                            stacked.fieldSettings &&
                                                            stacked
                                                                .fieldSettings
                                                                .displayOptions &&
                                                            stacked
                                                                .fieldSettings
                                                                .displayOptions
                                                                .showLabel ===
                                                            true && (
                                                                <span
                                                                    className={
                                                                        styles.labelAbove
                                                                    }
                                                                >
                                                                    {this.getFormItemLabel(
                                                                        stacked
                                                                    )}
                                                                </span>
                                                            )}
                                                        {this.getControl(
                                                            stacked,
                                                            this.props.field.childFields.find(
                                                                fld =>
                                                                    fld.fieldName ===
                                                                    stacked.fieldName
                                                            ),
                                                            dataObject,
                                                            element.fieldName
                                                        )}
                                                    </span>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>
                            </Form.Item>
                        )
                    }
                    children.push(child)
                }
            })
            groupsWithChildren.push({
                groupName: groupKey !== 'undefined' ? groupKey : 'default',
                children,
                groupSettings
            })
        })
        return groupsWithChildren
    }
}

function getTypeofProperty<T, K extends keyof T>(o: T, name: K) {
    return typeof o[name]
}

const getGetters = (targetObject, fieldName?): string[] => {
    const result = Object.keys(targetObject.__proto__).filter(name => {
        // tslint:disable-next-line:no-string-literal
        return (
            typeof Object.getOwnPropertyDescriptor(targetObject.__proto__, name)
                .get === 'function'
        )
    })
    return result
}

export const primitiveTypes: string[] = [
    'Boolean',
    'Null',
    'Undefined',
    'Number',
    'String',
    'BigInt',
    'Symbol',
    'Array'
]

function getType(targetObject, key) {
    return Reflect.getMetadata('design:type', targetObject, key)
}

export interface ValueControlInterface {
    control: any
    valuePropName?: string
}

const convertObjectToFields = (
    targetObject: any,
    objectType?: any,
    parentObjectKey?: string
) => {
    const objectKeys = Reflect.getMetadata('refl', objectType)
    let fieldsObject: any = {}
    objectKeys.forEach(key => {
        const propertyType = getType(new objectType(), key)
        const elementKeyPath = parentObjectKey
            ? parentObjectKey + '.' + key
            : key
        const parentKey = parentObjectKey ? parentObjectKey + '.' + key : key
        if (!primitiveTypes.includes(propertyType.name)) {
            const objectToFlat = convertObjectToFields(
                targetObject ? targetObject[key] : new propertyType(),
                propertyType,
                elementKeyPath
            )
            fieldsObject = { ...fieldsObject, ...objectToFlat }
        } else {
            fieldsObject[key] = Form.createFormField({
                value: targetObject[key],
                fieldName: parentObjectKey ? parentObjectKey + '.' + key : key,
                parentObjectKey,
                path: elementKeyPath
            })
        }
    })
    return fieldsObject
}

const getValueControl = (targetType, fieldName): ValueControlInterface => {
    const typeOfValue = targetType
    const typeName = typeOfValue ? typeOfValue.name : undefined
    if (typeOfValue && typeName && !primitiveTypes.includes(typeOfValue.name)) {
        return { control: FormFieldsControl }
    } else {
        switch (typeName) {
            case 'String':
                return { control: Input, valuePropName: 'value' }
            case 'Number':
                return { control: InputNumber, valuePropName: 'value' }
            case 'Boolean':
                return { control: Switch, valuePropName: 'checked' }
            default:
                return { control: Input, valuePropName: 'value' }
        }
    }
}
const getFieldsTypeInfo = (objectType, field): ProperyFieldInterface[] => {
    const objectKeys = Reflect.getMetadata('refl', objectType) // Object.keys(targetObject) // getGetters(targetObject)
    if (objectType === Array) {
        return []
    } else {
        const instance = new objectType()
        const presettedFields: FormFieldInterface[] =
            field && field.childFields ? field.childFields : undefined
        const presettedFieldsNames =
            field && field.childFields
                ? field.childFields.map(fld => fld.fieldName)
                : undefined
        const fields: any = objectKeys.map(key => {
            const fieldType = getType(instance, key)
            const valueControlSettings = getValueControl(fieldType, key)
            return {
                fieldName: key,
                valueControl: valueControlSettings.control,
                valuePropName: valueControlSettings.valuePropName
                    ? valueControlSettings.valuePropName
                    : 'value',
                isObject: !primitiveTypes.includes(fieldType.name),
                propertyType: fieldType,
                parentObjectKey: field.fieldName,
                fieldSettings: presettedFields
                    ? presettedFields.find(fld => fld.fieldName === key)
                    : undefined
            }
        })
        const visibleFields = presettedFieldsNames
            ? fields
                .filter(fld => presettedFieldsNames.includes(fld.fieldName))
                .sort((fld1, fld2) =>
                    fieldOrderComparer(fld1, fld2, presettedFieldsNames)
                )
            : fields
        return visibleFields
    }
}

const ControlledForm = Form.create<FormControlProps>({
    name: 'dynamic form',
    onFieldsChange(props, changedFields, allFields) {
        props.onDataChange(changedFields)
    },
    mapPropsToFields(props) {
        const objectFields = convertObjectToFields(
            props.dataObject,
            props.dataObjectType
        )
        return objectFields
    }
})(FormControl)

const fieldOrderComparer = (
    fld1: ProperyFieldInterface,
    fld2: ProperyFieldInterface,
    orderArray: string[]
) => {
    const fld1Index = orderArray.findIndex(
        fldName => fldName === fld1.fieldName
    )
    const fld2Index = orderArray.findIndex(
        fldName => fldName === fld2.fieldName
    )
    if (fld1Index > fld2Index) {
        return 1
    }
    if (fld1Index < fld2Index) {
        return -1
    }
    return 0
}

export default ControlledForm
