import FastMutex from 'fast-mutex/dist/fast-mutex'

import { THREAD_ID } from '~/app-store'
import { getUserInfo } from '~/services/auth/auth-utils'
import { throwOnFail } from '~/utils/control-flow'

export enum StorageType {
    localStorage,
    sessionStorage
}

const mutex = new FastMutex({
    clientId: THREAD_ID,
    localStorage: window.localStorage
})

export interface StorageOptions {
    storageType?: StorageType
    useUserHash?: boolean
}

export function getStorage(storageOptions: StorageOptions) {
    if (!storageOptions || storageOptions.storageType !== StorageType.sessionStorage) {
        return localStorage
    }
    return sessionStorage
}

export function get<T = any>(key: string | { key: string, defaultValue?: any }, storageOptions: StorageOptions = { storageType: StorageType.localStorage, useUserHash: true }): T {
    try {
        const storage = getStorage(storageOptions)
        const defaultValue = typeof key === 'string' ? undefined : key.defaultValue
        const storageKey = typeof key === 'string' ? key : key.key
        const prefix = storageOptions && storageOptions.useUserHash ? getUserInfo().userHash + '_' : ''
        const value = storage.getItem(prefix + storageKey)
        if (value === null || value === 'undefined') return defaultValue
        return JSON.parse(value)
    } catch {
        return undefined
    }
}

export function set(key: string, value: any, storageOptions: StorageOptions = { storageType: StorageType.localStorage, useUserHash: true }) {
    try {
        const storage = getStorage(storageOptions)
        const prefix = storageOptions && storageOptions.useUserHash ? getUserInfo().userHash + '_' : ''
        storage.setItem(prefix + key, JSON.stringify(value))
    } catch {
        // do nothing
    }
}

export async function setWithMutex(key: string, value: any, storageOptions: StorageOptions = { storageType: StorageType.localStorage, useUserHash: true }): Promise<void> {

    await throwOnFail(mutex.lock(key), 'Mutex locked')
    set(key, value, storageOptions)
    mutex.release(key)
}

export default { get, set, setWithMutex }
