import React from 'react'

import { observer, Provider } from 'mobx-react'

import Page from '~/layouts/main/page'

import { RangePopover } from '~/components'

import DashboardStore from './dashboard-store'

import { Button } from '~/components'

import { CalendarIcon, ChartIcon, PieIcon } from '../../components/icon/index'
import IssuingBanksChart from './components/charts/issuing-banks-chart'
import SuccessfulTransactionsChart from './components/charts/successful-transactions-chart'
import UnsuccessfulTransactionsChart from './components/charts/unsuccessful-transactions-chart'

import res from './res'

import PeriodSelector from '~/components/date-selector/period-selector'
import BrowserStore from '~/services/browser/browser-state'
import styles from './styles.css'
import locale from '~/utils/locale'
import CartTypeChart from '~/pages/dashboard/components/charts/cart-type-chart'
import InteractiveMobileHints from '~/components/interactive-mobile-hints'

@observer
export default class Dashboard extends React.Component<{ store: DashboardStore }, {}> {

    public render() {
        const { store } = this.props

        const makeRangeSelector = () => {
            if (locale.lang === 'kk') {
                return (
                    <React.Fragment>
                        <RangePopover
                            placement="bottomLeft"
                            presets={store.rangePresets}
                            value={store.range}
                            onChange={store.setRange}
                        />
                        {res().dateSelectorLabel}
                    </React.Fragment>
                )
            }
            return (
                <React.Fragment>
                    {res().dateSelectorLabel}{' '}
                    <RangePopover
                        placement="bottomLeft"
                        presets={store.rangePresets}
                        value={store.range}
                        onChange={store.setRange}
                    />
                </React.Fragment>
            )
        }
        return (
            <Page
                title={res().title}
                controls={
                    !BrowserStore.isMobile ? (
                        <React.Fragment>{makeRangeSelector()}</React.Fragment>
                    ) : (
                        <React.Fragment>
                            <a
                                href="javascript:void(0)"
                                onClick={store.showDateSelector}
                                className={styles.text}
                            >
                                {store.currentLabel}
                            </a>
                        </React.Fragment>
                    )
                }
                contentClassName={styles.pageContent}
                toolbarClassName={styles.mobileToolbar}
                toolbar={
                    <React.Fragment>
                        <div>
                            <Button onClick={store.showDateSelector}>
                                <CalendarIcon></CalendarIcon>
                            </Button>
                            <PeriodSelector
                                selectedValue={store.range}
                                values={store.rangePresets}
                                onClose={store.setRange}
                                visible={store.rangeSelectorVisible}
                            ></PeriodSelector>

                            <Button
                                onClick={() =>
                                    store.setFormFieldVisibility(
                                        'SuccessfulTransactionsChart',
                                        true
                                    )
                                }
                            >
                                <ChartIcon></ChartIcon>
                            </Button>
                            <Button
                                onClick={() =>
                                    store.setFormFieldVisibility(
                                        'IssuingBanksChart',
                                        true
                                    )
                                }
                            >
                                <PieIcon></PieIcon>
                            </Button>
                        </div>
                    </React.Fragment>
                }
            >
                <Provider dashBoardStore={store}>
                    <React.Fragment>
                        {!store.isChartDataLoading && store.mobileHintStore &&
                            <InteractiveMobileHints store={store.mobileHintStore} />
                        }
                        <SuccessfulTransactionsChart
                            title={res().charts.successfulTransactions.title}
                            fieldsSettingsSubtitle={res().charts.successfulTransactions.fieldsSettings}
                            loading={store.successulTransactionsLoading}
                            data={store.successfulTransactionsData}
                            className={styles.item}
                            chartName="SuccessfulTransactionsChart"
                        />
                        <UnsuccessfulTransactionsChart
                            title={res().charts.unsuccessfulTransactions.title}
                            fieldsSettingsSubtitle={res().charts.unsuccessfulTransactions.fieldsSettings}
                            loading={store.unsuccessulTransactionsLoading}
                            data={store.unsuccessfulTransactionsData}
                            className={styles.item}
                            chartName="UnsuccessfulTransactionsChart"
                        />
                        <IssuingBanksChart
                            title={res().charts.issuingBanks.title}
                            fieldsSettingsSubtitle={res().charts.issuingBanks.fieldsSettings}
                            loading={store.issuingBanksTransactionsLoading}
                            data={store.issuingBanksData}
                            className={styles.item}
                            chartName="IssuingBanksChart"
                        />
                        <CartTypeChart
                            title={res().charts.cartType.title}
                            fieldsSettingsSubtitle={res().charts.cartType.fieldsSettings}
                            loading={store.cartTypeTransactionsLoading}
                            data={store.cartTypeTransactionsData}
                            className={styles.item}
                            chartName="CartTypeChart"
                        />

                        {/*<IssuingBanksChart*/}
                        {/*    loading={store.issuingBanksTransactionsLoading}*/}
                        {/*    data={store.issuingBanksData}*/}
                        {/*    className={styles.item}*/}
                        {/*    chartName="IssuingBanksChart"*/}
                        {/*/>*/}

                        {/*<ChannelsChart*/}
                        {/*    data={store.channelsData}*/}
                        {/*    loading={store.channelsDataLoading}*/}
                        {/*    className={styles.item}*/}
                        {/*    chartName="ChannelsChart"*/}
                        {/*/>*/}
                    </React.Fragment>
                </Provider>
            </Page>
        )
    }
}
