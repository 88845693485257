import React from 'react'

const SvgFilter = props => (
    <svg width="1em" height="1em" viewBox="0 0 21 19" {...props}>
        <path
            d="M20 1H1l7.6 8.934v6.177L12.4 18V9.934L20 1z"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default SvgFilter
