import 'core-js/features/array'
import 'core-js/features/array/find'
import 'core-js/features/map'
import 'core-js/features/number/is-finite'
import 'core-js/features/object/assign'
import 'core-js/features/object/entries'

import 'core-js/features/promise'
import 'core-js/features/set'
import 'core-js/features/string'
import 'core-js/features/typed-array'
import 'html5-history-api'

// const location = (window.history as any).location || window.location

// fetch API polyfill
import 'unfetch/polyfill'

// polyfill for React animations
import 'raf/polyfill'

import 'resize-observer-polyfill'

import 'classlist-polyfill'
