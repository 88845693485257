import React from 'react'
import res from './res'

res.add('en', {
    title: 'Inquiry for product: ePay',
    contracts: 'Сontracts',
    contract: 'Сontract',
    sortBy: 'Sort by:',
    sortOptions: {
        date: 'Date of last schedule',
        name: 'Name',
        contract: 'Contract',
        type: 'Type',
        asc: {
            date: 'old first',
            name: 'ascending',
            contract: 'ascending',
            type: 'ascending'
        },
        desc: {
            date: 'new first',
            name: 'descending',
            contract: 'descending',
            type: 'descending'
        }
    },
    dateRanges: {
        yesterday: 'yesterday',
        priorWeek: 'prior week',
        priorMonth: 'prior month',
        customPeriod: 'custom period'
    },
    statusType: {
        forming: 'Формирование заявки',
        created: 'Подача заявки', // Создана,
        processing: 'Обработка', // - В обработке,
        approved: 'Утверждение', // - Утверждена,
        returned: 'Возвращено на доработку', // - Возвращена на доработку
        canceled: 'Отменена', // - От
        retry: 'Повторная подача'
    },
    statusSteps: {
        forming: 'Формирование заявки',
        created: 'Заявка подана', // Создана,
        processing: 'Обраотка', // - В обработке,
        approved: 'Утверждение', // - Утверждена,
        returned: 'Возвращено на доработку', // - Возвращена на доработку
        canceled: 'Отменена', // - От
        retry: 'Повторная подача'
    },
    statusAction: {
        forming: 'Редактировать',
        created: 'Отменить', // Создана,
        processing: 'Отменить', // - В обработке,
        approved: '', // - Утверждена,
        returned: 'Редактировать', // - Возвращена на доработку
        canceled: '', // - От
        retry: 'Отменить'
    },
    errors: {
        productsLoadingError: 'products loading error',
        productCreatingError: 'product creating error',
        productUpdatingError: 'product updating error',
        productHistoryLoadingError: 'product history loading error'
    },
    createInquiry: 'Заявка на продукт',
    inquiryFields: {
        companyInfo: 'Информация о предприятии',
        traderTypeLabel: 'Тип предприятия',
        iinBin: 'БИН предприятия',
        traderType: 'Тип предприятия',
        traderName: 'Юридическое наименование предприятия',
        traderNameLt: 'Юридическое наименование предприятия (лат.)',
        mainActivity: 'Основной вид деятельности',
        createdDate: 'Дата образования предприятия',
        serviceBank: 'Банк обслуживания',
        servicePeriodOfBank: 'Срок обслуживания в Банке',
        hasCredits: 'Наличие кредитов',
        registerCompanyAddress: 'Юридический адрес предприятия',
        registerCompanyAddressZip: 'Индекс юридического адреса',
        realCompanyAddress: 'Фактический адрес предприятия',
        realCompanyAddressZip: 'Индекс фактического адреса',
        realPhone: 'Телефон предприятия',
        realFax: 'Факс предприятия',
        contactPhone:
            'Контактный телефон для клиентов компании (для сайта банка)',
        premisesOwnership: 'Принадлежность помещения',
        lessorName: 'Наименование арендодателя (лизингодателя)',
        leaseTerm: 'Срок аренды (лизинга)',
        hasEncumbranceOnProperty: 'Имеется обременение на имущество',
        registrationAddress: {
            type: 'Тип',
            countryCode: 'Код страны',
            zip: 'Почтовый индекс',
            regionCode: 'Код региона',
            districtCode: 'Код района',
            cityCode: 'Код города',
            townCode: 'Код административного центра',
            microdistrictCode: 'Код микрорайона',
            street: 'Улица',
            buildingNumber: 'Номер дома',
            apartmentNumber: 'Номер квартиры',
            fullAddress: 'Полный адрес'
        },
        ceoShortInfoLabel: 'Данные руководителя',
        personLegalAddressLabel: 'Адрес прописки',
        personRealAddressLabel: 'Адрес проживания',
        chiefAccountantShortInfoLabel: 'Данные бухгалтера',
        cheifAccountantDoesNotExists: 'No Chief Accountant'
    },
    tooltips: {
        enabledStatus:
            'Configuration for automatic products has been created and activated',
        disabledStatus:
            'Configuration for automatic products has been created but not activated',
        noEmails: 'No recipients specified',
        downloadproduct: 'Download product',
        productCreating: 'product creating',
        reload: 'Reload data',
        productSettings: 'product settings'
    },
    pageInfo: (info: { from: number; to: number; total: number }) => (
        <>
            {info.from}-{info.to}
        </>
    ),
    nextButton: 'Next',
    previousButton: 'Previous',
    automaticproducts: 'Automatic products',
    productHistory: 'Formed products',
    productsFor: 'products',
    active: 'Activated',
    inactive: 'Not activated',
    product: 'product'
})
