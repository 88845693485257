import React from 'react'

const SvgCloseModal = props => (
    <svg width="1em" height="1em" viewBox="0 0 15 15" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.194 2.194C5.12-.732 9.88-.73 12.806 2.194c2.925 2.926 2.925 7.686 0 10.612-2.926 2.925-7.686 2.925-10.612 0C-.73 9.88-.732 5.12 2.194 2.194zm7.449 8.265a.577.577 0 00.816-.816L8.316 7.5l2.04-2.04a.577.577 0 10-.815-.817L7.5 6.683 5.357 4.542a.577.577 0 10-.816.816L6.684 7.5 4.439 9.745a.577.577 0 10.816.816L7.5 8.316l2.143 2.143z"
        />
    </svg>
)

export default SvgCloseModal
