import { PaymentStatus } from '~/api'

export default {

    [PaymentStatus.auth]: {
        color: '#FFB600'
    },
    [PaymentStatus.charged]: {
        color: '#05B9A6'
    },
    [PaymentStatus.cancelled]: {
        color: '#BDBDBD'
    },
    [PaymentStatus.refunded]: {
        color: '#F42B74'
    },
    [PaymentStatus.rejected]: {
        color: '#F42B74'
    }
}
