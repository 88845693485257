import { get, post } from '~/utils/ajax'

// import { getCardSystemType } from '~/utils'

import {
    P2PTransferRequest,
    P2PTransferResponse,
    P2PSummary,
    P2PSummaryRequest
} from './contracts'

export * from './contracts'

export const getP2PTransfers = (request: P2PTransferRequest) =>
    post<P2PTransferResponse>('p2p/operations', request).then(response => {
        // if (!response.error && !!response.result && !!response.result.records) {
        //     response.result.records.forEach(p => {
        //         p.cardType = p.cardMask
        //             ? getCardSystemType(p.cardMask)
        //             : undefined
        //         p.fee = undefined
        //         p.ipCountry = 'Казахстан'
        //         p.ipCity = 'г. Алматы'
        //         p.ipRegion = 'Алматинская обл.'
        //     })
        // }
        return response
    })

export const getP2PSummary = (request: P2PSummaryRequest) =>
    get<P2PSummary>('operations/summary', {
        from: request.from.format('YYYY-MM-DD'),
        to: request.to.format('YYYY-MM-DD')
    }).then(data => {
        if (!data.error) {
            data.result.timeline.forEach(item => {
                item.time = new Date(item.time)
            })
        }

        return data
    })

export const cancelP2P = (paymentId: string) =>
    post<void>(`operation/${paymentId}/cancel`)

export const chargeP2P = (paymentId: string, amount?: number) =>
    post<void>(
        `operation/${paymentId}/charge${amount ? `?amount=${amount}` : ''}`
    )

export const refundP2P = (paymentId: string, amount?: number) =>
    post<void>(
        `operation/${paymentId}/refund`,
        amount === undefined ? undefined : { amount }
    )

export const refundCreditP2P = (creditOrderId, creditAmount) =>
    post<void>('credit-cancel', { orderId: creditOrderId, amount: creditAmount })
