import { get, post } from '~/utils/ajax'

// import { getCardSystemType } from '~/utils'

import {
    AFTPaymentRequest,
    AFTPaymentResponse,
    AFTSummary,
    AFTSummaryRequest
} from './contracts'

export * from './contracts'

export const getAFTPayments = (request: AFTPaymentRequest) =>
    post<AFTPaymentResponse>('chdebit/operations', request).then(response => {
        // if (!response.error && !!response.result && !!response.result.records) {
        //     response.result.records.forEach(p => {
        //         p.cardType = p.cardMask
        //             ? getCardSystemType(p.cardMask)
        //             : undefined
        //         p.fee = undefined
        //         p.ipCountry = 'Казахстан'
        //         p.ipCity = 'г. Алматы'
        //         p.ipRegion = 'Алматинская обл.'
        //     })
        // }
        return response
    })

export const getAFTSummary = (request: AFTSummaryRequest) =>
    get<AFTSummary>('operations/summary', {
        from: request.from.format('YYYY-MM-DD'),
        to: request.to.format('YYYY-MM-DD')
    }).then(data => {
        if (!data.error) {
            data.result.timeline.forEach(item => {
                item.time = new Date(item.time)
            })
        }

        return data
    })

export const cancelAFT = (paymentId: string) =>
    post<void>(`operation/${paymentId}/cancel`)

export const chargeAFT = (paymentId: string, amount?: number) =>
    post<void>(
        `operation/${paymentId}/charge${amount ? `?amount=${amount}` : ''}`
    )

export const refundAFT = (paymentId: string, amount?: number) =>
    post<void>(
        `operation/${paymentId}/refund`,
        amount === undefined ? undefined : { amount }
    )

export const refundCreditAFT = (creditOrderId, creditAmount) =>
    post<void>('credit-cancel', { orderId: creditOrderId, amount: creditAmount })
