import React from 'react'
import res from './res'

res.add('ru', {
    title: 'Выписки по платежам',
    contracts: 'Контрактов',
    contract: 'Контракт',
    sortBy: 'Сортировать по:',
    sortOptions: {
        date: 'Дате последней выписки',
        name: 'Названию',
        contract: 'Котракту',
        type: 'Типу',
        asc: {
            date: 'cтарые вначале',
            name: 'по возрастанию',
            contract: 'по возрастанию',
            type: 'по возрастанию'
        },
        desc: {
            date: 'новые вначале',
            name: 'по убыванию',
            contract: 'по убыванию',
            type: 'по убыванию'
        }
    },
    dataFields: {
        titles: {
            contract: 'Контракт',
            type: 'Тип',
            daily: 'Ежедневно',
            weekly: 'Еженедельно',
            monthly: 'Ежемесячно',
            terminals: 'Терминалы',
            name: 'Название'
        },
        historyTitles: {
            create: 'Сформировано',
            period: 'За период',
            emails: 'Получатели',
            initiateType: 'Тип инициации',
            format: 'Формат',
            status: 'Статус'
        }
    },
    dateRanges: {
        yesterday: 'вчера',
        priorWeek: 'прошлую неделю',
        priorMonth: 'прошлый месяц',
        customPeriod: 'указанный период'
    },
    initiationType: {
        custom: 'По требованию',
        auto: 'Автоматическая',
        daily: 'Ежедневная',
        weekly: 'Eженедельная',
        monthly: 'Ежемесячная'
    },
    errors: {
        statementsLoadingError: 'Ошибка при загрузке выписок',
        statementCreatingError: 'Ошибка при создании выписки',
        statementUpdatingError: 'Ошибка при обновлении выписки',
        statementHistoryLoadingError: 'Ошибка при загрузке истории выписок'
    },
    tooltips: {
        enabledStatus:
            'Конфигурация для автоматических выписок создана и активирована',
        disabledStatus:
            'Конфигурация для автоматических выписок создана но не активирована',
        noEmails: 'Получатели не указаны',
        downloadStatement: 'Скачать выписку',
        statementCreating: 'Создание выписки',
        reload: 'Обновить данные',
        statementEmpty: 'Нет данных',
        statementSettings: 'Настройка выписок'
    },
    pageInfo: (info: { from: number; to: number; total: number }) => (
        <>
            {info.from}-{info.to}
        </>
    ),
    generateButton: 'Сформировать',
    automaticStatements: 'Автоматические выписки',
    statementHistory: 'Сформированные выписки',
    statementsFor: 'Выписки',
    active: 'Активированы',
    inactive: 'Не активированы',
    statement: 'Выписка'
})
