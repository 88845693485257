import React, { useEffect, useRef } from 'react'
import { Row, Tag, Skeleton } from 'antd'
import newsImg from '~/assets/img/newsImg.png'
import styles from './styles.css'
import moment from 'moment'

const constantTage = {
    system: { color: '#1890FF', text: 'Системное', name: 'system' },
    promotion: { color: '#1C9771', text: 'Акция', name: 'promotion' },
    NEW: { color: '#FFC700', text: 'Новое', name: 'NEW' }
}
const FullCard = ({ isMobile, date, tags = [], image, title, text, period, link, loading = false }) => {
    const myRef = useRef(null)

    useEffect(() => {
        myRef.current.innerHTML = text
    }, [text])

    if (loading) {
        return (
            <Row className={styles.cardRow}>
                <Skeleton className={styles.skeleton} />
            </Row>
        )
    }
    return (
        <>
            <Row className={styles.cardHeader}>
                {
                    !isMobile &&
                    <>
                        <div className={styles.separator}></div>
                        <div className={styles.tagWrapper}>
                            <div className={styles.tagContainer}>
                                <span className={styles.date}>{moment(date).format('h:mm DD.MM.YYYY')}</span>
                                {
                                    tags.map(tag => tag &&
                                        <Tag className={styles.tag} color={constantTage[tag]?.color} key={`${constantTage[tag]?.name}+ ${link}`}>
                                            {constantTage[tag]?.text}
                                        </Tag>
                                    )
                                }
                            </div>
                        </div>
                    </>
                }
            </Row>
            <Row className={styles.cardRow}>
                <Row>
                    <div className={styles.title}>{title}</div>
                </Row>
                <img className={styles.cardImg} src={image ? image : newsImg} />
                <p className={styles.paragraph} ref={myRef}></p>
                <Row style={{ display: 'flex' }}>
                    <span className={styles.period}>{period}</span>
                </Row>
                {isMobile &&
                    <Row>
                        <div className={styles.date}>{moment(date).format('h:mm MM.DD.YYYY')}</div>
                    </Row>
                }
            </Row>
        </>

    )
}

export default FullCard