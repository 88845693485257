import res from './res'

res.add('ru', {
    title: 'Сотрудники',
    role: {
        admin: 'Администратор',
        manager: 'Менеджер',
        accountant: 'Бухгалтер',
        view: 'Просмотр'
    },
    status: {
        active: 'Активен',
        waitingForRegistration: 'Ожидает регистрации'
    },
    cols: {
        email: 'Email',
        role: 'Роль',
        status: 'Статус',
        lastConnections: 'Последняя активность',
        control: 'Управление',
        controlsTooltip: {
            edit: 'Редактировать',
            delete: 'Удалить'
        }
    },
    invite: 'Пригласить сотрудника',
    okText: 'Да',
    cancelText: 'Нет',
    deleteConfirmContent:
        'Данное действие удалит сотрудника из вашей команды. Они не смогут пользоваться функционалом этого портала.',
    deleteConfirmTitle: 'Вы действитльно хотите удалить данного сотрудника?',
    deleteNotificationDescription:
        'Данный сотрудник больше не сможет пользоваться этим порталом.',
    deleteNotificationTitle: 'Готово',
    emailExists: 'Сотрудник с данной электронной почтой уже существует.',
    emailExistsDescription:
        'Пожалуйста, введите другую электронную почту чтобы добавить нового сотрудника.',
    newEmployeeCreatedNotificationDescription: (email: string) =>
        `Приглашени успешно отправлено на ${email}.`,
    newEmployeeCreatedNotificationTitle: 'Отправлено',
    employeeRoleUpdatedDescription: 'Роль сотрудника была обновлена',
    employeeRoleUpdatedTitle: 'Готово'
})
