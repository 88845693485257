import res from './res'

res.add('ru', {
    slides: [
        {
            title: 'Добро пожаловать в ePay!',
            description: 'В личном кабинете вы управляете своим бизнесом онлайн.'
        },
        {
            title: 'Статистика бизнеса онлайн',
            description: 'В Статистике вы можете отслеживать: Успешные/Неуспешные транзакции, Банки-эмитеты, Каналы'
        },
        {
            title: 'Управление транзакциями',
            description: 'Детальный поиск транзакций, с помощью гибкой системы фильтров. Отслеживайте историю платежей и управляйте ими'
        },
        {
            title: 'Онлайн выписки',
            description: 'Вы можете настроить автоматическую рассылку выписки на почту.'
        },
        {
            title: 'Подключение сотрудников',
            description: 'Управляйте персоналом, выдавайте необходимый уровень доступа'
        },
        {
            title: 'И многое другое!',
            description: 'Мы делаем все возможное, чтобы упростить процесс и улучшить опыт вашего онлайн-бизнеса.'
        }
    ],
    skipButton: 'Пропустить',
    startButton: 'Начать',
    modal: {
        title: 'Обучение',
        description: 'Вы уверены, что хотите пропустить?',
        okButton: 'Да',
        cancelButton: 'Нет'
    }
})
