import res from './res'

res.add('kk', {
    dataFields: {
        full: {
            createdAt: 'Құрылған күні',
            statusName: 'Мәртебесі',
            emails: 'Алушылар',
            shopIds: 'Дүкендер',
            moderatorComment: 'Пікірлер орны',
            creditConditions: 'Несиелеу талаптары',
            checkingAccount: 'Есеп айырысу шоты'
        },
        short: {
            createdAt: 'Құрылған күні',
            statusName: 'Мәртебесі',
            emails: 'Алушылар',
            shopIds: 'Дүкендер',
            moderatorComment: 'Пікірлер орны',
            creditConditions: 'Талаптар',
            checkingAccount: 'Есеп айырысу шоты'
        }
    },
    creditOrderStatus: {
        NEW: 'Өтінім берілді',
        IN_PROGRESS: 'Өңделуде',
        MERCHANT_CONFIRMATION: 'Растау қажет',
        ACCEPT: 'Өтінім бекітілді',
        REJECT: 'Қабылданбады'
    },
    tooltips: {
        noValues: 'Деректер көрсетілмеген'
    },
    installment: 'Бөліп төлеу',
    credit: 'Несие',
    conditionLabel: (val: string) => {
        const value = parseInt(val, null)

        return `${value} ай`
    }
})
