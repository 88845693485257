import locale from '~/utils/locale'

export default locale.create<{
    title: string
    sortBy: string
    cols: {
        id: string
        name: string
        number: string
        price: string
        visibility: string
        active_date: string
        set_date: string
    }
    successUpdate: string
    errorLoadData: string
}>()