import { action, observable, runInAction } from 'mobx'
import { apiCheck } from '~/api'
import { getFaq, getSwagger, Question, Swagger } from '~/api/faq'
import { noThrow } from '~/utils/control-flow'
import message from '~/utils/message'
import res from './res'

export default class ForDevelopersStore {

    constructor() {
        this.loadData()
    }

    @observable
    public data: Swagger

    @observable
    public sendingQuestion: boolean = false

    @action.bound
    public async loadData() {
        const { value, error } = await noThrow(apiCheck(getSwagger()))

        if (value) {
            runInAction(() => this.data = value)
        }

    }
}
