import { action, computed, observable, runInAction } from 'mobx'

import appStore from '~/app-store'
import auth from '~/services/auth'
import { isPasswordExpired } from '~/services/auth/auth-utils'
import FieldStore, { isValidArr } from '~/utils/field-store'
import { userError } from '~/utils/user-error'
import { email, required } from '~/utils/validation'

import res from '../res'
import actions from '~/actions'

export default class LoginStore {
    public email = new FieldStore<string>([
        required(() => res().emailRequired),
        email
    ])

    public password = new FieldStore<string>([
        required(() => res().passwordRequired)
    ])

    @observable
    public isPasswordShown = false

    @observable
    public isSending = false

    @observable
    public error: Error

    @computed
    public get errorMessage(): string {
        return userError(this.error).message
    }

    @action
    public async login() {
        if (!isValidArr(this.email, this.password)) return

        this.isSending = true

        const result = await auth.login({
            username: this.email.value,
            password: this.password.value
        })

        if (!result.error) {
            await appStore.onLogin()
        }

        runInAction(() => {
            if (!result.error) return
            this.isSending = false
            this.error = result.error
            if (isPasswordExpired(result.error)) {
                actions.openExpiredPasswordRecovery()
            }
        })
    }

    @action
    public togglePasswordVisibility() {
        this.isPasswordShown = !this.isPasswordShown
    }
}
