import React from 'react'

const SvgBanksProducts = props => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" {...props}>
        <path
            d="M955.233 262.436L532.34 5.65a38.714 38.714 0 00-40.278 0L69.166 262.436C57.54 269.494 50 282.57 50 296.788V727.23c0 14.086 7.28 27.134 19.166 34.352l422.893 256.786c12.009 7.294 27.56 7.722 40.278 0L955.23 761.582c11.885-7.218 19.165-20.264 19.165-34.352V296.788c.002-14.224-7.537-27.294-19.163-34.352zM128.61 367.234l344.283 209.054v337.28L128.61 704.518V367.234zm383.59 139.76L166.016 296.788 512.199 86.582l346.185 210.206-346.185 210.206zm383.59 197.524L551.502 913.57V576.288l344.285-209.054v337.284z"
            fillRule="nonzero"
        />
    </svg>
)

export default SvgBanksProducts
