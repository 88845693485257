import { Button } from '~/components'
import React from 'react'
import { ButtonProps } from 'antd/es/button'

const ButtonDefault = (props: ButtonProps) => {
    return (
        <Button
            size="large"
            type="primary"
            {...props}
        >
            {props.children}
        </Button>
    )
}

export default ButtonDefault