import { observer } from 'mobx-react'
import { Component } from 'react'
import React from 'react'
import { IconButton, message, Spin } from '~/components'
import { action } from 'mobx'
import { CloseModalIcon } from '~/components/icon'
import { ModalContainerContext } from '~/components/modal/modal-context'
import layoutStyles from '~/layouts/main/styles.css'
import globalRes from '~/res'
import BrowserStore from '~/services/browser/browser-state'
import res from './res'
import styles from './styles.css'
import { CreditOrder, UpdateCreditOrder } from '~/api/products'
import ConfirmationPage from './ConfirmationPage'
import RejectOrderForm from '~/pages/products/credit/components/reject-modal-config/OrderRejectForm'

export interface OrderRejectBody {
    id: string,
    createdAt?: string,
    shopIds: any[],
    emails: any[],
    statusId?: string,
    statusName?: string,
    moderatorComment: string,
    accountVerified?: boolean,
    verifiedBy?: string,
    number?: string,
    parentId?: string,
    checkingAccount?: string,
    shops?: any[],
    creditConditions?: any[],
    merchantName: string
}

interface OrderRejectProps {
    updateOrder: CreditOrder
    statusId: string
    onClose: () => void
    onApply: (requestBody: OrderRejectBody) => Promise<UpdateCreditOrder>
    modalContext?: ModalContainerContext
    orderUpdating?: boolean
}

interface OrderRejectState {
    order?: UpdateCreditOrder,
    statusId: string,
    updatedCreditOrder: UpdateCreditOrder | null
    isUpdatedOrder: boolean
    hasError: boolean,
    formValid: boolean
    errorMessage: string
}


@observer
export default class RejectForm extends Component<
OrderRejectProps,
OrderRejectState
> {
    constructor(props: OrderRejectProps) {
        super(props)
        this.state = {
            updatedCreditOrder: null,
            formValid: false,
            order: this.props.updateOrder,
            isUpdatedOrder: false,
            hasError: false,
            errorMessage: '',
            statusId: this.props.statusId
        }
    }

    @action
    public async componentDidMount() {
        try {
            if (this.props.modalContext) {
                const { onClose } = this.props
                this.props.modalContext.modalController.setTitle(
                    <div className={styles.mobileHeader}>
                        <div className={layoutStyles.title}>
                            {res().title}
                        </div>
                        <IconButton
                            className={`${styles.closeButton}`}
                            icon={CloseModalIcon}
                            onClick={onClose}
                            id="close-statement-config-icon"
                            tooltipTitle={globalRes().close}
                        />
                    </div>
                )
            }
        } catch (error) {
            message.error(error)
        }
    }

    public render() {
        const { onClose, orderUpdating } = this.props
        const { updatedCreditOrder } = this.state

        return (
            <>
                <Spin spinning={orderUpdating}>
                    {!BrowserStore.isMobile && (
                        <div data-semantic-id="modal">
                            {' '}
                            {this.state.isUpdatedOrder ? (
                                <ConfirmationPage
                                    onClose={onClose}
                                    updatedCreditOrder={updatedCreditOrder}
                                />
                            ) : (
                                <div>
                                    <div>
                                        <div className={layoutStyles.contentHeader}>
                                            <div className={layoutStyles.title}>
                                                {res().title}
                                            </div>
                                            <div className={layoutStyles.subtitle}>
                                                {res().description}
                                            </div>
                                            <IconButton
                                                className={`${layoutStyles.closeButton} ${styles.closeButton}`}
                                                icon={CloseModalIcon}
                                                onClick={onClose}
                                                id="close-statement-config-icon"
                                                tooltipTitle={globalRes().close}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <RejectOrderForm
                                            creditOrder={this.state.order}
                                            statusId={this.state.statusId}
                                            onApply={this.props.onApply}
                                            onClose={onClose}
                                            showConfirmation={this.showConfirmation}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Spin>
            </>
        )
    }

    @action.bound
    public async showConfirmation(updatedOrder: CreditOrder) {
        this.setState({
            isUpdatedOrder: true,
            updatedCreditOrder: updatedOrder
        })
    }
}
