import { action, computed, observable, runInAction } from 'mobx'
import React from 'react'
import { getDesktopSections, HintType, MEDIA_SCREEN_BREAKPOINT, Section } from './constants'
import BrowserStore from '~/services/browser/browser-state'
import InteractiveHint from '~/utils/interactive-hint'

class InteractiveHintsStore {

    public static manuallyCall(sectionKeys: string[]) {
        const store = new InteractiveHintsStore(sectionKeys)
        store.startShowing()
        return store
    }

    public static immediatelyCall() {
        const store = new InteractiveHintsStore(InteractiveHint.activeFlags)

        if (InteractiveHint.showFullTraining) {
            store.showWelcomeModal()
        } else {
            if (InteractiveHint.activeFlags.length > 0) {
                if (InteractiveHint.activeFlags.length === 1 && InteractiveHint.activeFlags[0] === 'transactions' && !InteractiveHint.flags.firstTransactionModal) {
                    store.setSections([])
                } else {
                    store.showContinueModal()
                }
            }
        }
        return store
    }

    public static afterFirstTransactionCall() {
        const store = new InteractiveHintsStore(['transactions'])
        store.showFirstSuccessTransactionModal()
        return store
    }

    constructor(sectionKeys: string[]) {
        this.sectionKeys = sectionKeys
        this.sections = getDesktopSections(this, sectionKeys)
    }

    @observable
    public isShowMode: boolean = false

    @observable
    public currentSectionIndex: number = 0

    @observable
    public currentStepIndex: number = 0

    @observable
    public isVisibleSkipAllModal: boolean = false

    @observable
    public isVisibleSkipSectionModal: boolean = false

    @observable
    public isVisibleCongratulationModal: boolean = false

    @observable
    public isVisibleTransactionsWarningModal: boolean = false

    @observable
    public isVisibleWelcomeModal: boolean = false

    @observable
    public isVisibleContinueModal: boolean = false

    @observable
    public isVisibleFirstSuccessTransactionModal: boolean = false

    @observable
    public isStepChanging: boolean = false

    @observable
    public sections: Section[] = []

    @computed
    public get continueFromSection(): string {
        return this.sectionKeys[0]
    }

    @computed
    public get isManySections() {
        return this.sections.length > 1
    }

    @action
    public async startShowing() {
        await this.sections[0].goToPage()
        if (!this.isAnyModalOpened) {
            this.updateSectionFlag()
            await this.changeStep(0, 0, 200)
        }
    }

    @action
    public goNextStep() {
        const nextStepIndex = this.currentStepIndex + 1
        if (nextStepIndex < this.currentSection.steps.length) {
            this.changeStep(nextStepIndex)
        } else {
            // this.goNextSection()
            this.goToNextStepOfNewSection()
        }
    }

    @action
    public goPrevStep() {
        const prevStepIndex = this.currentStepIndex - 1
        if (prevStepIndex >= 0) {
            this.changeStep(prevStepIndex)
        }
    }

    @action.bound
    public async goNextSection() {
        const nextSectionIndex = this.currentSectionIndex + 1
        if (nextSectionIndex < this.sections.length && this.isManySections) {
            runInAction(() => {
                this.isShowMode = false
            })
            await this.sections[nextSectionIndex].goToPage()
            await this.changeStep(0, nextSectionIndex)

            if (this.isAnyModalOpened) {
                return await this.setShowMode(false)
            }
            this.updateSectionFlag()
            runInAction(() => {
                this.isShowMode = true
            })
        } else {
            await this.showCongratulationModal()
        }
    }

    @action.bound
    public async goPrevSection() {
        const prevSectionIndex = this.currentSectionIndex - 1
        if (prevSectionIndex >= 0 && this.isManySections) {
            runInAction(() => {
                this.isShowMode = false
            })
            await this.changeStep(0, prevSectionIndex)
            await this.sections[prevSectionIndex].goToPage()
            if (this.isAnyModalOpened) {
                return await this.setShowMode(false)
            }
            runInAction(() => {
                this.isShowMode = true
            })
        }
    }

    @computed
    public get isFirstSection() {
        return this.currentSectionIndex === 0
    }

    @computed
    public get isLastSection() {
        return this.currentSectionIndex === this.sections.length - 1
    }

    @action
    public showSkipAllModal() {
        this.pauseStep(true).then(() => {
            runInAction(() => {
                this.isVisibleSkipAllModal = true
            })
        })
    }

    @action.bound
    public async cancelSkipAllModal() {
        this.isVisibleSkipAllModal = false
        await this.wait(100).then(() => {
            this.pauseStep(false)
        })
    }

    @action
    public showSkipSectionModal() {
        this.pauseStep(true).then(() => {
            runInAction(() => {
                this.isVisibleSkipSectionModal = true
            })
        })
    }

    @action.bound
    public async cancelSkipSectionModal() {
        this.hideSkipSectionModal()
        await this.wait(100).then(() => {
            this.pauseStep(false)
        })
    }

    @action.bound
    public hideSkipSectionModal() {
        this.isVisibleSkipSectionModal = false
    }

    @action
    public async showCongratulationModal() {
        await this.setShowMode(false)
        runInAction(() => {
            this.isVisibleCongratulationModal = true
        })
    }

    @action.bound
    public hideCongratulationModal() {
        this.isVisibleCongratulationModal = false
    }

    @action
    public showTransactionsWarningModal() {
        this.isVisibleTransactionsWarningModal = true
        this.updateSectionFlag('firstTransactionModal')
    }

    @action
    public hideTransactionsWarningModal() {
        this.isVisibleTransactionsWarningModal = false
    }

    @action
    public showWelcomeModal() {
        this.isVisibleWelcomeModal = true
    }

    @action.bound
    public cancelWelcomeModal() {
        this.isVisibleWelcomeModal = false
    }

    @action
    public showContinueModal() {
        this.isVisibleContinueModal = true
    }

    @action.bound
    public cancelContinueModal() {
        this.isVisibleContinueModal = false
    }

    @action
    public showFirstSuccessTransactionModal() {
        this.isVisibleFirstSuccessTransactionModal = true
    }

    @action.bound
    public hideFirstSuccessTransactionModal() {
        this.isVisibleFirstSuccessTransactionModal = false
    }

    @action.bound
    public finish() {
        this.setShowMode(false)
        this.isVisibleSkipAllModal = false
        this.isVisibleSkipSectionModal = false
        this.isVisibleCongratulationModal = false
        this.isVisibleTransactionsWarningModal = false
        this.isVisibleContinueModal = false
        this.isVisibleWelcomeModal = false
    }

    @action
    public async updateSectionFlag(sectionKey?: string) {
        const key = sectionKey || this.sectionKeys[this.currentSectionIndex]
        if (InteractiveHint.flags && InteractiveHint.flags[key]) {
            const keys = [key]
            // TODO исправить этот блок в будущем
            if (key === 'statements') {
                keys.push('statementPage')
            }
            await InteractiveHint.switchFlagsOff(keys)
        }
    }

    @action
    public setSections(sections) {
        this.sections = sections
    }

    @computed
    public get currentStep() {
        return this.sections[this.currentSectionIndex].steps[this.currentStepIndex]
    }

    @computed
    public get currentSection() {
        return this.sections[this.currentSectionIndex]
    }

    @computed
    public get isCurrentStepTypeModal() {
        return this.currentStep.type === HintType.modal
    }

    @computed
    public get isCurrentStepTypeSiderOnTablet() {
        return this.currentStep.type === HintType.sider
            && BrowserStore.windowWidth <= MEDIA_SCREEN_BREAKPOINT
    }

    @computed
    public get isSectionFirstStep() {
        return this.currentStepIndex === 0
    }

    @action.bound
    private async goToNextStepOfNewSection() {
        if (!this.isLastSection) {
            this.isStepChanging = true
            await this.sections[this.currentSectionIndex].steps[this.currentStepIndex].onAfterHide?.()

            await this.sections[this.currentSectionIndex + 1].goToPage()
            await this.sections[this.currentSectionIndex + 1].steps[0].onBeforeShow?.()
            runInAction(() => {
                this.currentSectionIndex = this.currentSectionIndex + 1
                this.currentStepIndex = 0
            })

            await this.wait(200)
            runInAction(() => {
                this.isStepChanging = false
            })
            if (this.isAnyModalOpened) {
                return await this.setShowMode(false)
            }
            this.updateSectionFlag()
        } else {
            await this.showCongratulationModal()
        }
    }

    @computed
    private get isAnyModalOpened() {
        return this.isVisibleTransactionsWarningModal
            || this.isVisibleCongratulationModal
            || this.isVisibleSkipSectionModal
            || this.isVisibleSkipAllModal
            || this.isVisibleWelcomeModal
            || this.isVisibleContinueModal
            || this.isVisibleFirstSuccessTransactionModal
    }

    @action
    private async setShowMode(mode: boolean) {
        if (mode) {
            await this.sections[this.currentSectionIndex].steps[this.currentStepIndex].onBeforeShow?.()
            runInAction(() => {
                this.isShowMode = mode
            })
        } else {
            runInAction(() => {
                this.isShowMode = mode
            })
            await this.sections[this.currentSectionIndex].steps[this.currentStepIndex].onAfterHide?.()
        }
    }

    @action
    private async changeStep(newStep: number, newSection = this.currentSectionIndex, delay = 0) {
        this.isStepChanging = true
        if (this.isShowMode) { // Call onAfterHide only when hint is visible
            await this.sections[this.currentSectionIndex]?.steps[this.currentStepIndex]?.onAfterHide?.()
        }
        await this.sections[newSection].steps[newStep].onBeforeShow?.()
        runInAction(() => {
            this.currentSectionIndex = newSection
            this.currentStepIndex = newStep
            const isVeryFirstStep = newSection === 0 && newStep === 0
            if (isVeryFirstStep) {
                runInAction(() => {
                    this.setShowMode(true)
                })
            }
        })

        setTimeout(() => {
            runInAction(() => {
                this.isStepChanging = false
            })
        }, delay + 200)
    }

    @action
    private async pauseStep(isPauseOn = true) {
        this.isStepChanging = true

        if (isPauseOn) {
            runInAction(() => {
                this.isShowMode = false
                this.isStepChanging = false
            })
            await this.sections[this.currentSectionIndex].steps[this.currentStepIndex].onAfterHide?.()
        } else {
            await this.sections[this.currentSectionIndex].steps[this.currentStepIndex].onBeforeShow?.()
            runInAction(() => {
                this.isShowMode = true
            })
            await this.wait(200).then(() => {
                runInAction(() => {
                    this.isStepChanging = false
                })
            })
        }
    }

    private wait(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    private isCalledFromFAQ: boolean = false

    private sectionKeys: string[] = []
}

export default InteractiveHintsStore