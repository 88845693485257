import moment from 'moment'

import { reaction } from 'mobx'

import locale from '~/utils/locale'

export { default } from './res'

import './res.en'
import './res.kk'
import './res.ru'

reaction(() => locale.lang, () => moment.locale(locale.lang), { fireImmediately: true })
