import React from 'react'

const SvgDone = props => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" {...props}>
        <g fillRule="evenodd">
            <path d="M512 1024C229.23 1024 0 794.77 0 512S229.23 0 512 0s512 229.23 512 512-229.23 512-512 512zm0-62c251.29 0 455-201.472 455-450S763.29 62 512 62 57 263.472 57 512s203.71 450 455 450z" />
            <g transform="translate(207 300)">
                <rect
                    transform="rotate(42 415.302 212.14)"
                    x={384.802}
                    y={-45.86}
                    width={61}
                    height={516}
                    rx={30.5}
                />
                <rect
                    transform="rotate(-45 149.907 273.907)"
                    x={118.907}
                    y={92.907}
                    width={62}
                    height={362}
                    rx={31}
                />
            </g>
        </g>
    </svg>
)

export default SvgDone
