export default {
    openLogin: undefined as () => void,
    openHome: undefined as () => void,
    openPayments: undefined as () => void,
    openProfile: undefined as () => void,
    getBackAction: undefined as () => () => void,
    openStatementPage: undefined as (statementId) => void,
    openStatements: undefined as () => void,
    openStatementTransferPage: undefined as (statementId) => void,
    openStatementsTransfer: undefined as () => void,
    openStatementOctPage: undefined as (statementId) => void,
    openStatementsOct: undefined as () => void,
    openPaymentLinks: undefined as () => void,
    openProducts: undefined as () => void,
    openProductInquiry: undefined as (inquiryId) => void,
    openProductsCredit: undefined as () => void,
    openSettingsTemplate: undefined as () => void,
    openSettingsTerminal: undefined as () => void,
    openSettingsEmployees: undefined as () => void,
    openDocumentation: undefined as () => void,
    openDocumentationPage: undefined as (nodeName) => void,
    openExpiredPasswordRecovery: undefined as () => void,
    openOrderCreate: undefined as () => void,
    openCurrentOrder: undefined as () => void,
    openMobileWelcomePage: undefined as () => void,
    openNews: undefined as () => void,
    openNewsSingle: undefined as (newsId: string) => void,
    openPaymentSettings: undefined as () => void
}
