import res from './res'

res.add('en', {
    title: 'Filter',
    cardsNumberStartsWith: 'First 6 digits',
    cardsNumberEndsWith: 'Last 4',
    endsWith: 'ends with',
    applyButton: 'Apply',
    cancelButton: 'Cancel',
    clearButton: 'Clear filter',
    periodTitle: 'Period',
    postLinkStatusOptions: {
        all: 'All',
        ok: 'OK',
        fail: 'Failed'
    }
})
