import EpayTemplateStore from '~/pages/settings/configure-epay-template/epay-template-store'
import React, { RefObject } from 'react'
import { action, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import Page from '~/layouts/main/page'
import res from './res'
import { Form, Input, notification, Upload } from 'antd'
import { Button, FormItem, InputItem, Radio, Select } from '~/components'
import styles from './styles.css'
import locale from '~/utils/locale'
import { languages } from '~/constants/laguages'
import { NameMap } from './colors'
import { ColorSchemeName } from '~/api/epay-template'
import { UploadOutlined } from '@ant-design/icons'
import { RcFile } from 'antd/lib/upload'
import { isValidArr } from '~/utils/field-store'
import confirm from 'antd/lib/modal/confirm'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import 'rc-color-picker/assets/index.css'
import FormPresentation from './components/FormPresentation'
import CustomColorPicker from './components/CustomColorPicker'
import BrowserStore from '~/services/browser/browser-state'
import InteractiveMobileHints from '~/components/interactive-mobile-hints'

@observer
export default class EpayTemplate extends React.Component<
{ store: EpayTemplateStore },
{}
> {
    constructor(props, ctx) {
        super(props, ctx)

        this.imageRef = React.createRef<HTMLImageElement>()
        this.uploadRef = React.createRef<Upload>()

        this.customBg = React.createRef<Input>()
        this.customCardBg = React.createRef<Input>()
        this.customBtnColor = React.createRef<Input>()
        this.customTxtColor = React.createRef<Input>()
        this.customCardTxtColor = React.createRef<Input>()

        this.state = {}
    }

    // public handleColorChange = () => {}

    public render() {
        const { store } = this.props

        const cs = store.assets.color_scheme

        return (
            <>
                <Page
                    // pageClassName={styles.page}
                    title={
                        <div>
                            {res().title}
                            <Select value={store.currentShopId} className={styles.titleSelect} onChange={this.onShopChange}>
                                {store.shops.map(shop => <Select.Option key={shop.id} value={shop.id}>{shop.name}</Select.Option>)}
                            </Select>
                        </div>
                    }
                >
                    <div className={styles.container}>
                        <div
                            className={styles.settingsForm}
                            style={{ display: store.isError ? 'none' : '' }}
                        >
                            <Form
                                style={{
                                    paddingRight: BrowserStore.isMobile ? '0' : '10%',
                                    minWidth: 'unset'
                                }}
                            >
                                <InputItem
                                    trigger={'onChange'}
                                    label={res().labels.shop_name}
                                    store={store.shopName}
                                    placeholder={
                                        res().labels.shop_name_placeholder
                                    }
                                    itemProps={{
                                        className: styles.rowItem
                                    }}
                                    inputProps={{
                                        name: 'shop_name',
                                        value: store.shopName.value,
                                        maxLength: 50
                                    }}
                                />
                                <FormItem
                                    label={res().labels.logo}
                                    className={styles.rowItem}
                                >
                                    <Upload
                                        ref={this.uploadRef}
                                        multiple={false}
                                        onRemove={_ => {
                                            this.imageRef.current.src = undefined
                                            runInAction(() => {
                                                this.imageRef.current.src =
                                                    store.assets.logo_url
                                                store.assets.logo = undefined
                                                store.assets.logo_file_name = undefined
                                            })
                                            return true
                                        }}
                                        beforeUpload={(
                                            file: RcFile,
                                            fileList
                                        ) => {
                                            if (
                                                file.size > 1024 * 1024 ||
                                                (!file.name.endsWith('.jpg') &&
                                                    !file.name.endsWith(
                                                        '.jpeg'
                                                    ) &&
                                                    !file.name.endsWith(
                                                        '.png'
                                                    ) &&
                                                    !file.name.endsWith(
                                                        '.gif'
                                                    ) &&
                                                    !file.name.endsWith('.svg'))
                                            ) {
                                                notification.error({
                                                    message: res().messages
                                                        .errorTitle,
                                                    description: res().messages
                                                        .invalidFile,
                                                    duration: 0
                                                })
                                                while (fileList.length > 0) {
                                                    fileList.pop()
                                                }
                                                return false
                                            }

                                            const reader = new FileReader()
                                            reader.readAsDataURL(file)
                                            reader.onloadend = () => {
                                                const url = reader.result.toString()
                                                this.imageRef.current.src = url
                                                runInAction(() => {
                                                    // store.assets.logo_url = url
                                                    store.assets.logo_file_name =
                                                        file.name
                                                    store.assets.logo = url.split(
                                                        ','
                                                    )[1]
                                                    store.isModified = true
                                                })
                                            }
                                            return false
                                        }}
                                    >
                                        <div>
                                            <UploadOutlined />
                                            <label>
                                                &nbsp;{res().labels.load}
                                            </label>
                                        </div>
                                        <div>
                                            <span
                                                className={styles.cardFormLabel}
                                            >
                                                {res().labels.fileDescription}
                                            </span>
                                        </div>
                                    </Upload>
                                </FormItem>
                                {store.lang.value &&
                                    <FormItem
                                        label={res().labels.lang}
                                        className={styles.rowItem}
                                    >
                                        <Select
                                            defaultValue={store.lang.value}
                                            onChange={lang => store.lang.set(lang)}
                                        >
                                            <Select.Option value="en">
                                                {languages.en}
                                            </Select.Option>
                                            <Select.Option value="kk">
                                                {languages.kk}
                                            </Select.Option>
                                            <Select.Option value="ru">
                                                {languages.ru}
                                            </Select.Option>
                                        </Select>
                                    </FormItem>
                                }


                                <InputItem
                                    trigger={'onChange'}
                                    label={res().labels.email}
                                    store={store.email}
                                    placeholder={res().labels.email_placeholder}
                                    itemProps={{
                                        className: styles.rowItem
                                    }}
                                    inputProps={{
                                        name: 'email',
                                        value: store.email.value,
                                        maxLength: 50
                                    }}
                                />

                                <FormItem
                                    label={res().labels.phone}
                                    className={
                                        styles.rowItem +
                                        ' ' +
                                        (store.phone.error ? 'has-error' : '')
                                    }
                                >
                                    <div className={styles.phoneInputWrapper}>
                                        <PhoneInput
                                            onBlur={_ => store.phone.validate()}
                                            placeholder={'+7 (xxx) xxx-xx-xx'}
                                            specialLabel={null}
                                            value={store.phone.value}
                                            priority={{ kz: 0, ru: 1 }}
                                            onChange={phone =>
                                                store.phone.set(phone)
                                            }
                                        />
                                    </div>
                                    <div
                                        hidden={!store.phone.error}
                                        className={'ant-form-explain'}
                                    >
                                        {res().messages.invalidPhone}
                                    </div>
                                </FormItem>

                                <FormItem
                                    label={res().labels.colorScheme}
                                    className={
                                        styles.rowItem +
                                        ' ' +
                                        styles.stdColorsRow
                                    }
                                >
                                    <div>
                                        {this.renderColorSelector(
                                            'white-gray',
                                            styles.colorSelectorWhiteGray
                                        )}
                                        {this.renderColorSelector(
                                            'black-gray',
                                            styles.colorSelectorBlackGray
                                        )}
                                        {this.renderColorSelector(
                                            'black-white',
                                            styles.colorSelectorBlackWhite
                                        )}
                                        {this.renderColorSelector(
                                            'white-black',
                                            styles.colorSelectorWhiteBlack
                                        )}
                                        {this.renderColorSelector(
                                            'white-blue',
                                            styles.colorSelectorWhiteBlue
                                        )}
                                        {this.renderColorSelector(
                                            'white-green',
                                            styles.colorSelectorWhiteGreen
                                        )}
                                        {this.renderColorSelector(
                                            'white-gold',
                                            styles.colorSelectorWhiteGold
                                        )}
                                        {this.renderColorSelector(
                                            'blue-white',
                                            styles.colorSelectorBlueWhite
                                        )}
                                        {this.renderColorSelector(
                                            'green-white',
                                            styles.colorSelectorGreenWhite
                                        )}
                                        {this.renderColorSelector(
                                            'gold-white',
                                            styles.colorSelectorGoldWhite
                                        )}
                                        {this.renderColorSelector(
                                            'red-white',
                                            styles.colorSelectorRedWhite
                                        )}
                                    </div>
                                </FormItem>

                                <div className={styles.customColorContainer}>
                                    <div
                                        className={styles.rowItem}
                                        style={{ alignItems: 'center' }}
                                    >
                                        <div
                                            style={{
                                                padding: '10px 0 10px 20%',
                                                color: '#00A8FF'
                                            }}
                                        >
                                            <Radio
                                                checked={
                                                    store.isColorSectionExpanded
                                                }
                                                onClick={() =>
                                                    this.setColors('custom')
                                                }
                                            />
                                            <label
                                                onClick={() =>
                                                    this.setColors('custom')
                                                }
                                            >
                                                {res().labels.custom}
                                            </label>
                                        </div>
                                    </div>
                                    <div
                                        className={styles.rowItem}
                                        style={{
                                            display: store.isColorSectionExpanded
                                                ? ''
                                                : 'none',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div style={{ textAlign: 'right' }}>
                                            <label>{res().labels.bg}</label>
                                            &nbsp;
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <CustomColorPicker
                                                currentColor={store.assets.color_scheme.bg?.color1}
                                                onChange={color => store.updateCustomBg(color, store.assets.color_scheme.bg?.color2)}
                                            />
                                            <div style={{ paddingLeft: '7px' }} />
                                            <CustomColorPicker
                                                currentColor={store.assets.color_scheme.bg?.color2}
                                                onChange={color => store.updateCustomBg(store.assets.color_scheme.bg?.color1, color)}
                                            />
                                            <div style={{ paddingLeft: '5px' }}>
                                                <span className={styles.cardFormLabel}>{res().labels.gradientDescription}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={styles.rowItem}
                                        style={{
                                            display: store.isColorSectionExpanded
                                                ? ''
                                                : 'none',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div style={{ textAlign: 'right' }}>
                                            <label>{res().labels.card_bg}</label>
                                            &nbsp;
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <CustomColorPicker
                                                currentColor={store.assets.color_scheme.card_bg?.color1}
                                                onChange={color => store.updateCustomCardBg(color, store.assets.color_scheme.card_bg?.color2)}
                                            />
                                            <div style={{ paddingLeft: '7px' }} />
                                            <CustomColorPicker
                                                currentColor={store.assets.color_scheme.card_bg?.color2}
                                                onChange={color => store.updateCustomCardBg(store.assets.color_scheme.card_bg?.color1, color)}
                                            />
                                            <div style={{ paddingLeft: '5px' }}>
                                                <span className={styles.cardFormLabel}>{res().labels.gradientDescription}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={styles.rowItem}
                                        style={{
                                            display: store.isColorSectionExpanded
                                                ? ''
                                                : 'none',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div style={{ textAlign: 'right' }}>
                                            <label>
                                                {res().labels.btn_color}
                                            </label>
                                            &nbsp;
                                        </div>
                                        <div>
                                            <CustomColorPicker
                                                currentColor={store.assets.color_scheme.buttons}
                                                onChange={color => (store.assets.color_scheme.buttons = color) && (store.isModified = true)}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={styles.rowItem}
                                        style={{
                                            display: store.isColorSectionExpanded
                                                ? ''
                                                : 'none',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div style={{ textAlign: 'right' }}>
                                            <label>
                                                {res().labels.txt_color}
                                            </label>
                                            &nbsp;
                                        </div>
                                        <div>
                                            <CustomColorPicker
                                                currentColor={store.assets.color_scheme.text}
                                                onChange={color => (store.assets.color_scheme.text = color) && (store.isModified = true)}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={styles.rowItem}
                                        style={{
                                            display: store.isColorSectionExpanded
                                                ? ''
                                                : 'none',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div style={{ textAlign: 'right' }}>
                                            <label>
                                                {res().labels.card_txt_color}
                                            </label>
                                            &nbsp;
                                        </div>
                                        <div>
                                            <CustomColorPicker
                                                currentColor={store.assets.color_scheme.card_text}
                                                onChange={color => (store.assets.color_scheme.card_text = color) && (store.isModified = true)}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={styles.rowItem}
                                        style={{
                                            display: store.isColorSectionExpanded
                                                ? ''
                                                : 'none'
                                        }}
                                    >
                                        <div
                                            style={{
                                                paddingLeft: '20%',
                                                paddingTop: '10px',
                                                paddingBottom: '15px'
                                            }}
                                        >
                                            <Button
                                                size={'small'}
                                                icon={'close'}
                                                onClick={this.resetCustomColors}
                                            >
                                                {res().labels.reset}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>

                        <FormPresentation
                            cs={cs}
                            store={store}
                            imageRef={this.imageRef}
                        />

                        <div className={styles.ctaContainer}>
                            <Button
                                size="large"
                                type={'primary'}
                                disabled={!store.isModified}
                                onClick={this.updateData}
                            >
                                {res().labels.save}
                            </Button>
                        </div>
                    </div>
                    {!store.isLoading && store.mobileHintStore &&
                        <InteractiveMobileHints store={store.mobileHintStore} />
                    }
                </Page>
            </>
        )
    }

    private renderColorSelector(
        scheme: ColorSchemeName,
        style: string
    ): JSX.Element {
        const { store } = this.props

        const cs = store.assets.color_scheme

        return (
            <>
                <div
                    onClick={_ => this.setColors(scheme)}
                    className={
                        styles.colorSelector +
                        ' ' +
                        style +
                        ' ' +
                        (cs.scheme_name === scheme
                            ? styles.colorSelectorActive
                            : '')
                    }
                />
            </>
        )
    }

    @action.bound
    private setColors(colorScheme: ColorSchemeName) {
        const { store } = this.props

        if (colorScheme === 'custom') {
            store.assets.color_scheme = {
                ...store.assets.color_scheme,
                scheme_name: 'custom'
            }
        } else {
            store.assets.color_scheme = NameMap[colorScheme]
        }

        store.isModified = true
    }

    @action.bound
    private async updateData() {
        const { store } = this.props

        if (!isValidArr(store.shopName, store.email, store.phone)) {
            return
        }

        store.assets.shop_name = store.shopName.value
        store.assets.lang = store.lang.value
        store.assets.email = store.email.value
        store.assets.phone = store.phone.value

        await store.updateData()
    }

    @action.bound
    private resetCustomColors() {
        const { store } = this.props

        store.assets.color_scheme = NameMap.custom
        store.isModified = true
    }

    @action.bound
    private onShopChange(shopId) {
        const { store } = this.props

        if (store.isModified) {
            confirm({
                title: res().confirmTitle,
                content: res().messages.unsavedChanges,
                okCancel: true,
                onOk: _ => store.loadAssets(shopId)
            })

            return
        }

        runInAction(() => {
            store.loadAssets(shopId)
        })
    }

    private readonly imageRef: RefObject<HTMLImageElement>
    private readonly uploadRef: RefObject<Upload>

    private customBg: RefObject<Input>
    private customCardBg: RefObject<Input>
    private customBtnColor: RefObject<Input>
    private customTxtColor: RefObject<Input>
    private customCardTxtColor: RefObject<Input>
}
