import React from 'react'

import { observer } from 'mobx-react'

import { FormItemProps } from 'antd/lib/form'
import { InputProps } from 'antd/lib/input'

import { FieldInput, Form } from '~/components'

import FieldStore from '~/utils/field-store'

interface InputItemProps {
    store: FieldStore<string>
    type?: string
    label?: string
    placeholder?: string
    popover?: {
        title?: string | JSX.Element
        content: string | JSX.Element
        className?: string
    }
    trigger?: 'onChange' | 'onBlur' | 'onKeyUp'
    itemProps?: FormItemProps
    inputProps?: InputProps
    help?: string
    isRequired?: boolean
    isNumeric?: boolean
    suffix?: any
}

const InputItem = observer((props: InputItemProps) => {
    const { popover, store, isNumeric, trigger, suffix } = props
    const { value } = store

    return (
        <Form.Item
            label={
                props.label ? (
                    <span
                        className={
                            props.isRequired ? 'ant-form-item-required' : ''
                        }
                    >
                        {props.label}
                    </span>
                ) : null
            }
            validateStatus={
                store.error === undefined
                    ? undefined
                    : !!value && !store.error
                        ? 'success'
                        : 'error'
            }
            help={store.error || props.help}
            {...props.itemProps}
        >
            <div>
                <FieldInput
                    type={props.type}
                    placeholder={props.placeholder}
                    store={store}
                    trigger={trigger}
                    popover={popover}
                    isNumeric={isNumeric}
                    suffix={suffix}
                    {...props.inputProps}
                />
            </div>
        </Form.Item>
    )
})

export default InputItem
