import 'moment/locale/ru'
import React from 'react'
import res from './res'
import instructionFile from '~/assets/files/instruction.pdf'

res.add('ru', {
    faqTitle: 'FAQ',
    instructionMerchantAccount: 'Инструкция по управлению личным кабинетом',
    searchPlaceholder: 'Поиск…',
    download: 'Скачать',
    messages: {
        required: 'Обязательно'
    },
    hintsModal: {
        title: 'Интерактивное обучение',
        question: 'Выберите раздел, по которому у Вас возникают вопросы или пройдите полное обучение.',
        all: 'Полное обучение',
        common: 'Общая информация',
        statistics: 'Статистика',
        transactions: 'Транзакции',
        terminals: 'ePay платежи',
        statements: 'Выписки',
        link: 'Ссылки на оплату',
        banksProducts: 'Продукты банка',
        credit: 'Рассрочка и кредитование',
        settings: 'Настройки',
        template: 'Шаблон ePay',
        terminalSettings: 'Настройки терминалов',
        team: 'Сотрудники',
        cancel: 'Отменить',
        start: 'Начать'
    },
    instructionText: (handleClick) => <>
        Для более подробного ознакомления с работой в кабинете Вы можете <a href={instructionFile} download>скачать</a> инструкцию
        {handleClick ? <> или <a onClick={handleClick}>запустить интерактивное обучение</a></> : null}
    </>
})
