import React from 'react'

import { action } from 'mobx'

import { observer } from 'mobx-react'

import res from '../../../res'

import StatisticsLineChart from '../../statistics-timeline-chart'

import BaseDashboardChart, { BaseDashboardChartProps } from '../base-chart'
import { TooltipContent } from '../tooltips/unsuccessful-transactions-chart-tooltip'

interface UnsuccessfulTransactionsChartProps extends BaseDashboardChartProps {
    data: any
}

@observer
export default class UnsuccessfulTransactionsChart extends BaseDashboardChart<
UnsuccessfulTransactionsChartProps
> {
    @action.bound
    protected initData() {
        this.fieldsConfig = [
            {
                field: 'count',
                title: 'count',
                visible: true,
                color: '#F42B74',
                dataKey: 'totalCount'
            }
        ]
    }

    protected renderContent = () => {
        if (this.props.data.length === 0) {
            return null
        }

        return (
            <StatisticsLineChart
                data={this.props.data}
                chartTooltip={TooltipContent}
            />
        )
    }

    protected data: Array<{}>

    protected fieldsSettingsSubtitle = res().charts.unsuccessfulTransactions
        .fieldsSettings

    protected configurable: boolean = false
}
