import React from 'react'

import { action, observable } from 'mobx'

import { observer } from 'mobx-react'

import { Button, IconButton, Modal, Radio } from '../index'

import layoutStyles from '~/layouts/main/styles.css'

import styles from './styles.css'

import radioStyles from '~/components/fields-config-form/styles.css'

import mainRes from '~/layouts/main/res'

import res from './res'

import { Icon } from 'antd'

import BrowserStore from '~/services/browser/browser-state'

import EditableFieldsList, { ItemField } from '../editable-fields-list'

import { ArrowLeftIcon, CheckIcon, CloseModalIcon } from '../icon'

import DateSelect from '~/components/date-selector/date-select'

import ModalContainer from '../modal/modal-container'

import { find, isEmpty, isEqual, isFunction, isNil } from 'lodash'

import { RangePopoverValue } from '~/components/range-popover'

import { RadioChangeEvent } from 'antd/lib/radio/interface'

import MobileHeader from '~/components/modal/mobile-header'

import { Range as DateRange } from '~/components/range-popover/range-popover'

interface PeriodSelectorProps {
    title?: React.ReactNode
    values: RangePopoverValue[]
    onClose: (value?: RangePopoverValue) => void
    selectedValue?: RangePopoverValue
    visible?: boolean
    renderLabel?: boolean
}

interface PeriodSelectorState {
    selectedIndex?: number
    selectedValue?: RangePopoverValue
    customValue?: RangePopoverValue
    dateSelectVisible?: boolean
    values?: RangePopoverValue[]
    showSelector?: boolean
}

@observer
class PeriodSelector extends React.Component<PeriodSelectorProps, {}> {
    public static getDerivedStateFromProps(
        props: PeriodSelectorProps,
        state: PeriodSelectorState
    ) {
        const stateChange: PeriodSelectorState = {}
        if (!state.selectedValue) {
            stateChange.selectedValue = props.selectedValue
            stateChange.selectedIndex = props.values.findIndex(
                val => val.key === props.selectedValue.key
            )
        }
        if (!isEqual(props.values, state.values)) {
            stateChange.values = [...props.values]
            stateChange.customValue = stateChange.values.find(
                value => value.key === 'customPeriod'
            )
        }
        if (!isEmpty(stateChange)) {
            return stateChange
        }
        return null
    }

    public modalContentRef: HTMLDivElement
    public state = {
        selectedIndex: null,
        selectedValue: null,
        customValue: null,
        dateSelectVisible: false,
        values: [],
        showSelector: false
    }

    public handlePeriodChange = (valueIndex: number) => {
        const selectedValue = this.state.values[valueIndex]
        const selectedIndex = valueIndex
        this.setState({ selectedValue, selectedIndex })
    }
    public handleDatePeriodValueChange = (value: DateRange) => {
        const selectedValue = { ...this.state.customValue }
        const changedValue = { ...selectedValue, range: value }
        this.setState({ customValue: changedValue })
    }
    public handleCustomDateClose = (value?: RangePopoverValue) => {
        if (value) {
            const settedValue = {
                ...this.state.customValue,
                range: value.range
            }
            this.setState({
                dateSelectVisible: false,
                customValue: settedValue,
                selectedValue: settedValue
            })
        } else {
            const initialCustomValue = this.state.customValue
                ? this.state.customValue
                : this.props.values.find(val => val.key === 'customPeriod')
            this.setState({
                dateSelectVisible: false,
                customValue: initialCustomValue,
                selectedValue: initialCustomValue
            })
        }
    }
    public onClose = (value?: RangePopoverValue) => {
        this.props.onClose(value)
        this.setState({ showSelector: false })
    }

    public render() {
        const { title, onClose, values, visible, renderLabel } = this.props
        return (
            <React.Fragment>
                {renderLabel && this.renderLabel()}
                <ModalContainer
                    onClose={onClose}
                    className={`${layoutStyles.modal} ${radioStyles.container}`}
                    visible={visible || this.state.showSelector}
                    closable={false}
                    footer={null}
                    title={res().setPeriod}
                    contentRef={this.modalContentRef}
                    headerControl={
                        <MobileHeader
                            title={res().setPeriod}
                            onOk={() => this.onClose(this.state.selectedValue)}
                            onCancel={() => this.onClose()}
                            isOkDisabled={() =>
                                !this.state.selectedValue ||
                                !this.state.selectedValue.range[0] ||
                                !this.state.selectedValue.range[1]
                            }
                        />
                    }
                >
                    <div ref={ref => (this.modalContentRef = ref)}>
                        <div
                            className={`${layoutStyles.contentMain} ${styles.selectorContainer} `}
                        >
                            <Radio.Group
                                onChange={(e: RadioChangeEvent) =>
                                    this.handlePeriodChange(e.target.value)
                                }
                                value={this.state.selectedIndex}
                                buttonStyle="solid"
                                name="periodRadioGroup"
                                className={layoutStyles.radioSelect}
                            >
                                {this.state.values.map(
                                    (
                                        value: RangePopoverValue,
                                        index: number
                                    ) => (
                                        <Radio
                                            value={index}
                                            checked={
                                                index ===
                                                this.state.selectedIndex
                                            }
                                            key={`option_${value.key}`}
                                        >
                                            {value.key !== 'customPeriod' && (
                                                <span>
                                                    {isFunction(value.label)
                                                        ? value.label()
                                                        : value.label}
                                                </span>
                                            )}
                                            {value.key === 'customPeriod' && (
                                                <React.Fragment>
                                                    <a
                                                        onClick={() =>
                                                            this.setState({
                                                                dateSelectVisible: true
                                                            })
                                                        }
                                                        className={
                                                            styles.customDateRangeRadioLabel
                                                        }
                                                    >
                                                        <span>
                                                            {mainRes().setValue}
                                                        </span>
                                                        {isCorrectRangeValue(
                                                            this.state
                                                                .customValue
                                                        ) && (
                                                                <span
                                                                    className={
                                                                        styles.customDateRangeRadioLink
                                                                    }
                                                                >
                                                                    (
                                                                    <span>
                                                                        {this.state.customValue.range[0].format(
                                                                            'DD.MM.YYYY'
                                                                        )}
                                                                    </span>
                                                                -
                                                                    <span>
                                                                        {this.state.customValue.range[1].format(
                                                                            'DD.MM.YYYY'
                                                                        )}
                                                                    </span>
                                                                )
                                                                </span>
                                                            )}
                                                    </a>
                                                </React.Fragment>
                                            )}
                                        </Radio>
                                    )
                                )}
                            </Radio.Group>
                            <ModalContainer
                                visible={this.state.dateSelectVisible}
                                title={res().setPeriod}
                                onClose={this.handleDatePeriodValueChange}
                                closable={true}
                                headerControl={
                                    <MobileHeader
                                        onCancel={() =>
                                            this.handleCustomDateClose()
                                        }
                                        onOk={() =>
                                            this.handleCustomDateClose(
                                                this.state.customValue
                                            )
                                        }
                                        isOkDisabled={() =>
                                            !isCorrectRangeValue(
                                                this.state.customValue
                                            )
                                        }
                                    ></MobileHeader>
                                }
                            >
                                <DateSelect
                                    value={this.state.customValue}
                                    onChange={this.handleDatePeriodValueChange}
                                ></DateSelect>
                            </ModalContainer>
                        </div>
                    </div>
                </ModalContainer>
            </React.Fragment>
        )
    }
    private renderLabel() {
        const value = this.props.selectedValue

        const label =
            (value.label && value.label instanceof Function
                ? value.label()
                : value.label) ||
            `${value.range[0].format('L')} - ${value.range[1].format('L')}`

        return (
            <a
                onClick={() => this.setState({ showSelector: true })}
                className={styles.text}
            >
                {label}
            </a>
        )
    }
}

function isCorrectRangeValue(value: RangePopoverValue) {
    return (
        value &&
        value.range[0] &&
        value.range[1] &&
        value.range[0].isValid() &&
        value.range[1].isValid()
    )
}

export default PeriodSelector
