import res from './res'

import { PaymentStatus } from '~/api'

res.add('kk', {
    statistics: 'Статистика',
    collapseSummary: range => ` ${range instanceof Function ? range() : range || 'кезең'}`,
    sorting: 'Сұрыптау',
    filter: 'Сүзгі'
})
