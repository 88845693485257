import locale from '~/utils/locale'

import { CashByCodePayment, CashByCodeStatus, CreditType } from '~/api'

export default locale.create<{
    title: string
    ranges: {
        today: string
        yesterday: string
        thisWeek: string
        priorWeek: string
        thisMonth: string
        priorMonth: string
        thisQuarter: string
        customPeriod: string
    },
    anyPaymentStatus: string
    paymentStatuses: {
        [key in CashByCodeStatus]: string
    },
    creditTypes: {
        [key in CreditType]: string
    }
    sortBy: string
    filtersPlaceholder: string
    dataFields: {
        full: {
            [key in keyof CashByCodePayment]?: string
        },
        short: {
            [key in keyof CashByCodePayment]?: string
        },
        groups: {
            location: string,
            paymentPage: string
        },
        geolocation: string
    }
    sortOptions: {
        date: string
        postLink: string
        status: string
        type: string
        asc: {
            date: string
            postLink: string
            status: string
        },
        desc: {
            date: string
            postLink: string
            status: string
        }
    }
    secure3DValues: {
        true: string
        false: string
    }
    partialSum: {
        isRequired: string
        constraints: string
    }
    operations: {
        fullCharge: {
            title: string
            itemError: (paymentId) => string
        },
        partialCharge: {
            title: string
            itemError: (paymentId) => string
        },
        cancel: {
            title: string
            itemError: (paymentId) => string
        },
        fullRefund: {
            title: string
            itemError: (paymentId) => string
        },
        partialRefund: {
            title: string
            itemError: (paymentId) => string
        }
    }
    postLinkStatus: {
        all: string
        ok: string
        fail: string
    }
    collapseSummary: (range: string | (() => string)) => string
    errors: {
        summaryLoadError: string
        paymentsLoadError: string
    },
    errorCodes: {
        454: string
        455: string
        456: string
        457: string
        458: string
        459: string
        460: string
        461: string
        462: string
        463: string
        464: string
        465: string
        466: string
        467: string
        468: string
        469: string
        470: string
        471: string
        472: string
        473: string
        475: string
        476: string
        477: string
        478: string
        479: string
        480: string
        481: string
        482: string
        483: string
        484: string
        485: string
        486: string
        487: string
        488: string
        489: string
        490: string
        491: string
        492: string
        493: string
        494: string
        495: string
        496: string
        497: string
        498: string
        499: string
        500: string
        501: string
        502: string
        503: string
        521: string
        522: string
        1636: string
        1654: string
        2092: string
        2678: string
    }
}>()
