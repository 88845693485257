import React from 'react'

import { observer } from 'mobx-react'

import { IconButton } from '~/components'
import { CloseModalIcon } from '~/components/icon'
import layoutStyles from '~/layouts/main/styles.css'

import { DetailedField, DetailedFieldsGroup } from '../../types'

import styles from './styles.css'

import globalRes from '~/res'
import BrowserStore from '~/services/browser/browser-state'
import res from './res'
import CollapseChargeback from '../collapse-chargeback'

interface PaymentDetailedFormProps<T> {
    visible: boolean
    onClose: () => void
    payment: T
    paymentReference?: string
    actionsPane?: React.ReactNode
    fields: Array<DetailedFieldsGroup<T>>
}

export default observer(function PaymentDetailedForm<T>(
    props: PaymentDetailedFormProps<T>
) {
    const { payment } = props

    const renderField = (fieldInfo: DetailedField<T>, showSeparator: boolean) => {
        const { field, type, render } = fieldInfo
        const value =
            field !== 'geolocationText' && field !== 'geolocationMap'
                ? props.payment[field]
                : null
        const separatorRow = showSeparator ? (
            <tr key={'subgroup_separator'}
                className={styles.subgroupSeparator}>
                <td colSpan={2}>&nbsp;</td>
            </tr>
        ) : null

        if (
            !fieldInfo ||
            (typeof fieldInfo.visibility !== 'undefined' &&
                !fieldInfo.visibility(value, payment))
        ) {
            return separatorRow
        }

        if (type === 'collapse') {
            return [
                <tr key={`chargeback`}>
                    <td colSpan={2}>
                        {render(value, payment)}
                    </td>
                </tr>,
                separatorRow
            ]
        }

        return [
            <tr
                key={`field_${field}`}
                className={`${styles.field} ${fieldInfo.withoutBorder ? styles.withoutBorder : ''
                    }`}
            >
                {!fieldInfo.wide && (
                    <td className={styles.label}>{fieldInfo.title}:</td>
                )}
                <td colSpan={fieldInfo.wide ? 2 : 1}>
                    {renderFieldValue(fieldInfo, value)}
                </td>
            </tr>,
            separatorRow
        ]
    }

    const renderFieldValue = (fieldInfo: DetailedField<T>, value: any) => {
        const emptyField = '-'
        if (typeof fieldInfo.render !== 'undefined') {
            const renderedFiled = fieldInfo.render(value, payment)
            return renderedFiled ? renderedFiled : emptyField
        }

        if (!value) return emptyField

        return value
    }
    return (
        <React.Fragment>
            {!BrowserStore.isMobile && (
                <div className={styles.headerWrapper}>
                    <div className={styles.header}>
                        <div
                            className={styles.title}
                            data-semantic-id="title-payment-datails"
                        >
                            {res().title(props.paymentReference)}
                        </div>
                        <div className={styles.actionsContainer}>
                            {props.actionsPane}
                        </div>
                        <IconButton
                            className={layoutStyles.closeButton}
                            icon={CloseModalIcon}
                            onClick={props.onClose}
                            id="close-payment-datails-icon"
                            tooltipTitle={globalRes().close}
                            tooltipPlacement="bottom"
                        />
                    </div>
                </div>
            )}
            <div className={styles.fields}>
                <table>
                    <tbody>
                        <tr key={`chargeback`}>
                            <td colSpan={1}>
                                {/*<CollapseChargeback chargeback={payment?.chargeback}/>*/}
                            </td>
                        </tr>
                        {props.visible &&
                            props.fields.map(group => [
                                group.title ? (
                                    <tr
                                        className={styles.groupTitle}
                                        key={`fields_group_${group.id}`}
                                    >
                                        <td colSpan={2}>{group.title}</td>
                                    </tr>
                                ) : null,
                                group.subgroups.map((subgroup, subgroupIndex) =>
                                    subgroup.map((field, index) => {
                                        return renderField(
                                            field,
                                            (index === subgroup.length - 1) && (subgroupIndex !== group.subgroups.length - 1)
                                        )
                                    })
                                )
                            ])}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
})
