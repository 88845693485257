import React from 'react'

import res from './res'

const getPaymentCountLabel = (paymentsCount: number) => {
    if (paymentsCount >= 5 && paymentsCount <= 20) {
        return 'төлемдер'
    }

    const lastDigit = paymentsCount % 10

    if (lastDigit === 1) {
        return 'төлем'
    }

    if (lastDigit >= 2 && lastDigit <= 4) {
        return 'төлем'
    }

    return 'төлемдер'
}

const getSelectedText = (count: number) => {
    return count % 10 === 1 ? 'таңдалған' : 'таңдалған'
}

res.add('kk', {
    selectedPaymentsLabel: (
        paymentCount: number,
        amountsContent: React.ReactNode
    ) => (
        <span>
            {paymentCount} {getPaymentCountLabel(paymentCount)}сомасына{' '}
            {amountsContent} {getSelectedText(paymentCount)}
        </span>
    ),
    clearButton: 'Бөлуді алып тастау'
})
