import locale from '~/utils/locale'

export default locale.create<{
    contacts: string
    docs: string
    help: string
    termsOfUse: string
    contactsModal: {
        title: string
        close: string
        forLegals: string
        forIndividuals: string
        acquiring: string
        cabinet: string
    }
}>()
