import React, { FormEvent } from 'react'

import { observer } from 'mobx-react'

import { Button, Form, FormItem, InputItem } from '~/components'

import styles from '~/layouts/auth/styles.css'
import SuccessOperation from '~/layouts/auth/success-operation'

import res from '../res'

import SignUpStore from './signup-store'

export default observer(({ store }: { store: SignUpStore }) => {
    function submit(e: FormEvent) {
        store.signUp()
        e.preventDefault()
    }

    if (store.isAccountCreated) {
        return (
            <>
                <SuccessOperation
                    title={res().accountCreatedTitle}
                    infoText={res().accountCreatedInfo(store.email.value)}
                />
                <div className={styles.noActivationEmail}>
                    <span>{res().noActivationEmail}</span>
                    <br />
                    <span
                        className={styles.signUpRepeatLink}
                        onClick={store.repeat}
                    >
                        {res().repeatSignUpLink}
                    </span>
                </div>
            </>
        )
    }

    return (
        <div data-semantic-id="signup_form">
            <div className={styles.header}>
                <span data-semantic-id="signup_title" className={styles.title}>
                    {res().signupTitle}
                </span>
            </div>
            <div className={styles.main}>
                <Form onSubmit={submit}>
                    <InputItem
                        store={store.email}
                        placeholder={res().signupEmailPrompt}
                        inputProps={{
                            name: 'email_field',
                            size: 'large'
                        }}
                    />
                    <FormItem>
                        {store.error && (
                            <div className={styles.error}>{store.errorMessage}</div>
                        )}
                        <Button
                            data-semantic-id="signup_button"
                            disabled={store.isSending}
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className={styles.mainButton}
                        >
                            {res().signUpAction}
                        </Button>
                    </FormItem>
                    <FormItem
                        style={{ textAlign: 'center' }}
                        className={styles.cancelButtonWrapper}
                    >
                        {res().hasAccount}{' '}
                        <a href={'/login'}>{res().loginLink}</a>
                    </FormItem>
                </Form>
            </div>
        </div>
    )
})
