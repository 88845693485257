import locale from '~/utils/locale'

export default locale.create<{
    dashboard: string
    // dashboardLabel: string

    /*****************************************/
    transactions: string
    payments: string
    transfers: string
    aftTransactions: string
    octTransactions: string
    cashByCode: string

    /*****************************************/
    banksProducts: string

    epay: string
    pos: string
    goBonuses: string
    orange: string
    homebank: string
    QRPayments: string,
    credit: string,

    /*****************************************/
    p2p: string,
    oct: string,

    /*****************************************/

    paymentAft: string,
    aft: string,

    /*****************************************/

    statements: string,
    payment: string,
    transfer: string,
    chargeback: string,

    /*****************************************/
    news: string

    increaseBonuses: string
    VIPMerchants: string
    /*****************************************/
    paymentLinks: string

    /*****************************************/
    qrCodes: string

    staticQRCode: string
    dynamicQRCodes: string
    labelGenerator: string
    /*****************************************/
    settings: string

    /*
        paymentSystemActivation: string
        ePayPaymentSystemActivation: string
    */
    configureEpayTemplate: string
    terminalSettings: string
    certificates: string
    managingMerchants: string
    team: string
    faq: string
    forDevelopers: string
    documentation: string
    notifications: string
    profile: string
    logOut: string
    applyButton: string
    cancelButton: string
    setValue: string
    orders: string
    currentOrders: string
    createOrder: string
    paymentSettings: string
}>()
