import { Input, message } from 'antd'
import React, { useEffect, useRef } from 'react'
import { IconButton } from '~/components'
import { CheckIcon, PaymentLinksIcon } from '~/components/icon'
import res from '../res'
import styles from '../styles.css'

import QRCode from 'qrcode.react'

import isNil from 'lodash/isNil'
import PaymentLinks from '~/pages/payment-links'
import { PaymentLink } from '~/api/payment-links'

// public renderQr = () => {
//     QRCode.toCanvas(this.canvasRef, this.state.createdPaymentLink.invoice_url)
// }
// public componentDidUpdate() {
//     if (!isNil(this.canvasRef) && this.state.isGenerated) {
//         this.renderQr()
//     }
// }

interface ConfirmationPageProps {
    createdPaymentLink: PaymentLink
}

function ConfirmationPage({ createdPaymentLink }: ConfirmationPageProps) {
    // const canvasRef = useRef(null)

    // const renderQR = () => {
    //     QRCode.toCanvas(canvasRef, createdPaymentLink.invoice_url)
    // }

    return (
        <div className={styles.confirmation}>
            <div className={styles.confirmationHeader}>
                <CheckIcon className={styles.checkIcon} />
                <div className={styles.confirmationTitle}>
                    {res().confirmationTitle(createdPaymentLink.invoice_id)}
                </div>
                {/* In case if we need qr in generated payment link component */}
                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                    <QRCode value={createdPaymentLink.invoice_url} />
                </div> */}
            </div>
            <div className={styles.link}>
                <Input
                    disabled={true}
                    id="link"
                    value={createdPaymentLink.invoice_url}
                    className={styles.linkInput}
                    addonAfter={
                        <IconButton
                            icon={PaymentLinksIcon}
                            className={styles.copyLinkIcon}
                            onClick={() => {
                                const link = document.createElement('input')
                                link.value = createdPaymentLink.invoice_url
                                document.body.appendChild(link)
                                link.select()
                                document.execCommand('copy')
                                document.body.removeChild(link)
                                message.info(res().clipBoardMessage)
                            }}
                        />
                    }
                />
            </div>
            <div className={styles.qrCodeContainer}></div>
            <div className={styles.linkInfo}>
                <div>
                    <span>{res().formItemTitles.orderNumber}:</span>
                    <span>{createdPaymentLink.invoice_id}</span>
                </div>
                <div>
                    <span>{res().formItemTitles.orderSum}:</span>
                    <span className={styles.sum}>
                        {createdPaymentLink.amount}
                    </span>
                </div>
                <div>
                    <span>{res().formItemTitles.language}:</span>
                    <span>{createdPaymentLink.language.toUpperCase()}</span>
                </div>
                <div>
                    <span>{res().formItemTitles.recieverEmail}:</span>
                    <span>{createdPaymentLink.recipient_contact}</span>
                </div>
                <div>
                    <span>{res().formItemTitles.recieverPhone}:</span>
                    <span>{createdPaymentLink.recipient_contact_sms}</span>
                </div>
                {/*<div>*/}
                {/*    <span>{res().formItemTitles.senderEmail}:</span>*/}
                {/*    <span>{createdPaymentLink.notifier_contact}</span>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <span>{res().formItemTitles.senderPhone}:</span>*/}
                {/*    <span>{createdPaymentLink.notifier_contact_sms}</span>*/}
                {/*</div>*/}
                <div>
                    <span>{res().formItemTitles.description}:</span>
                    <span>{createdPaymentLink.description}</span>
                </div>
                <div>
                    <span>{res().formItemTitles.expirePeriod}:</span>
                    <span>{createdPaymentLink.expire_period}</span>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationPage
