import React from 'react'

import { action } from 'mobx'

import { inject, observer } from 'mobx-react'

import BaseDashboardChart, { BaseDashboardChartProps } from '../base-chart'

import { IssuingBanksDataItem } from '../../../types'

import StatisticsPieChart from '../../statistics-pie-chart'
import { TooltipContent } from '../tooltips/issuer-transactions-chart-tooltip'

import DashboardStore from '~/pages/dashboard/dashboard-store'

const banks = ['bank1', 'bank2']

const banksColors = [
    '#00A8FF',
    '#FF8500',
    '#05B9A6',
    '#8483C5',
    '#F42B74',
    '#EBCB4C'
]

interface IssuingBanksChartProps extends BaseDashboardChartProps {
    data: IssuingBanksDataItem[]
    dashBoardStore?: DashboardStore
}

@inject('dashBoardStore')
@observer
export default class IssuingBanksChart extends BaseDashboardChart<
IssuingBanksChartProps
> {
    @action.bound
    protected initData() {
        this.fieldsConfig = banks.map((bank, i) => ({
            field: bank,
            title: bank,
            visible: true,
            color: banksColors[i],
            dataKey: `data.${bank}.amount`
        }))
    }

    protected renderContent = () => {
        if (this.props.data.length === 0) {
            return null
        }

        return (
            <StatisticsPieChart
                data={this.props.data}
                chartTooltip={TooltipContent}
            />
        )
    }

    protected data: Array<{}>

    protected configurable: boolean = false
}
