import copy from 'copy-to-clipboard'
import { action, computed, observable, runInAction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Installment } from '~/api'
import {
    PaymentLink,
    PaymentLinkDetails,
    PaymentLinkGrid,
    PaymentLinkStatus
} from '~/api/payment-links'
import appStyles from '~/app.css'
import {
    Affix,
    Button, ButtonDefault,
    FieldsConfigForm,
    List,
    SortConfigSelect
} from '~/components'
import { Column, Grid } from '~/components/grid'
import GridControls from '~/components/grid-controls'
import {
    CancelCircleIcon,
    ClockIcon,
    ContractIcon,
    PaymentLinksIcon,
    SortByDescAIcon
} from '~/components/icon'
import { InfinitiveList } from '~/components/list/infinitive-list'
import MobileMenu, {
    MobileMenuConfig
} from '~/components/mobile-menu/mobile-menu'
import MobileHeader from '~/components/modal/mobile-header'
import ModalContainer, {
    ModalContext
} from '~/components/modal/modal-container'
import StatusIcon from '~/components/status-icon'
import DictionaryStore from '~/dictionary/dictionary-store'
import Page from '~/layouts/main/page'
import { info } from '~/layouts/main/styles.css'
import BaseFilters from '~/pages/payments/epay/components/base-filters'
import store from '~/services/browser/browser-state'
import BrowserStore from '~/services/browser/browser-state'
import message from '~/utils/message'
import GenerationForm from './components/payment-links-config'
import constants from './constants'
import { renderDate } from './constants/data-fields'
import constantRes from './constants/res'
import PaymentLinksStore from './payment-links-store'
import res from './res'
import styles from './styles.css'
import { cancelledStatus } from './styles.css'
import InteractiveMobileHints from '~/components/interactive-mobile-hints'
import mainRes from './../../res'

export const StatusesTitlesRes = () => res().linkStatuses

export const StatusesOptions = [
    {
        key: PaymentLinkStatus.any,
        title: () => StatusesTitlesRes().any.toLocaleLowerCase()
    },
    {
        key: PaymentLinkStatus.active,
        title: () =>
            StatusesTitlesRes()[PaymentLinkStatus.active].toLocaleLowerCase()
    },
    {
        key: PaymentLinkStatus.annulled,
        title: () =>
            StatusesTitlesRes()[PaymentLinkStatus.annulled].toLocaleLowerCase()
    },
    {
        key: PaymentLinkStatus.expired,
        title: () =>
            StatusesTitlesRes()[PaymentLinkStatus.expired].toLocaleLowerCase()
    },
    {
        key: PaymentLinkStatus.charged,
        title: () =>
            StatusesTitlesRes()[PaymentLinkStatus.charged].toLocaleLowerCase()
    }
]

interface PaymentLinksProps {
    store: PaymentLinksStore
    dictionary: DictionaryStore
}

@observer
export default class PaymentLinks extends Component<PaymentLinksProps, {}> {
    @observable
    public menuVisible: boolean = false

    @action.bound
    public toggleMenu(selectAction?: () => void) {
        this.menuVisible = !this.menuVisible
        if (selectAction) {
            selectAction()
        }
    }

    public componentDidMount() {
        this.props.store.getInvoicesFromAPI()
    }

    public render() {
        const { store, dictionary } = this.props

        runInAction(() => (this._intallmentsList = dictionary.installments))

        return (
            <Page
                title={
                    <div className={styles.titleTable}>
                        <div>{res().paymentLinksTitle}</div>
                        {!BrowserStore.isMobile && (
                            <div className={styles.titleButton}>
                                <ButtonDefault
                                    type="primary"
                                    onClick={store.togglePaymentLinkConfigForm}
                                    className="hint-section-6-step-3"
                                >
                                    {res().generateButtonTitle}
                                </ButtonDefault>
                            </div>
                        )}
                    </div>
                }
                toolbar={this.toolbar}
                onBackClick={
                    store.showPaymentLinkConfig
                        ? store.togglePaymentLinkConfigForm
                        : null
                }
            >
                {!BrowserStore.isMobile && (
                    <React.Fragment>
                        <div className={styles.baseControls}>
                            <BaseFiltersContainer store={store} />
                            <div className={styles.sort}>
                                <label htmlFor="sort">
                                    {res().sortBy}&nbsp;&nbsp;
                                </label>
                                <this.SortContainer onChange={store.setSort} />
                            </div>
                            <div></div>
                        </div>
                        <div className={styles.gridControlsContainer}>
                            {this.gridControls}
                        </div>
                    </React.Fragment>
                )}
                {BrowserStore.isMobile && !store.showPaymentLinkConfig && (
                    <div className={styles.mobileButton}>
                        <div className={styles.titleButton}>
                            <Button
                                type="primary"
                                onClick={store.togglePaymentLinkConfigForm}
                                className="mobile-hint-section-6-step-3"
                            >
                                {res().generateButtonTitle}
                            </Button>
                        </div>
                    </div>
                )}

                {(!BrowserStore.isMobile || !store.showPaymentLinkConfig) && (
                    <div className={styles.gridCnt}>
                        {this.grid}
                        {this.customizeGridForm}
                    </div>
                )}
                {!BrowserStore.isMobile &&
                    store.showPaymentLinkConfig &&
                    this.paymentLinkConfigContainer()}

                {BrowserStore.isMobile && store.showPaymentLinkConfig && (
                    <GenerationForm
                        onClose={store.togglePaymentLinkConfigForm}
                        onApply={store.generateLink}
                        paymentLinkUpdating={store.paymentLinkUpdating}
                    />
                )}

                {BrowserStore.isMobile && store.mobileHintStore &&
                    <InteractiveMobileHints store={store.mobileHintStore} />
                }
            </Page>
        )
    }

    private get toolbar() {
        const { store } = this.props

        return (
            <>
                <div>
                    <MobileMenu config={this.mobileMenuConfig}></MobileMenu>

                    <ModalContainer
                        visible={store.showSortingMobile}
                        onClose={store.toggleSortingMobile}
                        headerControl={
                            <MobileHeader
                                onCancel={store.toggleSortingMobile}
                            ></MobileHeader>
                        }
                        closable={true}
                    >
                        <this.SortContainer
                            asMenu={true}
                            onChange={(field, direction) => {
                                store.toggleSortingMobile()
                                store.setSort(field, direction)
                            }}
                        />
                    </ModalContainer>
                </div>
            </>
        )
    }

    @computed
    private get mobileMenuConfig(): MobileMenuConfig {
        const store = this.props.store

        const menuConfig: MobileMenuConfig = {
            buttons: !store.showPaymentLinkConfig
                ? [
                    {
                        clickAction: () =>
                            this.toggleMenu(store.toggleSortingMobile),
                        icon: <SortByDescAIcon />
                    }
                ]
                : []
        }
        menuConfig.visible = this.menuVisible
        menuConfig.disabled = false
        return menuConfig
    }

    private get gridControls() {
        return (
            <GridControls
                store={this.props.store}
                customize={this.openCustomizeGridForm}
            />
        )
    }

    private get customizeGridForm() {
        return <this.gridConfigContainer />
    }

    protected get columns() {
        return constants.fields(this.props.store).get()
    }

    private get grid() {
        if (BrowserStore.isMobile) {
            return <this.listContainer />
        }

        return <this.gridContainer />
    }

    @action.bound
    protected openCustomizeGridForm() {
        this.showCustomizeGridForm = true
    }

    @action.bound
    protected closeCustomizeGridForm() {
        this.showCustomizeGridForm = false
    }

    private paymentLinkConfigContainer() {
        const { store } = this.props
        return (
            <ModalContainer
                modalClass={
                    `${!store.showPaymentLinkConfirmation ? styles.modal : ''} hint-section-6-step-4`
                }
                visible={!BrowserStore.isMobile && store.showPaymentLinkConfig}
                onClose={store.togglePaymentLinkConfigForm}
                headerControl={
                    <MobileHeader
                        onCancel={store.togglePaymentLinkConfigForm}
                    ></MobileHeader>
                }
                closable={true}
            >
                <ModalContext.Consumer>
                    {modalContext => (
                        <GenerationForm
                            onClose={store.togglePaymentLinkConfigForm}
                            onApply={store.generateLink}
                            paymentLinkUpdating={store.paymentLinkUpdating}
                            modalContext={modalContext}
                        />
                    )}
                </ModalContext.Consumer>
            </ModalContainer>
        )
    }

    private renderListItem = (item: PaymentLink) => {
        const store = this.props.store

        let itemStatusStyle = styles.mainColor

        if (item.status === PaymentLinkStatus.active.toUpperCase()) {
            itemStatusStyle = styles.activeStatus
        } else if (item.status === PaymentLinkStatus.annulled.toUpperCase()) {
            itemStatusStyle = styles.cancelledStatus
        } else if (item.status === PaymentLinkStatus.expired.toUpperCase()) {
            itemStatusStyle = styles.expiredStatus
        }

        return (
            <List.Item className={'styles.listItem'} key={item.id}>
                <div className={styles.cardElement}>
                    <div className={appStyles.floatTwoCellRow}>
                        <div className={itemStatusStyle}>
                            <StatusIcon className={styles.icon} />
                            &nbsp;
                            <span>
                                {constantRes().postLinkStatuses[item.status]}
                            </span>
                        </div>
                        <div className={appStyles.autoWidth}>
                            <ClockIcon />
                            &nbsp;
                            <span className={`${appStyles.primaryText}`}>
                                {renderDate(item.expire_date, false, true)}
                            </span>
                        </div>
                    </div>
                    <div className={appStyles.floatTwoCellRow}>
                        <div className={styles.mainColor}>
                            <ContractIcon />
                            &nbsp;
                            <span>{item.invoice_id}</span>
                        </div>
                        <div className={itemStatusStyle}>
                            <span>{item.amount}</span>
                        </div>
                    </div>
                    <div className={appStyles.floatTwoCellRow}>
                        <div className={styles.actions}>
                            <span>
                                <button>
                                    <PaymentLinksIcon
                                        className={`${styles.icon} ${styles.copyIcon}`}
                                        onClick={async () => {
                                            store.copyLink(item)
                                            message.success(
                                                res().savedText,
                                                mainRes().success
                                            )
                                        }}
                                    />
                                </button>
                            </span>
                            <span>
                                <CancelCircleIcon
                                    className={`${styles.icon} ${item.status === PaymentLinkStatus.active
                                        ? styles.activeCancelIcon
                                        : ''
                                        }`}
                                    onClick={() => store.cancelLink(item.id)}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </List.Item>
        )
    }

    private SortContainer = observer(
        ({
            asMenu,
            onChange
        }: {
            asMenu?: boolean
            onChange?: (
                field: keyof PaymentLinkDetails,
                direction: 'asc' | 'desc'
            ) => void
        }) => {
            const { store } = this.props
            return (
                <SortConfigSelect<PaymentLinkDetails>
                    id="sort"
                    options={constants.sortOptions.get()}
                    asMenu={asMenu}
                    selectedOption={{
                        field: store.sortField,
                        direction: store.sortDirection
                    }}
                    onChange={onChange}
                />
            )
        }
    )

    private gridConfigContainer = observer(() => {
        const { store } = this.props

        const columns = store.columns
            .filter(x => x.title && x.field !== 'actions')
            .map(column => {
                const data = this.columns.find(
                    item => item.field === column.field
                )
                return {
                    field: column.field,
                    visible: column.visible,
                    title: data && data.title
                }
            })

        return (
            <FieldsConfigForm
                values={columns}
                visible={this.showCustomizeGridForm}
                onClose={this.closeCustomizeGridForm}
                onSetFields={store.setColumnsOrder}
                pageSize={store.pageSize}
                onSetPageSize={store.setPageSize}
                sortable={true}
                customizablePageSize={true}
            />
        )
    })

    private gridContainer = observer(() => {
        const { store } = this.props

        const columns: Array<Column<PaymentLinkGrid>> = []

        const colMap = new Map(
            this.columns.map((col): [
                keyof PaymentLinkGrid,
                Column<PaymentLinkGrid>
            ] => [col.field, col])
        )

        store.paymetLinksGridColumn.forEach(col => {
            const column = colMap.get(col.field)
            column.width = col.width
            columns.push(column)
        })

        return (
            <Grid<PaymentLink>
                columns={columns}
                data={toJS(store.paymentLinks)}
                onRowClick={record => store.paymentLinkDetails(record)}
                rowKey={store.paymentLinkKey as keyof PaymentLink}
                loading={store.paymentLinksLoading}
                disableRowSelection={true}
            />
        )
    })

    private listContainer = observer(() => {
        const { store } = this.props
        return (
            <div
                className={styles.listInfiniteContainer}
                style={{ height: BrowserStore.windowHeight - 101 + 'px' }}
            >
                <InfinitiveList<PaymentLink>
                    dataSource={store?.paymentLinks ? store.paymentLinks : []}
                    loaded={!store.paymentLinksLoading}
                    dataLoader={store.getInvoicesFromAPI}
                    itemRenderer={this.renderListItem}
                    pageSize={store.pageSize}
                ></InfinitiveList>
            </div>
        )
    })

    @observable
    private _intallmentsList: Installment[]

    @observable
    private showCustomizeGridForm: boolean
}

const BaseFiltersContainer = observer(
    ({ store }: { store: PaymentLinksStore }) => (
        <BaseFilters
            rangePresets={store.rangePresets}
            range={store.range}
            onRangeChange={store.setRange}
            status={store.statusFilter}
            statusesOptions={StatusesOptions}
            onStatusChange={store.setStatusFilter}
            className={styles.baseFilters}
            dateLabel={res().dateFilterLabel}
        />
    )
)
