// TODO: Create fork of react-sortable-pane for IE9 support

import { cloneElement, createElement } from 'react'

import { Pane, SortablePane } from 'react-sortable-pane'

import { Motion, spring } from 'react-motion'

const MAXIMUM_Z_INDEX = 2147483647

const springConfig = {
    damping: 30,
    stiffness: 500
}

const assign =
    Object.assign ||
    function __assign(t) {
        for (let s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i]
            for (const p in s) {
                if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p]
            }
        }
        return t
    }

SortablePane.prototype.renderPanes = function () {
    const self = this
    const a = this.state
    const mouse = a.mouse
    const isPressed = a.isPressed
    const lastPressed = a.lastPressed
    const isResizing = a.isResizing
    const b = this.props
    const disableEffect = b.disableEffect
    const isSortable = b.isSortable
    const children = this.props.children || []
    return children.map((child, i) => {
        const pos = self.props.order
            ? self.getItemPositionByIndex(
                self.props.order.indexOf(String(child.key))
            )
            : self.getItemPositionByIndex(self.order.indexOf(i))
        const springPosition = spring(pos, springConfig)
        const style =
            lastPressed === i && isPressed && !isResizing
                ? {
                    scale: disableEffect ? 1 : spring(1.05, springConfig),
                    shadow: disableEffect ? 0 : spring(16, springConfig),
                    x: self.isHorizontal() ? mouse : 0,
                    y: !self.isHorizontal() ? mouse : 0
                }
                : {
                    scale: disableEffect ? 1 : spring(1, springConfig),
                    shadow: disableEffect ? 0 : spring(0, springConfig),
                    x: self.isHorizontal() ? springPosition : 0,
                    y: !self.isHorizontal() ? springPosition : 0
                }
        return createElement(Motion, { style, key: String(child.key) }, c => {
            const scale = c.scale
            const shadow = c.shadow
            const x = c.x
            const y = c.y
            const onResize = self.onResize.bind(self, i)
            const onMouseDown = isSortable
                ? self.handleMouseDown.bind(self, i, x, y)
                : e => {
                    if (child.props.onMouseDown) {
                        child.props.onMouseDown(e)
                    }
                }
            const onTouchStart = self.handleTouchStart.bind(
                self,
                i,
                x,
                y,
                child
            )
            const onResizeStart = self.handleResizeStart.bind(self, i)
            const onResizeStop = self.handleResizeStop.bind(self, i)
            const onSizeChange = () => self.forceUpdate()

            const elementStyle = assign({}, child.props.style, {
                boxShadow:
                    'rgba(0, 0, 0, 0.2) 0px ' +
                    shadow +
                    'px ' +
                    2 * shadow +
                    'px 0px',
                transform:
                    'translate(' + x + 'px, ' + y + 'px) scale(' + scale + ')',
                WebkitTransform:
                    'translate(' + x + 'px, ' + y + 'px) scale(' + scale + ')',
                MozTransform:
                    'translate(' + x + 'px, ' + y + 'px) scale(' + scale + ')',
                msTransform:
                    'translateX(' +
                    x +
                    'px) translateY(' +
                    y +
                    'px) scale(' +
                    scale +
                    ')' /* TODO: For IE9 */,
                zIndex:
                    i === lastPressed && self.state.isPressed
                        ? MAXIMUM_Z_INDEX
                        : (child.props.style && child.props.style.zIndex) ||
                        'auto',
                position: 'absolute',
                userSelect: isPressed || isResizing ? 'none' : 'auto'
            })
            return cloneElement(child, {
                onMouseDown,
                onTouchStart,
                onResizeStart,
                onResizeStop,
                onResize,
                onSizeChange,
                style: elementStyle
            })
        })
    })
}

export { Pane, SortablePane }
