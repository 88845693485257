import res from './res'

import { CashByCodeStatus, CreditType } from '~/api'

res.add('en', {
    title: 'Cash by code',
    ranges: {
        today: 'today',
        yesterday: 'yesterday',
        thisWeek: 'this week',
        priorWeek: 'prior week',
        thisMonth: 'this month',
        priorMonth: 'prior month',
        thisQuarter: 'this quarter',
        customPeriod: 'custom period'
    },
    anyPaymentStatus: 'Any',
    paymentStatuses: {
        [CashByCodeStatus.auth]: 'Pending',
        [CashByCodeStatus.charged]: 'Charged',
        [CashByCodeStatus.cancelled]: 'Cancelled',
        [CashByCodeStatus.rejected]: 'Rejected',
        [CashByCodeStatus.refunded]: 'Refunded',
        [CashByCodeStatus.failed]: 'Failed',
        [CashByCodeStatus.new]: 'New',
        [CashByCodeStatus.any]: 'Any',
        [CashByCodeStatus.CANCEL_OLD]: 'Cancel old',
        [CashByCodeStatus.TIMEOUT_3D]: '3DS Cancelled'
    },
    creditTypes: {
        [CreditType.loan]: 'Loan',
        [CreditType.installment]: 'Installment'
    },
    sortBy: 'Sort by',
    filtersPlaceholder: 'Payment attributes filter',
    dataFields: {
        full: {
            amount: 'Сумма платежа',
            authDate: 'Дата авторизации',
            cardMask: 'Маска карты',
            client: 'Клиент',
            contractNumberDevice: 'Номер девайса',
            createdAt: 'Дата создания',
            currency: 'Валюта',
            description: 'Детали платежа',
            destinationPhone: 'На номер',
            epayTerminalID: 'epayTerminalID',
            failurePostLink: 'Ссылка ошибочного перенаправления',
            id: 'Идентификатор',
            invoiceId: 'Номер заказа',
            isPostLink: 'Постлинк',
            merchant: 'Мерчант',
            payerIP: 'IP плателщика',
            postLink: 'Ссылка перенаправления',
            postingDate: 'Дата публикации',
            postingStatus: 'Статус публикации',
            receiverPhone: 'Номер получателя',
            reference: 'Ответ от карточной системы',
            returnCode: 'Код возвращения',
            sourcePhone: 'С номера',
            stan: 'Stan',
            status: 'Статус платежа',
            terminalID: 'ID терминала'
        },
        short: {
            amount: 'Сумма',
            authDate: 'Дата авторизации',
            cardMask: 'Маска карты',
            cashCodeSMS: 'SMS код',
            client: 'Клиент',
            contractNumberDevice: 'Номер девайса',
            createdAt: 'Дата создания',
            currency: 'Валюта',
            description: 'Детали платежа',
            destinationPhone: 'На номер',
            epayTerminalID: 'epayTerminalID',
            failurePostLink: 'Ссылка ошибочного',
            id: 'Идентификатор',
            invoiceId: 'Номер заказа',
            isPostLink: 'Постлинк',
            merchant: 'Мерчант',
            payerIP: 'IP плателщика',
            postLink: 'Ссылка перенаправления',
            postingDate: 'Дата публикации',
            postingStatus: 'Статус',
            receiverPhone: 'Номер получателя',
            reference: 'Ответ',
            returnCode: 'Код возвращения',
            sourcePhone: 'С номера',
            stan: 'Stan',
            status: 'Статус платежа',
            terminalID: 'ID терминала'
        },
        groups: {
            location: 'Location',
            paymentPage: 'Payment Page'
        },
        geolocation: 'Geolocation'
    },
    sortOptions: {
        date: 'Date',
        postLink: 'PostLink',
        status: 'Status',
        type: 'Type',
        asc: {
            date: 'old first',
            postLink: 'successful first',
            status: 'pending first'
        },
        desc: {
            date: 'new first',
            postLink: 'failed first',
            status: 'refund first'
        }
    },
    secure3DValues: {
        true: 'Yes',
        false: 'No'
    },
    partialSum: {
        isRequired: 'Sum field is required',
        constraints: 'Sum must be a positive number not greater than the payment sum'
    },
    operations: {
        fullCharge: {
            title: 'Full charge',
            itemError: paymentReference => `Error full charge on payment #${paymentReference}`
        },
        partialCharge: {
            title: 'Partial charge',
            itemError: paymentReference => `Error partial charge on payment #${paymentReference}`
        },
        cancel: {
            title: 'Cancel',
            itemError: paymentReference => `Error cancellation on payment #${paymentReference}`
        },
        fullRefund: {
            title: 'Full refund',
            itemError: paymentReference => `Error full refund on payment #${paymentReference}`
        },
        partialRefund: {
            title: 'Partial refund',
            itemError: paymentReference => `Error partial refund on payment #${paymentReference}`
        }
    },
    postLinkStatus: {
        all: 'All',
        ok: 'OK',
        fail: 'Failed'
    },
    collapseSummary: range => `Statistics for ${range instanceof Function ? range() : range || 'period'}`,
    errors: {
        summaryLoadError: 'Summary load error',
        paymentsLoadError: 'Payments load error'
    },
    errorCodes: {
        454: 'The operation failed, please check the amount on the card is not blocked and try again later.',
        455: 'Authentication failed (3DSecure/Securecode).',
        456: 'Error please try later',
        457: 'Error in card expiration date field.',
        458: 'Server is not responding.',
        459: 'Server is not responding.',
        460: 'An error occurred, the payment amount may be blocked, please contact customer support.',
        461: 'System error. Please try later. If this problem continues, please contact the customer support.',
        462: 'Call your bank.',
        463: 'Call your bank.',
        464: 'Invalid merchant.',
        465: 'Your card is restricted.',
        466: 'Transaction declined.Call your bank.',
        467: 'Your card is disabled.',
        468: 'Additional identification required.',
        469: 'Invalid transaction.',
        470: 'Invalid amount.',
        471: 'No such card.',
        472: 'No such card.',
        473: 'Authentication failed (3DSecure/Securecode).',
        475: 'Invalid response.',
        476: 'No action taken.',
        477: 'Format error.',
        478: 'Expired card.',
        479: 'Restricted card.',
        480: 'Call your bank.',
        481: 'Lost card.',
        482: 'Lost card.',
        483: 'Stolen card.',
        484: 'Not sufficient funds.',
        485: 'Expired card.',
        486: 'Not permitted to client by Issuing bank. Call your bank.',
        487: 'Not permitted to merchant.',
        488: 'Exceeds amount limit.',
        489: 'Restricted card.',
        490: 'The ban on the payments on your card, for more information, please contact customer support. Contact info is stated on the back of your card.',
        491: 'Exceeds frequency limit.',
        492: 'The card blocked due to incorrect entering pin-code. For the further information please contact customer support, contact info is stated on the back of your card.',
        493: 'Time-out at issuer system.',
        494: 'Issuer unavailable.',
        495: 'The transaction is prohibited. Please use other card.',
        496: '3DSecure SecureCode absent.',
        497: 'Server is not responding. Please try later.',
        498: 'Payment with bonuses is not available. Please try again later.',
        499: 'Incorrectly entered or not entered 3DSecure/Securecode',
        500: '3DSecure / Securecode verification is not available or you entered card number incorrectly. Try to use a different browser/device. If the error persists, reset the password.',
        501: 'Error in servicing the card. Be sure to enter the card number correctly. If the error persists, contact support.',
        502: 'This type of transaction requires the mandatory input of the password 3DSecure/Securecode.',
        503: 'Sorry! This operation requires you to use you 3D Secure password.',
        521: 'Transaction declined.Call your bank.',
        522: 'Record Not Found, check the card.',
        1636: 'Failed to establish the owner of the card.',
        1654: 'Failed to verify OTP code.',
        2092: 'Unable to pay with this car.',
        2678: 'Exceeded the daily limit on the terminal.'
    }
})
