import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import Page from '~/layouts/main/page'
import res from './res'

import { ButtonDefault, Checkbox, Input, Table, DatePicker } from '~/components'
import styles from './styles.css'
import PaymentSettingsStore from '~/pages/payment-settings/payment-settings-store'
import { PaymentSettingsData } from '~/api'
import BrowserStore from '~/services/browser/browser-state'
import moment from 'moment'
import locale from '~/utils/locale'
import UpcomingDates from './components/UpcomingDates'
import 'rc-calendar/assets/index.css'

const cols = [
    {
        key: 'number'
    },
    {
        key: 'name'
    },
    {
        key: 'price'
    },
    {
        key: 'set_date'
    },
    {
        key: 'active_date'
    },
    {
        key: 'visibility'
    }
]

@observer
export default class PaymentSettings extends React.Component<{ store: PaymentSettingsStore }, {}> {

    public render() {
        const { store } = this.props

        const tableCols = cols.map(col => {
            return { title: res().cols[col.key], ...col }
        })

        const buildDataSet = (dataSource: PaymentSettingsData[]) => {
            return dataSource && dataSource.map((item: PaymentSettingsData, index) => {
                return {
                    number: <span>{index + 1}</span>,
                    name: <span>{locale.lang === 'en' ? item.nameEn : item.nameRu}</span>,
                    price: <><Input className={styles.inputPrice}
                        value={item.price}
                        onChange={(e) => store.changeItem('price', e, index)} /> KZT</>,
                    visibility: <div className={'disabled'}>
                        {item.visibility}
                        <Checkbox
                            checked={item.visibility}
                            disabled={store.activeDay < moment()}
                            onChange={(e) => store.changeItem('visibility', e, index)}>
                            <span> Показать на странице </span>
                        </Checkbox>
                    </div>,
                    set_date: <span>{moment(item.set_date).format('DD.MM.YYYY')}</span>,
                    active_date: <span>{moment(item.active_date).format('DD.MM.YYYY')}</span>
                }
            })
        }

        return (
            <>
                <Page
                    title={
                        <div>
                            <div> {res().title} </div>
                        </div>
                    }
                >
                    <div className={styles.baseControls}>
                        <div className={`${BrowserStore.isMobile ? 'datepicker-mobile' : 'datepicker-desktop'}`}>
                            <DatePicker
                                defaultValue={store.activeDay}
                                onChange={(date) => store.onChange(date)}
                                dateRender={(current, v) => {
                                    let eventClass = ''

                                    const isSet = store.monthData?.find(d =>
                                        moment(d.date).format('YYYY-MM-DD') ===
                                        moment(current).format('YYYY-MM-DD'))
                                    if (isSet) {
                                        eventClass = 'event-date'
                                    }
                                    return (
                                        <div className={`ant-calendar-date ${eventClass}`}>
                                            {current.date()}
                                        </div>
                                    )
                                }}
                            />

                        </div>
                        <div className={`${BrowserStore.isMobile ? styles.saveButtonMobile : styles.saveButtonDesktop}`}>
                            <ButtonDefault
                                type="primary"
                                className="hint-section-6-step-3"
                                onClick={() => store.updateSettings()}
                                disabled={store.activeDay < moment()}
                            >
                                Сохранить стоимость оплаты
                            </ButtonDefault>
                        </div>
                    </div>
                    {/*<div className={styles.days}>*/}
                    {/*    <UpcomingDates store={store} />*/}
                    {/*</div>*/}
                    <div>
                        <Table
                            columns={tableCols}
                            className={'editable-row'}
                            dataSource={buildDataSet(store.data as any)}
                        />
                    </div>
                </Page>
            </>
        )
    }
}

