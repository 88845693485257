import { Language } from '~/utils/locale'
import { MerchantSchemeType } from '../products'

export enum TeamRoles {
    admin = 'admin',
    manager = 'manager',
    accountant = 'accountant',
    view = 'view'
}

export interface TeamMemberStatus {
    active: string
    name: string
    description: string
}

export interface TeamMember {
    id?: string
    lang: Language
    email?: string
    role?: TeamRoles
    lastConnections?: Date
    merchant: any
    merchantId: string
    publicId: string
    status?: TeamMemberStatus
    status_id: string
    updatedAt: string
}

export interface NewTeamMemberRequest {
    email: string
    role: TeamRoles
}

export interface UpdateRoleResponse {
    code: string
    message: string
}

export interface UpdateRoleRequestBody {
    role: TeamRoles
}
// createdAt: "2020-05-12T15:57:55.273452+06:00"
// email: "mukhamejanov.aza@gmail.com"
// id: "9d628b04-4e6a-4fb0-8f6c-b013af43c3b8"
// lang: "en"
// lastConnections: null
// merchant: {id: "1a8ca610-73eb-4996-a2ef-5ec1d57bf573", way4id: "", tradeName: "test", companyName: "test",…}
// merchantId: "1a8ca610-73eb-4996-a2ef-5ec1d57bf573"
// publicId: "e8e6e952-d098-4e1f-99af-eb408e806f17"
// role: "manager"
// status: {id: "54cbcc41-acf2-42d4-b6bf-6f322673eae3", name: "active", description: "Активный"}
// status_id: "54cbcc41-acf2-42d4-b6bf-6f322673eae3"
// updatedAt: "2020-05-12T17:08:42.176241+06:00"
