import res from './res'

res.add('ru', {
    errors: {
        146: 'Пожалуйста, проверьте email, пароль и повторите вход',
        148: 'Пользователь заблокирован, требуется пройти восстановление доступа',
        151: 'Пожалуйста, проверьте email, пароль и повторите вход',
        275: 'Пользователь с данным email уже зарегистрирован',
        267: 'Ваш пароль устарел. Вам необходимо восстановить пароль',
        912: 'Ошибка при поиске пользователя'
    }
})
