import React from 'react'

import { observer } from 'mobx-react'

import { formatMoneySum } from '~/utils'

import { AmountsByCurrencies } from '../../../types'

import res from './res'

import styles from './styles.css'

interface SelectedTransactionsPaneProps {
    store: {
        selectedPaymentCount: number
        selectedPaymentAmounts: AmountsByCurrencies
        clearSelectedPayments: () => void
    }
}

const getAmountsContent = (amountsByCurrencies: AmountsByCurrencies) => {
    const entries = Object.entries(amountsByCurrencies)

    return (
        entries &&
        entries.map(([currency, amount], i) => (
            <span
                key={`amount_by_currency_${currency}`}
                className={styles.amountWithCurrency}
            >
                <span className={styles.amount}>{formatMoneySum(amount)}</span>
                <span className={styles.currency}>{currency}</span>
                {i < entries.length - 1 ? ', ' : ''}
            </span>
        ))
    )
}

@observer
export default class SelectedTransactionsPane<T> extends React.Component<
SelectedTransactionsPaneProps,
{}
> {
    public render() {
        const {
            props: { store }
        } = this

        const isVisible = !!store.selectedPaymentCount

        if (isVisible) {
            this.paymentCount = store.selectedPaymentCount
            this.paymentAmounts = store.selectedPaymentAmounts
        }

        return (
            <div
                className={`${styles.wrapper} ${isVisible ? styles.visible : ''
                    }`}
            >
                <div className={`${styles.pane}`}>
                    {res().selectedPaymentsLabel(
                        this.paymentCount,
                        getAmountsContent(this.paymentAmounts)
                    )}
                    <span
                        className={styles.clearSelection}
                        onClick={store.clearSelectedPayments}
                    >
                        {res().clearButton}
                    </span>
                </div>
            </div>
        )
    }

    private paymentCount: number = 0
    private paymentAmounts: AmountsByCurrencies = {}
}
