import { action, observable, runInAction } from 'mobx'
import { getMenuStructure } from '~/api/main'

import { noThrow } from '~/utils/control-flow'

import { apiCheck, getShops } from '~/api'

const menu = []

export class MenuLoader {
    @observable
    public menuData

    @action.bound
    public async loadData() {

        const shopsResult = await noThrow(apiCheck(getShops()))

        const { value, error } = await noThrow(apiCheck(getMenuStructure()))

        if (shopsResult.value) {

            runInAction(() => {
                this.menuData = value.data
            })

        } else {

            runInAction(() => {
                this.menuData = value.unactivateMerchantMenu
            })

        }

    }
}
