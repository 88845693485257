import res from './res'
import React from 'react'

res.add('en', {
    title: 'Chargeback',
    infoTitle: 'We\'ve added a new feature that allows you to fully customize the chargeback URL and set your own shared secret for increased security.',
    infoText: <>
        <p>You can now specify your own URLs to which refund requests will be sent, giving you more flexibility andcontrol over the refund process. And setting up your own shared secretkey will provide an additional level of security when your application interacts with our system.
        </p>
        <p>A Shared Secret is a secret code that is set by the merchant and used to confirm the authenticity of the chargeback operation. The merchant must include the Shared Secret in every chargeback request. By comparing the merchant's Shared Secret with what is stored by the payment system, it guarantees the legitimacy of chargeback and protects against fraud.</p>
    </>,
    settingTitle: 'Chargeback setting',
    messages: {
        valueRequired: 'Required',
        successUpdate: 'Data updated successfully'
    },
    labels: {
        activeUrl: 'Active url chargeback',
        activeSecret: 'Active shared secret',
        chargebackUrl: 'url chargeback',
        chargebackUrlPlaceholder: 'https://yourwebsite.com/chargeback',
        sharedSecret: 'shared secret',
        sharedSecretPlaceholder: 'Y0urSh@r3dS3cr3tK3y',
        save: 'Save',
        edit: 'Edit'
    }
})
