import React, { useEffect, useState } from 'react'
import styles from '../styles.css'
import { ChromePicker } from 'react-color'
import { motion } from 'framer-motion'
import { runInAction } from 'mobx'
import { Icon } from 'antd'

const CustomColorPicker = (({
    currentColor,
    onChange
}: {
    currentColor: string
    onChange: (color) => void
}) => {
    const [isSelectorExpanded, setIsSelectorExpanded] = useState(false)
    const [color, setColor] = useState(currentColor)

    const handleChange = (color) => {
        const colorToAssign = color.hex

        // tslint:disable-next-line:no-console
        // console.log(color)

        runInAction(() => {
            onChange(colorToAssign)
            setColor(colorToAssign)
        })
    }

    useEffect(() => {
        setColor(currentColor)
    }, [currentColor])

    return (
        <div
            className={styles.customColorWrapper}
        >
            <button
                style={{
                    backgroundColor: color
                }}
                onClick={() => setIsSelectorExpanded(!isSelectorExpanded)}
                className={styles.colorPickerButton}
                type="button"
            />
            <motion.div
                className={styles.customColorPicker}
                style={{ overflow: 'visible', zIndex: 1000, textAlign: 'right' }}
                initial={isSelectorExpanded}
                animate={
                    isSelectorExpanded
                        ? { scale: 1, opacity: 1 }
                        : { scale: 0, opacity: 0.2 }
                }
            >
                <Icon type="close-circle" onClick={() => setIsSelectorExpanded(false)} />
                <ChromePicker
                    disableAlpha={true}
                    color={color}
                    onChange={handleChange}
                // onChangeComplete={handleChangeComplete}
                />
            </motion.div>
        </div>
    )
})

export default CustomColorPicker
