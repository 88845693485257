import { action, observable, runInAction } from 'mobx'
import * as amplitude from 'amplitude-js'

import isEmpty from 'lodash/isEmpty'

import { apiCheck } from '~/api'
import {
    authenticateClient,
    login,
    LoginRequest,
    refreshToken,
    signOut
} from '~/api/auth'

import config from '~/config'

import IdleTimerManager, {
    IdleTimerConfig,
    IdleTimerManagerConfig
} from '~/services/idle-timer/idle-timer-manager'

import { check, FlowResult, noThrow, throwOnFail } from '~/utils/control-flow'

import uuid from 'uuid/v4'

import {
    cleanAuth,
    getAuth,
    getClientAuth,
    isAuthorizationValid,
    isExistActiveInstance,
    isExpired,
    saveAuth,
    saveClientAuth,
    saveUserInfo
} from './auth-utils'

export interface UserInfo {
    userLogin: string
    userHash?: number
}

export class AuthStore {
    constructor() {
        this.instanceDetectionResponder = this.instanceDetectionResponder.bind(
            this
        )
        const idleTimerManagerConfig: IdleTimerManagerConfig = {
            onWarn: this.onIdleTimerWarn,
            onIdle: this.onIdleTimerOut,
            onActive: this.onIdleTimerActive
        }
        const idleTimerConfig: IdleTimerConfig = {
            timeout: config.auth.idleTimeout,
            warnBefore: 1
        }
        this.idleTimeManager = IdleTimerManager.getInstance(
            idleTimerManagerConfig,
            idleTimerConfig
        )
        this.initialize()
    }

    public ThreadId: string = uuid()

    @observable
    public initialized: boolean = false

    @observable
    public isAuthenticated: boolean = false

    @observable
    public showIdleWarning: boolean = false

    public getClient() {
        return getClientAuth()
    }

    public instanceDetectionResponder(event: StorageEvent) {
        if (
            event.key === 'instanceCaller' &&
            event.newValue !== this.ThreadId
        ) {
            localStorage.setItem('instanceWatcher', this.ThreadId)
        }
    }

    public async getUser() {
        if (this.tokenPromise) {
            check(await this.tokenPromise)
        }

        let result = throwOnFail(getAuth, error => {
            this.logout(false)
            return error
        })

        if (isExpired(result)) {
            await this.refreshToken()

            result = throwOnFail(getAuth, error => {
                this.logout(false)
                return error
            })
        }

        return result
    }

    public async login(request: LoginRequest) {
        const promise = noThrow(async () => {
            const expiresAt = new Date().valueOf()
            const response = await apiCheck(login(request))
            const expiresIn = parseInt(response.expires_in, 10)
            await saveUserInfo({ userLogin: request.username })
            amplitude.getInstance().setUserId(request.username)
            amplitude.getInstance().logEvent('user_logged_in')
            await saveAuth({
                data: response,
                expiresAt: expiresAt + expiresIn * 1000
            })
            this.initialize()
        })

        this.tokenPromise = promise

        const result = await promise

        if (this.tokenPromise === promise) {
            this.tokenPromise = undefined
        }

        return result
    }

    public async refreshToken() {
        let promise: Promise<FlowResult<void>>

        if (!this.tokenPromise) {
            promise = noThrow(async () => {
                const auth = getAuth()
                const expiresAt = new Date().valueOf()
                const response = await apiCheck(
                    refreshToken({
                        refresh_token: auth.data.refresh_token
                    })
                )
                const expiresIn = parseInt(response.expires_in, 10)
                if (isEmpty(response.refresh_token)) {
                    response.refresh_token = auth.data.refresh_token
                }
                await saveAuth({
                    data: response,
                    expiresAt: expiresAt + expiresIn * 1000
                })
                runInAction(() => {
                    this.isAuthenticated = true // ???
                })
            })

            this.tokenPromise = promise
        }

        const result = await this.tokenPromise

        if (promise && this.tokenPromise === promise) {
            this.tokenPromise = undefined
        }

        return !result.error
    }

    @action.bound
    public logout(revoke = true) {
        IdleTimerManager.getInstance().stop()
        this.internalLogout(revoke)
        amplitude.getInstance().setUserId(null)
        amplitude.getInstance().regenerateDeviceId()
    }

    @action.bound
    public onProlongSession() {
        this.showIdleWarning = false
        // TODO: why? !!!!!!!!!!!!!!!!!!!
        this.refreshToken()
        IdleTimerManager.getInstance().reset()
    }

    @action.bound
    public onIdleTimerWarn() {
        this.showIdleWarning = true
    }

    @action.bound
    public onIdleTimerActive() {
        IdleTimerManager.getInstance().reset()
    }

    @action.bound
    public onIdleTimerOut() {
        this.internalLogout()
        amplitude.getInstance().setUserId(null)
        amplitude.getInstance().regenerateDeviceId()
        // todo: ???
        // IdleTimerManager.getInstance().stop()
        // this.isAuthenticated = false
    }

    private authenticateClient() {
        noThrow(async () => {
            const appAuth = await apiCheck(authenticateClient())
            await saveClientAuth(appAuth)
        })
    }

    @action
    private initialize() {
        let isWindowRefreshed = false
        if (isEmpty(window.name)) {
            window.name = this.ThreadId
        } else {
            isWindowRefreshed = true
            this.ThreadId = window.name
        }
        window.removeEventListener('storage', this.instanceDetectionResponder)
        window.addEventListener('storage', this.instanceDetectionResponder)
        const isValid = isAuthorizationValid()
        if (isValid && !isWindowRefreshed) {
            noThrow(async () => {
                const isInstanceActive = await isExistActiveInstance(
                    this.ThreadId
                )
                if (isInstanceActive) {
                    this.idleTimeManager.reset()
                    runInAction(() => {
                        this.isAuthenticated = true
                        this.initialized = true
                    })
                } else {
                    this.logout()
                    runInAction(() => {
                        this.isAuthenticated = false
                        this.initialized = true
                    })
                }
                this.authenticateClient() // TODO: remove when bank will fix the issue
            })
        } else {
            if (isValid && isWindowRefreshed) {
                this.isAuthenticated = true
                this.idleTimeManager.reset()
            } else {
                this.isAuthenticated = false
                this.showIdleWarning = false
                this.idleTimeManager.stop()
                this.authenticateClient()
            }
            this.initialized = true
        }
    }

    private internalLogout(revoke = true) {
        noThrow(async () => {
            if (revoke && isAuthorizationValid()) {
                await noThrow(signOut())
            }
            await noThrow(cleanAuth())
            this.initialize()
        })
    }

    private idleTimeManager: IdleTimerManager

    private tokenPromise: Promise<FlowResult<void>>
}
