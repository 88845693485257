    export default {
        api: {
            baseUrl: 'https://epay-api.homebank.kz/'
        },
        auth: {
            baseUrl: 'https://epay-oauth.homebank.kz/',
            client: {
                scope: 'webapi usermanagement email_send verification',
                id: 'test',
                secret: 'yF587AV9Ms94qN2QShFzVR3vFnWkhjbAK3sG',
                storageKey: 'appAuth'
            },
            user: {
                scope: 'webapi usermanagement statement',
                storageKey: 'auth',
                userInfoKey: 'userInfo'
            },
            idleTimeout: 10
        },
        settings: {
                ui: {
                        siderVisibility: 'siderVisibility'
                }
        }
    }
