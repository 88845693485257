import locale from '~/utils/locale'

export default locale.create<{
    title: string
    contracts: string
    contract: string
    sortBy: string
    sortOptions: {
        date: string
        name: string
        contract: string
        type: string
        asc: {
            date: string
            name: string
            contract: string
            type: string
        },
        desc: {
            date: string
            name: string
            contract: string
            type: string
        }
    }
    dataFields: {
        titles: {
            contract: string
            type: string
            daily: string
            weekly: string
            monthly: string
            terminals: string
            name: string
        },
        historyTitles: {
            create: string
            period: string
            emails: string
            initiateType: string
            format: string
            status: string
        }
    }
    errors: {
        statementsLoadingError: string
        statementCreatingError: string
        statementUpdatingError: string
        statementHistoryLoadingError: string
    }
    tooltips: {
        enabledStatus: string
        disabledStatus: string
        noEmails: string
        downloadStatement: string
        statementCreating: string
        reload: string
        statementSettings: string
        statementEmpty: string
    }
    dateRanges: {
        yesterday: string
        priorWeek: string
        priorMonth: string
        customPeriod: string
    }
    initiationType: {
        custom: string,
        auto: string,
        daily: string,
        weekly: string,
        monthly: string
    }
    pageInfo: (info: { from: number, to: number, total: number }) => React.ReactNode
    generateButton: string
    automaticStatements: string
    statementHistory: string
    statementsFor: string
    active: string
    inactive: string
    statement: string
}>()
