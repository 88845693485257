import React from 'react'

import res from './res'

import { PaymentActionEnum } from '../index'
import styles from '../styles.css'

function actionPayments(
    action: string,
    paymentsCount: number,
    amounts: React.ReactNode
) {
    return (
        <>
            <span className={styles.noWrap}>
                {action} {paymentsCount} {getPaymentCountLabel(paymentsCount)}
            </span>
            <br />
            in the amount of {amounts}?
        </>
    )
}

res.add('en', {
    noSelection: 'Select transfers to charge, cancel or refund',
    multiStatusSelection: 'Select transfers with same status to enable actions',
    fullAction: 'Full сharge',
    partialAction: 'Partial сharge',

    chargeAction: 'Full charge',
    chargeFullForm: (paymentsCount, amounts) =>
        actionPayments('Charge', paymentsCount, amounts),
    chargePartialTitle: 'Partial Charge',
    chargePartialDisable: 'Partial charge enabled for one transfer at time',
    chargePartialOkButton: 'Charge',

    cancelAction: 'Cancel',
    cancelForm: (paymentsCount, amounts) =>
        actionPayments('Cancel', paymentsCount, amounts),

    refundAction: 'Full refund',
    refundFullForm: (paymentsCount, amounts) =>
        actionPayments('Refund', paymentsCount, amounts),
    refundPartialTitle: 'Partial Refund',
    refundPartialDisable: 'Partial refund enabled for one transfer at time',
    refundPartialOkButton: 'Refund',

    noActionsForCancelled: (
        <>
            No actions available for transfers with{' '}
            <span className={styles.status}>“Cancelled”</span> status
        </>
    ),
    noActionsForRefunded: (
        <>
            No actions available for transfers with{' '}
            <span className={styles.status}>“Refunded”</span> status
        </>
    ),
    confirmActions: {
        mainText: 'Confirm ',
        simpleText: 'Confirm actions on transfer:',
        simpleCancelText: 'Confirm cancel of transfer:',
        actions: {
            CHARGE: 'charge ',
            PARTIALCHARGE: 'partial charge ',
            CANCEL: 'cancel ',
            REFUND: 'refund ',
            PARTIALREFUND: 'partial refund '
        },
        sumText: 'in the amount of ',
        and: 'and '
    },
    confirmAction: (
        actionType: PaymentActionEnum,
        amount: number,
        partialRefundAmount?: number
    ) => {
        const actionTypes = res().confirmActions.actions
        let confirmLabel = `${res().confirmActions.mainText}
                            ${actionTypes[actionType]}
                            ${res().confirmActions.sumText} ${amount}`
        if (partialRefundAmount) {
            confirmLabel += `${res().confirmActions.and}
            ${res().confirmActions.actions.PARTIALCHARGE}
            ${res().confirmActions.sumText} ${partialRefundAmount}`
        }
        return confirmLabel
    },
    amountForm: {
        paymentAmount: 'Transfer amount',
        chargeAmount: 'Charge amount',
        payoutAmount: 'Refund amount'
    },
    okButton: 'OK',
    cancelButton: 'Cancel',
    partialCancelButton: 'Cancel',
    actionInProgress: 'Action in progress'
})

const getPaymentCountLabel = (paymentsCount: number) => {
    return paymentsCount === 1 ? 'transfer' : 'transfers'
}
