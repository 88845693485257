import locale from '~/utils/locale'

export default locale.create<{
    signupTitle: string
    signupLink: string
    setPasswordTitle: string
    loginTitle: string
    loginLink: string
    passwordRecoveryTitle: string
    signUpAction: string
    setPasswordAction: string
    logInAction: string
    sendAction: string
    cancel: string
    signupEmailPrompt: string
    noActivationEmail: string
    email: string
    password: string
    newPassword: string
    passwordRepeat: string
    passwordRestoreLink: string
    noAccount: string
    hasAccount: string
    didNotReceiveEmail: string
    repeatSignUpLink: string
    emailRequired: string
    passwordRequired: string
    passwordRepeatRequired: string
    passwordsDoNotMatch: string
    successSignUp: string
    passwordRecoveryInfo: string
    accountCreatedTitle: string
    accountCreatedInfo: (email: string) => JSX.Element,
    passwordSetTitle: string
    passwordRecoveredTitle: string
    passwordHelp: {
        title: string
        notContainsErrors: {
            numbers: string
            nonAlphabeticalCharacters: string
            capitalCharacters: string
            lowercaseCharacters: string
            minLength: string
            minLength8: string
        }
    },
    invalidPassword: string
    continue: string
    idleTimerTitle: string
    idleTimerWarning: string
    idleTimerButtonContinue: string
    idleTimerButtonStop: string
    passwordExpired: {
        title: string,
        message: string
    }
}>()
