import React from 'react'

import { observer } from 'mobx-react'

import CardType from '~/constants/card-systems'

import styles from './styles.css'

const PaymentCardIcon = observer(
    ({
        type,
        className
    }: {
        type: CardType | 'goBonus'
        className?: string
    }) => {
        return <div className={`${styles.icon} ${styles[type]} ${className}`} />
    }
)

export default PaymentCardIcon
