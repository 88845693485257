import moment from 'moment'

import { renameProperties } from '~/utils'
import { Content, get, post, put } from '~/utils/ajax'

import strict from '~/utils/strict'
import { ApiResponse } from '../index'
import { CreditOrder, ProductInquiry, ProductRequestStatus, ProductResponse, UpdateCreditOrder } from './contracts'
import config from '~/config'
import amplitude from 'amplitude-js'
import message from '~/utils/message'

export * from './contracts'

export const getProducts = (): ProductResponse => {
    const resultsCount = 10
    const productResponse: ProductResponse = {
        result: [
            {
                status: ProductRequestStatus.approved,
                requestId: Math.round(Math.random() * 1000),
                requestHistory: [
                    {
                        status: ProductRequestStatus.approved,
                        statusDate: moment().toDate(),
                        comment: 'simple comment appr'
                    },
                    {
                        status: ProductRequestStatus.retry,
                        statusDate: moment().toDate(),
                        comment: 'simple comment appr'
                    },
                    {
                        status: ProductRequestStatus.canceled,
                        statusDate: moment().toDate(),
                        comment: 'simple comment appr'
                    }
                ]
            },
            {
                status: ProductRequestStatus.created,
                requestId: Math.round(Math.random() * 1000),
                requestHistory: [
                    {
                        status: ProductRequestStatus.approved,
                        statusDate: moment().toDate(),
                        comment: 'simple comment appr'
                    }]
            },
            {
                status: ProductRequestStatus.returned,
                requestId: Math.round(Math.random() * 1000),
                requestHistory: [
                    {
                        status: ProductRequestStatus.approved,
                        statusDate: moment().toDate(),
                        comment: 'simple comment appr'
                    }]
            }

        ]
    }
    return productResponse
}

export const confirmCreditOrder = async (creditOrder: UpdateCreditOrder) => {
    const { data } = JSON.parse(localStorage.getItem('auth'))

    try {
        const response = await fetch(
            `${config.api.baseUrl}application`,
            {
                method: 'PUT',
                headers: new Headers({
                    Authorization: `Bearer ${data.access_token}`
                }),
                body: JSON.stringify(creditOrder)
            }
        )
        return response
    } catch (error) {
        message.error(error)
        return error
    }
}
