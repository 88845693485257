import res from './res'

res.add('kk', {
    dashboard: 'Статистика',

    /*****************************************/
    transactions: 'Транзакциялар',
    payments: 'ePay төлемдер',
    transfers: 'ePay аударымдар',
    aftTransactions: 'AFT толықтырулар',
    octTransactions: 'Төлемдер',
    cashByCode: 'Cash by code',

    /*****************************************/
    banksProducts: 'Банк өнімдері',

    epay: 'ePay-төлемдер',
    pos: 'POS-төлемдер',
    goBonuses: 'Go!-бонусы',
    orange: 'Orange-төлемдер',
    homebank: 'Homebank',
    QRPayments: 'QR-оплаты',
    credit: 'Бөліп төлеу және несиелеу',

    /*****************************************/
    p2p: 'Транзакциялар',
    oct: 'Төлемдер',

    /*****************************************/
    paymentAft: 'AFT төлемдері',
    aft: 'AFT транзакциялары',


    /*****************************************/

    statements: 'Үзінді көшірмелер',
    payment: 'Төлемдер бойынша',
    transfer: 'Аударымдар бойынша',
    chargeback: 'Chargeback',

    /*****************************************/
    news: 'Жаңалықтар тізімі',

    increaseBonuses: 'Бонустарды көбейту',
    VIPMerchants: 'VIP-коммерсанттарға',
    /*****************************************/
    paymentLinks: 'Төлемге сілтемелер',

    /*****************************************/
    qrCodes: 'QR-кодтар',

    staticQRCode: 'Статический QR-код',
    dynamicQRCodes: 'Динамические QR-коды',
    labelGenerator: 'QR-коды для ценников',

    /*****************************************/
    settings: 'Баптаулар',

    // paymentSystemActivation: 'Активация платежной системы',
    // ePayPaymentSystemActivation: 'Активация платежной системы',
    configureEpayTemplate: 'ePay үлгісін бейімдеу',
    terminalSettings: 'Терминалдардың баптаулары',
    certificates: 'Сертификаттар',
    managingMerchants: 'Саудагерді басқару',
    team: 'Қызметкерлер',
    faq: 'FAQ',
    forDevelopers: 'Разработчикам',
    documentation: 'Құжаттама',
    notifications: 'Хабарламалар',
    profile: 'Профиль',
    logOut: 'Шығу',
    applyButton: 'Сақтау',
    cancelButton: 'Болдырмау',
    setValue: 'мәнді көрсетіңіз',
    orders: 'Менің қолданбаларым',
    currentOrders: 'Ағымдағы қолданбалар',
    createOrder: 'Жаңа өтінімді жіберу',
    paymentSettings: 'Настройка оплаты'
})
