import { computed } from 'mobx'

import { P2PTransfer } from '~/api'

import strict from '~/utils/strict'

import { SortOption } from '../../components/sort-config-select'

import res from '../res'

const options = computed(() => {
    const titles = res().sortOptions

    return strict<
        Array<{ title: string; items: Array<SortOption<P2PTransfer>> }>
    >([
        {
            title: res().sortOptions.date,
            items: [
                {
                    field: 'createdDate',
                    direction: 'desc',
                    title: titles.desc.date
                },
                {
                    field: 'createdDate',
                    direction: 'asc',
                    title: titles.asc.date
                }
            ]
        }
        // {
        //     title: res().sortOptions.postLink,
        //     items: [
        //         {
        //             field: 'postLinkStatus',
        //             direction: 'asc',
        //             title: titles.asc.postLink
        //         },
        //         {
        //             field: 'postLinkStatus',
        //             direction: 'desc',
        //             title: titles.desc.postLink
        //         }
        //     ]
        // },
        // {
        //     title: res().sortOptions.code,
        //     items: [
        //         {
        //             field: 'code',
        //             direction: 'asc',
        //             title: titles.asc.code
        //         },
        //         {
        //             field: 'code',
        //             direction: 'desc',
        //             title: titles.desc.code
        //         }
        //     ]
        // }
    ])
})

export default options
