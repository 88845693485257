import res from './res'

res.add('ru', {
    operators: {
        less: '<',
        lessOrEqual: '≤',
        equals: '',
        greaterOrEqual: '≥',
        greater: '>',
        startsWith: 'начинается с',
        endsWith: 'заканчивается на',
        contains: 'содержит'
    }
})
