import locale from '~/utils/locale'
import React from 'react'

interface Section {
    title: string
    steps: React.ReactNode[]
}

interface Sections {
    generalInformation: Section
    dashboard: Section
    transactions: Section
    statements: Section
    paymentLinks: Section
    credit: Section
    configureEpayTemplate: Section
    terminalSettings: Section
    team: Section
}

interface Modal {
    title: string
    description: string
    okBtn?: string
    cancelBtn?: string
}

export default locale.create<{
    skipBtn: string
    nextSectionBtn: string
    prevSectionBtn: string
    nextBtn: string
    modals: {
        cancelBtn: string
        okBtn: string
        skipAll: Modal
        skipSection: Modal
        congratulation: Modal
        transactionsWarning: Modal
        welcome: Modal
        wantToContinue: {
            title: string
            description: (nameOfSection: string) => string
            okBtn?: string
            cancelBtn?: string
        }
        firstSuccessTransaction: Modal
    }
    sectionsDesktop: Sections
    sectionsMobile: {
        generalInformation: string[]
        dashboard: string[]
        transactions: string[]
        statements: string[]
        paymentLinks: string[]
        credit: string[]
        configureEpayTemplate: string[]
        terminalSettings: string[]
        team: string[]
    }
}>()
