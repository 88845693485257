import React from 'react'

import { observer } from 'mobx-react'

import moment from 'moment'

import { TimelineItem } from '~/api'

import {
    CartesianGrid,
    ChartTooltip,
    Line,
    LineChart,
    ResponsiveContainer,
    XAxis,
    YAxis
} from '~/components'

import SummaryChartTooltip from '../summary-chart-tooltip'

import styles from './styles.css'

import res from './res'

interface SummaryChartProps {
    data: TimelineItem[]
}

interface SummaryTimelineItem extends TimelineItem {
    normCount: number
    normAmount: number
}

const TooltipContent = ({
    payload
}: {
    payload: Array<{ payload: TimelineItem }>
}) => {
    if (!payload || payload.length === 0) {
        return null
    }

    const data = payload[0].payload

    const title = moment(data.time).format(res().dateFormat)

    return (
        <SummaryChartTooltip
            title={title}
            count={data.count}
            amount={data.amount}
        />
    )
}

export default observer((props: SummaryChartProps) => {
    const data = props.data as SummaryTimelineItem[]

    const max = data.reduce(
        (m, stat) => {
            if (m.amount < stat.amount) {
                m.amount = stat.amount
            }
            if (m.count < stat.count) {
                m.count = stat.count
            }
            return m
        },
        { amount: 0, count: 0 }
    )
    const newData = [...data]
    newData.forEach(item => {
        item.normAmount =
            50 + (max.amount === 0 ? 0 : (50 * item.amount) / max.amount)
        item.normCount = max.count === 0 ? 0 : (50 * item.count) / max.count
    })
    const values = (
        <LineChart data={newData}>
            <CartesianGrid strokeDasharray="1 0" stroke="#D8E2EE" />
            <ChartTooltip content={TooltipContent} position={{ y: 80 }} />
            <Line
                type="linear"
                dataKey="normAmount"
                stroke="#00A8FF"
                strokeWidth={2}
                dot={false}
            />
            <Line
                type="linear"
                dataKey="normCount"
                stroke="#05B9A6"
                strokeWidth={2}
                dot={false}
            />
            <XAxis interval={0} hide={true} />
            <YAxis interval={0} hide={true} />
        </LineChart>
    )

    return (
        <div className={styles.container}>
            <ResponsiveContainer width="100%" height="100%">
                {values}
            </ResponsiveContainer>
        </div>
    )
})
