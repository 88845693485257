import locale from '~/utils/locale'

export default locale.create<{
    faqTitle: string
    instructionMerchantAccount: string
    searchPlaceholder: string
    download: string
    messages: {
        required: string
    }
    hintsModal: {
        title: string
        question: string
        all: string
        common: string
        statistics: string
        transactions: string
        terminals: string
        statements: string
        link: string
        banksProducts: string
        credit: string
        settings: string
        template: string
        terminalSettings: string
        team: string
        cancel: string
        start: string
    }
    instructionText(handleClick?: any): JSX.Element
}>()
