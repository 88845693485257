
import { get, post, del, put } from '~/utils/ajax'
import message from '~/utils/message'
import { ApiResponse } from '../index'
import {
    NewTeamMemberRequest,
    TeamMember,
    TeamMemberStatus,
    TeamRoles,
    UpdateRoleRequestBody,
    UpdateRoleResponse
} from './contracts'

export * from './contracts'

export const getMembers = (merchantId: string) =>
    get<TeamMember[]>('staff/list', {
        merchantID: merchantId
    }).then(response => {
        return response
    })

export const inviteNewMember = async (newMemberInfo: NewTeamMemberRequest) => {
    const response = await post<NewTeamMemberRequest>(
        'staff/invite',
        newMemberInfo
    )
    return response
}

export const deleteTeamMember = async (teamMemberPublicId: string) => {
    const response = await del<string>(`staff/${teamMemberPublicId}`)
    return response
}

export const updateRole = async (
    teamMemberPublicId: string,
    updateRoleRequestBody: UpdateRoleRequestBody
) => {
    const response = await put<UpdateRoleResponse>(
        `staff/update-role/${teamMemberPublicId}`,
        updateRoleRequestBody
    )
    return response
}
