import React from 'react'

import res from './res'

res.add('en', {
    title: 'Statistics',
    dateSelectorLabel: 'Statistics for ',
    charts: {
        successfulTransactions: {
            title: 'Successful transactions',
            fieldsSettings:
                'Check the transactions that need to be displayed on the chart'
        },
        issuingBanks: {
            title: 'Issues banks',
            fieldsSettings:
                'Check the transactions that need to be displayed on the chart'
        },
        unsuccessfulTransactions: {
            title: 'Unsuccessful transactions'
        },
        channels: {
            title: 'Channels'
        },
        cartType: {
            title: 'Cart types'
        }
    },
    tooltip: {
        count: 'Quantity',
        cardType: 'Card type',
        sum: 'Sum',
        issuer: 'issuer',
        date: 'Date'
    },
    fieldsSettings: 'Fields customization',
    dateRanges: {
        today: 'today',
        yesterday: 'yesterday',
        thisWeek: 'this week',
        priorWeek: 'prior week',
        thisMonth: 'this month',
        priorMonth: 'prior month',
        thisQuarter: 'this quarter',
        threeMonths: '3 month',
        sixMonths: 'half of year',
        year: 'year',
        customPeriod: 'custom period'
    },
    errors: {
        successfulTransactionLoadError:
            'Successful transactions statistics load error',
        unsuccessfulTransactionLoadError:
            'Unuccessful transactions statistics load error',
        issuesBankLoadError: 'Issues banks statistics load error',
        channelsDataLoadError: 'Channels statistics load error',
        cartTypeLoadLoadError: 'Statistics load error'
    },
    count: 'Count',
    loading: 'Loading',
    noDataLabel: 'No Data'
})
