import React from 'react'
import res from './res'

res.add('kk', {
    title: 'Аударымдар бойынша үзінді көшірмелер',
    contracts: 'Келісімшарттар',
    contract: 'Келісімшарттар',
    sortBy: 'Бойынша сұрыптау:',
    sortOptions: {
        date: 'Соңғы шығарылған күні',
        name: 'Атауы',
        contract: 'Келісімшартпен',
        type: 'Типі',
        asc: {
            date: 'басында ескі',
            name: 'өсуі бойынша',
            contract: 'өсуі бойынша',
            type: 'өсуі бойынша'
        },
        desc: {
            date: 'басында жаңалары',
            name: 'кему бойынша',
            contract: 'кему бойынша',
            type: 'кему бойынша'
        }
    },
    dataFields: {
        titles: {
            contract: 'Келісімшарт',
            type: 'Түрі',
            daily: 'Күн сайын',
            weekly: 'Апта сайын',
            monthly: 'Ай сайын',
            terminals: 'Терминалдар',
            name: 'Атауы'
        },
        historyTitles: {
            create: 'Қалыптастырылды',
            period: 'Кезеңде',
            emails: 'Алушылар',
            initiateType: 'Бастама түрі',
            format: 'Пішім',
            status: 'Мәртебесі'
        }
    },
    dateRanges: {
        yesterday: 'кеше',
        priorWeek: 'өткен апта',
        priorMonth: 'өткен ай',
        customPeriod: 'көрсетілген кезең'
    },
    initiationType: {
        custom: 'Талабы бойынша',
        auto: 'Автоматты',
        daily: 'Күнделікті',
        weekly: 'Апталық',
        monthly: 'Ай сайын'
    },
    errors: {
        statementsLoadingError: 'Үзінді көшірмелерді жүктеу кезіндегі қате',
        statementCreatingError: 'Үзінді көшірмені жасау қатесі',
        statementUpdatingError: 'Үзінді көшірмені жаңарту қатесі',
        statementHistoryLoadingError:
            'Үзінді көшірменің тарихын жүктеу кезіндегі қате'
    },
    tooltips: {
        enabledStatus:
            'Автоматты көшірмелерге арналған Конфигурация жасалды және іске қосылды',
        disabledStatus:
            'Автоматты көшірмелерге арналған Конфигурация жасалды, бірақ іске қосылмаған',
        noEmails: 'Алушылар көрсетілмеген',
        downloadStatement: 'Үзінді көшірмені жүктеу',
        statementCreating: 'Үзінді жасау',
        reload: 'Деректерді жаңарту',
        statementEmpty: 'No Data',
        statementSettings: 'Көшірмелерді баптау'
    },
    pageInfo: (info: { from: number; to: number; total: number }) => (
        <>
            {info.from}-{info.to}
        </>
    ),
    generateButton: 'Қалыптастыру',
    automaticStatements: 'Автоматты үзінділер',
    statementHistory: 'Қалыптастырылған үзінді көшірмелер',
    statementsFor: 'Үзінді',
    active: 'Белсендірілген',
    inactive: 'Белсендірілмеген',
    statement: 'Үзінді көшірме'
})
