import React from 'react'

import moment from 'moment'

export interface FilterValue<T> {
    less?: T,
    lessOrEqual?: T
    equals?: T
    greaterOrEqual?: T
    greater?: T
    startsWith?: T
    endsWith?: T
    contains?: T
    in?: T
}

export type FilterValues<T> = {
    [key in keyof T]?: FilterValue<T[key]>
}

export interface FilterOptionType {
    isValid(str: string): boolean
    parse(str: string): any
}

export type FilterOperator = keyof FilterValue<{}>

export interface FilterOption<T> {
    field: keyof T
    title?: string
    type?: FilterOptionType
    operators: FilterOperator[],
    valueGetter?: (value: any) => any
}

export interface DataFilterProps<T> {
    placeholder?: React.ReactNode
    options: Array<FilterOption<T>>
    values?: FilterValues<T>
    action?: React.ReactNode
    query?: string
}

export const types = {

    string: {
        isValid: s => s.toString().length !== 0,
        parse: s => s.toString().trim()
    },

    number: {
        isValid: s => !isNaN(parseFloat(s)) && isFinite(+s),
        parse: s => +s
    },

    date: {
        isValid: s => moment(s).isValid(),
        parse: s => moment(s)
    },

    boolean: {
        isValid: s => typeof s === 'boolean',
        parse: s => !!(s === 'true')
    }
}
