import React from 'react'

import { action, computed, observable } from 'mobx'

import { observer } from 'mobx-react'

import { FieldsConfigForm, IconButton, Spin } from '~/components/'

import { ParamsIcon } from '~/components/icon'

import { Field } from '../../../types'

import res from '../../../res'

import DashboardStore from '~/pages/dashboard/dashboard-store'
import styles from './styles.css'
import { Empty } from 'antd'

export interface BaseDashboardChartProps {
    className?: string
    chartName?: string
    dashBoardStore?: DashboardStore
    loading?: boolean
    title?: string
    fieldsSettingsSubtitle?: string
}

export default abstract class BaseDashboardChart<
    T extends BaseDashboardChartProps
    > extends React.Component<T, {}> {
    public constructor(props: T) {
        super(props)
        this.initData && this.initData()
    }

    public dashBoardRef: any = null
    public render() {
        const content = (this.renderContent && this.renderContent())
            || <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                className={styles.empty}
                description={res().noDataLabel} />

        return (
            <div
                className={`${styles.container} ${this.props.className || ''}`}
                ref={ref => (this.dashBoardRef = ref)}
            >
                <div className={styles.header}>
                    <div className={styles.title}>{this.props.title}</div>
                    {this.configurable && (
                        <IconButton
                            icon={ParamsIcon}
                            className={styles.menuButton}
                            onClick={() => this.openCustomizeForm()}
                            id={`open-chart-${this.props.chartName}-config-icon`}
                            tooltipTitle={res().fieldsSettings}
                            tooltipPlacement="bottom"
                        />
                    )}
                </div>
                <div className={styles.content}>
                    {this.props.loading && (
                        <div className={styles.loading}>
                            <Spin />
                            &nbsp;&nbsp;&nbsp;{res().loading}
                        </div>
                    )}
                    {!this.props.loading && content}
                </div>
                {this.customizeForm}
            </div>
        )
    }

    protected abstract initData()

    protected abstract renderContent(): React.ReactNode

    @action.bound
    protected openCustomizeForm() {
        this.showCustomizeForm = true
    }

    @action.bound
    protected closeCustomizeGridForm() {
        this.showCustomizeForm = false
    }

    protected get customizeForm() {
        return <this.configContainer />
    }
    protected title: string

    @computed
    protected get showCustomizeForm(): boolean {
        if (this.props.dashBoardStore) {
            const modalConfig = this.props.dashBoardStore.chartConfigsModals.find(
                conf => conf.chartName === this.props.chartName
            )
            return modalConfig ? modalConfig.visible : false
        }
        return this._showCustomizeForm
    }

    protected set showCustomizeForm(value: boolean) {
        this._showCustomizeForm = value
        if (this.props.dashBoardStore) {
            this.props.dashBoardStore.setFormFieldVisibility(
                this.props.chartName,
                value
            )
        }
    }

    protected configurable: boolean = false

    @observable
    protected fieldsConfig: Field[] = []

    protected fieldsSettingsSubtitle: string = ''

    protected _showCustomizeForm: boolean = false

    @action.bound
    private setFieldsVisibility(
        newFields: Array<{ field: string; visible: boolean }>
    ) {
        newFields.forEach(newField => {
            const targetField = this.fieldsConfig.find(
                item => item.field === newField.field
            )

            targetField.visible = newField.visible
        })
    }

    private configContainer = observer(() => {
        if (!this.fieldsConfig) {
            return null
        }

        const values = this.fieldsConfig.map(item => ({ ...item }))

        return (
            <FieldsConfigForm
                title={res().fieldsSettings}
                values={values}
                visible={this.showCustomizeForm}
                onClose={this.closeCustomizeGridForm}
                fieldsSettingsSubtitle={this.props.fieldsSettingsSubtitle}
                onSetFields={this.setFieldsVisibility}
                comtainerRef={this.dashBoardRef}
            />
        )
    })
}
