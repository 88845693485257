import { property } from '~/utils/reflector'
import { OrderListResponse } from '~/api/contracts'

export enum ProductRequestStatus {
    forming = 'forming', // Формирование,
    created = 'created', // Создана,
    processing = 'processing', // - В обработке,
    approved = 'approved', // - Утверждена,
    returned = 'returned', // - Возвращена на доработку
    canceled = 'canceled', // - Отменена
    retry = 'retry' // - повторная подача
}

export interface ProductResponse {
    result: ProductRequest[]
}

export class ProductRequestHistory {
    @property public status: ProductRequestStatus
    @property public statusDate: Date
    @property public comment: string
}

export class ProductRequest {
    @property public requestId: number
    @property public status: ProductRequestStatus
    @property public requestHistory: ProductRequestHistory[]
}

export class MerchantSchemeType {
    @property public type: number
    @property public id: number
    @property public code: string
    @property public name: string
}

export class RiskScheme {
    @property public id: number
    @property public code: string
    @property public name: string
}

export class BranchWhichTraderConnectedIn {
    @property public id: string
    @property public parentId: string
    @property public name: string
    @property public number: string
}
export class Document {
    @property public type: number
    @property public series: string
    @property public seriesAndNumber: string
    @property public issuedBy: string
    @property public birthCountry: string
    @property public birthPlace: string
    @property public residentCountry: string
    @property public residentStatus: string
    @property public number: string
    @property public issueDate: string
    @property public expiryDate: string
}

export class PhoneNumber {
    @property public type: number
    @property public number: string
}

export class RegistrationAddress {
    @property public type: number
    @property public countryCode: string
    @property public zip: string
    @property public regionCode: string
    @property public districtCode: string
    @property public cityCode: string
    @property public townCode: string
    @property public microdistrictCode: string
    @property public street: string
    @property public buildingNumber: string
    @property public apartmentNumber: string
    @property public fullAddress: string
}

export class TraderType {
    @property public code: string
    @property public id: string
    @property public longname: string
}

export class ServicesAndTariffs {
    @property public recommendedLimit: string
    @property public dailyLimit: string
    @property public recommendedDeadline: string
    @property public eTraderDeadline: string
    @property public visa: number
    @property public mc: number
    @property public upi: number
    @property public amEx: number
    @property public discover: number
    @property public jcb: number
    @property public diners: number
    @property public visaOfBank: number
    @property public mcOfBank: number
    @property public upiOfBank: number
    @property public amExOfBank: number
    @property public electronOfBank: number
    @property public maestroOfBank: number
}

export class ConnectingProducts {
    @property public ePay: boolean
    @property public homeBank: boolean
    @property public homeBankOrange: boolean
    @property public p2P: boolean
    @property public mVisa: boolean
    @property public homeShop: boolean
    @property public connectingHomeBankOrangeToEPay: boolean
    @property public connectingMVisaToEPay: boolean
}

export class LoyaltyGo {
    @property public constantGoBonusOneThreePercentWithoutLimits: number
    @property public constantGoBonusMoreThanThreePercentWithoutLimits: number
    @property public constantAmexBonusOneFivePercentWithoutLimits: number
    @property public constantAmexBonusMoreThanFivePercentWithoutLimits: number
    @property public individualConditions: number
    @property public periodOfCampaign: number
    @property public goAgreementSigned: boolean
}

export class InstallmentPlan {
    @property public traderIsAgreedToConnect: boolean
    @property public agreementIsSigned: boolean
    @property public tariffForThreeMonth: number
    @property public tariffForSixMonth: number
    @property public tariffForNineMonth: number
    @property public tariffForTwelveMonth: number
}

export class SigningAuthorizedPerson {
    @property public login: string
    @property public name: string
    @property public attributeId: string
    @property public attributeName: string
    @property public attorneyNumber: string
    @property public attorneyDate: string
    @property public barnch: string
    @property public type: string
}

export class Point {
    @property public connectingProducts: ConnectingProducts = new ConnectingProducts()
    @property public agent: string
    @property public nameOfShop: string
    @property public urlOfShop: string
    @property public emailOfShop: string
    @property public workingSince: string
    @property public mainActivity: string
    @property public goodsDescription: string
    @property public deliverySchemeDescription: string
    @property public turnoversForLast6Months: string
    @property public estimateMonthsVolumeTransactions: string
    @property public estimateMonthsAmontTransactions: string
    @property public isServicesCardHolders: boolean
    @property public isLicensedActivities: boolean
    @property public licensedActivitiesName: string
    @property public orientedRegions: string
    @property public servicesAndTariffs: ServicesAndTariffs = new ServicesAndTariffs()
    @property public branchWhichTraderConnectedIn: BranchWhichTraderConnectedIn = new BranchWhichTraderConnectedIn()
    @property public name: string
    @property public nameLt: string
    @property public riskScheme: RiskScheme = new RiskScheme()
    @property public clientMcc: RiskScheme = new RiskScheme()
    @property public posTerminalType: RiskScheme = new RiskScheme()
    @property public merchantSchemeType: MerchantSchemeType = new MerchantSchemeType()
    @property public bankInfo: BankInfo = new BankInfo()
    @property public bik: string
    @property public accountNumberInTheBank: string
    @property public installmentPlan: InstallmentPlan = new InstallmentPlan()
    @property public loyaltyGo: LoyaltyGo = new LoyaltyGo()
}

export class Contract {
    @property public contractNumber: string
    // tslint:disable-next-line:array-type
    @property public points: Array<Point> = []
}

export class BankInfo {
    @property public departmentID: string
    @property public departmentName: string
    @property public departmentAdress: string
    @property public departmentPhone: string
    @property public departmentNumber: string
    @property public departmentAuthorisedPerson: string
    @property public departmentVillage: string
    @property public departmentIP: string
    @property public departmentAdressKZ: string
    @property public departmentNameKZ: string
    @property public departmentVillageKZ: string
    @property public userName: string
    @property public userSurname: string
    @property public userMail: string
    @property public branchID: string
    @property public branchName: string
    @property public branchAdress: string
    @property public branchAuthorisedPerson: string
    @property public branchColvirNumber: string
    @property public branchRNN: string
    @property public branchAdressKZ: string
    @property public branchNameKZ: string
    @property public branchVillage: string
    @property public branchVillageKZ: string
    @property public branchNumber: string
    @property public branchIP: string
    @property public branchBranch: string
    @property public branchBIK: string
    @property public branchBIN: string
    @property public signingAuthorizedPerson: SigningAuthorizedPerson = new SigningAuthorizedPerson()
    @property public decisionMakerAuthorizedPerson: SigningAuthorizedPerson = new SigningAuthorizedPerson()
    @property public authorizedPersonComment: string
}

export class CeoShortInfo {
    @property public registrationAddress: RegistrationAddress = new RegistrationAddress()
    @property public residenceAddress: RegistrationAddress = new RegistrationAddress()
    // tslint:disable-next-line:array-type
    @property public phoneNumbers: Array<PhoneNumber> = []
    @property public iin: string
    @property public firstName: string
    @property public lastName: string
    @property public middleName: string
    @property public fullName: string
    @property public shortName: string
    @property public birthDateString: string
    @property public birthDate: string
    @property public document: Document = new Document()
    @property public isNotResident: boolean
    @property public citizenship: string
}

export class ProductInquiry {

    @property public ceoShortInfo: CeoShortInfo = new CeoShortInfo()
    @property public chiefAccountantShortInfo: CeoShortInfo = new CeoShortInfo()
    @property public serviceBank: string = 'test'
    @property public servicePeriodOfBank: string = 'test bank period'
    @property public hasCredits: boolean = undefined
    @property public contactPhone: string = '123321321'
    @property public iinBin: string = '123321321'
    // tslint:disable-next-line:array-type
    @property public contracts: Array<Contract> = []
    @property public email: string = undefined
    @property public traderType: TraderType = new TraderType()
    @property public traderName: string = undefined
    @property public traderNameLt: string = undefined
    @property public mainActivity: string = undefined
    @property public createdDate: string = undefined
    @property public registerCompanyAddress: RegistrationAddress = new RegistrationAddress()
    @property public realCompanyAddress: RegistrationAddress = new RegistrationAddress()
    @property public registerPhone: string = undefined
    @property public registerFax: string = undefined
    @property public realPhone: string = undefined
    @property public realFax: string = undefined
    @property public premisesOwnership: string = undefined
    @property public lessorName: string = undefined
    @property public leaseTerm: string = undefined
    @property public hasEncumbranceOnProperty: boolean = undefined
    @property public cheifAccountantDoesNotExists: boolean = undefined
}

export interface CreditOrder {
    id: string,
    createdAt?: string,
    shopIds: any[],
    emails: any[],
    statusId?: string,
    statusName?: string,
    moderatorComment: string,
    accountVerified?: boolean,
    verifiedBy?: string,
    number?: string,
    parentId?: string,
    checkingAccount?: string,
    creditConditions?: any[],
    shops: Shop[],
    merchantName: string,
    itemRenderer?: void
}

export interface UpdateCreditOrder {
    id: string,
    createdAt?: string,
    shops?: Shop[],
    shopIds: any[],
    emails: any[],
    statusId?: string,
    statusName?: string,
    moderatorComment: string,
    accountVerified?: boolean,
    verifiedBy?: string,
    number?: string,
    parentId?: string,
    checkingAccount?: string,
    creditConditions?: any[],
    merchantName: string
}


export interface Shop {
    id?: string,
    creditConditions: any[],
    shopName: string
}


export enum CreditOrderStatus {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    MERCHANT_CONFIRMATION = 'MERCHANT_CONFIRMATION',
    ACCEPT = 'ACCEPT',
    REJECT = 'REJECT'
}


export type OrderResponse = OrderListResponse<CreditOrder>
