import styles from '../../styles.css'
import res from '../../res'
import { Col, Modal, Radio, Row } from 'antd'
import { ButtonDefault } from '~/components'
import React from 'react'
import { observer } from 'mobx-react'
import FaqStore from '../../faq-store'
import BrowserStore from '~/services/browser/browser-state'
import AppStore from '~/app-store'
import { RadioMiniGroup } from '../../components/radio-mini-group'
import { sectionsOrder } from '~/components/interactive-hints/constants'

const ALL_HINTS = 'all'

const HintsModal = observer((
    { store, appStore }: { store: FaqStore, appStore: typeof AppStore }
) => {
    return (
        <Modal
            visible={store.isVisibleHintsModal}
            onCancel={() => store.hideHintModal()}
            // centered
            footer={null}
            className={styles.hintModal}
            width={BrowserStore.windowWidth > 980 ? 900 : '80%'}
        >
            <h2 className={styles.modalTitle}>{res().hintsModal.title}</h2>
            <p className={styles.modalQuestion}>{res().hintsModal.question}</p>
            <Radio.Group
                onChange={(e) => store.setInteractiveHint(e.target.value)}
                className={styles.radioGroup}
                defaultValue={store.interactiveHint}
            >
                <Row>
                    <Col md={12} sm={24} xs={24} className={styles.radioGroupCol}>
                        <Radio value={ALL_HINTS} className={styles.radioBtn}>{res().hintsModal.all}</Radio>
                        <Radio value="generalInformation" className={styles.radioBtn}>{res().hintsModal.common}</Radio>
                        <Radio value="dashboard" className={styles.radioBtn}>{res().hintsModal.statistics}</Radio>
                        <RadioMiniGroup title={res().hintsModal.transactions}
                            value="transactions"
                            currentValue={store.interactiveHint}
                            items={[
                                { title: res().hintsModal.terminals, value: 'transactions' }
                            ]} />
                    </Col>
                    <Col md={12} sm={24} xs={24} className={styles.radioGroupCol}>
                        <Radio value="statements" className={styles.radioBtn}>{res().hintsModal.statements}</Radio>
                        <Radio value="paymentLinks" className={styles.radioBtn}>{res().hintsModal.link}</Radio>
                        <RadioMiniGroup title={res().hintsModal.banksProducts}
                            value="credit"
                            currentValue={store.interactiveHint}
                            items={[
                                { title: res().hintsModal.credit, value: 'credit' }
                            ]} />
                        <RadioMiniGroup title={res().hintsModal.settings}
                            value="settings"
                            currentValue={store.interactiveHint}
                            items={[
                                { title: res().hintsModal.template, value: 'configureEpayTemplate' },
                                { title: res().hintsModal.terminalSettings, value: 'terminalSettings' },
                                { title: res().hintsModal.team, value: 'team' }
                            ]} />
                    </Col>
                </Row>
            </Radio.Group>
            <div className={styles.modalFooter}>
                <ButtonDefault
                    onClick={() => store.hideHintModal()}
                    type="default"
                    className={styles.footerBtn}
                >
                    {res().hintsModal.cancel}
                </ButtonDefault>
                <ButtonDefault
                    type="primary"
                    onClick={() => {
                        store.hideHintModal()
                        if (store.interactiveHint === ALL_HINTS) {
                            return appStore.showInteractiveHintsFromFAQ(sectionsOrder)
                        } else if (store.interactiveHint === 'settings') {
                            return appStore.showInteractiveHintsFromFAQ(['configureEpayTemplate', 'terminalSettings', 'team'])
                        }
                        appStore.showInteractiveHintsFromFAQ([store.interactiveHint])
                    }}
                    className={styles.footerBtn}
                    disabled={store.interactiveHint === null}
                >
                    {res().hintsModal.start}
                </ButtonDefault>
            </div>
        </Modal>
    )
})

export default HintsModal