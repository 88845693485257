import { computed } from 'mobx'

import { EpayPayment } from '~/api'

import { FilterOption, types } from '~/components/data-filter'

import strict from '~/utils/strict'

import res from '../res'

const currency = {
    isValid: (str: string) => /^[A-Za-z]{1,3}$/.test(str),
    parse: (str: string) => str.toUpperCase()
}

const options = computed(() => {

    const titles = res().dataFields.short

    return strict<Array<FilterOption<EpayPayment> & { fullOnly?: boolean }>>([
        {
            field: 'invoiceId',
            title: titles.invoiceId,
            type: types.string,
            operators: ['endsWith']
        },
        {
            field: 'payerPhone',
            title: titles.payerPhone,
            operators: ['endsWith']
        },
        {
            field: 'payerEmail',
            title: titles.payerEmail,
            operators: ['startsWith']
        },
        {
            field: 'cardMask',
            title: titles.cardMask,
            type: types.number,
            operators: ['startsWith', 'endsWith']
        },
        {
            field: 'payerName',
            title: titles.payerName,
            operators: ['startsWith']
        },
        {
            field: 'shop',
            title: titles.shop,
            type: types.string,
            operators: ['equals']
        },
        {
            field: 'amount',
            title: titles.amount,
            type: types.number,
            operators: ['lessOrEqual', 'greaterOrEqual']
        },
        {
            field: 'currency',
            title: titles.currency,
            type: currency,
            operators: ['equals']
        },
        {
            field: 'invoiceIdAlt',
            title: titles.invoiceIdAlt,
            type: types.string,
            operators: ['equals']
        },
        {
            field: 'reference',
            title: titles.reference,
            type: types.string,
            operators: ['equals']
        },
        {
            field: 'paymentType',
            title: titles.paymentType,
            type: types.string,
            operators: ['equals']
        },
        {
            field: 'accountId',
            title: titles.accountId,
            operators: ['equals']
        },
        {
            field: 'postLinkStatus',
            title: titles.postLinkStatus,
            operators: ['equals'],
            valueGetter: (value: string) => {
                const posibleValues = [
                    { value: true, comparison: ['успешно', 'да', 'есть', 'success', 'exist', 'true', 'yes'] },
                    { value: false, comparison: ['неуспешно', 'нет', 'ошибка', 'error', 'failed', 'not exist', 'false', 'no'] }
                ]

                const existedValue = posibleValues.find(pValue => pValue.comparison.includes(value.toLocaleLowerCase()))
                return existedValue ? existedValue.value : undefined
            }

        },
        {
            field: 'payoutDate',
            title: titles.payoutDate,
            type: types.date,
            operators: ['greaterOrEqual', 'lessOrEqual']
        },
        {
            field: 'payoutAmount',
            title: titles.payoutAmount,
            type: types.number,
            operators: ['lessOrEqual', 'greaterOrEqual']
        },
        {
            field: 'installment_id',
            title: titles.installment_id,
            type: types.string,
            operators: ['in']
        },
        {
            field: 'approvalCode',
            title: titles.approvalCode,
            type: types.string,
            operators: ['equals']
        },
        {
            field: 'qrReference',
            title: titles.qrReference,
            type: types.string,
            operators: ['equals']
        }
    ])
})

export default options
