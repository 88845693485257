import React, { CSSProperties } from 'react'

import { Button, Modal, Progress } from '~/components'

import res from '../res'

import styles from '~/layouts/auth/styles.css'
import stylesModal from './styles.css'

const defaultState = {
    countDown: 58,
    visible: false,
    countDownStarted: false
}

export default class ModalIdleWarning extends React.Component<any, any> {
    public static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.visible !== prevState.visible) {
            if (nextProps.visible && !prevState.countDownStarted) {
                return { countDown: 58, visible: true }
            } else if (nextProps.visible === false) {
                return { ...defaultState }
            }
        }
        return null
    }

    constructor(props, state) {
        super(props, state)
        this.onOk = this.onOk.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.resetCountDown = this.resetCountDown.bind(this)
        this.stop = this.stop.bind(this)
    }

    public state = { ...defaultState }

    public stop() {
        clearInterval(this.interval)
        if (this.props.onCountDownComplete) {
            this.props.onCountDownComplete()
        }
    }
    public resetCountDown(started: boolean = false) {
        if (started === false) {
            clearInterval(this.interval)
        }
        this.setState({
            ...defaultState,
            visible: started,
            countDownStarted: started
        })
    }
    public onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel()
        }
    }
    public onOk() {
        this.resetCountDown()
        if (this.props.onOk) {
            this.props.onOk()
        }
    }

    public componentDidUpdate() {
        const self = this
        if (this.state.visible === true && !this.state.countDownStarted) {
            if (this.interval) clearInterval(this.interval)
            this.interval = setInterval(() => {
                self.state.countDown > 0
                    ? self.setState({ countDown: self.state.countDown - 1 })
                    : self.stop()
            }, 1000)
            this.resetCountDown(true)
        }
    }

    public render() {
        const percentFromSeconds: number = Math.ceil(
            100 - (this.state.countDown - 1) / (60 / 100)
        )
        return (
            <Modal
                centered
                closable={false}
                visible={this.state.visible}
                footer={null}
                title={null}
                width={290}
                zIndex={2000}
            >
                <div className={stylesModal.idleTimerModal}>
                    <div className={stylesModal.idleTimerTitle}>
                        {res().idleTimerTitle}
                    </div>
                    <div className={stylesModal.header}>
                        {res().idleTimerWarning}
                    </div>
                    <div className={stylesModal.countDown}>
                        <Progress
                            type="circle"
                            strokeLinecap="square"
                            percent={percentFromSeconds}
                            format={percent => `${this.state.countDown}`}
                        />
                    </div>
                    <div></div>
                    <div>
                        <Button
                            onClick={this.onOk}
                            type="primary"
                            className={stylesModal.actionButon}
                            block
                        >
                            {res().idleTimerButtonContinue}
                        </Button>
                    </div>
                    <div>
                        <Button
                            onClick={this.onCancel}
                            type="default"
                            block
                            className={stylesModal.actionButon}
                            style={{ borderColor: 'transparent' }}
                        >
                            {res().idleTimerButtonStop}
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    }
    private interval: any
}
