import React, { useEffect } from 'react'

import { observer } from 'mobx-react'

import { Menu } from 'antd'

import AppStore from '~/app-store'

import auth from '~/services/auth'

import { NavLink } from '~/components/'
import * as icons from '~/components/icon'
import layoutStore from '~/layouts/main/layout-store'
import res from './res'

import { camelCaseToKebabCase } from '~/utils'

// import menuLoader from './main-menu-items'

import styles from './main-menu.css'
import { action } from 'mobx'
import { getProfile } from '~/api'

const SubMenu = Menu.SubMenu
const MenuItemGroup = Menu.ItemGroup
const MenuItem = Menu.Item

const MainMenu = observer(
    (props: { layoutStore: typeof layoutStore; appStore: typeof AppStore }) => {
        const renderGroup = (group, index: number) => {
            const allowedGroupMenuItems = auth.isAuthenticated
                ? group.items
                : group.items.filter(menuItem => menuItem.anonymous === true)
            return (
                <MenuItemGroup key={`group_${index}`}>
                    {allowedGroupMenuItems.map((item, i) => renderItem(item))}
                </MenuItemGroup>
            )
        }

        const profile = AppStore.profile

        const renderItem = item => {

            const IconComponent = icons[item.icon]
            const icon = IconComponent ? (
                <IconComponent className={styles.icon} />
            ) : null

            if (item.id === 'paymentSettings' && profile && !profile?.merchant?.is_delivery_settings_enabled) {
                return false
            }

            if (!item.items) {
                return renderSubItem(item.id, item.ready, icon, null, item.hint_class)
            }

            return (
                <SubMenu
                    key={`sub_menu_${item.id}`}
                    title={
                        <span>
                            {icon}
                            {res()[item.id]}
                        </span>
                    }
                    className={item.hint_class}
                >
                    {item.items.map(subItem =>
                        renderSubItem(
                            subItem,
                            item.ready.includes(subItem),
                            null,
                            item.id
                        )
                    )}
                </SubMenu>
            )
        }

        const renderSubItem = (
            itemId: string,
            isReady: boolean,
            icon?: JSX.Element,
            parentId?: string,
            hintClass?: string
        ) => {
            const href = camelCaseToKebabCase(
                `/${parentId ? parentId + '/' : ''}${itemId}`
            )

            return (
                <MenuItem key={`item_${itemId}`} className={hintClass}>
                    <NavLink
                        href={href}
                        activeClassName={styles.active}
                        className={!isReady ? styles.notReady : ''}
                    >
                        {icon || null}
                        {res()[itemId]}
                    </NavLink>
                </MenuItem>
            )
        }
        let items = []

        if (props.appStore.menuData) {
            items = props.appStore.menuData
        } else {
            return <div />
        }

        const allowedMenuItems = auth.isAuthenticated
            ? items.filter(menuItem => !menuItem.disabled)
            : items.filter(menuItem => menuItem.anonymous === true)
        return (
            <div>
                <Menu
                    mode="inline"
                    className={`${styles.mainMenu} hint-section-1-step-1`}
                    openKeys={[...layoutStore.openMainMenuKeys]}
                    onOpenChange={(keys: string[]) => {
                        layoutStore.toggleSubMenu(keys)
                    }}
                    selectedKeys={['item_1']}
                >
                    {allowedMenuItems.map((item, i) => renderGroup(item, i))}
                </Menu>
            </div>
        )
    }
)

export default MainMenu
