import { computed } from 'mobx'

import { CashByCodePayment } from '~/api'

import { FilterOption, types } from '~/components/data-filter'

import strict from '~/utils/strict'

import res from '../res'

const options = computed(() => {

    const titles = res().dataFields.short

    return strict<Array<FilterOption<CashByCodePayment> & { fullOnly?: boolean }>>([
        {
            field: 'invoiceId',
            title: titles.invoiceId,
            type: types.string,
            operators: ['endsWith']
        },
        {
            field: 'terminalID',
            title: titles.terminalID,
            operators: ['startsWith']
        },
        {
            field: 'epayTerminalID',
            title: titles.epayTerminalID,
            operators: ['equals']
        },
        {
            field: 'client',
            title: titles.client,
            type: types.string,
            operators: ['equals']
        },
        {
            field: 'amount',
            title: titles.amount,
            type: types.number,
            operators: ['lessOrEqual', 'greaterOrEqual']
        },
        {
            field: 'reference',
            title: titles.reference,
            type: types.string,
            operators: ['equals']
        },
        {
            field: 'cardMask',
            title: titles.cardMask,
            type: types.number,
            operators: ['startsWith', 'endsWith']
        },
        {
            field: 'destinationPhone',
            title: titles.destinationPhone,
            operators: ['endsWith']
        },
        {
            field: 'sourcePhone',
            title: titles.sourcePhone,
            operators: ['endsWith']
        },
        {
            field: 'receiverPhone',
            title: titles.receiverPhone,
            operators: ['endsWith']
        },
        {
            field: 'isPostLink',
            title: titles.isPostLink,
            operators: ['equals'],
            valueGetter: (value: string) => {
                const posibleValues = [
                    { value: true, comparison: ['успешно', 'да', 'есть', 'success', 'exist', 'true', 'yes'] },
                    { value: false, comparison: ['неуспешно', 'нет', 'ошибка', 'error', 'failed', 'not exist', 'false', 'no'] }
                ]

                const existedValue = posibleValues.find(pValue => pValue.comparison.includes(value.toLocaleLowerCase()))
                return existedValue ? existedValue.value : undefined
            }
        },
        {
            field: 'authDate',
            title: titles.authDate,
            type: types.date,
            operators: ['greaterOrEqual', 'lessOrEqual']
        },
        {
            field: 'postingDate',
            title: titles.postingDate,
            type: types.date,
            operators: ['greaterOrEqual', 'lessOrEqual']
        }
    ])
})

export default options
