import { action, observable, runInAction } from 'mobx'
import { apiCheck } from '~/api'
import { getDocumentaionStructure, getFaq, getSwagger, Question, Swagger } from '~/api/faq'
import { noThrow } from '~/utils/control-flow'
import message from '~/utils/message'
import { DocumentNodeInterface } from './document-node'
import res from './res'

const findInChilds = (node: DocumentNodeInterface, childNodeId: string, parents?: DocumentNodeInterface[]): { targetNode: DocumentNodeInterface, parents: DocumentNodeInterface[] } => {
    let result = { targetNode: undefined, parents }
    const subNode = node.childNodes ? node.childNodes.find(childNode => childNode.nodeId === childNodeId) : undefined
    if (!subNode && node.childNodes) {
        node.childNodes.forEach(childNode => {
            const subResult = findInChilds(childNode, childNodeId, parents)
            if (subResult.targetNode) {
                result = subResult
            }
        })
    } else {
        if (subNode) {
            result.targetNode = subNode
            result.parents = parents ? [...parents, node] : [node]
        }
    }
    return result
}

export default class DocumentationStore {

    constructor(nodeId?: string) {
        if (nodeId) {
            this.nodeId = nodeId
        }
        this.loadData()
    }

    @observable
    public data: DocumentNodeInterface

    @observable
    public node: { targetNode: DocumentNodeInterface, parents: DocumentNodeInterface[] }

    @observable
    public nodeId: string

    @observable
    public sendingQuestion: boolean = false

    @observable
    public swagerData: Swagger

    @action.bound
    public setDocumentNode(nodeId?: string) {
        const searchedNode = this.data ? findInChilds(this.data, nodeId) : undefined
        if (searchedNode && searchedNode.targetNode && searchedNode.targetNode.nodeType === 'swaggerDocument') {
            this.loadSwagger()
        }
        this.node = searchedNode
        this.nodeId = nodeId ? nodeId : undefined
    }

    @action.bound
    public async loadSwagger() {
        const { value, error } = await noThrow(apiCheck(getSwagger()))

        if (value) {
            runInAction(() => this.swagerData = value)
        }
    }

    @action.bound
    public async loadData() {
        const { value, error } = await noThrow(apiCheck(getDocumentaionStructure()))

        if (value) {
            runInAction(() => {
                this.data = value
                if (this.nodeId) {
                    this.setDocumentNode(this.nodeId)
                }
            })
        }

    }

}
