import { action, computed, observable, runInAction } from 'mobx'

import { apiCheck, recoverPassword, signUp } from '~/api'

import { noThrow } from '~/utils/control-flow'
import FieldStore, { isValidArr } from '~/utils/field-store'
import { userError } from '~/utils/user-error'
import { email, required } from '~/utils/validation'

import res from '../res'
import message from '~/utils/message'

export default class PasswordRecoveryStore {
    constructor(
        public passwordExpired?: string
    ) {
        if (!!passwordExpired) {
            message.warn(res().passwordExpired.message, res().passwordExpired.title)
        }
    }

    public email = new FieldStore<string>([required(() => res().emailRequired), email])

    @observable
    public isSending = false

    @observable
    public error: Error

    @observable
    public isRecovered = false

    @computed
    public get errorMessage(): string {
        return userError(this.error).message
    }

    @action
    public async recover() {

        if (!isValidArr(this.email)) return

        this.isSending = true

        const result = await noThrow(apiCheck(recoverPassword({
            email: this.email.value
        })))

        runInAction(() => {
            this.isSending = false
            this.isRecovered = !result.error
            if (!result.error) return
            this.error = result.error
        })
    }
}
