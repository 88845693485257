import { observer } from 'mobx-react'
import React from 'react'
import { UnderConstuctionIcon } from '~/components/icon'
import Page from '~/layouts/main/page'
import res from './res'
import styles from './styles.css'

const UnderConstuction = observer(() => {
    return (
        <Page>
            <div className={styles.underConstruction}>
                <UnderConstuctionIcon
                    className={styles.underConstructionLabel}
                />
                <div className={styles.title}>{res().title}</div>
                <div className={styles.description}>{res().description}</div>
            </div>
        </Page>
    )
})

export default UnderConstuction
