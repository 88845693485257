import res from './res'

res.add('ru', {
    title: 'Заявка на подключение к интернет-эквайрингу EPAY',
    buttons: {
        download: 'Скачать заявку'
    },
    labels: {
        bin: 'БИН/ИНН',
        bin_placeholder: '012345678901',
        check: 'Проверить',
        city: 'В каком городе обслуживаетесь',
        contact_faces: 'Контактные лица',
        full_name: 'ФИО',
        emails: 'Email:',
        continue: 'Сохранить и продолжить',
        back: 'Вернуться назад',
        finish: 'Завершить',
        description: 'Описание интернет-магазина',
        url: 'Ссылка на сайт интернет магазина или моб. приложения',
        shop_name: 'Наименование интернет-магазина',
        establishment_date: 'Магазин открыт более 6 месяцев?',
        shop_description: 'Описание товаров/услуг, реализуемых (предполагаемых к реализации) через Интернет-магазин',
        name: 'Имя',
        surname: 'Фамилия',
        middlename: 'Отчество',
        phone: 'Телефон'
    },
    subtitles: {
        service_bank: 'Банк обслуживания',
        tariff: 'Выберите тариф'
    },
    messages: {
        required: 'Обязательно',
        notFoundTitle: 'БИН не найден в базе налоговой РК',
        notFoundDescription: 'Проверьте правильность написания БИН.',
        warningRequires: 'Пожалуйста заполните обязательные поля.',
        validateFile: 'Пожалуйста загрузите в формате PDF',
        validateFileSize: 'Файл должен быть меньше 2МБ'
    },
    documents: {
        stateRegister: 'Cвидетельства о государственной регистрации / талон о приёме',
        statute: 'Устав',
        decision: 'Решение о назначении первого руководителя/директора',
        identityCardAccountant: 'Удостоверение личности / копия паспорта бухгалетра (При наличии, в формате PDF)',
        identityCardLeader: 'Удостоверение личности первого руководителя (В формате PDF)',
        requisites: 'Реквизиты банковского счета',
        leaderSignatureToo: 'Карточка с подписью руководителя (Если в карточке указано нескольно людей необходимо прикладывать их удостоверение личности и доверенность на право подписи)',
        leaderSignatureIp: 'Карточка с подписью руководителя',
        constituentDocuments: 'Учредительные документы (Учредительный договор)',
        powerOfAttorney: 'Доверенность на подписанта (При наличии)',
        licensePaymentServices: 'Копия лицензии на платёжные услуги / Учётная регистрация'
    },
    tooltips: {
        stateRegister: 'Справка о государственной регистрации (перерегистрации) юридического лица, ' +
            'также справка об учетной регистрации (перерегистрации) филиала (представительства) юридического лица. E-gov: https://egov.kz/cms/ru/services/e_084',
        statute: 'Устав с отметкой органа юстиции о регистрации, документ, подтверждающий юридический адрес',
        decision: 'Решение уполномоченного органа юридического лица/приказ о назначении руководителя организации',
        identityCardAccountant: 'Документ, удостоверяющий личность бухгалтера (если предусмотрен)',
        identityCardLeader: 'Документ, удостоверяющий личность руководителя ',
        requisites: 'Банковские реквизиты содержат: БИК (Банковский Идентификационный Код), БИН, IBAN номера или ИИК (Корреспондентский счет в НБ РК), РНН (Регистрационный номер налогоплательщиков), КБе, ОКПО, номер и дата выдачи лицензий банков',
        leaderSignature: 'Карточка с подписью руководителя и оттиска его печати (если печати нет, прописывается знак «Б/П»)',
        constituentDocuments: 'В учредительные документы входят: учредительный договор + изменения, касающиеся организации (как пример: смена фактического и (или) юридического адреса)',
        powerOfAttorney: 'Документы, подтверждающие полномочия должностного (-ых) лица (лиц) на совершение действий от имени Предприятия, в том числе на подписание документов юридического лица на совершение операций с деньгами и (или) иным имуществом.',
        licensePaymentServices: 'Копия лицензии на платёжные услуги / Учётная регистрация'
    }
})
