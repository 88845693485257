import React from 'react'

import { observer } from 'mobx-react'

import { RangePickerValue } from 'antd/lib/date-picker/interface'

import { DatePicker, RangePopover } from '~/components'
import { RangePopoverValue } from '~/components/range-popover'

import res from '~/res'

const { RangePicker } = DatePicker

interface StatusSelectProps {
    presets?: RangePopoverValue[]
    value?: RangePopoverValue
    onChange?: (value: RangePopoverValue) => void
    allowClear?: boolean
    className?: string
}

export default observer((props: StatusSelectProps) => {
    const { className, value, onChange, presets, allowClear } = props

    const pickerValue: RangePickerValue = value
        ? [value.range[0], value.range[1]]
        : null

    const handlePickerChange = (newValue: RangePickerValue) => {
        if (!(newValue && newValue.length)) {
            onChange(null)
        }
    }

    return (
        <RangePopover
            placement="bottomLeft"
            presets={presets}
            value={value}
            onChange={onChange}
        >
            <div>
                <RangePicker
                    value={pickerValue}
                    allowClear={allowClear}
                    open={false}
                    onChange={handlePickerChange}
                    placeholder={[res().valueFrom, res().valueTo]}
                    className={className}
                />
            </div>
        </RangePopover>
    )
})
