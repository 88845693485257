import React from 'react'

const SvgBell = props => (
    <svg width="1em" height="1em" viewBox="0 0 18 19" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 15.572c-1.5-1.63-2.25-4.074-3-8.148-.65-3.53-2.687-6.518-6-6.518S3.65 3.894 3 7.424c-.75 4.074-1.5 6.519-3 8.148h6.88c-.082.256-.13.53-.13.815 0 1.35 1.007 2.444 2.25 2.444s2.25-1.094 2.25-2.444c0-.286-.048-.56-.13-.815H18zM4.47 7.743C4.697 6.522 5.688 2.535 9 2.535s4.304 3.987 4.53 5.209c.464 2.525.942 4.55 1.654 6.199H2.816c.712-1.648 1.19-3.674 1.655-6.2zM9 17.202c.414 0 .75-.366.75-.815 0-.45-.336-.815-.75-.815s-.75.366-.75.815c0 .45.336.815.75.815z"
        />
    </svg>
)

export default SvgBell
