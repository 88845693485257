import React, { FormEvent } from 'react'

import { observer } from 'mobx-react'

import { Button, Form, FormItem, InputItem, NavLink } from '~/components'

import styles from '~/layouts/auth/styles.css'
import SuccessOperation from '~/layouts/auth/success-operation'

import res from '../res'

import PasswordRecoveryStore from './password-recovery-store'

export default observer(({ store }: { store: PasswordRecoveryStore }) => {
    function submit(e: FormEvent) {
        store.recover()
        e.preventDefault()
    }

    if (store.isRecovered) {
        return <SuccessOperation title={res().passwordRecoveredTitle} />
    }

    return (
        <div data-semantic-id="password_recovery_form">
            <div className={styles.header}>
                <span
                    data-semantic-id="password_recovery_title"
                    className={styles.title}
                >
                    {store.isRecovered
                        ? res().passwordRecoveredTitle
                        : res().passwordRecoveryTitle}
                </span>
            </div>
            <div className={styles.main}>
                <Form onSubmit={submit}>
                    <FormItem className={styles.info}>
                        {res().passwordRecoveryInfo}
                    </FormItem>
                    <InputItem
                        store={store.email}
                        placeholder={res().email}
                        inputProps={{ name: 'email_field', size: 'large' }}
                    />
                    {store.error && (
                        <div className={styles.error}>{store.errorMessage}</div>
                    )}
                    <FormItem>
                        <Button
                            data-semantic-id="password_recovery_button"
                            disabled={store.isSending}
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className={styles.mainButton}
                        >
                            {res().sendAction}
                        </Button>
                    </FormItem>
                    <FormItem className={styles.cancelButtonWrapper}>
                        <NavLink href="/login" className={styles.cancelButton}>
                            {res().cancel}
                        </NavLink>
                    </FormItem>
                </Form>
            </div>
        </div>
    )
})
