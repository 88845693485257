import res from './res'

res.add('ru', {
    generalInformation: [
        'С помощью бокового меню осуществляется вся навигация по личному кабинету'
    ],
    dashboard: [
        'Отслеживайте в статистике: Успешные/Неуспешные транзакции, Банки-эмитеты, Каналы'
    ],
    transactions: [
        'С помощью статистики, вы можете мониторить информацию о поступивших платежах за выбранный период',
        'Платежи можно отсортировать по дате, либо по статусу',
        'С помощью фильтра, Вы можете осуществлять расширенный поиск по нескольким полям'
    ],
    statements: [
        'Здесь отображаются Ваши магазины (контракты). Для управления выписками выберите нужный терминал',
        'Вы можете настроить автоматическую ежедневную, еженедельную или ежемесячную рассылку выписки на почту'
    ],
    paymentLinks: [
        'Сформируйте уникальныую ссылку, с помощью которой, покупатель оплатит с помощью Apple Pay и Samsung Pay всего в один клик'
    ],
    credit: [
        'Подключите к магазину возможность принимать оплату от клиентов в рассрочку или в кредит'
    ],
    configureEpayTemplate: [
        'Адаптируйте шаблон платёжной страницы под свой магазин'
    ],
    terminalSettings: [
        'Переключатель в пункте «Активен» отвечает за включение\\отключение самого терминала',
        'Переключатель в пункте «Сохранить карту» включает\\отключает функцию, позволяющую вашим клиентам сохранять карту',
        'Если переключатель в пункте «1-шаговая схема» находится в активированном состоянии, то средства с карты клиента будут списаны сразу, без изначального удержания суммы банком. Если данный переключатель отключён, то сумма изначально будет удержана банком и списана тогда, когда вы самостоятельно проведёте платёж в разделе «Платежи\\ePay-терминалы»'
    ],
    team: [
        'Здесь отображаются все Ваши сотрудники и выданные им роли. Вы можете редактировать роль сотрудника или удалить его'
    ]
})
