import locale from '~/utils/locale'

export default locale.create<{
    unknownError: string
    errorCodes: {
        75: string
        506: string
        3141: string
    }
    permissionError: string
    required: string
    invalidEmail: string
    invalidNumber: string
    success: string
    info: string
    warn: string
    error: string
    valueFrom: string
    valueTo: string
    techinalInfo: string
    errorCode: string
    close: string
    paymentsSystems: {
        visa: string
        masterCard: string
        amexCard: string
        other: string
    }
    antd: any,
    longNumberDigits: {
        thousand: string
        million: string
        billion: string
        trillion: string
        quadrillion: string
        quintillion: string
    }
    failedToFetch: string
}>()
