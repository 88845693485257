import React from 'react'
import { observer } from 'mobx-react'
import styles from '../../styles.css'
import res from '../../res'
import locale from '~/utils/locale'
import { action, computed, observable } from 'mobx'
import ContactsModal from '../contacts-modal'

@observer
export default class FooterLinks extends React.Component {

    @observable
    public isOpenContactsModal: boolean = false

    @action.bound
    public toggleContactsModal() {
        this.isOpenContactsModal = !this.isOpenContactsModal
    }

    public render() {

        return (
            <>
                <div className={styles.links}>
                    {/*<a href="#">{res().termsOfUse}</a>*/}
                    <a onClick={() => this.toggleContactsModal()}>
                        {res().contacts}
                    </a>
                    {/*<a href="https://homebank.userecho.com/">{res().help}</a>*/}
                    <a
                        href={`https://epayment.kz/${this.isLangEN ? '' : 'ru/'}docs`}
                        target="_blank"
                    >
                        {res().docs}
                    </a>
                </div>
                <ContactsModal store={this} />
            </>
        )
    }

    @computed
    private get isLangEN() {
        return locale.isLang('en')
    }
}