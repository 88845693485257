import moment from 'moment'

import { renameProperties } from '~/utils'
import { Content, get, post, put } from '~/utils/ajax'
import strict from '~/utils/strict'
import { ChangeStatementResponse, ContractStatementHistory, ContractStatementInfo, CreateStatementRequest, FrequencyType, GenerateStatementRequest, InitiationType, StatementHistoryResponse, StatementResponse, UpdateStatementRequest } from './contracts'
import { format } from 'typescript-formatter/lib/formatter'

export * from './contracts'

export const getStatements = () => get<StatementResponse[]>('chpayment/schedule')
    .then(response => {

        const { error, result: res } = response

        if (error || !res) return { error }

        const result = renameProperties<StatementResponse[]>(res, name => {
            if (name === 'shedulerId') return 'schedulerId'
            if (name === 'shedulers') return 'schedulers'
            return name
        })

        const statements = result.map(s => {

            const dailyScheduler = s.schedulers ? s.schedulers.find(x => x.frequency === FrequencyType.daily.toUpperCase()) : null
            const weeklyScheduler = s.schedulers ? s.schedulers.find(x => x.frequency === FrequencyType.weekly.toUpperCase()) : null
            const monthlyScheduler = s.schedulers ? s.schedulers.find(x => x.frequency === FrequencyType.monthly.toUpperCase()) : null

            return strict<ContractStatementInfo>({
                shopID: s.shopID,
                shop: s.shop,
                name: s.name,
                type: s.type,
                contract: s.contract,
                daily: dailyScheduler ? dailyScheduler : { enable: false },
                weekly: weeklyScheduler ? weeklyScheduler : { enable: false },
                monthly: monthlyScheduler ? monthlyScheduler : { enable: false },
                terminalId: s.terminalId
            })
        })

        return { result: statements }
    })

export const createStatement = (request: CreateStatementRequest) => post<ChangeStatementResponse>('chpayment/schedule', request)

export const updateStatement = (request: UpdateStatementRequest) => put<ChangeStatementResponse>('chpayment/schedule', request)

function createContractStatement(source: StatementHistoryResponse) {

    let emails: string[]
    if (source.emails instanceof Array) {
        emails = source.emails.filter(x => x !== '')
    } else {
        emails = source.emails === '' ? null : source.emails.split(';')
    }

    return strict<ContractStatementHistory>({
        id: source.id,
        create: source.create,
        period: [source.fromDate, source.toDate],
        emails,
        initiationType: source.frequency,
        format: source.format,
        status: source.status,
        initiator: source.initiator,
        fileFormat: source.fileFormat
    })
}

export const getStatementHistory = (id: string, terminalId: string) => get<StatementHistoryResponse[]>(`chpayment/shop/${id}/schedule/history?terminalID=${terminalId}&fromdate=2019-01-01&todate=${moment().format('YYYY-MM-DD')}`)
    .then(response => {

        const { result, error } = response

        const clearedResult = result ? result : []
        if (error) return { error }

        clearedResult.forEach(resultEntry => {
            const emails = resultEntry.emails instanceof Array ? resultEntry.emails : resultEntry.emails.split(' ')
            resultEntry.emails = emails
        })
        return { result: clearedResult ? clearedResult.map(createContractStatement) : [] }
    })

export const generateStatement = (request: GenerateStatementRequest) => post<StatementHistoryResponse>('chpayment/statement', request)

export const downloadStatement = (id: string) => get<Response>(`download/${id}`, undefined, { response: new Content('binary', { id }) })

export const getStatementStatus = (id: string) => get<StatementHistoryResponse>(`chpayment/statement/${id}`)
    .then(response => {

        const { result, error } = response

        if (error) return { error }

        return { result: createContractStatement(result) }
    })
