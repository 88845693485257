import React from 'react'

const SvgQrCodes = props => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" {...props}>
        <g fillRule="nonzero">
            <path d="M-2 1023h445.217V577.783H-2V1023zm89.037-356.167H354.17v266.44H87.037v-266.44z" />
            <path d="M176.087 755.87h89.043v89.043h-89.043zM754.87 933.957h89.043V1023H754.87zM932.957 933.957H1022V1023h-89.043z" />
            <path d="M932.957 666.833h-89.036v-89.05H576.783V1023h89.05V755.869h89.028v89.052H1022V577.783h-89.043zM-2 444.217h445.217V-1H-2v445.217zM87.037 88.035H354.17v267.128H87.037V88.035z" />
            <path d="M176.087 177.087h89.043v89.043h-89.043zM576.783-1v445.217H1022V-1H576.783zM932.95 355.163h-267.12V88.035h267.12v267.128z" />
            <path d="M754.87 177.087h89.043v89.043H754.87z" />
        </g>
    </svg>
)

export default SvgQrCodes
