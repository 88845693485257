// TODO: GET TRANSLATIONS

import { Moment } from 'moment'
import { FrequencyType } from '~/api/statements'
import res from './res'

res.add('ru', {
    manualGenerationTitle: 'Генерация выписки',
    scheduleTitle: (scheduleType: FrequencyType) =>
        `Настройка ${scheduleType === FrequencyType.daily
            ? 'ежедневных'
            : scheduleType === FrequencyType.weekly
                ? 'еженедельных'
                : 'ежемесячных'
        } выписок`,
    periodTitle: 'Период',
    emailsTitle: 'Email-ы получателей',
    emailRequired: 'Пожалуйста, введите email адрес участника',
    invalidEmail: 'Неправильный формат email',
    formatTitle: 'Формат',
    generateButton: 'Сформировать',
    applyButton: 'Применить',
    cancelButton: 'Отменить',
    active: 'Активированы',
    formatZip: 'При выборе данного поля формирование будет происходить в zip формате',
    cols: {
        email: 'Email',
        control: 'Управление',
        controlsTooltip: {
            add: 'Добавить',
            edit: 'Редактировать',
            save: 'Применить',
            cancel: 'Отменить',
            delete: 'Удалить'
        }
    }
})
