import React, { RefObject } from 'react'

import { observer } from 'mobx-react'

import Resizable from 're-resizable'

import { DragSource, DropTarget } from 'react-dnd'

import { Table, Tooltip } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { Column, ColumnEvents, GridProps } from '../grid-props'

import { LoadingIcon } from '../../icon'

import styles from './grid-antd.css'

import res from '../res'

import BrowserStore from '~/services/browser/browser-state'

const Title = observer(props => {
    const {
        children,
        connectDragSource,
        connectDropTarget,
        connectDragPreview,
        isDragDisabled
    } = props

    const className = `${styles.draggableCellWrapper} ${isDragDisabled ? styles.disabled : ''
        }`

    return (
        connectDragPreview &&
        connectDragSource &&
        connectDragPreview(
            connectDropTarget(
                connectDragSource(<div className={className}>{children}</div>)
            )
        )
    )
})

const titleSource = {
    canDrag(props) {
        return !props.isDragDisabled
    },
    beginDrag(props) {
        return {
            index: props.index
        }
    }
}

const titleTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index

        const hoverIndex = props.index

        if (dragIndex === hoverIndex) {
            return
        }

        props.events.onColumnMove(dragIndex, hoverIndex)

        monitor.getItem().index = hoverIndex
    }
}

const titleDraggableIdentifier = 'grid-title'

const DraggableTitle = DropTarget(
    titleDraggableIdentifier,
    titleTarget,
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget()
    })
)(
    DragSource(titleDraggableIdentifier, titleSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview()
    }))(Title)
)

interface HeaderCellProps {
    index: number
    tooltip: React.ReactNode
    width: number
    resizable: boolean
    draggable: boolean
    events: ColumnEvents
    children: React.ReactNode
}

const HeaderCell = observer((props: HeaderCellProps) => {
    const { index, tooltip, width, events, children, ...restProps } = props

    let content = children
    content = (
        <div className={index !== undefined ? styles.columnTitle : null}>
            {content}
        </div>
    )

    if (index !== undefined && tooltip) {
        content = (
            <Tooltip title={tooltip} mouseEnterDelay={0.5}>
                {content}
            </Tooltip>
        )
    }

    if (events) {
        if (events.onColumnMove) {
            content = (
                <DraggableTitle index={index} events={events}>
                    {content}
                </DraggableTitle>
            )
        }

        if (events.onColumnResize) {
            const resize = (e, direction, ref, d) => {
                events.onColumnResize(
                    index,
                    (width * Math.max(ref.offsetWidth, 20)) /
                    Math.max(ref.offsetWidth - d.width, 20)
                )
            }

            content = (
                <Resizable
                    enable={{ right: true }}
                    size={{ width: 'auto' }}
                    onResizeStop={resize}
                    handleClasses={{ right: styles.resizableHandle }}
                >
                    {content}
                </Resizable>
            )
        }
    }

    return <th {...restProps}>{content}</th>
})

interface DataCellProps {
    showTooltip: boolean
    className?: string
    notSelectionColumn?: boolean
}

class DataCell extends React.Component<DataCellProps> {
    public state = {
        tooltipVisible: false
    }

    public render() {
        const {
            showTooltip,
            children,
            className,
            notSelectionColumn,
            ...rest
        } = this.props

        const childrenContent = !notSelectionColumn ? (
            <>
                {children}
                <LoadingIcon className={styles.processingIcon} />
            </>
        ) : (
            children
        )

        if (!showTooltip) {
            return (
                <td {...rest} className={`${className} ${styles.ellipsis}`}>
                    {childrenContent}
                </td>
            )
        }

        if (!this.container) {
            this.container = React.createRef<HTMLTableDataCellElement>()
        }

        if (!this.content) {
            this.content = React.createRef<HTMLDivElement>()
        }

        return (
            <td className={className} {...rest}>
                <Tooltip
                    title={children}
                    visible={this.state.tooltipVisible}
                    onVisibleChange={this.onVisibleChange}
                >
                    <div
                        ref={this.container}
                        className={styles.cellContentContainer}
                    >
                        <span ref={this.content}>{childrenContent}</span>
                    </div>
                </Tooltip>
            </td>
        )
    }

    private onVisibleChange = (value: boolean) => {
        if (!this.container || !this.content) return

        if (value) {
            const rect = this.container.current.getBoundingClientRect()
            const container = rect.width
            let rectContent = this.content.current.getBoundingClientRect()
            let content = 0
            if (BrowserStore.isIE) {
                this.content.current.parentElement.style.textOverflow = 'clip'
                rectContent = this.content.current.getBoundingClientRect()
                this.content.current.parentElement.style.textOverflow =
                    'ellipsis'
            }
            content = rectContent.width
            if (container >= content) return
        }
        this.setState({ tooltipVisible: value })
    }

    private container: RefObject<HTMLDivElement>
    private content: RefObject<HTMLDivElement>
}

const components = {
    header: {
        cell: HeaderCell
    },
    body: {
        cell: DataCell
    }
}

const isProcessingRecord = (processingRowKeys: string[], rowKeyValue: string) =>
    !!(processingRowKeys && processingRowKeys.indexOf(rowKeyValue) >= 0)

function convertColumn<T>(
    source: Column<T>,
    index: number,
    events: ColumnEvents,
    processingRowKeys: string[],
    rowKey: string,
    horizontalScrollable: boolean
): ColumnProps<T> {
    const onHeaderCell = () => {
        return {
            index,
            width: source.width,
            tooltip: source.titleTooltip,
            events
        }
    }

    const onCell = (record: T): DataCellProps => {
        return {
            showTooltip: source.showTooltip !== false,
            notSelectionColumn: true
        }
    }

    return {
        dataIndex: source.field,
        title: <label htmlFor={source.field}>{source.title}</label>,
        render: source.render,
        className: source.className,
        width: source.width + (horizontalScrollable ? 'px' : '%'),
        fixed: source.fixed,
        sorter: source.sortable,
        sortOrder: !source.sortOrder
            ? undefined
            : source.sortOrder === 'asc'
                ? 'ascend'
                : 'descend',
        onHeaderCell,
        onCell
    }
}

export default observer(function GridRdg<T>(props: GridProps<T>) {
    const lastIndex = props.columns.length - 1
    const onSelectRows = props.onRowsSelect

    const columns: Array<ColumnProps<T>> = props.columns.map((col, index) => {
        return convertColumn(
            col,
            index,
            index !== lastIndex ? props : { onColumnMove: props.onColumnMove },
            props.processingRowKeys,
            props.rowKey,
            !!props.scroll
        )
    })

    let onClickTimeoutId = null

    const onRow = (record: T, index: number) => ({
        onClick: () => {
            if (typeof props.onRowClick === 'function') {
                const selection = window.getSelection()

                if (!selection.toString().length) {
                    onClickTimeoutId = setTimeout(
                        () => props.onRowClick(record),
                        200
                    )
                }
            }
        },
        onDoubleClick: () => {
            clearTimeout(onClickTimeoutId)
        }
    })

    const getRowSelection = () => {
        const optionsTitles = res().selectionOptions

        return {
            onChange: (selectedRowKeys, selectedRows: T[]) => {
                if (typeof onSelectRows !== 'undefined') {
                    onSelectRows(selectedRowKeys, selectedRows)
                }
            },
            hideDefaultSelections: true,
            selectedRowKeys: props.selectedRowKeys as any,
            selections: [
                {
                    key: 'all',
                    text: optionsTitles.all,
                    onSelect: null
                },
                {
                    key: 'removeAll',
                    text: optionsTitles.none,
                    onSelect: null
                },
                {
                    key: 'invert',
                    text: optionsTitles.invert,
                    onSelect: null
                }
            ]
        }
    }

    const getRowClassName = (record: T, index: number) => {
        const { processingRowKeys, rowKey } = props

        let externalClassName = ''

        const externalClassNameProp = props.rowClassName as any

        const rowClassNameType = typeof externalClassNameProp

        switch (rowClassNameType) {
            case 'string':
                externalClassName = externalClassNameProp
                break

            case 'function':
                externalClassName = externalClassNameProp(record, index)
                break
        }

        const rowKeyValue: string = record[rowKey] as any

        return `${isProcessingRecord(processingRowKeys, rowKeyValue)
            ? styles.processing
            : ''
            } ${props.onRowClick ? styles.clickable : null} ${externalClassName}`
    }
    return (
        <Table
            columns={columns}
            rowKey={props.rowKey}
            rowClassName={getRowClassName}
            onRow={onRow}
            loading={props.loading}
            dataSource={props.data}
            className={`${styles.table} ${!!props.scroll ? styles.scrollable : ''}`}
            pagination={false}
            rowSelection={!props.disableRowSelection ? getRowSelection() : null}
            components={components}
            scroll={props.scroll}
        />
    )
})
