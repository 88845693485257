import { noThrow } from '~/utils/control-flow'

import { computed, observable, runInAction } from 'mobx'

import { apiCheck, getInstallments, getShops, Installment, Shop } from '~/api'

export class DictionaryStore {

    @computed
    public get shops(): Shop[] {

        if (this._shops) {
            return this._shops
        }

        this.loadShops()

        return []
    }

    private loadShops() {

        noThrow(async () => {

            const result = await apiCheck(getShops())
            runInAction(() => this._shops = result)
        })
    }

    @computed
    public get installments(): Installment[] {

        if (this._installments) {
            return this._installments
        }

        this.loadInstallments()

        return []
    }

    private loadInstallments() {

        noThrow(async () => {

            const result = await apiCheck(getInstallments())

            runInAction(() => this._installments = result)
        })
    }

    @observable
    private _installments: Installment[]

    @observable
    private _shops: Shop[]
}

export default DictionaryStore
