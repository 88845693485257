export default {
    '': '#cbcbcb',
    Other: '#f36263',
    AmericanExpress: '#00A8FF',
    SamsungPay: '#1d4ac0',
    ApplePay: '#666',
    MasterCard: '#f8bd54',
    VISA: '#4864ff',
    CardID: '#8483C5',
    HomebankPay: '#1c9d7a',
    GooglePay: '#EBCB4C',
    OpenwayID: '#e5e5e5'
}