import { observer } from 'mobx-react'
import React from 'react'
import TerminalSettingsStore from '~/pages/settings/terminal-settings/terminal-settings-store'
import styles from './styles.css'
import res from './res'
import Page from '~/layouts/main/page'
import { Button, Select, Switch } from '~/components'
import { action, runInAction, toJS } from 'mobx'
import confirm from 'antd/lib/modal/confirm'
import BrowserStore from '~/services/browser/browser-state'
import { Table } from 'antd'
import { Column, Grid } from '~/components/grid'
import InteractiveMobileHints from '~/components/interactive-mobile-hints'

@observer
export default class TerminalSettingsPage extends React.Component<{ store: TerminalSettingsStore }, {}> {

    constructor(props, ctx) {
        super(props, ctx)
    }

    public render() {
        const { store } = this.props

        {/*
        const cols = [
            {
                title: 'Id',
                dataIndex: 'terminalId',
                key: 'terminalId'
            },
            {
                title: 'Is Active',
                dataIndex: 'isActive',
                key: 'isActive',
                render: (r) =>
                    <Switch
                        key={`${r}_isactive`}
                        size="small"
                        loading={this.props.store.isLoading}
                        checked={store.terminal.isActive}
                        onChange={checked => runInAction(() => store.terminal.isActive = checked)}
                    />
            }
        ]
        const columns: Array<Column<TerminalDataApp>> = [
            {
                title: 'Id',
                titleTooltip: 'Id',
                field: 'terminalId'
            },
            {
                title: 'Is Active',
                titleTooltip: 'Is Active',
                field: 'isActive',
                render: (_, r) =>
                    <Switch
                        key={`${r.terminalId}_isactive`}
                        size="small"
                        loading={this.props.store.isLoading}
                        checked={r.isActive}
                        onChange={() => (r.isActive = !r.isActive) && (this.updateData(r))}
                    />
            },
            {
                title: 'Save Card',
                titleTooltip: 'Save Card',
                field: 'saveCard',
                render: (_, r) =>
                    <Switch
                        key={`${r.terminalId}_savecard`}
                        size="small"
                        loading={store.isLoading}
                        checked={r.saveCard}
                        onChange={() => (r.saveCard = !r.saveCard) && (this.updateData(r))}
                    />
            },
            {
                title: 'Single Step',
                titleTooltip: 'Single Step',
                field: 'singleMessageScheme',
                render: (_, r, i) =>
                    <Switch
                        key={`${r.terminalId}_1step`}
                        size="small"
                        loading={store.isLoading}
                        checked={store.terminal.singleMessageScheme}
                        onChange={(checked) => runInAction(() => {
                            store.terminal.singleMessageScheme = checked
                            // const t = store.terminalData.find(e => e.terminalId === r.terminalId)

                            // if (t) {
                            // t.singleMessageScheme = checked
                            // this.updateData(t)
                            // }
                        })}
                    />
            }
        ]
        */}
        return (
            <>
                <Page
                    // pageClassName={styles.page}
                    title={
                        <div>
                            {res().title}
                            {!BrowserStore.isMobile &&
                                <Select value={store.currentShopId} className={styles.titleSelect} onChange={this.onShopChange}>
                                    {store.shops.map(shop => <Select.Option key={shop.id} value={shop.id}>{shop.name}</Select.Option>)}
                                </Select>
                            }
                        </div>
                    }
                >
                    {BrowserStore.isMobile &&
                        <Select value={store.currentShopId} className={styles.titleSelect} onChange={this.onShopChange}>
                            {store.shops.map(shop => <Select.Option key={shop.id} value={shop.id}>{shop.name}</Select.Option>)}
                        </Select>
                    }
                    {BrowserStore.isMobile &&
                        store.terminalData.map(v =>
                            <div key={`${v.id}_main_div`} className={styles.container}>
                                <div className={styles.mobileItem}>
                                    <div className={styles.terminalId}>{res().labels.terminalId}</div>
                                    <div key={`${v.id}_terminalId_div`} className={styles.terminalId}>{v.terminalId}</div>
                                </div>
                                <div className={styles.mobileItem}>
                                    <div className={styles.switch}>{res().labels.isActive}</div>
                                    <div key={`${v.id}_isActive_div`} className={styles.switch}>
                                        <Switch
                                            key={`${v.id}_isActive`}
                                            size="small"
                                            checked={v.isActive}
                                            onClick={checked => runInAction(() => v.isActive = checked)}
                                            className={'mobile-hint-section-8-step-2'}
                                        />
                                    </div>
                                </div>

                                <div className={styles.mobileItem}>
                                    <div className={styles.switch}>{res().labels.saveCard}</div>
                                    <div key={`${v.id}_saveCard`} className={styles.switch}>
                                        <Switch
                                            key={`${v.id}_saveCard`}
                                            size="small"
                                            checked={v.saveCard}
                                            onChange={checked => runInAction(() => v.saveCard = checked)}
                                            className={'mobile-hint-section-8-step-3'}
                                        />
                                    </div>
                                </div>

                                <div className={styles.mobileItem}>
                                    <div className={styles.switch}>{res().labels.singleMessage}</div>
                                    <div key={`${v.id}_singleMessageScheme`} className={styles.switch}>
                                        <Switch
                                            key={`${v.id}_singleMessageScheme`}
                                            size="small"
                                            checked={v.singleMessageScheme}
                                            onChange={checked => runInAction(() => v.singleMessageScheme = checked)}
                                            className={'mobile-hint-section-8-step-4'}
                                        />
                                    </div>
                                </div>

                            </div>
                        )
                    }
                    {!BrowserStore.isMobile &&
                        <>
                            <div className={styles.container + ' ' + styles.headerRow}>
                                <div className={styles.terminalId}>{res().labels.terminalId}</div>
                                <div className={`${styles.switch} hint-section-8-step-2`}>{res().labels.isActive}</div>
                                <div className={`${styles.switch} hint-section-8-step-3`}>{res().labels.saveCard}</div>
                                <div className={`${styles.switch} hint-section-8-step-4`}>{res().labels.singleMessage}</div>
                            </div>

                            {store.terminalData.map(v =>
                                <div key={`${v.id}_main_div`} className={styles.container}>

                                    <div key={`${v.id}_terminalId_div`} className={styles.terminalId}>{v.terminalId}</div>

                                    <div key={`${v.id}_isActive_div`} className={styles.switch}>
                                        <Switch
                                            key={`${v.id}_isActive`}
                                            size="small"
                                            checked={v.isActive}
                                            onClick={checked => runInAction(() => v.isActive = checked)}
                                        />
                                    </div>

                                    <div key={`${v.id}_saveCard`} className={styles.switch}>
                                        <Switch
                                            key={`${v.id}_saveCard`}
                                            size="small"
                                            checked={v.saveCard}
                                            onChange={checked => runInAction(() => v.saveCard = checked)}
                                        />
                                    </div>

                                    <div key={`${v.id}_singleMessageScheme`} className={styles.switch}>
                                        <Switch
                                            key={`${v.id}_singleMessageScheme`}
                                            size="small"
                                            checked={v.singleMessageScheme}
                                            onChange={checked => runInAction(() => v.singleMessageScheme = checked)}
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                    }

                    <div className={styles.ctaContainer}>
                        <Button
                            size="large"
                            type={'primary'}
                            disabled={!store.isModified}
                            icon="save"
                            loading={store.isLoading}
                            onClick={store.updateDataAll}
                        >
                            {res().labels.save}
                        </Button>
                    </div>

                    {BrowserStore.isMobile && !store.isLoading && store.mobileHintStore &&
                        <InteractiveMobileHints store={store.mobileHintStore} />
                    }

                    { /*
                    <Table
                        columns={cols}
                        dataSource={store.terminalData}
                    />

                    <Switch
                        size={'small'}
                        checked={store.terminal?.isActive}
                        onClick={checked => runInAction(() => store.terminal.isActive = checked)}
                    />

                    <br/>

                    <Grid
                        rowKey={'terminalId'}
                        disableRowSelection={true}
                        columns={columns}
                        data={store.terminalData}
                    />
                */}
                </Page>
            </>)
    }

    @action.bound
    private onShopChange(shopId) {
        const { store } = this.props

        if (store.isModified) {
            confirm({
                title: res().confirmTitle,
                content: res().messages.unsavedChanges,
                okCancel: true,
                onOk: args => store.loadTerminalData(shopId)
            })

            return
        }

        store.loadTerminalData(shopId)
    }
}