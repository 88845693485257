import { observer } from 'mobx-react'
import React from 'react'

import { Button, Collapse, Input, Spin } from '~/components'
const TextArea = Input.TextArea
const Panel = Collapse.Panel

import { InfoCircleIcon } from '~/components/icon'
import Page from '~/layouts/main/page'
import res from './res'
import styles from './styles.css'

import { ApiPostInfo } from '~/api/faq/contracts'
import BrowserStore from '~/services/browser/browser-state'
import ForDevelopersStore from './for-developers-store'

import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'

export default observer(({ store }: { store: ForDevelopersStore }) => {
    const { data } = store
    if (!data) return <div></div>
    const apiInfo = data.info
    const additionalPageClass = BrowserStore.isMobile ? styles.mobile : ''
    const pathKeys = Object.keys(data.paths)
    return (
        <>
            <Page title="API FAQ" pageClassName={additionalPageClass}>
                {/*<SwaggerUI url="static/swagger.json" />*/}
            </Page>
        </>
    )
})
