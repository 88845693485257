import React from 'react'

const SvgStatistics = props => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" {...props}>
        <g transform="translate(1 1)" fillRule="evenodd">
            <rect
                x={114.278}
                y={680.361}
                width={112.778}
                height={338.931}
                rx={8}
            />
            <rect
                x={341.833}
                y={567.051}
                width={112.778}
                height={452.241}
                rx={8}
            />
            <rect
                x={569.389}
                y={397.086}
                width={112.778}
                height={622.206}
                rx={8}
            />
            <rect
                x={796.944}
                y={283.775}
                width={112.778}
                height={735.516}
                rx={8}
            />
            <path
                d="M106.997 445.7c12.768-2.08 306.838-70.089 533.512-295.955v83.816c0 23.71 19.675 43.26 43.535 43.26 23.86 0 43.535-19.55 43.535-45.34V43.26c0-23.71-19.674-43.26-43.535-43.26H492.532c-23.86 0-43.534 19.55-43.534 43.26 0 23.71 19.674 43.26 43.534 43.26h89.791C374.277 299.074 89.625 365.627 87.533 365.627c-24.07 4.368-37.047 25.997-32.861 49.707 6.698 27.661 36.21 35.773 52.325 30.365z"
                fillRule="nonzero"
            />
            <rect y={906.482} width={1024} height={113.31} rx={8} />
        </g>
    </svg>
)

export default SvgStatistics
