import { AFTStatus } from '~/api'

export default {

    // [P2PStatus.auth]: {
    //     color: '#FFB600'
    // },
    [AFTStatus.charged]: {
        color: '#05B9A6'
    },
    // [P2PStatus.cancelled]: {
    //     color: '#BDBDBD'
    // },
    // [P2PStatus.refunded]: {
    //     color: '#F42B74'
    // },
    [AFTStatus.rejected]: {
        color: '#F42B74'
    }
}
