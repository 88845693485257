import res from './res'

import { PaymentStatus } from '~/api'

res.add('ru', {
    statistics: 'Статистика',
    collapseSummary: range => `за ${range instanceof Function ? range() : range || 'период'}`,
    sorting: 'Сортировка',
    filter: 'Фильтр'
})
