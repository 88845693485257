import React from 'react'

import { action } from 'mobx'

import { inject, observer } from 'mobx-react'

import paymentsTypes from '~/constants/payments-types'

import { TransactionsDataItem } from '../../../types'

import StatisticsLineChart from '../../statistics-timeline-chart'

import res from '../../../res'

import BaseDashboardChart, { BaseDashboardChartProps } from '../base-chart'

import { TooltipContent } from '../tooltips/successful-transactions-chart-tooltip'

interface SuccessfulTransactionsChartProps extends BaseDashboardChartProps {
    data: TransactionsDataItem[]
}

@inject('dashBoardStore')
@observer
export default class SuccessfulTransactionsChart extends BaseDashboardChart<
SuccessfulTransactionsChartProps
> {
    @action.bound
    protected initData() {
        this.fieldsConfig = paymentsTypes.map(paymentType => ({
            field: paymentType.id,
            title: paymentType.title ? paymentType.title : paymentType.id,
            id: paymentType.id,
            visible: true,
            color: paymentType.color,
            dataKey: `data.${paymentType.id}.amount`
        }))

    }

    protected renderContent = () => {
        if (this.props.data.length === 0) {
            return null
        }

        return (
            <StatisticsLineChart
                data={this.props.data}
                chartTooltip={TooltipContent}
            />
        )
    }

    protected data: Array<{}>

    protected fieldsSettingsSubtitle = res().charts.successfulTransactions
        .fieldsSettings

    protected configurable: boolean = false
}
