import React from 'react'
import res from './res'
import { Step } from '~/components/interactive-hints/constants'

export const mobileSectionsOrder = [
    'generalInformation',
    'dashboard',
    'transactions',
    'statements',
    'statementPage',
    'paymentLinks',
    'credit',
    'configureEpayTemplate',
    'terminalSettings',
    'team'
]

export const sectionsMobile = (sectionsKeys: string[]): Step[] => {

    const sections = {
        generalInformation: {
            steps: [
                {
                    target: '.mobile-hint-section-1-step-1',
                    placement: 'right',
                    // placement: 'top',
                    description: res().generalInformation[0],
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        document.body.style.overflow = 'hidden'
                        resolve()
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        document.body.style.overflow = 'unset'
                        resolve()
                    })
                }
            ]
        },
        dashboard: {
            steps: [
                {
                    target: '.mobile-hint-page-content',
                    placement: 'top',
                    description: res().dashboard[0]
                }
            ]
        },
        transactions: {
            steps: [
                {
                    target: '.mobile-hint-section-3-step-3',
                    placement: 'bottom',
                    description: res().transactions[0]
                },
                {
                    target: '.mobile-hint-section-3-step-4',
                    placement: 'bottom',
                    description: res().transactions[1]
                },
                {
                    target: '.mobile-hint-section-3-step-5',
                    placement: 'bottom',
                    description: res().transactions[2]
                }
            ]
        },
        statements: {
            steps: [
                {
                    target: '.mobile-hint-section-5-step-2',
                    // target: '.hint-page',
                    placement: 'top',
                    description: res().statements[0]
                }
            ]
        },
        statementPage: {
            steps: [
                {
                    target: '.hint-section-5-step-5',
                    placement: 'top',
                    description: res().statements[1]
                }
            ]
        },
        paymentLinks: {
            steps: [
                {
                    target: '.mobile-hint-section-6-step-3',
                    placement: 'bottom',
                    description: res().paymentLinks[0]
                }
            ]
        },
        credit: {
            steps: [
                {
                    target: '.mobile-hint-section-7-step-3',
                    placement: 'bottom',
                    description: res().credit[0]
                }
            ]
        },
        configureEpayTemplate: {
            steps: [
                {
                    // target: '.hint-page',
                    target: '.mobile-hint-page-content',
                    placement: 'top',
                    description: res().configureEpayTemplate[0]
                    // type: HintType.sider,
                    // onBeforeShow: () => new Promise<void>((resolve) => {
                    //     document.body.style.overflow = 'hidden'
                    //     resolve()
                    // }),
                    // onAfterHide: () => new Promise<void>((resolve) => {
                    //     document.body.style.overflow = 'unset'
                    //     resolve()
                    // })
                }
            ]
        },
        terminalSettings: {
            steps: [
                {
                    target: '.mobile-hint-section-8-step-2',
                    placement: 'bottom',
                    description: res().terminalSettings[0]
                },
                {
                    target: '.mobile-hint-section-8-step-3',
                    placement: 'bottom',
                    description: res().terminalSettings[1]
                },
                {
                    target: '.mobile-hint-section-8-step-4',
                    placement: 'bottom',
                    description: res().terminalSettings[2]
                }
            ]
        },
        team: {
            steps: [
                {
                    target: '.hint-page',
                    placement: 'bottom',
                    description: res().team[0]
                }
            ]
        }
    }

    return sectionsKeys.reduce((steps, key) => {
        return [...steps, ...sections[key].steps]
    }, [])
}
