import React from 'react'

import styles from '~/layouts/auth/styles.css'

import res from './res'

res.add('kk', {
    signupTitle: 'Жүйеге тіркелу',
    signupLink: 'Тіркеу',
    setPasswordTitle: 'Құпиясөзді орнату',
    loginTitle: 'Жүйеге кіру',
    loginLink: 'Кіру',
    passwordRecoveryTitle: 'Құпиясөзді қалпына келтіру',
    signUpAction: 'Тіркелу',
    setPasswordAction: 'Орнату',
    logInAction: 'Кіру',
    sendAction: 'Жіберу',
    cancel: 'Жабу',
    signupEmailPrompt: 'Сіздің жұмыс email поштаңыз',
    noActivationEmail: 'Растау туралы хат келмеді ме?',
    email: 'Email',
    password: 'Құпиясөз',
    newPassword: 'Сіздің жаңа құпия сөзіңіз',
    passwordRepeat: 'Құпиясөзді қайталаңыз',
    passwordRestoreLink: 'Құпиясөзді ұмыттыңыз ба?',
    noAccount: 'Әлі тіркелмегенсіз бе?',
    hasAccount: 'Тіркелгенсіз бе?',
    didNotReceiveEmail: 'Хат келмеді ме?',
    repeatSignUpLink: 'Тіркеуді қайталау',
    emailRequired: 'Еmail-ді енгізіңіз',
    passwordRequired: 'Құпиясөзіңізді енгізіңіз',
    passwordRepeatRequired: 'Жаңа құпиясөзді қайталаңыз',
    passwordsDoNotMatch: 'Енгізілген құпиясөздер сәйкес келмейді',
    successSignUp: 'Растау хаты сіздің email мекенжайыңызға жіберілді',
    passwordRecoveryInfo:
        'Тіркеу кезінде жазылған email-ді енгізіңіз, оған  қайтадан растау хаты жіберіледі.',
    accountCreatedTitle: 'Тіркеу жасалынды',
    accountCreatedInfo: (email: string) => (
        <span>
            Тіркеуді аяқтау үшін <span className={styles.email}>{email}</span>{' '}
            өтіңіз және поштаңызды растаңыз
        </span>
    ),
    passwordSetTitle: 'Құпиясөз сәтті орнатылды',
    passwordRecoveredTitle:
        'Құпиясөзді қалпына келтіру бойынша нұсқаулықтары бар хат сіздің email мекенжайыңызға жіберілді',
    passwordHelp: {
        title: 'Құпиясөз кем дегенде болуы керек:',
        notContainsErrors: {
            numbers: '1 цифры',
            nonAlphabeticalCharacters: '1 альфавиттік емес символ',
            capitalCharacters: '1 бас әріп',
            lowercaseCharacters: '1 кіші әріп',
            minLength: 'символдар',
            minLength8: '8 таңба'
        }
    },
    invalidPassword: 'Құпиясөзіңіздің форматы сәйкес келмейді',
    continue: 'Жалғастыру',
    idleTimerTitle: 'Автоматты түрде жұмыс аяқталу',
    idleTimerWarning:
        'Сіз 10 минуттан артық белсенді емессіз. Сессия аяқталады',
    idleTimerButtonContinue: 'Жұмыс жалғастыру',
    idleTimerButtonStop: 'Жұмысты аяқтау',
    passwordExpired: {
        title: 'Құпиясөзіңіздің мерзімі аяқталды',
        message: 'Сізге Құпиясөзді қалпына келтіру қажет'
    }
})
