import res from './res'

res.add('en', {
    orderText: 'Request №',
    creditOrderTitle: 'Installment and credit orders',
    newOrderTitle: 'New order',
    confirmButton: 'Confirm order',
    cancelButton: 'Reject',
    accept: 'Accept',
    acceptConfirmationTitle: 'Are you sure you want to accept the application?',
    acceptNotificationTitle: 'Application has been successfully approved',
    acceptFailedNotificationTitle: 'An error occured, please try later',
    acceptNotificationDescription: 'Refresh the table to see the updated application status',
    rejectReason: 'Reject reason',
    noDataLabel: 'No data'
})
