import { CashByCodeStatus } from '~/api'

export default {

    [CashByCodeStatus.auth]: {
        color: '#FFB600'
    },
    [CashByCodeStatus.charged]: {
        color: '#05B9A6'
    },
    [CashByCodeStatus.cancelled]: {
        color: '#BDBDBD'
    },
    [CashByCodeStatus.refunded]: {
        color: '#F42B74'
    },
    [CashByCodeStatus.rejected]: {
        color: '#F42B74'
    },
    [CashByCodeStatus.CANCEL_OLD]: {
        color: '#BDBDBD'
    },
    [CashByCodeStatus.TIMEOUT_3D]: {
        color: '#F42B74'
    }
}
