import React from 'react'

import res from './res'

res.add('ru', {
    contacts: 'Контакты',
    docs: 'Техническая документация',
    help: 'Помощь',
    termsOfUse: 'Пользовательское соглашение',
    contactsModal: {
        title: 'Контакты',
        close: 'Закрыть',
        forLegals: 'для юр. лиц',
        forIndividuals: 'для физ. лиц',
        acquiring: 'По вопросам эквайринга',
        cabinet: 'По вопросам личного кабинета'
    }
})
