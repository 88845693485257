import { notification } from 'antd'
import { remove } from 'lodash'
import {
    action,
    observable,
    runInAction,
    toJS
} from 'mobx'
import page from 'page'
import { apiCheck, createOrGetMerchant, RegisterMerchant } from '~/api'
import { noThrow } from '~/utils/control-flow'
import message from '~/utils/message'
import res from './res'

export default class CurrentOrdersStore {

    constructor() {
        this.loadData()
    }

    @observable
    public currentMerchant: RegisterMerchant

    @observable
    public orderStatusName: string

    @action.bound
    private async loadData() {

        const { value } = await noThrow(apiCheck(createOrGetMerchant()))

        runInAction(() => {
            this.currentMerchant = value
            this.orderStatusName = this.currentMerchant.statusName
        })
    }


}
