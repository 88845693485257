import React from 'react'
import { render } from 'react-dom'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { when } from 'mobx'
import { observer } from 'mobx-react'

import { ConfigProvider } from 'antd'

import page from 'page'

import './startup'

import store from './app-store'

import './app.css'

import res from './res'

when(
    () => store.ready,
    () => {
        page()

        const Layout = observer(props => store.layout(props))

        const Page = observer(() => store.page())

        const userAgent = window.navigator.userAgent

        const App = observer(() => (
            <DndProvider backend={HTML5Backend}>
                <ConfigProvider locale={res().antd}>
                    <Layout>
                        <Page />
                    </Layout>
                </ConfigProvider>
            </DndProvider>
        ))

        document.addEventListener('touchmove', (event) => {
            if (event.touches.length > 1) {
                event.preventDefault()
            }
        }, { passive: false })

        if (/iPhone|iPod|iPad/i.test(userAgent) && !/Safari/i.test(userAgent)) {
            const style = document.createElement('style')
            style.innerHTML = 'body, .src-layouts-main-styles_siderMobile { top: 40px }'
            document.head.appendChild(style)
        }

        render(<App />, document.getElementById('mp-content'))
    }
)
