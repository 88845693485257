import locale from '~/utils/locale'

export default locale.create<{
    generalInformation: string[]
    dashboard: string[]
    transactions: string[]
    statements: string[]
    paymentLinks: string[]
    credit: string[]
    configureEpayTemplate: string[]
    terminalSettings: string[]
    team: string[]
}>()
