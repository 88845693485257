import { email } from '~/layouts/auth/styles.css'
import res from './res'

res.add('ru', {
    title: 'Пригласить в команду',
    subTitle:
        'Введите email участника чтобы он имел возможность авторизироваться в системе',
    email: 'Email участника',
    emailRequired: 'Пожалуйста, введите email адрес участника',
    invalidEmail: 'Неправильный формат email',
    roleTitle: 'Определите роль для нового участника',
    manager: 'Менеджер',
    managerDescription:
        'Доступ к просмотру платежей и статистики, выставлению счетов на оплату, формированию выписок, управлению платежами, поиску платежей',
    accountant: 'Бухгалтер',
    accountantDescription:
        'Доступ к просмотру транзакций и статистики, формированию выписок, поиску платежей',
    view: 'Просмотр',
    viewDescription: 'Доступ к просмотру транзакций и статистики',
    accessLevelChanges: 'Изменения уровня доступа для',
    applyButton: 'Применить',
    cancelButton: 'Отменить'
})
