import React from 'react'

const SvgClose = props => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" {...props}>
        <path
            d="M1024 102.4L921.6 0 512 409.6 102.4 0 0 102.4 409.6 512 0 921.6 102.4 1024 512 614.4 921.6 1024 1024 921.6 614.4 512z"
            fillRule="nonzero"
        />
    </svg>
)

export default SvgClose
