import React from 'react'

import { observer } from 'mobx-react'

import {
    CartesianGrid,
    ChartTooltip,
    Line,
    LineChart,
    ResponsiveContainer,
    XAxis,
    YAxis
} from '~/components'

import { StatisticsLineChartProps } from '../../types'

export default observer(function StatisticsLineChart(
    props: StatisticsLineChartProps
) {
    const { data, chartTooltip } = props

    const formatYAxis = (tickValue: number) => {
        return tickValue
    }

    const formatXAxis = (tickIndex: number) => {
        return data[tickIndex].date.toLocaleDateString()
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={[...data]}
                margin={{ top: 30, right: 20, left: 10, bottom: 0 }}
            >
                <CartesianGrid
                    strokeDasharray="2 2"
                    stroke="#EEF2F8"
                    vertical={false}
                />
                <Line type="monotone" dataKey="totalCount" stroke="#8884d8" />
                <ChartTooltip
                    content={chartTooltip ? chartTooltip : null}
                    position={{ y: 80 }}
                />
                <XAxis
                    stroke={'#ccc'}
                    tick={{ fontSize: 9 }}
                    tickFormatter={formatXAxis}
                />
                <YAxis
                    stroke={'#ccc'}
                    width={30}
                    tick={{ fontSize: 9 }}
                    tickFormatter={formatYAxis}
                />
            </LineChart>
        </ResponsiveContainer>
    )
})
