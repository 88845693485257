import React from 'react'

import res from './res'

res.add('en', {
    selectedPaymentsLabel: (
        paymentCount: number,
        amountsContent: React.ReactNode
    ) => (
        <span>
            {paymentCount} payment{paymentCount === 1 ? '' : 's'} in the amount
            of {amountsContent} {paymentCount === 1 ? 'is' : 'are'} selected
        </span>
    ),
    clearButton: 'Clear selection'
})
