import React from 'react'

import { observer } from 'mobx-react'

import {
    ChartTooltip, Pie, PieChart, ResponsiveContainer, Cell
} from '~/components'

import { CartTypeChartProps } from '../../types'

import CartTypeColors from '~/constants/cart-type-colors'

export default observer(function CartTypeChart(
    props: CartTypeChartProps
) {

    const { data, chartTooltip } = props
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => data[index].cardTypeName

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart margin={{ top: 30, right: 20, left: 10, bottom: 0 }}>
                <Pie
                    data={[...data]}
                    dataKey="transactionCount"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={3}
                    label={renderCustomizedLabel}
                >
                    {data.map((entry, index) => <Cell key={`cell-${index}`} fill={CartTypeColors[entry.cardTypeName]} />)}
                </Pie>
                <ChartTooltip
                    content={chartTooltip ? chartTooltip : null}
                    position={{ y: 10 }}
                />
            </PieChart>
        </ResponsiveContainer>
    )
})
