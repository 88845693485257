import React, { ReactNode } from 'react'
import { observer } from 'mobx-react'
import Page from '~/layouts/main/page'
import res from './res'
import styles from './styles.css'
import { Button, Table } from '~/components'
import CurrentOrdersStore from '~/pages/orders/current-orders/current-orders-store'
import OrderResult from '~/pages/orders/create-order/components/OrderResult'
import actions from '~/actions'

const cols = [
    {
        key: 'id'
    },
    {
        key: 'orderType'
    },
    {
        key: 'orderStatus'
    }
]

const ordersData = [
    {
        id: '1',
        orderType: 'Заявка на подключение',
        orderStatus: 'На рассмотрении'
    }
]

@observer
export default class CurrentOrders extends React.Component<{ store: CurrentOrdersStore }, {}> {
    public render() {

        const { store } = this.props

        const tableCols = cols.map(col => {
            return {
                title: res().cols[col.key],
                ...col
            }
        })

        return (
            <>
                {store.orderStatusName === 'SENT' &&
                    <Page
                        title={
                            <div>
                                <div> {res().title} </div>
                            </div>
                        }
                    >
                        <div className={`${styles.mobileContent} ${styles.content}`}>
                            <Table
                                columns={tableCols}
                                dataSource={ordersData}
                            />
                        </div>
                    </Page>
                }

                {store.orderStatusName === 'INPROGRESS' || store.orderStatusName === 'FAILED' &&
                    <Page>
                        <div>
                            <div className={styles.result}>
                                <h2 className={styles.title}> Регистрация прошла успешно! </h2>
                                <div className={styles.text}>
                                    Спасибо за регистрацию! Мы благодарны, что Вы выбрали нас. <br />
                                    Чтобы продолжить и получить терминал, необходимо заполнить анкету.
                                </div>

                                <Button type="primary" size="large" onClick={() => actions.openOrderCreate()}>
                                    Начать анкетирование
                                </Button>
                            </div>
                        </div>
                    </Page>
                }
            </>
        )
    }
}
