import locale from '~/utils/locale'

export default locale.create<{
    title: string,
    confirmTitle: string,

    labels: {
        terminalId: string,
        isActive: string,
        saveCard: string,
        singleMessage: string,
        save: string
    },

    messages: {
        errorTitle: string,
        cannotLoadShops: string,
        saveSuccess: string,
        unsavedChanges: string
    }
}>()
