import { notification } from 'antd'
import {
    action, computed,
    observable, reaction,
    runInAction,
    toJS
} from 'mobx'
import page from 'page'
import { apiCheck, getProfile, updateMerchantData, updateSettings } from '~/api'
import { noThrow } from '~/utils/control-flow'
import res from './res'
import moment from 'moment'
import { PaymentSettingsData } from '~/api/payment-settings'
import { getPaymentSettings, getPaymentSettingsHistory } from '~/api'
import { observer } from 'mobx-react'


export default class PaymentSettingsStore {

    constructor() {
        this.loadData()
        this.loadUpcomingDates()
        this.loadMonthData()
    }

    @observable
    public activeDay: any = moment()

    @observable
    public loading: boolean = true

    @observable
    public data: PaymentSettingsData[] = []

    @observable
    public monthData: any

    @observable
    public upcomingDateList: any

    @observable
    public activeSetting: any

    @observable
    public selectedDate: moment.Moment = moment()

    @action
    public loadMonthData = async () => {
        const startQuarter = moment().clone().startOf('quarter')
        const endQuarter = startQuarter.clone().endOf('quarter')
        const { value, error }: { value?: any; error?: any } = await noThrow(apiCheck(getPaymentSettingsHistory(startQuarter.format('YYYY-MM-DD'), endQuarter.format('YYYY-MM-DD'))))
        runInAction(() => {
            if (error) {
                notification.error({ message: res().errorLoadData })
                return
            }
            this.monthData = value.data

        })
    }

    @action
    public onChange = (date) => {
        this.getPaymentSettingsByDate(date)
    }

    @action
    public setVisible = (event, index) => {
        runInAction(() => this.data[index].visibility = event.target.checked)
    }

    @action
    public changeItem = (type, event, index): void => {
        if (type === 'visibility') {
            runInAction(() => this.data[index].visibility = event.target.checked)
        }

        if (type === 'price') {
            runInAction(() => this.data[index].price = event.target.value)
        }
    }

    @action
    public getPaymentSettingsByDate = async (date: any) => {
        const { value, error }: { value?: PaymentSettingsData[]; error?: any } = await noThrow(apiCheck(getPaymentSettings(date.format('YYYY-MM-DD'))))

        runInAction(() => {
            if (error) {
                notification.error({ message: 'Error' })
                return
            }
            if (!value) {
                notification.error({ message: 'Не удалось получить данные за выбранную дату' })
                return
            }
            this.activeDay = date
            this.data = value
        })
    }

    @action
    public updateSettings = async () => {
        this.data.map((item) => {
            item.price = Number(item.price)
            delete item.id
            delete item.active_date
            delete item.set_date
        })

        const reqDat = {
            date: this.activeDay.format('YYYY-MM-DD'),
            docs: toJS(this.data)
        }

        const { value, error }: { value?: any; error?: any } = await noThrow(apiCheck(updateSettings(reqDat)))

        if (error) {
            notification.error({ message: 'Ошибка при обновлении' })
            return
        }

        notification.success({ message: res().successUpdate })
    }

    @action
    public async loadData() {

        const { value, error }: { value?: PaymentSettingsData[]; error?: any } = await noThrow(apiCheck(getPaymentSettings(moment().format('YYYY-MM-DD'))))

        runInAction(() => {
            if (error) {
                notification.error({ message: 'Error' })
                return
            }
            this.data = value
        })

    }

    @action
    public async loadUpcomingDates() {

        const startDate = moment().subtract(4, 'days')
        const endDate = moment().add(4, 'days')

        const now = startDate.clone()
        const dates = []

        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('DD.MM.YYYY'))
            now.add(1, 'days')
        }

        runInAction(() => this.upcomingDateList = dates)
    }

    @action
    public onChangeCalendar = async (date) => {

        // if (date) {
        //     const start = moment(date).startOf('month')
        //     const end = moment(date).endOf('month')
        //     console.log('onChange between')
        // }

        // const {value, error}: { value?: any; error?: any } = await noThrow(apiCheck(getPaymentSettingsHistory(start, end)))

    }

}
