import React from 'react'

import { formatLongNumber, formatMoneySum, formatSum } from '~/utils'

import _ from 'lodash'
import moment from 'moment'
import {
    CartTypeDataItem,
    IssuingBanksDataItem,
    TransactionsDataItem
} from '~/pages/dashboard/types'
import styles from '../../../styles.css'
import res from '../../../res'
interface TransactionDataItem {
    payload: TransactionsDataItem | CartTypeDataItem
    stroke: string
    value: number
    name: string
    id: string
}

const TooltipContent = ({ payload }: { payload: any }) => {
    if (!payload || payload.length === 0) {
        return null
    }
    const title = payload[0].payload.issuerId

    return (
        <CartTypeTransactionsTooltip
            title={title}
            data={payload}
            showTotal={true}
        />
    )
}

const CartTypeTransactionsTooltip = ({ title, data, showTotal }: {
    title: string
    data: TransactionDataItem
    showTotal?: boolean
}) => {

    const totalAmount = data[0].payload.transactionAmountSum
    const totalCount = data[0].payload.transactionCount
    const cardTypeName = data[0].payload.cardTypeName

    return (
        <div className={`${styles.tooltip}`}>
            <table>
                <tbody>
                    <tr className={styles.title}>
                        <td>{res().tooltip.cardType}:</td>
                        <td className={styles.countColumn}>{cardTypeName}</td>
                    </tr>
                    <tr className={styles.title}>
                        <td>{res().tooltip.count}:</td>
                        <td className={styles.countColumn}>
                            {formatSum(totalCount)}
                        </td>
                    </tr>
                    <tr className={styles.title}>
                        <td>{res().tooltip.sum}:</td>
                        <td className={styles.countColumn}>
                            {formatLongNumber(totalAmount)}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export { TooltipContent }

export default CartTypeTransactionsTooltip
