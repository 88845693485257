import { CreditOrderStatus } from '~/api/products'

export default {

    [CreditOrderStatus.NEW]: {
        color: '#BDBDBD'
    },
    [CreditOrderStatus.IN_PROGRESS]: {
        color: '#FFB600'
    },
    [CreditOrderStatus.MERCHANT_CONFIRMATION]: {
        color: '#FFB600'
    },
    [CreditOrderStatus.ACCEPT]: {
        color: '#05B9A6'
    },
    [CreditOrderStatus.REJECT]: {
        color: '#F42B74'
    }
}
