import res from './res'

import { PaymentStatus } from '~/api'

res.add('kk', {
    title: 'Pos төлемдер',
    ranges: {
        today: 'бүгін',
        yesterday: 'кеше',
        thisWeek: 'ағымдағы апта',
        priorWeek: 'өткен апта',
        thisMonth: 'ағымдағы ай',
        priorMonth: 'өткен ай',
        thisQuarter: 'ағымдағы тоқсан'
    },
    anyPaymentStatus: 'Кез келген',
    paymentStatuses: {
        [PaymentStatus.auth]: 'Күтеді',
        [PaymentStatus.charged]: 'Өткізілді',
        [PaymentStatus.cancelled]: 'жойылды',
        [PaymentStatus.rejected]: 'жойылды',
        [PaymentStatus.refunded]: 'Қайтарылды',
        [PaymentStatus.failed]: 'Сәтсіз',
        [PaymentStatus.new]: 'Жаңа',
        [PaymentStatus.any]: 'Кез келген',
        [PaymentStatus.CANCEL_OLD]: 'Мерзімі бойынша жойылды',
        [PaymentStatus.TIMEOUT_3D]: '3DS Қайтарылды',
        [PaymentStatus.REFUND_IN_PROCESSING_QR]: 'Орындалуда',
        [PaymentStatus.PENDING]: 'Күтүде',
        [PaymentStatus.SUCCESS]: 'Сәтті'
    },
    sortBy: 'Бойынша сұрыптау',
    filtersPlaceholder: 'Төлем атрибуттары бойынша сүзгі',
    dataFields: {
        full: {
            accountId: 'Клиент идентификаторы',
            amount: 'Төлем сомасы',
            amountBonus: 'Go Сомасы!бонустар',
            authDate: 'Авторизация күні',
            cardMask: 'Карта маскасы',
            cardType: 'Карта түрі',
            chargeDate: 'Растау күні',
            client: 'Клиент',
            createdDate: 'Құрылған күні',
            currency: 'Валюта',
            data: 'Қосымша жолдар',
            description: 'Бөлшектер төлем',
            fee: 'Ұсталған комиссия',
            installment_id: 'Бөліп төлеу мерзімі',
            invoiceId: 'Тапсырыс нөмірі',
            ipCity: 'Қаласы',
            ipCountry: 'Ел',
            ipDistrict: 'Ауданы',
            ipLatitude: 'Ені',
            ipLongitude: 'Ұзындығы',
            ipRegion: 'Аймақ',
            issuer: 'Банк эмитент',
            issuerBankCountry: 'Эмитент банкінің елі',
            language: 'Тілі',
            merchant: 'Мерчантке сілтеме',
            payerEmail: 'Клиенттің Email',
            payerIp: 'Клиенттің IP',
            payerName: 'Карта иесінің аты',
            payerPhone: 'Клиенттің телефон нөмірі',
            postLink: 'Қайта бағыттау сілтемесі',
            postLinkStatus: 'Постлинк',
            reference: 'Карта жүйесінің жауабы',
            payoutAmount: 'Қайтару сомасы',
            payoutDate: 'Қайтару күні',
            secure3D: '3D Транзакция',
            shop: 'Дүкен атауы',
            status: 'Төлем мәртебесі',
            template: '?'
        },
        short: {
            accountId: 'Клиент идентификаторы',
            amount: 'Төлем сомасы',
            amountBonus: 'Go Сомасы!бонустар',
            authDate: 'Авторизация күні',
            cardMask: 'Карта',
            cardType: 'Карта түрі',
            chargeDate: 'Растау күні',
            client: 'Клиент',
            createdDate: 'Құрылған күні',
            currency: 'Валюта',
            data: 'Қосымша жолдар',
            description: 'Бөлшектер төлем',
            fee: 'Ұсталған комиссия',
            installment_id: 'Бөліп төлеу мерзімі',
            invoiceId: 'Тапсырыс нөмірі',
            ipCity: 'Қаласы',
            ipCountry: 'Ел',
            ipDistrict: 'Ауданы',
            ipLatitude: 'Ені',
            ipLongitude: 'Ұзындығы',
            ipRegion: 'Аймақ',
            issuer: 'Банк эмитент',
            issuerBankCountry: 'Эмитент банкінің елі',
            language: 'Тілі',
            merchant: 'Мерчантке сілтеме',
            payerEmail: 'Email',
            payerIp: 'Клиенттің IP',
            payerName: 'Карта иесінің аты',
            payerPhone: 'Tелефон',
            postLink: 'Қайта бағыттау сілтемесі',
            postLinkStatus: 'Постлинк',
            reference: 'Карта жүйесінің жауабы',
            payoutAmount: 'Қайтару сомасы',
            payoutDate: 'Қайтару күні',
            secure3D: 'Транзакция с 3D',
            shop: 'Дүкен атауы',
            status: 'Төлем мәртебесі',
            template: 'Шаблон платежной страницы'
        },
        groups: {
            location: 'Орналасқан жері',
            paymentPage: 'Төлем беті'
        },
        geolocation: 'Геолокация'
    },
    sortOptions: {
        date: 'Күні бойынша',
        postLink: 'Постлинк',
        status: 'Мәртебесі бойынша',
        type: 'Типі бойынша',
        asc: {
            date: 'Типі бойынша',
            postLink: 'басындағы сәттілер',
            status: 'басында күтеді'
        },
        desc: {
            date: 'басында жаңалары',
            postLink: 'басындағы сәтсіздері',
            status: 'алдымен қайтарылғандар'
        }
    },
    installments: {
        full: {
            '3m': '3 ай',
            '6m': '6 ай',
            '9m': '9 ай',
            '12m': '12 ай'
        },
        short: {
            '3m': '3m',
            '6m': '6m',
            '9m': '9m',
            '12m': '12m'
        }
    },
    secure3DValues: {
        true: 'Иә',
        false: 'Жоқ'
    },
    partialSum: {
        isRequired: 'Толтыруға міндетті сома',
        constraints: 'Сома төлем сомасынан аспайтын оң сан болуы тиіс'
    },
    operations: {
        fullCharge: {
            title: 'Толық есептен шығару',
            itemError: paymentReference => `#${paymentReference} Төлем бойынша толық есептен шығару қатесі `
        },
        partialCharge: {
            title: 'Ішінара есептен шығару',
            itemError: paymentReference => `#${paymentReference} Төлем бойынша ішінара есептен шығару қатесі `
        },
        cancel: {
            title: 'Болдырмау',
            itemError: paymentReference => `#${paymentReference} Төлемді болдырмау қатесі`
        },
        fullRefund: {
            title: 'Толық қайтару',
            itemError: paymentReference => `#${paymentReference} Төлемді толық қайтару қатесі`
        },
        partialRefund: {
            title: 'Ішінара қайтару',
            itemError: paymentReference => `#${paymentReference} Төлемді ішінара қайтару қатесі `
        }
    },
    postLinkStatus: {
        all: 'Барлығы',
        ok: 'Сәтті',
        fail: 'Қате'
    },
    collapseSummary: range => `${range instanceof Function ? range() : range || 'период'} кезең үшін статистика`,
    errors: {
        summaryLoadError: 'Статистиканы жүктеу қатесі',
        paymentsLoadError: 'Төлемдерді жүктеу қатесі'
    }
})
