export * from './auth'

export * from './dictionary'

export * from './payments'

// p2p @Dos
export * from './p2p'

// aft @Dos
export * from './aft'

// oct @Dos
export * from './oct'

export * from './cash-by-code'

export * from './profile'

export * from './create-order'

export * from './payment-settings'

export interface ApiResponse<T> {
    result?: T
    error?: ApiResponseError
}

export interface ApiResponseError {
    code?: string
    message?: string
}

export class ApiError extends Error {

    public constructor(error: ApiResponseError) {

        super(error.message)

        Object.setPrototypeOf(this, ApiError.prototype)

        this.name = 'ApiError'
        this.code = error.code
        this.stack = Error().stack
    }

    public code: string
}

export function apiCheck<T>(promise: Promise<ApiResponse<T>>): Promise<T>
export function apiCheck<T>(response: ApiResponse<T>): T
export function apiCheck<T>(arg: Promise<ApiResponse<T>> | ApiResponse<T>): Promise<T> | T {

    if (arg instanceof Promise) {
        return arg.then(response => {
            if (response.error) throw new ApiError(response.error)
            return response.result
        })
    }

    if (arg.error) throw new ApiError(arg.error)

    return arg.result
}
