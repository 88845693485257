import { get, patch, put } from '~/utils/ajax'
import { apiCheck } from '~/api'
import { ParamsBody, TerminalData, TerminalDataApp } from '~/api/terminal-settings/contracts'
export * from './contracts'

export const getTerminalData = async (shopId: string): Promise<TerminalDataApp[]> =>
    apiCheck(await get<TerminalData[]>(`terminals-by-shopid/${shopId}`))
        .map(v => {
            return {
                id: v.id,
                terminalId: v.terminalId,
                isActive: v.params.isActive,
                saveCard: v.params.saveCard,
                singleMessageScheme: v.params.singleMessageScheme
            }
        })

export const updateTerminal = async (terminalSettings: { params: ParamsBody }, Id: string): Promise<TerminalData> => apiCheck(await patch<TerminalData>(`terminal/${Id}`, terminalSettings))
