import React, { CSSProperties } from 'react'

import { computed } from 'mobx'

import moment from 'moment'

import { GeolocationPane, PaymentCardIcon } from '~/components'

import { Column } from '~/components/grid'

import CardType from '~/constants/card-systems'

import { EpayPayment, PaymentStatus } from '~/api/payments'

import { formatMoneySum, isNumber } from '~/utils'
import strict from '~/utils/strict'

import styles from '../styles.css'

import StatusIcon from '~/components/status-icon'

import { DetailedFieldsGroup } from '../../types'

import statusStyles from './payment-statuses'

import { isString } from 'util'
import res from '../res'

const renderCurrencyAmount = (
    value: number,
    payment: EpayPayment,
    style?: CSSProperties
) => (
    <span style={style}>
        <span className={styles.amountValue}>{formatMoneySum(value)}</span>
        <span className={styles.amountCurrency}>{payment.currency}</span>
    </span>
)

const renderDate = (value, hideHours?: boolean) =>
    value ? (
        <span className={styles.date}>
            <span>{moment(value).format('DD.MM.YYYY')}</span>
            {!hideHours && (
                <span className="date-hours">
                    {moment(value).format('hh:mm')}
                </span>
            )}
        </span>
    ) : null

const renderGeolocationText = (payment: EpayPayment) => {
    const {
        ipCity,
        ipCountry,
        ipRegion,
        ipDistrict,
        ipLatitude,
        ipLongitude
    } = payment

    let locationSpecification = [ipCountry, ipRegion, ipDistrict]
        .filter(item => !!item)
        .join(', ')

    if (locationSpecification.length) {
        locationSpecification = '(' + locationSpecification + ')'
    }

    return (
        <div>
            <div>
                {ipCity} {locationSpecification}
            </div>
            <a
                href={`http://www.google.com/maps/place/${ipLatitude},${ipLongitude}`}
            >{`N${ipLatitude}, E${ipLongitude}`}</a>
        </div>
    )
}

const renderGeolocationMap = (payment: EpayPayment) => {
    const { ipLatitude, ipLongitude } = payment

    return (
        <div className={styles.geolocationContainer}>
            <GeolocationPane
                center={{
                    lat: ipLatitude,
                    lng: ipLongitude
                }}
            />
        </div>
    )
}

const fields = computed(() => {
    const { short: titles, full: tooltips } = res().dataFields

    return strict<Array<Column<EpayPayment>>>([
        {
            field: 'shop',
            title: titles.shop,
            titleTooltip: tooltips.shop
        },
        {
            field: 'invoiceId',
            title: titles.invoiceId,
            titleTooltip: tooltips.invoiceId,
            render: value => <span className={styles.orderNumber}>{value}</span>
        },
        {
            field: 'createdDate',
            title: titles.createdDate,
            titleTooltip: tooltips.createdDate,
            render: value => renderDate(value)
        },
        {
            field: 'amount',
            title: titles.amount,
            titleTooltip: tooltips.amount,
            className: 'rightAlign',
            render: renderCurrencyAmountLabel
        },
        {
            field: 'fee',
            title: titles.fee,
            titleTooltip: tooltips.fee,
            className: 'rightAlign',
            render: (value, payment) => renderCurrencyAmount(value, payment)
        },
        {
            field: 'status',
            title: titles.status,
            titleTooltip: tooltips.status,
            render: value => renderStatusLabel(value, false),
            showTooltip: false
        },
        {
            field: 'installment_id',
            title: titles.installment_id,
            titleTooltip: tooltips.installment_id
        },
        {
            field: 'reference',
            title: titles.reference,
            titleTooltip: tooltips.reference
        },
        {
            field: 'cardMask',
            title: titles.cardMask,
            titleTooltip: tooltips.cardMask,
            render: (value, payment) =>
                !value ? (
                    undefined
                ) : (
                    <>
                        <PaymentCardIcon
                            type={payment.cardType as CardType}
                            className={styles.creditCardIcon}
                        />
                        <span className={styles.creditCardMask}>{value}</span>
                    </>
                )
        },
        {
            field: 'payerName',
            title: titles.payerName,
            titleTooltip: tooltips.payerName
        },
        {
            field: 'payerPhone',
            title: titles.payerPhone,
            titleTooltip: tooltips.payerPhone
        },
        {
            field: 'accountId',
            title: titles.accountId,
            titleTooltip: tooltips.accountId
        },
        {
            field: 'payerEmail',
            title: titles.payerEmail,
            titleTooltip: tooltips.payerEmail
        },
        {
            field: 'description',
            title: titles.description,
            titleTooltip: tooltips.description
        },
        {
            field: 'postLink',
            title: titles.postLink,
            titleTooltip: tooltips.postLink
        },
        {
            field: 'amountBonus',
            title: titles.amountBonus,
            titleTooltip: tooltips.amountBonus
        },
        {
            field: 'payoutDate',
            title: titles.payoutDate,
            titleTooltip: tooltips.payoutDate,
            render: value => renderDate(value)
        },
        {
            field: 'payoutAmount',
            title: titles.payoutAmount,
            titleTooltip: tooltips.payoutAmount,
            className: 'rightAlign',
            render: (value, payment) => renderCurrencyAmount(value, payment)
        }
    ])
})

const detailedFields = computed(() => {
    const {
        groups: groupsTitles,
        short: titles,
        geolocation: geolocationTitle
    } = res().dataFields

    return strict<Array<DetailedFieldsGroup<EpayPayment>>>([
        {
            id: 'main',
            subgroups: [
                [
                    {
                        field: 'status',
                        title: titles.status,
                        render: value => {
                            const title = res().paymentStatuses[value]
                            return value ? (
                                <>
                                    <StatusIcon
                                        title={title}
                                        color={
                                            (statusStyles[value] || {}).color
                                        }
                                    />
                                    <span className={styles.statusLabel}>
                                        {title}
                                    </span>
                                </>
                            ) : null
                        }
                    },
                    {
                        field: 'createdDate',
                        title: titles.createdDate,
                        render: value => renderDate(value)
                    },
                    {
                        field: 'shop',
                        title: titles.shop
                    },
                    {
                        field: 'invoiceId',
                        title: titles.invoiceId
                    },
                    {
                        field: 'client',
                        title: titles.client
                    },
                    {
                        field: 'description',
                        title: titles.description
                    }
                ],
                [
                    {
                        field: 'amount',
                        title: titles.amount,
                        render: (value, payment) => {
                            const statusStyle = statusStyles[payment.status]
                            return renderCurrencyAmount(
                                value,
                                payment,
                                statusStyle
                                    ? { color: statusStyle.color }
                                    : null
                            )
                        }
                    },
                    {
                        field: 'fee',
                        title: titles.fee,
                        render: (value, payment) =>
                            renderCurrencyAmount(value, payment)
                    },
                    {
                        field: 'amountBonus',
                        title: titles.amountBonus
                    }
                ],

                [
                    {
                        field: 'installment_id',
                        title: titles.installment_id
                    }
                ],

                [
                    {
                        field: 'authDate',
                        title: titles.authDate,
                        render: value => renderDate(value, true)
                    },
                    {
                        field: 'issuer',
                        title: titles.issuer
                    },
                    {
                        field: 'cardMask',
                        title: titles.cardMask,
                        render: (value, payment) =>
                            !value ? (
                                undefined
                            ) : (
                                <>
                                    <PaymentCardIcon
                                        type={payment.cardType as CardType}
                                        className={styles.creditCardIcon}
                                    />
                                    <span className={styles.creditCardMask}>
                                        {value}
                                    </span>
                                </>
                            )
                    },
                    {
                        field: 'secure3D',
                        title: titles.secure3D,
                        render: value =>
                            value
                                ? res().secure3DValues.true
                                : res().secure3DValues.false
                    },
                    {
                        field: 'merchant',
                        title: titles.merchant
                    }
                ],

                [
                    {
                        field: 'payerName',
                        title: titles.payerName
                    },
                    {
                        field: 'payerEmail',
                        title: titles.payerEmail
                    },
                    {
                        field: 'payerPhone',
                        title: titles.payerPhone
                    },
                    {
                        field: 'chargeDate',
                        title: titles.chargeDate,
                        render: value => renderDate(value),
                        visibility: (value, payment) =>
                            payment.status === PaymentStatus.charged
                    },
                    {
                        field: 'payoutDate',
                        title: titles.payoutDate,
                        visibility: (value, payment) =>
                            payment.status === PaymentStatus.refunded
                    },
                    {
                        field: 'payoutAmount',
                        title: titles.payoutAmount,
                        visibility: (value, payment) =>
                            payment.status === PaymentStatus.refunded
                    }
                ]
            ]
        },
        {
            id: 'customerData',
            title: groupsTitles.location,
            subgroups: [
                [
                    {
                        field: 'payerIp',
                        title: titles.payerIp
                    },
                    {
                        field: 'geolocationText',
                        title: geolocationTitle,
                        render: (value, payment) =>
                            renderGeolocationText(payment),
                        withoutBorder: true
                    },
                    {
                        field: 'geolocationMap',
                        title: null,
                        render: (value, payment) =>
                            renderGeolocationMap(payment),
                        wide: true,
                        withoutBorder: true
                    }
                ]
            ]
        },
        {
            id: 'paymentPage',
            title: groupsTitles.paymentPage,
            subgroups: [
                [
                    {
                        field: 'language',
                        title: titles.language
                    },
                    {
                        field: 'template',
                        title: titles.template
                    },
                    {
                        field: 'postLink',
                        title: titles.postLink
                    },
                    {
                        field: 'postLinkStatus',
                        title: titles.postLinkStatus,
                        render: value =>
                            value === undefined ? (
                                undefined
                            ) : value ? (
                                <span
                                    className={`${styles.postlinkStatus} ${styles.ok}`}
                                >
                                    {res().postLinkStatus.ok}
                                </span>
                            ) : (
                                <span
                                    className={`${styles.postlinkStatus} ${styles.failed}`}
                                >
                                    {res().postLinkStatus.fail}
                                </span>
                            )
                    },
                    {
                        field: 'client',
                        title: titles.client
                    }
                ]
            ]
        }
    ])
})

export function renderCurrencyAmountLabel(value, payment) {
    const statusStyle = statusStyles[payment.status]
    const valueNum = isString(value) ? parseFloat(value) : value
    return renderCurrencyAmount(
        valueNum,
        payment,
        statusStyle ? { color: statusStyle.color } : null
    )
}
export function renderStatusLabel(value, renderText: boolean = false) {
    return value ? (
        <React.Fragment>
            <StatusIcon
                title={res().paymentStatuses[value]}
                color={(statusStyles[value] || {}).color}
            />
            &nbsp;
            {renderText && <span>{res().paymentStatuses[value]}</span>}
        </React.Fragment>
    ) : null
}

export { detailedFields }

export default fields
