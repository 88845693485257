import { action, computed, observable, runInAction } from 'mobx'
import React from 'react'
import { HintType, Step } from '../interactive-hints/constants'
import BrowserStore from '~/services/browser/browser-state'
import { sectionsMobile } from './constants'
import InteractiveHint from '~/utils/interactive-hint'

class InteractiveMobileHintsStore {

    constructor(sectionKeys: string[]) {
        if (BrowserStore.isMobile) {
            this.steps = sectionsMobile(sectionKeys)

            if (sectionKeys.length > 0) {
                this.startShowing()
                InteractiveHint.switchFlagsOff(sectionKeys)
            }
        }
    }

    @observable
    public isShowMode: boolean = true

    @observable
    public currentStepIndex: number = 0

    @observable
    public steps: Step[]

    @observable
    public isStepChanging: boolean = false

    @computed
    public get currentStep() {
        return this.steps[this.currentStepIndex]
    }

    @computed
    public get isCurrentStepTypeSider() {
        return this.currentStep.type === HintType.sider
    }

    @action.bound
    public goNextStep() {
        const nextStepIndex = this.currentStepIndex + 1
        if (nextStepIndex < this.steps.length) {
            this.changeStep(nextStepIndex, 100)
        } else {
            this.finish()
        }
    }

    @action
    private async startShowing() {
        // this.updateSectionFlag()
        await this.changeStep(0, 200)
    }

    @action.bound
    private finish() {
        this.setShowMode(false)
    }

    @action
    private async setShowMode(mode: boolean) {
        if (mode) {
            await this.steps[this.currentStepIndex].onBeforeShow?.()
            runInAction(() => {
                this.isShowMode = mode
            })
        } else {
            runInAction(() => {
                this.isShowMode = mode
            })
            await this.steps[this.currentStepIndex].onAfterHide?.()
        }
    }

    @action
    private async changeStep(newStep: number, delay = 0) {
        this.isStepChanging = true
        await this.steps[this.currentStepIndex]?.onAfterHide?.()
        await this.steps[newStep].onBeforeShow?.()
        runInAction(() => {
            this.currentStepIndex = newStep
            const isVeryFirstStep = newStep === 0
            if (isVeryFirstStep) {
                runInAction(() => {
                    this.setShowMode(true)
                })
            }
        })

        setTimeout(() => {
            runInAction(() => {
                this.isStepChanging = false
            })
        }, delay + 200)
    }
}

export default InteractiveMobileHintsStore