import React from 'react'

import { action, observable } from 'mobx'

import { observer } from 'mobx-react'

import { Checkbox, Pane, SortablePane } from '~/components'
import { MenuIcon } from '~/components/icon'

import styles from './styles.css'

export interface ItemField {
    field: string
    title: React.ReactNode
    visible: boolean
}

interface EditableFieldsListProps {
    fields: ItemField[]
    fieldsOrder?: string[]
    sortable?: boolean
    className?: string
    onFieldsOrderChange: (order: string[]) => void
}

const FIELD_HEIGHT = 35

@observer
class EditableFieldsList extends React.Component<EditableFieldsListProps, {}> {
    public constructor(props) {
        super(props)
    }

    public render() {
        const {
            className,
            fields,
            fieldsOrder,
            sortable,
            onFieldsOrderChange
        } = this.props

        if (!fields) {
            return null
        }

        return (
            <div className={`${styles.container} ${className || ''}`}>
                <SortablePane
                    direction="vertical"
                    style={{ height: `${FIELD_HEIGHT * fields.length}px ` }}
                    order={fieldsOrder}
                    onOrderChange={onFieldsOrderChange}
                    onDragStart={this.onItemDragStart}
                    onDragStop={this.onItemDragStop}
                    isSortable={!!sortable}
                    disableEffect={true}
                >
                    {fields.map(item => this.renderItemPane(item))}
                </SortablePane>
            </div>
        )
    }

    private renderItemPane(item: ItemField) {
        return (
            <Pane
                key={item.field}
                size={{ width: '100%', height: FIELD_HEIGHT }}
                maxHeight={FIELD_HEIGHT}
                minHeight={FIELD_HEIGHT}
                className={`${styles.field} ${this.props.sortable ? styles.canDrag : ''
                    } ${item.field === this.draggableItemKey ? styles.draggable : ''
                    }`}
                resizable={{ x: false, y: false, xy: false }}
            >
                <span
                    className={styles.checkboxWrapper}
                    onMouseDown={e => e.stopPropagation()}
                >
                    <Checkbox
                        checked={item.visible}
                        onChange={e =>
                            this.handleItemVisibilityChange(
                                item.field,
                                e.target.checked
                            )
                        }
                    >
                        {item.title}
                    </Checkbox>
                </span>
                {this.props.sortable && (
                    <div className={styles.dragIcon}>
                        <MenuIcon />
                    </div>
                )}
            </Pane>
        )
    }

    @action.bound
    private handleItemVisibilityChange(field, isVisible: boolean) {
        this.props.fields.find(item => item.field === field).visible = isVisible
    }

    @action.bound
    private onItemDragStart(e: React.MouseEvent<HTMLElement>, itemKey: string) {
        this.draggableItemKey = itemKey

        return null
    }

    @action.bound
    private onItemDragStop() {
        this.draggableItemKey = null

        return null
    }

    @observable
    private draggableItemKey?: string
}

export default EditableFieldsList
