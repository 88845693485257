import { computed } from 'mobx'
import moment from 'moment'
import React, { CSSProperties, useRef, useState } from 'react'
import { Column } from '~/components/grid'
import strict from '~/utils/strict'
import res from './res'
import CreditProductsStore from '~/pages/products/credit/credit-products-store'
import { CreditOrder } from '~/api/products'
import StatusIcon from '~/components/status-icon'
import statusStyles from '../constants/order-statuses'
import { Tooltip } from 'antd'
import styles from '~/pages/products/credit/styles.css'

export const renderOrderStatus = (
    value: number,
    title: string,
    style?: CSSProperties
) => (
    <div>
        <StatusIcon
            title={title}
            color={style?.color}
        />
        &nbsp;
        <span className={styles.statusLabel}
            style={{ color: style?.color }}>
            {title}
        </span>
    </div>
)

export interface RenderMultipleInterface {
    value: string[] | string
}

function RenderMultipleValues(props: RenderMultipleInterface) {
    const { value } = props
    const refContainer = useRef(null)
    const refContent = useRef(null)
    const [tooltipVisible, setTooltipVisible] = useState(false)
    const getLastChildWidth = elem => {
        let childWidth = elem.getBoundingClientRect().width
        if (elem.firstChild && elem.firstChild.getBoundingClientRect) {
            childWidth = getLastChildWidth(elem.firstChild)
        }
        return childWidth
    }
    const multipleTooltip =
        value instanceof Array
            ? value.map((item, key) => <div key={key}>{item}</div>)
            : [value]
    const multiValue = value instanceof Array ? value.length > 1 : false
    const displayedElement = value instanceof Array ? value[0] : value

    const onVisibleChange = (show: boolean) => {
        if (!refContainer || !refContent) return

        if (show) {
            const container = refContainer.current.getBoundingClientRect().width
            const content = getLastChildWidth(refContent.current)
            if (container >= content && !multiValue) return
        }

        setTooltipVisible(show)
    }
    if (!value) {
        return (
            <Tooltip title={res().tooltips.noValues} placement="bottom">
                <span className={styles.values}>-</span>
            </Tooltip>
        )
    }

    return (
        <Tooltip
            title={multipleTooltip}
            placement="bottom"
            onVisibleChange={onVisibleChange}
            visible={tooltipVisible}
        >
            <span
                className={`${styles.values} ${multiValue ? styles.multiValue : ''
                    }`}
                ref={refContainer}
            >
                <span ref={refContent}>
                    <span className={styles.firstValue}>
                        <span>{displayedElement}</span>
                    </span>
                    {multiValue && (
                        <span className={styles.moreValues}>
                            +{value.length - 1}
                        </span>
                    )}
                </span>
            </span>
        </Tooltip>
    )
}

export const renderDate = (value, hideHours?: boolean, inline?: boolean) => {
    const dateRender = value ? (
        <span className={styles.date}>
            <span>{moment(value).format('DD.MM.YYYY')}</span> {inline && '/ '}
            {!hideHours && (
                <span
                    className={`${styles.dateHours} ${inline ? styles.inline : ''
                        }`}
                >
                    {moment(value).format('hh:mm')}
                </span>
            )}
        </span>
    ) : null

    return dateRender
}

export const fields = (store: CreditProductsStore) =>
    computed(() => {
        const { short: titles, full: tooltips } = res().dataFields

        return strict<Array<Column<CreditOrder>>>([
            {
                field: 'createdAt',
                title: titles.createdAt,
                render: value => renderDate(value, false, true),
                titleTooltip: tooltips.createdAt,
                width: 10
            },
            {
                field: 'statusName',
                title: titles.statusName,
                render: (value) => {
                    const title = res().creditOrderStatus[value]
                    const statusStyle = statusStyles[value]
                    return renderOrderStatus(
                        value,
                        title,
                        statusStyle
                    )
                },
                titleTooltip: tooltips.statusName,
                width: 11
            },
            {
                field: 'emails',
                title: titles.emails,
                render: (value) => (
                    <RenderMultipleValues value={value}></RenderMultipleValues>
                ),
                titleTooltip: tooltips.emails
            },
            {
                field: 'shops',
                title: titles.shopIds,
                render: (shops) => (
                    !shops ? (
                        <div>-</div>
                    ) : (
                        shops.map(shop => {
                            return (
                                <div key={shop.id}>
                                    <span>{shop.name}</span>
                                </div>
                            )
                        })
                    )
                ),
                titleTooltip: tooltips.shopIds
            },
            {
                field: 'checkingAccount',
                title: titles.checkingAccount,
                render: (value: string) => (
                    <span className={styles.mainColor}>
                        {value}
                    </span>
                ),
                titleTooltip: tooltips.checkingAccount,
                width: 14
            },
            {
                field: 'creditConditions',
                title: titles.creditConditions,
                render: (creditConditions, index) => (
                    !creditConditions ? (
                        <div>-</div>
                    ) : (creditConditions.map(condition => {
                        return (
                            <div key={condition.id}>
                                {condition.interestRate === 0 ? (
                                    <div>
                                        <span><span className={styles.conditionValue}>{res().installment}:</span> {condition.interestRate} % - {res().conditionLabel(condition.monthCount)}</span>
                                    </div>
                                ) : (
                                    <div>
                                        <span><span className={styles.conditionValue}>{res().credit}:</span> {condition.interestRate} % - {res().conditionLabel(condition.monthCount)}</span>
                                    </div>
                                )}
                            </div>
                        )
                    })
                    )
                ),
                titleTooltip: tooltips.creditConditions,
                width: 15
            }

        ])
    })
