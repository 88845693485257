import res from './res'

res.add('kk', {
    slides: [
        {
            title: 'ePay-ге қош келдіңізі!',
            description: 'Жеке кабинетте сіз өз бизнесіңізді онлайн басқарасыз.'
        },
        {
            title: 'Онлайн бизнес статистикасы',
            description: 'Статистикада сіз бақылай аласыз: сәтті / сәтсіз транзакцияларды, эмитент банктерді, арналарды'
        },
        {
            title: 'Транзакцияларды басқару',
            description: 'Икемді сүзгі жүйесі арқылы транзакцияларды егжей-тегжейлі іздеу. Төлемдер тарихын қадағалаңыз және басқарыңыз'
        },
        {
            title: 'Онлайн үзінді көшірмелер',
            description: 'Сіз үзінді-көшірмені автоматты түрде поштаға жіберуді орната аласыз.'
        },
        {
            title: 'Қызметкерлерді қосу',
            description: 'Қызметкерлерді басқарыңыз, қол жетімділіктің қажетті деңгейін беріңіз'
        },
        {
            title: 'Және тағы басқалар!',
            description: 'Біз процесті жеңілдету және онлайн-бизнес тәжірибеңізді жақсарту үшін бар күшімізді саламыз.'
        }
    ],
    skipButton: 'Өткізіп жіберу',
    startButton: 'Бастау',
    modal: {
        title: 'Оқыту',
        description: 'Сіз жіберіп алғыңыз келетініне сенімдісіз бе?',
        okButton: 'Ия',
        cancelButton: 'Жоқ'
    }
})
