import res from './res'

import { PaymentStatus } from '~/api'

res.add('ru', {
    title: 'Pos платежи',
    ranges: {
        today: 'сегодня',
        yesterday: 'вчера',
        thisWeek: 'текущую неделю',
        priorWeek: 'прошлую неделю',
        thisMonth: 'текущий месяц',
        priorMonth: 'прошлый месяц',
        thisQuarter: 'текущий квартал'
    },
    anyPaymentStatus: 'Любой',
    paymentStatuses: {
        [PaymentStatus.auth]: 'Ожидает',
        [PaymentStatus.charged]: 'Проведен',
        [PaymentStatus.cancelled]: 'Отменен',
        [PaymentStatus.rejected]: 'Отменен',
        [PaymentStatus.refunded]: 'Возвращен',
        [PaymentStatus.failed]: 'Неудачный',
        [PaymentStatus.new]: 'Новый',
        [PaymentStatus.any]: 'Любой',
        [PaymentStatus.CANCEL_OLD]: 'Отменен по сроку',
        [PaymentStatus.TIMEOUT_3D]: '3DS Отменен',
        [PaymentStatus.REFUND_IN_PROCESSING_QR]: 'В процессе',
        [PaymentStatus.PENDING]: 'В ожидании',
        [PaymentStatus.SUCCESS]: 'Успешный'
    },
    sortBy: 'Сортировать по',
    filtersPlaceholder: 'Фильтр по атрибутам платежей',
    dataFields: {
        full: {
            accountId: 'Идентификатор клиента',
            amount: 'Сумма платежа',
            amountBonus: 'Сумма Go!бонусов',
            authDate: 'Дата авторизации',
            cardMask: 'Маска карты',
            cardType: 'Тип карты',
            chargeDate: 'Дата подтверждения',
            client: 'Клиент',
            createdDate: 'Дата создания',
            currency: 'Валюта',
            data: 'Дополнительные поля',
            description: 'Детали платежа',
            fee: 'Удержанная комиссия',
            installment_id: 'Период рассрочки',
            invoiceId: 'Номер заказа',
            ipCity: 'Город',
            ipCountry: 'Страна',
            ipDistrict: 'Район',
            ipLatitude: 'Широта',
            ipLongitude: 'Долгота',
            ipRegion: 'Регион',
            issuer: 'Банк эмитент',
            issuerBankCountry: 'Страна банка эмитента',
            language: 'Язык',
            merchant: 'Ссылка на мерчанта',
            payerEmail: 'Email клиента',
            payerIp: 'IP клиента',
            payerName: 'Имя владельца карты',
            payerPhone: 'Номер телефона клиента',
            postLink: 'Ссылка перенаправления',
            postLinkStatus: 'Постлинк',
            reference: 'Ответ от карточной системы',
            payoutAmount: 'Сумма возврата',
            payoutDate: 'Дата возврата',
            secure3D: 'Транзакция с 3D',
            shop: 'Название магазина',
            status: 'Статус платежа',
            template: '?'
        },
        short: {
            accountId: 'ID клиента',
            amount: 'Сумма',
            amountBonus: 'Go!бонус',
            authDate: 'Дата авторизации',
            cardMask: 'Карта',
            cardType: 'Тип карты',
            chargeDate: 'Дата подтверждения',
            client: 'Клиент',
            createdDate: 'Дата',
            currency: 'Валюта',
            data: 'Дополнительные поля',
            description: 'Детали',
            fee: 'Комиссия',
            installment_id: 'Рассрочка',
            invoiceId: '№ заказа',
            ipCity: 'Город',
            ipCountry: 'Страна',
            ipDistrict: 'Район',
            ipLatitude: 'Широта',
            ipLongitude: 'Долгота',
            ipRegion: 'Регион',
            issuer: 'Банк эмитент',
            issuerBankCountry: 'Страна банка эмитента',
            language: 'Язык',
            merchant: 'Ссылка на мерчанта',
            payerEmail: 'Email',
            payerIp: 'IP клиента',
            payerName: 'Плательщик',
            payerPhone: 'Телефон',
            postLink: 'Ссылка перенаправления',
            postLinkStatus: 'Постлинк',
            reference: 'РРН',
            payoutAmount: 'Возврат-сумма',
            payoutDate: 'Возврат-дата',
            secure3D: 'Транзакция с 3D',
            shop: 'Магазин',
            status: 'Статус',
            template: 'Шаблон платежной страницы'
        },
        groups: {
            location: 'Местоположение',
            paymentPage: 'Платежная страница'
        },
        geolocation: 'Геолокация'
    },
    sortOptions: {
        date: 'Дате',
        postLink: 'Постлинк',
        status: 'Статусу',
        type: 'Типу',
        asc: {
            date: 'старые вначале',
            postLink: 'успешные вначале',
            status: 'вначале ожидают'
        },
        desc: {
            date: 'новые вначале',
            postLink: 'неуспешные вначале',
            status: 'вначале возвраты'
        }
    },
    installments: {
        full: {
            '3m': '3 месяца',
            '6m': '6 месяцев',
            '9m': '9 месяцев',
            '12m': '12 месяцев'
        },
        short: {
            '3m': '3m',
            '6m': '6m',
            '9m': '9m',
            '12m': '12m'
        }
    },
    secure3DValues: {
        true: 'Да',
        false: 'Нет'
    },
    partialSum: {
        isRequired: 'Сумма обязательна к заполнению',
        constraints: 'Сумма должна быть положительным числом не более суммы платежа'
    },
    operations: {
        fullCharge: {
            title: 'Полное списание',
            itemError: paymentReference => `Ошибка полного списания по платежу #${paymentReference}`
        },
        partialCharge: {
            title: 'Частичное списание',
            itemError: paymentReference => `Ошибка частичного списания по платежу #${paymentReference}`
        },
        cancel: {
            title: 'Отмена',
            itemError: paymentReference => `Ошибка отмены платежа #${paymentReference}`
        },
        fullRefund: {
            title: 'Полный возврат',
            itemError: paymentReference => `Ошибка полного возврата платежа #${paymentReference}`
        },
        partialRefund: {
            title: 'Частичный возврат',
            itemError: paymentReference => `Ошибка частичного возврата платежа #${paymentReference}`
        }
    },
    postLinkStatus: {
        all: 'Все',
        ok: 'Успешно',
        fail: 'Ошибка'
    },
    collapseSummary: range => `Статистика за ${range instanceof Function ? range() : range || 'период'}`,
    errors: {
        summaryLoadError: 'Ошибка загрузки статистики',
        paymentsLoadError: 'Ошибка загрузки платежей'
    }
})
