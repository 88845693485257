import React, { KeyboardEvent } from 'react'

import { action, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'

import { Button, Popover } from 'antd'

import styles from './styles.css'

export interface PopConfirmProps {
    children?: React.ReactNode
    title?: React.ReactNode
    content?: React.ReactNode
    okText: string
    cancelText: string
    onOk?: () => boolean
    isPayout?: boolean
    onCancel?: () => void
}

@observer
export default class PopConfirm extends React.Component<PopConfirmProps, {}> {
    constructor(props: PopConfirmProps, context: any) {
        super(props, context)

        this.visible = false
    }

    public render() {
        const props = this.props

        const content = (
            <div onKeyDown={e => this.onKeyDown(e)}>
                {this.props.isPayout ?
                    <>
                        <div className={styles.content}>{props.content}</div>
                        <div className={styles.contentButtons}>
                            <div>
                                <Button type="primary" onClick={this.ok} style={{ width: '100%' }}>
                                    {props.okText}
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={this.cancel}
                                    data-prevent-validation={true}
                                    style={{ width: '100%', marginTop: '12px' }}
                                >
                                    {props.cancelText}
                                </Button>
                            </div>

                        </div>
                    </> :
                    <>
                        <div className={styles.content}>{props.content}</div>
                        <div className={styles.footer}>
                            <Button type="primary" onClick={this.ok}>
                                {props.okText}
                            </Button>
                            <Button
                                onClick={this.cancel}
                                data-prevent-validation={true}
                            >
                                {props.cancelText}
                            </Button>
                        </div>
                    </>
                }
            </div>
        )

        return (
            <Popover
                title={props.title}
                content={content}
                trigger="click"
                placement="bottom"
                visible={this.visible}
                onVisibleChange={this.setVisible}
                overlayClassName={styles.popconfirm}
            >
                {props.children}
            </Popover>
        )
    }

    private onKeyDown(e: KeyboardEvent) {
        if (e.key === 'Enter') {
            this.ok()
        } else if (e.key === 'Escape') {
            this.cancel()
        }
    }

    @action.bound
    private ok() {
        if (this.props.onOk && !this.props.onOk()) {
            return
        }

        this.visible = false
    }

    @action.bound
    private cancel() {
        this.visible = false

        this.props.onCancel && this.props.onCancel()
    }

    @action.bound
    private setVisible(value: boolean) {
        this.visible = value
        this.props.onCancel && this.props.onCancel()
    }

    @observable
    private visible: boolean
}
