import { action, computed, observable, runInAction } from 'mobx'
import { noThrow } from '~/utils/control-flow'
import { apiCheck, getShops, Shop } from '~/api'
import res from './res'
import { notification } from '~/components'
import { getTerminalData, ParamsBody, TerminalData, TerminalDataApp, updateTerminal } from '~/api/terminal-settings'
import message from '~/utils/message'
import BrowserStore from '~/services/browser/browser-state'
import InteractiveMobileHintsStore from '~/components/interactive-mobile-hints/interactive-mobile-hints-store'
import InteractiveHint from '~/utils/interactive-hint'

export default class TerminalSettingsStore {

    constructor() {
        this.loadData()

        setTimeout(() => {
            this.showHintOnMobile()
        }, 500)
    }

    @computed
    public get isModified() {
        return this.terminalData.some(v => v.originalStateHash !== TerminalSettingsStore.getHash(v))
    }

    @observable
    public isLoading: boolean

    @observable
    public isError: boolean

    @observable
    public shops: Shop[] = []

    @observable
    public currentShopId: string

    @observable
    public terminalData: TerminalDataApp[] = []

    @observable.ref
    public mobileHintStore: InteractiveMobileHintsStore

    @action
    public showHints(sectionKeys: string[]) {
        this.mobileHintStore = new InteractiveMobileHintsStore(sectionKeys)
    }

    @action
    public hideHints() {
        this.mobileHintStore = null
    }

    @action.bound
    public async loadTerminalData(shopId: string) {

        this.isLoading = true

        const { value, error }: { value?: TerminalDataApp[], error?: any } = await noThrow(getTerminalData(shopId))

        runInAction(() => {

            if (error) {
                message.error(error)
            }
            else {

                value.forEach(v => v.originalStateHash = TerminalSettingsStore.getHash(v))
                this.terminalData = value

                this.currentShopId = shopId
            }

            this.isError = !!error
            this.isLoading = false
        })
    }

    @action.bound
    public async updateDataAll() {

        this.isLoading = true

        const changes = this.terminalData.filter(v => v.originalStateHash !== TerminalSettingsStore.getHash(v))
        if (!changes?.length) return

        let result: boolean = true
        for (const data of changes) {

            result = await TerminalSettingsStore.updateData({
                isActive: data.isActive,
                saveCard: data.saveCard,
                singleMessageScheme: data.singleMessageScheme
            }, data.id)

            if (!result) break

            // on success recalculate hash
            runInAction(() => data.originalStateHash = TerminalSettingsStore.getHash(data))
        }

        if (result) {
            notification.success({
                message: res().messages.saveSuccess
            })
        }

        runInAction(() => {
            this.isError = !res
            this.isLoading = false
        })
    }

    private static async updateData(data: ParamsBody, terminalId: string): Promise<boolean> {

        const { error }: { value?: TerminalData, error?: any } = await noThrow(updateTerminal({ params: data }, terminalId))

        if (error) {
            message.error(error)
        }

        return !error
    }

    private static getHash(obj: TerminalDataApp): number {
        if (!obj) return 0

        return ((obj.saveCard ? 100 : 0) + (obj.singleMessageScheme ? 10 : 0) + (obj.isActive ? 1 : 0))
    }

    @action.bound
    private async loadData() {

        this.isLoading = true

        const shopList = await noThrow(apiCheck(getShops()))

        if (shopList.error || !shopList.value?.length) {
            notification.error({
                message: res().messages.errorTitle,
                description: res().messages.cannotLoadShops,
                duration: 0
            })

            runInAction(() => {
                this.isError = true
                this.isLoading = false
            })

            return
        }

        runInAction(() => this.shops = shopList.value)

        await this.loadTerminalData(this.shops[0].id)

        runInAction(() => this.isLoading = false)
    }

    private showHintOnMobile() {
        if (BrowserStore.isMobile) {
            const hintSections = InteractiveHint.getPageFlags('terminalSettings')

            if (hintSections) {
                this.showHints(hintSections)
            }
        }
    }
}