import res from './res'

res.add('ru', {
    orderText: 'Заявка №',
    creditOrderTitle: 'Заявки на рассрочку и кредитование',
    newOrderTitle: 'Новая заявка',
    confirmButton: 'Подтвердить заявку',
    cancelButton: 'Отклонить',
    accept: 'Подтвердить',
    acceptConfirmationTitle: 'Вы уверены, что хотите подтвердить заявку?',
    acceptNotificationTitle: 'Заявка успешно одобрена',
    acceptFailedNotificationTitle: 'Произошла ошибка, попробуйте позже',
    acceptNotificationDescription: 'Обновите таблицу, чтобы увидеть обновленный статус заявки',
    rejectReason: 'Причина отклонения',
    noDataLabel: 'Нет данных'
})
