import React from 'react'

import { Card, Layout, NavLink } from '~/components'

import Footer from '../components/footer'

const { Content } = Layout

import LanguageSelect from './language-select'
import FooterLinks from './components/footer-links'
import styles from './styles.css'

const AuthLayout = (props: { children?: React.ReactNode }) => {
    return (
        <Layout>
            <Content className={styles.container}>
                <NavLink href="/" className={styles.logo} />
                <LanguageSelect />
                <Card className={styles.content} bordered={false}>
                    {props.children}
                </Card>
                <FooterLinks />
            </Content>
            <Footer />
        </Layout>
    )
}

export default AuthLayout
