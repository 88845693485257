import React from 'react'

import res from './res'

res.add('en', {
    title: 'My Profile',
    labels: {
        language: 'Language',
        email: 'Email',
        password: 'Password',
        role: 'Role'
    },
    buttons: {
        changePassword: 'Change password'
    },
    lastUsage: {
        header: 'Login history',
        table: {
            headers: {
                geo: 'Location',
                recordDate: 'Time',
                userSystem: 'OS',
                ip: 'IP address',
                status: 'Status'
            }
        }
    },
    changePasswordInfo: 'Password change',
    changePasswordSuccess: email => (
        <>
            Email with instructions was sent to <b>{email}</b>
        </>
    )
})
