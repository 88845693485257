import { observer, Provider } from 'mobx-react'
import React from 'react'

import AppStore from '~/app-store'
import { Card } from '~/components'

import Footer from '../components/footer'

import LayoutStore from './layout-store'

import { isString, some } from 'lodash'
import { Swipeable, useSwipeable } from 'react-swipeable'

import BrowserStore from '~/services/browser/browser-state'
import Header from './header'
import Sider from './sider'
import styles from './styles.css'
import InteractiveHints from '~/components/interactive-hints'

interface MasterProps {
    children: React.ReactNode
    appStore: typeof AppStore
    layoutStore: typeof LayoutStore
}

@observer
class MainLayout extends React.Component<MasterProps> {
    public componentDidMount() {
        if (BrowserStore.isMobile) {
            const bodyelement = document.getElementById('appBody')
            bodyelement.className = 'mobileBody'
        }
    }
    public onSwipe(eventdata, isOpening, direction?: string) {
        const isSelfTarget = some(eventdata.event.path, pathPart => {
            return (
                pathPart.className &&
                isString(pathPart.className) &&
                pathPart.className.includes('siderMobile')
            )
        })
        const containsBlockingElement: boolean = isSelfTarget
            ? false
            : some(eventdata.event.path, pathPart => {
                return (
                    pathPart.className &&
                    isString(pathPart.className) &&
                    pathPart.className.includes('ant-drawer')
                )
            })
        if (!containsBlockingElement) {
            this.props.layoutStore.toggleSider(isOpening)
        }
    }
    public render() {
        const { appStore, layoutStore, children } = this.props
        const mainClassName = BrowserStore.isMobile
            ? `${styles.layoutWrapper} ${styles.mobile}`
            : `${styles.defaultLayout} ${styles.layoutWrapper}`
        return (
            <React.Fragment>
                <div className={`${mainClassName} main-layout`}>
                    {!BrowserStore.isMobile && (
                        <Header appStore={appStore} layoutStore={layoutStore} />
                    )}
                    <Swipeable
                        onSwipedRight={eventData =>
                            this.onSwipe(eventData, true, 'right')
                        }
                        onSwipedLeft={eventData =>
                            this.onSwipe(eventData, false, 'left')
                        }
                        delta={40}
                        className={styles.pageContainer}
                    >
                        <div className={styles.mainContentWrapper}>
                            {!BrowserStore.isMobile && (
                                <Sider
                                    layoutStore={layoutStore}
                                    appStore={appStore}
                                />
                            )}
                            <div className={`${styles.pageContentWrapper} ${BrowserStore.isMobile ? '' : (layoutStore.isSiderVisible ? 'siderOpened' : 'siderClosed')}`}>
                                {children}
                                <div className={styles.floatBlock}></div>
                            </div>
                        </div>
                    </Swipeable>
                </div>
                {!BrowserStore.isMobile && <Footer />}
                {BrowserStore.isMobile && (
                    <Swipeable
                        onSwipedLeft={eventData =>
                            this.onSwipe(eventData, false, 'left')
                        }
                        className={styles.swipeContainer}
                    >
                        <Sider layoutStore={layoutStore} appStore={appStore} />
                    </Swipeable>
                )}
                {appStore.interactiveHints}
            </React.Fragment>
        )
    }
}

export default MainLayout
