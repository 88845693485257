import React from 'react'
import res from './res'

res.add('ru', {
    title: 'Заявка на продукт ePay-терминалы',
    contracts: 'Контрактов',
    contract: 'Контракт',
    sortBy: 'Сортировать по:',
    sortOptions: {
        date: 'Дате последней выписки',
        name: 'Названию',
        contract: 'Контракту',
        type: 'Типу',
        asc: {
            date: 'cтарые вначале',
            name: 'по возрастанию',
            contract: 'по возрастанию',
            type: 'по возрастанию'
        },
        desc: {
            date: 'новые вначале',
            name: 'по убыванию',
            contract: 'по убыванию',
            type: 'по убыванию'
        }
    },
    dateRanges: {
        yesterday: 'вчера',
        priorWeek: 'прошлую неделю',
        priorMonth: 'прошлый месяц',
        customPeriod: 'указанный период'
    },
    statusType: {
        forming: 'Формирование заявки',
        created: 'Подача заявки', // Создана,
        processing: 'Обработка', // - В обработке,
        approved: 'Утверждение', // - Утверждена,
        returned: 'Возвращено на доработку', // - Возвращена на доработку
        canceled: 'Отменена', // - От
        retry: 'Повторная подача'
    },
    statusSteps: {
        forming: 'Формирование заявки',
        created: 'Заявка подана', // Создана,
        processing: 'Обработка', // - В обработке,
        approved: 'Утверждение', // - Утверждена,
        returned: 'Возвращено на доработку', // - Возвращена на доработку
        canceled: 'Отменена', // - От
        retry: 'Повторная подача'
    },
    statusAction: {
        forming: 'Редактировать',
        created: 'Отменить', // Создана,
        processing: 'Отменить', // - В обработке,
        approved: 'Одобрена', // - Утверждена,
        returned: 'Редактировать', // - Возвращена на доработку
        canceled: 'Отиенена', // - От
        retry: 'Отменить'
    },
    errors: {
        productsLoadingError: 'Ошибка при загрузке выписок',
        productCreatingError: 'Ошибка при создании выписки',
        productUpdatingError: 'Ошибка при обновлении выписки',
        productHistoryLoadingError: 'Ошибка при загрузке истории выписок'
    },
    createInquiry: 'Заявка на продукт',
    inquiryFields: {
        companyInfo: 'Информация о предприятии',
        traderTypeLabel: 'Тип предприятия',
        iinBin: 'БИН предприятия',
        traderType: 'Тип предприятия',
        traderName: 'Юридическое наименование предприятия',
        traderNameLt: 'Юридическое наименование предприятия (лат.)',
        mainActivity: 'Основной вид деятельности',
        createdDate: 'Дата образования предприятия',
        serviceBank: 'Банк обслуживания',
        servicePeriodOfBank: 'Срок обслуживания в Банке',
        hasCredits: 'Наличие кредитов',
        registerCompanyAddress: 'Юридический адрес предприятия',
        registerCompanyAddressZip: 'Индекс юридического адреса',
        realCompanyAddress: 'Фактический адрес предприятия',
        realCompanyAddressZip: 'Индекс фактического адреса',
        realPhone: 'Телефон предприятия',
        realFax: 'Факс предприятия',
        contactPhone:
            'Контактный телефон для клиентов компании (для сайта банка)',
        premisesOwnership: 'Принадлежность помещения',
        lessorName: 'Наименование арендодателя (лизингодателя)',
        leaseTerm: 'Срок аренды (лизинга)',
        hasEncumbranceOnProperty: 'Имеется обременение на имущество',
        registrationAddress: {
            type: 'Тип',
            countryCode: 'Код страны',
            zip: 'Почтовый индекс',
            regionCode: 'Код региона',
            districtCode: 'Код района',
            cityCode: 'Код города',
            townCode: 'Код административного центра',
            microdistrictCode: 'Код микрорайона',
            street: 'Улица',
            buildingNumber: 'Номер дома',
            apartmentNumber: 'Номер квартиры',
            fullAddress: 'Полный адрес'
        },
        ceoShortInfoLabel: 'Данные руководителя',
        personLegalAddressLabel: 'Адрес прописки',
        personRealAddressLabel: 'Адрес проживания',
        chiefAccountantShortInfoLabel: 'Данные бухгалтера',
        cheifAccountantDoesNotExists: 'Бухгалтер не предусмотрен'
    },
    tooltips: {
        enabledStatus:
            'Конфигурация для автоматических выписок создана и активирована',
        disabledStatus:
            'Конфигурация для автоматических выписок создана но не активирована',
        noEmails: 'Получатели не указаны',
        downloadproduct: 'Скачать выписку',
        productCreating: 'Создание выписки',
        reload: 'Обновить данные',
        productSettings: 'Настройка выписок'
    },
    pageInfo: (info: { from: number; to: number; total: number }) => (
        <>
            {info.from}-{info.to}
        </>
    ),
    nextButton: 'Сформировать',
    previousButton: 'Сформировать',
    automaticproducts: 'Автоматические выписки',
    productHistory: 'Сформированные выписки',
    productsFor: 'Выписки',
    active: 'Активированы',
    inactive: 'Не активированы',
    product: 'Выписка'
})
