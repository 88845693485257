export interface FlowResult<T> {
    value?: T
    error?: Error
}

export type PromiseProvider<T> = Promise<T> | (() => Promise<T>)

export type ErrorProvider = string | Error | ((inner?: Error) => Error | string)

function getError(error: ErrorProvider, inner: Error) {

    if (typeof error === 'string') {
        return new Error(error)
    }

    if (typeof error === 'function') {
        try {
            const result = error(inner)
            return typeof result === 'string' ? new Error(result) : result
        } catch (e) {
            return new Error('Error provider failed')
        }
    }

    return error
}

export function check<T>(result: FlowResult<T>, error?: ErrorProvider): T {

    if (!result.error) return result.value

    throw !error ? result.error : getError(error, result.error)
}

export function throwOnFail<T>(promise: PromiseProvider<T>, error?: ErrorProvider): Promise<T>
export function throwOnFail<T>(cb: () => T, error?: ErrorProvider): T
export function throwOnFail<T>(arg: (() => T) | PromiseProvider<T>, error?: ErrorProvider): T | Promise<T> {

    if (!error) {
        return typeof arg === 'function' ? arg() : arg
    }

    let promise: Promise<T>

    if (typeof arg === 'function') {
        try {
            const value = arg()
            if (!(value instanceof Promise)) return value
            promise = value
        } catch (e) {
            throw getError(error, e)
        }
    } else {
        promise = arg
    }

    return promise.catch(reason => { throw getError(error, reason) })
}

export type NoThrowArg<T> = (() => T) | PromiseProvider<T>

export function noThrow<T>(promise: PromiseProvider<T>, error?: ErrorProvider): Promise<FlowResult<T>>
export function noThrow<T>(cb: () => T, error?: ErrorProvider): FlowResult<T>
export function noThrow<T>(arg: NoThrowArg<T>, error?: ErrorProvider): FlowResult<T> | Promise<FlowResult<T>> {

    let promise: Promise<T>

    if (typeof arg === 'function') {
        try {
            const value = arg()
            if (!(value instanceof Promise)) return { value }
            promise = value
        } catch (e) {
            return { error: !error ? e : getError(error, e) }
        }
    } else {
        promise = arg
    }

    return promise
        .then(value => ({ value }))
        .catch(reason => ({ error: !error ? reason : getError(error, reason) }))
}
