import React from 'react'

import styles from '~/layouts/auth/styles.css'

import res from './res'

res.add('en', {
    signupTitle: 'Signup',
    signupLink: 'Signup',
    setPasswordTitle: 'Set Password',
    loginTitle: 'Login',
    loginLink: 'Login',
    passwordRecoveryTitle: 'Password Recovery',
    signUpAction: 'Sign Up',
    setPasswordAction: 'Set Password',
    logInAction: 'Log In',
    sendAction: 'Send',
    cancel: 'Cancel',
    signupEmailPrompt: 'Your business email',
    noActivationEmail: 'Didn\'t receive confirmation email?',
    email: 'Email',
    password: 'Password',
    newPassword: 'Your new password',
    passwordRepeat: 'Confirm password',
    passwordRestoreLink: 'Forgot password?',
    noAccount: 'Not registered?',
    hasAccount: 'Already registered?',
    didNotReceiveEmail: 'Did not receive email?',
    repeatSignUpLink: 'Repeat signup',
    emailRequired: 'Please, enter your email',
    passwordRequired: 'Please, enter your password',
    passwordRepeatRequired: 'Please, enter your password again to confirm',
    passwordsDoNotMatch: 'The passwords you entered do not match',
    successSignUp: 'Confirmation email has been sent to your email address',
    passwordRecoveryInfo:
        'Enter the email address you provided on signup, a change password link will be sent to it',
    accountCreatedTitle: 'Account created',
    accountCreatedInfo: (email: string) => (
        <span>
            To complete signup go to{' '}
            <span className={styles.email}>{email}</span> and confirm your email
            address
        </span>
    ),
    passwordSetTitle: 'Password changed',
    passwordRecoveredTitle:
        'The password change instructions has been sent to your email address',
    passwordHelp: {
        title: 'Password must contain at least:',
        notContainsErrors: {
            numbers: '1 digit',
            nonAlphabeticalCharacters: '1 non-alphanumeric character',
            capitalCharacters: '1 upper-case letter',
            lowercaseCharacters: '1 lower-case letter',
            minLength: 'characters',
            minLength8: '8 characters'
        }
    },
    invalidPassword: 'Incorrect password format',
    continue: 'Continue',
    idleTimerTitle: 'Session Expiration',
    idleTimerWarning:
        'You are inactive more than 10 minutes. The session will be closed in',
    idleTimerButtonContinue: 'Continue the session',
    idleTimerButtonStop: 'Log Out',
    passwordExpired: {
        title: 'Your password has expired',
        message: 'You need to reset your password'
    }
})
