import React from 'react'

const SvgCancelCircle = props => (
    <svg width="1em" height="1em" viewBox="0 0 31 31" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.505 7.505c-4.138 4.138-4.138 10.872 0 15.01 4.139 4.14 10.873 4.14 15.01 0 4.14-4.138 4.14-10.871 0-15.01-4.138-4.138-10.871-4.138-15.01 0zm.858.857c3.665-3.665 9.63-3.665 13.295 0 3.666 3.666 3.665 9.63 0 13.295-3.665 3.666-9.63 3.666-13.295 0-3.665-3.666-3.665-9.63 0-13.295z"
        />
        <path d="M17.541 18.398l-5.918-5.918a.606.606 0 11.857-.858L18.4 17.54a.607.607 0 01-.858.858z" />
        <path d="M11.623 18.398a.607.607 0 010-.858l5.918-5.918a.607.607 0 01.857.858l-5.918 5.918a.605.605 0 01-.857 0z" />
    </svg>
)

export default SvgCancelCircle
