import res from './res'

res.add('en', {
    dashboard: 'Dashboard',
    // dashboardLabel: 'Today statistics',

    /*****************************************/
    transactions: 'Transactions',
    payments: 'ePay payments',
    transfers: 'ePay transfer',
    aftTransactions: 'ATF replenishments',
    octTransactions: 'Payouts',
    cashByCode: 'Cash by code',

    /*****************************************/
    banksProducts: 'Bank products',

    epay: 'ePay',
    pos: 'POS',
    goBonuses: 'Go!-bonuses',
    orange: 'Orange',
    homebank: 'Homebank',
    QRPayments: 'QR',
    credit: 'Installment and credit',

    /*****************************************/
    oct: 'Payments',
    p2p: 'Transactions',

    /*****************************************/

    paymentAft: 'Payments AFT',
    aft: 'Transactions AFT',

    /*****************************************/

    statements: 'Statements',
    payment: 'By payments',
    transfer: 'By transfers',
    chargeback: 'Chargeback',

    /*****************************************/
    news: 'News feed',

    increaseBonuses: 'Increase bonuses',
    VIPMerchants: 'VIP-merchants',
    /*****************************************/
    paymentLinks: 'Payment links',

    /*****************************************/
    qrCodes: 'QR-codes',

    staticQRCode: 'Static QR-code',
    dynamicQRCodes: 'Dynamic QR-code',
    labelGenerator: 'QR-коды для ценников',
    /*****************************************/
    settings: 'Settings',

    // paymentSystemActivation: 'Активация платежной системы',
    // ePayPaymentSystemActivation: 'Активация платежной системы',
    configureEpayTemplate: 'Customize ePay template',
    terminalSettings: 'Terminal settings',
    certificates: 'Certificates',
    managingMerchants: 'Manage merchants',
    team: 'Team',
    faq: 'FAQ',
    forDevelopers: 'For developers',
    documentation: 'Documentation',
    notifications: 'Notifications',
    profile: 'Profile',
    logOut: 'Log Out',
    applyButton: 'Save',
    cancelButton: 'Cancel',
    setValue: 'Set value',
    orders: 'Мои заявки',
    currentOrders: 'Текущие заявки',
    createOrder: 'Подача новой заявки',
    paymentSettings: 'Payment settings'
})
