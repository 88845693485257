import { Button, Input, message } from 'antd'
import React, { useEffect, useRef } from 'react'
import { IconButton } from '~/components'
import { CheckIcon, PaymentLinksIcon } from '~/components/icon'
import res from '../res'
import styles from '../styles.css'
import { CreditOrder } from '~/api/products'

interface ConfirmationPageProps {
    createdCreditOrder?: CreditOrder,
    updatedCreditOrder?: boolean,
    onClose: () => void
}

function ConfirmationPage({ createdCreditOrder, updatedCreditOrder, onClose }: ConfirmationPageProps) {
    return (
        <div className={styles.confirmation}>
            <div className={styles.confirmationHeader}>
                <CheckIcon className={styles.checkIcon} />
                {!updatedCreditOrder ? (
                    <div className={styles.confirmationTitle}>
                        {res().confirmationTitle(createdCreditOrder.number)}
                    </div>
                ) : (
                    <div className={styles.confirmationTitle}>
                        {res().confirmationTitle}
                    </div>
                )}
            </div>
            <div className={styles.linkInfo}>
                {res().confirmationSubtitle}
            </div>
            <Button size="large" onClick={() => onClose()}>
                {res().closeButton}
            </Button>
        </div>
    )
}

export default ConfirmationPage
