import { notification } from 'antd'
import { remove } from 'lodash'
import {
    action,
    observable,
    runInAction,
    toJS
} from 'mobx'
import page from 'page'
import { apiCheck, createOrGetMerchant, getProfile, getShops, RegisterMerchant } from '~/api'
import { noThrow } from '~/utils/control-flow'
import message from '~/utils/message'
import res from './res'
import FieldStore from '~/utils/field-store'
import { required } from '~/utils/validation'
import { updateChargebackData } from '~/api/chargeback'

export default class ChargebackStore {

    constructor() {
        this.loadData()
    }

    public chargebackUrl = new FieldStore<string>(
        [required(() => res().messages.valueRequired)],
        this.onChange
    )

    public sharedSecret = new FieldStore<string>(
        [required(() => res().messages.valueRequired)],
        this.onChange
    )

    @observable
    public profile: any

    @observable
    public isModified: boolean

    @observable
    public isEdited: boolean

    @action.bound
    public updateData() {

        const chargebackData = {
            chargebackUrl: this.chargebackUrl.value,
            sharedSecret: this.sharedSecret.value
        }

        updateChargebackData(chargebackData).then(result => {
            runInAction(() => {
                this.isEdited = false
                notification.success({
                    message: res().messages.successUpdate
                })
            })
        })
    }

    @action.bound
    public editDate() {
        runInAction(() => this.isEdited = true)
    }

    @action
    public async loadData() {
        const response = await noThrow(apiCheck(getProfile()))

        runInAction(() => {
            this.profile = response?.value
            this.isModified = false

            this.chargebackUrl.set(this.profile.merchant.chargebackURL)
            this.sharedSecret.set(this.profile.merchant.shared_secret)
            this.isEdited = !(this.sharedSecret.value && this.sharedSecret.value)
        })
    }

    @action.bound
    private onChange() {
        this.isModified = true
    }
}
