import React from 'react'

const SvgCreditCard = props => (
    <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
        <g clipPath="url(#credit-card_svg__clip0_11784_365)">
            <path d="M17.357 1.925H.643A.642.642 0 000 2.568v12.857c0 .355.287.643.643.643h16.714a.642.642 0 00.643-.643V2.568a.642.642 0 00-.643-.643zM1.447 3.37h15.107v2.411H1.446v-2.41zm15.107 11.25H1.446V7.55h15.108v7.071zm-4.762-1.285h3.315c.088 0 .16-.073.16-.161v-1.447a.161.161 0 00-.16-.16h-3.315a.161.161 0 00-.16.16v1.447c0 .088.072.16.16.16z" />
        </g>
        <defs>
            <clipPath>
                <path d="M0 0h18v18H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default SvgCreditCard
