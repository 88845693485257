import res from './res'
import React from 'react'

res.add('ru', {
    skipBtn: 'Пропустить обучение',
    nextSectionBtn: 'Следующий раздел',
    prevSectionBtn: 'Предыдущий раздел',
    nextBtn: 'Продолжить',
    modals: {
        cancelBtn: 'Отмена',
        okBtn: 'Пропустить',
        skipAll: {
            title: 'Внимание!',
            description: 'Вы уверены, что хотите пропустить обучение?'
        },
        skipSection: {
            title: 'Внимание!',
            description: 'Вы уверены, что хотите пропустить обучение по текущему разделу и перейти к следующему?'
        },
        congratulation: {
            title: 'Поздравляем!',
            description: 'Вы завершили обучение по личному кабинету. Если возникнут вопросы или Вы захотите пройти обучение ещё раз, перейдите в раздел FAQ',
            okBtn: 'Принять'
        },
        transactionsWarning: {
            title: 'Обучение',
            description: 'Подробное обучение по разделу "ePay платежи" будет доступно после проведения первого успешного платежа',
            okBtn: 'Принять'
        },
        welcome: {
            title: 'Добро пожаловать в ePay!',
            description: 'Мы подготовили обучение по разделам личного кабинета. Начать обучение?',
            cancelBtn: 'Отменить',
            okBtn: 'Приступить'
        },
        wantToContinue: {
            title: 'Обучение',
            description: (nameOfSection) => `Вы остановились на разделе “${nameOfSection}”. Продолжить обучение?`,
            cancelBtn: 'Отменить',
            okBtn: 'Приступить'
        },
        firstSuccessTransaction: {
            title: 'Обучение',
            description: 'Проведена первая успешная транзакция. Пройти обучение по разделу?',
            cancelBtn: 'Отменить',
            okBtn: 'Приступить'
        }
    },
    sectionsDesktop: {
        generalInformation: {
            title: 'Общая информация',
            steps: [
                <><span>Боковое меню</span>. С его помощью осуществляется вся навигация по <span>Личному кабинету</span></>,
                <><span>Рабочая область</span>. Вся основная деятельность <span>Личного кабинета</span> происходит здесь.</>,
                <>Рядом с иконкой уведомлений расположен <span>Профиль</span>. Нажав иконку, Вы можете перейти в управление профилем или выйти из системы.</>
            ]
        },
        dashboard: {
            title: 'Статистика',
            steps: [
                <>Раздел <span>Статистика,</span> он встречает Вас каждый раз при авторизации в кабинете.</>,
                <>В <span>Статистике</span> Вы можете отслеживать: Успешные/Неуспешные транзакции, Банки-эмитеты, Каналы</>
            ]
        },
        transactions: {
            title: 'ePay платежи',
            steps: [
                <>Раздел <span>Транзакции</span>. В нём находятся 4 подраздела: ePay платежи, ePay переводы, Пополнения AFT и Выплаты. </>,
                <>В подразделе <span>ePay платежи</span> отображаются все транзакции, которые проходили через платежную систему ePay. Информация о транзакциях отображается в виде таблицы. Столбцы таблицы Вы можете передвигать, а также добавлять/удалять</>,
                <>Платежи можно отсортировать по дате, либо по статусу.</>,
                <>«Настройка таблицы», вы можете настраивать отображение и порядок столбцов таблицы</>,
                <>С помощью статистики, вы можете мониторить информацию о поступивших платежах за выбранный период. Период выбирается с помощью фильтра «Платежи за»</>,
                <>Поисковая строка - позволяет Вам найти необходимую транзакцию по ключевым словам. С помощью фильтра, Вы можете осуществлять расширенный поиск по нескольким полям.</>,
                <>Подробная информация о платеже появляется при нажатии на платёж.</>,
                <>Подробная информация о платеже появляется при нажатии на платёж.</>,
                <>Для подтверждения, отмены или возврата отметьте платежи.</>,
                <>После отметки транзакции «галочкой» выберите необходимое действие.</>
            ]
        },
        statements: {
            title: 'Выписки',
            steps: [
                <>Раздел <span>Выписки</span>, при необходимости получения выписки по платежам е-коммерсанта, в целях произведения каких-либо сверок воспользуйтесь этим разделом.</>,
                <>Здесь отображаются Ваши магазины (контракты). Для управления выписками выберите нужный терминал.</>,
                <>Для формирования выписки нажмите кнопку «Сформировать»</>,
                <>Установите период «с» даты оплаты и период «по», а также формат выписки. Нажмите кнопку сформировать. Выписка выгружается в формате CSV и XLSX (Excel).</>,
                <>Также Вы можете настроить автоматическую ежедневную, еженедельную или ежемесячную рассылку выписки на почту.</>,
                <>После выбора нужного периода рассылки появится окно с необходимой информацией, которую надо заполнить и нажать «Применить». </>
            ]
        },
        paymentLinks: {
            title: 'Ссылки на оплату',
            steps: [
                <>Раздел <span>Ссылки на оплату</span>. С помощью которого формируется счет на оплату и генерируется уникальная ссылка, по которой покупатель переходит на платежную форму, вводит данные банковской карты или оплачивает с помощью Apple Pay и Samsung Pay всего в один клик.</>,
                <>В рабочей области отображается история Ваших ссылок на оплату.</>,
                <>Для генерации деталей оплаты, нажмите кнопку «Сформировать ссылку».</>,
                <>Появится окно, в котором необходимо заполнить детали оплаты и нажать «Применить». После этого сформируется ссылка с данными по оплате. Сгенерированную ссылку можно будет скопировать и отправить клиенту (по мессенджеру или на электронный адрес). После перехода по ссылке, клиенту откроется платежная страница где необходимо
                    будет ввести карточные данные и произвести оплату.</>
            ]
        },
        credit: {
            title: 'Рассрочка и кредитование',
            steps: [
                <>В разделе <span>Продукты банка</span> находится один подраздел <span>Рассрочка и кредитование</span>.</>,
                <>С помощью раздела <span>Рассрочка и кредитование</span> доступна активация рассрочки и кредитования</>,
                <>Здесь отображается история заявок для получения кредитования. Вы можете отслеживать их статусы: «Заявка подана», «В обработке», «Принята» и «Отклонена». В случае, если заявка получит статус «Отклонена» Вам будет выведена причина отказа в поле заявки. Также в поле заявки доступны для просмотра условия рассрочки/кредита.</>,
                <>Чтобы подключить к магазину возможность принимать оплату от клиентов в рассрочку или в кредит, необходимо заполнить заявку. Сделать это можно с помощью кнопки «Новая заявка»</>,
                <>Необходимо заполнить поля, отправить заявку и ожидать подтверждение банка. После одобрения заявки, при покупке товаров на Вашем магазине, на платежной странице EPAY, клиенту будут доступны функции выбора оплаты в рассрочку/ кредит.</>
            ]
        },
        configureEpayTemplate: {
            title: 'Шаблон ePay',
            steps: [
                <>Раздел <span>Настройки</span> делится на 3 подраздела: Шаблон ePay, Настройки терминалов и Сотрудники</>,
                <>В подразделе <span>Шаблон ePay</span> можно адаптировать шаблон платёжной страницы под свой магазин.</>,
                <>Для самостоятельной настройки доступны следующие пункты:<br />
                    —Название магазина<br />
                    —Логотип<br />
                    —Язык<br />
                    —Email<br />
                    —Телефон<br />
                    —Цветовая схема (в случае если заготовленные цветовые схемы вас не устраивают вы
                    можете настроить её самостоятельно выбрав пункт «Цветовая схема»</>
            ]
        },
        terminalSettings: {
            title: 'Настройки терминалов',
            steps: [
                <>В подразделе <span>Настройки терминалов</span> Вам доступны некоторые функции управления терминалами.</>,
                <>Переключатель в пункте «Активен» отвечает за включение\отключение самого терминала. </>,
                <>Переключатель в пункте «Сохранить карту» включает\отключает функцию, позволяющую вашим клиентам сохранять карту, с которой
                производилась оплата, для того чтобы следующие операции проводились напрямую (без заполнения формы оплаты).
                Для работы оплат по сохраненным картам требуется специальная интеграция, см.
                    раздел документации «Платеж по сохраненной карте».</>,
                <>Переключатель в пункте «1-шаговая схема». Если он находится в активированном состоянии, то средства с карты клиента, в случае оплаты, будут списаны сразу, без изначального удержания суммы банком. Если данный переключатель отключён, то сумма изначально будет удержана банком и списана тогда, когда вы самостоятельно проведёте платёж в разделе «Платежи\ePay-терминалы».</>
            ]
        },
        team: {
            title: 'Сотрудники',
            steps: [
                <>В подразделе <span>Сотрудники</span> можно управлять персоналом и выдавать сотрудникам необходимый уровень доступа.</>,
                <>Здесь отображаются все Ваши сотрудники и выданные им роли. Вы можете редактировать роль сотрудника или удалить его.</>,
                <>Для добавления нового сотрудника необходимо нажать на кнопку «Пригласить сотрудника» и заполнить форму.</>,
                <>После заполнения формы, нажмите кнопку «Применить» и на указанный Email будет автоматически отправлена ссылка на страницу установки пароля.</>
            ]
        }
    },
    sectionsMobile: {
        generalInformation: [
            'С помощью бокового меню осуществляется вся навигация по личному кабинету'
        ],
        dashboard: [
            'Отслеживайте в статистике: Успешные/Неуспешные транзакции, Банки-эмитеты, Каналы'
        ],
        transactions: [
            'С помощью статистики, вы можете мониторить информацию о поступивших платежах за выбранный период',
            'Платежи можно отсортировать по дате, либо по статусу',
            'С помощью фильтра, Вы можете осуществлять расширенный поиск по нескольким полям'
        ],
        statements: [
            'Здесь отображаются Ваши магазины (контракты). Для управления выписками выберите нужный терминал',
            'Вы можете настроить автоматическую ежедневную, еженедельную или ежемесячную рассылку выписки на почту'
        ],
        paymentLinks: [
            'Сформируйте уникальныую ссылку, с помощью которой, покупатель оплатит с помощью Apple Pay и Samsung Pay всего в один клик'
        ],
        credit: [
            'Подключите к магазину возможность принимать оплату от клиентов в рассрочку или в кредит'
        ],
        configureEpayTemplate: [
            'Адаптируйте шаблон платёжной страницы под свой магазин'
        ],
        terminalSettings: [
            'Переключатель в пункте «Активен» отвечает за включение\\отключение самого терминала',
            'Переключатель в пункте «Сохранить карту» включает\\отключает функцию, позволяющую вашим клиентам сохранять карту',
            'Если переключатель в пункте «1-шаговая схема» находится в активированном состоянии, то средства с карты клиента будут списаны сразу, без изначального удержания суммы банком. Если данный переключатель отключён, то сумма изначально будет удержана банком и списана тогда, когда вы самостоятельно проведёте платёж в разделе «Платежи\\ePay-терминалы»'
        ],
        team: [
            'Здесь отображаются все Ваши сотрудники и выданные им роли. Вы можете редактировать роль сотрудника или удалить его'
        ]
    }
})
