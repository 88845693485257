import React from 'react'

import res from './res'

res.add('kk', {
    contacts: 'Байланыс',
    docs: 'Техникалық құжаттама',
    help: 'Көмек',
    termsOfUse: 'Пайдаланушы келісім',
    contactsModal: {
        title: 'Байланыс',
        close: 'Жабу',
        forLegals: 'заңды тұлғалар үшін',
        forIndividuals: 'жеке тұлғалар үшін',
        acquiring: 'Эквайринг сұрақтары бойынша',
        cabinet: 'Жеке кабинет сұрақтары бойынша'
    }
})
