import React from 'react'
import res from './res'

res.add('en', {
    title: 'Application for activation of installments and loans',
    description: 'For further use of the system, please fill out the activation application. System functions will be available after the approval of the application',
    sendOrder: 'Send an order',
    formItemTitles: {
        shop: 'Shop',
        email: 'E-mail of recipients'
    },
    shopPlaceholder: 'Choose shop',
    emailPlaceholder: 'Enter email',
    emailRequired: 'Please, enter member email',
    invalidEmail: 'Incorrect email format',
    invalidShop: 'Choose shop',
    confirmationTitle: (orderNumber: string) => (
        <div>
            Application №{orderNumber} has been sent for consideration!
        </div>
    ),
    confirmationUpdateTitle: 'Application approved',
    confirmationSubtitle: 'It will be considered in the near future. Follow the status of the application, and the comments of the moderator.',
    closeButton: 'Close',
    send: 'Send'
})
