import res from './res'

res.add('kk', {
    orderText: 'Өтінім №',
    creditOrderTitle: 'Бөліп төлеу және несиеге өтінімдер',
    newOrderTitle: 'Жаңа өтінім',
    confirmButton: 'Өтінімді растау',
    cancelButton: 'Қабылдамау',
    accept: 'Қабылдау',
    acceptConfirmationTitle: 'Өтінішті шынымен қабылдағыңыз келе ме?',
    acceptNotificationTitle: 'Өтінім сәтті мақұлданды',
    acceptFailedNotificationTitle: 'Қателік орын алды, кейінірек көріңіз',
    acceptNotificationDescription: 'Жаңа статусты көру үшін кестені жаңартыңыз',
    rejectReason: 'Өтінім қабылданбау себебі',
    noDataLabel: 'Мәлімет жоқ'
})
