import { ListRequest, ListResponse } from '~/api/contracts'

export type CashByCodeFilterableField =
    | 'id'
    | 'created_at'
    | 'shop_id'
    | 'invoice_id'
    | 'account_id'
    | 'payer_phone'
    | 'payer_email'
    | 'post_link'
    | 'amount'
    | 'card_mask'
    | 'client'
    | 'installment_id'
    | 'payout_date'
    | 'status_id'
    | 'result_code'

export type CashByCodeSortableField =
    | 'id'
    | 'created_at'
    | 'status_id'
    | 'post_link'

export type CashByCodeRequest = ListRequest<
    CashByCodeFilterableField,
    CashByCodeSortableField
>

export type CashByCodePaymentResponse = ListResponse<CashByCodePayment>

export enum CashByCodeStatus {
    auth = 'AUTH',
    charged = 'CHARGE',
    cancelled = 'CANCEL',
    refunded = 'REFUND',
    failed = 'FAILED',
    rejected = 'REJECT',
    new = 'NEW',
    any = 'ANY',
    CANCEL_OLD = 'CANCEL_OLD',
    TIMEOUT_3D = '3D'
}

export interface CashByCodePayment {
    amount: number
    authDate: Date
    cardMask: string
    cashCodeSMS: string
    client: string
    contractNumberDevice: string
    createdAt: Date
    currency: string
    description: string
    destinationPhone: string
    epayTerminalID: string
    failurePostLink: string
    id: string
    invoiceId: string
    isPostLink: boolean
    merchant: string
    payerIP: string
    postLink: string
    postingDate: Date
    postingStatus: string
    receiverPhone: string
    reference: string
    returnCode: string
    sourcePhone: string
    stan: string
    status: string
    terminalID: string
}
