import { email } from '~/layouts/auth/styles.css'
import res from './res'

res.add('kk', {
    title: 'Командаға шақыру',
    subTitle:
        'Жүйеге кіру мүмкіндігіне ие болу үшін қатысушының email-ін енгізіңіз',
    email: 'Қатысушының Email',
    emailRequired: 'Қатысушының email мекенжайын енгізіңіз',
    invalidEmail: 'Қате email пішімі',
    roleTitle: 'Жаңа мүше үшін рөлді анықтаңыз',
    manager: 'Менеджер',
    managerDescription:
        'Төлемдер мен статистиканы көру, төлемге шот-фактураларды шығару, үзінді көшірмелерді жасау, төлемдерді басқару, төлемдерді іздеу мүмкіндігі бар',
    accountant: 'Есепші',
    accountantDescription:
        'Транзакциялар мен статистиканы көру, үзінді көшірмелерді жасау, төлемдерді іздеу мүмкіндігі бар',
    view: 'Қараушы',
    viewDescription: 'Транзакциялар мен статистиканы көру мүмкіндігі бар',
    accessLevelChanges: 'Қол жеткізу деңгейін өзгерту',
    applyButton: 'Қолдану',
    cancelButton: 'Болдырмау'
})
