import { Moment } from 'moment'
import { FrequencyType } from '~/api/statements'
import { upperCaseFirstLetter } from '~/utils'
import res from './res'

res.add('en', {
    manualGenerationTitle: 'Statement generation',
    scheduleTitle: (scheduleType: FrequencyType) =>
        `${upperCaseFirstLetter(scheduleType)} statements settings`,
    periodTitle: 'Period',
    emailsTitle: 'Recipient emails',
    emailRequired: 'Please, enter member email',
    invalidEmail: 'Incorrect email format',
    formatTitle: 'Format',
    generateButton: 'Generate',
    applyButton: 'Apply',
    cancelButton: 'Cancel',
    active: 'Activated',
    formatZip: 'When this field is selected, statement will be formed in ZIP',
    dateTitle: 'Select date',
    cols: {
        email: 'Email',
        control: 'Control',
        controlsTooltip: {
            add: 'Add',
            edit: 'Edit',
            save: 'Apply',
            cancel: 'Cancel',
            delete: 'Delete'
        }
    }
})
