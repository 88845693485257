import qs from 'qs'

import config from '~/config'

import { ApiResponse } from '~/api'

import strict from '~/utils/strict'

import BrowserStore from '~/services/browser/browser-state'

import { set } from 'lodash'
import { message, notification } from 'antd'
import res from '../res'

export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

export type FormType = 'application/json' | 'application/x-www-form-urlencoded' | 'multipart/form-data' | 'binary'

export const auth = {
    client: async () => undefined as string,
    user: async () => undefined as string,
    refresh: async () => undefined as boolean,
    logout: () => undefined
}

export interface RequestOptions {
    baseUrl?: string
    anonymous?: boolean
    setClientToken?: boolean
    response?: ContentType
}

export class Content {
    constructor(public type: FormType, public body: any) { }
}

export type ContentType = any | Content

export function jsonContent(data: any) {
    return new Content(
        'application/json',
        data === undefined ? undefined : JSON.stringify(data)
    )
}

export function multipart(data: FormData) {
    return new Content(
        'multipart/form-data',
        data
    )
}

export function form(data: { [key: string]: string }) {
    return new Content(
        'application/x-www-form-urlencoded',
        data === undefined
            ? undefined
            : Object.entries(data)
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join('&')
    )
}

async function fetcher(
    method: Method,
    url: string,
    data?: ContentType,
    options?: RequestOptions
) {
    const bStore = BrowserStore
    const headers: any = bStore.isIE ? {} : []

    const request: RequestInit = {
        method,
        headers
    }
    if (bStore.isIE) {
        set(
            headers,
            'Access-Control-Request-Headers',
            'x-requested-with, authorization, accesstoken, content-type, deviceid, ip, fingerprint'
        )
    }
    if (data !== undefined) {
        const content: Content =
            data instanceof Content ? data : jsonContent(data)

        if (content.type !== 'multipart/form-data') {
            if (bStore.isIE) {
                set(headers, 'Content-Type', content.type)
            } else {
                headers.push(['Content-Type', content.type])
            }
        }
        request.body = content.body
    }

    let authorization: string

    if (options.setClientToken) {
        authorization = await auth.client()
    } else if (!options.anonymous) {
        authorization = await auth.user()
    }

    if (authorization) {
        if (bStore.isIE) {
            set(headers, 'Authorization', authorization)
        } else {
            headers.push(['Authorization', authorization])
        }
    }

    const baseUrl = options.baseUrl
    return (await fetch(baseUrl + url, request)) as Response
}

export async function send<T>(
    method: Method,
    url: string,
    data?: ContentType,
    options?: RequestOptions
): Promise<ApiResponse<T>> {
    options = {
        baseUrl: config.api.baseUrl,
        ...options
    }
    let response = await fetcher(method, url, data, options)

    if (!options.anonymous && response.status === 401) {
        let ok = await auth.refresh()
        if (ok) {
            response = await fetcher(method, url, data, options)
            ok = response.status !== 401
        }
        if (!ok) {
            auth.logout()
        }
    }

    if (response.status === 405) {
        return { error: { code: '', message: res().permissionError } }
    }

    if (response.status === 204) return {}

    if (
        response.ok &&
        options &&
        options.response &&
        options.response.type &&
        options.response.type === 'binary'
    ) {
        return strict<ApiResponse<T>>({ result: response as any })
    }

    const text = await response.text()

    const json = text ? JSON.parse(text) : undefined

    return strict<ApiResponse<T>>(
        response.ok ? { result: json } : { error: json }
    )
}

export function get<T>(
    url: string,
    data?: ContentType,
    options?: RequestOptions
) {
    if (data !== undefined) {
        url = url + '?' + qs.stringify(data)
    }

    return send<T>('GET', url, undefined, options)
}

export function post<T>(
    url: string,
    data?: ContentType,
    options?: RequestOptions
) {
    return send<T>('POST', url, data, options)
}

export function patch<T>(
    url: string,
    data?: ContentType,
    options?: RequestOptions
) {
    return send<T>('PATCH', url, data, options)
}

export function put<T>(
    url: string,
    data?: ContentType,
    options?: RequestOptions
) {
    return send<T>('PUT', url, data, options)
}

export function del<T>(
    url: string,
    data?: ContentType,
    options?: RequestOptions
) {
    return send<T>('DELETE', url, data, options)
}

// function downloadFile(fileName, csvContent) {
//     const D = document
//     const a = D.createElement('a')
//     const strMimeType = 'application/octet-stream;charset=utf-8'
//     let rawFile

//     if (!fileName) {
//       const currentDate = new Date()
//       fileName = 'CWS Export - ' + currentDate.getFullYear() + (currentDate.getMonth() + 1) +
//                     currentDate.getDate() + currentDate.getHours() +
//                     currentDate.getMinutes() + currentDate.getSeconds() + '.csv'
//     }

//     if (this.isIE() < 10) {
//       const frame = D.createElement('iframe')
//       document.body.appendChild(frame)

//       frame.contentWindow.document.open('text/html', 'replace')
//       frame.contentWindow.document.write('sep=,\r\n' + csvContent)
//       frame.contentWindow.document.close()
//       frame.contentWindow.focus()
//       frame.contentWindow.document.execCommand('SaveAs', true, fileName)

//       document.body.removeChild(frame)
//       return true
//     }

//     // IE10+
//     if (navigator.msSaveBlob) {
//       return navigator.msSaveBlob(new Blob(['\ufeff', csvContent], {
//         type: strMimeType
//       }), fileName)
//     }

//     // // html5 A[download]
//     // if ('download' in a) {
//     //   const blob = new Blob([csvContent], {
//     //     type: strMimeType
//     //   })
//     //   rawFile = URL.createObjectURL(blob)
//     //   a.setAttribute('download', fileName)
//     // } else {
//     //   rawFile = 'data:' + strMimeType + ',' + encodeURIComponent(csvContent)
//     //   a.setAttribute('target', '_blank')
//     //   a.setAttribute('download', fileName)
//     // }

//     a.href = rawFile
//     a.setAttribute('style', 'display:none;')
//     D.body.appendChild(a)
//     setTimeout(function () {
//       if (a.click) {
//         a.click()
//         // Workaround for Safari 5
//       } else if (document.createEvent) {
//         const eventObj = document.createEvent('MouseEvents')
//         eventObj.initEvent('click', true, true)
//         a.dispatchEvent(eventObj)
//       }
//       D.body.removeChild(a)

//     }, 100)
//   }
