import React from 'react'
import { HintModal } from '../modal'
import InteractiveHintsStore from '../../../interactive-hints-store'
import { observer } from 'mobx-react'
import res from '../../../res'
import AppStore from '~/app-store'
import InteractiveHint from '~/utils/interactive-hint'

interface Props {
    store: InstanceType<typeof InteractiveHintsStore>
    appStore: typeof AppStore
}

const WelcomeModal = observer(({ store, appStore }: Props) => {
    const { isVisibleWelcomeModal } = store

    return (
        <HintModal
            visible={isVisibleWelcomeModal}
            onCancel={async () => {
                store.cancelWelcomeModal()
                await InteractiveHint.cancelAllFlags()
                setTimeout(() => appStore.hideInteractiveHints(), 250)
            }}
            onOk={() => {
                store.cancelWelcomeModal()
                store.startShowing()
            }}
            title={res().modals.welcome.title}
            description={res().modals.welcome.description}
            okText={res().modals.welcome.okBtn}
            cancelText={res().modals.welcome.cancelBtn} />
    )
})

export default WelcomeModal