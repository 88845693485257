import res from './res'

import { AFTStatus } from '~/api'

res.add('kk', {
    title: 'ePay AFT',
    ranges: {
        today: 'бүгін',
        yesterday: 'кеше',
        thisWeek: 'ағымдағы апта',
        priorWeek: 'өткен апта',
        thisMonth: 'ағымдағы ай',
        priorMonth: 'өткен ай',
        thisQuarter: 'ағымдағы тоқсан',
        customPeriod: 'көрсетілген кезең'
    },
    anyPaymentStatus: 'Кез келген',
    paymentStatuses: {
        // [AFTStatus.auth]: 'Күтеді',
        [AFTStatus.charged]: 'Өткізілді',
        // [AFTStatus.cancelled]: 'Жойылды',
        [AFTStatus.rejected]: 'Жойылды',
        // [AFTStatus.refunded]: 'Қайтарылды',
        // [AFTStatus.failed]: 'Сәтсіз',
        [AFTStatus.new]: 'Жаңа',
        [AFTStatus.any]: 'Кез келген'
    },
    sortBy: 'Бойынша сұрыптау',
    filtersPlaceholder: 'Төлем атрибуттары бойынша сүзгі',
    dataFields: {
        full: {
            accountID: 'Клиент сәйкестендіргіші',
            amount: 'Төлем сомасы',
            currency: 'Валюта',
            invoiceID: 'Тапсырыс нөмірі',
            reference: 'Карта жүйесінің жауабы',
            description: 'Бөлшектер төлемі',
            fee: 'Ұсталған комиссия',
            language: 'Тілі',
            createdDate: 'Құрылған күні',
            dateTime: 'Жасалған күні',

            ipCity: 'Қаласы',
            ipCountry: 'Ел',
            ipDistrict: 'Ауданы',
            ipLatitude: 'Ені',
            ipLongitude: 'Ұзындығы',
            ipRegion: 'Аймақ',

            email: 'Клиенттің Email',
            ip: 'Клиенттің IP',
            name: 'Карта иесінің аты',
            phone: 'Клиенттің телефон нөмірі',

            secure: '3D Транзакция',
            code: 'Төлем мәртебесі',
            reason: 'Нәтижесі',

            senderCardMask: 'Жіберуші картасының маскасы',
            senderCardType: 'Жіберуші картасының түрі',
            // senderOwID: 'Жіберушінің ow id', 
            // senderCardID: 'Жіберушінің card id', 
            senderIssuer: 'Жіберушінің банк эмитенті',
            // senderTransferType: 'Жіберушінің аударма әдісі',

            // receiverCardMask: 'Қабылдаушының карта маскасы',
            // receiverCardType: 'Қабылдаушының карта түрі',
            // receiverCardID: 'Қабылдаушының card id',
            // receiverOwID: 'Қабылдаушының ow id',
            // receiverIssuer: 'Қабылдаушының банк эмитенті',
            // receiverTransferType: 'Қабылдаушының аударма әдісі',

            data: 'Қосымша жолдар',

            //            shop: 'Дүкен атауы',
            //            amountBonus: 'Go Сомасы!бонустар',
            //            chargeDate: 'Растау күні',
            //            client: 'Клиент',
            //            installment_id: 'Бөліп төлеу мерзімі',            
            //            issuerBankCountry: 'Эмитент банкінің елі',
            //            merchant: 'Мерчантқа сілтеме',
            //            postLink: 'Қайта бағыттау сілтемесі',
            postLinkStatus: 'Постлинк'
            //            payoutAmount: 'Қайтару сомасы',
            //            payoutDate: 'Қайтару күні',
            //            orgAmount: 'Бастапқы сомасы',
            //            template: '?',

        },
        short: {
            accountID: 'Клиент сәйкестендіргіші',
            amount: 'Төлем сомасы',
            currency: 'Валюта',
            invoiceID: 'Тапсырыс нөмірі',
            reference: 'РРН',
            description: 'Бөлшектер төлемі',
            fee: 'Ұсталған комиссия',
            language: 'Тілі',
            createdDate: 'Құрылған күні',
            dateTime: 'Жасалған күні',

            ipCity: 'Қаласы',
            ipCountry: 'Ел',
            ipDistrict: 'Ауданы',
            ipLatitude: 'Ені',
            ipLongitude: 'Ұзындығы',
            ipRegion: 'Аймақ',

            email: 'Email',
            ip: 'Клиенттің IP',
            name: 'Карта иесінің аты',
            phone: 'Телефон',

            secure: '3D Транзакция',
            code: 'Статус',
            reason: 'Нәтижесі',

            senderCardMask: 'Жіберуші картасының маскасы',
            senderCardType: 'Жіберуші картасының түрі',
            senderIssuer: 'Жіберушінің банк эмитенті',
            // senderCardID: 'Жіберушінің card id', 
            // senderOwID: 'Жіберушінің ow id', 
            // senderTransferType: 'Жіберушінің аударма әдісі',

            // receiverCardMask: 'Қабылдаушының карта маскасы',
            // receiverCardType: 'Қабылдаушының карта түрі',
            // receiverCardID: 'Қабылдаушының card id',
            // receiverOwID: 'Қабылдаушының ow id',
            // receiverIssuer: 'Қабылдаушының банк эмитенті',
            // receiverTransferType: 'Қабылдаушының аударма әдісі',

            data: 'Қосымша жолдар',

            //            chargeDate: 'Растау күні',
            //            client: 'Клиент',
            //            shop: 'Дүкен',
            //            installment_id: 'Бөліп төлеу мерзімі',
            //            amountBonus: 'Go Сомасы!бонустар',
            //            issuerBankCountry: 'Эмитент банкінің елі',
            //            merchant: 'Мерчантқа сілтеме',
            //            postLink: 'Қайта бағыттау сілтемесі',
            postLinkStatus: 'Постлинк'
            //            payoutAmount: 'Қайтару сомасы',
            //            payoutDate: 'Қайтару күні',
            //            orgAmount: 'Бастапқы сомасы',
            //            template: 'Шаблон платежной страницы',

        },
        groups: {
            location: 'Орналасқан жері',
            paymentPage: 'Төлем беті'
        },
        geolocation: 'Геолокация'
    },
    sortOptions: {
        date: 'Күні бойынша',
        postLink: 'Постлинк',
        code: 'Мәртебесі бойынша',
        type: 'Типі бойынша',
        asc: {
            date: 'басында ескілері',
            postLink: 'басындағы сәттілер',
            code: 'басында күтеді'
        },
        desc: {
            date: 'басында жаңалары',
            postLink: 'басындағы сәтсіздері',
            code: 'алдымен қайтарылғандар'
        }
    },
    installments: {
        full: {
            '3m': '3 ай',
            '6m': '6 ай',
            '9m': '9 ай',
            '12m': '12 ай'
        },
        short: {
            '3m': '3m',
            '6m': '6m',
            '9m': '9m',
            '12m': '12m'
        }
    },
    secureValues: {
        true: 'Иә',
        false: 'Жоқ'
    },
    partialSum: {
        isRequired: 'Толтыруға міндетті сома',
        constraints: 'Төлем сомасынан аспайтын оң сан болуы тиіс'
    },
    operations: {
        fullCharge: {
            title: 'Толық есептен шығару',
            itemError: paymentReference =>
                `#${paymentReference} Төлем бойынша толық есептен шығару қатесі `
        },
        partialCharge: {
            title: 'Ішінара есептен шығару',
            itemError: paymentReference =>
                `#${paymentReference} Төлем бойынша ішінара есептен шығару қатесі `
        },
        cancel: {
            title: 'Болдырмау',
            itemError: paymentReference =>
                `#${paymentReference} Төлемді болдырмау қатесі`
        },
        fullRefund: {
            title: 'Толық қайтару',
            itemError: paymentReference =>
                `#${paymentReference} Төлемді толық қайтару қатесі`
        },
        partialRefund: {
            title: 'Ішінара қайтару',
            itemError: paymentReference =>
                `#${paymentReference} Төлемді ішінара қайтару қатесі `
        }
    },
    postLinkStatus: {
        all: 'Барлығы',
        ok: 'Сәтті',
        fail: 'Қате'
    },
    collapseSummary: range =>
        `${range instanceof Function ? range() : range || 'период'
        } кезең үшін статистика`,
    errors: {
        summaryLoadError: 'Статистиканы жүктеу қатесі',
        paymentsLoadError: 'Төлемдерді жүктеу қатесі'
    }
})
