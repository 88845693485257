import { action, computed, observable } from 'mobx'
import { FlagsGetResponse, getFlags, updateFlags } from '~/api/interactive-learning'
import { noThrow } from '~/utils/control-flow'
import { apiCheck } from '~/api'
import { sectionsOrder } from '~/components/interactive-hints/constants'

class InteractiveHint {

    @observable
    public flags: FlagsGetResponse

    @action
    public setFlags(flags: FlagsGetResponse) {
        this.flags = flags
    }

    @action
    public async loadFlags(merchantId: string) {
        const { value } = await noThrow(apiCheck(getFlags(merchantId)))
        if (value) {
            this.setFlags(value)
        }
        return value
    }

    @action.bound
    public async switchFlagsOff(sectionKeys: string[]) {
        if (this.flags) {
            const { merchantId } = this.flags
            sectionKeys.map((key) => {
                if (this.flags[key]) {
                    this.setFlags({ ...this.flags, [key]: false })
                }
            })
            await noThrow(apiCheck(updateFlags(merchantId, this.flags)))
        }
    }

    @action
    public async cancelAllFlags() {
        if (this.flags) {
            const { merchantId } = this.flags
            this.setFlags({
                merchantId,
                generalInformation: false,
                dashboard: false,
                transactions: false,
                statements: false,
                paymentLinks: false,
                credit: false,
                configureEpayTemplate: false,
                terminalSettings: false,
                team: false,
                statementPage: false,
                firstTransactionModal: false
            })
            await noThrow(apiCheck(updateFlags(merchantId, this.flags)))
        }
    }

    public getPageFlags(sectionKey: string) {
        const trueFlags = []
        if (this.flags) {
            if (this.flags.generalInformation) {
                trueFlags.push('generalInformation')
            }
            if (this.flags[sectionKey]) {
                trueFlags.push(sectionKey)
            }
        }
        return trueFlags
    }

    @computed
    public get showFullTraining() {
        return !sectionsOrder.some((key) => !this.flags[key])
    }

    @computed
    public get activeFlags() {
        return sectionsOrder.filter((key) => this.flags[key])
    }
}

export default new InteractiveHint()