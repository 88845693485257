import { action, computed, observable, runInAction } from 'mobx'

import { apiCheck, signUp } from '~/api'

import { noThrow } from '~/utils/control-flow'
import FieldStore, { isValidArr } from '~/utils/field-store'
import { userError } from '~/utils/user-error'
import { email, required } from '~/utils/validation'

import res from '../res'

export default class SignUpStore {

    public email = new FieldStore<string>([required(() => res().emailRequired), email])

    @observable
    public isSending = false

    @observable
    public error: Error

    @observable
    public isAccountCreated = false

    @computed
    public get errorMessage(): string {
        return userError(this.error).message
    }

    @action
    public async signUp() {

        if (!isValidArr(this.email)) return

        this.isSending = true

        const result = await noThrow(apiCheck(signUp({
            email: this.email.value
        })))

        runInAction(() => {
            this.isSending = false
            this.isAccountCreated = !result.error
            if (!result.error) return
            this.error = result.error
        })
    }

    @action.bound
    public repeat() {

        this.isAccountCreated = false
    }
}
