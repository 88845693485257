import React from 'react'

import { observer } from 'mobx-react'

import ButtonGroup from 'antd/lib/button/button-group'

import { Button, FieldInput, PopConfirm, Radio, Tooltip } from '~/components'

import { formatMoneySum } from '~/utils'

import FieldStore from '~/utils/field-store'

import { InfoCircleIcon } from '~/components/icon'

import { OCTPayment, OCTStatus, OCTType } from '~/api/oct'

import { AmountsByCurrencies } from '~/pages/payment-oct/types'

import styles from './styles.css'

import res from './res'
import { renderCurrencyAmountLabel } from '~/pages/payments/pos/constants/data-fields'

import ModalContainer from '~/components/modal/modal-container'

import MobileHeader from '~/components/modal/mobile-header'
import { FlowResult } from '~/utils/control-flow'

export interface PaymentActionRadio {
    actionType: PaymentActionEnum
    actionName: string
    confirmQuestion: string | React.ReactNode
    dataFieldGetter?: () => FieldStore<string>
    fieldConfirm: (
        payment?: OCTPayment,
        props?: PaymentsActionsProps
    ) => string | React.ReactNode
    confirmedAction: () => void
    form?: (
        payment?: OCTPayment,
        props?: PaymentsActionsProps
    ) => React.ReactNode
    cssClass?: string
}

export interface StatusPaymentActions {
    code: OCTStatus
    actionTypes: PaymentActionEnum[]
    defaultAction?: PaymentActionEnum
}

export enum PaymentActionEnum {
    CHARGE = 'CHARGE',
    PARTIALCHARGE = 'PARTIALCHARGE',
    CANCEL = 'CANCEL',
    PARTIALREFUND = 'PARTIALREFUND',
    FULLREFUND = 'FULLREFUND'
}

const RadioGroup = Radio.Group

const PaymentAction = observer(
    (props: {
        name: string
        onClick?: () => void
        title?: React.ReactNode
        form: React.ReactNode
        okText?: string
        cancelText?: string
        onOk: () => boolean
        disableReason?: string | boolean
    }) => {
        if (props.disableReason) {
            return (
                <Tooltip title={props.disableReason} placement="bottom">
                    <Button disabled={true}>{props.name}</Button>
                </Tooltip>
            )
        }

        return (
            <PopConfirm
                title={props.title}
                content={props.form}
                okText={props.okText || res().okButton}
                cancelText={props.cancelText || res().cancelButton}
                onOk={props.onOk}
            >
                <Button onClick={props.onClick}>{props.name}</Button>
            </PopConfirm>
        )
    }
)

const AmountForm = observer(
    (props: {
        amount: number
        currency: string
        label: string
        field: FieldStore<string>
    }) => (
        <div className={styles.formContent}>
            <div className={styles.amountValues}>
                <div>
                    <div>{res().amountForm.paymentAmount}</div>
                    <div className={styles.value}>
                        {formatMoneySum(props.amount)}
                    </div>
                    <div className={styles.currency}>{props.currency}</div>
                </div>
                <div>
                    <div>{props.label}</div>
                    <div className={styles.value}>
                        <FieldInput
                            store={props.field}
                            size="small"
                            className={
                                !props.field.error ? null : styles.hasError
                            }
                        />
                    </div>
                    <div className={styles.currency}>{props.currency}</div>
                </div>
            </div>
            <div className={styles.error}>{props.field.error}</div>
        </div>
    )
)

interface PaymentsActionsProps {
    selectedPaymentCount: number
    selectedPayments: OCTPayment[]
    selectedPaymentStatus: OCTStatus
    selectedPaymentAmounts: AmountsByCurrencies
    partialChargeAmount: FieldStore<string>
    partialRefundAmount: FieldStore<string>
    actionsDisabled?: boolean
    chargePaymentsInFull()
    chargePaymentPartial()
    cancelPayments()
    refundPaymentsInFull()
    refundPaymentPartial()
}

export class PaymentsActions extends React.Component<PaymentsActionsProps, {}> {
    public render() {
        return <div className={styles.pane}>{this.renderContent()}</div>
    }

    protected authActions() {
        const { props } = this

        let amountForm: JSX.Element

        if (props.selectedPaymentCount === 1) {
            const payment = props.selectedPayments[0]

            amountForm = (
                <AmountForm
                    amount={payment.amount}
                    currency={payment.currency}
                    label={res().amountForm.chargeAmount}
                    field={props.partialChargeAmount}
                />
            )
        }

        return (
            <>
                <div className={styles.actionGroup}>
                    <ButtonGroup className={styles.buttonGroup}>
                        <PaymentAction
                            name={res().fullAction}
                            form={this.createFullForm(res().chargeFullForm)}
                            onOk={props.chargePaymentsInFull}
                            disableReason={
                                this.props.actionsDisabled
                                    ? res().actionInProgress
                                    : undefined
                            }
                        />
                        <PaymentAction
                            name={res().partialAction}
                            onClick={() =>
                                props.partialChargeAmount.set(undefined, false)
                            }
                            title={res().chargePartialTitle}
                            form={amountForm}
                            okText={res().chargePartialOkButton}
                            cancelText={res().partialCancelButton}
                            disableReason={
                                props.actionsDisabled
                                    ? res().actionInProgress
                                    : props.selectedPaymentCount <= 1
                                        ? null
                                        : res().chargePartialDisable
                            }
                            onOk={props.chargePaymentPartial}
                        />
                        <PaymentAction
                            name={res().cancelAction}
                            form={this.createFullForm(res().cancelForm)}
                            disableReason={
                                props.actionsDisabled
                                    ? res().actionInProgress
                                    : undefined
                            }
                            onOk={props.cancelPayments}
                        />
                    </ButtonGroup>
                </div>
            </>
        )
    }

    protected createFullForm(
        form: (
            paymentCount: number,
            amounts: React.ReactNode
        ) => React.ReactNode
    ) {
        const { props } = this
        return form(
            props.selectedPaymentCount,
            getAmountsContent(props.selectedPaymentAmounts)
        )
    }

    protected chargedActions() {
        const { props } = this

        let amountForm: JSX.Element
        const isCreditPartialCancel = false
        let paymentType

        if (props.selectedPaymentCount === 1) {
            const payment = props.selectedPayments[0]
            paymentType = props.selectedPayments[0].senderTransferType

            // if (payment.shopIsCreditCancelWithTransfer) {
            //     isCreditPartialCancel = payment?.shopIsCreditCancelWithTransfer
            // }

            amountForm = (
                <AmountForm
                    amount={payment.amount}
                    currency={payment.currency}
                    label={res().amountForm.payoutAmount}
                    field={props.partialRefundAmount}
                />
            )
        }

        return (
            <>
                <ButtonGroup className={styles.buttonGroup}>
                    <PaymentAction
                        name={res().refundAction}
                        form={this.createFullForm(res().refundFullForm)}
                        disableReason={
                            this.props.actionsDisabled
                                ? res().actionInProgress
                                : undefined
                        }
                        onOk={props.refundPaymentsInFull}
                    />
                    {((paymentType === OCTType.credit && isCreditPartialCancel) || paymentType !== OCTType.credit) && (
                        <PaymentAction
                            name={res().refundPartialTitle}
                            onClick={() =>
                                props.partialRefundAmount.set(undefined, false)
                            }
                            title={res().refundPartialTitle}
                            form={amountForm}
                            okText={res().refundPartialOkButton}
                            cancelText={res().partialCancelButton}
                            disableReason={
                                this.props.actionsDisabled
                                    ? res().actionInProgress
                                    : props.selectedPaymentCount <= 1
                                        ? null
                                        : res().refundPartialDisable
                            }
                            onOk={props.refundPaymentPartial}
                        />)}
                </ButtonGroup>
            </>
        )
    }

    private renderContent() {
        const { props } = this

        if (props.selectedPaymentCount === 0) {
            return (
                <>
                    <InfoCircleIcon className={styles.actionIcon} />{' '}
                    {res().noSelection}
                </>
            )
        }

        const code = props.selectedPaymentStatus
        // const paymentType = props.selectedPayments[0].senderTransferType

        if (code === null) {
            return (
                <>
                    <InfoCircleIcon className={styles.actionIcon} />{' '}
                    {res().multiStatusSelection}
                </>
            )
        }

        // if (code === OCTStatus.auth) {
        //     return this.authActions()
        // }

        if (code === OCTStatus.charged) {
            return this.chargedActions()
        }

        // if (code === OCTStatus.cancelled) {
        //     return (
        //         <>
        //             <InfoCircleIcon className={styles.actionIcon} />{' '}
        //             {res().noActionsForCancelled}
        //         </>
        //     )
        // }

        // if (code === OCTStatus.refunded) {
        //     return (
        //         <>
        //             <InfoCircleIcon className={styles.actionIcon} />{' '}
        //             {res().noActionsForRefunded}
        //         </>
        //     )
        // }

        return ''
    }
}

export default observer(PaymentsActions)

@observer
export class MobilePaymentsActions extends PaymentsActions {
    public state = {
        selectedActionOption: PaymentActionEnum.CHARGE,
        paymentConfirmVisible: false
    }

    protected getActionTitle = (): string => {
        switch (this.state.selectedActionOption) {
            case PaymentActionEnum.CHARGE:
                return res().fullAction
            case PaymentActionEnum.PARTIALCHARGE:
                return res().chargePartialTitle
            case PaymentActionEnum.CANCEL:
                return res().cancelAction
            default:
                return res().fullAction
        }
    }
    protected getActionType = (): string => {
        switch (this.state.selectedActionOption) {
            case PaymentActionEnum.CHARGE:
                return res().fullAction
            case PaymentActionEnum.PARTIALCHARGE:
                return res().chargePartialTitle
            case PaymentActionEnum.CANCEL:
                return res().cancelAction
            default:
                return res().fullAction
        }
    }
    protected authActions() {
        return this.chargedActions()
    }
    protected actionsByStatuses: StatusPaymentActions[] = [
        // {
        //     code: OCTStatus.auth,
        //     actionTypes: [
        //         PaymentActionEnum.CHARGE,
        //         PaymentActionEnum.PARTIALCHARGE,
        //         PaymentActionEnum.CANCEL
        //     ],
        //     defaultAction: PaymentActionEnum.CHARGE
        // },
        {
            code: OCTStatus.charged,
            actionTypes: [
                PaymentActionEnum.FULLREFUND,
                PaymentActionEnum.PARTIALREFUND
            ],
            defaultAction: PaymentActionEnum.FULLREFUND
        }
    ]
    protected paymentRadioActions: PaymentActionRadio[] = [
        {
            actionType: PaymentActionEnum.CHARGE,
            actionName: res().fullAction,
            confirmQuestion: res().confirmActions.simpleText,
            fieldConfirm: payment => (
                <React.Fragment>
                    {res().amountForm.chargeAmount}:{' '}
                    {renderCurrencyAmountLabel(payment.amount, payment)}
                </React.Fragment>
            ),
            confirmedAction: () => this.props.chargePaymentsInFull()
        },
        {
            actionType: PaymentActionEnum.PARTIALCHARGE,
            actionName: res().partialAction,
            form: (payment: OCTPayment): React.ReactNode => (
                <span className={styles.chargeAmountFieldLabel}>
                    <span>{res().partialAction}</span>
                    {this.getControlField(
                        this.props.partialChargeAmount,
                        payment.amount,
                        payment,
                        this.state.selectedActionOption !==
                        PaymentActionEnum.PARTIALCHARGE
                    )}
                </span>
            ),
            confirmQuestion: res().confirmActions.simpleText,
            fieldConfirm: (payment, props) => (
                <React.Fragment>
                    {res().amountForm.chargeAmount}:{' '}
                    {renderCurrencyAmountLabel(
                        props.partialChargeAmount.value,
                        payment
                    )}
                </React.Fragment>
            ),
            dataFieldGetter: () => this.props.partialChargeAmount,
            confirmedAction: () => this.props.chargePaymentsInFull(),
            cssClass: styles.partialChargeElement
        },
        {
            actionType: PaymentActionEnum.FULLREFUND,
            actionName: res().refundAction,
            confirmQuestion: res().confirmActions.actions.REFUND,
            fieldConfirm: payment => (
                <React.Fragment>
                    {res().amountForm.chargeAmount}:{' '}
                    {renderCurrencyAmountLabel(payment.amount, payment)}
                </React.Fragment>
            ),
            confirmedAction: () => this.props.refundPaymentsInFull()
        },
        {
            actionType: PaymentActionEnum.PARTIALREFUND,
            actionName: res().refundPartialTitle,
            form: (payment, props) => (
                <span className={styles.chargeAmountFieldLabel}>
                    <span>{res().refundPartialTitle}</span>
                    {this.getControlField(
                        props.partialRefundAmount,
                        payment.amount,
                        payment,
                        this.state.selectedActionOption !==
                        PaymentActionEnum.PARTIALREFUND
                    )}
                </span>
            ),
            fieldConfirm: (payment, props) => {
                const confirmField = (
                    <React.Fragment>
                        {res().amountForm.chargeAmount}:{' '}
                        {renderCurrencyAmountLabel(
                            props.partialRefundAmount.value,
                            payment
                        )}
                    </React.Fragment>
                )
                return confirmField
            },
            dataFieldGetter: () => this.props.partialRefundAmount,
            confirmQuestion: res().confirmActions.actions.PARTIALREFUND,
            confirmedAction: () => {
                this.props.refundPaymentPartial()
            },
            cssClass: styles.partialChargeElement
        },
        {
            actionType: PaymentActionEnum.CANCEL,
            actionName: res().cancelAction,
            confirmQuestion: res().confirmActions.simpleText,
            fieldConfirm: payment => undefined,
            confirmedAction: () => this.props.cancelPayments()
        }
    ]
    protected getActionsByStatus(payment: OCTPayment) {
        const actionsTypes = this.actionsByStatuses.find(
            (actionType: StatusPaymentActions) =>
                payment.code === actionType.code
        )
        const actionRadioTypes = this.paymentRadioActions.filter(
            (aRadio: PaymentActionRadio) =>
                actionsTypes.actionTypes.includes(aRadio.actionType)
        )
        // const actionRadioControls = actionRadioTypes.map((aRadio: PaymentActionRadio) => aRadio.control(this.state.selectedActionOption, payment))
        return actionRadioTypes
    }
    protected getAction(actionType: PaymentActionEnum) {
        const action = this.paymentRadioActions.find(
            (aRadio: PaymentActionRadio) => aRadio.actionType === actionType
        )
        return action
    }
    protected chargedActions() {
        const { props } = this

        const payment = props.selectedPayments[0]
        const selectedAction = this.state.selectedActionOption
        const amountLabel = renderCurrencyAmountLabel(payment.amount, payment)
        const currentAction = this.getAction(this.state.selectedActionOption)
        const currentActionDisabled = currentAction.dataFieldGetter
            ? !(
                currentAction.dataFieldGetter().value &&
                !parseMoneyField(currentAction.dataFieldGetter()).error &&
                !currentAction.dataFieldGetter().error
            )
            : false
        const posibleActions = this.getActionsByStatus(payment)
        return (
            <React.Fragment>
                <div className={styles.actionGroupMobile}>
                    <div className={styles.paymentAmountLabel}>
                        {res().amountForm.paymentAmount}: &nbsp; {amountLabel}
                    </div>
                    <RadioGroup
                        onChange={e =>
                            this.setState({
                                selectedActionOption: e.target.value
                            })
                        }
                        value={selectedAction}
                        className={styles.verticalRadio}
                    >
                        {posibleActions.map(action => (
                            <Radio
                                key={action.actionType}
                                className={
                                    selectedAction === action.actionType
                                        ? `${styles.verticalRadioElement} ${styles.verticalRadioElementSelected
                                        } ${action.cssClass
                                            ? action.cssClass
                                            : ''
                                        }`
                                        : `${styles.verticalRadioElement} ${styles.partialChargeElement}`
                                }
                                value={action.actionType}
                            >
                                <span className={styles.chargeAmountFieldLabel}>
                                    {action.form ? (
                                        action.form(payment, this.props)
                                    ) : (
                                        <span>{action.actionName}</span>
                                    )}
                                </span>
                            </Radio>
                        ))}
                    </RadioGroup>
                </div>
                <div className={styles.mainButtonContainer}>
                    <Button
                        data-semantic-id="charge_button"
                        disabled={currentActionDisabled}
                        type="primary"
                        size="large"
                        className={styles.mainButton}
                        onClick={() =>
                            this.setState({ paymentConfirmVisible: true })
                        }
                    >
                        {res().confirmActions.mainText}
                    </Button>
                </div>
                <ModalContainer
                    visible={this.state.paymentConfirmVisible}
                    title={currentAction.actionName}
                    onClose={() =>
                        this.setState({ paymentConfirmVisible: false })
                    }
                    closable={true}
                    headerControl={
                        <MobileHeader
                            title={undefined}
                            onCancel={() =>
                                this.setState({ paymentConfirmVisible: false })
                            }
                        />
                    }
                >
                    <React.Fragment>
                        <div className={styles.paymentAmountLabel}>
                            {currentAction.confirmQuestion}
                        </div>
                        <div className={styles.paymentAmountLabel}>
                            {currentAction.fieldConfirm(payment, this.props)}
                        </div>
                        <div className={styles.mainButtonContainer}>
                            <Button
                                data-semantic-id="charge_button"
                                type="primary"
                                size="large"
                                className={styles.mainButton}
                                onClick={() =>
                                    this.setState(
                                        { paymentConfirmVisible: false },
                                        currentAction.confirmedAction
                                    )
                                }
                            >
                                {`${res().confirmActions.mainText}`}
                            </Button>
                        </div>
                    </React.Fragment>
                </ModalContainer>
            </React.Fragment>
        )
    }

    private getControlField = (
        fieldToControl: FieldStore<string>,
        amount: number,
        payment: OCTPayment,
        disabled?: boolean
    ) => {
        const { props } = this
        const label = res().amountForm.payoutAmount
        const partialMoneyValue = parseMoneyField(fieldToControl) // fieldToControl.value// parseMoneyField(fieldToControl) // && props.partialChargeAmount.value ? parseFloat(props.partialChargeAmount.value) : 0

        const chargeAmount = !partialMoneyValue.error
            ? amount - partialMoneyValue.value
            : amount
        // const chargeAmount = partialMoneyValue ? amountField.value - partialMoneyValue : amountField.value
        const chargeAmountLabel = disabled ? (
            <span></span>
        ) : (
            renderCurrencyAmountLabel(chargeAmount, payment)
        )
        return (
            <div
                className={
                    disabled
                        ? `${styles.chargeAmountField} ${styles.fieldDisabled}`
                        : `${styles.chargeAmountField}`
                }
            >
                <div className={styles.chargeAmountFieldLabel}>
                    {label} ({payment.currency})
                </div>
                <div className={styles.value}>
                    <FieldInput
                        store={fieldToControl}
                        size="small"
                        trigger={'onChange'}
                        className={
                            !fieldToControl.error || disabled
                                ? null
                                : styles.hasError
                        }
                        disabled={disabled}
                    />
                </div>
                <div>
                    {res().amountForm.paymentAmount}: {chargeAmountLabel}
                </div>
            </div>
        )
    }
}

const getAmountsContent = (amountsByCurrencies: AmountsByCurrencies) => {
    const entries = Object.entries(amountsByCurrencies)

    return (
        entries &&
        entries.map(([currency, amount], i) => (
            <span
                key={`amount_by_currency_${currency}`}
                className={styles.amountWithCurrency}
            >
                <span className={styles.amount}>{formatMoneySum(amount)}</span>
                <span className={styles.currency}>{currency}</span>
                {i < entries.length - 1 ? ', ' : ''}
            </span>
        ))
    )
}

const parseMoneyField = (field: FieldStore<string>): FlowResult<number> => {
    const parsedValueResult: FlowResult<number> =
        field && field.value
            ? { value: parseFloat(field.value) }
            : { value: 0, error: new Error('incorect value') }
    return parsedValueResult
}
