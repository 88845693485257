import { ApiError } from '~/api'

import apiRes from '~/api/res'
import res from '~/res'

import { FlowResult, noThrow, NoThrowArg, PromiseProvider } from './control-flow'
import notification from './message'

export class UserError extends Error {

    public constructor(errorMessage: string, innerError: Error) {

        super(errorMessage)

        Object.setPrototypeOf(this, UserError.prototype)

        this.name = 'UserError'
        this.innerError = innerError
        this.stack = Error().stack
    }

    public innerError: Error
}

export function userError(error): UserError {

    const codeError = error.code ? res().errorCodes[error.code] : undefined
    let message = codeError ? codeError : res().unknownError

    if (error instanceof UserError) {
        return error
    }

    if (error instanceof ApiError) {
        const errorMessage = apiRes().errors[error.code]
        if (errorMessage) message = errorMessage
    }

    if (error instanceof TypeError && error.message === 'Failed to fetch') {
        error = new TypeError(res().failedToFetch)
    }

    return new UserError(message, error)
}

function showError(error: any, title?: string) {

    const err = userError(error)

    notification.error(err, title)

    return err
}

function showErrorProvider(title?: string) {

    return !title ? showError : (error) => showError(error, title)
}

export function showErrorOnThrow<T>(promise: PromiseProvider<T>, errorTitle?: string): Promise<FlowResult<T>>
export function showErrorOnThrow<T>(cb: () => T, errorTitle?: string): FlowResult<T>
export function showErrorOnThrow<T>(arg: NoThrowArg<T>, errorTitle?: string): FlowResult<T> | Promise<FlowResult<T>> {

    return noThrow(arg as any, showErrorProvider(errorTitle))
}
