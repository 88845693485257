import locale from '~/utils/locale'

export default locale.create<{
    title: string,
    confirmTitle: string,

    labels: {
        shop_name: string,
        shop_name_placeholder,
        logo: string,
        lang: string,
        email: string,
        email_placeholder: string,
        phone: string,
        phone_placeholder: string,
        required: string,
        colorScheme: string,

        bg: string,
        card_bg: string,
        btn_color: string,
        txt_color: string,
        card_txt_color: string,

        fileDescription: string,
        gradientDescription: string,

        reset: string,
        save: string,
        load: string,
        custom: string
    },

    sampleForm: {
        Order: string,
        PaymentStore: string,
        Commission: string,
        Seller: string,
        cardNumber: string,
        expires: string,
        cardName: string,
        email: string,
        phoneNumber: string,
        pay: string,
        back: string
    },

    messages: {
        errorTitle: string,
        epayTemplateUpdated: string,
        cannotLoadShops: string,
        invalidFile: string,
        unsavedChanges: string,
        valueRequired: string,
        invalidEmail: string,
        invalidPhone: string
    }
}>()
