import res from './res'

import { PaymentStatus } from '~/api'

res.add('en', {
    statistics: 'Statistics',
    collapseSummary: range => `for ${range instanceof Function ? range() : range || 'period'}`,
    sorting: 'Sorting',
    filter: 'Filter'
})
