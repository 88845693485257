import 'moment/locale/ru'
import React from 'react'
import res from './res'
import instructionFile from '~/assets/files/instruction-kk.pdf'

res.add('kk', {
    faqTitle: 'FAQ',
    instructionMerchantAccount: 'Жеке кабинетті басқару бойынша нұсқаулар',
    searchPlaceholder: 'Іздеу…',
    download: 'Жүктеу',
    messages: {
        required: 'Міндетті түрде'
    },
    hintsModal: {
        title: 'Интерактивті оқыту',
        question: 'Сұрақтарыңыз бар бөлімді таңдаңыз немесе толық оқуды бастаңыз.',
        all: 'Толық оқу',
        common: 'Жалпы ақпарат',
        statistics: 'Статистика',
        transactions: 'Транзакциялар',
        terminals: 'ePay төлемдер',
        statements: 'Үзінді көшірмелер',
        link: 'Төлемге сілтемелер',
        banksProducts: 'Банк өнімдері',
        credit: 'Бөліп төлеу және несиелеу',
        settings: 'Баптаулар',
        template: 'Үлгі бейімдеу',
        terminalSettings: 'Терминалдардың баптаулары',
        team: 'Қызметкерлер',
        cancel: 'Бас тарту',
        start: 'Бастау'
    },
    instructionText: (handleClick) => <>
        Кабинетте жұмыс істеу туралы толық ақпарат үшін нұсқаулықты <a href={instructionFile} download>жүктей аласыз</a>
        {handleClick ? <> немесе <a onClick={handleClick}>интерактивті тренингті бастауға болады</a></> : null}
    </>
})
