import React from 'react'

import { Layout } from '~/components'

import res from './res'

import styles from './styles.css'

import BrowserStore from '~/services/browser/browser-state'

const Footer = () => (
    <Layout.Footer className={styles.footer}>
        <div>{res().copyright1}</div>
        <div>{res().copyright2}</div>
    </Layout.Footer>
)

export default Footer
