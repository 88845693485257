import { get, put } from '~/utils/ajax'

import { FlagsUpdateRequest } from './contracts'

export * from './contracts'

export const getFlags = (merchantId: string) => get<any>(`interactive-learning/${merchantId}`)
    .then(response => {
        const { result, error } = response

        if (error) return { error }

        return { result }
    })

export const updateFlags = (
    merchantId: string,
    data: FlagsUpdateRequest
) => put(`interactive-learning/${merchantId}`, data)
