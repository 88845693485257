import { get, post } from '~/utils/ajax'

// import { getCardSystemType } from '~/utils'

import {
    OCTPaymentRequest,
    OCTPaymentResponse,
    OCTSummary,
    OCTSummaryRequest
} from './contracts'

export * from './contracts'

export const getOCTPayments = (request: OCTPaymentRequest) =>
    post<OCTPaymentResponse>('chpayment/operations', request).then(response => {
        // if (!response.error && !!response.result && !!response.result.records) {
        //     response.result.records.forEach(p => {
        //         p.cardType = p.cardMask
        //             ? getCardSystemType(p.cardMask)
        //             : undefined
        //         p.fee = undefined
        //         p.ipCountry = 'Казахстан'
        //         p.ipCity = 'г. Алматы'
        //         p.ipRegion = 'Алматинская обл.'
        //     })
        // }
        return response
    })

export const getOCTSummary = (request: OCTSummaryRequest) =>
    get<OCTSummary>('operations/summary', {
        from: request.from.format('YYYY-MM-DD'),
        to: request.to.format('YYYY-MM-DD')
    }).then(data => {
        if (!data.error) {
            data.result.timeline.forEach(item => {
                item.time = new Date(item.time)
            })
        }

        return data
    })

export const cancelOCT = (paymentId: string) =>
    post<void>(`operation/${paymentId}/cancel`)

export const chargeOCT = (paymentId: string, amount?: number) =>
    post<void>(
        `operation/${paymentId}/charge${amount ? `?amount=${amount}` : ''}`
    )

export const refundOCT = (paymentId: string, amount?: number) =>
    post<void>(
        `operation/${paymentId}/refund`,
        amount === undefined ? undefined : { amount }
    )

export const refundCreditOCT = (creditOrderId, creditAmount) =>
    post<void>('credit-cancel', { orderId: creditOrderId, amount: creditAmount })
