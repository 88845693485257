import React from 'react'

export const faqs =
{
    'ru':
        [
            {
                title: 'Как подключить магазин к интернет – эквайрингу ePay?',
                answer: <>
                    <p>Чтобы подключить магазин к интернет – эквайрингу ePay необходимо:</p>
                    <ul>
                        <li> <span>Шаг 1.</span> Зарегистрироваться в Личном кабинете е-коммерсанта https://epay.homebank.kz;</li>
                        <li> <span>Шаг 2.</span> Перейти в раздел «Мои заявки»;</li>
                        <li> <span>Шаг 3.</span> Выбрать пункт «Новая заявка»;</li>
                        <li> <span>Шаг 4.</span> Заполнить заявку и нажать на кнопку «Отправить»;</li>
                        <li> <span>Шаг 5.</span> Ожидать ответа от Банка, Вам сообщат о результате рассмотрения заявки.</li>
                    </ul>

                </>
            },
            {
                title: 'Как получить техническую документацию для подключения магазин к интернет – эквайрингу ePay?',
                answer: <>Техническая документация расположена по ссылке: <a href="https://epayment.kz/ru/docs">https://epayment.kz/ru/docs</a></>
            },
            {
                title: 'Зачем нужна оферта?',
                answer: 'Оферта – это краткое предложение к заключению договора, в котором указаны важные условия сделки. Она нужна для того, чтобы проинформировать клиента о содержании договора. Говоря простым языком, за какой товар/услугу клиент производит платеж. В том числе размещение оферты на сайте коммерсанта – это требование МПС.'
            },
            {
                title: 'Сроки зачисления средств?',
                answer: 'Перевод денег на Счет Предприятия по проведенным Транзакциям осуществляется Банком в течение 3 рабочих дней со дня совершения Транзакции. Срок перевода денег может быть изменен по согласованию Сторон, либо по усмотрению Банка в одностороннем порядке в случаях, установленных внутренними документами Банка'
            },
            {
                title: 'Apple/Samsung/Google PAY, принципы работы?',
                answer:
                    <>
                        <p>
                            Apple – данный метод оплаты возможен на устройствах с операционной системой IOS, при переходе на платежную страницу через браузер safari.
                            При открытии страницы методом «Платежный виджет», фрейм системы apple блокирует возможность оплаты методом apple pay.
                            Рекомендуется подключаться методом redirect (раздел документации Платежная страница)
                            </p>
                        <p> Samsung- оплата возможна на устройствах с установленной программой Samsung Pay </p>
                        <p> Google – поддерживается на различных платформах </p>
                    </>
            },
            {
                title: 'Что такое 3D Secure?',
                answer: 'Технология 3D Secure (от англ. Three-Domain Secure) - это самая современная технология обеспечения безопасности платежей по картам в сети интернет. Позволяет идентифицировать владельца карточки при проведении интернет-платежей с помощью специального одноразового пароля 3D Secure, который направляется в виде SMS-сообщения на номер мобильного телефона.'
            },
            {
                title: 'Как отключить 3DS пароль?',
                answer: (
                    <>
                        Чтобы отключить 3DS пароль необходимо:
                        Предоставить Банку официальное письмо/запрос на отключение 3DS пароля с указанием в письме:
                        <ul>
                            <li> Подробное обоснование для чего необходимо отключить 3DS/SC;</li>
                            <li> Понимание рисков того, что транзакции будут проходить с индикатором электронной коммерции ECI7, который не дает защиты от возвратов платежей по причине мошенничества;</li>
                            <li> План по предотвращению мошенничества и урегулирования уже случившегося мошенничества.</li>
                        </ul>
                        <strong> Банк вправе отказать в отключении 3DS. </strong>
                    </>
                )
            },
            {
                title: 'Кто несет ответственность за платежи, проведенные без 3DS пароля?',
                answer: 'Коммерсант (т.е. владелец магазина) должен принимать все риски и нести ответственность по возможным мошенническим действиям в рамках отключения 3DS пароля и готов возместить любую сумму по первому требованию Банка, в виду возможных штрафов, выставленных Банку со стороны МПС.'
            },
            {
                title: 'Как я могу увеличить суточные лимиты  на  проведение операций через  свой Интернет-магазин?',
                answer: 'Чтобы увеличить лимиты на проведение операций, Вам необходимо обратиться к менеджеру Банка и направить официальное письмо с указанием причины, по которой необходимо увеличение лимита. '
            },
            {
                title: 'Как отменить платеж, по которому уже произведено списание?',
                answer: (
                    <>
                        Чтобы отменить платеж (вернуть сумму на карту клиента, на его доступный баланс) необходимо:
                        <ul>
                            <li><span>Шаг 1.</span> Найти платеж по периоду, либо по имеющимся атрибутам;</li>
                            <li><span>Шаг 2.</span> Отметить транзакцию «галочкой», либо нажать на платеж и выбрать действие «Оформить возврат», «Частичный возврат».</li>
                        </ul>
                    </>
                )
            },
            {
                title: 'Через какое время можно оформить возрат списанного платежа? ',
                answer: 'Возврат списанного платежа не должен производиться в тот же день, когда было произведено его списание. ' +
                    'Если возникла необходимость вернуть сумму сразу после списания необходимо произвести возврат на следующий день. ' +
                    'Возврат может быть проведен в любое время в течение 3-х лет с момента оплаты, при условии что карта клинта активна.'
            },
            {
                title: 'Как можно сверить поступление денежных средств?',
                answer: (
                    <ul>
                        <li> <span>Шаг 1.</span> Выбрать раздел «Выписки».</li>
                        <li> <span>Шаг 2.</span> Выбрать необходимый терминал, нажать на кнопку «Сформировать» и установить период «с» даты оплаты и период «по», а также формат выписки. Выписка выгружается в формате CSV и XLSX (Excel)</li>
                    </ul>
                )
            },
            {
                title: 'Как я могу отправить ссылку на оплату своему клиенту? ',
                answer: (
                    <div>
                        <p>Вы можете генерировать ссылку на оплату в личном кабинете, отправлять ее на почту клиента или в любой мессенджер.</p>
                        <p>Чтобы сформировать ссылку на оплату, необходимо:</p>
                        <ul>
                            <li> <span>Шаг 1.</span> Перейти в раздел «Ссылку на оплату»;</li>
                            <li> <span>Шаг 2.</span> Нажать на кнопку «Сформировать ссылку», которая генерирует детали оплаты;</li>
                            <li> <span>Шаг 3.</span> Заполнить детали оплаты и нажать на кнопку «применить»;</li>
                            <li> <span>Шаг 4.</span> Скопировать и отправить ссылку клиенту (по месенджеру или на электронный адрес).</li>
                        </ul>
                    </div>
                )
            }
        ],
    'kk':
        [
            {
                title: 'Дүкенді ePay-дің интернет – эквайрингіне қалай қосуға болады?',
                answer: <>
                    <p>Дүкенді ePay-дің интернет-эквайрингіне қосу үшін:</p>
                    <ul>
                        <li> <span> 1-қадам. </span> Е-коммерсанттың https://epay.homebank.kz  Жеке кабинетіне тіркеліңіз;</li>
                        <li> <span> 2-қадам. </span> «Менің өтінімдерім» бөліміне өтіңіз;</li>
                        <li> <span> 3-қадам. </span> «Жаңа өтінім» тармағын таңдаңыз;</li>
                        <li> <span> 4-қадам. </span> Өтінімді толтырып, «Жіберу» батырмасын басыңыз;</li>
                        <li> <span> 5-қадам. </span> Банктен жауап күтіңіз, Сізге өтінімді қарау нәтижесі туралы хабарланады.</li>
                    </ul>
                </>
            },
            {
                title: 'Дүкенді ePay-дің интернет – эквайрингіне қосу үшін техникалық құжататманы қалай қосуға болады?',
                answer: <>Техникалық құжаттама <a href="https://epayment.kz/ru/docs">https://epayment.kz/ru/docs</a> сілтемесі бойынша орналасқан</>
            },
            {
                title: 'Оферта не үшін керек?',
                answer: 'Оферта-бұл мәміленің маңызды талаптарын көрсететін келісімшартқа қысқаша ұсыныс. Бұл клиентке шарттың мазмұны туралы хабарлау үшін қажет. Қарапайым тілмен айтқанда клиент қандай тауар/қызмет үшін төлем жасайды. Оның ішінде Офертаны коммерсанттың веб – сайтына орналастыру - бұл ХТЖ-нің талабы.'
            },
            {
                title: 'Қаражатты есепке алу мерзімі?',
                answer: 'Жүргізілген транзакциялар бойынша кәсіпорынның шотына ақша аударуды Банк транзакция жасалған күннен бастап 3 жұмыс күні ішінде жүзеге асырады. Ақша аудару мерзімі тараптардың келісімі бойынша немесе  Банктің ішкі құжаттарында белгіленген жағдайларда банктің қалауы бойынша біржақты тәртіппен өзгертілуі мүмкін'
            },
            {
                title: 'Apple / Samsung / Google PAY, жұмыс принциптері?',
                answer:
                    <>
                        <p>
                            Apple-бұл төлем әдісі IOS операциялық жүйесі бар құрылғыларда, Safari браузері арқылы төлем бетіне өту кезінде мүмкін болады.
                            Бетті «Ттөлем виджеті» әдісімен ашқан кезде apple жүйесінің жақтауы apple pay әдісімен төлем жасау мүмкіндігін бұғаттайды.
                            Redirect әдісімен қосылу ұсынылады (құжаттама бөлімі Төлем беті).
                            </p>
                        <p> Samsung-Samsung Pay бағдарламасы орнатылған құрылғыларда төлем жасауға болады. </p>
                        <p> Google - әртүрлі платформаларда қолдау көрсетіледі. </p>
                    </>
            },
            {
                title: '3D Secure деген не?',
                answer: '3D Secure технологиясы (ағылш. Three - Domain Secure) - бұл интернет желісіндегі карталар бойынша төлемдердің қауіпсіздігін қамтамасыз етудің ең заманауи технологиясы. Ұялы телефон нөміріне SMS-хабарлама түрінде жіберілетін 3D Secure арнайы бір реттік құпия сөзінің көмегімен интернет-төлемдер жүргізу кезінде карточка иесін сәйкестендіруге мүмкіндік береді.'
            },
            {
                title: '3DS құпия сөзін қалай өшіру керек?',
                answer:
                    <>
                        3DS құпия сөзін өшіру үшін сізге:
                        Банкке хатта көрсетілген 3DS құпия сөзін өшіру туралы ресми хат/сұраныс беру:
                        <ul>
                            <li> 3 DS /SC өшіру үшін не қажет екендігі туралы толық негіздеме;</li>
                            <li> Транзакциялар алаяқтық себебінен төлемдерді қайтарудан қорғауды қамтамасыз етпейтін  ЕСІ7 электрондық коммерция индикаторымен жүзеге асырылатын тәуекелдерді түсіну;</li>
                            <li> Алаяқтықтың алдын алу және бұрыннан болған алаяқтықты реттеу жоспары.</li>
                        </ul>
                        <strong> Банк 3DS өшіруден бас тартуға құқылы. </strong>
                    </>
            },
            {
                title: '3DS құпия сөзіcіз жүргізілген төлемдер үшін кім жауап береді?',
                answer: 'Саудагер (яғни дүкен иесі) барлық тәуекелдерді қабылдауы керек және 3DS паролін өшіру аясында мүмкін болатын алаяқтық әрекеттер үшін жауап беруі және банктің бірінші талабы бойынша кез-келген соманы ХТЖ тарапынан Банкке салынуы мүмкін айыппұлдарға байланысты өтеуге дайын болуы керек.'
            },
            {
                title: 'Интернет-дүкен арқылы операцияларды жүргізуге тәуліктік лимиттерді қалай ұлғайта аламын?',
                answer: 'Операцияларды жүргізуге лимиттерді ұлғайту үшін Сіздің банк менеджеріне хабарласып, лимитті ұлғайту себебін көрсететін ресми хат жіберуіңіз керек. '
            },
            {
                title: 'Есептен шығарылған төлемді қалай болдырмауға болады?',
                answer: (
                    <>
                        Төлемді болдырмау үшін (соманы клиенттің картасына, оның қол жетімді балансына қайтару) сіз:
                        <ul>
                            <li> <span> 1-қадам. </span> Төлемді кезең бойынша немесе бар атрибуттар бойынша табыңыз;</li>
                            <li> <span> 2-қадам. </span> Транзакцияны «құсбелгімен» белгілеңіз немесе төлемге басыңыз және «Қайтаруды рәсімдеу», «Ішінара қайтару» әрекетін таңдаңыз.</li>
                        </ul>
                    </>
                )
            },
            {
                title: 'Есептен шығарылған төлемді қанша уақыттан кейін қайтаруға болады?',
                answer: 'Есептен шығарылған төлемді қайтару оны есептен шығару жүргізілген күні жүргізілмеуі тиіс. ' +
                    'Егер соманы есептен шығарғаннан кейін бірден қайтару қажет болса, келесі күні қайтару қажет.\n' +
                    'Қайтару Клиент картасы белсенді болған жағдайда төлем жасалған сәттен бастап 3 жыл ішінде кез келген уақытта жүргізілуі мүмкін.\n'
            },
            {
                title: 'Ақша қаражаттарының түсуін қалай салыстыруға болады?',
                answer: (
                    <ul>
                        <li> <span> 1-қадам. </span> «Үзінділер» бөлімін таңдау.</li>
                        <li> <span> 2-қадам. </span>  Қажетті терминалды таңдап, «Қалыптастыру» батырмасын басып, төлеу күні  («бастап») мен  («дейін») кезеңін, сондай-ақ үзінді көшірменің форматын белгілеу. Үзінді CSV және XLSX (Excel) форматында жүктеліп шығарылады</li>
                    </ul>
                )
            },
            {
                title: 'Клиентіме төлем сілтемесін қалай жіберуіме болады?',
                answer: (
                    <div>
                        <p>Сіз Жеке кабинетте төлемге сілтеме жасай аласыз, оны клиенттің поштасына немесе кез-келген мессенджерге жібере аласыз.</p>
                        <p>Төлемге сілтеме жасау үшін сіз:</p>
                        <ul>
                            <li> <span> 1-қадам. </span> «Төлем сілтемесі» бөліміне өтіңіз;</li>
                            <li> <span> 2-қадам. </span> Төлем мәліметтерін жасайтын «Сілтеме жасау» батырмасын басыңыз;</li>
                            <li> <span> 3-қадам. </span> Төлем мәліметтерін толтырып, «Қолдану» батырмасын басыңыз;</li>
                            <li> <span> 4-қадам. </span> Клиентке сілтемені көшіру және жіберу (месенджер немесе электрондық пошта мекенжайы бойынша).</li>
                        </ul>
                    </div>
                )
            }
        ],
    'en':
        [
            {
                title: 'Как подключить магазин к интернет – эквайрингу ePay?',
                answer: <>
                    <p>Чтобы подключить магазин к интернет – эквайрингу ePay необходимо:</p>
                    <ul>
                        <li> <span>Шаг 1.</span> Зарегистрироваться в Личном кабинете е-коммерсанта https://epay.homebank.kz;</li>
                        <li> <span>Шаг 2.</span> Перейти в раздел «Мои заявки»;</li>
                        <li> <span>Шаг 3.</span> Выбрать пункт «Новая заявка»;</li>
                        <li> <span>Шаг 4.</span> Заполнить заявку и нажать на кнопку «Отправить»;</li>
                        <li> <span>Шаг 5.</span> Ожидать ответа от Банка, Вам сообщат о результате рассмотрения заявки.</li>
                    </ul>

                </>
            },
            {
                title: 'Как получить техническую документацию для подключения магазин к интернет – эквайрингу ePay?',
                answer: <>Техническая документация расположена по ссылке: <a href="https://epayment.kz/ru/docs">https://epayment.kz/ru/docs</a></>
            },
            {
                title: 'Зачем нужна оферта?',
                answer: 'Оферта – это краткое предложение к заключению договора, в котором указаны важные условия сделки. Она нужна для того, чтобы проинформировать клиента о содержании договора. Говоря простым языком, за какой товар/услугу клиент производит платеж. В том числе размещение оферты на сайте коммерсанта – это требование МПС.'
            },
            {
                title: 'Сроки зачисления средств?',
                answer: 'Перевод денег на Счет Предприятия по проведенным Транзакциям осуществляется Банком в течение 3 рабочих дней со дня совершения Транзакции. Срок перевода денег может быть изменен по согласованию Сторон, либо по усмотрению Банка в одностороннем порядке в случаях, установленных внутренними документами Банка'
            },
            {
                title: 'Apple/Samsung/Google PAY, принципы работы?',
                answer:
                    <>
                        <p>
                            Apple – данный метод оплаты возможен на устройствах с операционной системой IOS, при переходе на платежную страницу через браузер safari.
                            При открытии страницы методом «Платежный виджет», фрейм системы apple блокирует возможность оплаты методом apple pay.
                            Рекомендуется подключаться методом redirect (раздел документации Платежная страница)
                            </p>
                        <p> Samsung- оплата возможна на устройствах с установленной программой Samsung Pay </p>
                        <p> Google – поддерживается на различных платформах </p>
                    </>
            },
            {
                title: 'Что такое 3D Secure?',
                answer: 'Технология 3D Secure (от англ. Three-Domain Secure) - это самая современная технология обеспечения безопасности платежей по картам в сети интернет. Позволяет идентифицировать владельца карточки при проведении интернет-платежей с помощью специального одноразового пароля 3D Secure, который направляется в виде SMS-сообщения на номер мобильного телефона.'
            },
            {
                title: 'Как отключить 3DS пароль?',
                answer: (
                    <>
                        Чтобы отключить 3DS пароль необходимо:
                        Предоставить Банку официальное письмо/запрос на отключение 3DS пароля с указанием в письме:
                        <ul>
                            <li> Подробное обоснование для чего необходимо отключить 3DS/SC;</li>
                            <li> Понимание рисков того, что транзакции будут проходить с индикатором электронной коммерции ECI7, который не дает защиты от возвратов платежей по причине мошенничества;</li>
                            <li> План по предотвращению мошенничества и урегулирования уже случившегося мошенничества.</li>
                        </ul>
                        <strong> Банк вправе отказать в отключении 3DS. </strong>
                    </>
                )
            },
            {
                title: 'Кто несет ответственность за платежи, проведенные без 3DS пароля?',
                answer: 'Коммерсант (т.е. владелец магазина) должен принимать все риски и нести ответственность по возможным мошенническим действиям в рамках отключения 3DS пароля и готов возместить любую сумму по первому требованию Банка, в виду возможных штрафов, выставленных Банку со стороны МПС.'
            },
            {
                title: 'Как я могу увеличить суточные лимиты  на  проведение операций через  свой Интернет-магазин?',
                answer: 'Чтобы увеличить лимиты на проведение операций, Вам необходимо обратиться к менеджеру Банка и направить официальное письмо с указанием причины, по которой необходимо увеличение лимита. '
            },
            {
                title: 'Как отменить платеж, по которому уже произведено списание?',
                answer: (
                    <>
                        Чтобы отменить платеж (вернуть сумму на карту клиента, на его доступный баланс) необходимо:
                        <ul>
                            <li><span>Шаг 1.</span> Найти платеж по периоду, либо по имеющимся атрибутам;</li>
                            <li><span>Шаг 2.</span> Отметить транзакцию «галочкой», либо нажать на платеж и выбрать действие «Оформить возврат», «Частичный возврат».</li>
                        </ul>
                    </>
                )
            },
            {
                title: 'Через какое время можно оформить возрат списанного платежа? ',
                answer: 'Возврат списанного платежа не должен производиться в тот же день, когда было произведено его списание. ' +
                    'Если возникла необходимость вернуть сумму сразу после списания необходимо произвести возврат на следующий день. ' +
                    'Возврат может быть проведен в любое время в течение 3-х лет с момента оплаты, при условии что карта клинта активна.'
            },
            {
                title: 'Как можно сверить поступление денежных средств?',
                answer: (
                    <ul>
                        <li> <span>Шаг 1.</span> Выбрать раздел «Выписки».</li>
                        <li> <span>Шаг 2.</span> Выбрать необходимый терминал, нажать на кнопку «Сформировать» и установить период «с» даты оплаты и период «по», а также формат выписки. Выписка выгружается в формате CSV и XLSX (Excel)</li>
                    </ul>
                )
            },
            {
                title: 'Как я могу отправить ссылку на оплату своему клиенту? ',
                answer: (
                    <div>
                        <p>Вы можете генерировать ссылку на оплату в личном кабинете, отправлять ее на почту клиента или в любой мессенджер.</p>
                        <p>Чтобы сформировать ссылку на оплату, необходимо:</p>
                        <ul>
                            <li> <span>Шаг 1.</span> Перейти в раздел «Ссылку на оплату»;</li>
                            <li> <span>Шаг 2.</span> Нажать на кнопку «Сформировать ссылку», которая генерирует детали оплаты;</li>
                            <li> <span>Шаг 3.</span> Заполнить детали оплаты и нажать на кнопку «применить»;</li>
                            <li> <span>Шаг 4.</span> Скопировать и отправить ссылку клиенту (по месенджеру или на электронный адрес).</li>
                        </ul>
                    </div>
                )
            }
        ]
}
