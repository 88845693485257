import React, { CSSProperties } from 'react'

import { computed } from 'mobx'

import moment from 'moment'

import { GeolocationPane, PaymentCardIcon } from '~/components'

import { Column } from '~/components/grid'

import CardType from '~/constants/card-systems'

import { CreditType, EpayPayment, PaymentStatus } from '~/api/payments'

import { formatMoneySum } from '~/utils'
import strict from '~/utils/strict'

import styles from '../styles.css'

import StatusIcon from '~/components/status-icon'

import { DetailedFieldsGroup } from '../../types'

import statusStyles from '../constants/payment-statuses'

import res from '../res'
import locale from '~/utils/locale'
import CollapseChargeback from '~/pages/payments/components/collapse-chargeback'

const renderCurrencyAmount = (
    value: number,
    payment?: EpayPayment,
    style?: CSSProperties
) => {
    return (
        <span style={style}>
            <span className={styles.amountValue}>{formatMoneySum(value)}</span>
            <span className={styles.amountCurrency}>{payment.currency}</span>
        </span>
    )
}

const renderDate = (value, hideHours?: boolean) =>
    value ? (
        <span className={styles.date}>
            <span>{moment.parseZone(value).format('DD.MM.YYYY')}</span>
            {!hideHours && (
                <span className="date-hours">
                    {locale.lang === 'en' ? moment.parseZone(value).locale('en').format('hh:mm a') : moment.parseZone(value).format('HH:mm ')}
                </span>
            )}
        </span>
    ) : null

const renderGeolocationText = (payment: EpayPayment) => {
    const {
        ipCity,
        ipCountry,
        ipRegion,
        ipDistrict,
        ipLatitude,
        ipLongitude
    } = payment

    let locationSpecification = [ipCountry, ipRegion, ipDistrict]
        .filter(item => !!item)
        .join(', ')

    if (locationSpecification.length) {
        locationSpecification = '(' + locationSpecification + ')'
    }

    return (
        <div>
            <div>
                {ipCity} {locationSpecification}
            </div>
            <a
                href={`http://www.google.com/maps/place/${ipLatitude},${ipLongitude}`}
            >{`N${ipLatitude}, E${ipLongitude}`}</a>
        </div>
    )
}

const renderGeolocationMap = (payment: EpayPayment) => {
    const {ipLatitude, ipLongitude} = payment

    return (
        <div className={styles.geolocationContainer}>
            <GeolocationPane
                center={{
                    lat: ipLatitude,
                    lng: ipLongitude
                }}
            />
        </div>
    )
}

const renderResultMessage = (value, payment: EpayPayment) => {
    const {resultCode} = payment
    return res().errorCodes[resultCode] ? res().errorCodes[resultCode] : null
}

const fields = computed(() => {
    const {short: titles, full: tooltips} = res().dataFields
    return strict<Array<Column<EpayPayment>>>([
        {
            field: 'shop',
            title: titles.shop,
            titleTooltip: tooltips.shop,
            width: 170
        },
        {
            field: 'invoiceId',
            title: titles.invoiceId,
            titleTooltip: tooltips.invoiceId,
            render: value => <span className={styles.orderNumber}>{value}</span>,
            width: 180
        },
        {
            field: 'createdDate',
            title: titles.createdDate,
            titleTooltip: tooltips.createdDate,
            render: value => renderDate(value),
            width: 200
        },
        {
            field: 'orgAmount',
            title: titles.orgAmount,
            titleTooltip: tooltips.orgAmount,
            render: (value, payment) => (
                <span>
                    {formatMoneySum(value)} {payment.currency}
                </span>
            ),
            width: 150
        },
        {
            field: 'amount',
            title: titles.amount,
            titleTooltip: tooltips.amount,
            className: 'rightAlign',
            render: renderCurrencyAmountLabel,
            width: 150
        },
        {
            field: 'fee',
            title: titles.fee,
            titleTooltip: tooltips.fee,
            className: 'rightAlign',
            render: (value, payment) => renderCurrencyAmount(value, payment),
            width: 150
        },
        {
            field: 'status',
            title: titles.status,
            titleTooltip: tooltips.status,
            render: value => renderStatusLabel(value, true),
            showTooltip: false,
            width: 180
        },
        {
            field: 'resultMessage',
            title: titles.resultMessage,
            titleTooltip: tooltips.resultMessage,
            render: (value, payment) => renderResultMessage(value, payment),
            width: 150
        },

        // TODO: Bring it back once the transaction types implemented
        // {
        //     field: 'transactionTypes',
        //     title: titles.transactionTypes,
        //     titleTooltip: tooltips.transactionTypes,
        //     render: (value, payment) => renderTransactionTypes(payment)
        // },
        // {
        //     field: 'installment_id',
        //     title: titles.installment_id,
        //     titleTooltip: tooltips.installment_id
        // },
        {
            field: 'reference',
            title: titles.reference,
            titleTooltip: tooltips.reference,
            width: 200
        },
        {
            field: 'paymentType',
            title: titles.paymentType,
            titleTooltip: tooltips.paymentType,
            render: (value) => value === CreditType.installment || value === CreditType.loan ? 'Credit' : value
        },
        {
            field: 'cardMask',
            title: titles.cardMask,
            titleTooltip: tooltips.cardMask,
            render: (value, payment) => renderCardMaskAndType(value, payment)
        },
        {
            field: 'payerName',
            title: titles.payerName,
            titleTooltip: tooltips.payerName
        },
        {
            field: 'payerPhone',
            title: titles.payerPhone,
            titleTooltip: tooltips.payerPhone
        },
        {
            field: 'accountId',
            title: titles.accountId,
            titleTooltip: tooltips.accountId
        },
        {
            field: 'payerEmail',
            title: titles.payerEmail,
            titleTooltip: tooltips.payerEmail
        },
        {
            field: 'description',
            title: titles.description,
            titleTooltip: tooltips.description
        },
        {
            field: 'postLink',
            title: titles.postLink,
            titleTooltip: tooltips.postLink
        },
        {
            field: 'amountBonus',
            title: titles.amountBonus,
            titleTooltip: tooltips.amountBonus
        },
        {
            field: 'payoutDate',
            title: titles.payoutDate,
            titleTooltip: tooltips.payoutDate,
            render: value => renderDate(value)
        },
        {
            field: 'payoutAmount',
            title: titles.payoutAmount,
            titleTooltip: tooltips.payoutAmount,
            className: 'rightAlign',
            render: (value, payment) => renderCurrencyAmount(value, payment)
        },
        {
            field: 'approvalCode',
            title: titles.approvalCode,
            titleTooltip: tooltips.approvalCode
        },
        {
            field: 'invoiceIdAlt',
            title: titles.invoiceIdAlt,
            titleTooltip: tooltips.invoiceIdAlt,
            width: 200
        },
        {
            field: 'purpose',
            title: titles.purpose,
            titleTooltip: tooltips.purpose,
            showTooltip: true
        },
        {
            field: 'user',
            title: titles.user,
            titleTooltip: tooltips.user
        },
        {
            field: 'user_ip',
            title: titles.user_ip,
            titleTooltip: tooltips.user_ip
        },
        {
            field: 'qrReference',
            title: titles.qrReference,
            titleTooltip: tooltips.qrReference
        },
        {
            field: 'chargeback',
            title: 'Chargeback',
            titleTooltip: 'Chargeback',
            render: value => <span>{value.amount > 0 ? res().chargeback.yes : res().chargeback.no}</span>
        }
    ])
})
const statusChargeback = (status: string) => {
    const title = res().paymentStatuses[status.toUpperCase()]
    return status ? (
        <>
            <StatusIcon
                title={title}
                color={
                    (statusStyles?.[status.toUpperCase()] || {})?.color
                }
            />
            <span className={styles.statusLabel}>
                {title}
            </span>
        </>
    ) : null
}
const detailedFields = computed(() => {
    const {
        groups: groupsTitles,
        short: titles,
        geolocation: geolocationTitle
    } = res().dataFields

    return strict<Array<DetailedFieldsGroup<EpayPayment>>>([
        {
            id: 'main',
            subgroups: [
                [
                    {
                        field: 'status',
                        title: titles.status,
                        render: value => {
                            const title = res().paymentStatuses[value]
                            return value ? (
                                <>
                                    <StatusIcon
                                        title={title}
                                        color={
                                            (statusStyles[value] || {}).color
                                        }
                                    />
                                    <span className={styles.statusLabel}>
                                        {title}
                                    </span>
                                </>
                            ) : null
                        }
                    },
                    {
                        field: 'resultMessage',
                        title: titles.resultMessage,
                        render: (value, payment) => renderResultMessage(value, payment)
                    },
                    {
                        field: 'createdDate',
                        title: titles.createdDate,
                        render: value => renderDate(value)
                    },
                    {
                        field: 'shop',
                        title: titles.shop
                    },
                    {
                        field: 'invoiceId',
                        title: titles.invoiceId
                    },
                    {
                        field: 'client',
                        title: titles.client
                    },
                    {
                        field: 'description',
                        title: titles.description
                    },
                    {
                        field: 'invoiceIdAlt',
                        title: titles.invoiceIdAlt
                    },
                    {
                        field: 'approvalCode',
                        title: titles.approvalCode
                    },
                    {
                        field: 'qrReference',
                        title: titles.qrReference
                    }
                ],
                [
                    {
                        field: 'orgAmount',
                        title: titles.orgAmount,
                        render: (value, payment) => (
                            <span>
                                {formatMoneySum(value)} {payment.currency}
                            </span>
                        )
                    },
                    {
                        field: 'amount',
                        title: titles.amount,
                        render: (value, payment) => {
                            const statusStyle = statusStyles[payment.status]
                            return renderCurrencyAmount(
                                value,
                                payment,
                                statusStyle
                                    ? {color: statusStyle.color}
                                    : null
                            )
                        }
                    },
                    {
                        field: 'fee',
                        title: titles.fee,
                        render: (value, payment) =>
                            value ? renderCurrencyAmount(value, payment) : null
                    },
                    {
                        field: 'amountBonus',
                        title: titles.amountBonus,
                        render: value => value ? formatMoneySum(value) : null
                    },
                    {
                        field: 'xlsTrxIdCharge',
                        title: titles.xlsTrxIdCharge
                    }
                ],

                [
                    {
                        type: 'collapse',
                        field: 'chargeback',
                        title: 'Chargeback',
                        render: (value, record) => {
                            return (
                                <CollapseChargeback chargeback={value}>
                                    {
                                        value &&
                                        <table>
                                            <tbody>
                                            <tr key={1} className={styles.trItem}>
                                                <td className={styles.label}>{res().chargeback.status}:</td>
                                                <td>{statusChargeback(value.status) || '-'}</td>
                                            </tr>
                                            <tr key={2} className={styles.trItem}>
                                                <td className={styles.label}>{res().chargeback.invoice_id}:</td>
                                                <td>{value.invoice_id || '-'}</td>
                                            </tr>
                                            <tr key={3} className={styles.trItem}>
                                                <td className={styles.label}>{res().chargeback.operation}:</td>
                                                <td>{value.operation || '-'}</td>
                                            </tr>
                                            <tr key={4} className={styles.trItem}>
                                                <td className={styles.label}>{res().chargeback.operation_id}:</td>
                                                <td>{value.operation_id || '-'}</td>
                                            </tr>
                                            <tr key={5} className={styles.trItem}>
                                                <td className={styles.label}>{res().chargeback.amount}:</td>
                                                <td style={{color: '#05B9A6'}}>{value.amount || '-'}</td>
                                            </tr>
                                            <tr key={6} className={styles.trItem}>
                                                <td className={styles.label}>{res().chargeback.ARN}:</td>
                                                <td>{value.metadata.ARN || '-'}</td>
                                            </tr>
                                            <tr key={7} className={styles.trItem}>
                                                <td className={styles.label}>{res().chargeback.authCode}:</td>
                                                <td>{value.metadata.AuthCode || '-'}</td>
                                            </tr>
                                            <tr key={8} className={styles.trItem}>
                                                <td className={styles.label}>{res().chargeback.cardBin}:</td>
                                                <td>{value.metadata.CardBin || '-'}</td>
                                            </tr>
                                            <tr key={9} className={styles.trItem}>
                                                <td className={styles.label}>{res().chargeback.transAmount}:</td>
                                                <td>{value.metadata.TransAmount || '-'}</td>
                                            </tr>
                                            <tr key={10} className={styles.trItem}>
                                                <td className={styles.label}>{res().chargeback.chargebackDate}:</td>
                                                <td> {renderDate(value.metadata.ChargebackDate) || '-'} </td>
                                            </tr>
                                            <tr key={11} className={styles.trItem}>
                                                <td className={styles.label}>{res().chargeback.RRN}:</td>
                                                <td>{value.metadata.RRN || '-'}</td>
                                            </tr>
                                            <tr key={12} className={styles.trItem}>
                                                <td className={styles.label}>{res().chargeback.chargebackReasonCode}:</td>
                                                <td>{value.metadata.ChargebackReasonCode || '-'}</td>
                                            </tr>
                                            <tr key={13} className={styles.trItem}>
                                                <td className={styles.label}>{res().chargeback.transDate}:</td>
                                                <td>{value.metadata.TransDate || '-'}</td>
                                            </tr>
                                            <tr key={14} className={styles.trItem}>
                                                <td className={styles.label}>{res().chargeback.transCurrency}:</td>
                                                <td>{value.metadata.TransCurrency || '-'}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    }
                                </CollapseChargeback>
                            )
                        }
                    }
                ],
                [
                    // {
                    //     field: 'installment_id',
                    //     title: titles.installment_id
                    // },
                    {

                        field: 'paymentType',
                        title: titles.paymentType,
                        render: (value) => value === CreditType.installment || value === CreditType.loan ? 'Credit' : value
                    },
                    {
                        field: 'creditType',
                        title: titles.creditType,
                        render: (_, record) => record.paymentType,
                        visibility: (_, record) => record.paymentType === CreditType.loan || record.paymentType === CreditType.installment
                    }
                ],

                [
                    {
                        field: 'authDate',
                        title: titles.authDate,
                        render: value => renderDate(value, true)
                    },
                    {
                        field: 'issuer',
                        title: titles.issuer
                    },
                    {
                        field: 'cardMask',
                        title: titles.cardMask,
                        render: (value, payment) =>
                            !value ? (
                                undefined
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}
                                >
                                    <PaymentCardIcon
                                        type={payment.cardType as CardType}
                                        className={styles.creditCardIcon}
                                    />
                                    <span className={styles.creditCardMask}>
                                        {value}
                                    </span>
                                </div>
                            )
                    },
                    {
                        field: 'secure3D',
                        title: titles.secure3D,
                        render: value =>
                            value
                                ? res().secure3DValues.true
                                : res().secure3DValues.false
                    },
                    {
                        field: 'merchant',
                        title: titles.merchant
                    }
                ],

                [
                    {
                        field: 'payerName',
                        title: titles.payerName
                    },
                    {
                        field: 'payerEmail',
                        title: titles.payerEmail
                    },
                    {
                        field: 'payerPhone',
                        title: titles.payerPhone
                    },
                    {
                        field: 'chargeDate',
                        title: titles.chargeDate,
                        render: value => renderDate(value),
                        visibility: (value, payment) =>
                            payment.status === PaymentStatus.charged
                    },
                    {
                        field: 'payoutDate',
                        title: titles.payoutDate,
                        render: value => renderDate(value),
                        visibility: (value, payment) =>
                            payment.status === PaymentStatus.refunded
                    },
                    {
                        field: 'payoutAmount',
                        title: titles.payoutAmount,
                        visibility: (value, payment) =>
                            payment.status === PaymentStatus.refunded
                    }
                ]
            ]
        },
        {
            id: 'customerData',
            title: groupsTitles.location,
            subgroups: [
                [
                    {
                        field: 'payerIp',
                        title: titles.payerIp
                    },
                    {
                        field: 'geolocationText',
                        title: geolocationTitle,
                        render: (value, payment) =>
                            renderGeolocationText(payment),
                        withoutBorder: true
                    },
                    {
                        field: 'geolocationMap',
                        title: null,
                        render: (value, payment) =>
                            renderGeolocationMap(payment),
                        wide: true,
                        withoutBorder: true
                    }
                ]
            ]
        },
        {
            id: 'paymentPage',
            title: groupsTitles.paymentPage,
            subgroups: [
                [
                    {
                        field: 'language',
                        title: titles.language
                    },
                    {
                        field: 'template',
                        title: titles.template
                    },
                    {
                        field: 'postLink',
                        title: titles.postLink
                    },
                    {
                        field: 'postLinkStatus',
                        title: titles.postLinkStatus,
                        render: value =>
                            value === undefined ? (
                                undefined
                            ) : value ? (
                                <span
                                    className={`${styles.postlinkStatus} ${styles.ok}`}
                                >
                                    {res().postLinkStatus.ok}
                                </span>
                            ) : (
                                <span
                                    className={`${styles.postlinkStatus} ${styles.failed}`}
                                >
                                    {res().postLinkStatus.fail}
                                </span>
                            )
                    },
                    {
                        field: 'client',
                        title: titles.client
                    }
                ]
            ]
        }
    ])
})

export function renderCurrencyAmountLabel(value, payment: EpayPayment) {
    const statusStyle = statusStyles[payment.status]
    return (
        <>
            {renderCurrencyAmount(
                value,
                payment,
                statusStyle ? {color: statusStyle.color} : null
            )}
            {payment.amountBonus !== 0 && (
                <>
                    <span className={styles.amountValue}>
                        {formatMoneySum(payment.amountBonus)}
                    </span>
                    <span className={styles.amountCurrency}>Go! Bonus</span>
                </>
            )}
        </>
    )
}

export function renderStatusLabel(value, renderText: boolean = false) {
    return value ? (
        <React.Fragment>
            <StatusIcon
                title={res().paymentStatuses[value]}
                color={(statusStyles[value] || {}).color}
            />
            &nbsp;
            {renderText && <span>{res().paymentStatuses[value]}</span>}
        </React.Fragment>
    ) : null
}

// determines how to render the card mask, card type, and weather the bonus tag is visible or not

function implyCardType(cardMask: string): JSX.Element {
    switch (cardMask[0]) {
        case '4':
            return (
                <PaymentCardIcon
                    type={'VISA' as CardType}
                    className={styles.creditCardIcon}
                />
            )
        case '5':
            return (
                <PaymentCardIcon
                    type="MasterCard"
                    className={styles.creditCardIcon}
                />
            )
        case '3':
            return (
                <PaymentCardIcon
                    type="AmericanExpress"
                    className={styles.creditCardIcon}
                />
            )
        default:
            return <></>
    }
}

function renderCardMaskAndType(value: any, payment: EpayPayment): JSX.Element {
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            {payment.amountBonus > 0 && (
                <div
                    style={{marginRight: '10px'}}
                    className={styles.iconDescriptionWrapper}
                >
                    <PaymentCardIcon
                        type="goBonus"
                        className={styles.creditCardIcon}
                    />
                    <span style={{paddingLeft: '5px'}}>Go! Bonus</span>
                </div>
            )}
            <div className={styles.iconDescriptionWrapper}>
                {/*TODO: Temporary solution change this whole logic once the solution is implemented on the back end  */}
                {payment.cardType !== '' ? (
                    <PaymentCardIcon
                        type={payment.cardType as CardType}
                        className={styles.creditCardIcon}
                    />
                ) : (
                    implyCardType(payment.cardMask)
                )}
                {value ? (
                    <span className={styles.creditCardMask}>{value}</span>
                ) : (
                    undefined
                )}
            </div>
        </div>
    )
}

// takes a transaction and determines which transaction type logos must be rendered
export function renderTransactionTypes(transaction: EpayPayment) {
    const applePayIcon = (
        <svg height={15} width={15}>
            <circle cx={7.5} cy={7.5} r={7} fill="black"/>
        </svg>
    )

    const samsungPayIcon = (
        <svg height={15} width={15}>
            <circle cx={7.5} cy={7.5} r={7} fill="blue"/>
        </svg>
    )

    const goBonusIcon = (
        <svg height={15} width={15}>
            <circle cx={7.5} cy={7.5} r={7} fill="green"/>
        </svg>
    )

    const invoiceLinkIcon = (
        <svg height={15} width={15}>
            <circle cx={7.5} cy={7.5} r={7} fill="yellow"/>
        </svg>
    )
    return (
        <>
            {transaction.amountBonus > 0 && goBonusIcon}
            {transaction.cardType === 'ApplePay' && applePayIcon}
            {transaction.cardType === 'SamsungPay' && samsungPayIcon}
        </>
    )
}

export { detailedFields }

export default fields
