import React from 'react'

import { defaultCurrency } from '~/constants/currencies'
import { formatMoneySum, formatSum } from '~/utils'

import res from './res'
import styles from './styles.css'

const SummaryChartTooltip = ({
    title,
    count,
    amount
}: {
    title: string
    count: number
    amount: number
}) => {
    return (
        <div className={styles.tooltip}>
            <div className={styles.title}>{title}</div>
            <div className={`${styles.field} ${styles.amount}`}>
                {res().paymentsAmount}:{' '}
                <span className={styles.value}>
                    {formatMoneySum(amount)}{' '}
                    <span className={styles.currency}>{defaultCurrency}</span>
                </span>
            </div>
            <div className={`${styles.field} ${styles.count}`}>
                {res().paymentsCount}:{' '}
                <span className={styles.value}>{formatSum(count)}</span>
            </div>
        </div>
    )
}

export default SummaryChartTooltip
