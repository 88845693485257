import res from './res'

res.add('ru', {
    title: 'Фильтр',
    cardsNumberStartsWith: 'Первые 6 цифр',
    cardsNumberEndsWith: 'Последние 4',
    endsWith: 'оканчивается на',
    applyButton: 'Применить',
    cancelButton: 'Отменить',
    clearButton: 'Очисть фильтр',
    periodTitle: 'Период',
    postLinkStatusOptions: {
        all: 'Все',
        ok: 'Успешно',
        fail: 'Ошибка'
    }
})
