import React from 'react'

const SvgTransfer = props => (
    <svg width="1em" height="1em" viewBox="0 0 1023 1023" {...props}>
        <path
            d="M374.014 811.43L322.2 862.884c-44.915 44.519-117.965 44.557-162.854 0-21.581-21.414-33.434-49.856-33.434-80.09 0-30.233 11.866-58.687 33.42-80.101l190.72-189.222c39.527-39.194 113.882-96.909 168.078-43.162 24.87 24.704 65.036 24.538 89.728-.32 24.704-24.857 24.55-65.036-.32-89.715-92.122-91.417-228.276-74.521-346.855 43.136L69.95 612.646C24.19 658.073-1 718.489-1 782.796s25.19 124.71 70.963 170.137c47.091 46.72 108.928 70.067 170.79 70.067 61.876 0 123.738-23.347 170.855-70.093l51.853-51.468c24.857-24.679 24.998-64.845.294-89.715-24.665-24.845-64.87-24.986-89.74-.295zM952.011 76.365c-98.944-98.176-237.286-103.5-328.883-12.62l-64.589 64.114c-24.87 24.691-25.024 64.845-.32 89.715 24.704 24.87 64.87 25.011 89.741.32l64.563-64.077c47.45-47.116 109.581-27.584 150.106 12.621 21.606 21.415 33.472 49.869 33.472 80.102 0 30.247-11.879 58.701-33.447 80.115L659.173 528.512c-93.043 92.313-136.691 48.985-155.315 30.502-24.87-24.691-65.024-24.537-89.728.32-24.704 24.858-24.55 65.037.32 89.715 42.713 42.394 91.468 63.411 142.579 63.411 62.579 0 128.678-31.513 191.526-93.913L952.037 416.69C997.784 371.264 1023 310.836 1023 246.53c0-64.282-25.216-124.71-70.989-170.163z"
            fillRule="nonzero"
        />
    </svg>
)

export default SvgTransfer
