import React from 'react'

import { observer } from 'mobx-react'

import {
    Button,
    Checkbox,
    Form,
    IconButton,
    Input,
    notification
} from '~/components'
import { CloseModalIcon } from '~/components/icon'

import layoutStyles from '~/layouts/main/styles.css'

import TeamStore from '../../team-store'

import { TeamRoles } from '~/api/team'
import { ModalContainerContext } from '~/components/modal/modal-context'
import BrowserStore from '~/services/browser/browser-state'
import res from './res'
import styles from './styles.css'

import { FormComponentProps } from 'antd/lib/form'

interface InvitationProps extends FormComponentProps {
    store: TeamStore
    modalContext?: ModalContainerContext
}

@observer
class Invitation extends React.Component<InvitationProps, {}> {
    public render() {
        const { store } = this.props
        const { getFieldDecorator } = this.props.form
        return (
            <>
                <Form onSubmit={this.handleSubmit}>
                    <div>
                        <div
                            className={` ${layoutStyles.contentHeader} ${BrowserStore.isMobile ? styles.mobileHeader : ''
                                }`}
                        >
                            {!BrowserStore.isMobile && (
                                <IconButton
                                    className={`${layoutStyles.closeButton}`}
                                    icon={CloseModalIcon}
                                    onClick={() =>
                                        store.toggleInvitationModal()
                                    }
                                />
                            )}
                            <div
                                className={`${BrowserStore.isMobile ? styles.title : ''
                                    } ${layoutStyles.title}`}
                            >
                                {!store.isEmployeeNew
                                    ? `${res().accessLevelChanges}`
                                    : res().title}
                            </div>

                            {store.isEmployeeNew && (
                                <div
                                    className={`${BrowserStore.isMobile
                                        ? styles.subtitle
                                        : ''
                                        } ${layoutStyles.subtitle}`}
                                >
                                    {res().subTitle}
                                </div>
                            )}
                        </div>
                        <div
                            className={`${BrowserStore.isMobile
                                ? styles.mobileContent
                                : ''
                                } ${layoutStyles.contentMain}`}
                        >
                            <div className={`${BrowserStore.isMobile ? styles.title : ''
                                } ${layoutStyles.title}`}>
                                {store.invitedEmployee.email}
                            </div>
                            {store.isEmployeeNew && (
                                <>
                                    <div
                                        className={`${styles.email} ${BrowserStore.isMobile
                                            ? styles.mobileEmail
                                            : ''
                                            }`}
                                    >
                                        <label htmlFor="email">
                                            {res().email}:
                                        </label>
                                        <Form.Item>
                                            {getFieldDecorator('email', {
                                                rules: [
                                                    {
                                                        type: 'email',
                                                        message: res()
                                                            .invalidEmail
                                                    },
                                                    {
                                                        required: true,
                                                        message: res()
                                                            .emailRequired
                                                    }
                                                ]
                                            })(<Input id="email" />)}
                                        </Form.Item>
                                    </div>
                                    <span className={styles.subFormTitle}>
                                        {res().roleTitle}:
                                    </span>
                                </>
                            )}
                            <div
                                className={`${store.invitedEmployee.email
                                    ? styles.withoutMargin
                                    : ''
                                    } ${styles.roleContainer}`}
                            >
                                <div className={styles.roleItem}>
                                    <Checkbox
                                        checked={
                                            store.invitedEmployeeRole ===
                                            TeamRoles.manager
                                        }
                                        onChange={() =>
                                            store.setInvitedEmployeeRole(
                                                TeamRoles.manager
                                            )
                                        }
                                    >
                                        <span
                                            className={styles.radioLabelTitle}
                                        >
                                            {res().manager}
                                        </span>
                                        <span className={styles.radioInfo}>
                                            {res().managerDescription}
                                        </span>
                                    </Checkbox>
                                </div>
                                <div className={styles.roleItem}>
                                    <Checkbox
                                        checked={
                                            store.invitedEmployeeRole ===
                                            TeamRoles.accountant
                                        }
                                        onChange={() =>
                                            store.setInvitedEmployeeRole(
                                                TeamRoles.accountant
                                            )
                                        }
                                    >
                                        <span
                                            className={styles.radioLabelTitle}
                                        >
                                            {res().accountant}
                                        </span>
                                        <span className={styles.radioInfo}>
                                            {res().accountantDescription}
                                        </span>
                                    </Checkbox>
                                </div>
                                <div className={styles.roleItem}>
                                    <Checkbox
                                        checked={
                                            store.invitedEmployeeRole ===
                                            TeamRoles.view
                                        }
                                        onChange={() =>
                                            store.setInvitedEmployeeRole(
                                                TeamRoles.view
                                            )
                                        }
                                    >
                                        <span
                                            className={styles.radioLabelTitle}
                                        >
                                            {res().view}
                                        </span>
                                        <span className={styles.radioInfo}>
                                            {res().viewDescription}
                                        </span>
                                    </Checkbox>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${BrowserStore.isMobile ? styles.mobileFooter : ''
                                } ${layoutStyles.contentFooter}`}
                        >
                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                                disabled={
                                    this.props.form.getFieldError('email') !==
                                    undefined
                                }
                            // onClick={() => store.applyEmployeeConfig()}
                            >
                                {res().applyButton}
                            </Button>
                            {!BrowserStore.isMobile && (
                                <Button
                                    size="large"
                                    // disabled={hasErrors(this.props.form.getFieldsError())}
                                    onClick={() =>
                                        store.toggleInvitationModal()
                                    }
                                >
                                    {res().cancelButton}
                                </Button>
                            )}
                        </div>
                    </div>
                </Form>
            </>
        )
    }

    private handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const newTeamMember = {
            email: this.props.form.getFieldValue('email'),
            role: this.props.store.invitedEmployeeRole
        }
        await this.props.store.applyEmployeeConfig(newTeamMember)
    }
}

const InvitationForm = Form.create({
    onFieldsChange(props: InvitationProps, changedFields: any) {
        const emailField = changedFields.email
        props.store.setInvitedEmployeeField('email', emailField.value)
    },
    mapPropsToFields(props) {
        return {
            email: Form.createFormField({
                value: props.store.invitedEmployee.email
            })
        }
    }
})(Invitation)
export default InvitationForm