import React, { Children, ReactNode } from 'react'

import { action, computed, observable, reaction } from 'mobx'

import { observer } from 'mobx-react'

import { Button, Drawer, IconButton, Modal } from '../index'

import layoutStyles from '~/layouts/main/styles.css'

import styles from '~/components/fields-config-form/styles.css'

import modalStyles from './styles.css'

import { CloseModalIcon } from '../icon'

import BrowserStore from '~/services/browser/browser-state'
import EditableFieldsList, { ItemField } from '../editable-fields-list'
import { ModalContainerContext } from './modal-context'

interface FieldsConfigFormProps {
    title?: string | React.ReactNode
    subTitle?: string | React.ReactNode
    visible: boolean
    closable: boolean
    onClose: (value?: any) => void
    className?: string
    footer?: string | ReactNode
    height?: number
    width?: number
    contentRef?: any
    desktopControlRenderer?: (props) => ReactNode
    mobileControlRenderer?: (props) => ReactNode
    headerControl?: ReactNode
    modalClass?: string
    drawerClass?: string
}
export const ModalStyles = modalStyles

export const ModalContext = React.createContext(new ModalContainerContext())

export interface ModalContainerInterface {
    setTitle: (title: string | ReactNode) => void
}

@observer
class ModalContainer extends React.Component<FieldsConfigFormProps, {}>
    implements ModalContainerInterface {
    constructor(props) {
        super(props)
    }

    public state = {
        contentHeight: 200
    }

    @observable
    public headerControl: string | ReactNode

    @observable
    public contextSource: ModalContainerContext = new ModalContainerContext(
        this
    )

    public contentRef: HTMLDivElement

    @action.bound
    public setTitle = (title: string | ReactNode) => {
        if (typeof title !== 'string') {
            this.headerControl = title
        } else {
            this.headerControl = this.createHeader(title)
        }
    }

    public createHeader = (
        title: string | ReactNode,
        subTitle?: string | ReactNode
    ): ReactNode => {
        return (
            <div className={`${layoutStyles.drawerTitle}`}>
                {title}
                {subTitle && (
                    <div className={`${layoutStyles.drawerSubTitle}`}>
                        {subTitle}
                    </div>
                )}
            </div>
        )
    }

    public render() {
        const {
            closable,
            visible,
            title,
            subTitle,
            onClose,
            height,
            width,
            contentRef,
            desktopControlRenderer,
            mobileControlRenderer,
            headerControl,
            modalClass,
            drawerClass
        } = this.props
        const drawerClassName = drawerClass
            ? `${drawerClass} ${layoutStyles.mobile}`
            : layoutStyles.mobile
        if (BrowserStore.isMobile) {
            if (mobileControlRenderer) {
                return mobileControlRenderer(this.props.children)
            }
            if (!visible) {
                return <React.Fragment></React.Fragment>
            }
            let headerControlElement: ReactNode = this.createHeader(title)

            if (headerControl || this.headerControl) {
                headerControlElement = this.headerControl
                    ? this.headerControl
                    : headerControl
            }

            return (
                <ModalContext.Provider value={this.contextSource}>
                    <Drawer
                        placement="bottom"
                        closable={false}
                        maskClosable={false}
                        onClose={onClose}
                        visible={visible}
                        title={headerControlElement}
                        height={this.state.contentHeight}
                        className={drawerClassName}
                    >
                        <div>{this.props.children}</div>
                    </Drawer>
                </ModalContext.Provider>
            )
        } else {
            if (desktopControlRenderer) {
                return desktopControlRenderer(this.props.children)
            }
            const modalClassName = modalClass ? modalClass : styles.container
            return (
                <Modal
                    className={`${layoutStyles.modal} ${modalClassName}`}
                    visible={visible}
                    closable={false}
                    footer={null}
                    title={undefined}
                >
                    {title}
                    {subTitle && (
                        <div className={`${layoutStyles.drawerSubTitle}`}>
                            {subTitle}
                        </div>
                    )}
                    {this.props.children}
                </Modal>
            )
        }
    }
}

export default ModalContainer
