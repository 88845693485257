import React from 'react'

const SvgEdit = props => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" {...props}>
        <path
            d="M628.19 66.281c81.26-88.375 213.408-88.375 294.662 0C962.28 108.831 984 165.566 984 226.01c0 60.446-21.72 117.18-61.148 159.948l-540.256 585.89c-5.026 5.671-12.066 8.728-19.105 8.728-7.038 0-14.082-3.27-19.11-8.728l-217.63-236.1-27.958 224.316 143.213-20.945c14.884-2.182 28.559 9.161 30.57 25.309 2.013 16.148-8.445 30.988-23.33 33.17l-178.613 26.182c-.6.11-1.2.166-1.8.19-.606.031-1.21.031-1.82.031-7.04 0-14.078-3.269-19.105-8.728-5.834-6.325-8.854-15.494-7.644-24.654l40.025-321.644c.806-6.329 3.418-12.219 7.644-16.802L628.19 66.282zm101.17 170.42l-90.713-98.411L145.66 673.12l90.714 98.413L729.36 236.702zM363.695 909.439l-88.703-96.224L767.98 278.38l88.699 96.23-492.984 534.828zm313.773-812.39l217.026 235.447c22.727-29.894 35.196-66.99 35.196-106.486 0-44.733-15.883-86.847-45.053-118.268-29.162-31.423-67.778-48.88-109.013-48.88-36.208 0-70.601 13.53-98.156 38.187z"
            fillRule="evenodd"
        />
    </svg>
)

export default SvgEdit
