import res from './res'

res.add('en', {
    operators: {
        less: '<',
        lessOrEqual: '≤',
        equals: '',
        greaterOrEqual: '≥',
        greater: '>',
        startsWith: 'starts with',
        endsWith: 'ends with',
        contains: 'contains'
    }
})
