import React from 'react'
import res from './res'

res.add('kk', {
    title: 'EPay-терминалдар өніміне өтінім',
    contracts: 'Келісімшарттар',
    contract: 'Келісімшарт',
    sortBy: 'Бойынша сұрыптау:',
    sortOptions: {
        date: 'Соңғы шығарылған күні',
        name: 'Атауы',
        contract: 'Келісімшартпен',
        type: 'Типі',
        asc: {
            date: 'басында ескі',
            name: 'өсуі бойынша',
            contract: 'өсуі бойынша',
            type: 'өсуі бойынша'
        },
        desc: {
            date: 'басында жаңалары',
            name: 'кему бойынша',
            contract: 'кему бойынша',
            type: 'кему бойынша'
        }
    },
    dateRanges: {
        yesterday: 'кеше',
        priorWeek: 'өткен апта',
        priorMonth: 'өткен ай',
        customPeriod: 'көрсетілген кезең'
    },
    statusType: {
        forming: 'Өтінімді қалыптастыру',
        created: 'Өтінім беру', // Создана,
        processing: 'Өңдеу', // - В обработке,
        approved: 'Бекіту', // - Утверждена,
        returned: 'Пысықтауға қайтарылды', // - Возвращена на доработку
        canceled: 'Жойылды', // - От
        retry: 'Қайта жіберу'
    },
    statusSteps: {
        forming: 'Өтінімді қалыптастыру',
        created: 'Өтінім берілді', // Создана,
        processing: 'Өңдеу', // - В обработке,
        approved: 'Бекіту', // - Утверждена,
        returned: 'Пысықтауға қайтарылды', // - Возвращена на доработку
        canceled: 'Жойылды', // - От
        retry: 'Қайта жіберу'
    },
    statusAction: {
        forming: 'Қайта жіберу',
        created: 'Жасалды', // Создана,
        processing: 'Кабылдауда', // - В обработке,
        approved: 'Мақұлданған', // - Утверждена,
        returned: 'Пысықтауға қайтарылды', // - Возвращена на доработку
        canceled: 'Жойылды', // - От
        retry: 'Қайта жіберу'
    },
    errors: {
        productsLoadingError: 'Көшірмелерді жүктеу кезіндегі қате',
        productCreatingError: 'Үзінді жасау қатесі',
        productUpdatingError: 'Үзінді көшірмені жаңарту қатесі',
        productHistoryLoadingError: 'Жүктеу қатесі тарих үзінді'
    },
    createInquiry: 'Өнімге өтінім',
    inquiryFields: {
        companyInfo: 'Кәсіпорын туралы ақпарат',
        traderTypeLabel: 'Кәсіпорын түрі',
        iinBin: 'Кәсіпорынның БСН',
        traderType: 'Кәсіпорын түрі',
        traderName: 'Кәсіпорынның заңды атауы',
        traderNameLt: 'Кәсіпорынның заңды атауы (лат.)',
        mainActivity: 'Негізгі қызмет түрі',
        createdDate: 'Кәсіпорынның құрылған күні',
        serviceBank: 'Қызмет көрсету Банкі',
        servicePeriodOfBank: 'Банктегі қызмет көрсету мерзімі',
        hasCredits: 'Кредиттердің болуы',
        registerCompanyAddress: 'Кәсіпорынның заңды мекен-жайы',
        registerCompanyAddressZip: 'Заңды мекенжай индексі',
        realCompanyAddress: 'Кәсіпорынның нақты мекен-жайы',
        realCompanyAddressZip: 'Нақты мекенжай индексі',
        realPhone: 'Кәсіпорын телефоны',
        realFax: 'Кәсіпорын факсы',
        contactPhone:
            'Компания клиенттері үшін байланыс телефоны (банк сайты үшін)',
        premisesOwnership: 'Үй-жайдың тиесілігі',
        lessorName: 'Жалға берушінің (лизинг берушінің) атауы)',
        leaseTerm: 'Мерзімі жалдау (лизинг)',
        hasEncumbranceOnProperty: 'Мүлікке ауыртпалық бар',
        registrationAddress: {
            type: 'Түрі',
            countryCode: 'Ел коды',
            zip: 'Пошта индексі -',
            regionCode: 'Аймақ коды',
            districtCode: 'Аудан коды',
            cityCode: 'Қала коды',
            townCode: 'Әкімшілік орталықтың коды',
            microdistrictCode: 'Шағын аудан коды',
            street: 'Көше',
            buildingNumber: 'Үй нөмірі',
            apartmentNumber: 'Пәтер нөмірі',
            fullAddress: 'Толық мекенжайы'
        },
        ceoShortInfoLabel: 'Басшының деректері',
        personLegalAddressLabel: 'Тіркелген мекенжайы',
        personRealAddressLabel: 'Тұратын мекенжайы',
        chiefAccountantShortInfoLabel: 'Бухгалтердің деректері',
        cheifAccountantDoesNotExists: 'Бухгалтер қарастырылмаған'
    },
    tooltips: {
        enabledStatus:
            'Автоматты көшірмелерге арналған Конфигурация жасалды және іске қосылды',
        disabledStatus:
            'Автоматты көшірмелерге арналған Конфигурация жасалды, бірақ іске қосылмаған',
        noEmails: 'Алушылар көрсетілмеген',
        downloadproduct: 'Үзінді көшірмені жүктеу',
        productCreating: 'Үзінді жасау',
        reload: 'Деректерді жаңарту',
        productSettings: 'Көшірмелерді баптау'
    },
    pageInfo: (info: { from: number; to: number; total: number }) => (
        <>
            {info.from}-{info.to}
        </>
    ),
    nextButton: 'Қалыптастыру',
    previousButton: 'Қалыптастыру',
    automaticproducts: 'Автоматты үзінділер',
    productHistory: 'Қалыптастырылған үзінді көшірмелер',
    productsFor: 'Үзінді',
    active: 'Белсендірілген',
    inactive: 'Белсендірілмеген',
    product: 'Үзінді көшірме'
})
