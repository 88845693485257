import res from './res'
import React from 'react'

res.add('kk', {
    skipBtn: 'Оқуды өткізіп жіберу',
    nextSectionBtn: 'Келесі бөлім',
    prevSectionBtn: 'Алдыңғы бөлім',
    nextBtn: 'Жалғастыру',
    modals: {
        cancelBtn: 'Бас тарту',
        okBtn: 'Өткізіп жіберу',
        skipAll: {
            title: 'Назар аударыңыз!',
            description: 'Сіз оқуды өткізіп жібергіңіз келетініне сенімдісіз бе?'
        },
        skipSection: {
            title: 'Назар аударыңыз!',
            description: 'Ағымдағы бөлімде оқуды өткізіп жіберіп, келесіге өтуді қалайтыныңызға сенімдісіз бе?'
        },
        congratulation: {
            title: 'Құттықтаймыз!',
            description: 'Сіз Жеке кабинет бойынша оқуды аяқтадыңыз. Егер сұрақтар туындаса немесе сіз қайтадан оқығыңыз келсе, FAQ бөліміне өтіңіз',
            okBtn: 'Түсінікті'
        },
        transactionsWarning: {
            title: 'Оқыту',
            description: '"ePay төлемдер" бөлім бойынша егжей-тегжейлі оқыту бірінші сәтті төлем жасалғаннан кейін қолжетімді болады',
            okBtn: 'Түсінікті'
        },
        welcome: {
            title: 'ePay-ге қош келдіңіз!',
            description: 'Біз жеке кабинеттің бөлімдеріне арнап оқу дайындадық. Бастаймыз ба?',
            cancelBtn: 'Бас тарту',
            okBtn: 'Бастау'
        },
        wantToContinue: {
            title: 'Оқыту',
            description: (nameOfSection) => `Сіз “${nameOfSection}” бөлімінде тоқтадыңыз. Оқуды жалғастырамыз ба?`,
            cancelBtn: 'Бас тарту',
            okBtn: 'Бастау'
        },
        firstSuccessTransaction: {
            title: 'Оқыту',
            description: 'Бірінші сәтті транзакция жасалынды. Бөлім бойынша оқуды өтесіз бе?',
            cancelBtn: 'Бас тарту',
            okBtn: 'Бастау'
        }
    },
    sectionsDesktop: {
        generalInformation: {
            title: 'Жалпы ақпарат',
            steps: [
                <><span>Бүйірлік меню</span>. Оның көмегімен <span>жеке кабинетте</span> барлық навигация жүзеге асырылады</>,
                <><span>Жұмыс аймағы</span>. <span>Жеке кабинеттің</span> барлық негізгі қызметі осында өтеді</>,
                <>Хабарландыру белгішесінің жанында <span>Бейін</span> орналасқан. Белгішені басу арқылы Сіз Бейінді басқаруға немесе жүйеден шыға аласыз</>
            ]
        },
        dashboard: {
            title: 'Статистика',
            steps: [
                <><span>Статистика</span> бөлімі, ол Сізді кабинетке кірген сайын қарсы алады</>,
                <><span>Статистикада</span> Сіз бақылай аласыз: сәтті / сәтсіз транзакцияларды, Эмитент банктерді, арналарды</>
            ]
        },
        transactions: {
            title: 'ePay төлемдер',
            steps: [
                <><span>Транзакция</span> бөлімі. Онда 4 кіші бөлім бар: ePay терминалдары, ePay аударымдары, AFT толықтырулары және төлемдер</>,
                <><span>ePay төлемдері</span> бөлімінде ePay төлем жүйесі арқылы жасалған барлық транзакциялар көрсетіледі. Транзакция туралы ақпарат кесте түрінде көрсетіледі. Кесте бағандарын жылжытуға, сондай-ақ қосуға/жоюға болады</>,
                <>Төлемдерді күні немесе мәртебесі бойынша сұрыптауға болады</>,
                <>«Кестені орнату», cіз көргіңіз келген кесте бағандарын таңдап және олардың ретін өзгерте аласыз</>,
                <>Статистиканың көмегімен сіз таңдалған кезеңдегі төлемдер туралы ақпаратты бақылай аласыз. Кезең «Төлемдер» сүзгісі арқылы таңдалады</>,
                <>Іздеу жолағы – Сізге қажетті кілт сөз транзакциясын табуға мүмкіндік береді. Сүзгінің көмегімен Сіз бірнеше өрістер бойынша кеңейтілген іздеуді жүзеге асыра аласыз</>,
                <>Төлем туралы толық ақпарат төлемді басқан кезде пайда болады</>,
                <>Төлем туралы толық ақпарат төлемді басқан кезде пайда болады</>,
                <>Растау, болдырмау немесе қайтару үшін төлемдерді белгілеңіз</>,
                <>Транзакцияны «құсбелгімен»  белгілегеннен  кейін қажетті әрекетті таңдаңыз</>
            ]
        },
        statements: {
            title: 'Үзінді-көшірмелер',
            steps: [
                <><span>Үзінді көшірмелер</span> бөлімі, қажет болған жағдайда Е-коммерсанттың төлемдері бойынша үзінді көшірмені алу, қандай да бір салыстырып тексеру жүргізу мақсатында осы бөлімді пайдаланыңыз</>,
                <>Мұнда Сіздің дүкендеріңіз (келісімшарттар) көрсетіледі. Мәлімдемелерді басқару үшін қажетті терминалды таңдаңыз</>,
                <>Мәлімдеме жасау үшін «Қалыптастыру» түймесін басыңыз</>,
                <>Төлем күнінен «бастап» кезеңін және «дейін» кезеңін, сондай-ақ үзінді көшірме форматын белгілеңіз. Қалыптастыру түймесін басыңыз. Үзінді көшірме CSV және XLSX (Excel) форматында жүктеледі</>,
                <>Сондай-ақ, Сіз күнделікті, апталық немесе ай сайынғы автоматты пошта хабарламаларын орната аласыз</>,
                <>Қажетті тарату кезеңін таңдағаннан кейін қажетті ақпарат бар терезе пайда болады, оны толтырып, «Қолдану» батырмасын басу керек</>
            ]
        },
        paymentLinks: {
            title: 'Төлем сілтемелері',
            steps: [
                <><span>Төлемге сілтеме</span> бөлімі. Оның көмегімен төлем шоты жасалады және бірегей сілтеме жасалады, ол арқылы сатып алушы төлем формасына ауысады, банк картасының деректерін енгізеді немесе Apple Pay және Samsung Pay көмегімен бір рет басу арқылы төлейді</>,
                <>Жұмыс кеңістігі Сіздің төлем сілтемелеріңіздің тарихын көрсетеді.</>,
                <>Төлем мәліметтерін жасау үшін «Сілтемені қалыптастыру» батырмасын басыңыз</>,
                <>Төлем мәліметтерін толтырып, «Қолдану» батырмасын басу керек болатын терезе пайда болады. Осыдан кейін төлем туралы мәліметтермен сілтеме жасалады. Жасалған сілтемені көшіруге және клиентке жіберуге болады (мессенджер немесе электрондық пошта арқылы). Сілтеме бойынша өткеннен кейін клиентке карточкалық деректерді енгізу және төлем жасау қажет болатын төлем беті ашылады</>
            ]
        },
        credit: {
            title: 'Бөліп төлеу және кредит беру',
            steps: [
                <><span>Банк өнімдері</span> бөлімінде <span>бөліп төлеу және кредит беру</span> деген бір кіші бөлім бар</>,
                <><span>Бөліп төлеу және кредит беру</span> бөлімі арқылы бөліп төлеу мен кредит беруді белсендіруге қолжетімді</>,
                <>Мұнда кредит алуға өтінімдердің тарихы көрсетіледі. Сіз олардың мәртебесін бақылай аласыз: «өтініш берілді», «өңдеуде», «қабылданды» және «қабылданбады». Егер өтінім «қабылданбады» мәртебесін алса, Сізге өтінім жолағында бас тарту себебі көрсетіледі. Сондай-ақ, өтінім өрісінде бөліп төлеу/кредит талаптарын көруге болады</>,
                <>Дүкенге клиенттерден төлемді бөліп төлеу немесе кредитпен қабылдау мүмкіндігін қосу үшін өтінімді толтыру қажет. Мұны «Жаңа өтінім» батырмасы арқылы жасауға болады</>,
                <>Жолдарды толтыру, өтінім жіберу және Банктің растауын күту қажет. Өтінім мақұлданғаннан кейін, дүкеннен тауарларды сатып алғанда, EPAY төлем бетінде клиентке бөліп төлеу/ кредитке алуды таңдау мүмкіндігі қолжетімді болады</>
            ]
        },
        configureEpayTemplate: {
            title: 'ePay үлгісі',
            steps: [
                <><span>Баптаулар</span> бөлімі 3 бөлімге бөлінеді: ePay үлгісі, терминал баптаулары және қызметкерлер</>,
                <><span>ePay шаблоны</span> бөлімінде төлем парағының шаблонын дүкенге бейімдеуге болады</>,
                <>Дербес реттеу үшін келесі тармақтарға қолжетімді:<br />
                    —Дүкен атауы<br />
                    —Логотип<br />
                    —Тіл<br />
                    —Email<br />
                    —Телефон<br />
                    —Түс схемасы (егер дайындалған түс схемаларын  қаламасаңыз, Сіз оны «Түс схемасы» тармағын таңдау арқылы өзіңіз реттей аласыз</>
            ]
        },
        terminalSettings: {
            title: 'Терминалдарды баптау',
            steps: [
                <><span>Терминал баптаулары</span> бөлімінде Сізге терминалды басқарудың кейбір мүмкіндіктеріне қолжетімді</>,
                <>«Белсенді» тармағындағы қосқыш терминалдың өзін қосуға\өшіруге жауап береді</>,
                <>«Картаны сақтау» тармағындағы қосқыш сіздің клиенттеріңізге келесі операциялар тікелей жүргізілуі үшін төлем жасалған картаны
                сақтауға мүмкіндік беретін функцияны қосады\өшіреді (төлем нысанын толтырмай). Сақталған карталар бойынша төлемдерді орындау
                    үшін арнайы интеграция қажет, «Сақталған карта бойынша төлем» құжаттама бөлімін қараңыз</>,
                <>«1-қадамдық схема» тармағындағы қосқыш. Егер ол белсендірілген күйде болса, онда төлем жасалған жағдайда клиенттің картасынан
                қаражат банктің соманы бастапқы ұстап қалуынсыз бірден есептен шығарылады. Егер бұл қосқыш өшірілген болса, онда соманы бастапқыда
                    банк ұстап қалады және төлемді «Төлемдер\ePay-терминалдар» бөлімінде дербес жүргізген кезде есептен шығарылады</>
            ]
        },
        team: {
            title: 'Қызметкерлер',
            steps: [
                <><span>Қызметкерлер</span> бөлімінде қызметкерлерді басқаруға және қызметкерлерге қолжетімділіктің қажетті деңгейін беруге болады</>,
                <>Мұнда сіздің барлық қызметкерлеріңіз және оларға берілген рөлдер көрсетіледі. Сіз қызметкердің рөлін өңдей аласыз немесе оны жоя аласыз</>,
                <>Жаңа қызметкерді қосу үшін «Қызметкерді шақыру» батырмасын басып, нысанды толтыру қажет</>,
                <>Нысанды толтырғаннан кейін «Қолдану» батырмасын басыңыз және көрсетілген электрондық поштаға парольді орнату бетіне сілтеме автоматты түрде жіберіледі</>
            ]
        }
    },
    sectionsMobile: {
        generalInformation: [
            'Бүйірлік меню көмегімен жеке кабинетте барлық навигация жүзеге асырылады'
        ],
        dashboard: [
            'Статистикадан: Сәтті/Сәтсіз транзакцияларды, Эмитент банктерді, Арналарды бақылай аласыз'
        ],
        transactions: [
            'Статистиканың көмегімен сіз таңдалған кезеңдегі төлемдер туралы ақпаратты бақылай аласыз',
            'Төлемдерді күні немесе мәртебесі бойынша сұрыптауға болады',
            'Сүзгі көмегімен сіз бірнеше өрістер бойынша кеңейтілген іздеуді жүзеге асыра аласыз'
        ],
        statements: [
            'Мұнда Сіздің дүкендеріңіз (келісімшарттар) көрсетіледі. Мәлімдемелерді басқару үшін қажетті терминалды таңдаңыз',
            'Сіз күнделікті, апталық немесе ай сайынғы автоматты пошта хабарламаларын орната аласыз'
        ],
        paymentLinks: [
            'Бірегей сілтеме жасаңыз, ол арқылы сатып алушы сілтемені бір рет басып Apple Pay немесе Samsung Pay көмегімен төлейді'
        ],
        credit: [
            'Дүкенге клиенттерден төлемдерді бөліп төлеу немесе несие беру мүмкіндігін қосыңыз'
        ],
        configureEpayTemplate: [
            'Төлем беті үлгісін өз дүкеніңізге бейімдеңіз'
        ],
        terminalSettings: [
            '«Белсенді» тармағындағы қосқыш терминалдың өзін қосуға\\өшіруге жауап береді',
            '«Картаны сақтау» тармағындағы қосқыш сіздің клиенттеріңізге картаны сақтауға мүмкіндік беретін функцияны қосады\\өшіреді',
            'Егер «1-қадамдық схема» тармағындағы қосқыш белсендірілген күйде болса, онда төлем жасалған жағдайда клиенттің картасынан қаражат банктің соманы бастапқы ұстап қалуынсыз бірден есептен шығарылады. Егер бұл қосқыш өшірілген болса, онда соманы бастапқыда банк ұстап қалады және төлемді «Төлемдер\\ePay-терминалдар» бөлімінде дербес жүргізген кезде есептен шығарылады'
        ],
        team: [
            'Мұнда сіздің барлық қызметкерлеріңіз және оларға берілген рөлдер көрсетіледі. Сіз қызметкердің рөлін өзгерте аласыз немесе оны жоя аласыз'
        ]
    }
})
