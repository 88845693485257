import React from 'react'

import { Icon } from 'antd'
import { IconProps } from 'antd/lib/icon'
import AppStyles from '~/app.css'

import ArrowSvg from '~/assets/icons/color/arrow'
import ChangeSvg from '~/assets/icons/color/change'
import LogoIconInverted from '~/assets/icons/color/logo-green-dark'
import NoDataSvg from '~/assets/icons/color/no-data'
import UnderConstructionSvg from '~/assets/icons/color/under-construction'
import BanksProductsSvg from '~/assets/icons/mono/banks-products'
import BellSvg from '~/assets/icons/mono/bell'
import BinSvg from '~/assets/icons/mono/bin'
import BottomSvg from '~/assets/icons/mono/bottom'
import CancelSvg from '~/assets/icons/mono/cancel'
import CancelCircleSvg from '~/assets/icons/mono/cancel-circle'
import CheckedSvg from '~/assets/icons/mono/checked'
import CloseSvg from '~/assets/icons/mono/close'
import CloseModalSvg from '~/assets/icons/mono/close-modal'
import CompanySvg from '~/assets/icons/mono/company'
import ContractSvg from '~/assets/icons/mono/contract'
import DoneSvg from '~/assets/icons/mono/done'
import DownloadSvg from '~/assets/icons/mono/download'
import EditSvg from '~/assets/icons/mono/edit'
import FaqSvg from '~/assets/icons/mono/faq'
import FilterSvg from '~/assets/icons/mono/filter'
import FirstPageSvg from '~/assets/icons/mono/first-page'
import InfoSvg from '~/assets/icons/mono/info'
import LanguageSvg from '~/assets/icons/mono/language'
import LastPageSvg from '~/assets/icons/mono/last-page'
import LogoutDoorSvg from '~/assets/icons/mono/logout-door'
import MailSvg from '~/assets/icons/mono/mail'
import NewsSvg from '~/assets/icons/mono/news'
import MenuSvg from '~/assets/icons/mono/menu'
import NextPageSvg from '~/assets/icons/mono/next-page'
import ParamsSvg from '~/assets/icons/mono/params'
import PaymentLinksSvg from '~/assets/icons/mono/payment-links'
import TransactionsSvg from '~/assets/icons/mono/transactions'
// p2p @Dos
import PaymentsSvg from '~/assets/icons/mono/payments'
// p2p @Dos
import SvgTransfer from '~/assets/icons/mono/transfer'

// aft @Dos
import SvgPaymentAft from '~/assets/icons/mono/payment-aft'
import PrevPageSvg from '~/assets/icons/mono/prev-page'
import QrCodesSvg from '~/assets/icons/mono/qr-codes'
import RefreshSvg from '~/assets/icons/mono/refresh'
import ReloadSvg from '~/assets/icons/mono/reload'
import RoundLeftSvg from '~/assets/icons/mono/round-left'
import RoundRightSvg from '~/assets/icons/mono/round-right'
import SettingsSvg from '~/assets/icons/mono/settings'
import SortByAscSvg from '~/assets/icons/mono/sort-by-asc'
import SortByDescSvg from '~/assets/icons/mono/sort-by-desc'
import DocumentationSvg from '~/assets/icons/mono/statements'
import StatementsSvg from '~/assets/icons/mono/statements'
import StatisticsSvg from '~/assets/icons/mono/statistics'
import SvgOrders from '~/assets/icons/mono/orders'
import PaymentSettingsSvg from '~/assets/icons/mono/credit-card'

export const BanksProductsIcon = (props: IconProps) => (
    <Icon component={BanksProductsSvg} {...props} />
)
export const BarsIcon = (props: IconProps) => <Icon type="bars" {...props} />
export const BellIcon = (props: IconProps) => (
    <Icon component={BellSvg} {...props} />
)
export const BottomIcon = (props: IconProps) => (
    <Icon component={BottomSvg} {...props} />
)
export const CalendarIcon = (props: IconProps) => (
    <Icon type="calendar" {...props} />
)
export const ChartIcon = (props: IconProps) => (
    <Icon type="line-chart" {...props} />
)
export const CancelIcon = (props: IconProps) => (
    <Icon component={CancelSvg} {...props} />
)
export const CaretDownIcon = (props: IconProps) => (
    <Icon type="caret-down" {...props} />
)
export const ChangeIcon = (props: IconProps) => (
    <Icon component={ChangeSvg} {...props} />
)
export const CheckCircleIcon = (props: IconProps) => (
    <Icon type="check-circle" {...props} />
)
export const CheckedIcon = (props: IconProps) => (
    <Icon component={CheckedSvg} {...props} />
)
export const CloseCircleIcon = (props: IconProps) => (
    <Icon type="close-circle" {...props} />
)
export const CloseIcon = (props: IconProps) => (
    <Icon component={CloseSvg} {...props} />
)
export const CloseModalIcon = (props: IconProps) => (
    <Icon component={CloseModalSvg} {...props} />
)
export const ClockIcon = (props: IconProps) => (
    <Icon type="clock-circle" {...props} />
)
export const ContractIcon = (props: IconProps) => (
    <Icon component={ContractSvg} {...props} />
)
export const CompanyIcon = (props: IconProps) => (
    <Icon component={CompanySvg} {...props} />
)
export const DocumentationIcon = (props: IconProps) => (
    <Icon component={DocumentationSvg} {...props} />
)
export const DownloadIcon = (props: IconProps) => (
    <Icon component={DownloadSvg} {...props} />
)
export const DocumentIcon = (props: IconProps) => (
    <Icon type="profile" {...props} />
)
export const DoneIcon = (props: IconProps) => (
    <Icon component={DoneSvg} {...props} />
)
export const DownIcon = (props: IconProps) => <Icon type="down" {...props} />
export const EditIcon = (props: IconProps) => (
    <Icon component={EditSvg} {...props} />
)
export const EyeIcon = (props: IconProps) => <Icon type="eye-fill" {...props} />
export const FaqIcon = (props: IconProps) => (
    <Icon component={FaqSvg} {...props} />
)
export const FirstPageIcon = (props: IconProps) => (
    // <Icon component={FirstPageSvg} {...props} />
    <Icon type="double-left" theme="outlined" {...props} />
)
export const FilterIcon = (props: IconProps) => (
    <Icon component={FilterSvg} {...props} />
)
export const InfoIcon = (props: IconProps) => (
    <Icon component={InfoSvg} {...props} />
)
export const InfoCircleIcon = (props: IconProps) => (
    <Icon type="info-circle" {...props} />
)
export const LastPageIcon = (props: IconProps) => (
    // <Icon component={LastPageSvg} {...props} />
    <Icon type="double-right" theme="outlined" {...props} />
)
export const LeftCircleIcon = (props: IconProps) => (
    <Icon type="left-circle" {...props} />
)
export const LoadingIcon = (props: IconProps) => (
    <Icon type="loading" {...props} />
)
export const RefreshIcon = (props: IconProps) => (
    <Icon component={RefreshSvg} {...props} />
)
export const LogoInvertedIcon = (props: IconProps) => (
    <Icon component={LogoIconInverted} {...props} />
)
export const LogoutDoorIcon = (props: IconProps) => (
    <Icon component={LogoutDoorSvg} {...props} />
)
export const ArrowLeftIcon = (props: IconProps) => (
    <Icon type="arrow-left" {...props} />
)
export const ArrowTopIcon = (props: IconProps) => <Icon type="up" {...props} />
export const CheckIcon = (props: IconProps) => <Icon type="check" {...props} />
export const LanguageIcon = (props: IconProps) => (
    <Icon component={LanguageSvg} {...props} />
)
export const LogoutIcon = (props: IconProps) => (
    <Icon type="logout" {...props} />
)
export const MarketingIcon = (props: IconProps) => (
    <Icon component={NewsSvg} {...props} />
)
export const MenuFoldIcon = (props: IconProps) => (
    <Icon type="menu-fold" {...props} />
)
export const MenuIcon = (props: IconProps) => (
    <Icon component={MenuSvg} {...props} />
)
export const MenuUnfoldIcon = (props: IconProps) => (
    <Icon type="menu-unfold" {...props} />
)
export const NextPageIcon = (props: IconProps) => (
    // <Icon component={NextPageSvg} {...props} />
    <Icon type="right" theme="outlined" {...props} />
)
export const ParamsIcon = (props: IconProps) => (
    <Icon component={ParamsSvg} {...props} />
)
export const PaymentLinksIcon = (props: IconProps) => (
    <Icon component={PaymentLinksSvg} {...props} />
)
export const PaymentsIcon = (props: IconProps) => (
    <Icon component={TransactionsSvg} {...props} />
)
// p2p @Dos
export const TransferIcon = (props: IconProps) => (
    <Icon component={SvgTransfer} {...props} />
)
// aft @Dos
export const PaymentAftIcon = (props: IconProps) => (
    <Icon component={SvgPaymentAft} {...props} />
)

export const PlusIcon = (props: IconProps) => <Icon type="plus" {...props} />
export const PieIcon = (props: IconProps) => (
    <Icon type="pie-chart" {...props} />
)
export const PrevPageIcon = (props: IconProps) => (
    // <Icon component={PrevPageSvg} {...props} />
    <Icon type="left" theme="outlined" {...props} />
)
export const QrCodesIcon = (props: IconProps) => (
    <Icon component={QrCodesSvg} {...props} />
)
export const ReloadIcon = (props: IconProps) => (
    <Icon component={ReloadSvg} {...props} />
)
export const RoundLeftIcon = (props: IconProps) => (
    <Icon component={RoundLeftSvg} {...props} />
)
export const RoundRightIcon = (props: IconProps) => (
    <Icon component={RoundRightSvg} {...props} />
)
export const SaveIcon = (props: IconProps) => <Icon type="save" {...props} />
export const SearchIcon = (props: IconProps) => (
    <Icon type="search" {...props} />
)
export const SortAscIcon = (props: IconProps) => (
    <Icon type="sort-ascending" {...props} />
)
export const SortDescIcon = (props: IconProps) => (
    <Icon type="sort-descending" {...props} />
)
export const SettingsIcon = (props: IconProps) => (
    <Icon component={SettingsSvg} {...props} />
)
export const StatementsIcon = (props: IconProps) => (
    <Icon component={StatementsSvg} {...props} />
)
export const SortByAscIcon = (props: IconProps) => (
    <Icon component={SortByAscSvg} {...props} />
)
export const SortByDescIcon = (props: IconProps) => (
    <Icon component={SortByDescSvg} {...props} />
)
export const SortByDescAIcon = (props: IconProps) => (
    <Icon type="sort-ascending" {...props} />
)
export const DashboardIcon = (props: IconProps) => (
    <Icon component={StatisticsSvg} {...props} />
)
export const UpIcon = (props: IconProps) => <Icon type="up" {...props} />
export const UserIcon = (props: IconProps) => <Icon type="user" {...props} />
export const FormIcon = (props: IconProps) => <Icon type="form" {...props} />
export const UnderConstuctionIcon = (props: IconProps) => (
    <Icon component={UnderConstructionSvg} {...props} />
)
export const NoDataIcon = (props: IconProps) => (
    <Icon component={NoDataSvg} {...props} />
)
export const ShadowDot = (props: IconProps) => (
    <div {...props} className={`${AppStyles.shadowDot} ${props.className}`} />
)
export const ArrowIcon = (props: IconProps) => (
    <Icon component={ArrowSvg} {...props} />
)
export const MailIcon = (props: IconProps) => (
    <Icon component={MailSvg} {...props} />
)
export const CancelCircleIcon = (props: IconProps) => (
    <Icon component={CancelCircleSvg} {...props} />
)
export const BinIcon = (props: IconProps) => (
    <Icon component={BinSvg} {...props} />
)
export const OrdersIcon = (props: IconProps) => (
    <Icon component={SvgOrders} {...props} />
)

export const PaymentSettingsIcon = (props: IconProps) => (
    <Icon component={PaymentSettingsSvg} {...props} />
)
