import res from './res'

res.add('ru', {
    title: 'Настройка оплаты',
    sortBy: 'Сортировать по',
    cols: {
        id: 'id',
        name: 'Наименование',
        number: '№',
        price: 'Стоимость',
        visibility: 'Отображение',
        active_date: 'Дата активации',
        set_date: 'Цена установлена'
    },
    successUpdate: 'Данные успешно обновлены',
    errorLoadData: 'Ошибка при получении истории'
})
