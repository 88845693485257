import { post } from '~/utils/ajax'

import {
    CashByCodePaymentResponse,
    CashByCodeRequest
} from '~/api'

export * from './contracts'

export const getCashByCodePayments = (request: CashByCodeRequest) =>
    post<CashByCodePaymentResponse>('cash-by-code/operations', request).then(response => {
        return response
    })

export const cancelCashByCodePayment = (paymentId: string) =>
    post<void>(`operation/${paymentId}/cancel`)

export const chargeCashByCodePayment = (paymentId: string, amount?: number) =>
    post<void>(
        `operation/${paymentId}/charge${amount ? `?amount=${amount}` : ''}`
    )

export const refundCashByCodePayment = (paymentId: string, amount?: number, purpose?: string) =>
    post<void>(
        `operation/${paymentId}/refund`,
        amount === undefined ? undefined : { amount, purpose }
    )

export const refundCreditCashByCodePayment = (creditOrderId, creditAmount) =>
    post<void>('credit-cancel', { orderId: creditOrderId, amount: creditAmount })
