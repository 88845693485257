import React from 'react'

import res from './res'

res.add('ru', {
    title: 'Мой профиль',
    labels: {
        language: 'Язык системы',
        email: 'Email',
        password: 'Пароль',
        role: 'Роль'
    },
    buttons: {
        changePassword: 'Изменить пароль'
    },
    lastUsage: {
        header: 'История подключений',
        table: {
            headers: {
                geo: 'Локация',
                recordDate: 'Время',
                userSystem: 'ОС',
                ip: 'IP адрес',
                status: 'Состояние'
            }
        }
    },
    changePasswordInfo: 'Изменение пароля',
    changePasswordSuccess: email => (
        <>
            Письмо с инструкциями было отправлено на <b>{email}</b>
        </>
    )
})
