import locale from '~/utils/locale'

export default locale.create<{
    title: string,
    buttons: {
        download: string
    },
    labels: {
        bin: string
        bin_placeholder: string
        check: string
        city: string
        contact_faces: string
        full_name: string
        emails: string
        continue: string
        back: string
        finish: string
        description: string
        url: string
        shop_name: string
        establishment_date: string
        shop_description: string
        name: string
        surname: string
        middlename: string
        phone: string
    },
    subtitles: {
        service_bank: string
        tariff: string
    },
    messages: {
        required: string
        notFoundTitle: string
        notFoundDescription: string
        warningRequires: string
        validateFile: string
        validateFileSize: string
    },
    documents: {
        stateRegister: string
        statute: string
        decision: string
        identityCardAccountant: string
        identityCardLeader: string
        requisites: string
        leaderSignatureToo: string
        leaderSignatureIp: string
        constituentDocuments: string
        powerOfAttorney: string
        licensePaymentServices: string
    },
    tooltips: {
        stateRegister: string
        statute: string
        decision: string
        identityCardAccountant: string
        identityCardLeader: string
        requisites: string
        leaderSignature: string
        constituentDocuments: string
        powerOfAttorney: string
        licensePaymentServices: string
    }
}>()
