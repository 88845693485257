import React from 'react'

const SvgCompany = props => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" {...props}>
        <g fillRule="evenodd">
            <path d="M512 1024C229.23 1024 0 794.77 0 512S229.23 0 512 0s512 229.23 512 512-229.23 512-512 512zm0-62c248.528 0 450-201.472 450-450S760.528 62 512 62 62 263.472 62 512s201.472 450 450 450z" />
            <g fillRule="nonzero">
                <path d="M364.947 744.16c5.24 0 9.497-4.317 9.497-9.646V568.195c0-5.303-4.257-9.644-9.497-9.644h-36.229c-5.273 0-9.496 4.34-9.496 9.644v166.319c0 5.33 4.222 9.646 9.496 9.646h36.23zM475.441 744.16c5.192 0 9.448-4.317 9.448-9.646V568.195c0-5.303-4.256-9.644-9.448-9.644h-36.36c-5.188 0-9.414 4.34-9.414 9.644v166.319c0 5.33 4.226 9.646 9.413 9.646h36.361zM696.314 744.16c5.187 0 9.464-4.317 9.464-9.646V568.195c0-5.303-4.279-9.644-9.464-9.644h-36.331c-5.205 0-9.427 4.34-9.427 9.644v166.319c0 5.33 4.223 9.646 9.427 9.646h36.331zM748.366 459.635l-198.59-167.67c-20.525-17.286-54.048-17.286-74.574 0l-198.55 167.67c-20.554 17.368-15.515 31.49 11.122 31.49H737.26c26.611 0 31.622-14.122 11.106-31.49zm-235.875-9.302c-20.826 0-37.705-17.257-37.705-38.492 0-21.238 16.88-38.433 37.705-38.433 20.877 0 37.707 17.195 37.707 38.433 0 21.235-16.83 38.492-37.707 38.492zM580.344 744.16c5.242 0 9.467-4.317 9.467-9.646V568.195c0-5.303-4.225-9.644-9.467-9.644h-36.277c-5.253 0-9.478 4.34-9.478 9.644v166.319c0 5.33 4.225 9.646 9.478 9.646h36.277z" />
            </g>
        </g>
    </svg>
)

export default SvgCompany
