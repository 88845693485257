import res from './res'

res.add('kk', {
    title: 'Қызметкерлер',
    role: {
        admin: 'Әкімші',
        manager: 'Менеджер',
        accountant: 'Есепші',
        view: 'Қараушы'
    },
    status: {
        active: 'Белсенді',
        waitingForRegistration: 'Тіркеуді күтеді'
    },
    cols: {
        email: 'Email',
        role: 'Рөлі',
        status: 'Мәртебесі',
        lastConnections: 'Соңғы әрекет',
        control: 'Басқармасы',
        controlsTooltip: {
            edit: 'Редакциялау',
            delete: 'Өшіру'
        }
    },
    invite: 'Қызметкерді шақыру',
    okText: 'Иә',
    cancelText: 'Жоқ',
    deleteConfirmContent:
        'Қызметкерді ажыратқаннан кейін порталға қосылу мүмкін емес.',
    deleteConfirmTitle: 'Сіз қызметкерді өшіргіңіз келетініне сенімдісіз бе?',
    deleteNotificationDescription:
        'Бұл қызметкер енді осы порталды қолдана алмайды',
    deleteNotificationTitle: 'Дайын',
    emailExists: '',
    emailExistsDescription: '',
    newEmployeeCreatedNotificationDescription: (email: string) =>
        `${email} электрондық поштаға шақыру сәтті жіберілді`,
    newEmployeeCreatedNotificationTitle: 'Жіберілді!',
    employeeRoleUpdatedDescription: 'Қызметкердін рөлі өзгертілді',
    employeeRoleUpdatedTitle: 'Дайын!'
})
