import React from 'react'

const SvgStatements = props => (
    <svg width="1em" height="1em" viewBox="0 0 20 21" {...props}>
        <path d="M9.37 17.932c0 .395-.32.716-.715.716H1a1 1 0 01-1-1V1a1 1 0 011-1h8.926a1 1 0 01.682.269l4.46 4.16a1 1 0 01.317.732v6.261a.77.77 0 11-1.54 0V5.753a1 1 0 00-.318-.731l-3.56-3.318a1 1 0 00-.681-.268H2.535a1 1 0 00-1 1v13.781a1 1 0 001 1h6.12c.395 0 .715.32.715.715z" />
        <path d="M14.615 5.022c0 .395-.32.715-.715.715h-3.67a1 1 0 01-1-1v-3.25a.77.77 0 111.54 0v1.82a1 1 0 001 1h2.13c.395 0 .715.32.715.715zM7.482 21l.815-3.799 7.768-7.24c.871-.813 2.39-.813 3.26 0 .439.403.675.942.675 1.519 0 .576-.242 1.116-.675 1.52l-7.768 7.24-4.075.76zm2.231-3.097l-.27 1.263 1.355-.251 7.443-6.938a.685.685 0 00.224-.508.674.674 0 00-.224-.503.822.822 0 00-1.085 0l-7.443 6.937zM12.12 7.173H3.304v1.436h8.814V7.173zM12.12 10.044H3.304v1.436h8.814v-1.436z" />
        <path d="M12.12 12.91H3.304v1.436h8.814V12.91z" />
    </svg>
)

export default SvgStatements
