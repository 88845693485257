import { observer } from 'mobx-react'
import React from 'react'

import { Button, Collapse, Input, Spin } from '~/components'
const TextArea = Input.TextArea
const Panel = Collapse.Panel

import { InfoCircleIcon } from '~/components/icon'
import Page from '~/layouts/main/page'
import res from './res'
import styles from './styles.css'

import actions from '~/actions'

import { ApiPostInfo } from '~/api/faq/contracts'
import BrowserStore from '~/services/browser/browser-state'
import locale from '~/utils/locale'

import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import DocumentationStore from './documentation-store'

import { PDFObject } from 'react-pdfobject'

export default observer(({ store }: { store: DocumentationStore }) => {
    const { data, node } = store
    if (!data) return <div></div>
    const additionalPageClass = BrowserStore.isMobile ? styles.mobile : ''
    const isSubStructureNode =
        node && node.targetNode && node.targetNode.nodeType === 'structure'
            ? true
            : false
    const isDocNode =
        !isSubStructureNode && node && node.targetNode ? true : false
    const stuctureNode = isSubStructureNode ? node.targetNode : data
    const showStructureMenu =
        isSubStructureNode || stuctureNode.nodeId === 'rootNode'

    return (
        <>
            /* @Dos 05.10.20 Added Redirect to Epay2 */
            {(window.location.href = 'https://developer.homebank.kz/epay')}
            {/*<Page title={data.nodeName[locale.lang] ? data.nodeName[locale.lang] : 'Documentation'} pageClassName={additionalPageClass}>*/}
            {/*    <div className={styles.pageContainer}>*/}
            {/*        {!isSubStructureNode && !isDocNode && showStructureMenu && stuctureNode.childNodes.map(childNode => {*/}
            {/*            return <div>*/}
            {/*                <div className={styles.groupTitle}>{childNode.nodeName[locale.lang]}</div>*/}
            {/*                <div className={styles.documentsBlock}>*/}
            {/*                    {childNode.childNodes && childNode.childNodes.map(childSubNode =>*/}
            {/*                        <div className={styles.documentLabel}>*/}
            {/*                            <a href={'/documentation/' + childSubNode.nodeId}>*/}
            {/*                                {childSubNode.nodeName[locale.lang]}*/}
            {/*                            </a>*/}
            {/*                        </div>*/}
            {/*                    )}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        })}*/}
            {/*        {node && node.targetNode && node.parents && <React.Fragment>*/}
            {/*            <a href={'/documentation'}>*/}
            {/*                {data.nodeName[locale.lang]}*/}
            {/*            </a>&nbsp;>&nbsp;*/}
            {/*                {node.parents.map(parentNode =>*/}
            {/*                <React.Fragment>*/}
            {/*                    {parentNode.nodeId !== 'rootNode' &&*/}
            {/*                        <React.Fragment>*/}
            {/*                            <a href={'/documentation/' + parentNode.nodeId} >*/}
            {/*                                {parentNode.nodeName[locale.lang]}*/}
            {/*                            </a> > {node.targetNode.nodeName[locale.lang]}*/}
            {/*                        </React.Fragment>*/}
            {/*                    }*/}
            {/*                </React.Fragment>*/}
            {/*            )*/}
            {/*            }*/}
            {/*        </React.Fragment>}*/}
            {/*        {!isDocNode && isSubStructureNode &&*/}
            {/*            <div>*/}
            {/*                <div className={styles.groupTitle}>{stuctureNode.nodeName[locale.lang]}</div>*/}
            {/*                <div className={styles.documentsBlock}>*/}
            {/*                    {stuctureNode.childNodes && stuctureNode.childNodes.map(childSubNode =>*/}
            {/*                        <div className={styles.documentLabel}>*/}
            {/*                            <a href={'/documentation/' + childSubNode.nodeId}>*/}
            {/*                                {childSubNode.nodeName[locale.lang]}*/}
            {/*                            </a>*/}
            {/*                        </div>*/}
            {/*                    )}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        }*/}
            {/*        {node && node.targetNode && node.targetNode.nodeType === 'swaggerDocument' &&*/}
            {/*            <SwaggerUI url={'/static/' + node.targetNode.documentPath} />*/}
            {/*        }*/}
            {/*        {node && node.targetNode && node.targetNode.nodeType === 'pdfDocument' &&*/}
            {/*            <PDFObject url={'/static/' + node.targetNode.documentPath} height={'600px'}></PDFObject>*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</Page>*/}
        </>
    )
})
