import locale from '~/utils/locale'

import { EpayPayment, PaymentStatus } from '~/api'

export default locale.create<{
    statistics: string
    collapseSummary: (range: string | (() => string)) => string
    sorting: string
    filter: string
}>()
