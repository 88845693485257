import { ColorSchemeName } from '~/api/epay-template'


export interface TwoColors {
    color1: string,
    color2: string,
    gradient: string
}

export interface ColorScheme {
    bg: TwoColors
    card_bg: TwoColors
    buttons: string
    text: string
    card_text: string
    scheme_name: ColorSchemeName
}

export function oneColorGradient(color: string) {
    color = color || '#FFFFFF'
    // return `linear-gradient(0deg, ${color} 0%, ${color} 100%)`
    return `${color}`
}
export function stdBgGradient(color1: string, color2: string) {
    color1 = color1 || '#FFFFFF'
    color2 = color2 || '#FFFFFF'
    return `linear-gradient(10deg, ${color1} 3%, ${color2} 100%)`
}
export function stdCardBgGradient(color1: string, color2: string) {
    color1 = color1 || '#FFFFFF'
    color2 = color2 || '#FFFFFF'
    return `linear-gradient(214deg, ${color1} 10.5%, ${color2} 87%)`
}

export const whiteGray: ColorScheme = {
    bg: {
        color1: '#FFFFFF',
        color2: '#FFFFFF',
        gradient: oneColorGradient('#FFFFFF')
    },
    buttons: '#00a8ff',
    card_bg: {
        color1: '#f6f7f8',
        color2: '#f6f7f8',
        gradient: oneColorGradient('#f6f7f8')
    },
    card_text: '#03034B',
    text: '#7E8194',
    scheme_name: 'white-gray'
}

export const blackGray: ColorScheme = {
    bg: {
        color1: '#252527',
        color2: '#252527',
        gradient: oneColorGradient('#252527')
    },
    buttons: '#00a8ff',
    card_bg: {
        color1: '#51535D',
        color2: '#717486',
        gradient: 'linear-gradient(67.6deg, #51535D 3.35%, #717486 108.62%)' // stdCardBgGradient('#51535D', '#717486')
    },
    card_text: '#c1c2c7',
    text: '#cfcfcf',
    scheme_name: 'black-gray'
}

export const blackWhite: ColorScheme = {
    bg: {
        color1: '#252527',
        color2: '#252527',
        gradient: oneColorGradient('#252527')
    },
    buttons: '#00a8ff',
    card_bg: {
        color1: '#f6f7f8',
        color2: '#f6f7f8',
        gradient: oneColorGradient('#f6f7f8')
    },
    card_text: '#212261',
    text: '#cfcfcf',
    scheme_name: 'black-white'
}

export const whiteBlack: ColorScheme = {
    bg: {
        color1: '#FFFFFF',
        color2: '#FFFFFF',
        gradient: oneColorGradient('#FFFFFF')
    },
    buttons: '#00a8ff',
    card_bg: {
        color1: '#393945',
        color2: '#252527',
        gradient: stdCardBgGradient('#393945', '#252527')
    },
    card_text: '#FFFFFF',
    text: '#252527',
    scheme_name: 'white-black'
}

export const whiteBlue: ColorScheme = {
    bg: {
        color1: '#FFFFFF',
        color2: '#FFFFFF',
        gradient: oneColorGradient('#FFFFFF')
    },
    buttons: '#00a8ff',
    card_bg: {
        color1: '#00A8FF',
        color2: '#148DFF',
        gradient: stdCardBgGradient('#00A8FF', '#148DFF')
    },
    card_text: '#FFFFFF',
    text: '#807f7f',
    scheme_name: 'white-blue'
}

export const whiteGreen: ColorScheme = {
    bg: {
        color1: '#FFFFFF',
        color2: '#FFFFFF',
        gradient: oneColorGradient('#FFFFFF')
    },
    buttons: '#02a993',
    card_bg: {
        color1: '#05B983',
        color2: '#009AA4',
        gradient: stdCardBgGradient('#05B983', '#009AA4')
    },
    card_text: '#ffffff',
    text: '#9c9ead',
    scheme_name: 'white-green'
}

export const whiteGold: ColorScheme = {
    bg: {
        color1: '#FFFFFF',
        color2: '#FFFFFF',
        gradient: oneColorGradient('#FFFFFF')
    },
    buttons: '#C1A146FF',
    card_bg: {
        color1: '#E6CD69',
        color2: '#A8832E',
        gradient: stdCardBgGradient('#E6CD69', '#A8832E')
    },
    card_text: '#ffffff',
    text: '#9c9ead',
    scheme_name: 'white-gold'
}

export const blueWhite: ColorScheme = {
    bg: {
        color1: '#148DFF',
        color2: '#148DFF',
        gradient: oneColorGradient('#148DFF')
    },
    buttons: '#FFFFFF',
    card_bg: {
        color1: '#FFFFFF',
        color2: '#FFFFFF',
        gradient: oneColorGradient('#FFFFFF')
    },
    card_text: '#212261',
    text: '#b9e8f1',
    scheme_name: 'blue-white'
}
export const greenWhite: ColorScheme = {
    bg: {
        color1: '#05B983',
        color2: '#009BA5',
        gradient: stdBgGradient('#05B983', '#009BA5')
    },
    buttons: '#FFFFFF',
    card_bg: {
        color1: '#FFFFFF',
        color2: '#FFFFFF',
        gradient: oneColorGradient('#FFFFFF')
    },
    card_text: '#03034b',
    text: '#b8e8f1',
    scheme_name: 'green-white'
}
export const goldWhite: ColorScheme = {
    bg: {
        color1: '#E4CB66',
        color2: '#A4812A',
        gradient: stdBgGradient('#E4CB66', '#A4812A')
    },
    buttons: '#6d5402',
    card_bg: {
        color1: '#FFFFFF',
        color2: '#FFFFFF',
        gradient: oneColorGradient('#FFFFFF')
    },
    card_text: '#212261',
    text: '#ffffff',
    scheme_name: 'gold-white'
}
export const redWhite: ColorScheme = {
    bg: {
        color1: '#9A151A',
        color2: '#680E34',
        gradient: stdBgGradient('#9A151A', '#680E34')
    },
    buttons: '#ffffff',
    card_bg: {
        color1: '#FFFFFF',
        color2: '#FFFFFF',
        gradient: oneColorGradient('#FFFFFF')
    },
    card_text: '#212261',
    text: '#b8e8f1',
    scheme_name: 'red-white'
}

export const custom: ColorScheme = {
    bg: {
        color1: '#000000',
        color2: '#000000',
        gradient: oneColorGradient('#000000')
    },
    buttons: '#ffffff',
    card_bg: {
        color1: '#FFFFFF',
        color2: '#FFFFFF',
        gradient: oneColorGradient('#FFFFFF')
    },
    card_text: '#212261',
    text: '#b8e8f1',
    scheme_name: 'custom'
}

type NameMapType = {
    [k in ColorSchemeName]: ColorScheme
}
export const NameMap: NameMapType = {
    'black-gray': blackGray,
    'black-white': blackWhite,
    'blue-white': blueWhite,
    'white-black': whiteBlack,
    'white-blue': whiteBlue,
    'white-gold': whiteGold,
    'white-gray': whiteGray,
    'white-green': whiteGreen,
    'gold-white': goldWhite,
    'red-white': redWhite,
    'green-white': greenWhite,
    'custom': custom
}