import React from 'react'
import { PaymentLinkStatus } from '~/api/payment-links'
import res from './res'

res.add('kk', {
    paymentLinksTitle: 'Төлемге сілтемелер',
    generateButtonTitle: 'Сілтемені қалыптастыру',
    sortBy: 'Бойынша сұрыптау',
    ranges: {
        today: 'бүгін',
        yesterday: 'кеше',
        thisWeek: 'ағымдағы апта',
        priorWeek: 'өткен апта',
        thisMonth: 'ағымдағы ай',
        priorMonth: 'өткен ай',
        thisQuarter: 'ағымдағы тоқсан',
        customPeriod: 'көрсетілген кезең'
    },
    linkStatuses: {
        [PaymentLinkStatus.any]: 'Кез келген',
        [PaymentLinkStatus.active]: 'Белсенді',
        [PaymentLinkStatus.annulled]: 'Жойылды',
        [PaymentLinkStatus.expired]: 'мерзімі өткен',
        [PaymentLinkStatus.charged]: 'Есептен шығарылды'
    },
    dateFilterLabel: 'Кезеңде',
    savedText: 'Ура. Сілтеме алмасу буферіне сақталды'
})
