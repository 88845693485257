import React, { CSSProperties } from 'react'

import { computed } from 'mobx'

import moment from 'moment'

import { PaymentCardIcon } from '~/components'

import { Column } from '~/components/grid'

import CardType from '~/constants/card-systems'

import { formatMoneySum } from '~/utils'
import strict from '~/utils/strict'

import styles from '../styles.css'

import StatusIcon from '~/components/status-icon'

import { DetailedFieldsGroup } from '../../types'

import statusStyles from '../constants/payment-statuses'

import res from '../res'
import locale from '~/utils/locale'
import { CashByCodePayment } from '~/api/cash-by-code'

const renderCurrencyAmount = (
    value: number,
    payment: CashByCodePayment,
    style?: CSSProperties
) => {
    return (
        <span style={style}>
            <span className={styles.amountValue}>{formatMoneySum(value)}</span>
            <span className={styles.amountCurrency}>{payment.currency}</span>
        </span>
    )
}

const renderDate = (value, hideHours?: boolean) =>
    value ? (
        <span className={styles.date}>
            <span>{moment(value).format('DD.MM.YYYY')}</span>
            {!hideHours && (
                <span className="date-hours">
                    {locale.lang === 'en' ? moment(value).locale('en').format('hh:mm a') : moment(value).format('HH:mm ')}
                </span>
            )}
        </span>
    ) : null

const renderResultMessage = (value, payment: CashByCodePayment) => {
    const { returnCode } = payment
    return res().errorCodes[returnCode] ? res().errorCodes[returnCode] : null
}

const fields = computed(() => {
    const { short: titles, full: tooltips } = res().dataFields
    return strict<Array<Column<CashByCodePayment>>>([
        {
            field: 'currency',
            title: titles.currency,
            titleTooltip: tooltips.currency,
            width: 170
        },
        {
            field: 'invoiceId',
            title: titles.invoiceId,
            titleTooltip: tooltips.invoiceId,
            render: value => <span className={styles.orderNumber}>{value}</span>,
            width: 180
        },
        {
            field: 'createdAt',
            title: titles.createdAt,
            titleTooltip: tooltips.createdAt,
            render: value => renderDate(value),
            width: 200
        },
        {
            field: 'destinationPhone',
            title: titles.destinationPhone,
            titleTooltip: tooltips.destinationPhone,
            width: 150
        },
        {
            field: 'amount',
            title: titles.amount,
            titleTooltip: tooltips.amount,
            className: 'rightAlign',
            // render: renderCurrencyAmountLabel,
            width: 150
        },
        {
            field: 'sourcePhone',
            title: titles.sourcePhone,
            titleTooltip: tooltips.sourcePhone,
            className: 'rightAlign',
            width: 150
        },
        {
            field: 'stan',
            title: titles.stan,
            titleTooltip: tooltips.stan,
            width: 180
        },
        {
            field: 'receiverPhone',
            title: titles.receiverPhone,
            titleTooltip: tooltips.receiverPhone,
            width: 150
        },
        {
            field: 'reference',
            title: titles.reference,
            titleTooltip: tooltips.reference,
            width: 200
        },
        {
            field: 'merchant',
            title: titles.merchant,
            titleTooltip: tooltips.merchant
        },
        {
            field: 'cardMask',
            title: titles.cardMask,
            titleTooltip: tooltips.cardMask,
            render: (value, payment) => renderCardMaskAndType(value, payment)
        },
        {
            field: 'payerIP',
            title: titles.payerIP,
            titleTooltip: tooltips.payerIP
        },
        {
            field: 'cashCodeSMS',
            title: titles.cashCodeSMS,
            titleTooltip: tooltips.cashCodeSMS
        },
        {
            field: 'isPostLink',
            title: titles.isPostLink,
            titleTooltip: tooltips.isPostLink
        },
        {
            field: 'postLink',
            title: titles.postLink,
            titleTooltip: tooltips.postLink
        },
        {
            field: 'description',
            title: titles.description,
            titleTooltip: tooltips.description
        },
        {
            field: 'status',
            title: titles.status,
            titleTooltip: tooltips.status,
            render: value => renderStatusLabel(value, true),
            showTooltip: false,
            width: 180
        },
        {
            field: 'contractNumberDevice',
            title: titles.contractNumberDevice,
            titleTooltip: tooltips.contractNumberDevice
        },
        {
            field: 'authDate',
            title: titles.authDate,
            titleTooltip: tooltips.authDate,
            render: value => renderDate(value)
        },
        {
            field: 'postingDate',
            title: titles.postingDate,
            titleTooltip: tooltips.postingDate,
            render: value => renderDate(value)
        },
        {
            field: 'failurePostLink',
            title: titles.failurePostLink,
            titleTooltip: tooltips.failurePostLink
        },
        {
            field: 'returnCode',
            title: titles.returnCode,
            titleTooltip: tooltips.returnCode,
            width: 200
        },
        {
            field: 'postingStatus',
            title: titles.postingStatus,
            titleTooltip: tooltips.postingStatus,
            showTooltip: true
        }
    ])
})

const detailedFields = computed(() => {
    const {
        short: titles
    } = res().dataFields

    return strict<Array<DetailedFieldsGroup<CashByCodePayment>>>([
        {
            id: 'main',
            subgroups: [
                [
                    {
                        field: 'amount',
                        title: titles.amount
                    },
                    {
                        field: 'status',
                        title: titles.status,
                        render: value => renderStatusLabel(value, true)
                    },
                    {
                        field: 'currency',
                        title: titles.currency
                    },
                    {
                        field: 'destinationPhone',
                        title: titles.destinationPhone
                    },
                    {
                        field: 'sourcePhone',
                        title: titles.sourcePhone
                    },
                    {
                        field: 'invoiceId',
                        title: titles.invoiceId
                    },
                    {
                        field: 'cardMask',
                        title: titles.cardMask
                    },
                    {
                        field: 'reference',
                        title: titles.reference
                    },
                    {
                        field: 'receiverPhone',
                        title: titles.receiverPhone
                    }
                ],
                [
                    {
                        field: 'merchant',
                        title: titles.merchant
                    },
                    {
                        field: 'cashCodeSMS',
                        title: titles.cashCodeSMS
                    },
                    {
                        field: 'stan',
                        title: titles.stan
                    },
                    {
                        field: 'createdAt',
                        title: titles.createdAt,
                        render: value => renderDate(value, true)
                    }
                ],

                [
                    {
                        field: 'authDate',
                        title: titles.authDate,
                        render: value => renderDate(value, true)
                    },
                    {
                        field: 'postingDate',
                        title: titles.postingDate,
                        render: value => renderDate(value, true)
                    }
                ],

                [
                    {
                        field: 'contractNumberDevice',
                        title: titles.contractNumberDevice
                    },
                    {
                        field: 'terminalID',
                        title: titles.terminalID
                    },
                    {
                        field: 'payerIP',
                        title: titles.payerIP
                    },
                    {
                        field: 'description',
                        title: titles.description
                    }
                ],

                [
                    {
                        field: 'isPostLink',
                        title: titles.isPostLink
                    },
                    {
                        field: 'postLink',
                        title: titles.postLink
                    },
                    {
                        field: 'failurePostLink',
                        title: titles.failurePostLink
                    },
                    {
                        field: 'returnCode',
                        title: titles.returnCode
                    },
                    {
                        field: 'postingStatus',
                        title: titles.postingStatus
                    }
                ]
            ]
        }
    ])
})

export function renderCurrencyAmountLabel(value, payment: CashByCodePayment) {
    const statusStyle = statusStyles[payment.status]
    return (
        <>
            {renderCurrencyAmount(
                value,
                payment,
                statusStyle ? { color: statusStyle.color } : null
            )}
        </>
    )
}
export function renderStatusLabel(value, renderText: boolean = false) {
    return value ? (
        <React.Fragment>
            <StatusIcon
                title={res().paymentStatuses[value]}
                color={(statusStyles[value] || {}).color}
            />
            &nbsp;
            {renderText && <span>{res().paymentStatuses[value]}</span>}
        </React.Fragment>
    ) : null
}

// determines how to render the card mask, card type, and weather the bonus tag is visible or not

function implyCardType(cardMask: string): JSX.Element {
    switch (cardMask[0]) {
        case '4':
            return (
                <PaymentCardIcon
                    type={'VISA' as CardType}
                    className={styles.creditCardIcon}
                />
            )
        case '5':
            return (
                <PaymentCardIcon
                    type="MasterCard"
                    className={styles.creditCardIcon}
                />
            )
        case '3':
            return (
                <PaymentCardIcon
                    type="AmericanExpress"
                    className={styles.creditCardIcon}
                />
            )
        default:
            return <></>
    }
}

function renderCardMaskAndType(value: any, payment: CashByCodePayment): JSX.Element {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={styles.iconDescriptionWrapper}>
                {/*TODO: Temporary solution change this whole logic once the solution is implemented on the back end  */}
                {implyCardType(payment.cardMask)}
                {value ? (
                    <span className={styles.creditCardMask}>{value}</span>
                ) : (
                    undefined
                )}
            </div>
        </div>
    )
}

export { detailedFields }

export default fields
