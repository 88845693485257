import React from 'react'
import { observer } from 'mobx-react'
import { Form, Input, Radio } from 'antd'
import { Button, InputItem, Select } from '~/components'

const { Option } = Select
const { TextArea } = Input
import res from '~/pages/orders/create-order/res/res'
import styles from '~/pages/orders/create-order/styles.css'
import CreateOrderStore from '~/pages/orders/create-order/create-order-store'
import { FormComponentProps } from 'antd/lib/form'
import { logEventFirebase } from '~/pages/orders/create-order/services/firebase'

interface CreateOrderStoreProps extends FormComponentProps {
    store: CreateOrderStore
}

const StoreDescription = observer(({ store, form }: CreateOrderStoreProps) => {

    const { getFieldDecorator } = form

    return (
        <>
            {store.step === 2 && (
                <div className={`${styles.step} ${store.step === 2 && styles.stepActive}`}>
                    <Form onSubmit={(e) => store.submit(e, form)}>
                        <div className={styles.section}>
                            <div className={styles.subtitle}>{res().labels.description}</div>

                            <div className={`${styles.rowItem} ${styles.shopLink}`}>
                                <div className={styles.url}>
                                    <div className={styles.labelSm}> {res().labels.url} </div>
                                    <Form.Item>
                                        {getFieldDecorator('url', {
                                            initialValue: store.url.value,
                                            rules: [{ required: true, message: 'Обязательно для заполнения' }]
                                        })(
                                            <Input
                                                id="url"
                                                onChange={(e) => store.url.set(e.target.value)}
                                                className={styles.marginMd}
                                                placeholder={'www.store.com'}
                                                onBlur={() => store.updateData()}
                                            />
                                        )}
                                    </Form.Item>
                                </div>
                                <div className={styles.shopName}>
                                    <div className={styles.labelSm}> {res().labels.shop_name} </div>
                                    <div className={styles.name}>
                                        <Form.Item>
                                            {getFieldDecorator('shopName', {
                                                initialValue: store.shopName.value,
                                                rules: [{ required: true, message: 'Обязательно для заполнения' }]
                                            })(
                                                <Input
                                                    id="shopName"
                                                    onChange={(e) => store.shopName.set(e.target.value)}
                                                    className={styles.marginMd}
                                                    placeholder={'HALYK'}
                                                    onBlur={() => store.updateData()}
                                                />
                                            )}
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.labelSm}>{res().labels.establishment_date}</div>
                            <Form.Item>
                                {getFieldDecorator('establishment_date', {
                                    initialValue: store.existSixMonths,
                                    rules: [{ required: true, message: 'Выберите один из вариантов' }]
                                })(
                                    <Radio.Group name="establishment_date"
                                        buttonStyle="solid"
                                        onChange={store.onChangeEstablishmentDate}>
                                        <Radio value={true}> Да </Radio>
                                        <Radio value={false}> Нет </Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>

                        </div>
                        <div className={styles.section}>
                            <div className={styles.labelSm}>{res().labels.shop_description}</div>
                            <Form.Item>
                                {getFieldDecorator('serviceDescription', {
                                    initialValue: store.serviceDescription,
                                    rules: [{ required: true, message: 'Обязательно для заполнения' }]
                                })(
                                    <TextArea
                                        rows={4}
                                        onBlur={e => store.setDescription(e.target.value)}
                                        placeholder="Укажите основные группы товаров/услуг, максимальные и минимальные цены для каждой из групп,максимальную цену по асспортименту" />
                                )}
                            </Form.Item>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.subtitle}>{res().subtitles.tariff}</div>
                            <Form.Item>
                                {getFieldDecorator('tariff', {
                                    initialValue: store.tariff.value ? store.tariff.value : undefined,
                                    rules: [{ required: true, message: 'Выберите один из вариантов' }]
                                })(
                                    <Select
                                        id={'tariff'}
                                        onChange={(tariff) => store.onChangeTariff(tariff)}
                                        placeholder="Выберите значение">
                                        <Option value="standard" key={'standard'}> Стандартный — за 1 рабочий день </Option>
                                        <Option value="individual" key={'individual'}> Индивидуальный — до 14 рабочих дней </Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </div>

                        {store.tariff.value === 'standard' &&
                            <div>
                                <div className={styles.section}>
                                    <div className={styles.condition}>
                                        <div className={styles.conditionKey}>2,5%</div>
                                        <div className={styles.conditionValue}>Visa/Mastercard/UPI выпущенные АО «Народный банк Казахстана»</div>
                                    </div>
                                    <div className={styles.condition}>
                                        <div className={styles.conditionKey}>3%</div>
                                        <div className={styles.conditionValue}>Visa/Mastercard/UPI выпущенные платёжные карты чужих банков</div>
                                    </div>
                                    <div className={styles.condition}>
                                        <div className={styles.conditionKey}>4%</div>
                                        <div className={styles.conditionValue}> American Express, Diners Club, Discover, JCB</div>
                                    </div>
                                </div>
                                <div className={styles.infoText}>
                                    <span> Внимание! </span>
                                    После успешного заполнения всех документов, вы можете поучаствовать в предложении получить терминал за рабочий день
                                </div>
                            </div>
                        }
                        {store.tariff.value === 'individual' &&
                            <div>
                                <div className={styles.infoText}>
                                    <span> Внимание! </span>
                                    На рассмотрение индивидуальных условий по тарифу требуется до 14 рабочих дней
                                </div>
                            </div>
                        }

                        <div className={styles.ctaContainer}>
                            <Button
                                size="large"
                                type={'default'}
                                onClick={() => {
                                    logEventFirebase('step_2_go_back_btn')
                                    store.backStep()
                                }}
                            >
                                {res().labels.back}
                            </Button>
                            <Button
                                htmlType="submit"
                                size="large"
                                type={'primary'}
                                onClick={() => store.changeStep(3)}
                            >
                                {res().labels.continue}
                            </Button>
                        </div>

                    </Form>
                </div>
            )}
        </>

    )
})

export default StoreDescription