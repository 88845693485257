import React from 'react'

import res from '~/pages/auth/res'

import actions from '~/actions'
import { Button, FormItem } from '~/components'
import { DoneIcon } from '~/components/icon'

import styles from './styles.css'

interface SuccessOperationProps {
    title: string
    infoText?: string | JSX.Element
    buttonText?: string
    onButtonClick?: () => void
}

const SuccessOperation = ({
    title,
    infoText,
    buttonText,
    onButtonClick
}: SuccessOperationProps) => {
    return (
        <div>
            <div className={styles.header}>
                <DoneIcon className={styles.doneIcon} />
                <span className={styles.title}>{title}</span>
            </div>
            <div className={styles.main}>
                {infoText && (
                    <FormItem
                        className={`${styles.info} ${styles.successOperation}`}
                    >
                        {infoText}
                    </FormItem>
                )}
                <Button
                    type="primary"
                    size="large"
                    className={styles.mainButton}
                    onClick={() => {
                        typeof onButtonClick !== 'undefined'
                            ? onButtonClick()
                            : actions.openLogin()
                    }}
                >
                    {typeof buttonText !== 'undefined'
                        ? buttonText
                        : res().loginTitle}
                </Button>
            </div>
        </div>
    )
}

export default SuccessOperation
