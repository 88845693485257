import { action, computed, observable, runInAction } from 'mobx'

import { apiCheck, setPassword, setTeamMemberPassword, signUp } from '~/api'

import { noThrow } from '~/utils/control-flow'
import FieldStore, { isValidArr } from '~/utils/field-store'
import { userError } from '~/utils/user-error'
import {
    create,
    minLength,
    needToCapitalCharacters,
    needToContainsNumeric,
    needToLowercaseCharacters,
    needToNonAlphabeticalCharacters,
    required
} from '~/utils/validation'

import res from '../res'

import isFunction from 'lodash/isFunction'
import message from '~/utils/message'

const passwordRegex = /(?=.*[0-9])(?=.*[\W_])(?=.*[a-zа-яё])(?=.*[A-ZА-ЯЁ])[0-9a-zA-Zа-яёА-ЯЁ\W_]{8,}/

const password = create<string>(
    value => !value || passwordRegex.test(value),
    () => res().invalidPassword
)

const passwordCheck = [
    minLength(
        8,
        `${res().passwordHelp.title} 8 ${res().passwordHelp.notContainsErrors.minLength
        }`
    ),
    needToContainsNumeric(
        `${res().passwordHelp.title} ${res().passwordHelp.notContainsErrors.numbers
        }`
    ),
    needToNonAlphabeticalCharacters(
        `${res().passwordHelp.title} ${res().passwordHelp.notContainsErrors.nonAlphabeticalCharacters
        }`
    ),
    needToCapitalCharacters(
        `${res().passwordHelp.title} ${res().passwordHelp.notContainsErrors.capitalCharacters
        }`
    ),
    needToLowercaseCharacters(
        `${res().passwordHelp.title} ${res().passwordHelp.notContainsErrors.lowercaseCharacters
        }`
    )
]

export interface PasswordErrors {
    minLength8: boolean
    numbers: boolean
    nonAlphabeticalCharacters: boolean
    capitalCharacters: boolean
    lowercaseCharacters: boolean
}

export default class SetPasswordStore {
    constructor(
        private code?: string,
        private token?: string,
        private email?: string,
        private merid?: string
    ) { }

    public password = new FieldStore<string>(
        [required(() => res().passwordRequired), ...passwordCheck],
        this.handlePasswordChange
    )

    public passwordRepeat = new FieldStore<string>([
        required(() => res().passwordRepeatRequired),
        {
            rule: value => value === this.password.value,
            message: () => res().passwordsDoNotMatch
        },
        ...passwordCheck
    ])

    @observable
    public isPasswordShown = false

    @observable
    public isPasswordRepeatShown = false

    @observable
    public isSending = false

    @observable
    public isPasswordSet = false

    @observable
    public error: Error

    @observable
    public passwordNotContainsErrors: PasswordErrors = {
        minLength8: true,
        numbers: true,
        nonAlphabeticalCharacters: true,
        capitalCharacters: true,
        lowercaseCharacters: true
    }

    @computed
    public get hasPasswordErrors() {
        const result = !!Object.entries(this.passwordNotContainsErrors).filter(
            ([key, value]) => value
        ).length
        return result
    }

    @computed
    public get errorMessage(): string {
        const errorMessage = userError(this.error).message
        return isFunction(errorMessage) ? errorMessage() : errorMessage
    }

    @action
    public async setPassword() {
        if (!isValidArr(this.password, this.passwordRepeat)) return

        this.isSending = true

        if (this.code) {
            const result = await noThrow(
                apiCheck(
                    setPassword({
                        password: this.password.value,
                        key: this.code
                    })
                )
            )

            runInAction(() => {
                this.isSending = false
                this.isPasswordSet = !result.error
                if (!result.error) return
                this.error = result.error
            })
        } else if (this.email && this.token && this.merid) {
            const decryptedEmail = atob(this.email)
            const decryptedMerchantId = atob(this.merid)
            const data = {
                email: decryptedEmail,
                password: this.password.value,
                password_confirmation: this.passwordRepeat.value,
                token: this.token,
                merchant_id: decryptedMerchantId
            }
            // console.log(data)
            const result = await setTeamMemberPassword(data)
            runInAction(() => {
                this.isSending = false
                this.isPasswordSet = result.code === undefined
                // if (result) return
                // const errorMessage = userError(result).message
                // this.error = isFunction(errorMessage)
                //     ? errorMessage()
                //     : errorMessage
            })
        }
    }

    @action
    public togglePasswordVisibility() {
        this.isPasswordShown = !this.isPasswordShown
    }

    @action
    public togglePasswordRepeatVisibility() {
        this.isPasswordRepeatShown = !this.isPasswordRepeatShown
    }
    @action.bound
    private handlePasswordChange(value: string) {
        const check = {
            minLength8: value.length < 8,
            numbers: !/\d/.test(value),
            nonAlphabeticalCharacters: !/[^a-zA-Zа-яёА-ЯЁ0-9]/.test(value),
            capitalCharacters: !/[A-ZА-ЯЁ]/.test(value),
            lowercaseCharacters: !/[a-zа-яё]/.test(value)
        }
        this.passwordNotContainsErrors = check
    }
}
