import { ReactNode } from 'react'
import locale from '~/utils/locale'
import { EpayPayment } from '~/api'
import { CreditOrder } from '~/api/products'

export default locale.create<{
    dataFields: {
        full: {
            [key in keyof CreditOrder]?: string
        },
        short: {
            [key in keyof CreditOrder]?: string
        }
    }
    creditOrderStatus: {
        NEW: string
        IN_PROGRESS: string
        MERCHANT_CONFIRMATION: string
        ACCEPT: string
        REJECT: string
    }
    tooltips: {
        noValues: string
    }
    installment: string
    credit: string
    conditionLabel: (value: string) => string | ReactNode
}>()
