import res from './res'

import { CashByCodeStatus, CreditType } from '~/api'

res.add('kk', {
    title: 'Cash by code',
    ranges: {
        today: 'бүгін',
        yesterday: 'кеше',
        thisWeek: 'ағымдағы апта',
        priorWeek: 'өткен апта',
        thisMonth: 'ағымдағы ай',
        priorMonth: 'өткен ай',
        thisQuarter: 'ағымдағы тоқсан',
        customPeriod: 'көрсетілген кезең'
    },
    anyPaymentStatus: 'Кез келген',
    paymentStatuses: {
        [CashByCodeStatus.auth]: 'Күтеді',
        [CashByCodeStatus.charged]: 'Өткізілді',
        [CashByCodeStatus.cancelled]: 'Жойылды',
        [CashByCodeStatus.rejected]: 'Жойылды',
        [CashByCodeStatus.refunded]: 'Қайтарылды',
        [CashByCodeStatus.failed]: 'Сәтсіз',
        [CashByCodeStatus.new]: 'Жаңа',
        [CashByCodeStatus.any]: 'Кез келген',
        [CashByCodeStatus.CANCEL_OLD]: 'Мерзімі бойынша жойылды',
        [CashByCodeStatus.TIMEOUT_3D]: '3DS Қайтарылды'
    },
    creditTypes: {
        [CreditType.loan]: 'Кредит',
        [CreditType.installment]: 'Рассрочка'
    },
    sortBy: 'Бойынша сұрыптау',
    filtersPlaceholder: 'Төлем атрибуттары бойынша сүзгі',
    dataFields: {
        full: {
            amount: 'Сумма платежа',
            authDate: 'Дата авторизации',
            cardMask: 'Маска карты',
            client: 'Клиент',
            contractNumberDevice: 'Номер девайса',
            createdAt: 'Дата создания',
            currency: 'Валюта',
            description: 'Детали платежа',
            destinationPhone: 'На номер',
            epayTerminalID: 'epayTerminalID',
            failurePostLink: 'Ссылка ошибочного перенаправления',
            id: 'Идентификатор',
            invoiceId: 'Номер заказа',
            isPostLink: 'Постлинк',
            merchant: 'Мерчант',
            payerIP: 'IP плателщика',
            postLink: 'Ссылка перенаправления',
            postingDate: 'Дата публикации',
            postingStatus: 'Статус публикации',
            receiverPhone: 'Номер получателя',
            reference: 'Ответ от карточной системы',
            returnCode: 'Код возвращения',
            sourcePhone: 'С номера',
            stan: 'Stan',
            status: 'Статус платежа',
            terminalID: 'ID терминала'
        },
        short: {
            amount: 'Сумма',
            authDate: 'Дата авторизации',
            cardMask: 'Маска карты',
            cashCodeSMS: 'SMS код',
            client: 'Клиент',
            contractNumberDevice: 'Номер девайса',
            createdAt: 'Дата создания',
            currency: 'Валюта',
            description: 'Детали платежа',
            destinationPhone: 'На номер',
            epayTerminalID: 'epayTerminalID',
            failurePostLink: 'Ссылка ошибочного',
            id: 'Идентификатор',
            invoiceId: 'Номер заказа',
            isPostLink: 'Постлинк',
            merchant: 'Мерчант',
            payerIP: 'IP плателщика',
            postLink: 'Ссылка перенаправления',
            postingDate: 'Дата публикации',
            postingStatus: 'Статус',
            receiverPhone: 'Номер получателя',
            reference: 'Ответ',
            returnCode: 'Код возвращения',
            sourcePhone: 'С номера',
            stan: 'Stan',
            status: 'Статус платежа',
            terminalID: 'ID терминала'
        },
        groups: {
            location: 'Орналасқан жері',
            paymentPage: 'Төлем беті'
        },
        geolocation: 'Геолокация'
    },
    sortOptions: {
        date: 'Күні бойынша',
        postLink: 'Постлинк',
        status: 'Мәртебесі бойынша',
        type: 'Типі бойынша',
        asc: {
            date: 'басында ескілері',
            postLink: 'басындағы сәттілер',
            status: 'басында күтеді'
        },
        desc: {
            date: 'басында жаңалары',
            postLink: 'басындағы сәтсіздері',
            status: 'алдымен қайтарылғандар'
        }
    },
    secure3DValues: {
        true: 'Иә',
        false: 'Жоқ'
    },
    partialSum: {
        isRequired: 'Толтыруға міндетті сома',
        constraints: 'Сома төлем сомасынан аспайтын оң сан болуы тиіс'
    },
    operations: {
        fullCharge: {
            title: 'Толық есептен шығару',
            itemError: paymentReference =>
                `#${paymentReference} Төлем бойынша толық есептен шығару қатесі `
        },
        partialCharge: {
            title: 'Ішінара есептен шығару',
            itemError: paymentReference =>
                `#${paymentReference} Төлем бойынша ішінара есептен шығару қатесі `
        },
        cancel: {
            title: 'Болдырмау',
            itemError: paymentReference =>
                `#${paymentReference} Төлемді болдырмау қатесі`
        },
        fullRefund: {
            title: 'Толық қайтару',
            itemError: paymentReference =>
                `#${paymentReference} Төлемді толық қайтару қатесі`
        },
        partialRefund: {
            title: 'Ішінара қайтару',
            itemError: paymentReference =>
                `#${paymentReference} Төлемді ішінара қайтару қатесі `
        }
    },
    postLinkStatus: {
        all: 'Барлығы',
        ok: 'Сәтті',
        fail: 'Қате'
    },
    collapseSummary: range =>
        `${range instanceof Function ? range() : range || 'период'
        } кезең үшін статистика`,
    errors: {
        summaryLoadError: 'Статистиканы жүктеу қатесі',
        paymentsLoadError: 'Төлемдерді жүктеу қатесі'
    },
    errorCodes: {
        454: 'Операция сәтсіз аяқталды, картадағы соманың оқшауланғанын тексеріңіз және кейінірек әрекетті қайталаңыз.',
        455: '3DSecure/Securecode ровері қол жетімді емес немесе карта нөмірі дұрыс енгізілмеген. Басқа браузерді/құрылғыны пайдаланып көріңіз. Егер қате қайталанса, кодты қайта белгілеңіз.',
        456: 'Бұл карта бойынша төлем жасау мүмкін емес.',
        457: 'Картаның жарамдылық мерзімі дұрыс енгізілмеген.',
        458: 'Сервер жауап бермейді. Кейінірек көріңіз.',
        459: 'Сервер жауап бермейді. Кейінірек көріңіз.',
        460: 'Қате орын алды, картада сома оқшауланған болуы мүмкін, Қолдау қызметіне хабарласыңыз.',
        461: 'Жүйелік қате, егер қате қайталанса, кейінірек транзакция жасап көріңіз қолдау қызметіне хабарласыңыз.',
        462: 'Транзакцияны сіздің банк қабылдамады. Бас тарту себебін нақтылау үшін картаңыздың артқы жағында көрсетілген байланыстар бойынша өтініш жасау қажет.',
        463: 'Транзакцияны сіздің банк қабылдамады. Бас тарту себебін нақтылау үшін картаңыздың артқы жағында көрсетілген байланыстар бойынша өтініш жасау қажет.',
        464: 'Жарамсыз саудагер.',
        465: 'Карта бұғатталған.',
        466: 'Транзакцияны сіздің банк қабылдамады. Бас тарту себебін нақтылау үшін картаңыздың артқы жағында көрсетілген байланыстар бойынша өтініш жасау қажет.',
        467: 'Карта бұғатталған.',
        468: 'Қосымша сәйкестендіру қажет.',
        469: 'Жарамсыз транзакция, енгізілген деректерді қайта тексеріңіз.',
        470: 'Транзакция сомасы нөлге тең, қайталап көріңіз.',
        471: 'Жарамсыз карточка нөмірі, карта нөмірін енгізудің дұрыстығына көз жеткізіп, қайталап көріңіз.',
        472: 'Жарамсыз карточка нөмірі, карта нөмірін енгізудің дұрыстығына көз жеткізіп, қайталап көріңіз.',
        473: 'DSecure / Securecode енгізілген немесе дұрыс енгізілмеген. Енгізудің дұрыстығына көз жеткізіңіз немесе құпия сөзді қайта белгілеңіз. Егер қате қайталанса, қолдау қызметіне хабарласыңыз.',
        475: 'Транзакция сәтсіз аяқталды. Қайта қайталаңыз.',
        476: 'Транзакцияны қайта жүргізу кем дегенде 30 минуттан кейін қолжетімді болады.',
        477: 'Қате, басқа картаны пайдаланыңыз. Ол болмаған жағдайда Қолдау қызметіне хабарласыңыз epay@halykbank.kz',
        478: 'Картаның мерзімі өтіп кеткен.',
        479: 'Карта бұғатталған.',
        480: 'Эмитент-банкке өтініш жасау.',
        481: 'Карта жарамсыз. Жаңа карта шығару үшін банкке хабарласыңыз.',
        482: 'Карта жарамсыз. Жаңа карта шығару үшін банкке хабарласыңыз.',
        483: 'Карта мәртебесі - ұрланған. Жаңа картаны шығару үшін банкке хабарласыңыз.',
        484: 'Картада қаражат жеткіліксіз.',
        485: 'Картаның мерзімі аяқталған.',
        486: 'Транзакция қабылданбады. Картада интернет желісінде сатып алу мүмкіндігіне тыйым салынған немесе карта деректері дұрыс енгізілмеген.',
        487: 'Транзакция қабылданбады, қолдау қызметіне хабарласыңыз.',
        488: 'Сома рұқсат етілген шектен асады.',
        489: 'Карта бұғатталған.',
        490: 'Сіздің картаңыз бойынша транзакция жасауға тыйым салынған, қосымша ақпарат алу үшін картаңыздың артқы жағында көрсетілген байланыстарға хабарласыңыз.',
        491: 'Төлем жиілігі лимитінен асып кетті.',
        492: 'ДСН (ПИН)-кодтың дұрыс енгізілмеуіне байланысты карта оқшауланған, қосымша ақпарат алу үшін картаңыздың артқы жағында көрсетілген байланыстарға хабарласыңыз.',
        493: 'Картаны шығарған банк қолжетімді емес, төлемді кейінірек қайталап көріңіз.',
        494: 'Транзакция сәтті болмады-картаны шығарған банк, кейінірек транзакция жасап көріңіз.',
        495: 'Транзакцияға тыйым салынған, басқа картаны пайдаланыңыз.',
        496: '3DSecure SecureCode absent. Егер ККБ картасы болса-3DSecure SecureCode орнатылмаған. Басқа жағдайларда мәселе Эмитент жағынан.',
        497: 'Сервер жауап бермейді. Кейінірек көріңіз.',
        498: 'Бонустармен төлеу мүмкін емес. Кейінірек көріңіз.',
        499: '3DSecure/Securecode қате енгізілген немесе енгізілмеген.',
        500: '3DSecure/Securecode тексеру қол жетімді емес немесе сіз карта нөмірін қате енгіздіңіз. Басқа браузерді/құрылғыны пайдаланып көріңіз. Егер қате қайталанса, құпия сөзді қалпына келтіріңіз.',
        501: 'Картаға қызмет көрсету қатесі. Картаның дұрыс енгізілгенін тексеріңіз. Егер қате қайталанса, қолдау қызметіне хабарласыңыз.',
        502: 'Транзакцияның бұл түрі міндетті түрде 3DSecure/Securecode құпия сөзін енгізуді талап етеді.',
        503: 'Бұл операция сізден 3D Secure құпия сөзін пайдалануды талап етеді.',
        521: 'Транзакцияны сіздің банк қабылдамады. Бас тарту себебін нақтылау үшін картаңыздың артқы жағында көрсетілген байланыстар бойынша өтініш жасау қажет.',
        522: 'Жазба табылмады, картаны тексеріңіз.',
        1636: 'Карта иесін анықтау мүмкін болмады.',
        1654: 'OTP кодын тексеру мүмкін болмады.',
        2092: 'Транзакция сәтсіз аяқталды.',
        2678: 'Терминалда тәуліктік лимит асып кетті.'
    }
})
