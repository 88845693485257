import { action, computed, observable } from 'mobx'

import storage from '~/services/storage'

export type Language = 'en' | 'kk' | 'ru'

export interface Resource<T> {
    (): T
    add(lang: Language, value: T): Resource<T>
}

class Locale {

    constructor() {

        const lang = (storage.get('lang') || navigator.language || (navigator as any).userLanguage || '').substr(0, 2)

        this._lang = (lang === 'en' || lang === 'kk') ? lang : 'ru'
    }

    public get lang() {
        return this._lang
    }

    public isLang(lang: Language) {
        return this._lang === lang
    }

    @action
    public setLang(value) {

        storage.set('lang', value)

        this._lang = value
    }

    public create<T>(): Resource<T> {

        const map = new Map<string, T>()

        const current = computed(() => map.get(this._lang) || map.get('ru'))

        const result = () => current.get()

        result.add = (lang, value) => {

            map.set(lang, value)

            return result
        }

        return result
    }

    @observable
    private _lang: Language
}

const locale = new Locale()

export default locale
