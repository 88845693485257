import React from 'react'

import { action, computed, observable, runInAction, toJS } from 'mobx'

import moment, { DurationInputArg2 } from 'moment'

import strict from '~/utils/strict'

import paymentsTypes from '~/constants/payments-types'

import { RangePopoverValue } from '~/components/range-popover'

import {
    CartTypeDataItem,
    Channel,
    ChannelsDataItem,
    IssuingBanksDataItem,
    TransactionsDataItem
} from './types'

import res from './res'

import _ from 'lodash'
import { noThrow } from '~/utils/control-flow'
import message from '~/utils/message'
import {
    BaseDashboardFields,
    getTransactionsByBanksStatistic,
    getTransactionsByStatusStatistic,
    getTransactionsByCartTypeStatistic
} from '../../api/dashboard/index'
import { apiCheck } from '../../api/index'
import { PaymentStatus } from '../../api/payments/contracts'
import InteractiveMobileHintsStore from '~/components/interactive-mobile-hints/interactive-mobile-hints-store'
import BrowserStore from '~/services/browser/browser-state'
import InteractiveHint from '~/utils/interactive-hint'

export default class DashboardStore {

    @computed
    public get isChartDataLoading() {
        return this.successulTransactionsLoading
            || this.unsuccessulTransactionsLoading
            || this.issuingBanksTransactionsLoading
            || this.cartTypeTransactionsLoading
    }

    @computed
    public get rangePresets() {
        const localization = res().dateRanges

        const today = moment().startOf('day')

        const thisWeek = today.clone().startOf('isoWeek')

        const thisMonth = today.clone().startOf('month')

        const threeMonths = today.clone().add(-3, 'month')

        const sixMonths = today.clone().add(-6, 'month')

        const year = today.clone().add(-1, 'year')

        return strict<RangePopoverValue[]>([
            {
                key: 'thisWeek',
                label: localization.thisWeek,
                range: [thisWeek, thisWeek.clone().endOf('week')]
            },
            {
                key: 'thisMonth',
                label: localization.thisMonth,
                range: [thisMonth, thisMonth.clone().endOf('month')]
            },
            {
                key: 'threeMonths',
                label: localization.threeMonths,
                range: [threeMonths, today.clone().endOf('day')]
            },
            {
                key: 'sixMonths',
                label: localization.sixMonths,
                range: [sixMonths, today.clone().endOf('day')]
            },
            {
                key: 'year',
                label: localization.year,
                range: [year, today.clone().endOf('day')]
            },
            {
                key: 'customPeriod',
                label: localization.customPeriod,
                range: [null, null]
            }
        ])
    }

    @computed
    public get currentLabel() {
        const value = this.range
        const text =
            value.label && value.label instanceof Function
                ? value.label()
                : value.label
        const label =
            this.range.key === 'customPeriod'
                ? `${value.range[0].format('L')} - ${value.range[1].format(
                    'L'
                )}`
                : text
        return label
    }
    constructor() {
        this.getData()

        setTimeout(() => {
            this.showHintOnMobile()
        }, 500)
    }

    @observable
    public successulTransactionsLoading: boolean = false

    @observable
    public unsuccessulTransactionsLoading: boolean = false

    @observable
    public issuingBanksTransactionsLoading: boolean = false

    @observable
    public cartTypeTransactionsLoading: boolean = false

    @observable
    public channelsDataLoading: boolean = false

    @observable
    public rangeSelectorVisible: boolean = false

    @observable
    public interactiveHintsOn: boolean = true

    @observable
    public footerToolbarControls: React.ReactNode

    @observable
    public successfulTransactionsData: TransactionsDataItem[] = []

    @observable
    public issuingBanksData: IssuingBanksDataItem[] = []

    @observable
    public unsuccessfulTransactionsData: TransactionsDataItem[] = []

    @observable
    public cartTypeTransactionsData: CartTypeDataItem[] = []

    @observable
    public channelsData: ChannelsDataItem[] = []

    @observable
    public chartConfigsModals: Array<{
        chartName: string
        visible: boolean
    }> = [
            { chartName: 'SuccessfulTransactionsChart', visible: false },
            { chartName: 'IssuingBanksChart', visible: false }
        ]

    @observable.ref
    public mobileHintStore: InteractiveMobileHintsStore
    @observable.ref
    public range: RangePopoverValue = this.rangePresets[0]

    @action
    public showHints(sectionKeys: string[]) {
        this.mobileHintStore = new InteractiveMobileHintsStore(sectionKeys)
    }

    @action
    public hideHints() {
        this.mobileHintStore = null
    }

    @action.bound
    public setFormFieldVisibility(
        chartName: string,
        visibility?: boolean
    ): void {
        const chartConfig = this.chartConfigsModals.find(
            conf => conf.chartName === chartName
        )
        this.chartConfigsModals.forEach(conf => (conf.visible = false))
        chartConfig.visible = visibility ? visibility : false
    }

    @action.bound
    public setRange(value: RangePopoverValue) {
        if (value && value.range && value.range[0] && value.range[1]) {
            this.range = value

            this.getData()
        }
        this.rangeSelectorVisible = false
    }
    @action.bound
    public showDateSelector(e) {
        this.rangeSelectorVisible = true
    }

    private getData() {
        this.getSuccessfulTransactionsData()
        this.getUnsuccessfulTransactionsData()
        this.getCartTypeTransactionsData()
        this.getIssuingBanksData()
    }

    @action
    private async getSuccessfulTransactionsData() {
        this.successulTransactionsLoading = true

        const successfulTransactionRequest: BaseDashboardFields = {
            statuses: [PaymentStatus.auth, PaymentStatus.charged],
            date: {
                start: this.range.range[0].toDate(),
                end: this.range.range[1].toDate()
            }
        }

        const { value, error } = await noThrow(
            apiCheck(
                getTransactionsByStatusStatistic(successfulTransactionRequest)
            )
        )

        runInAction(() => {
            this.successulTransactionsLoading = false

            if (error) {
                return message.error(
                    error,
                    res().errors.successfulTransactionLoadError,
                    10
                )
            }
            this.successfulTransactionsData = value
        })
    }

    @action
    private async getUnsuccessfulTransactionsData() {
        this.unsuccessulTransactionsLoading = true

        const unSuccessfulTransactionRequest: BaseDashboardFields = {
            statuses: [PaymentStatus.failed, PaymentStatus.rejected],
            date: {
                start: this.range.range[0].toDate(),
                end: this.range.range[1].toDate()
            }
        }

        const { value, error } = await noThrow(
            apiCheck(
                getTransactionsByStatusStatistic(unSuccessfulTransactionRequest)
            )
        )

        runInAction(() => {
            this.unsuccessulTransactionsLoading = false

            if (error) {
                return message.error(
                    error,
                    res().errors.unsuccessfulTransactionLoadError,
                    10
                )
            }

            this.unsuccessfulTransactionsData = value
        })
    }

    @action
    private async getCartTypeTransactionsData() {
        this.cartTypeTransactionsLoading = true

        const cartTypeTransactionRequest: BaseDashboardFields = {
            statuses: [PaymentStatus.auth, PaymentStatus.charged],
            date: {
                start: this.range.range[0].toDate(),
                end: this.range.range[1].toDate()
            }
        }

        const { value, error } = await noThrow(
            apiCheck(getTransactionsByCartTypeStatistic(cartTypeTransactionRequest))
        )

        runInAction(() => {
            this.cartTypeTransactionsLoading = false

            if (error) {
                return message.error(
                    error,
                    res().errors.cartTypeLoadLoadError,
                    10
                )
            }

            this.cartTypeTransactionsData = value ?? []

        })
    }

    @action
    private async getChannelData() {
        this.channelsDataLoading = true

        // const channelsRequest: DashboardByChannelsRequest = {
        //     currency: defaultCurrency,
        //     date: {
        //         start: this.range.range[0].toDate(),
        //         end: this.range.range[1].toDate()
        //     }
        // }
        //
        // const { value, error } = await noThrow(
        //     apiCheck(getChannelsStatistic(channelsRequest))
        // )

        runInAction(() => (this.channelsDataLoading = false))
    }

    @action
    private async getIssuingBanksData() {
        this.issuingBanksTransactionsLoading = true

        const issuingBanksRequest: BaseDashboardFields = {
            statuses: [PaymentStatus.auth, PaymentStatus.charged],
            date: {
                start: this.range.range[0].toDate(),
                end: this.range.range[1].toDate()
            }
        }

        const { value, error } = await noThrow(
            apiCheck(getTransactionsByBanksStatistic(issuingBanksRequest))
        )

        runInAction(() => {
            this.issuingBanksTransactionsLoading = false

            if (error) {
                return message.error(
                    error,
                    res().errors.issuesBankLoadError,
                    10
                )
            }

            this.issuingBanksData = value ?? []
        })
    }

    private showHintOnMobile() {
        if (BrowserStore.isMobile) {
            const hintSections = InteractiveHint.getPageFlags('dashboard')

            if (hintSections) {
                this.showHints(hintSections)
            }
        }
    }
}
