import _, { snakeCase, upperCase } from 'lodash'
import { action, computed, observable, reaction, runInAction } from 'mobx'
import { ReactNode } from 'react'
import {
    AscDesc,
    PageSize,
    PaymentLinksRequest,
    SearchParameter
} from '~/api/contracts'
import {
    AlertLanguages,
    cancelPaymentLink,
    getPaymentLinks,
    PaymentLink,
    PaymentLinkDetails,
    PaymentLinkFilterableField,
    PaymentLinkGrid as PaymentLinksGrid,
    PaymentLinkRequest,
    PaymentLinkResponse,
    PaymentLinksBaseInfo,
    PaymentLinkSortableField,
    PaymentLinkStatus
} from '~/api/payment-links'
import { RangePopoverValue } from '~/components/range-popover'
import strict from '~/utils/strict'
import constants from './constants'

import amplitude from 'amplitude-js'

import res from './res'

import moment from 'moment'
import config from '~/config'
import { apiCheck } from '~/api'
import { noThrow } from '~/utils/control-flow'
import message from '~/utils/message'
import copy from 'copy-to-clipboard'
import { GeneratePaymentLinkBody } from './components/payment-links-config'
import Header from '~/layouts/main/header'
import { StatusPaymentActions } from '../payments/epay/components/payments-actions'
import InteractiveMobileHintsStore from '~/components/interactive-mobile-hints/interactive-mobile-hints-store'
import BrowserStore from '~/services/browser/browser-state'
import InteractiveHint from '~/utils/interactive-hint'

interface ColumnPref {
    field: keyof PaymentLinksGrid
    visible: boolean
    width?: number
    title: ReactNode
}

const lastDefaultVisibleColumn = 6

export default class PaymentLinksStore {
    constructor() {
        this.sortField = 'createdDate'
        this.sortDirection = 'desc'
        // if (!this.paymentLinks || this.paymentLinks.length === 0) {
        //     this.getInvoicesFromAPI()
        // }
        this.setup()

        reaction(
            () => this.paymetLinksGridColumn.map(col => col.field),
            () => this.normalizeWidths(),
            {
                fireImmediately: true
            }
        )

        setTimeout(() => {
            this.showHintOnMobile()
        }, 500)
    }
    @computed
    public get rangePresets() {
        const today = moment().startOf('day')

        const yesterday = today.clone().add(-1, 'days')

        const thisWeek = today.clone().startOf('isoWeek')

        const prevWeek = thisWeek.clone().add(-1, 'week')

        const thisMonth = today.clone().startOf('month')

        const prevMonth = thisMonth.clone().add(-1, 'month')

        const thisQuarter = today.clone().startOf('quarter')

        return strict<RangePopoverValue[]>([
            {
                key: 'today',
                label: () => res().ranges.today,
                range: [today, today.clone().endOf('day')]
            },
            {
                key: 'yesterday',
                label: () => res().ranges.yesterday,
                range: [yesterday, yesterday.clone().endOf('day')]
            },
            {
                key: 'thisWeek',
                label: () => res().ranges.thisWeek,
                range: [thisWeek, thisWeek.clone().endOf('isoWeek')]
            },
            {
                key: 'priorWeek',
                label: () => res().ranges.priorWeek,
                range: [prevWeek, prevWeek.clone().endOf('isoWeek')]
            },
            {
                key: 'thisMonth',
                label: () => res().ranges.thisMonth,
                range: [thisMonth, thisMonth.clone().endOf('month')]
            },
            {
                key: 'priorMonth',
                label: () => res().ranges.priorMonth,
                range: [prevMonth, prevMonth.clone().endOf('month')]
            },
            {
                key: 'thisQuarter',
                label: () => res().ranges.thisQuarter,
                range: [thisQuarter, thisQuarter.clone().endOf('quarter')]
            },
            {
                key: 'customPeriod',
                label: () => res().ranges.customPeriod,
                range: [undefined, undefined]
            }
        ])
    }
    @observable
    public pageInfo: {
        from: number
        to: number
        total: number
    }

    @observable
    public pageSize: PageSize = 20

    @observable
    public pageIndex = 0

    @observable
    public paymentLinks: PaymentLink[] = []

    @observable
    public paymentLinksLoading: boolean

    @observable
    public showPaymentLinkConfig: boolean

    @observable
    public showPaymentLinkConfirmation: boolean

    @observable
    public showSortingMobile: boolean

    @observable
    public paymentLinkUpdating: boolean = false

    @observable
    public sortField: keyof PaymentLinkDetails

    @observable
    public sortDirection: 'asc' | 'desc'

    public paymentLinkKey = 'id'

    @observable
    public columns: ColumnPref[]

    @observable.ref
    public range: RangePopoverValue

    @observable
    public statusFilter: PaymentLinkStatus

    @observable
    public hasError: boolean = false

    @observable.ref
    public mobileHintStore: InteractiveMobileHintsStore

    @action
    public showHints(sectionKeys: string[]) {
        this.mobileHintStore = new InteractiveMobileHintsStore(sectionKeys)
    }

    @action
    public hideHints() {
        this.mobileHintStore = null
    }

    public get paymetLinksGridColumn() {
        return this.columns.filter(x => x.visible)
    }

    @action.bound
    public setStatusFilter(value: PaymentLinkStatus) {
        this.statusFilter = value

        runInAction(() => {
            this.getInvoicesFromAPI()
        })
    }

    @action.bound
    public setRange(value: RangePopoverValue) {
        if (value && value.range && value.range[0] && value.range[1]) {
            this.range = value
        }

        runInAction(() => {
            this.getInvoicesFromAPI()
        })
    }

    @action
    public paymentLinkDetails(paymentLink: PaymentLinksBaseInfo) {
        return null
    }

    @action.bound
    public setSort(field: keyof PaymentLinkDetails, direction: 'asc' | 'desc') {
        this.sortField = field
        this.sortDirection = direction

        runInAction(() => {
            this.getInvoicesFromAPI()
        })
    }

    @action.bound
    public async reload() {
        await this.getInvoicesFromAPI()
    }

    @action.bound
    public togglePaymentLinkConfigForm = () => {
        this.showPaymentLinkConfig = !this.showPaymentLinkConfig
        if (!this.showPaymentLinkConfig) {
            this.showPaymentLinkConfirmation = false
            runInAction(() => this.reload())
        }
    }

    @action.bound
    public setColumnsOrder(
        fields: Array<{ field: keyof PaymentLinksGrid; visible: boolean }>
    ) {
        const map = this.columnMap

        const actionsColumn = {
            field: constants
                .fields(this)
                .get()
                .find(x => x.field === 'actions').field,
            visible: true
        }

        fields.push(actionsColumn)

        this.columns = fields.map(col => {
            const column = map.get(col.field)

            column.visible = col.visible

            return column
        })
    }

    @action.bound
    public setPageSize(size: PageSize) {
        this.pageIndex = Math.floor((this.pageSize * this.pageIndex) / size)

        this.pageSize = size
    }

    @action.bound
    public toggleSortingMobile = () =>
        (this.showSortingMobile = !this.showSortingMobile)

    @action.bound
    public async cancelLink(id: string) {
        const params = {
            id
        }
        const cancellationRequest = await cancelPaymentLink(params)

        runInAction(() => {
            this.getInvoicesFromAPI()
        })
    }

    @action.bound
    public goFirstPage() {
        this.pageIndex = 0

        runInAction(() => {
            this.getInvoicesFromAPI()
        })
    }

    @action.bound
    public goPrevPage() {
        if (this.pageIndex > 0) {
            this.pageIndex--

            runInAction(() => {
                this.getInvoicesFromAPI()
            })
        }
    }

    @action.bound
    public goNextPage() {
        if (!this.pageInfo || this.pageIndex >= this.lastPage) return

        this.pageIndex++

        runInAction(() => {
            this.getInvoicesFromAPI()
        })
    }

    @action.bound
    public goLastPage() {
        if (!this.pageInfo) return

        this.pageIndex = this.lastPage

        runInAction(() => {
            this.getInvoicesFromAPI()
        })
    }

    @action.bound
    public async getInvoicesFromAPI() {
        this.paymentLinksLoading = true
        this.hasError = false

        const skipValue: number = this.pageIndex * this.pageSize

        const searchParameters: Array<SearchParameter<
            PaymentLinkFilterableField
        >> =
            !this.statusFilter || this.statusFilter === 'any'
                ? [
                    {
                        name: 'created_date',
                        method: 'between',
                        searchParameter: [
                            this.range.range[0],
                            this.range.range[1]
                        ]
                    }
                ]
                : [
                    {
                        name: 'created_date',
                        method: 'between',
                        searchParameter: [
                            this.range.range[0],
                            this.range.range[1]
                        ]
                    },
                    {
                        name: 'status',
                        method: 'like',
                        searchParameter: [this.statusFilter.toUpperCase()]
                    }
                ]

        const requestBody: PaymentLinkRequest = {
            paging: {
                skip: skipValue,
                limit: this.pageSize
            },
            searchParameters,
            orderParameters: {
                field: snakeCase(this.sortField) as PaymentLinkSortableField,
                typeOrder: upperCase(this.sortDirection) as AscDesc
            }
        }

        const { value, error } = await noThrow(
            apiCheck(getPaymentLinks(requestBody))
        )

        runInAction(() => {
            this.paymentLinksLoading = false
            if (error) {
                this.hasError = true
                amplitude
                    .getInstance()
                    .logEvent('failed_to_load_payment_links', error)
                return message.error(
                    error
                    // `Click on the link below to get more details about this error`
                )
            }

            this.paymentLinks = value?.Records
            amplitude.getInstance().logEvent('fetched_payment_links')

            this.setPageInfo(value)
        })
    }

    @action
    public setPageInfo(value: PaymentLinkResponse) {
        let toValue: number = 0
        let fromValue: number
        const skipValue = this.pageIndex * this.pageSize

        if (this.pageIndex === 0) {
            fromValue = 1
        } else {
            fromValue = skipValue + 1
        }

        if (value?.TotalCount > skipValue + this.pageSize) {
            toValue = skipValue + this.pageSize
        } else {
            toValue = value?.TotalCount
        }

        this.pageInfo = {
            from: fromValue,
            to: toValue,
            total: value?.TotalCount
        }
    }

    @action.bound
    public async generateLink(
        generatedPaymentLinkBody: GeneratePaymentLinkBody
    ): Promise<PaymentLink> {
        const { data } = JSON.parse(localStorage.getItem('auth'))

        const response = await fetch(`${config.api.baseUrl}invoice`, {
            method: 'POST',
            headers: new Headers({
                Authorization: `Bearer ${data.access_token}`,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(generatedPaymentLinkBody)
        })

        const resJSON = await response.json()
        return resJSON
    }

    public async copyLink(item: PaymentLink) {
        return copy(item.invoice_url)
    }

    protected set prefs(value) {
        const { range, ...rest } = value

        Object.assign(this, rest)

        if (range) {
            const key = range.key

            if (key) {
                const preset = this.rangePresets.find(r => r.key === key)
                if (preset) {
                    this.range = preset
                    return
                }
            }

            this.range = {
                range: [moment(range.range[0]), moment(range.range[1])]
            }
        }
    }

    @computed
    private get lastPage() {
        const n = this.pageInfo.total / this.pageSize

        return Math.floor(n) - (n % 1 === 0 ? 1 : 0)
    }
    private async setup() {
        this.columns = constants
            .fields(this)
            .get()
            .map((item, index) => {
                return {
                    field: item.field,
                    width: +item.width,
                    visible:
                        index <= lastDefaultVisibleColumn ||
                        item.field === 'actions',
                    title: item.title
                }
            })
        this.range = this.rangePresets[2]
        this.statusFilter = undefined
    }

    private normalizeWidths() {
        const width = this.paymetLinksGridColumn.reduce(
            (sum, col) => sum + (col.width || 10),
            0
        )

        if (width !== 100) {
            this.paymetLinksGridColumn.forEach(
                col => (col.width = (100 * (col.width || 10)) / width)
            )
        }
    }

    @computed
    private get columnMap() {
        return new Map(
            this.columns.map((col): [keyof PaymentLinksGrid, ColumnPref] => [
                col.field,
                col
            ])
        )
    }

    private showHintOnMobile() {
        if (BrowserStore.isMobile) {
            const hintSections = InteractiveHint.getPageFlags('paymentLinks')

            if (hintSections) {
                this.showHints(hintSections)
            }
        }
    }
}
