import React from 'react'
import res from './res'

res.add('en', {
    title: 'Contract OCT',
    contracts: 'Сontracts',
    contract: 'Сontract',
    terminal: 'Терминал',
    sortBy: 'Sort by:',
    sortOptions: {
        date: 'Date of last schedule',
        name: 'Name',
        contract: 'Contract',
        type: 'Type',
        asc: {
            date: 'old first',
            name: 'ascending',
            contract: 'ascending',
            type: 'ascending'
        },
        desc: {
            date: 'new first',
            name: 'descending',
            contract: 'descending',
            type: 'descending'
        }
    },
    dataFields: {
        titles: {
            contract: 'Contract',
            type: 'Type',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            terminals: 'Terminals',
            name: 'Name'
        },
        historyTitles: {
            create: 'Formed',
            period: 'For period',
            emails: 'Recipients',
            initiateType: 'Initiate type',
            format: 'Format',
            status: 'Status'
        }
    },
    dateRanges: {
        yesterday: 'yesterday',
        priorWeek: 'prior week',
        priorMonth: 'prior month',
        customPeriod: 'custom period'
    },
    initiationType: {
        custom: 'On demand',
        auto: 'Auto',
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly'
    },
    errors: {
        statementsLoadingError: 'Statements loading error',
        statementCreatingError: 'Statement creating error',
        statementUpdatingError: 'Statement updating error',
        statementHistoryLoadingError: 'Statement history loading error'
    },
    tooltips: {
        enabledStatus:
            'Configuration for automatic statements has been created and activated',
        disabledStatus:
            'Configuration for automatic statements has been created but not activated',
        noEmails: 'No recipients specified',
        downloadStatement: 'Download statement',
        statementCreating: 'Statement creating',
        reload: 'Reload data',
        statementEmpty: 'No data',
        statementSettings: 'Statement settings'
    },
    pageInfo: (info: { from: number; to: number; total: number }) => (
        <>
            {info.from}-{info.to}
        </>
    ),
    generateButton: 'Generate',
    automaticStatements: 'Automatic statements',
    statementHistory: 'Formed statements',
    statementsFor: 'Statements',
    active: 'Activated',
    inactive: 'Not activated',
    statement: 'Statement'
})
