import React from 'react'

import { observer } from 'mobx-react'

import { OCTStatus } from '~/api'

import { RangePopover } from '~/components'

import { RangePopoverValue } from '~/components/range-popover'

import StatusSelect from '../../../components/status-select'

import { PaymentLinkStatus } from '~/api/payment-links'

import epayRes from '../../res'

import res from './res'

interface BaseFiltersProps {
    rangePresets: RangePopoverValue[]
    range: RangePopoverValue
    onRangeChange: (value: RangePopoverValue) => void
    code: OCTStatus | PaymentLinkStatus
    onStatusChange: (value: OCTStatus | PaymentLinkStatus) => void
    className?: string
    statusesOptions?: Array<{ key: string; title: () => string | string }>
    dateLabel?: string
}

export const StatusesTitlesRes = () => epayRes().paymentStatuses

export const StatusesOptions = [
    {
        key: OCTStatus.any,
        title: () => epayRes().anyPaymentStatus.toLocaleLowerCase()
    },
    // {
    //     key: OCTStatus.auth,
    //     title: () => StatusesTitlesRes()[OCTStatus.auth].toLocaleLowerCase()
    // },
    {
        key: OCTStatus.charged,
        title: () =>
            StatusesTitlesRes()[OCTStatus.charged].toLocaleLowerCase()
    },
    {
        key: OCTStatus.rejected,
        title: () =>
            StatusesTitlesRes()[OCTStatus.rejected].toLocaleLowerCase()
    }
    // {
    //     key: OCTStatus.cancelled,
    //     title: () =>
    //         StatusesTitlesRes()[OCTStatus.cancelled].toLocaleLowerCase()
    // },
    // {
    //     key: OCTStatus.refunded,
    //     title: () =>
    //         StatusesTitlesRes()[OCTStatus.refunded].toLocaleLowerCase()
    // }
]

export default observer((props: BaseFiltersProps) => {
    const onStatusChange = (value: string) => {
        props.onStatusChange(
            value === OCTStatus.any ? undefined : (value as OCTStatus)
        )
    }

    return (
        <div className={props.className}>
            <label htmlFor="range-popover">
                {props.dateLabel ? props.dateLabel : res().datePeriod}
                &nbsp;&nbsp;
            </label>
            <RangePopover
                id="range-popover"
                placement="bottomLeft"
                presets={props.rangePresets}
                value={props.range}
                onChange={props.onRangeChange}
            />
            ,&nbsp;&nbsp;
            <label htmlFor="status">{res().code}&nbsp;&nbsp;</label>
            <StatusSelect
                id="status"
                options={
                    props.statusesOptions
                        ? props.statusesOptions
                        : StatusesOptions
                }
                onChange={onStatusChange}
                value={props.code || 'any'}
            />
        </div>
    )
})
