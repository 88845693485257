import React from 'react'
import res from './res'

res.add('ru', {
    title: 'Заявка на активацию рассрочки и кредитования',
    description: 'Для дальнейшего использования системы, пожалуйста, заполните заявку на активацию. Функции системы будут доступны после утверждения заявки   ',
    sendOrder: 'Отправить заявку',
    formItemTitles: {
        shop: 'Магазин',
        email: 'E-mail получателей'
    },
    shopPlaceholder: 'Выберите магазин',
    emailPlaceholder: 'Введите email',
    emailRequired: 'Пожалуйста, введите email адрес участника',
    invalidEmail: 'Введите email правильного формата (example@email.co)',
    invalidShop: 'Выберите магазин',
    confirmationTitle: (orderNumber: string) => (
        <div>
            Заявка №{orderNumber} отправлена на рассмотрение!
        </div>
    ),
    confirmationUpdateTitle: 'Заявка одобрена',
    confirmationSubtitle: 'В ближайшее время она будет рассмотрена. Следите за статусом заявки, и комментариями модератора.',
    closeButton: 'Закрыть',
    send: 'Отправить'
})
