import res from './res'

res.add('en', {
    title: 'Invite to the team',
    subTitle: `Enter the member's email address so that he can log in.'accessLevelChanges`,
    email: 'Member Email',
    emailRequired: 'Please, enter member email',
    invalidEmail: 'Incorrect email format',
    roleTitle: 'Define the role for the new member',
    manager: 'Manager',
    managerDescription:
        'Access to viewing payments and statistics, issuing invoices for payment, generating statements, managing payments, searching for payments',
    accountant: 'Accountant',
    accountantDescription: 'Access to viewing transactions and statistics, generation of statements, search for payments',
    view: 'Viewer',
    viewDescription: 'Access to view transactions and statistics',
    accessLevelChanges: 'Access Level Changes for',
    applyButton: 'Apply',
    cancelButton: 'Cancel'
})
