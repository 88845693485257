import React from 'react'

import { observer } from 'mobx-react'
import { Button } from '../index'

export interface MobileMenuConfig {
    buttons: ButtonConfig[]
    visible?: boolean
    disabled?: boolean
}

export interface ButtonConfig {
    clickAction: () => void
    icon: React.ReactNode
    className?: string
}

export const defaultMobileMenuConfig: MobileMenuConfig = {
    disabled: true,
    visible: false,
    buttons: []
}

export default observer(({ config }: { config: MobileMenuConfig }) => {
    if (!config) {
        return <React.Fragment></React.Fragment>
    }
    return (
        <React.Fragment>
            {config && config.buttons.length > 0 && (
                <React.Fragment>
                    {config.buttons.map((buttonConfig, index) => (
                        <Button key={index} onClick={buttonConfig.clickAction} className={buttonConfig.className}>
                            {buttonConfig.icon}
                        </Button>
                    ))}
                </React.Fragment>
            )}
        </React.Fragment>
    )
})
