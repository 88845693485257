import React from 'react'

import { observer } from 'mobx-react'

import ModalIdleWarning from './modal-idle-warning'

export interface IdleTimerComponentProps {
    visible: boolean
    onOK: () => void
    onCancel: () => void
}

export default observer((props: IdleTimerComponentProps) => {
    return (
        <span>
            <ModalIdleWarning
                onOk={props.onOK}
                onCancel={props.onCancel}
                onCountDownComplete={props.onCancel}
                visible={props.visible}
            />
        </span>
    )
})
