import { ReactNode } from 'react'

import locale from '~/utils/locale'

export default locale.create<{
    title: string
    labels: {
        language: string
        email: string
        password: string
        role: string
    },
    buttons: {
        changePassword: string
    },
    lastUsage: {
        header: string,
        table: {
            headers: {
                geo: string
                recordDate: string
                userSystem: string
                ip: string
                status: string
            }
        }
    },
    changePasswordInfo: string
    changePasswordSuccess: (email: string) => ReactNode
}>()
