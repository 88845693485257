import res from './res'

res.add('kk', {
    title: 'Дүкенге арналған бизнес параметрлері ',
    confirmTitle: 'Сіз деректерді жүктегіңіз келетініне сенімдісіз бе?',

    labels: {
        shop_name: 'Дүкен атауы',
        shop_name_placeholder: 'Дүкен атауын енгізіңіз',
        logo: 'Логотип',
        lang: 'Тілі',
        email: 'Email',
        email_placeholder: 'Электрондық поштаны енгізіңіз',
        phone: 'Телефон',
        phone_placeholder: 'Телефонды енгізіңіз',
        colorScheme: 'Түс схемасы',

        bg: 'Жалпы аясы',
        btn_color: 'Батырма түсі',
        card_bg: 'Карта аясы',
        card_txt_color: 'Картадағы мәтін түсі',
        txt_color: 'Жалпы мәтін түсі',

        required: 'Міндетті',

        fileDescription: 'Жарамды форматтар тек jpg, png, gif немесе svg өлшемі 1 Мб дейін. Логотип төлем парағының жоғарғы жағында көрсетіледі',
        gradientDescription: '(Градиенттік толтыру үшін екі түсті орнатыңыз)',

        reset: 'Қалпына келтіру',
        save: 'Сақтау',
        load: 'Жүктеу',
        custom: 'Түстерді қолмен орнатыңыз'
    },

    sampleForm: {
        Order: 'Артқа',
        PaymentStore: 'Интернет дүкенде төлеу',
        Commission: 'Комиссия',
        Seller: 'Сатушы',
        cardNumber: 'Карта нөмірі',
        expires: 'Дейін жарамды',
        cardName: 'Картадағы атау',
        email: 'Сіздің электрондық поштаңыз',
        phoneNumber: 'Сіздің телефоныңыз',
        pay: 'Төлеу',
        back: 'Артқа'
    },

    messages: {
        epayTemplateUpdated: 'Бизнес параметрлері сақталды',
        errorTitle: 'Қате',
        cannotLoadShops: 'Дүкендер тізімін жүктеу мүмкін емес',
        invalidFile: 'Жарамсыз файл түрі немесе өлшемі 1 Мб-тан асады',
        unsavedChanges: 'Егер сіз операцияны растасаңыз, барлық өзгерістер жоғалады',
        invalidEmail: 'Жарамсыз электрондық пошта',
        valueRequired: 'Мәнді беру керек',
        invalidPhone: 'Қате телефон нөмірі'
    }

})
