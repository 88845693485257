import res from './res'
import React from 'react'

res.add('kk', {
    title: 'Chargeback',
    infoTitle: 'Біз ақшаны қайтару процедурасы (chargeback) үшін URL мекенжайын толығымен конфигурациялауға және қауіпсіздікті жақсарту үшін өзіңіздің құпия кілтіңізді орнатуға мүмкіндік беретін жаңа мүмкіндікті қостық',
    infoText: <>
        <p>Енді сіз қайтару сұраулары жіберілетін жеке URL мекенжайларын көрсете аласыз, бұл сізге икемділік пен қайтару процесін бақылауға мүмкіндік береді. Өзіңіздің ортақ құпия кілтіңізді орнату сіздің қосымшаңыздың біздің жүйемен өзара әрекеттесуі кезінде қауіпсіздіктің қосымша деңгейін қамтамасыз етеді.
        </p>
        <p>Shared Secret - бұл сатушы орнатқан және кері қайтару операциясының түпнұсқалығын растау үшін пайдаланылатын құпия код. Сатушы төлемді қайтару сұрауының әрқайсысында Shared Secret қамтуы керек. Саудагердің shared secret төлем жүйесі сақтаған құпиямен сәйкестендіру арқылы ол төлемді қайтарудың заңдылығына кепілдік береді және алаяқтықтан қорғайды.</p>
    </>,
    settingTitle: 'Chargeback баптаулары',
    messages: {
        valueRequired: 'Міндетті түрде толтыру қажет',
        successUpdate: 'Cәтті жаңартылды'
    },
    labels: {
        activeUrl: 'Ағымдағы url chargeback',
        activeSecret: 'Ағымдағы shared secret',
        chargebackUrl: 'url chargeback',
        chargebackUrlPlaceholder: 'https://yourwebsite.com/chargeback',
        sharedSecret: 'shared secret',
        sharedSecretPlaceholder: 'Y0urSh@r3dS3cr3tK3y',
        save: 'Сақтау',
        edit: 'Өзгерту'
    }
})
