import res from './res'

res.add('en', {
    errors: {
        146: 'Please, check email and password and try again',
        148: 'The user is blocked, access must be restored',
        151: 'Please, check email and password and try again',
        275: 'The email address you have entered is already registered',
        267: 'Your password has expired. You need to reset your password',
        912: 'Error searching for user'
    }
})
