import React from 'react'

import { observer } from 'mobx-react'

import { FormItem } from '~/components'

let itemIndex = 0

export function createFormItem(defaultProps) {
    const i = ++itemIndex

    let fieldIndex = 0

    return observer(({ children, ...rest }) => {
        const j = ++fieldIndex

        const id = `form-item-${i}-${j}`

        if (children.length > 1) {
            children = (
                <span id={id} className="ant-input-group">
                    {children}
                </span>
            )
        } else {
            children = React.Children.map(children, (child, index) =>
                React.cloneElement(child, { id })
            )
        }

        return (
            <FormItem htmlFor={id} {...defaultProps} {...rest}>
                {children}
            </FormItem>
        )
    })
}
