import React from 'react'

import { observer } from 'mobx-react'

import { NoDataIcon } from '../icon/index'
import res from './res'
import styles from './styles.css'

interface Column {
    title: string
    key?: string
    cellClassName?: string
}

interface TableProps {
    columns: Column[]
    dataSource?: any[]
    className?: string
    showHeader?: boolean
}

const renderCell = (row, rowIndex, col) => {
    return (
        <div
            className={`${styles.td} ${row[col.key] ? '' : styles.empty
                } ${col.cellClassName || ''}`}
            key={`row_${rowIndex}_col_${col.key}`}
        >
            <span className={styles.title}>{col.title}</span>
            <span className={styles.value}>{row[col.key]}</span>
        </div>
    )
}

export default observer((props: TableProps) => {
    const { columns, dataSource, className, showHeader } = props

    let rows: React.ReactNode
    let noData: React.ReactNode

    if (!dataSource || !dataSource.length) {
        noData = (
            <div className={styles.noData}>
                <NoDataIcon className={styles.noDataIcon} />
                <span>{res().noDataLable}</span>
            </div>
        )
    } else {
        rows = (
            <div className={styles.body}>
                {dataSource.map((row, i) => (
                    <div
                        key={`row_${i}`}
                        className={`${styles.tr} ${row.className || ''}`}
                    >
                        {props.columns.map(col => renderCell(row, i, col))}
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className={className}>
            <div className={styles.table}>
                {showHeader !== false && (
                    <div className={styles.head}>
                        {columns.map((col, i) => (
                            <div key={`col_${i}`} className={styles.th}>
                                {col.title}
                            </div>
                        ))}
                    </div>
                )}
                {rows}
            </div>
            {noData}
        </div>
    )
})
