import { Content, del, get, post, put } from '~/utils/ajax'

import { City, FileAPI, MerchantDataAPI, RegisterMerchant } from './contracts'
import { GovAPI } from '~/api/create-order/contracts'
import { apiCheck, Shop } from '~/api'
import config from '~/config'

export * from './contracts'

export const getBankBranches = () =>
    get<any>('bankBranches.json?' + Date.now().toString(), undefined, {
        baseUrl: '/static/data/'
    }).then(response => {
        return response
    })

export const checkByBin = async (bin: string): Promise<GovAPI> => apiCheck(await get<GovAPI>(`application/new/info/${bin}/ru`))

export const getMerchantData = async (id: string) => get<MerchantDataAPI>(`application/new/${id}/data`)

export const createOrGetMerchant = async () => await post<RegisterMerchant>('application/new/register')

export const updateMerchantData = async (MerchantData: MerchantDataAPI, id: string) => await post(`application/new/${id}/data`, MerchantData)

export const sendToComundaService = async () => await get(`application/new/camunda`)

export const updateStep = async (step) => await post(`application/new/step/${step}`)

export const getUploadedFiles = async (id: string): Promise<any> =>
    apiCheck(await get<FileAPI[]>(`/application/new/${id}/files`))
        .map(v => {
            return {
                uid: v.id,
                name: v.name,
                status: 'done',
                size: v.name,
                type: 'text'
            }
        })

export const uploadFile = async (fileData, id: string) => {

    const { data } = JSON.parse(localStorage.getItem('auth'))

    return await fetch(
        `${config.api.baseUrl}application/new/${id}/file`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${data.access_token}`
            },
            body: fileData
        }
    )
}

export const downloadFile = (id: string) => get<Response>(`application/new/file/${id}/download`, undefined, { response: new Content('binary', { id }) })

export const removeFile = async (id: string) => await del(`application/new/file/${id}`)
