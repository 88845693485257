import { ReactNode } from 'react'

import locale from '~/utils/locale'

interface ChartTexts {
    title: string
    fieldsSettings?: string
}

export default locale.create<{
    title: string
    dateSelectorLabel: string
    charts: {
        successfulTransactions: ChartTexts
        issuingBanks: ChartTexts
        unsuccessfulTransactions: ChartTexts
        channels: ChartTexts
        cartType: ChartTexts
    }
    fieldsSettings: string
    dateRanges: {
        today: string
        yesterday: string
        thisWeek: string
        priorWeek: string
        thisMonth: string
        priorMonth: string
        thisQuarter: string
        threeMonths: string
        sixMonths: string
        year: string,
        customPeriod: string

    }
    errors: {
        successfulTransactionLoadError: string
        unsuccessfulTransactionLoadError: string
        issuesBankLoadError: string
        channelsDataLoadError: string
        cartTypeLoadLoadError: string
    }
    tooltip: {
        count: string
        cardType: string
        sum: string
        issuer: string
        date: string
    }
    count: string
    loading: string
    noDataLabel: string
}>()
