import React from 'react'

import res from './res'

res.add('ru', {
    title: 'Статистика',
    dateSelectorLabel: 'Статистика за ',
    charts: {
        successfulTransactions: {
            title: 'Успешные транзакции',
            fieldsSettings:
                'Отметьте транзакции, которые необходимо выводить на графике'
        },
        issuingBanks: {
            title: 'Банки-эмитенты',
            fieldsSettings:
                'Отметьте банки, которые необходим выводить на графике'
        },
        unsuccessfulTransactions: {
            title: 'Неуспешные транзакции'
        },
        channels: {
            title: 'Каналы'
        },
        cartType: {
            title: 'Типы карт'
        }
    },
    fieldsSettings: 'Настройка вывода',
    dateRanges: {
        today: 'сегодня',
        yesterday: 'вчера',
        thisWeek: 'текущую неделю',
        priorWeek: 'прошлую неделю',
        thisMonth: 'текущий месяц',
        priorMonth: 'прошлый месяц',
        thisQuarter: 'текущий квартал',
        threeMonths: '3 месяца',
        sixMonths: 'полгода',
        year: 'год',
        customPeriod: 'указанный период'
    },
    tooltip: {
        count: 'Кол-во',
        cardType: 'Типа карты',
        sum: 'Сумма',
        issuer: 'Эмитент',
        date: 'Дата'
    },
    errors: {
        successfulTransactionLoadError:
            'Ошибка загрузки статистики успешных тразакций',
        unsuccessfulTransactionLoadError:
            'Ошибка загрузки статистики неуспешных тразакций',
        issuesBankLoadError: 'Ошибка загрузки статистики банков-эмитентов',
        channelsDataLoadError: 'Ошибка загрузки статистики каналов',
        cartTypeLoadLoadError: 'Ошибка загрузки статистики по картам'
    },
    count: 'Количество',
    loading: 'Загрузка',
    noDataLabel: 'Нет данных'
})
