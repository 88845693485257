import React from 'react'
import res from './res'

res.add('ru', {
    title: 'Отклонить заявку',
    description: 'Пожалуйста опишите ниже причину отклонения заявки',
    confirmationTitle: (orderNumber: string) => (
        <div>
            Заявка №{orderNumber} повторно отправлена на обработку
        </div>
    ),
    cancelButton: 'Отмена',
    sendButton: 'Отправить',
    closeButton: 'Закрыть',
    commentPlaceholder: 'Введите свой комментарий'
})
