import React from 'react'

const SvgDownload = props => (
    <svg width="1em" height="1em" viewBox="0 0 18 20" {...props}>
        <path d="M15.259 20H2.74a2.889 2.889 0 01-1.937-.731A2.387 2.387 0 010 17.507V2.493c0-.66.29-1.295.804-1.762A2.889 2.889 0 012.74 0h8.211c.108 0 .215.02.314.057.1.037.19.092.267.161l6.227 5.666c.076.07.136.152.178.242.04.09.062.188.062.286v11.094c0 .662-.29 1.295-.804 1.763A2.89 2.89 0 0115.26 20zM2.74 1.493c-.292 0-.571.105-.777.293a.958.958 0 00-.323.707v15.014c0 .265.116.52.323.707.206.188.485.293.777.294H15.26c.291 0 .571-.106.777-.294a.958.958 0 00.323-.707V6.721L10.61 1.493H2.741z" />
        <path d="M17.18 7.158h-4.31a2.889 2.889 0 01-1.937-.73 2.387 2.387 0 01-.804-1.763V.746c0-.198.087-.387.24-.527A.864.864 0 0110.95 0c.218 0 .427.079.581.219.154.14.24.33.24.527v3.92c0 .264.117.519.323.706.206.188.486.293.777.294h4.31c.217 0 .426.078.58.218.153.14.24.33.24.528a.714.714 0 01-.24.528.864.864 0 01-.58.218zM9 16.269a.893.893 0 01-.315-.057.83.83 0 01-.266-.162L5.44 13.34a.745.745 0 01-.178-.242.688.688 0 010-.571.745.745 0 01.178-.242.83.83 0 01.266-.162.892.892 0 01.628 0c.1.037.19.092.266.162l2.4 2.18 2.397-2.18a.83.83 0 01.267-.162.893.893 0 01.628 0c.1.037.19.092.266.162.076.07.136.151.178.242a.689.689 0 010 .571.745.745 0 01-.178.242l-2.979 2.706a.83.83 0 01-.266.163.891.891 0 01-.314.059z" />
        <path d="M9 15.771a.864.864 0 01-.581-.218.714.714 0 01-.24-.528v-5.92c0-.198.086-.388.24-.528a.864.864 0 01.58-.219c.218 0 .426.079.58.219.154.14.24.33.24.527v5.92a.714.714 0 01-.24.529.864.864 0 01-.58.218z" />
    </svg>
)

export default SvgDownload
