import React from 'react'

const SvgChecked = props => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" {...props}>
        <g transform="translate(-13 149)" fillRule="nonzero">
            <rect
                transform="rotate(42 716 363)"
                x={664}
                y={-78}
                width={104}
                height={882}
                rx={30.5}
            />
            <rect
                transform="rotate(-48 266 479.5)"
                x={213}
                y={170}
                width={106}
                height={619}
                rx={31}
            />
        </g>
    </svg>
)

export default SvgChecked
