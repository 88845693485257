import { OCTStatus } from '~/api'

export default {

    // [P2PStatus.auth]: {
    //     color: '#FFB600'
    // },
    [OCTStatus.charged]: {
        color: '#05B9A6'
    },
    // [P2PStatus.cancelled]: {
    //     color: '#BDBDBD'
    // },
    // [P2PStatus.refunded]: {
    //     color: '#F42B74'
    // },
    [OCTStatus.rejected]: {
        color: '#F42B74'
    }
}
