import locale from '~/utils/locale'

export default locale.create<{
    title: string
    cols: {
        id: string
        orderType: string
        orderStatus: string
    }
}>()
