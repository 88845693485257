import locale from '~/utils/locale'

export default locale.create<{
    title: string
    role: {
        admin: string
        manager: string
        accountant: string
        view: string
    }
    status: {
        active: string
        waitingForRegistration: string
    }
    cols: {
        email: string
        role: string
        status: string
        lastConnections: string
        control: string
        controlsTooltip: {
            edit: string
            delete: string
        }
    }
    invite: string
    okText: string
    cancelText: string
    deleteConfirmTitle: string
    deleteConfirmContent: string
    deleteNotificationTitle: string
    deleteNotificationDescription: string
    emailExists: string
    emailExistsDescription: string
    newEmployeeCreatedNotificationTitle: string
    newEmployeeCreatedNotificationDescription: (email: string) => string
    employeeRoleUpdatedTitle: string
    employeeRoleUpdatedDescription: string

}>()
