import React from 'react'

import { computed } from 'mobx'
import { observer } from 'mobx-react'

import { Avatar, Button } from 'antd'

import { Layout, Menu } from '~/components'
import {
    LogoInvertedIcon,
    LogoutDoorIcon,
    LogoutIcon,
    UserIcon
} from '~/components/icon'

import actions from '~/actions'
import AppStore from '~/app-store'
import auth from '~/services/auth'

import LayoutStore from './layout-store'

import res from './res'

import layoutStyles from '~/layouts/main/styles.css'
import styles from './header.css'

export const userMenu = computed(() => (
    <Menu>
        <Menu.Item key="profile" onClick={() => actions.openProfile()}>
            <UserIcon />
            {res().profile}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="signOut" onClick={() => auth.logout()}>
            <LogoutIcon />
            {res().logOut}
        </Menu.Item>
    </Menu>
))

const Header = observer(
    (props: { appStore: typeof AppStore; layoutStore: typeof LayoutStore }) => {
        const { layoutStore } = props

        return (
            <Layout.Header className={styles.mobileMenuHeader}>
                <div
                    className={styles.wrapper}
                    ref={el => layoutStore.setHeaderComponent(el)}
                >
                    <div className={styles.mobileLogo}>
                        <LogoInvertedIcon />
                    </div>
                    <div className={styles.iconPic}>
                        <Button
                            shape="circle"
                            className={styles.profileButton}
                            onClick={() => actions.openProfile()}
                        >
                            <Avatar icon="user" />
                        </Button>
                        <Button
                            shape="circle"
                            className={styles.logoutButton}
                            key="signOut"
                            onClick={() => auth.logout()}
                        >
                            <LogoutDoorIcon
                                className={layoutStyles.secondaryLink}
                            />
                        </Button>
                    </div>
                    <div className={`${layoutStyles.floatBlock}`}></div>
                </div>
            </Layout.Header>
        )
    }
)

export default Header
