import actions from '~/actions'
import React, { ReactNode } from 'react'
import res from './res'
import { openSiderOnTablets, closeSiderOnTablets } from './utils'
import InteractiveHintsStore from './interactive-hints-store'
import appStore from '~/app-store'
import layoutStore from '~/layouts/main/layout-store'

export const interactiveHintId = 'interactive-hint'

export const MEDIA_SCREEN_BREAKPOINT = 980

export type HintPlacement = 'right' | 'left' | 'bottom' | 'top'

export enum HintType {
    modal = 'modal',
    sider = 'sider980'
}

export const sectionsOrder = [
    'generalInformation',
    'dashboard',
    'transactions',
    'statements',
    'paymentLinks',
    'credit',
    'configureEpayTemplate',
    'terminalSettings',
    'team'
]

export interface Section {
    goToPage: () => void | Promise<void>
    title: string
    steps: Step[]
}

export interface Step {
    description: string | ReactNode
    target: string
    placement: HintPlacement
    onBeforeShow?: () => Promise<void>
    onAfterHide?: () => Promise<void>
    type?: HintType
}

const sectionsDesktop = (
    hintStore: InteractiveHintsStore
): { [key: string]: Section } => {

    return {
        generalInformation: {
            goToPage: () => {
                actions.openHome()
            },
            title: res().sectionsDesktop.generalInformation.title,
            steps: [
                {
                    target: '.hint-section-1-step-1',
                    placement: 'right',
                    description: res().sectionsDesktop.generalInformation.steps[0],
                    type: HintType.sider,
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        layoutStore.toggleSider(true)
                        openSiderOnTablets()
                        resolve()
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        closeSiderOnTablets()
                        resolve()
                    })
                },
                {
                    target: '.hint-page',
                    placement: 'left',
                    description: res().sectionsDesktop.generalInformation.steps[1]
                },
                {
                    target: '.hint-section-1-step-3',
                    placement: 'bottom',
                    description: res().sectionsDesktop.generalInformation.steps[2]
                }
            ]
        },
        dashboard: {
            goToPage: () => {
                actions.openHome()
            },
            title: res().sectionsDesktop.dashboard.title,
            steps: [
                {
                    target: '.hint-section-2-step-1',
                    placement: 'right',
                    description: res().sectionsDesktop.dashboard.steps[0],
                    type: HintType.sider,
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        layoutStore.toggleSider(true)
                        openSiderOnTablets()
                        resolve()
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        closeSiderOnTablets()
                        resolve()
                    })
                },
                {
                    target: '.hint-page',
                    placement: 'left',
                    description: res().sectionsDesktop.dashboard.steps[1]
                }
            ]
        },
        transactions: {
            goToPage: () => new Promise<void>((resolve) => {
                actions.openPayments()
                appStore.getSuccessfulEpayPayment().then((isOk) => {
                    if (!isOk) {
                        hintStore.showTransactionsWarningModal()
                    }
                    resolve()
                })
            }),
            title: res().sectionsDesktop.transactions.title,
            steps: [
                {
                    target: '.hint-section-3-step-1',
                    placement: 'right',
                    description: res().sectionsDesktop.transactions.steps[0],
                    type: HintType.sider,
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        layoutStore.toggleSider(true)
                        layoutStore.openSubMenu(['sub_menu_transactions'])
                        openSiderOnTablets()
                        setTimeout(() => {
                            resolve()
                        }, 300)
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        closeSiderOnTablets()
                        resolve()
                    })
                },
                {
                    target: '.hint-page',
                    placement: 'left',
                    description: res().sectionsDesktop.transactions.steps[1]
                },
                {
                    target: '.hint-section-4-step-2',
                    placement: 'bottom',
                    description: res().sectionsDesktop.transactions.steps[2]
                },
                {
                    target: '.hint-section-4-step-3',
                    placement: 'bottom',
                    description: res().sectionsDesktop.transactions.steps[3],
                    type: HintType.modal,
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        appStore.openPaymentsColumnControlModal()
                        setTimeout(() => {
                            resolve()
                        }, 300)
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        appStore.closePaymentsColumnControlModal()
                        setTimeout(() => {
                            resolve()
                        }, 200)
                    })
                },
                {
                    target: '.hint-section-4-step-4',
                    placement: 'bottom',
                    description: res().sectionsDesktop.transactions.steps[4]
                },
                {
                    // target: '.hint-section-4-step-5',
                    target: '.hint-section-4-step-5-2',
                    placement: 'bottom',
                    description: res().sectionsDesktop.transactions.steps[5],
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        document.querySelector('.hint-section-4-step-5').classList.add('highlight-z-index')
                        appStore.openPaymentSearchFilter()
                        setTimeout(() => resolve(), 300)
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        document.querySelector('.hint-section-4-step-5').classList.remove('highlight-z-index')
                        appStore.closePaymentSearchFilter()
                        setTimeout(() => resolve(), 200)
                    })
                },
                {
                    target: '.hint-section-4-step-6:first-child',
                    placement: 'bottom',
                    description: res().sectionsDesktop.transactions.steps[6]
                },
                {
                    target: '.hint-section-4-step-7 .ant-drawer-content',
                    placement: 'left',
                    description: res().sectionsDesktop.transactions.steps[7],
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        appStore.openDetailedPayment()
                        setTimeout(() => resolve(), 200)
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        appStore.closeDetailedPayment()
                        setTimeout(() => resolve(), 200)
                    })
                },
                {
                    target: '.hint-section-4-step-6:first-child td:first-child',
                    placement: 'right',
                    description: res().sectionsDesktop.transactions.steps[8]
                },
                {
                    target: '.hint-section-4-step-9',
                    placement: 'bottom',
                    description: res().sectionsDesktop.transactions.steps[9],
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        appStore.selectSuccessfulPayment()
                        setTimeout(() => resolve(), 500)
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        appStore.deselectSuccessfulPayment()
                        setTimeout(() => resolve(), 200)
                    })
                }
            ]
        },
        statements: {
            goToPage: () => {
                actions.openStatements()
            },
            title: res().sectionsDesktop.statements.title,
            steps: [
                {
                    target: '.hint-section-5-step-1',
                    placement: 'right',
                    description: res().sectionsDesktop.statements.steps[0],
                    type: HintType.sider,
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        layoutStore.toggleSider(true)
                        openSiderOnTablets()
                        resolve()
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        closeSiderOnTablets()
                        resolve()
                    })
                },
                {
                    target: '.hint-page',
                    placement: 'bottom',
                    description: res().sectionsDesktop.statements.steps[1],
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        actions.openStatements()
                        setTimeout(() => {
                            resolve()
                        }, 500)
                    })
                },
                {
                    target: '.hint-section-5-step-3',
                    placement: 'bottom',
                    description: res().sectionsDesktop.statements.steps[2],
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        appStore.openFirstStatement()
                        setTimeout(() => {
                            resolve()
                        }, 500)
                    })
                },
                {
                    target: '.hint-section-5-step-4',
                    placement: 'left',
                    description: res().sectionsDesktop.statements.steps[3],
                    type: HintType.modal,
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        appStore.toggleStatementModal()
                        setTimeout(() => {
                            resolve()
                        }, 200)
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        appStore.toggleStatementModal()
                        setTimeout(() => {
                            resolve()
                        }, 200)
                    })
                },
                {
                    target: '.hint-section-5-step-5',
                    placement: 'right',
                    description: res().sectionsDesktop.statements.steps[4]
                },
                {
                    target: '.hint-section-5-step-4',
                    placement: 'right',
                    description: res().sectionsDesktop.statements.steps[5],
                    type: HintType.modal,
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        appStore.toggleStatementConfigForm()
                        setTimeout(() => {
                            resolve()
                        }, 200)
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        appStore.toggleStatementConfigForm()
                        setTimeout(() => {
                            resolve()
                        }, 200)
                    })
                }
            ]
        },
        paymentLinks: {
            goToPage: () => {
                actions.openPaymentLinks()
            },
            title: res().sectionsDesktop.paymentLinks.title,
            steps: [
                {
                    target: '.hint-section-6-step-1',
                    placement: 'right',
                    description: res().sectionsDesktop.paymentLinks.steps[0],
                    type: HintType.sider,
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        layoutStore.toggleSider(true)
                        openSiderOnTablets()
                        resolve()
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        closeSiderOnTablets()
                        resolve()
                    })
                },
                {
                    target: '.hint-page',
                    placement: 'bottom',
                    description: res().sectionsDesktop.paymentLinks.steps[1]
                },
                {
                    target: '.hint-section-6-step-3',
                    placement: 'bottom',
                    description: res().sectionsDesktop.paymentLinks.steps[2]
                },
                {
                    target: '.hint-section-6-step-4',
                    placement: 'bottom',
                    description: res().sectionsDesktop.paymentLinks.steps[3],
                    type: HintType.modal,
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        appStore.togglePaymentLinksModal()
                        setTimeout(() => {
                            resolve()
                        }, 300)
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        appStore.togglePaymentLinksModal()
                        setTimeout(() => {
                            resolve()
                        }, 300)
                    })
                }
            ]
        },
        credit: {
            goToPage: () => {
                actions.openProductsCredit()
            },
            title: res().sectionsDesktop.credit.title,
            steps: [
                {
                    target: '.hint-section-7-step-1',
                    placement: 'right',
                    description: res().sectionsDesktop.credit.steps[0],
                    type: HintType.sider,
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        layoutStore.toggleSider(true)
                        layoutStore.openSubMenu(['sub_menu_banksProducts'])
                        openSiderOnTablets()
                        setTimeout(() => resolve(), 300)
                    })
                },
                {
                    target: '.hint-section-7-step-1',
                    placement: 'right',
                    description: res().sectionsDesktop.credit.steps[1],
                    type: HintType.sider,
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        layoutStore.toggleSider(true)
                        layoutStore.openSubMenu(['sub_menu_banksProducts'])
                        openSiderOnTablets()
                        setTimeout(() => resolve(), 300)
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        closeSiderOnTablets()
                        setTimeout(() => resolve(), 200)
                    })
                },
                {
                    target: '.hint-page',
                    placement: 'left',
                    description: res().sectionsDesktop.credit.steps[2]
                },
                {
                    target: '.hint-section-7-step-3',
                    placement: 'bottom',
                    description: res().sectionsDesktop.credit.steps[3]
                },
                {
                    target: '.hint-section-7-step-4',
                    placement: 'bottom',
                    description: res().sectionsDesktop.credit.steps[4],
                    type: HintType.modal,
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        appStore.toggleCreditProductsModal()
                        setTimeout(() => resolve(), 210)
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        appStore.toggleCreditProductsModal()
                        setTimeout(() => resolve(), 300)
                    })
                }
            ]
        },
        configureEpayTemplate: {
            goToPage: () => {
                actions.openSettingsTemplate()
            },
            title: res().sectionsDesktop.configureEpayTemplate.title,
            steps: [
                {
                    target: '.hint-section-8-step-1',
                    placement: 'right',
                    description: res().sectionsDesktop.configureEpayTemplate.steps[0],
                    type: HintType.sider,
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        layoutStore.toggleSider(true)
                        layoutStore.openSubMenu(['sub_menu_settings'])
                        openSiderOnTablets()
                        setTimeout(() => resolve(), 300)
                    })
                },
                {
                    target: '.hint-section-8-step-1',
                    placement: 'right',
                    description: res().sectionsDesktop.configureEpayTemplate.steps[1],
                    type: HintType.sider,
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        layoutStore.toggleSider(true)
                        layoutStore.openSubMenu(['sub_menu_settings'])
                        openSiderOnTablets()
                        resolve()
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        closeSiderOnTablets()
                        resolve()
                    })
                },
                {
                    target: '.hint-page',
                    placement: 'right',
                    description: res().sectionsDesktop.configureEpayTemplate.steps[2]
                }
            ]
        },
        terminalSettings: {
            goToPage: () => {
                actions.openSettingsTerminal()
            },
            title: res().sectionsDesktop.terminalSettings.title,
            steps: [
                {
                    target: '.hint-section-8-step-1',
                    placement: 'right',
                    description: res().sectionsDesktop.terminalSettings.steps[0],
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        layoutStore.toggleSider(true)
                        layoutStore.openSubMenu(['sub_menu_settings'])
                        openSiderOnTablets()
                        setTimeout(() => resolve(), 300)
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        closeSiderOnTablets()
                        resolve()
                    })
                },
                {
                    target: '.hint-section-8-step-2',
                    placement: 'bottom',
                    description: res().sectionsDesktop.terminalSettings.steps[1]
                },
                {
                    target: '.hint-section-8-step-3',
                    placement: 'bottom',
                    description: res().sectionsDesktop.terminalSettings.steps[2]
                },
                {
                    target: '.hint-section-8-step-4',
                    placement: 'bottom',
                    description: res().sectionsDesktop.terminalSettings.steps[3]
                }
            ]
        },
        team: {
            goToPage: () => {
                actions.openSettingsEmployees()
            },
            title: res().sectionsDesktop.team.title,
            steps: [
                {
                    target: '.hint-section-8-step-1',
                    placement: 'right',
                    description: res().sectionsDesktop.team.steps[0],
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        layoutStore.toggleSider(true)
                        layoutStore.openSubMenu(['sub_menu_settings'])
                        openSiderOnTablets()
                        setTimeout(() => resolve(), 300)
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        closeSiderOnTablets()
                        resolve()
                    })
                },
                {
                    target: '.hint-page',
                    placement: 'bottom',
                    description: res().sectionsDesktop.team.steps[1]
                },
                {
                    target: '.hint-section-9-step-3',
                    placement: 'bottom',
                    description: res().sectionsDesktop.team.steps[2]
                },
                {
                    target: '.hint-section-9-step-4',
                    placement: 'bottom',
                    description: res().sectionsDesktop.team.steps[3],
                    type: HintType.modal,
                    onBeforeShow: () => new Promise<void>((resolve) => {
                        appStore.openTeamInvitationModal()
                        setTimeout(() => resolve(), 300)
                    }),
                    onAfterHide: () => new Promise<void>((resolve) => {
                        appStore.closeTeamInvitationModal()
                        setTimeout(() => resolve(), 300)
                    })
                }
            ]
        }
    }
}

export const getDesktopSections = (
    hintStore: InteractiveHintsStore,
    sectionsKeys: string[]
): Section[] => {

    const sections = sectionsDesktop(hintStore)

    return sectionsKeys.map((key) => {
        return sections[key]
    })
}
