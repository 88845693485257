import React, { ReactNode } from 'react'

import { ArrowLeftIcon, CheckIcon, CloseModalIcon } from '../icon'

import { Button } from '../index'

import layoutStyles from '~/layouts/main/styles.css'

import mainRes from '~/layouts/main/res'

export interface MobileHeaderProps {
    onCancel?: () => void
    onOk?: () => void
    isOkDisabled?: () => boolean
    title?: string | ReactNode
    subTitle?: string | ReactNode
    okElement?: string | ReactNode
    rightControl?: string | ReactNode
}

export default (props: MobileHeaderProps) => (
    <div className={layoutStyles.mobileDrawerHeader}>
        <div className={`${layoutStyles.mobileHeaderContainer}`}>
            <Button
                className={`${layoutStyles.drawerButton}`}
                size="large"
                ghost
                onClick={props.onCancel}
            >
                <ArrowLeftIcon></ArrowLeftIcon>
            </Button>
            {props.onOk && (
                <Button
                    className={`${layoutStyles.drawerButton +
                        ' ' +
                        layoutStyles.lastButton}`}
                    type="primary"
                    ghost
                    size="large"
                    disabled={props.isOkDisabled ? props.isOkDisabled() : false}
                    onClick={props.onOk}
                >
                    {props.okElement && props.okElement}
                    {!props.okElement && (
                        <React.Fragment>{mainRes().applyButton}</React.Fragment>
                    )}
                </Button>
            )}
            <div className={`${layoutStyles.floatBlock}`}></div>
        </div>
        {props.title && (
            <div className={`${layoutStyles.drawerTitle}`}>
                <div>
                    {props.title}
                    {props.rightControl && (
                        <span className={`${layoutStyles.rightControl}`}>
                            {props.rightControl}
                        </span>
                    )}
                </div>
                {props.subTitle && (
                    <div className={`${layoutStyles.drawerSubTitle}`}>
                        {props.subTitle}
                    </div>
                )}
            </div>
        )}
    </div>
)
