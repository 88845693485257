import { notification } from 'antd'
import { action, computed, observable, runInAction, toJS } from 'mobx'
import { apiCheck, getShops, Shop } from '~/api'
import { noThrow } from '~/utils/control-flow'
import message from '~/utils/message'
import { AssetsAPI, getAssets, ColorSchemeAPI, updateAssets } from '~/api/epay-template'
import res from './res'
import FieldStore from '~/utils/field-store'
import { email, phone, required } from '~/utils/validation'
import { ColorScheme, NameMap, stdBgGradient, stdCardBgGradient } from '~/pages/settings/configure-epay-template/colors'
import BrowserStore from '~/services/browser/browser-state'
import InteractiveMobileHintsStore from '~/components/interactive-mobile-hints/interactive-mobile-hints-store'
import InteractiveHint from '~/utils/interactive-hint'

interface Assets {
    shop_name: string
    logo_url: string
    logo?: string
    logo_file_name?: string
    lang: string
    email: string
    phone: string
    color_scheme: ColorScheme
}

export default class EpayTemplateStore {
    constructor() {
        this.loadData()

        setTimeout(() => {
            this.showHintOnMobile()
        }, 500)
    }

    public shopName = new FieldStore<string>(
        [required(() => res().messages.valueRequired)],
        this.onChange
    )
    public email = new FieldStore<string>(
        [
            required(() => res().messages.valueRequired),
            email(() => res().messages.invalidEmail)
        ],
        this.onChange
    )
    public lang = new FieldStore<string>(
        [required(() => res().messages.valueRequired)],
        this.onChange
    )

    public phone = new FieldStore<string>(
        [
            required(() => res().messages.valueRequired),
            phone(res().messages.invalidPhone)
        ],
        this.onChange
    )

    @observable
    public isLoading: boolean

    @observable
    public isModified: boolean

    @observable
    public isError: boolean

    @computed
    public get isColorSectionExpanded() {
        return this.assets.color_scheme.scheme_name === 'custom'
    }

    @observable
    public assets: Assets = {
        color_scheme: {} as any
    } as any

    @observable
    public shops: Shop[] = []

    @observable
    public currentShopId: string

    @observable.ref
    public mobileHintStore: InteractiveMobileHintsStore

    @action
    public showHints(sectionKeys: string[]) {
        this.mobileHintStore = new InteractiveMobileHintsStore(sectionKeys)
    }

    @action
    public hideHints() {
        this.mobileHintStore = null
    }

    @action
    public async loadAssets(shopId: string) {
        this.isLoading = true
        const { value, error }: { value?: AssetsAPI; error?: any } = await noThrow(
            getAssets(shopId)
        )

        runInAction(() => {
            if (error) {
                // do not hide form, if some prev data was loaded successfully
                if (!this.assets.shop_name) this.isError = true
                message.error(error)
            } else {
                this.updateAssets(value)

                this.currentShopId = shopId
                this.isError = false
                this.isModified = false
                this.isLoading = false
            }
        })
    }

    @action.bound
    public async updateData() {
        this.isLoading = true
        const colorScheme: ColorSchemeAPI = {
            bg: JSON.stringify(this.assets.color_scheme.bg),
            buttons: this.assets.color_scheme.buttons,
            card_bg: JSON.stringify(this.assets.color_scheme.card_bg),
            card_text: this.assets.color_scheme.card_text,
            text: this.assets.color_scheme.text,
            scheme_name: this.assets.color_scheme.scheme_name
        }
        const newAssets = { ...this.assets, color_scheme: colorScheme }

        if (newAssets.logo) {
            newAssets.logo_url = undefined
        }

        const { value, error }: { value?: AssetsAPI; error?: any } = await noThrow(
            updateAssets(newAssets, this.currentShopId)
        )

        runInAction(() => {
            if (error) {
                message.error(error)
            } else {
                this.updateAssets(value)

                this.isError = false
                this.isModified = false
                this.isLoading = false

                notification.success({
                    message: res().messages.epayTemplateUpdated
                })
            }
        })
    }

    @action
    public updateCustomBg(color1: string, color2: string) {

        this.assets.color_scheme.bg.color1 = color1
        this.assets.color_scheme.bg.color2 = color2
        this.assets.color_scheme.bg.gradient = stdBgGradient(color1, color2)

        this.isModified = true
    }

    @action
    public updateCustomCardBg(color1: string, color2: string) {

        this.assets.color_scheme.card_bg.color1 = color1
        this.assets.color_scheme.card_bg.color2 = color2
        this.assets.color_scheme.card_bg.gradient = stdCardBgGradient(color1, color2)

        this.isModified = true
    }

    private updateAssets(value: AssetsAPI) {

        if (!value) return

        this.assets = {
            shop_name: value.shop_name,
            logo_url: value.logo_url,
            lang: value.lang,
            email: value.email,
            phone: value.phone,

            color_scheme: {
                bg: JSON.parse(value?.color_scheme?.bg),
                card_bg: JSON.parse(value?.color_scheme?.card_bg),
                buttons: value?.color_scheme?.buttons,
                text: value?.color_scheme?.text,
                card_text: value?.color_scheme?.card_text,
                scheme_name: undefined // TBD later
            }
        }

        this.shopName.set(this.assets.shop_name)
        this.email.set(this.assets.email)
        this.phone.set(this.assets.phone)
        this.lang.set(this.assets.lang)

        this.setSchemeName()
    }

    @action.bound
    private setSchemeName() {
        const cs = this.assets.color_scheme

        for (const [k, v] of Object.entries(NameMap)) {
            if (
                v.card_text === cs.card_text &&
                v.text === cs.text &&
                v.bg.gradient === cs.bg.gradient &&
                v.card_bg.gradient === cs.card_bg.gradient &&
                v.buttons === cs.buttons
            ) {
                cs.scheme_name = k as any
            }
        }

        if (!cs.scheme_name) {
            cs.scheme_name = 'custom'
        }
    }

    @action.bound
    private async loadData() {

        this.isLoading = true

        const shopList = await noThrow(apiCheck(getShops()))

        if (shopList.error || !shopList.value?.length) {
            notification.error({
                message: res().messages.errorTitle,
                description: res().messages.cannotLoadShops,
                duration: 0
            })

            runInAction(() => {
                this.isError = true
            })

            return
        }

        runInAction(() => {
            this.shops = shopList.value
            this.currentShopId = this.shops[0].id
        }
        )

        await this.loadAssets(this.shops[0].id)

        runInAction(() => {
            this.isLoading = false
        })
    }

    @action.bound
    private onChange() {
        this.isModified = true
    }

    private showHintOnMobile() {
        if (BrowserStore.isMobile) {
            const hintSections = InteractiveHint.getPageFlags('configureEpayTemplate')

            if (hintSections) {
                this.showHints(hintSections)
            }
        }
    }
}
