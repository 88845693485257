import {
    AutoComplete,
    Button,
    Form,
    Input,
    notification,
    Radio,
    Select,
    Switch,
    Slider
} from 'antd'
import type { SliderMarks } from 'antd/lib/slider'
import React, { useEffect, useState } from 'react'
import { useForm, Controller, SubmitHandler, useWatch } from 'react-hook-form'
import res from '../res'
import styles from '../styles.css'
import mainStyles from '~/layouts/main/commonStyles.css'
import { LoadingOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { GeneratePaymentLinkBody, MerchantShop } from '..'
import SelectField from './SelectField'
import config from '~/config'
import { AlertLanguages, PaymentLink } from '~/api/payment-links'
import { MaskedInput } from 'antd-mask-input'
import message from '~/utils/message'
import layoutStyles from '~/layouts/main/styles.css'

import amplitude from 'amplitude-js'

interface FormInput {
    shop_id: string
    account_id: string
    invoice_id: string
    amount: string
    language: AlertLanguages
    description: string
    recipient_contact: string
    recipient_contact_sms: string
    notifier_contact: string
    notifier_contact_sms: string
    expire_period: number
    invoice_alt: string
}

function CreateInvoiceForm({
                               onApply,
                               onClose,
                               showConfirmation
                           }: {
    onApply: (requestBody: GeneratePaymentLinkBody) => Promise<PaymentLink>
    onClose: () => void
    showConfirmation: (newPaymentLink: PaymentLink) => void
}) {
    const formItemLayout = {
        labelCol: {
            xs: { span: 28 },
            sm: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 28 },
            sm: { span: 16 }
        }
    }

    const errorStyle = { color: 'red' }

    const DESCRIPTION_TEXT_LIMIT = 125

    const { handleSubmit, control, errors, reset, getValues } = useForm<FormInput>()
    const [shops, setShops] = useState<MerchantShop[]>([])
    const [notifierSMSSelected, setNotifierSMSSelected] = useState(false)
    const [sendingRequest, setSendingRequest] = useState(false)
    const emails = useWatch({ name: 'recipient_contact', control })
    const phoneNumber = useWatch({ name: 'recipient_contact_sms', control })

    const onSubmit: SubmitHandler<FormInput> = async (data: FormInput) => {
        setSendingRequest(true)
        let requestBody: GeneratePaymentLinkBody
        try {
            const recipientContactSMS = `+7${data.recipient_contact_sms.replace(
                /\D/g,
                ''
            )}`
            let notifierContactSMS: string
            if (data.notifier_contact_sms) {
                notifierContactSMS = `+7${data.notifier_contact_sms.replace(
                    /\D/g,
                    ''
                )}`
            } else {
                notifierContactSMS = ''
            }
            requestBody = {
                ...data,
                amount: parseFloat(data.amount),
                notifier_contact_sms: notifierContactSMS,
                recipient_contact_sms: data?.recipient_contact_sms ? recipientContactSMS : '',
                expire_period: `${data.expire_period}d`,
                currency: 'KZT',
                postLink: '',
                failurePostLink: ''
            }
            const newPaymentLink = await onApply(requestBody)
            setSendingRequest(false)
            if (newPaymentLink.hasOwnProperty('code')) {
                if (newPaymentLink.code === 1293) {
                    throw new Error(res().duplicateOrderIdError)
                }
                amplitude
                    .getInstance()
                    .logEvent('payment_link_creation_failed', newPaymentLink)
                throw new Error(newPaymentLink.message)
            }
            amplitude.getInstance().logEvent('user_created_payment_link', {
                id: newPaymentLink.id
            })
            showConfirmation(newPaymentLink)
        } catch (error) {
            setSendingRequest(false)
            message.error(error)
        }
    }

    const prefillShopsFromApi = async () => {
        const { data } = JSON.parse(localStorage.getItem('auth'))

        const response = await fetch(`${config.api.baseUrl}dictionary/shop`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${data.access_token}`
            })
        })
        if (response.ok) {
            const shops = await response.json()
            if (shops) {
                setShops(shops)

                shops &&
                reset({
                    shop_id: shops[0].id.toString(),
                    account_id: '',
                    amount: '100',
                    invoice_id: '',
                    language: AlertLanguages.russian,
                    description: '',
                    recipient_contact: '',
                    recipient_contact_sms: '',
                    notifier_contact_sms: '',
                    notifier_contact: '',
                    expire_period: 1
                })
            } else {
                message.error({ error: 'Error' })
            }
        } else {
            notification.error({
                message: res().serverError
            })
        }
    }

    const expirePeriods: SliderMarks = {
        1: '1',
        2: '2',
        3: '3'
    }

    useEffect(() => {
        let active
        let timer
        const modal = document.querySelector('.ant-modal-wrap')
        if (modal) {
            modal.addEventListener('scroll', () => {
                document.querySelectorAll('.form-input').forEach(input => {
                    if (document.activeElement === input) {
                        active = input
                    }
                    (input as HTMLElement).blur()
                })
                document.querySelectorAll('#phone-input').forEach(input => {
                    if (document.activeElement === input) {
                        active = input
                    }
                    (input as HTMLElement).blur()
                })
                clearTimeout(timer)
                timer = setTimeout(() => active && active.focus(), 200)
            })
            return modal.removeEventListener('scroll', () => {
                document.querySelectorAll('.form-input').forEach(input => {
                    if (document.activeElement === input) {
                        active = input
                    }
                    (input as HTMLElement).blur()
                })
                document.querySelectorAll('#phone-input').forEach(input => {
                    if (document.activeElement === input) {
                        active = input
                    }
                    (input as HTMLElement).blur()
                })
                clearTimeout(timer)
                timer = setTimeout(() => active && active.focus(), 200)
            })
        }
    }, [])

    useEffect(() => {
        prefillShopsFromApi()
    }, [reset])

    useWatch({ name: 'description', control })
    const getEncodeDescLength = (text) => (new TextEncoder().encode(text)).length
    const descEncodeLength = getEncodeDescLength(getValues('description'))

    return (
        <>
            <Form
                {...formItemLayout}
                className={styles.form}
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className={styles.section}>
                    <div className={mainStyles.subTitle}>
                        {res().generalData}
                    </div>
                    {shops ? (
                        <Form.Item label={res().chooseStoreLabel}>
                            <Controller
                                rules={{ required: true }}
                                control={control}
                                name="shop_id"
                                placeholder={res().chooseStoreLabel}
                                as={SelectField(shops[0], shops)}
                                defaultValue={shops[0]}
                            />
                            {errors.shop_id && (
                                <span style={errorStyle}>
                                    {res().invalidShop}
                                </span>
                            )}
                        </Form.Item>
                    ) : (
                        <LoadingOutlined />
                    )}
                    <Form.Item label={res().formItemTitles.accountId}>
                        <Controller
                            style={{
                                borderColor: errors.account_id ? 'red' : ''
                            }}
                            control={control}
                            type="text"
                            as={<Input className="form-input" />}
                            rules={{
                                minLength: 6,
                                maxLength: 20,
                                validate: {
                                    doesNotContainCharacters: value =>
                                        !RegExp(/[A-Za-z]+$/).test(value) ||
                                        'Must not contain characters'
                                }
                            }}
                            name="account_id"
                            defaultValue=""
                            placeholder={res().accountIdPlaceholder}
                        />
                        {errors.account_id && (
                            <span style={errorStyle}>
                                {res().invalidAccountId}
                            </span>
                        )}
                    </Form.Item>
                    <Form.Item label={res().formItemTitles.orderNumber}>
                        <Controller
                            style={{
                                borderColor: errors.invoice_id ? 'red' : ''
                            }}
                            control={control}
                            type="text"
                            as={<Input className="form-input" />}
                            rules={{
                                required: true,
                                minLength: 8,
                                maxLength: 16,
                                validate: {
                                    doesNotContainCharacters: value =>
                                        /^\d+$/g.test(value) ||
                                        'Must be numbers only'
                                }
                            }}
                            name="invoice_id"
                            defaultValue=""
                            placeholder={res().invoicePlaceholder}
                        />
                        {errors.invoice_id && (
                            <span style={errorStyle}>
                                {res().invalidOrderId}
                            </span>
                        )}
                    </Form.Item>
                    <Form.Item label={res().formItemTitles.invoiceAlt}>
                        <Controller
                            style={{
                                borderColor: errors.invoice_alt ? 'red' : ''
                            }}
                            control={control}
                            type="text"
                            as={<Input className="form-input" />}
                            name="invoice_alt"
                            defaultValue=""
                            placeholder={res().invoiceAltPlaceholder}
                        />
                        {errors.invoice_alt && (
                            <span style={errorStyle}>
                                {res().invalidOrderId}
                            </span>
                        )}
                    </Form.Item>
                    <Form.Item label={res().formItemTitles.orderSum}>
                        <Controller
                            style={{ borderColor: errors.amount ? 'red' : '' }}
                            control={control}
                            type="text"
                            as={<Input className="form-input" />}
                            rules={{
                                required: true,
                                min: 100,
                                pattern: /^[-]?([1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|\.[0-9]{1,2})$/
                            }}
                            name="amount"
                            defaultValue={100.0}
                        />
                        {errors.amount && (
                            <span style={errorStyle}>
                                {res().invalidAmount}
                            </span>
                        )}
                    </Form.Item>
                    <Form.Item label={res().formItemTitles.language}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="language"
                            defaultValue={AlertLanguages.russian}
                            render={props => {
                                return (
                                    <Radio.Group
                                        buttonStyle="solid"
                                        className={styles.radioGroup}
                                        value={props.value}
                                        onChange={e =>
                                            props.onChange(e.target.value)
                                        }
                                    >
                                        <Radio.Button key={1} value="kaz">
                                            KZ
                                        </Radio.Button>
                                        <Radio.Button key={2} value="rus">
                                            RU
                                        </Radio.Button>
                                        <Radio.Button key={3} value="eng">
                                            EN
                                        </Radio.Button>
                                    </Radio.Group>
                                )
                            }}
                        />
                        {errors.language && (
                            <span style={errorStyle}>
                                Language must be selected
                            </span>
                        )}
                    </Form.Item>
                    <Form.Item label={`${res().formItemTitles.description} (${descEncodeLength}/${DESCRIPTION_TEXT_LIMIT})`}>
                        <Controller
                            style={{
                                borderColor: errors.description?.message ? 'red' : ''
                            }}
                            name="description"
                            as={Input.TextArea}
                            control={control}
                            rules={{
                                required: res().descriptionRequired,
                                validate: {
                                    // encodedMaxLength: (value) => {
                                    //     if (getEncodeDescLength(value) <= DESCRIPTION_TEXT_LIMIT) return true
                                    //     return res().descriptionMaxLength
                                    // }
                                }
                            }}
                        />
                        {errors.description && (
                            <span style={errorStyle}>
                                {errors.description.message}
                            </span>
                        )}
                    </Form.Item>
                    <Form.Item label={res().formItemTitles.expirePeriod}>
                        <Controller
                            name="expire_period"
                            control={control}
                            defaultValue={1}
                            rules={{ required: true }}
                            render={props => (
                                <>
                                    <Slider
                                        onChange={e => props.onChange(e)}
                                        marks={expirePeriods}
                                        value={props.value}
                                        min={1}
                                        max={3}
                                    />
                                </>
                            )}
                        />
                        {errors.expire_period && (
                            <span style={errorStyle}>
                                Expire period must be selected
                            </span>
                        )}

                    </Form.Item>
                </div>
                <div className={styles.section}>
                    <div className={mainStyles.subTitle}>
                        {res().dataToSendLink}
                    </div>
                    <Form.Item label={res().formItemTitles.recieverEmail}>
                        <Controller
                            style={{
                                borderColor: errors.recipient_contact
                                    ? 'red'
                                    : ''
                            }}
                            name="recipient_contact"
                            as={
                                <Input
                                    className="form-input"
                                    autoComplete="off"
                                />
                            }
                            type="email"
                            control={control}
                            rules={{
                                required: !phoneNumber,
                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            }}
                        />
                        {errors.recipient_contact && (
                            <span style={errorStyle}>{res().invalidEmail}</span>
                        )}
                    </Form.Item>
                    <Form.Item label={res().formItemTitles.recieverPhone}>
                        <Controller
                            className={errors.recipient_contact_sms ? styles.maskedInputInvalid : null}
                            name="recipient_contact_sms"
                            as={
                                <MaskedInput
                                    mask="(111) 111-1111"
                                    id="phone-input"
                                />
                            }
                            type="tel"
                            control={control}
                            rules={{
                                required: !emails,
                                minLength: 14,
                                maxLength: 14
                            }}
                            prefix="+7"
                        />
                        {!emails && errors.recipient_contact_sms && (
                            <span style={errorStyle}>{res().invalidPhone}</span>
                        )}
                    </Form.Item>
                </div>
                {/*<div className={styles.section}>*/}
                {/*    <div*/}
                {/*        className={mainStyles.subTitle}*/}
                {/*        style={{*/}
                {/*            display: 'flex',*/}
                {/*            justifyContent: 'space-between',*/}
                {/*            alignItems: 'center'*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <span className="selectorTitle">*/}
                {/*            {res().paymentNotificationData}*/}
                {/*        </span>*/}
                {/*        <div>*/}
                {/*            <Switch*/}
                {/*                checkedChildren={<PhoneOutlined />}*/}
                {/*                unCheckedChildren={<MailOutlined />}*/}
                {/*                defaultChecked={notifierSMSSelected}*/}
                {/*                onChange={() =>*/}
                {/*                    setNotifierSMSSelected(!notifierSMSSelected)*/}
                {/*                }*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    {notifierSMSSelected ? (*/}
                {/*        <Form.Item label={res().formItemTitles.phone}>*/}
                {/*            <Controller*/}
                {/*                style={{*/}
                {/*                    borderColor: errors.notifier_contact_sms*/}
                {/*                        ? 'red'*/}
                {/*                        : ''*/}
                {/*                }}*/}
                {/*                name="notifier_contact_sms"*/}
                {/*                as={*/}
                {/*                    <MaskedInput*/}
                {/*                        mask="(111) 111-1111"*/}
                {/*                        id="phone-input"*/}
                {/*                    />*/}
                {/*                }*/}
                {/*                prefix="+7"*/}
                {/*                type="tel"*/}
                {/*                control={control}*/}
                {/*                rules={{ minLength: 14, maxLength: 14 }}*/}
                {/*            />*/}
                {/*        </Form.Item>*/}
                {/*    ) : (*/}
                {/*        <Form.Item label={res().formItemTitles.email}>*/}
                {/*            <Controller*/}
                {/*                style={{*/}
                {/*                    borderColor: errors.notifier_contact*/}
                {/*                        ? 'red'*/}
                {/*                        : ''*/}
                {/*                }}*/}
                {/*                name="notifier_contact"*/}
                {/*                as={<Input className="form-input" />}*/}
                {/*                type="email"*/}
                {/*                control={control}*/}
                {/*                rules={{*/}
                {/*                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/*/}
                {/*                }}*/}
                {/*            />*/}
                {/*            {errors.notifier_contact && (*/}
                {/*                <span style={errorStyle}>*/}
                {/*                    {res().invalidEmail}*/}
                {/*                </span>*/}
                {/*            )}*/}
                {/*        </Form.Item>*/}
                {/*    )}*/}
                {/*</div>*/}
                <div
                    className={`${layoutStyles.contentFooter} ${styles.footer}`}
                >
                    <Button htmlType="submit" size="large" type="primary">
                        {sendingRequest ? (
                            <LoadingOutlined />
                        ) : (
                            res().applyButton
                        )}
                    </Button>
                    <Button size="large" onClick={onClose}>
                        {res().closeButton}
                    </Button>
                </div>
            </Form>
        </>
    )
}

export default CreateInvoiceForm
