import { Checkbox } from 'antd'
import { CheckboxProps } from 'antd/lib/checkbox/Checkbox'
import { SelectionBoxProps } from 'antd/lib/table/interface'
import SelectionBox from 'antd/lib/table/SelectionBox'
import React, { ReactNode } from 'react'

function withLabelFor<T>(ComponentToWrap) {
    return class extends React.Component<T, any> {
        public componentRef: any

        public componentDidUpdate(prevProps, prevState) {
            if (
                this.componentRef &&
                this.componentRef.rcCheckbox &&
                this.componentRef.rcCheckbox.input.labels &&
                this.componentRef.rcCheckbox.input.labels[0] &&
                !this.componentRef.rcCheckbox.input.labels[0].getAttribute(
                    'for'
                )
            ) {
                const domElem: HTMLElement = this.componentRef.rcCheckbox.input
                const label: HTMLElement = this.componentRef.rcCheckbox.input
                    .labels[0]
                const isAuthoId = !this.componentRef.rcCheckbox.input.id
                const id = isAuthoId
                    ? 'autoid_' + Math.random()
                    : this.componentRef.rcCheckbox.input.id
                if (isAuthoId) {
                    label.setAttribute('for', id)
                }
                domElem.setAttribute('id', id)
            }
        }
        public render() {
            return (
                <ComponentToWrap
                    {...this.props}
                    ref={ref => (this.componentRef = ref)}
                ></ComponentToWrap>
            )
        }
    }
}

export const CheckBoxWithLabelFor = withLabelFor<CheckboxProps>(Checkbox)
