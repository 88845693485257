import * as ajax from '~/utils/ajax'

import { AuthStore } from './auth-store'

const store = new AuthStore()

ajax.auth.client = async () => {
    const client = await store.getClient()
    return client ? `${client.token_type} ${client.access_token}` : undefined
}

ajax.auth.user = async () => {
    const user = await store.getUser()
    return `${user.data.token_type} ${user.data.access_token}`
}

ajax.auth.refresh = () => store.refreshToken()

ajax.auth.logout = () => store.logout(false)

export default store
