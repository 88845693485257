import React from 'react'

const SvgParams = props => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" {...props}>
        <g fillRule="evenodd">
            <path d="M867.717 831.075h113.616c23.6 0 42.667 19.008 42.667 42.428 0 23.42-19.074 42.429-42.667 42.429H867.71C849.19 977.068 792.74 1022 725.333 1022c-67.408 0-123.9-44.932-142.375-106.068H42.667C19.117 915.932 0 896.924 0 873.503c0-23.42 19.117-42.428 42.667-42.428h540.291C601.475 769.932 657.925 725 725.333 725c67.414 0 123.911 44.932 142.384 106.075zm-206.384 42.428c0 35.09 28.72 63.64 64 63.64s64-28.55 64-63.64c0-35.089-28.72-63.646-64-63.646s-64 28.557-64 63.646zM867.71 109.071h113.623c23.593 0 42.667 19.01 42.667 42.43 0 23.421-19.074 42.428-42.667 42.428H867.71C849.203 255.07 792.747 300 725.333 300c-67.416 0-123.858-44.931-142.383-106.073H42.667C19.117 193.927 0 174.92 0 151.5c0-23.42 19.117-42.428 42.667-42.428H582.95C601.475 47.931 657.925 3 725.333 3 792.747 3 849.19 47.932 867.71 109.071zM661.333 151.5c0 35.09 28.72 63.644 64 63.644s64-28.554 64-63.644c0-35.089-28.72-63.643-64-63.643s-64 28.554-64 63.643zM441.05 459.072h540.283c23.6 0 42.667 19.007 42.667 42.428 0 23.42-19.074 42.429-42.667 42.429H441.042C422.525 605.068 366.075 650 298.667 650c-67.409 0-123.859-44.932-142.375-106.071H42.667C19.117 543.929 0 524.92 0 501.5c0-23.42 19.117-42.428 42.667-42.428h113.625C174.808 397.932 231.258 353 298.667 353c67.416 0 123.858 44.932 142.383 106.072zM234.667 501.5c0 35.09 28.716 63.644 64 63.644 35.283 0 64-28.554 64-63.644s-28.717-63.644-64-63.644c-35.284 0-64 28.555-64 63.644z" />
        </g>
    </svg>
)

export default SvgParams
