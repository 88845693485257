import { Skeleton } from 'antd'
import { Tooltip } from 'antd'
import map from 'lodash/map'
import { action, observable, toJS } from 'mobx'
import { observer } from 'mobx-react'
import React, { ReactNode } from 'react'
import {
    ContractStatementHistory,
    FrequencyType,
    HistoryItemStatus
} from '~/api/statements'
import appStyles from '~/app.css'
import { Button, ButtonDefault, Icon, IconButton, List, Spin } from '~/components'
import { Grid } from '~/components/grid'
import {
    ArrowIcon,
    ClockIcon,
    DownloadIcon,
    FormIcon,
    MailIcon
} from '~/components/icon'
import StatusIcon from '~/components/status-icon'
import Page from '~/layouts/main/page'
import ConfigForm from './components/statement-config-form'
import configRes from './components/statement-config-form/res'
import res from './res'
import StatementsTransferStore from './statements-transfer-store'
import styles from './styles.css'

import flatMap from 'lodash/flatMap'
import MobileHeader from '~/components/modal/mobile-header'
import ModalContainer, {
    ModalContext
} from '~/components/modal/modal-container'
import BrowserStore from '~/services/browser/browser-state'
import { renderDate, renderPeriod } from './constants/data-fields'

@observer
export class StatementTransferPage extends React.Component<
{ store: StatementsTransferStore },
{}
> {
    private get toolbar() {
        return (
            <>
                <div>{this.statementConfigContainer()}</div>
            </>
        )
    }

    @observable
    public menuVisible: boolean = false

    public componentWillUnmount(): void {
        this.props.store.disableHistoryWatch()
    }

    @action.bound
    public toggleMenu(selectAction?: () => void) {
        this.menuVisible = !this.menuVisible
        if (selectAction) {
            selectAction()
        }
    }

    public renderStatementPage() {
        const { store } = this.props

        const { statement } = store
        const { titles } = res().dataFields

        return (
            <>
                <div className={styles.clearElement}></div>
                <div
                    className={`${styles.content} ${BrowserStore.isMobile ? styles.mobileContent : null
                        }`}
                >
                    <div className={styles.statementsSchedule}>
                        {!BrowserStore.isMobile && (
                            <span className={styles.title}>
                                {res().automaticStatements}
                            </span>
                        )}
                        {this.renderScheduler()}
                        {BrowserStore.isMobile && (
                            <div className={styles.mainButton}>
                                <Button
                                    type="primary"
                                    onClick={() =>
                                        store.toggleStatementConfigForm()
                                    }
                                >
                                    {res().generateButton}
                                </Button>
                            </div>
                        )}
                        {statement.terminals && statement.terminals.length > 0 && (
                            <div className={styles.terminals}>
                                <span className={styles.title}>
                                    {titles.terminals}:
                                </span>
                                &nbsp;&nbsp;
                                <span
                                    className={`${styles.terminalsList} ${store.hideTerminals
                                        ? styles.hiddenTerminals
                                        : ''
                                        }`}
                                >
                                    <div>
                                        {statement.terminals &&
                                            statement.terminals.join(', ')}
                                    </div>
                                </span>
                                <span>
                                    <Icon
                                        type={
                                            store.hideTerminals
                                                ? 'plus'
                                                : 'minus'
                                        }
                                        className={styles.collapsibleIcon}
                                        onClick={store.showTerminals}
                                    />
                                </span>
                            </div>
                        )}
                    </div>

                    <div className={styles.statementHistory}>
                        {this.renderHistory()}
                    </div>
                </div>
            </>
        )
    }

    public renderConfigPage() {
        const { store } = this.props

        return <ConfigForm store={store} frequency={store.curentSchedule} />
    }

    public render() {
        const { store } = this.props

        const { statement } = store

        if (!statement) {
            return (
                <Page title={<Skeleton paragraph={{ rows: 0 }} active />}>
                    <Skeleton
                        paragraph={{ rows: 4 }}
                        active
                        className={styles.content}
                    />
                </Page>
            )
        }

        const subtitle = !store.showStatementConfigForm
            ? res().contract + ' ' + statement.contract
            : null

        let title: string | ReactNode = (
            <>
                {res().statementsFor} - {statement.name}{' '}
                <span className={styles.withBorder}>{statement.type}</span>
            </>
        )
        if (store.showStatementConfigForm && BrowserStore.isMobile) {
            title = store.curentSchedule
                ? configRes().scheduleTitle(store.curentSchedule)
                : configRes().manualGenerationTitle
        }

        return (
            <>
                <Page
                    title={
                        <div className={styles.titleTable}>
                            <div>{title}
                                <div className={styles.subtitle}>{subtitle}</div>
                            </div>

                            {!BrowserStore.isMobile && (
                                <div className={styles.titleButton}>
                                    <ButtonDefault
                                        type="primary"
                                        onClick={() =>
                                            store.toggleStatementConfigForm()
                                        }
                                        className="hint-section-5-step-3"
                                    >
                                        {res().generateButton}
                                    </ButtonDefault>
                                </div>
                            )}
                        </div>
                    }
                    toolbar={this.toolbar}
                    onBackClick={
                        store.showStatementConfigForm
                            ? store.toggleStatementConfigForm
                            : store.getBack
                    }
                >
                    {(!store.showStatementConfigForm ||
                        !BrowserStore.isMobile) &&
                        this.renderStatementPage()}
                    {BrowserStore.isMobile &&
                        store.showStatementConfigForm &&
                        this.renderConfigPage()}
                </Page>

                {!BrowserStore.isMobile &&
                    store.showStatementConfigForm &&
                    this.statementConfigContainer()}
            </>
        )
    }

    private renderScheduler() {
        const { store } = this.props
        const { statement } = store
        const { titles } = res().dataFields
        const { daily } = statement // weekly, monthly were hided
        const scheduler = { daily } // weekly, monthly were hided

        const records = map(scheduler, (value, key: FrequencyType) => {
            const emails = flatMap(value.emails, x => x.split(';')).join(', ')
            const emValue = value.emails
            const mailsTooltip =
                emValue instanceof Array
                    ? emValue.map((mail, keyValue) => (
                        <div key={keyValue}>{mail}</div>
                    ))
                    : [emValue]
            const multiEmail =
                emValue instanceof Array ? emValue.length > 1 : false
            const displayedElement =
                emValue instanceof Array ? emValue[0] : emValue

            if (BrowserStore.isMobile) {
                return (
                    <List.Item
                        className={`${styles.cardElement} ${styles.listItem}`}
                        key={key}
                        onClick={() => store.toggleStatementConfigForm(key)}
                    >
                        <div className={styles.cardSchedule}>
                            <div className={styles.cardSchedule}>
                                <div className={styles.scheduleRecord}>
                                    <div>{titles[key]}</div>
                                    <div className={styles.scheduleFormat}>
                                        {value.format}
                                    </div>
                                    <div
                                        className={`${value.enable
                                            ? styles.enableStatus
                                            : styles.disableStatus
                                            } ${styles.status}`}
                                    >
                                        <StatusIcon
                                            className={
                                                value.enable
                                                    ? styles.enableStatus
                                                    : styles.disableStatus
                                            }
                                        />
                                        &nbsp;
                                        {value.enable
                                            ? res().active
                                            : res().inactive}
                                        !
                                    </div>
                                </div>
                                <div className={`${styles.scheduleEmails}`}>
                                    {value.emails ? emails : '-'}
                                </div>
                            </div>
                            <div
                                className={`${styles.tableCell} ${styles.middleAlign} ${styles.openDetailFormIcon}`}
                            >
                                <ArrowIcon
                                    className={`${styles.collapsibleArrow} ${styles.leftArrow}`}
                                />
                            </div>
                        </div>
                    </List.Item>
                )
            }

            return (
                <div className={styles.scheduleRecord} key={key}>
                    <span>{titles[key]}</span>
                    <span
                        className={`${value.enable
                            ? styles.enableStatus
                            : styles.disableStatus
                            } ${styles.status}`}
                    >
                        <StatusIcon
                            className={
                                value.enable
                                    ? styles.enableStatus
                                    : styles.disableStatus
                            }
                        />
                        &nbsp;
                        {value.enable ? res().active : res().inactive}
                    </span>
                    <span className={styles.format}>
                        {value && value.format ? value.format : '-'}
                    </span>
                    {multiEmail && (
                        <Tooltip title={mailsTooltip} placement="bottom">
                            <span
                                className={`${styles.emails} ${multiEmail ? styles.multiEmail : ''
                                    }`}
                            >
                                <span className={styles.firstMail}>
                                    {displayedElement}
                                </span>
                                <span className={styles.moreMails}>
                                    +{emValue.length - 1}
                                </span>
                            </span>
                        </Tooltip>
                    )}
                    {!multiEmail && (
                        <span className={styles.emails}>
                            {emails && emails !== '' ? emails : '-'}
                        </span>
                    )}
                    <span className={styles.editButton}>
                        <IconButton
                            icon={FormIcon}
                            dataSemanticId="statement-settings-form"
                            className={styles.settingsFormButton}
                            id="open-statement-settings-icon"
                            onClick={() => store.toggleStatementConfigForm(key)}
                            tooltipTitle={res().tooltips.statementSettings}
                        />
                    </span>
                </div>
            )
        })

        return (
            <>
                <div className={`${styles.schedule} hint-section-5-step-5`}>{records}</div>
            </>
        )
    }

    private gridContainer() {
        const { store } = this.props

        const { statementHistoryGridColumns } = store

        return (
            <Grid<ContractStatementHistory>
                columns={statementHistoryGridColumns}
                data={toJS(store.statementHistory)}
                rowKey={
                    store.statementHistoryKey as keyof ContractStatementHistory
                }
                loading={store.statementHistoryLoading}
                disableRowSelection={true}
            />
        )
    }

    private renderHistory() {
        if (BrowserStore.isMobile) {
            return (
                <div className={styles.historyList}>
                    <this.historyListContainer />
                </div>
            )
        }

        return <div className={styles.historyGrid}>{this.gridContainer()}</div>
    }

    private statementConfigContainer() {
        const { store } = this.props

        return (
            <ModalContainer
                visible={
                    !BrowserStore.isMobile && store.showStatementConfigForm
                }
                onClose={store.toggleStatementConfigForm}
                headerControl={
                    <MobileHeader
                        onCancel={store.toggleStatementConfigForm}
                    ></MobileHeader>
                }
                closable={true}
                modalClass="hint-section-5-step-4"
            >
                <ModalContext.Consumer>
                    {modalContext => (
                        <ConfigForm
                            store={store}
                            frequency={store.curentSchedule}
                            modalContext={modalContext}
                        />
                    )}
                </ModalContext.Consumer>
            </ModalContainer>
        )
    }

    private renderHistoryItem = (item: ContractStatementHistory) => {
        let statusStyle = ''
        if (item.status === HistoryItemStatus.error) {
            statusStyle = styles.disableStatus
        } else if (item.status === HistoryItemStatus.success) {
            statusStyle = styles.enableStatus
        }

        return (
            <List.Item key={item.id} className={styles.historyListItem}>
                <div className={styles.cardElement}>
                    <div className={appStyles.floatTwoCellRow}>
                        <div className={appStyles.autoWidth}>
                            <ClockIcon />
                            &nbsp;
                            <span
                                className={`${styles.statementPeriod} ${appStyles.primaryText}`}
                            >
                                {renderPeriod(item.period as [Date, Date])}
                            </span>
                        </div>
                        <div
                            className={`${styles.withBorder} ${styles.typeCell} ${statusStyle}`}
                            onClick={() =>
                                item.status === HistoryItemStatus.success
                                    ? this.props.store.statementDownload(item)
                                    : null
                            }
                        >
                            <span className={appStyles.capsText}>
                                {item.format}
                            </span>
                            &nbsp;
                            <span className={styles.statusColumn}>
                                {item.status === HistoryItemStatus.success && (
                                    <DownloadIcon className={styles.pointer} />
                                )}
                                {item.status ===
                                    HistoryItemStatus.inProgress && (
                                        <Spin
                                            indicator={<Icon type="loading" spin />}
                                        />
                                    )}
                                {item.status === HistoryItemStatus.error && (
                                    <Icon
                                        className={styles.errorIcon}
                                        type="warning"
                                    />
                                )}
                            </span>
                        </div>
                    </div>
                    <div className={styles.historyMails}>
                        <MailIcon />
                        &nbsp;
                        <div>
                            {item.emails && item.emails.length !== 0 && (
                                <span className={styles.scheduleEmails}>
                                    {item.emails
                                        ? flatMap(item.emails, x =>
                                            x.split(';')
                                        ).join(', ')
                                        : '-'}
                                </span>
                            )}
                            {(!item.emails || item.emails.length === 0) && (
                                <span>-</span>
                            )}
                        </div>
                    </div>
                    <div>
                        {item.initiationType.toLocaleLowerCase() !==
                            FrequencyType.custom && (
                                <span className={appStyles.primaryText}>
                                    {`${res().initiationType[
                                        item.initiationType.toLocaleLowerCase()
                                    ]
                                        } ${res().statement.toLocaleLowerCase()}`}
                                </span>
                            )}
                        {item.initiationType.toLocaleLowerCase() ===
                            FrequencyType.custom && (
                                <span className={appStyles.primaryText}>
                                    {`${res().initiationType.custom}`}
                                    {item.initiator && (
                                        <span>: {item.initiator}</span>
                                    )}
                                </span>
                            )}
                    </div>
                    <div className={styles.creationTime}>
                        <span>{res().dataFields.historyTitles.create}:</span>
                        &nbsp;
                        <span>{renderDate(item.create, null, true)}</span>
                    </div>
                </div>
            </List.Item>
        )
    }

    private historyListContainer = observer(() => {
        const { store } = this.props

        if (store.statementHistoryLoading) {
            return (
                <div className={styles.spin}>
                    <Spin spinning={store.statementHistoryLoading} />
                </div>
            )
        }

        return (
            <div>
                {store.statementHistory.map(historyItem =>
                    this.renderHistoryItem(historyItem)
                )}
            </div>
        )
    })
}
