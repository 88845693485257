import { ReactNode } from 'react'
import locale from '~/utils/locale'

export default locale.create<{
    title: string
    description: string
    sendOrder: string
    formItemTitles: {
        shop: string
        email: string
    },
    shopPlaceholder: string
    emailPlaceholder: string
    emailRequired: string
    invalidEmail: string
    invalidShop: string
    confirmationTitle: (orderNumber: string) => ReactNode
    confirmationUpdateTitle: string
    confirmationSubtitle: string
    closeButton: string
    send: string
}>()
