import {
    Button,
    Form
} from 'antd'
import React, { useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import res from '../res'
import styles from '../styles.css'
import { LoadingOutlined } from '@ant-design/icons'
import message from '~/utils/message'
import layoutStyles from '~/layouts/main/styles.css'
import { UpdateCreditOrder } from '~/api/products'
import TextArea from 'antd/es/input/TextArea'
import { OrderRejectBody } from '~/pages/products/credit/components/reject-modal-config'
import { runInAction } from 'mobx'

interface FormInput {
    moderatorComment: string
}

function RejectOrderForm({
    creditOrder,
    statusId,
    onApply,
    onClose,
    showConfirmation
}: {
    creditOrder: UpdateCreditOrder
    statusId: string
    onApply: (requestBody: OrderRejectBody) => Promise<UpdateCreditOrder>
    onClose: () => void
    showConfirmation: (updateOrder: UpdateCreditOrder) => void
}) {
    const formItemLayout = {
        labelCol: {
            xs: { span: 28 },
            sm: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 28 },
            sm: { span: 16 }
        }
    }

    const { handleSubmit, control } = useForm<FormInput>()
    const [sendingRequest, setSendingRequest] = useState(false)

    const onSubmit: SubmitHandler<FormInput> = async (orderBody: FormInput) => {
        setSendingRequest(true)
        const updateOrderBody = {
            ...creditOrder,
            shopIds: []
        }
        let requestBody: OrderRejectBody
        try {

            runInAction(() => {
                updateOrderBody.moderatorComment = orderBody.moderatorComment
                updateOrderBody.statusId = statusId

                if (updateOrderBody.shops.length > 0) {
                    updateOrderBody.shops.map(shop => {
                        updateOrderBody.shopIds.push(shop.id)
                    })

                    if (updateOrderBody.shops[0].creditConditions.length > 0) {
                        updateOrderBody.creditConditions = updateOrderBody.shops[0].creditConditions
                    }

                    delete updateOrderBody.shops
                }
            })

            requestBody = {
                ...updateOrderBody
            }

            const updatedCreditOrder = await onApply(requestBody)
            setSendingRequest(false)
            if (updatedCreditOrder) {
                showConfirmation(updatedCreditOrder)
            }
        } catch (error) {
            setSendingRequest(false)
            message.error(error)
        }
    }

    return (
        <>
            <Form
                {...formItemLayout}
                className={styles.form}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Form.Item>
                    <Controller
                        control={control}
                        type="text"
                        as={<TextArea
                            autoSize={false} />}
                        name="moderatorComment"
                        defaultValue=""
                        placeholder={res().commentPlaceholder}
                    />
                </Form.Item>
                <div
                    className={`${layoutStyles.contentFooter}`}
                >
                    <Button size="large" onClick={onClose}>
                        {res().cancelButton}
                    </Button>
                    <Button htmlType="submit" size="large" type="primary">
                        {sendingRequest ? (
                            <LoadingOutlined />
                        ) : (
                            res().sendButton
                        )}
                    </Button>
                </div>
            </Form>
        </>
    )
}

export default RejectOrderForm
