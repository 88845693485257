import res from './res'

res.add('en', {
    title: 'Shop settings ',
    confirmTitle: 'Are you sure you want to load data?',

    labels: {
        shop_name: 'Shop name',
        shop_name_placeholder: 'Enter shop name',
        logo: 'Logo',
        lang: 'Preferred language',
        email: 'Email',
        email_placeholder: 'Enter email',
        phone: 'Phone',
        phone_placeholder: 'Enter phone',
        colorScheme: 'Color scheme',

        bg: 'Background',
        btn_color: 'Button color',
        card_bg: 'Card background',
        card_txt_color: 'Text color on card',
        txt_color: 'Text color',

        required: 'Required',

        fileDescription: 'Can accept only jpg, png, gif or svg up to 1 Mb of size. Logo is shown on top of the payment page',
        gradientDescription: '(Set two colors for gradient fill)',

        reset: 'Reset',
        save: 'Save',
        load: 'Load',
        custom: 'Set custom colors'
    },

    sampleForm: {
        Order: 'Order',
        PaymentStore: 'Payment in the online store',
        Commission: 'Commission',
        Seller: 'Seller',
        cardNumber: 'Card number',
        expires: 'Valid until',
        cardName: 'Name on the card',
        email: 'Your email',
        phoneNumber: 'Your phone number',
        pay: 'To pay',
        back: 'Back'
    },

    messages: {
        epayTemplateUpdated: 'Business settings are saved',
        errorTitle: 'Error',
        cannotLoadShops: 'Cannot load merchant shops',
        invalidFile: 'Invalid file type or size exceeds 1Mb',
        unsavedChanges: 'All unsaved changes will be lost, if you proceed with operation',
        invalidEmail: 'Invalid email',
        valueRequired: 'Please provide value',
        invalidPhone: 'Invalid phone number'
    }

})
