import res from './res'

import { CashByCodeStatus, CreditType } from '~/api'

res.add('ru', {
    title: 'Cash by code',
    ranges: {
        today: 'сегодня',
        yesterday: 'вчера',
        thisWeek: 'текущую неделю',
        priorWeek: 'прошлую неделю',
        thisMonth: 'текущий месяц',
        priorMonth: 'прошлый месяц',
        thisQuarter: 'текущий квартал',
        customPeriod: 'указанный период'
    },
    anyPaymentStatus: 'Любой',
    paymentStatuses: {
        [CashByCodeStatus.auth]: 'Ожидает',
        [CashByCodeStatus.charged]: 'Проведен',
        [CashByCodeStatus.cancelled]: 'Отменен',
        [CashByCodeStatus.rejected]: 'Отклонен',
        [CashByCodeStatus.refunded]: 'Возвращен',
        [CashByCodeStatus.failed]: 'Неудачный',
        [CashByCodeStatus.new]: 'Новый',
        [CashByCodeStatus.any]: 'Любой',
        [CashByCodeStatus.CANCEL_OLD]: 'Отменен по сроку',
        [CashByCodeStatus.TIMEOUT_3D]: '3DS Отменен'
    },
    creditTypes: {
        [CreditType.loan]: 'Кредит',
        [CreditType.installment]: 'Рассрочка'
    },
    sortBy: 'Сортировать по',
    filtersPlaceholder: 'Фильтр по атрибутам платежей',
    dataFields: {
        full: {
            amount: 'Сумма платежа',
            authDate: 'Дата авторизации',
            cardMask: 'Маска карты',
            client: 'Клиент',
            contractNumberDevice: 'Номер девайса',
            createdAt: 'Дата создания',
            currency: 'Валюта',
            description: 'Детали платежа',
            destinationPhone: 'На номер',
            epayTerminalID: 'epayTerminalID',
            failurePostLink: 'Ссылка ошибочного перенаправления',
            id: 'Идентификатор',
            invoiceId: 'Номер заказа',
            isPostLink: 'Постлинк',
            merchant: 'Мерчант',
            payerIP: 'IP плателщика',
            postLink: 'Ссылка перенаправления',
            postingDate: 'Дата публикации',
            postingStatus: 'Статус публикации',
            receiverPhone: 'Номер получателя',
            reference: 'Ответ от карточной системы',
            returnCode: 'Код возвращения',
            sourcePhone: 'С номера',
            stan: 'Stan',
            status: 'Статус платежа',
            terminalID: 'ID терминала'
        },
        short: {
            amount: 'Сумма',
            authDate: 'Дата авторизации',
            cardMask: 'Маска карты',
            cashCodeSMS: 'SMS код',
            client: 'Клиент',
            contractNumberDevice: 'Номер девайса',
            createdAt: 'Дата создания',
            currency: 'Валюта',
            description: 'Детали платежа',
            destinationPhone: 'На номер',
            epayTerminalID: 'epayTerminalID',
            failurePostLink: 'Ссылка ошибочного',
            id: 'Идентификатор',
            invoiceId: 'Номер заказа',
            isPostLink: 'Постлинк',
            merchant: 'Мерчант',
            payerIP: 'IP плателщика',
            postLink: 'Ссылка перенаправления',
            postingDate: 'Дата публикации',
            postingStatus: 'Статус',
            receiverPhone: 'Номер получателя',
            reference: 'Ответ',
            returnCode: 'Код возвращения',
            sourcePhone: 'С номера',
            stan: 'Stan',
            status: 'Статус платежа',
            terminalID: 'ID терминала'
        },
        groups: {
            location: 'Местоположение',
            paymentPage: 'Платежная страница'
        },
        geolocation: 'Геолокация'
    },
    sortOptions: {
        date: 'Дате',
        postLink: 'Постлинк',
        status: 'Статусу',
        type: 'Типу',
        asc: {
            date: 'старые вначале',
            postLink: 'успешные вначале',
            status: 'вначале ожидают'
        },
        desc: {
            date: 'новые вначале',
            postLink: 'неуспешные вначале',
            status: 'вначале возвраты'
        }
    },
    secure3DValues: {
        true: 'Да',
        false: 'Нет'
    },
    partialSum: {
        isRequired: 'Сумма обязательна к заполнению',
        constraints:
            'Сумма должна быть положительным числом не более суммы платежа'
    },
    operations: {
        fullCharge: {
            title: 'Полное списание',
            itemError: paymentReference =>
                `Ошибка полного списания по платежу #${paymentReference}`
        },
        partialCharge: {
            title: 'Частичное списание',
            itemError: paymentReference =>
                `Ошибка частичного списания по платежу #${paymentReference}`
        },
        cancel: {
            title: 'Отмена',
            itemError: paymentReference =>
                `Ошибка отмены платежа #${paymentReference}`
        },
        fullRefund: {
            title: 'Полный возврат',
            itemError: paymentReference =>
                `Ошибка полного возврата платежа #${paymentReference}`
        },
        partialRefund: {
            title: 'Частичный возврат',
            itemError: paymentReference =>
                `Ошибка частичного возврата платежа #${paymentReference}`
        }
    },
    postLinkStatus: {
        all: 'Все',
        ok: 'Успешно',
        fail: 'Ошибка'
    },
    collapseSummary: range =>
        `Статистика за ${range instanceof Function ? range() : range || 'период'
        }`,
    errors: {
        summaryLoadError: 'Ошибка загрузки статистики',
        paymentsLoadError: 'Ошибка загрузки платежей'
    },
    errorCodes: {
        454: 'Операция не удалась, проверьте, не заблокирована ли сумма на карте и повторите попытку позже.',
        455: 'Проверка 3DSecure/Securecode недоступна,либо неверно введен номер карты. Попробуйте воспользоваться другим браузером/устройством. Если ошибка повторяется, переустановите код.',
        456: 'Невозможно провести оплату по данной карте.',
        457: 'Некорректно введен срок действия карты.',
        458: 'Сервер не отвечает. Попробуйте попозже.',
        459: 'Сервер не отвечает. Попробуйте попозже.',
        460: 'Произошла ошибка, возможно сумма заблокировалась на карте, обратитесь службу поддержки.',
        461: 'Системная ошибка, попробуйте провести транзакцию позже, если ошибка повторяется обратитесь в службу поддержки.',
        462: 'Транзакция отклонена вашим банком. Для уточнения причины отказа необходимо обратиться по контактам, указанным на обратной стороне вашей карты.',
        463: 'Транзакция отклонена вашим банком. Для уточнения причины отказа необходимо обратиться по контактам, указанным на обратной стороне вашей карты.',
        464: 'Недействительный коммерсант.',
        465: 'Карта заблокирована.',
        466: 'Транзакция отклонена вашим банком. Для уточнения причины отказа необходимо обратиться по контактам, указанным на обратной стороне вашей карты.',
        467: 'Карта заблокирована.',
        468: 'Требуется дополнительная идентификация.',
        469: 'Недействительная транзакция, перепроверить введенные данные.',
        470: 'Сумма транзакции равно нулю, пожалуйста, попробуйте ещё раз.',
        471: 'Недействительный номер карточки, пожалуйста, убедитесь в корректности ввода номера карты и попробуйте ещё раз.',
        472: 'Недействительный номер карточки, пожалуйста, убедитесь в корректности ввода номера карты и попробуйте ещё раз.',
        473: 'DSecure/Securecode введен или введен некорректно. Пожалуйста, убедитесь в корректности ввода, либо переустановите  пароль. Если ошибка повторяется обратитесь в службу поддержки.',
        475: 'Транзакция не успешна. Пожалуйста, повторите снова.',
        476: 'Повторное проведение транзакции будет доступно не менее чем через 30 минут.',
        477: 'Ошибка, пожалуйста, воспользуйтесь другой картой. В случае её отсутствия обратитесь в службу поддержки по адресу epay@halykbank.kz.',
        478: 'Просрочен срок действия карты.',
        479: 'Карточка заблокирована.',
        480: 'Обратиться к банку - эмитенту.',
        481: 'Карта недействительна. Пожалуйста, обратитесь в Банк для выпуска новой карт.',
        482: 'Карта недействительна. Пожалуйста, обратитесь в Банк для выпуска новой карт.',
        483: 'Статус карты - украдена. Пожалуйста, обратитесь в Банк для выпуска новой карты.',
        484: 'Недостаточно средств на карте.',
        485: 'Срок действия карты истек.',
        486: 'Транзакция отклонена. На карте запрещена возможность покупок в сети интернет, либо карточные данные введены не верно.',
        487: 'Транзакция отклонена, пожалуйста, обратитесь в службу поддержки.',
        488: 'Сумма превышает допустимый лимит.',
        489: 'Карточка заблокирована.',
        490: 'Запрет на проведение транзакции по вашей карте, за дополнительной информацией обратитесь по контактам, укзанным на обратной стороне вашей карты.',
        491: 'Превышен лимит частоты оплат.',
        492: 'Карта заблокирована по причине неверного ввода пин-кода, за дополнительной информацией обратитесь по контактам, укзанным на обратной стороне вашей карты.',
        493: 'Недоступен банк, выпустивший карту, попробуйте повторить оплату позже.',
        494: 'Транзакция не успешна - банк, выпустивший карту, попробуйте провести транзакцию позже.',
        495: 'Транзакция запрещена, воспользуйтесь другой картой.',
        496: '3DSecure SecureCode absent Если карта ККБ- не установлен 3DSecure SecureCode. В других случаях проблема на стороне эмитента.',
        497: 'Сервер не отвечает. Попробуйте попозже',
        498: 'Оплата бонусами невозможна. Попробуйте попозже.',
        499: 'Неверно введен или не введен 3DSecure/Securecode.',
        500: 'Верификация 3DSecure/Securecode недоступна или вы неправильно ввели номер карты. Попробуйте использовать другой браузер/устройство. Если ошибка повторяется, сбросьте пароль.',
        501: 'Ошибка обслуживания карты. Проверьте правильность ввода карты. Если ошибка повторяется, обратитесь в службу поддержки.',
        502: 'Этот тип транзакции требует обязательного ввода пароля 3DSecure/Securecode.',
        503: 'Эта операция требует от вас использования пароля 3D Secure.',
        521: 'Транзакция отклонена вашим банком. Для уточнения причины отказа необходимо обратиться по контактам, указанным на обратной стороне вашей карты.',
        522: 'Запись не найдена, проверьте карточку.',
        1636: 'Не удалось установить владельца карты.',
        1654: 'Не удалось верифицировать OTP код.',
        2092: 'Не удалось провести транзакцию.',
        2678: 'Превышен суточный лимит на терминале.'
    }
})
