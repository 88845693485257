import { computed } from 'mobx'

import strict from '~/utils/strict'

import { SortOption } from '~/pages/payments/components/sort-config-select'

import { PaymentLinksBaseInfo } from '~/api/payment-links'
import { ContractStatementInfo } from '~/api/statements'
import res from './res'

const options = computed(() => {
    const titles = res().sortOptions

    return strict<
        Array<{ title: string; items: Array<SortOption<PaymentLinksBaseInfo>> }>
    >([
        {
            title: res().sortOptions.createdDate,
            items: [
                {
                    field: 'createdDate',
                    direction: 'desc',
                    title: titles.desc.createdDate
                },
                {
                    field: 'createdDate',
                    direction: 'asc',
                    title: titles.asc.createdDate
                }
            ]
        },
        {
            title: res().sortOptions.expirationDate,
            items: [
                {
                    field: 'expireDate',
                    direction: 'desc',
                    title: titles.desc.expirationDate
                },
                {
                    field: 'expireDate',
                    direction: 'asc',
                    title: titles.asc.expirationDate
                }
            ]
        },
        {
            title: res().sortOptions.order,
            items: [
                {
                    field: 'invoiceId',
                    direction: 'desc',
                    title: titles.desc.order
                },
                {
                    field: 'invoiceId',
                    direction: 'asc',
                    title: titles.asc.order
                }
            ]
        }
    ])
})

export default options
