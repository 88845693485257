import React from 'react'

import { observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'

import page from 'page'

const context = observable({
    pathname: ''
})

page((ctx, next) => {
    runInAction(() => {
        context.pathname = ctx.pathname
    })
    next()
})

export interface NavLinkProps {
    href: string
    activeClassName?: string
    children?: any
    className?: string
}

export const NavLink = observer((props: NavLinkProps) => (
    <a
        href={props.href}
        className={`${props.className || ''} ${context.pathname === props.href ? props.activeClassName : ''
            }`}
        data-prevent-validation={true}
    >
        {props.children}
    </a>
))

export default NavLink
