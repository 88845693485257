import React from 'react'

import { observer } from 'mobx-react'

import { Map, Marker, TileLayer } from 'react-leaflet'

interface GeolocationPaneProps {
    center: {
        lat: number
        lng: number
    }
    zoom?: number
}

const GeolocationPane = observer(
    ({ center, zoom = 10 }: GeolocationPaneProps) => {
        return (
            <Map
                center={[center.lat, center.lng]}
                zoom={zoom}
                scrollWheelZoom={false}
            >
                <TileLayer
                    attribution={
                        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    }
                    url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'}
                />
                <Marker position={[center.lat, center.lng]} />
            </Map>
        )
    }
)

export default GeolocationPane
