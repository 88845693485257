import React from 'react'

import { Layout } from '~/components'

const { Content } = Layout

const WelcomePageLayout = (props: { children?: React.ReactNode }) => {
    return (
        <Layout>
            <Content>
                {props.children}
            </Content>
        </Layout>
    )
}

export default WelcomePageLayout
