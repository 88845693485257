import React from 'react'

import { observer } from 'mobx-react'

import { ClickParam } from 'antd/es/menu'

import { Dropdown, Menu } from '~/components'

import BrowserStore from '~/services/browser/browser-state'

import styles from './styles.css'

type SortDirection = 'asc' | 'desc'

export interface SortOption<T> {
    field: keyof T
    direction: SortDirection
    title: string
}

interface SortConfigSelectProps<T> {
    options: Array<{ title: string; items: Array<SortOption<T>> }>
    id?: string
    selectedOption?: {
        field: keyof T
        direction: SortDirection
    }
    asMenu?: boolean
    onChange: (field: keyof T, direction: SortDirection) => void
}

@observer
export class SortConfigSelect<T> extends React.Component<
SortConfigSelectProps<T>,
{}
> {
    public render() {
        const { options, selectedOption, asMenu } = this.props

        const selectedKey = !selectedOption
            ? undefined
            : [
                this.createOptionKey(
                    selectedOption.field,
                    selectedOption.direction
                )
            ]

        let selectedValue = 'Unknown'

        const overlay = (
            <Menu
                selectedKeys={selectedKey}
                onClick={param => this.handleMenuClick(param)}
                className={`${BrowserStore.isMobile ? styles.mobileMenu : ''}`}
            >
                {options.map((option, index) => (
                    <Menu.ItemGroup key={index} title={option.title}>
                        {option.items.map(item => {
                            if (
                                selectedOption &&
                                item.field === selectedOption.field &&
                                item.direction === selectedOption.direction
                            ) {
                                selectedValue = `${option.title.toLocaleLowerCase()} - ${item.title
                                    }`
                            }
                            return (
                                <Menu.Item
                                    key={this.createOptionKey(
                                        item.field,
                                        item.direction
                                    )}
                                >
                                    {item.title}
                                </Menu.Item>
                            )
                        })}
                    </Menu.ItemGroup>
                ))}
            </Menu>
        )
        if (asMenu === true) {
            return overlay
        }
        return (
            <Dropdown
                placement="bottomLeft"
                trigger={['click']}
                overlay={overlay}
                overlayClassName={styles.overlay}
            >
                <span id={this.props.id} className={styles.selectedValue}>
                    {selectedValue}
                </span>
            </Dropdown>
        )
    }

    private createOptionKey(field: keyof T, direction: SortDirection) {
        return `option_${field}_${direction}`
    }

    private handleMenuClick(param: ClickParam) {
        const optionKeyParts = param.key.replace('option_', '').split('_')

        this.props.onChange(
            optionKeyParts[0] as keyof T,
            optionKeyParts[1] as SortDirection
        )
    }
}

export default SortConfigSelect
