import React from 'react'
import res from './res'

res.add('kk', {
    title: 'Өтінімді қабылдамау',
    description: 'Өтінімді қабылдамау себебін төменде сипаттаңыз',
    confirmationTitle: (orderNumber: string) => (
        <div>
            Өтінім №{orderNumber} өңдеуге қайта жіберілді
        </div>
    ),
    cancelButton: 'Бас тарту',
    sendButton: 'Жіберу',
    closeButton: 'Жабу',
    commentPlaceholder: 'Пікіріңізді енгізіңіз'
})
