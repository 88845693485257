import React from 'react'
import { HintModal } from '../modal'
import InteractiveHintsStore from '../../../interactive-hints-store'
import { observer } from 'mobx-react'
import AppStore from '~/app-store'
import res from '../../../res'

interface Props {
    store: InteractiveHintsStore
    appStore: typeof AppStore
}

const CongratulationModal = observer(({ store, appStore }: Props) => {
    const { isVisibleCongratulationModal } = store
    const closeModal = () => {
        store.hideCongratulationModal()
        setTimeout(() => appStore.hideInteractiveHints(), 250)
    }

    return (
        <HintModal
            visible={isVisibleCongratulationModal}
            onCancel={closeModal}
            onOk={closeModal}
            title={res().modals.congratulation.title}
            description={res().modals.congratulation.description}
            okText={res().modals.congratulation.okBtn} />
    )
})

export default CongratulationModal