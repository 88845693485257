import React from 'react'

import { computed } from 'mobx'
import { observer } from 'mobx-react'

import { languages } from '~/constants/laguages'

import { Button, Form, FormItem, Select, Table } from '~/components'

import Page from '~/layouts/main/page'

import locale from '~/utils/locale'

import UserProfileStore from './user-profile-store'

import res from '~/pages/profile/res'

import styles from './styles.css'

import BrowserStore from '~/services/browser/browser-state'

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 12 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
    },
    className: BrowserStore.isMobile ? '' : styles.formItem,
    colon: false
}

const lastUsageColumns = computed(() => [
    {
        title: res().lastUsage.table.headers.geo,
        dataIndex: 'name',
        key: 'name',
        render: text => <a href="javascript:;">{text}</a>
    },
    {
        title: res().lastUsage.table.headers.recordDate,
        dataIndex: 'age',
        key: 'age'
    },
    {
        title: res().lastUsage.table.headers.userSystem,
        dataIndex: 'address',
        key: 'address'
    },
    {
        title: res().lastUsage.table.headers.ip,
        key: 'tags',
        dataIndex: 'tags'
    },
    {
        title: res().lastUsage.table.headers.status,
        key: 'action'
    }
])

export const UserProfile = observer(
    ({ store }: { store: UserProfileStore }) => {
        const additionalPageClass = BrowserStore.isMobile ? styles.mobile : ''
        return (
            <>
                <Page title={res().title} pageClassName={additionalPageClass}>
                    <Form>
                        <FormItem
                            label={res().labels.email}
                            {...formItemLayout}
                        >
                            <span className={styles.email}>
                                {store.userLogin}
                            </span>
                        </FormItem>
                        <FormItem label={res().labels.role} {...formItemLayout}>
                            <span className={styles.email}>
                                {store.userRole}
                            </span>
                        </FormItem>
                        <FormItem
                            label={res().labels.language}
                            {...formItemLayout}
                        >
                            <Select
                                defaultValue={locale.lang}
                                onChange={store.setLang}
                            >
                                <Select.Option value="en">
                                    {languages.en}
                                </Select.Option>
                                <Select.Option value="kk">
                                    {languages.kk}
                                </Select.Option>
                                <Select.Option value="ru">
                                    {languages.ru}
                                </Select.Option>
                            </Select>
                        </FormItem>
                        <FormItem
                            label={res().labels.password}
                            {...formItemLayout}
                        >
                            <Button
                                size="large"
                                onClick={store.changePassword}
                                disabled={store.disableChangePassword}
                            >
                                {res().buttons.changePassword}
                            </Button>
                        </FormItem>
                    </Form>
                </Page>
                <Page
                    title={res().lastUsage.header}
                    pageClassName={`${additionalPageClass} ${styles.history}`}
                >
                    <Table
                        columns={lastUsageColumns.get()}
                        className={styles.table}
                        dataSource={[]}
                    />
                </Page>
            </>
        )
    }
)
