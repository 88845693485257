import React from 'react'

import { observer } from 'mobx-react'

import { InputProps } from 'antd/lib/input'
import { PopoverProps } from 'antd/lib/popover'

import { Popover } from '~/components'

import { isNumber } from '~/utils'

import FieldStore from '~/utils/field-store'
import TextArea from 'antd/es/input/TextArea'

interface FieldInputProps extends InputProps {
    store: FieldStore<string>
    popover?: PopoverProps
    trigger?: 'onChange' | 'onBlur' | 'onKeyUp'
    isNumeric?: boolean
    focused?: boolean
    disabled?: boolean
    placeHolder?: string
}

const FieldTextArea = observer((props: FieldInputProps) => {
    const { popover, store, placeHolder, isNumeric, trigger: triggerProp, ...rest } = props
    const { value } = store
    const trigger = triggerProp || 'onBlur'
    const validateOnChange = trigger === 'onChange'

    // const onBlur =
    //     trigger !== 'onBlur'
    //         ? undefined
    //         : e => {
    //             if (
    //                 e.relatedTarget &&
    //                 e.relatedTarget.dataset.preventValidation
    //             ) {
    //                 return
    //             }
    //             store.validate()
    //         }
    // const onKeyUp = trigger !== 'onKeyUp' ? undefined : store.validate

    const handleValueChange = (newValue: string) => {
        const trimmedValue = newValue.trim()

        if (trimmedValue.length && isNumeric && !isNumber(trimmedValue)) {
            return
        }

        store.set(newValue, validateOnChange)
    }

    // const input = (
    //     <Input
    //         placeholder={placeHolder}
    //         value={value}
    //         onChange={e => handleValueChange(e.target.value)}
    //         onBlur={onBlur}
    //         onKeyUp={onKeyUp}
    //         disabled={props.disabled}
    //         {...rest}
    //     />
    // )
    const textArea = (
        <TextArea value={value} style={{ resize: 'none', height: '108px' }} placeholder={placeHolder} onChange={e => handleValueChange(e.target.value)} />
    )

    return popover ? <Popover {...popover}>{textArea}</Popover> : textArea
})

export default FieldTextArea
