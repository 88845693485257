import { observer } from 'mobx-react'
import React from 'react'

import { Collapse } from '~/components'

const Panel = Collapse.Panel
import Page from '~/layouts/main/page'
import FaqStore from './faq-store'
import res from './res'
import styles from './styles.css'
import locale from '~/utils/locale'

import BrowserStore from '~/services/browser/browser-state'
import { Col, Row, Input } from 'antd'
import { InfoCircleIcon, SearchIcon } from '~/components/icon'
import instructionFileRu from '~/assets/files/instruction.pdf'
import instructionFileKk from '~/assets/files/instruction-kk.pdf'
import AppStore from '~/app-store'
import HintsModal from '~/pages/faq/components/hints-modal'

const options = [
    { label: 'Apple', value: 'Apple' },
    { label: 'Pear', value: 'Pear' },
    { label: 'Orange', value: 'Orange' }
]

export default observer(({ store, appStore }: { store: FaqStore, appStore: typeof AppStore }) => {

    const additionalPageClass = BrowserStore.isMobile ? styles.mobile : ''

    return (
        <>
            <Page
                title={
                    <div className={styles.titleTable}>
                        <div>{res().faqTitle}</div>
                        <div className={styles.infoBlock}>
                            <div className={styles.wrap}>
                                <InfoCircleIcon className={styles.actionIcon} />
                                <span className={styles.text}>{res().instructionText(BrowserStore.isMobile ? undefined : store.showHintModal)}</span>
                            </div>
                            {/*<a href={instructionFile} className="ant-btn ant-btn-lg" download> {res().download} </a>*/}
                        </div>
                    </div>
                }
                pageClassName={additionalPageClass}>
                <Row>
                    <Col span={16} xs={24} md={16} lg={16}>
                        <div className={styles.searchInput}>
                            <Input size="default"
                                value={store.keyword.value}
                                onChange={store.searchByKeyword}
                                placeholder="Поиск…"
                                prefix={<SearchIcon />} />
                        </div>

                        {store.filteredData && (
                            <Collapse bordered={false} expandIconPosition={'right'} className={styles.questions}>
                                {store.filteredData.map((question, key) => (
                                    <Panel
                                        header={
                                            <div className={styles.questionTitle}>
                                                {question.title}
                                            </div>
                                        }
                                        key={key.toString()}
                                        className={styles.question}
                                    >
                                        <div className={styles.answerBody}>
                                            <div>{question.answer}</div>
                                        </div>
                                    </Panel>
                                ))}
                            </Collapse>
                        )}
                    </Col>

                </Row>

            </Page>
            <HintsModal store={store} appStore={appStore} />

            {/*<AnyQuestions store={anyQuestionsStore} />*/}
        </>
    )
})
