import React from 'react'
import styles from '../../styles.css'
import { Modal } from 'antd'
import { ButtonDefault } from '~/components'

interface ModalProps {
    visible: boolean
    onCancel: () => void
    onOk: () => void
    title: string
    description: string
    okText: string
    cancelText?: string
}

export const HintModal = ({ visible, onCancel, onOk, title, description, okText, cancelText }: ModalProps) => {
    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            centered
            footer={null}
            className={styles.modal}
        >
            <h2 className={styles.modalTitle}>{title}</h2>
            <p className={styles.modalQuestion}>{description}</p>
            <div className={styles.modalFooter}>
                {cancelText &&
                    <ButtonDefault
                        onClick={onCancel}
                        type="default"
                    >
                        {cancelText}
                    </ButtonDefault>
                }
                <ButtonDefault
                    type="primary"
                    onClick={onOk}
                    className={cancelText ? '' : styles.modalBtnCentered}
                >
                    {okText}
                </ButtonDefault>
            </div>
        </Modal>
    )
}