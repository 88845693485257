import { camelCaseObject } from '~/utils'
import { get, put } from '~/utils/ajax'

import { Profile, ProfileUpdateRequest } from './contracts'

export * from './contracts'

export const getProfile = () => get<Profile>('profile')
    .then(camelCaseObject)

export const updateProfile = (data: ProfileUpdateRequest) => put('profile', data)
