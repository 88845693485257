import React from 'react'
import res from './res'

res.add('en', {
    title: 'Reject application',
    description: 'Please describe below the reason for the rejection of the application',
    confirmationTitle: (orderNumber: string) => (
        <div>
            Application №{orderNumber} has been sent for processing again
        </div>
    ),
    cancelButton: 'Cancel',
    sendButton: 'Send',
    closeButton: 'Close',
    commentPlaceholder: 'Enter your comment'
})
