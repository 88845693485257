import React from 'react'

import { action, computed, observable, values } from 'mobx'

import { inject, observer } from 'mobx-react'

import { AFTPayment, AFTStatus, AFTType } from '~/api'

import { Badge, Collapsible } from '~/components'

import DictionaryStore from '~/dictionary/dictionary-store'

import FieldStore from '~/utils/field-store'

import { numeric, required } from '~/utils/validation'

import { PaymentsPage } from '../components/payments-page'

import SortConfigSelect from '../components/sort-config-select'

import constants from './constants'

import { Button, IconButton, List } from '~/components'

import BaseFilters from './components/base-filters'
import FullFiltersForm from './components/full-filters-form'
import PaymentsActions, {
    MobilePaymentsActions
} from './components/aft-actions'
import Summary from './components/summary-old'

import AFTPaymentsStore from './aft-payments-store'

import paymentsRes from '../res'
import res from './res'

import moment from 'moment'
import appStyles from '~/app.css'
import {
    CalendarIcon,
    ChartIcon,
    ClockIcon,
    DocumentIcon,
    FilterIcon,
    ShadowDot,
    SortByAscIcon,
    SortByDescAIcon
} from '~/components/icon'
import MobileHeader from '~/components/modal/mobile-header'
import ModalContainer, {
    ModalContext
} from '~/components/modal/modal-container'
import StatusIcon from '~/components/status-icon'
import {
    renderCurrencyAmountLabel,
    renderStatusLabel
} from '~/pages/payment-aft/aft/constants/data-fields'
import statusStyles from '~/pages/payment-aft/aft/constants/aft-statuses'
import BrowserStore from '~/services/browser/browser-state'

import fullFormStyles from './components/full-filters-form/styles.css'
import styles from './styles.css'

import ListToolbar from '~/components/mobile-contols/list-toolbar'

import MobileMenu, {
    MobileMenuConfig
} from '~/components/mobile-menu/mobile-menu'

@observer
export default class AFTPayments extends PaymentsPage<
AFTPayment,
AFTPaymentsStore
> {
    @observable
    public menuVisible: boolean = false

    protected get pageHeaderControls() {
        return <div></div>
    }

    protected get title() {
        return res().title
    }

    // @Dos Transfer
    // protected get summary() {
    //     return !BrowserStore.isMobile ? (
    //         <SummaryContainer store={this.props.store} />
    //     ) : (
    //         undefined
    //     )
    // }

    protected get baseControls() {
        const { store } = this.props

        return BrowserStore.isMobile ? (
            undefined
        ) : (
            <div className={styles.baseControls}>
                <BaseFiltersContainer store={store} />
                <div>
                    <label htmlFor="sort">{res().sortBy}&nbsp;&nbsp;</label>
                    <SortContainer id="sort" store={store} />
                </div>
            </div>
        )
    }

    protected get toolbar() {
        const { store } = this.props

        return (
            <React.Fragment>
                <div>
                    <MobileMenu config={this.mobileMenuConfig}></MobileMenu>
                    <ModalContainer
                        visible={store.showSummaryMobile}
                        title={paymentsRes().statistics}
                        subTitle={
                            store.range
                                ? paymentsRes().collapseSummary(
                                    store.range.label
                                )
                                : ''
                        }
                        onClose={store.toggleSummaryMobile}
                        closable={true}
                        headerControl={
                            <MobileHeader
                                title={paymentsRes().statistics}
                                onCancel={store.toggleSummaryMobile}
                            ></MobileHeader>
                        }
                    >
                        <Summary
                            statistics={store.summaryData}
                            loading={store.summaryLoading}
                            failed={store.summaryFailed}
                        />
                    </ModalContainer>
                    <ModalContainer
                        visible={store.showSortingMobile}
                        title={paymentsRes().sorting}
                        onClose={store.toggleSortingMobile}
                        headerControl={
                            <MobileHeader
                                onCancel={store.toggleSortingMobile}
                            ></MobileHeader>
                        }
                        closable={true}
                    >
                        <SortContainer
                            id="sort"
                            store={store}
                            asMenu={true}
                            onChange={(field, direction) => {
                                store.toggleSortingMobile()
                                store.setSort(field, direction)
                            }}
                        />
                    </ModalContainer>
                    <DataFilterFormContainer
                        store={store}
                        dictionary={this.props.dictionary}
                        visible={store.showFilteringMobile}
                        onClose={store.toggleFilteringMobile}
                    />
                    <ListToolbar
                        onRefresh={this.props.store.refresh}
                        onScrollTop={this.scrollToTop}
                        scrollTargetClassName={this.scrollDependedControls[0]}
                    />
                </div>
            </React.Fragment>
        )
    }

    @action.bound
    public toggleMenu(selectAction?: () => void) {
        this.menuVisible = !this.menuVisible
        if (selectAction) {
            selectAction()
        }
    }
    @computed
    protected get dataFilterForm() {
        return BrowserStore.isMobile ? (
            undefined
        ) : (
            <DataFilterFormContainer
                store={this.props.store}
                dictionary={this.props.dictionary}
                visible={this.showDataFilterForm}
                onClose={this.closeDataFilterForm}
            />
        )
    }

    protected get dataFilterOptions() {
        return constants.filterOptions.get().filter(item => !item.fullOnly)
    }

    protected get gridActions() {
        return BrowserStore.isMobile ? (
            undefined
        ) : (
            // aft @Dos disabled refund 
            null // <PaymentsActionsContainer store={this.props.store} />
        )
    }

    protected get columns() {
        return constants.dataFields.get()
    }

    protected get dataFields() {
        return constants.dataFields.get()
    }

    protected get paymentDetailedFields() {
        return constants.detailedFields.get()
    }

    // aft @Dos disabled refund & charge in modal  
    protected get detailedPaymentActions() {
        return null // <DetailedPaymentActionsContainer store={this.props.store} />
    }

    @computed
    protected get isActionVisible() {
        const selectedPayment = this.props.store.selectedPayments[0]
        const isActionlessStatus =
            !selectedPayment ||
            (/* selectedPayment.code !== AFTStatus.auth && */
                selectedPayment.code !== AFTStatus.charged)
        return !isActionlessStatus
    }

    @computed
    protected get mobileMenuConfig(): MobileMenuConfig {
        const store = this.props.store
        const filterIcon = (
            <Badge count={store.activeFiltersCount}>
                <FilterIcon className={styles.strokedIcon}></FilterIcon>
            </Badge>
        )

        const menuConfig: MobileMenuConfig = {
            buttons: [
                {
                    clickAction: () =>
                        this.toggleMenu(store.toggleSummaryMobile),
                    icon: <ChartIcon />
                },
                {
                    clickAction: () =>
                        this.toggleMenu(store.toggleSortingMobile),
                    icon: <SortByDescAIcon />
                },
                {
                    clickAction: () =>
                        this.toggleMenu(store.toggleFilteringMobile),
                    icon: filterIcon
                }
            ]
        }
        menuConfig.visible = this.menuVisible
        menuConfig.disabled = false
        return menuConfig
    }

    protected renderListItem = (item: AFTPayment) => {
        return (
            <List.Item
                key={item.id}
                onClick={() => this.openPaymentDetailedForm(item)}
            >
                <div className={styles.cardElement}>
                    <div className={appStyles.floatTwoCellRow}>
                        <div>{renderStatusLabel(item.code, true)}</div>
                        <div>
                            <ClockIcon />
                            &nbsp;
                            <span className={appStyles.fatText}>
                                {moment(item.createdDate).format('DD.MM.YY')}
                                &nbsp;
                            </span>
                            <span>
                                {moment(item.createdDate).format('/ HH:mm')}
                            </span>
                        </div>
                    </div>
                    <div className={appStyles.floatTwoCellRow}>
                        <div>
                            <DocumentIcon /> {item.invoiceID}
                        </div>
                        <div>
                            <span>
                                {renderCurrencyAmountLabel(item.amount, item)}
                            </span>
                        </div>
                    </div>
                    <div className={styles.contactInfo}>
                        <span className={styles.contactInfoName}>
                            {item.name}
                        </span>
                    </div>
                    <div className={styles.contactInfoSecondary}>
                        <span>{item.email}</span>
                    </div>
                    {item.phone && (
                        <div className={styles.contactInfoSecondary}>
                            <span>
                                {item.phone.includes('+') ? '' : '+'}
                                {item.phone}
                            </span>
                        </div>
                    )}
                </div>
            </List.Item>
        )
    }
}

const SummaryContainer = observer(({ store }: { store: AFTPaymentsStore }) => (
    <div className={styles.summary}>
        <Collapsible
            collapseLabel={res().collapseSummary(store.range.label)}
            expanded={store.showSummary}
            onToggle={store.toggleSummary}
        >
            <Summary
                statistics={store.summaryData}
                loading={store.summaryLoading}
                failed={store.summaryFailed}
            />
        </Collapsible>
    </div>
))

const BaseFiltersContainer = observer(
    ({ store }: { store: AFTPaymentsStore }) => (
        <BaseFilters
            rangePresets={store.rangePresets}
            range={store.range}
            onRangeChange={store.setRange}
            code={store.statusFilter}
            onStatusChange={store.setStatusFilter}
            className={styles.baseFilters}
        />
    )
)

const SortContainer = observer(
    ({
        store,
        id,
        onChange,
        asMenu
    }: {
        store: AFTPaymentsStore
        id?: string
        onChange?: (field: keyof AFTPayment, direction: 'asc' | 'desc') => void
        asMenu?: boolean
    }) => (
        <SortConfigSelect
            options={constants.sortOptions.get()}
            id={id}
            asMenu={asMenu}
            selectedOption={{
                field: store.sortField,
                direction: store.sortDirection
            }}
            onChange={onChange ? onChange : store.setSort}
        />
    )
)

const DataFilterFormContainer = observer(
    ({
        store,
        dictionary,
        ...rest
    }: {
        store: AFTPaymentsStore
        dictionary: DictionaryStore
        visible: boolean
        onClose: () => any
    }) => {
        const shopDict = dictionary.shops
        const InstallmentsDict = dictionary.installments
        const shops = shopDict.map(s => s.name)
        return (
            <ModalContainer
                visible={rest.visible}
                title={BrowserStore.isMobile ? paymentsRes().filter : undefined}
                onClose={rest.onClose}
                modalClass={fullFormStyles.fullModal}
                headerControl={
                    <MobileHeader
                        onCancel={store.toggleFilteringMobile}
                        title={paymentsRes().filter}
                    />
                }
                closable={true}
            >
                <ModalContext.Consumer>
                    {modalContext => (
                        <FullFiltersForm
                            values={store.filters}
                            dateRangeValue={store.range}
                            dateRangePresets={store.rangePresets}
                            onRangeChange={store.setRange}
                            installments={store.installments}
                            installmentsList={dictionary.installments}
                            shopsList={shops}
                            modalContext={modalContext}
                            onFiltersChange={store.applyFilters}
                            setStatusFilter={store.setStatusFilter}
                            code={store.statusFilter}
                            onStatusChange={store.setStatusFilter}
                            {...rest}
                        />
                    )}
                </ModalContext.Consumer>
            </ModalContainer>
        )
    }
)

const PaymentsActionsContainer = observer(
    ({ store }: { store: AFTPaymentsStore }) => (
        <PaymentsActions
            selectedPaymentCount={store.selectedPaymentCount}
            selectedPayments={store.selectedPayments}
            selectedPaymentStatus={store.selectedPaymentStatus}
            selectedPaymentAmounts={store.selectedPaymentAmounts}
            partialChargeAmount={store.partialChargeAmount}
            partialRefundAmount={store.partialRefundAmount}
            chargePaymentsInFull={store.chargePaymentsInFull}
            chargePaymentPartial={store.chargePaymentPartial}
            cancelPayments={store.cancelPayments}
            refundPaymentsInFull={store.refundPaymentsInFull}
            refundPaymentPartial={store.refundPaymentPartial}
        />
    )
)

const DetailedPaymentActionsContainer = observer(
    ({ store }: { store: AFTPaymentsStore }) => {
        const { detailedPayment } = store
        const creditPaymentInfo = {}

        if (!detailedPayment) {
            return null
        }

        const targetPaymentsKeys = [detailedPayment[store.paymentKey]]

        // @Dos AFT
        // if (detailedPayment.senderTransferType === AFTType.credit) {
        //      creditPaymentInfo = {
        //         amount: detailedPayment.amount,
        //         orderId: detailedPayment.invoiceID
        //     }
        // }

        const partialValidations = [
            required(res().partialSum.isRequired),
            numeric(res().partialSum.constraints),
            {
                rule: (value: string) => {
                    const num = +value
                    return num >= 0 && num <= detailedPayment.amount
                },
                message: res().partialSum.constraints
            }
        ]

        const partialChargeAmount = store.partialChargeAmount // new FieldStore<string>(partialValidations)

        const partialRefundAmount = store.partialRefundAmount // new FieldStore<string>(partialValidations)
        const PaymentActionControl = BrowserStore.isMobile
            ? MobilePaymentsActions
            : PaymentsActions

        return (
            <PaymentActionControl
                selectedPaymentCount={1}
                selectedPayments={[detailedPayment]}
                selectedPaymentStatus={detailedPayment.code}
                selectedPaymentAmounts={{
                    [detailedPayment.currency]: detailedPayment.amount
                }}
                partialChargeAmount={partialChargeAmount}
                partialRefundAmount={partialRefundAmount}
                chargePaymentsInFull={() =>
                    store.chargePaymentsInFull(targetPaymentsKeys)
                }
                chargePaymentPartial={() =>
                    store.chargePaymentPartial(targetPaymentsKeys)
                }
                cancelPayments={() => store.cancelPayments(targetPaymentsKeys)}
                refundPaymentsInFull={() =>
                    store.refundPaymentsInFull(targetPaymentsKeys, creditPaymentInfo)
                }
                refundPaymentPartial={() =>
                    store.refundPaymentPartial(targetPaymentsKeys, creditPaymentInfo)
                }
                actionsDisabled={store.actionInProgress}
            />
        )
    }
)
