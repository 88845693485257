import res from './res'

res.add('kk', {
    operators: {
        less: '<',
        lessOrEqual: '≤',
        equals: '',
        greaterOrEqual: '≥',
        greater: '>',
        startsWith: 'басталады',
        endsWith: 'аяқталады',
        contains: 'құрамында'
    }
})
