import React from 'react'

const SvgBottom = props => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" {...props}>
        <path
            d="M1.094 255h1021.812l1.094 1.094-512 512-512-512L1.094 255z"
            fillRule="evenodd"
        />
    </svg>
)

export default SvgBottom
