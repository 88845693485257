import React from 'react'

import moment from 'moment'

import { observer } from 'mobx-react'

import DatePicker from 'antd/lib/date-picker'

import { TooltipPlacement } from 'antd/lib/tooltip'

import { action, observable, toJS } from 'mobx'

import BrowserStore from '~/services/browser/browser-state'

import styles from '../range-popover/styles.css'

import calendarStyles from './styles.css'

import MobileHeader from '~/components/modal/mobile-header'

import { Range as DateRange } from '~/components/range-popover/range-popover'

export interface RangePopoverValue {
    key?: string
    label?: string | (() => string)
    range: DateRange
}

export interface DateSelectProps {
    children?: React.ReactNode
    placement?: TooltipPlacement
    presets?: RangePopoverValue[]
    value?: RangePopoverValue
    id?: string
    onChange?: (value: DateRange) => void
}

interface DateSelectState {
    rangeValue?: DateRange
    initalRangeValue?: DateRange
}

@observer
export class DateSelect extends React.Component<DateSelectProps, {}> {
    public static getDerivedStateFromProps(
        nextProps: DateSelectProps,
        prevState: DateSelectState
    ) {
        if (!prevState.initalRangeValue) {
            const stateValue = [null, null]
            if (nextProps.value) {
                const propsRange = nextProps.value.range
                stateValue[0] = propsRange[0] ? propsRange[0] : null
                stateValue[1] = propsRange[1] ? propsRange[1] : null
            }
            return {
                rangeValue: stateValue,
                initalRangeValue: stateValue
            }
        }
        return null
    }

    constructor(props, context) {
        super(props, context)
    }
    public state: DateSelectState = {
        rangeValue: [null, null],
        initalRangeValue: null
    }

    @observable
    public calendarInputDisabled: boolean = false

    @observable
    public currentCalendarIndex: number = 0

    @observable
    public calendarRef: any[]

    public getCalendarRefClassName(calendarIndex: number): string {
        let className: string = this.calendarCssClassNames[calendarIndex]
        className =
            this.calendarInputDisabled ||
                this.currentCalendarIndex !== calendarIndex
                ? className + ' ' + calendarStyles.disabled
                : className
        return className
    }

    @action.bound
    public getCalendaContainer(calendarIndex: number, trigger: any) {
        return this.calendarRef[calendarIndex]
    }
    @action.bound
    public setCalendarRef(ref: any, calendarIndex: number) {
        if (!this.calendarRef || !this.calendarRef[calendarIndex]) {
            this.calendarRef = !this.calendarRef
                ? [undefined, undefined]
                : this.calendarRef
            this.calendarRef[calendarIndex] = ref
        }
    }
    @action.bound
    public calendarRequested(calendarIndex: number, status: any) {
        if (this.currentCalendarIndex !== calendarIndex) {
            this.currentCalendarIndex = calendarIndex
        }
    }
    public render() {
        return (
            <div
                style={{ position: 'relative' }}
                className={calendarStyles.selectorContainer}
            >
                {this.calendarRef &&
                    this.calendarRef[0] &&
                    this.calendarRef[1] && (
                        <React.Fragment>
                            <DatePicker
                                showToday={false}
                                prefixCls="ant-calendar"
                                className={`ant-calendar-range-with-ranges ${styles.container} ${styles.noShadow}`}
                                defaultValue={this.state.rangeValue[0]}
                                value={this.state.rangeValue[0]}
                                onChange={v => this.onSelect(v, 0)}
                                open={this.currentCalendarIndex === 0}
                                getCalendarContainer={trigger =>
                                    this.getCalendaContainer(0, trigger)
                                }
                                onOpenChange={status =>
                                    this.calendarRequested(0, status)
                                }
                            />
                            <DatePicker
                                showToday={false}
                                prefixCls="ant-calendar"
                                className={`ant-calendar-range-with-ranges ${styles.container} ${styles.noShadow}`}
                                defaultValue={this.state.rangeValue[1]}
                                value={this.state.rangeValue[1]}
                                onChange={v => this.onSelect(v, 1)}
                                open={this.currentCalendarIndex === 1}
                                getCalendarContainer={trigger =>
                                    this.getCalendaContainer(1, trigger)
                                }
                                onOpenChange={status =>
                                    this.calendarRequested(1, status)
                                }
                            />
                        </React.Fragment>
                    )}
                <div
                    ref={ref => this.setCalendarRef(ref, 0)}
                    className={this.getCalendarRefClassName(0)}
                ></div>
                <div
                    ref={ref => this.setCalendarRef(ref, 1)}
                    className={this.getCalendarRefClassName(1)}
                ></div>
            </div>
        )
    }

    private onSelect = (value: moment.Moment, calendarIndex: number) => {
        const rangeValue: DateRange = [
            this.state.rangeValue[0],
            this.state.rangeValue[1]
        ]
        rangeValue[calendarIndex] = value
        this.setState({ rangeValue }, () => this.props.onChange(rangeValue))
    }

    private calendarCssClassNames: string[] = [
        calendarStyles.dateSelectorContainer0,
        calendarStyles.dateSelectorContainer1
    ]
}

export default DateSelect
