import React from 'react'

const SvgNews = props => (
    <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
        <path d="M16 2v14H2V2h14zm0-2H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z" />
        <path d="M11 14H4v-2h7v2zm3-4H4V8h10v2zm0-4H4V4h10v2z" />
    </svg>
)

export default SvgNews
