import { PaymentStatus } from '~/api'

import res from './res'

res.add('kk', {
    loading: 'Статистиканы жүктеу ...',
    failed: 'Статистика қол жетімді емес :(',
    statuses: {
        [PaymentStatus.auth]: 'Күтеді',
        [PaymentStatus.charged]: 'Өткізілді',
        [PaymentStatus.cancelled]: 'Күші жойылды',
        [PaymentStatus.rejected]: 'Күші жойылды',
        [PaymentStatus.refunded]: 'Қайтару',
        [PaymentStatus.failed]: 'Сәтсіз',
        [PaymentStatus.new]: 'Жаңа',
        [PaymentStatus.any]: 'Кез келген',
        [PaymentStatus.CANCEL_OLD]: 'Мерзімі бойынша жойылды',
        [PaymentStatus.TIMEOUT_3D]: '3DS Қайтарылды',
        [PaymentStatus.REFUND_IN_PROCESSING_QR]: 'Орындалуда',
        [PaymentStatus.PENDING]: 'Күтүде',
        [PaymentStatus.SUCCESS]: 'Сәтті'
    }
})
