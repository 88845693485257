export const mobileCodes: Array<[string, string, RegExp]> = [
    ['AC', '247', /^247(?:4\d{4})$/],
    ['AD', '376', /^376(?:690\d{6}|[36]\d{5})$/],
    ['AE', '971', /^971(?:5[024-68]\d{7})$/],
    ['AF', '93', /^93(?:7(?:[014-9]\d|2[89]|3[01])\d{6})$/],
    ['AG', '1', /^1(?:268(?:464|7(?:1[3-9]|2\d|3[246]|64|[78][0-689]))\d{4})$/],
    ['AI', '1', /^1(?:264(?:235|476|5(?:3[6-9]|8[1-4])|7(?:29|72))\d{4})$/],
    ['AL', '355', /^355(?:6(?:[689][2-9]|7[2-6])\d{6})$/],
    ['AM', '374', /^374(?:(?:4[1349]|55|77|88|9[13-9])\d{6})$/],
    ['AO', '244', /^244(?:9[1-49]\d{7})$/],
    ['AR', '54', /^54(?:(?:675\d\d|9(?:11[2-7]\d\d|2(?:2(?:0(?:2[4-6]|[45]\d)|(?:1[2-6]|3[3-6])\d|2(?:14|[3467][4-6]|[59][45])|4(?:[156][4-6]|[23]4|4[45])|5(?:2[45]|[45][4-6]|7[3-6])|6(?:[145]4|2[2-6]|[6-8][4-6])|7[1-4]4|8(?:1[3-6]|[356]4|4[2-7])|9(?:1[4-6]|[267]4))|3(?:0(?:2[2-6]|4\d)|1(?:[47][4-6]|64)|2(?:[03][2-6]|4[3-6]|5[4-6]|6[45])|3[13-8]4|4(?:[24][45]|34|5[4-6]|6[3-6])|5(?:[25][4-6]|[346-8]4)|(?:64|7[45])\d|9(?:2[3-6]|[3-5]4|6[4-6]))|4(?:7(?:3[45]|[48][4-6]|54|7[2-6])|94\d)|6(?:(?:04|1[2-7]|[36][45])\d|2(?:2[2-6]|[46]4|5[4-6])|4(?:[45]\d|6[0-46-9]|[78]4)|5(?:[1568]4|7[2-7]))|80[45]\d|9(?:0(?:1[3-6]|2[45]|34)|(?:1[4-6]|9[3-6])\d|2(?:0[2-7]|[1457-9]4|[26][45]|3[3-6])|3(?:[1356]4|2[4-6]|4[45])|4(?:[08]4|2[2-6]|4\d|5[02-69]|6[45])|5(?:[23]4|4[2-8])|6(?:[23]4|4[3-6]|6[2-7])|7(?:2[45]|[4-6]\d)|8(?:24|3[2-6]|[45]\d)))|3(?:3(?:2(?:7[45]|9[3-6])|64\d|8[2578][4-6])|4(?:0[0-24-9][4-6]|(?:1[2-7]|2[4-6])\d|3(?:4\d|5[0-7]|6[1-69]|[78][4-6])|4(?:2[3-6]|[457][4-6]|6[2-6])|5(?:4[0-4679]|[56][024-6]|8[4-6])|6(?:[03-9][4-6]|2[2-6])|7(?:1[3-6]|2[4-6]|6[2-6])|8(?:[27][2-7]|3[4-6]|4\d|9[2-6])|9(?:[136-8][4-6]|2[2-7]))|5(?:1[2-8]\d|2(?:[124][4-6]|5[3-6])|3(?:[23][4-6]|[4-6]\d|7[3-6])|4(?:1[2-6]|[2689][4-6]|[347][3-6])|6(?:[23][4-6]|4[2-6])|7(?:1[3-6]|[2-6][4-6])|8(?:[23][4-6]|[46]\d|5[013-7]))|6(?:2[45]|44)\d|7(?:[069][45]\d|1(?:[15][46]|6[4-6]|8[3-6])|(?:2[15]|3[145]|4[13])[4-6]|5(?:[17][3-6]|[468][4-6]|5[2-7])|7(?:[2-5][4-6]|7[2-8])|8(?:1[46]|[26][4-6]))|8(?:(?:0[45]|1[2-6])\d|2(?:1[46]|[5-7][4-6])|3(?:[278][4-6]|4\d|5[124-6])|4(?:[16][46]|[3-5][4-6])|5(?:[34]\d|5[0-46-9]|6[0-246-9]|[78][4-6])|6(?:[1-378][4-6]|5[2-8]|9[46])|7(?:[24-6]\d|3[2-6]|7[4-6]|8[2-7])|8(?:[3-5]\d|6[0-68]|7[4-6]|8[3-6])|9(?:[12][46]|4[4-6])))))\d{5})$/],
    ['AS', '1', /^1(?:684(?:2(?:5[2468]|72)|7(?:3[13]|70))\d{4})$/],
    ['AT', '43', /^43(?:6(?:5[0-3579]|6[013-9]|[7-9]\d)\d{4,10})$/],
    ['AU', '61', /^61(?:4(?:[0-3]\d|4[047-9]|5[0-25-9]|6[6-9]|7[02-9]|8[0-2457-9]|9[017-9])\d{6})$/],
    ['AW', '297', /^297(?:(?:290|5[69]\d|6(?:[03]0|22|4[0-2]|[69]\d)|7(?:[34]\d|7[07])|9(?:6[45]|9[4-8]))\d{4})$/],
    ['AX', '358', /^358(?:(?:4[0-8]|50)\d{4,8})$/],
    ['AZ', '994', /^994(?:(?:36554|(?:4[04]|5[015]|60|7[07])\d{3})\d{4})$/],
    ['BA', '387', /^387(?:6(?:0(?:3\d|40)|[1-356]\d|44[0-6]|71[137])\d{5})$/],
    ['BB', '1', /^1(?:246(?:2(?:[356]\d|4[0-57-9]|8[0-79])|45\d|69[5-7]|8(?:[2-5]\d|83))\d{4})$/],
    ['BD', '880', /^880(?:(?:1[13-9]\d|(?:3[78]|44)[02-9]|6(?:44|6[02-9]))\d{7})$/],
    ['BE', '32', /^32(?:4(?:5[56]|6[0135-8]|[79]\d|8[3-9])\d{6})$/],
    ['BF', '226', /^226(?:(?:5[124-8]|[67]\d)\d{6})$/],
    ['BG', '359', /^359(?:(?:4(?:3[07-9]|8\d)|(?:8[7-9]\d|9(?:8\d|9[69]))\d)\d{5})$/],
    ['BH', '973', /^973(?:(?:3(?:[1-4679]\d|5[013-69]|8[0-47-9])\d|6(?:3(?:00|33|6[16])|6(?:3[03-9]|[69]\d|7[0-6])))\d{4})$/],
    ['BI', '257', /^257(?:(?:29|31|6[189]|7[125-9])\d{6})$/],
    ['BJ', '229', /^229(?:(?:6\d|9[03-9])\d{6})$/],
    ['BL', '590', /^590(?:69(?:0\d\d|1(?:2[29]|3[0-5]))\d{4})$/],
    ['BM', '1', /^1(?:441(?:[37]\d|5[0-39])\d{5})$/],
    ['BN', '673', /^673(?:(?:22[89]|[78]\d\d)\d{4})$/],
    ['BO', '591', /^591(?:[67]\d{7})$/],
    ['BQ', '599', /^599(?:(?:31(?:8[14-8]|9[14578])|416[14-9]|7(?:0[01]|7[07]|8\d|9[056])\d)\d{3})$/],
    ['BR', '55', /^55(?:(?:[189][1-9]|2[12478])(?:7|9\d)\d{7}|(?:3[1-578]|[46][1-9]|5[13-5]|7[13-579])(?:[6-9]|9\d)\d{7})$/],
    ['BS', '1', /^1(?:242(?:3(?:5[79]|7[56]|95)|4(?:[23][1-9]|4[1-35-9]|5[1-8]|6[2-8]|7\d|81)|5(?:2[45]|3[35]|44|5[1-46-9]|65|77)|6[34]6|7(?:27|38)|8(?:0[1-9]|1[02-9]|2\d|[89]9))\d{4})$/],
    ['BT', '975', /^975(?:(?:1[67]|77)\d{6})$/],
    ['BW', '267', /^267(?:7(?:[1-6]\d{3}|7(?:[014-8]\d\d|200))\d{3})$/],
    ['BY', '375', /^375(?:(?:2(?:5[5-79]|9[1-9])|(?:33|44)\d)\d{6})$/],
    ['BZ', '501', /^501(?:6[0-35-7]\d{5})$/],
    ['CA', '1', /^1(?:(?:2(?:04|[23]6|[48]9|50)|3(?:06|43|65)|4(?:03|1[68]|3[178]|50)|5(?:06|1[49]|48|79|8[17])|6(?:04|13|39|47)|7(?:0[59]|78|8[02])|8(?:[06]7|19|25|73)|90[25])[2-9]\d{6})$/],
    ['CC', '61', /^61(?:4(?:[0-3]\d|4[047-9]|5[0-25-9]|6[6-9]|7[02-9]|8[0-2457-9]|9[017-9])\d{6})$/],
    ['CD', '243', /^243(?:(?:8(?:[0-2459]\d\d|8)|9[017-9]\d\d)\d{5})$/],
    ['CF', '236', /^236(?:7[0257]\d{6})$/],
    ['CG', '242', /^242(?:0[14-6]\d{7})$/],
    ['CH', '41', /^41(?:7[35-9]\d{7})$/],
    ['CI', '225', /^225(?:(?:[07][1-9]|[45]\d|6[014-9]|8[4-9])\d{6})$/],
    ['CK', '682', /^682(?:[5-8]\d{4})$/],
    ['CL', '56', /^56(?:(?:2(?:1962|(?:2\d\d|32[0-46-8])\d)|(?:(?:3[2-5]|[47][1-35]|5[1-3578]|6[13-57]|9[2-9])\d|8(?:0[1-9]|[1-9]\d))\d\d)\d{4})$/],
    ['CM', '237', /^237(?:6[5-9]\d{7})$/],
    ['CN', '86', /^86(?:1(?:[38]\d{3}|4[57]\d{2}|5[0-35-9]\d{2}|6[25-7]\d{2}|7(?:[0-35-8]\d{2}|40[0-5])|9[189]\d{2})\d{6})$/],
    ['CO', '57', /^57(?:3(?:0[0-5]|1\d|2[0-3]|5[01])\d{7})$/],
    ['CR', '506', /^506(?:(?:(?:5(?:0[01]|7[0-3])|(?:7[0-3]|8[3-9])\d)\d\d|6(?:[0-4]\d{3}|500[01]))\d{3})$/],
    ['CU', '53', /^53(?:5\d{7})$/],
    ['CV', '238', /^238(?:(?:[34][36]|5[1-389]|9\d)\d{5})$/],
    ['CW', '599', /^599(?:9(?:5(?:[12467]\d|3[01])|6[5-9]\d)\d{4})$/],
    ['CX', '61', /^61(?:4(?:[0-3]\d|4[047-9]|5[0-25-9]|6[6-9]|7[02-9]|8[0-2457-9]|9[017-9])\d{6})$/],
    ['CY', '357', /^357(?:9[4-79]\d{6})$/],
    ['CZ', '420', /^420(?:(?:60[1-8]|7(?:0[2-5]|[2379]\d))\d{6})$/],
    ['DE', '49', /^49(?:1(?:5[0-25-9]\d{8}|(?:6[023]|7\d)\d{7,8}))$/],
    ['DJ', '253', /^253(?:77\d{6})$/],
    ['DK', '45', /^45(?:(?:[2-7]\d|8[126-9]|9[1-36-9])\d{6})$/],
    ['DM', '1', /^1(?:767(?:2(?:[2-4689]5|7[5-7])|31[5-7]|61[1-7])\d{4})$/],
    ['DO', '1', /^1(?:8[024]9[2-9]\d{6})$/],
    ['DZ', '213', /^213(?:(?:(?:5[4-6]|7[7-9])\d|6(?:[569]\d|7[0-6]))\d{6})$/],
    ['EC', '593', /^593(?:9(?:(?:39|[57][89]|[89]\d)\d|6(?:[0-27-9]\d|30))\d{5})$/],
    ['EE', '372', /^372(?:(?:5\d|8[1-4])\d{6}|5(?:(?:[02]\d|5[0-478])\d|1(?:[0-8]\d|95)|6(?:4[0-4]|5[1-589]))\d{3})$/],
    ['EG', '20', /^20(?:1[0-25]\d{8})$/],
    ['EH', '212', /^212(?:(?:6(?:[0-79]\d|8[0-247-9])|7(?:0[067]|6[1267]|7[017]))\d{6})$/],
    ['ER', '291', /^291(?:(?:17[1-3]|7\d\d)\d{4})$/],
    ['ES', '34', /^34(?:(?:(?:6\d|7[1-48])\d{5}|9(?:6906(?:09|10)|7390\d\d))\d\d)$/],
    ['ET', '251', /^251(?:9\d{8})$/],
    ['FI', '358', /^358(?:(?:4[0-8]|50)\d{4,8})$/],
    ['FJ', '679', /^679(?:(?:[279]\d|45|5[01568]|8[034679])\d{5})$/],
    ['FK', '500', /^500(?:[56]\d{4})$/],
    ['FM', '691', /^691(?:(?:3[2357]0[1-9]|9[2-7]\d\d)\d{3})$/],
    ['FO', '298', /^298(?:(?:[27][1-9]|5\d)\d{4})$/],
    ['FR', '33', /^33(?:(?:6\d\d|7(?:00|[3-9]\d))\d{6})$/],
    ['GA', '241', /^241(?:(?:0[2-7]|[2-7])\d{6})$/],
    ['GB', '44', /^44(?:7(?:(?:[1-3]\d\d|5(?:0[0-8]|[13-9]\d|2[0-35-9])|8(?:[014-9]\d|[23][0-8]))\d|4(?:[0-46-9]\d\d|5(?:[0-689]\d|7[0-57-9]))|7(?:0(?:0[01]|[1-9]\d)|(?:[1-7]\d|8[02-9]|9[0-689])\d)|9(?:(?:[024-9]\d|3[0-689])\d|1(?:[02-9]\d|1[028])))\d{5})$/],
    ['GD', '1', /^1(?:473(?:4(?:0[2-79]|1[04-9]|2[0-5]|58)|5(?:2[01]|3[3-8])|901)\d{4})$/],
    ['GE', '995', /^995(?:(?:5(?:[14]4|5[0157-9]|68|7[0147-9]|9[1-35-9])|790)\d{6})$/],
    ['GF', '594', /^594(?:694(?:[0-249]\d|3[0-48])\d{4})$/],
    ['GG', '44', /^44(?:7(?:(?:781|839)\d|911[17])\d{5})$/],
    ['GH', '233', /^233(?:(?:2[0346-8]\d|5(?:[0457]\d|6[01]))\d{6})$/],
    ['GI', '350', /^350(?:(?:5[46-8]\d|629)\d{5})$/],
    ['GL', '299', /^299(?:(?:[25][1-9]|4[2-9])\d{4})$/],
    ['GM', '220', /^220(?:(?:[23679]\d|5[01])\d{5})$/],
    ['GN', '224', /^224(?:6[02356]\d{7})$/],
    ['GP', '590', /^590(?:69(?:0\d\d|1(?:2[29]|3[0-5]))\d{4})$/],
    ['GQ', '240', /^240(?:(?:222|55[015])\d{6})$/],
    ['GR', '30', /^30(?:6(?:8[57-9]|9\d)\d{7})$/],
    ['GT', '502', /^502(?:[3-5]\d{7})$/],
    ['GU', '1', /^1(?:671(?:3(?:00|3[39]|4[349]|55|6[26])|4(?:00|56|7[1-9]|8[0236-9])|5(?:55|6[2-5]|88)|6(?:3[2-578]|4[24-9]|5[34]|78|8[235-9])|7(?:[0479]7|2[0167]|3[45]|8[7-9])|8(?:[2-57-9]8|6[48])|9(?:2[29]|6[79]|7[1279]|8[7-9]|9[78]))\d{4})$/],
    ['GW', '245', /^245(?:9(?:5\d|6[569]|77)\d{6})$/],
    ['GY', '592', /^592(?:6\d{6})$/],
    ['HK', '852', /^852(?:(?:46(?:0[0-6]|1[0-2]|4[0-57-9])|5(?:(?:[1-59][0-46-9]|6[0-4689])\d|7(?:[0-2469]\d|30))|6(?:(?:0[1-9]|[13-59]\d|[68][0-57-9]|7[0-79])\d|2(?:[0-57-9]\d|6[01]))|707[1-5]|848[01]|9(?:(?:0[1-9]|1[02-9]|[358][0-8]|[467]\d)\d|2(?:[0-8]\d|9[03-9])))\d{4})$/],
    ['HN', '504', /^504(?:[37-9]\d{7})$/],
    ['HR', '385', /^385(?:9(?:(?:01|[12589]\d)\d|7(?:[0679]\d|51))\d{5}|98\d{6})$/],
    ['HT', '509', /^509(?:[34]\d{7})$/],
    ['HU', '36', /^36(?:(?:[257]0|3[01])\d{7})$/],
    ['ID', '62', /^62(?:8[1-35-9]\d{7,10})$/],
    ['IE', '353', /^353(?:8(?:22|[35-9]\d)\d{6})$/],
    ['IL', '972', /^972(?:5(?:(?:[0-489][2-9]|6\d)\d|5(?:01|2[2-5]|3[23]|4[45]|5[05689]|6[6-8]|7[0-267]|8[7-9]|9[1-9]))\d{5})$/],
    ['IM', '44', /^44(?:7(?:4576|[59]24\d|624[0-4689])\d{5})$/],
    ['IN', '91', /^91(?:(?:6(?:(?:0(?:0[0-3569]|26|33)|2(?:[06]\d|3[02589]|8[0-479]|9[0-79])|9(?:0[019]|13))\d|1279|3(?:(?:0[0-79]|6[0-4679]|7[0-24-9]|[89]\d)\d|5(?:0[0-6]|[1-9]\d)))|7(?:(?:0\d\d|19[0-5])\d|2(?:(?:[0235-79]\d|[14][017-9])\d|8(?:[0-59]\d|[6-8][089]))|3(?:(?:[05-8]\d|3[017-9])\d|1(?:[089]\d|11|7[02-8])|2(?:[0-49][089]|[5-8]\d)|4(?:[07-9]\d|11)|9(?:[016-9]\d|[2-5][089]))|4(?:0\d\d|1(?:[015-9]\d|[2-4][089])|[29](?:[0-7][089]|[89]\d)|3(?:[0-8][089]|9\d)|[47](?:[089]\d|11|7[02-8])|[56]\d[089]|8(?:[0-24-7][089]|[389]\d))|5(?:(?:[0346-8]\d|5[017-9])\d|1(?:[07-9]\d|11)|2(?:[04-9]\d|[1-3][089])|9(?:[0-6][089]|[7-9]\d))|6(?:0(?:[0-47]\d|[5689][089])|(?:1[0-257-9]|[6-9]\d)\d|2(?:[0-4]\d|[5-9][089])|3(?:[02-8][089]|[19]\d)|4\d[089]|5(?:[0-367][089]|[4589]\d))|7(?:0(?:0[02-9]|[13-7][089]|[289]\d)|[1-9]\d\d)|8(?:[0-79]\d\d|8(?:[089]\d|11|7[02-9]))|9(?:[089]\d\d|313|7(?:[02-8]\d|9[07-9])))|8(?:0(?:(?:[01589]\d|6[67])\d|7(?:[02-8]\d|9[04-9]))|1(?:[0-57-9]\d\d|6(?:[089]\d|7[02-8]))|2(?:[014](?:[089]\d|7[02-8])|[235-9]\d\d)|3(?:[03-57-9]\d\d|[126](?:[089]\d|7[02-8]))|[45]\d{3}|6(?:[02457-9]\d\d|[136](?:[089]\d|7[02-8]))|7(?:(?:0[07-9]|[1-69]\d)\d|[78](?:[089]\d|7[02-8]))|8(?:[0-25-9]\d\d|3(?:[089]\d|7[02-8])|4(?:[0489]\d|7[02-8]))|9(?:[02-9]\d\d|1(?:[0289]\d|7[02-8])))|9\d{4})\d{5})$/],
    ['IO', '246', /^246(?:38\d{5})$/],
    ['IQ', '964', /^964(?:7[3-9]\d{8})$/],
    ['IR', '98', /^98(?:9(?:(?:0(?:[1-35]\d|44)|(?:[13]\d|2[0-2])\d)\d|9(?:(?:[01]\d|44)\d|510|8(?:1[01]|88)|9(?:0[013]|1[0134]|21|77|9[6-9])))\d{5})$/],
    ['IS', '354', /^354(?:(?:38[589]\d\d|6(?:1[1-8]|2[0-6]|3[027-9]|4[014679]|5[0159]|6[0-69]|70|8[06-8]|9\d)|7(?:5[057]|[6-8]\d|9[0-3])|8(?:2[0-59]|[3469]\d|5[1-9]|8[28]))\d{4})$/],
    ['IT', '39', /^39(?:33\d{9}|3[1-9]\d{8}|3[2-9]\d{7})$/],
    ['JE', '44', /^44(?:7(?:(?:(?:50|82)9|937)\d|7(?:00[378]|97[7-9]))\d{5})$/],
    ['JM', '1', /^1(?:876(?:(?:2[14-9]|[348]\d)\d|5(?:0[3-9]|[2-57-9]\d|6[0-24-9])|7(?:0[07]|7\d|8[1-47-9]|9[0-36-9])|9(?:[01]9|9[0579]))\d{4})$/],
    ['JO', '962', /^962(?:7(?:55[0-49]|(?:7[025-9]|[89][0-25-9])\d)\d{5})$/],
    ['JP', '81', /^81(?:[7-9]0[1-9]\d{7})$/],
    ['KE', '254', /^254(?:7\d{8})$/],
    ['KG', '996', /^996(?:(?:2(?:0[0-35]|2\d)|5[0-24-7]\d|7(?:[07]\d|55)|99[69])\d{6})$/],
    ['KH', '855', /^855(?:(?:(?:(?:1[28]|9[67])\d|8(?:[013-79]|8\d))\d|(?:2[3-6]|4[2-4]|[56][2-5])48|3(?:[18]\d\d|[2-6]48)|7(?:(?:[07-9]|[16]\d)\d|[2-5]48))\d{5}|(?:1\d|6[016-9]|9[0-57-9])\d{6})$/],
    ['KI', '686', /^686(?:(?:6(?:200[01]|30[01]\d)|7(?:200[01]|3(?:0[0-5]\d|140)))\d{3})$/],
    ['KM', '269', /^269(?:[34]\d{6})$/],
    ['KN', '1', /^1(?:869(?:5(?:5[6-8]|6[5-7])|66\d|76[02-7])\d{4})$/],
    ['KP', '850', /^850(?:19[1-3]\d{7})$/],
    ['KR', '82', /^82(?:1[0-26-9]\d{7,8})$/],
    ['KW', '965', /^965(?:(?:5(?:(?:[05]\d|1[0-7]|6[56])\d|2(?:22|5[25]))|6(?:(?:0[034679]|5[015-9]|6\d)\d|222|7(?:0[013-9]|[67]\d)|9(?:[069]\d|3[039]))|9(?:(?:0[09]|22|4[01479]|55|6[0679]|8[057-9]|9\d)\d|11[01]|7(?:02|[1-9]\d)))\d{4})$/],
    ['KY', '1', /^1(?:345(?:32[1-9]|5(?:1[67]|2[5-79]|4[6-9]|50|76)|649|9(?:1[67]|2[2-9]|3[689]))\d{4})$/],
    ['KZ', '7', /^7(?:7(?:0[0-2578]|47|6[02-4]|7[15-8]|85)\d{7})$/],
    ['LA', '856', /^856(?:20(?:[29]\d|5[24-689]|7[6-8])\d{6})$/],
    ['LB', '961', /^961(?:(?:(?:3|81)\d|7(?:[01]\d|6[013-9]|8[89]|9[1-3]))\d{5})$/],
    ['LC', '1', /^1(?:758(?:28[4-7]|384|4(?:6[01]|8[4-9])|5(?:1[89]|20|84)|7(?:1[2-9]|2\d|3[01]))\d{4})$/],
    ['LI', '423', /^423(?:(?:6(?:5(?:09|1\d|20)|6(?:0[0-6]|10|2[06-9]|39))\d|7(?:[37-9]\d|42|56))\d{4})$/],
    ['LK', '94', /^94(?:7[0-25-8]\d{7})$/],
    ['LR', '231', /^231(?:(?:(?:330|555|(?:77|88)\d)\d|4[67])\d{5}|5\d{6})$/],
    ['LS', '266', /^266(?:[56]\d{7})$/],
    ['LT', '370', /^370(?:6\d{7})$/],
    ['LU', '352', /^352(?:6(?:[269][18]|5[158]|7[189]|81)\d{6})$/],
    ['LV', '371', /^371(?:2\d{7})$/],
    ['LY', '218', /^218(?:9[1-6]\d{7})$/],
    ['MA', '212', /^212(?:(?:6(?:[0-79]\d|8[0-247-9])|7(?:0[067]|6[1267]|7[017]))\d{6})$/],
    ['MC', '377', /^377(?:(?:(?:3|6\d)\d\d|4(?:4\d|5[1-9]))\d{5})$/],
    ['MD', '373', /^373(?:(?:562|6\d\d|7(?:[189]\d|6[07]|7[457-9]))\d{5})$/],
    ['ME', '382', /^382(?:6(?:00|3[024]|6[0-25]|[7-9]\d)\d{5})$/],
    ['MF', '590', /^590(?:69(?:0\d\d|1(?:2[29]|3[0-5]))\d{4})$/],
    ['MG', '261', /^261(?:3[2-49]\d{7})$/],
    ['MH', '692', /^692(?:(?:(?:23|54)5|329|45[56])\d{4})$/],
    ['MK', '389', /^389(?:7(?:(?:[0-25-8]\d|3[2-4]|9[23])\d|421)\d{4})$/],
    ['ML', '223', /^223(?:(?:2(?:079|17\d)|(?:50|[679]\d|8[239])\d\d)\d{4})$/],
    ['MM', '95', /^95(?:(?:17[01]|9(?:2(?:[0-4]|[56]\d\d)|(?:3(?:[0-36]|4\d)|(?:6[89]|89)\d|7(?:3|5[0-2]|[6-9]\d))\d|4(?:(?:[0245]\d|[1379])\d|88)|5[0-6]|9(?:[089]|[5-7]\d\d))\d)\d{4}|9[69]1\d{6}|9[68]\d{6})$/],
    ['MN', '976', /^976(?:(?:8(?:[05689]\d|3[01])|9[013-9]\d)\d{5})$/],
    ['MO', '853', /^853(?:6(?:[2356]\d\d|8(?:[02][5-9]|[1478]\d|[356][0-4]))\d{4})$/],
    ['MP', '1', /^1(?:670(?:2(?:3[3-7]|56|8[5-8])|32[1-38]|4(?:33|8[348])|5(?:32|55|88)|6(?:64|70|82)|78[3589]|8[3-9]8|989)\d{4})$/],
    ['MQ', '596', /^596(?:69(?:6(?:[0-47-9]\d|5[0-6]|6[0-4])|727)\d{4})$/],
    ['MR', '222', /^222(?:[2-4][0-46-9]\d{6})$/],
    ['MS', '1', /^1(?:66449[2-6]\d{4})$/],
    ['MT', '356', /^356(?:(?:7(?:210|[79]\d\d)|9(?:2(?:1[01]|31)|69[67]|8(?:1[1-3]|89|97)|9\d\d))\d{4})$/],
    ['MU', '230', /^230(?:5(?:(?:2[589]|7\d|9[0-8])\d|4(?:2[1-389]|[489]\d|7[1-9])|8(?:[0-689]\d|7[15-8]))\d{4})$/],
    ['MV', '960', /^960(?:(?:46[46]|(?:7[2-9]|9[14-9])\d)\d{4})$/],
    ['MW', '265', /^265(?:(?:111|(?:77|88|99)\d)\d{6})$/],
    ['MX', '52', /^52(?:1(?:2(?:2[1-9]|3[1-35-8]|4[13-9]|7[1-689]|8[1-578]|9[467])|3(?:1[1-79]|[2458][1-9]|3\d|7[1-8]|9[1-5])|4(?:1[1-57-9]|[24-7][1-9]|3[1-8]|8[1-35-9]|9[2-689])|5(?:[56]\d|88|9[1-79])|6(?:1[2-68]|[2-4][1-9]|5[1-3689]|6[1-57-9]|7[1-7]|8[67]|9[4-8])|7(?:[1-467][1-9]|5[13-9]|8[1-69]|9[17])|8(?:1\d|2[13-689]|3[1-6]|4[124-6]|6[1246-9]|7[1-378]|9[12479])|9(?:1[346-9]|2[1-4]|3[2-46-8]|5[1348]|[69][1-9]|7[12]|8[1-8]))\d{7})$/],
    ['MY', '60', /^60(?:1(?:(?:0(?:[23568]\d|4[0-6]|7[016-9]|9[0-8])|1(?:[1-5]\d\d|6(?:0[5-9]|[1-9]\d))|(?:[23679][2-9]|59\d)\d)\d|4(?:[235-9]\d\d|400)|8(?:(?:1[23]|[236]\d|5[7-9]|7[016-9]|9[0-8])\d|4(?:[06]\d|7[0-4])|8(?:[01]\d|[27][0-4])))\d{4})$/],
    ['MZ', '258', /^258(?:8[2-7]\d{7})$/],
    ['NA', '264', /^264(?:(?:60|8[1245])\d{7})$/],
    ['NC', '687', /^687(?:(?:5[0-4]|[79]\d|8[0-79])\d{4})$/],
    ['NE', '227', /^227(?:(?:8[04589]|9\d)\d{6})$/],
    ['NF', '672', /^672(?:3[58]\d{4})$/],
    ['NG', '234', /^234(?:(?:70(?:[1-689]\d|7[0-3])|8(?:0(?:1[01]|[2-9]\d)|1(?:[0-8]\d|9[01]))|90[235-9]\d)\d{6})$/],
    ['NI', '505', /^505(?:(?:5(?:5[0-7]|[78]\d)|6(?:20|3[035]|4[045]|5[05]|77|8[1-9]|9[059])|(?:7[5-8]|8\d)\d)\d{5})$/],
    ['NL', '31', /^31(?:6[1-58]\d{7})$/],
    ['NO', '47', /^47(?:(?:4[015-8]|5[89]|9\d)\d{6})$/],
    ['NP', '977', /^977(?:9(?:6[0-3]|7[245]|8[0-24-68])\d{7})$/],
    ['NR', '674', /^674(?:55[4-9]\d{4})$/],
    ['NU', '683', /^683(?:888[4-9]\d{3})$/],
    ['NZ', '64', /^64(?:2(?:[0-28]\d?|[79])\d{7}|21\d{6})$/],
    ['OM', '968', /^968(?:(?:7[129]\d|9(?:0[1-9]|[1-9]\d))\d{5})$/],
    ['PA', '507', /^507(?:(?:1[16]1|21[89]|6(?:[02-9]\d|1[0-6])\d|8(?:1[01]|7[23]))\d{4})$/],
    ['PE', '51', /^51(?:9\d{8})$/],
    ['PF', '689', /^689(?:8[79]\d{6})$/],
    ['PG', '675', /^675(?:(?:7(?:[0-689]\d|75)|81\d)\d{5})$/],
    ['PH', '63', /^63(?:(?:81[37]|9(?:0[5-9]|1[024-9]|2[0-35-9]|3[02-9]|4[235-9]|5[056]|6[5-7]|7[3-79]|89|9[4-9]))\d{7})$/],
    ['PK', '92', /^92(?:3(?:[014]\d|2[0-5]|3[0-7]|55|64)\d{7})$/],
    ['PL', '48', /^48(?:(?:45|5[0137]|6[069]|7[2389]|88)\d{7})$/],
    ['PM', '508', /^508(?:(?:4[02-4]|5[05])\d{4})$/],
    ['PR', '1', /^1(?:(?:787|939)[2-9]\d{6})$/],
    ['PS', '970', /^970(?:5[69]\d{7})$/],
    ['PT', '351', /^351(?:9(?:[1-36]\d\d|480)\d{5})$/],
    ['PW', '680', /^680(?:(?:6[2-4689]0|77\d|88[0-4])\d{4})$/],
    ['PY', '595', /^595(?:9(?:51|6[129]|[78][1-6]|9[1-5])\d{6})$/],
    ['QA', '974', /^974(?:[35-7]\d{7})$/],
    ['RE', '262', /^262(?:69(?:2\d\d|3(?:0[0-46]|1[013]|2[0-2]|3[0-39]|4\d|5[05]|6[0-26]|7[0-27]|8[0-38]|9[0-479]))\d{4})$/],
    ['RO', '40', /^40(?:7(?:(?:[02-7]\d|8[03-8]|99)\d|1(?:[01]\d|20))\d{5})$/],
    ['RS', '381', /^381(?:6(?:[0-689]|7\d)\d{6,7})$/],
    ['RU', '7', /^7(?:9\d{9})$/],
    ['RW', '250', /^250(?:7[238]\d{7})$/],
    ['SA', '966', /^966(?:5(?:[013-689]\d|7[0-36-8])\d{6})$/],
    ['SB', '677', /^677(?:(?:48|(?:(?:7[1-9]|8[4-9])\d|9(?:1[2-9]|2[013-9]|3[0-2]|[46]\d|5[0-46-9]|7[0-689]|8[0-79]|9[0-8]))\d)\d{3})$/],
    ['SC', '248', /^248(?:2[5-8]\d{5})$/],
    ['SD', '249', /^249(?:(?:1[0-2]|9[0-3569])\d{7})$/],
    ['SE', '46', /^46(?:7[02369]\d{7})$/],
    ['SG', '65', /^65(?:(?:8[1-8]|9[0-8])\d{6})$/],
    ['SH', '290', /^290(?:[56]\d{4})$/],
    ['SI', '386', /^386(?:(?:(?:[37][01]|4[0139]|51)\d\d|6(?:[48]\d\d|5(?:1\d|55|[67]0)|9(?:10|[69]\d)))\d{4})$/],
    ['SJ', '47', /^47(?:(?:4[015-8]|5[89]|9\d)\d{6})$/],
    ['SK', '421', /^421(?:9(?:0(?:[1-8]\d|9[1-9])|(?:1[0-24-9]|[45]\d)\d)\d{5})$/],
    ['SL', '232', /^232(?:(?:2[15]|3[013-5]|4[04]|5[05]|66|7[5-9]|8[08]|99)\d{6})$/],
    ['SM', '378', /^378(?:6[16]\d{6})$/],
    ['SN', '221', /^221(?:7(?:[06-8]\d|21|90)\d{6})$/],
    ['SO', '252', /^252(?:(?:(?:15|(?:3[59]|4[89]|6[1-9]|79|8[08])\d|9(?:0[67]|[2-9]))\d|2(?:4\d|8))\d{5}|(?:6\d|7[1-9])\d{6})$/],
    ['SR', '597', /^597(?:(?:7[124-7]|8[125-9])\d{5})$/],
    ['SS', '211', /^211(?:(?:12|9[1257])\d{7})$/],
    ['ST', '239', /^239(?:9(?:0(?:0[5-9]|[1-9]\d)|[89]\d\d)\d{3})$/],
    ['SV', '503', /^503(?:[67]\d{7})$/],
    ['SX', '1', /^1(?:7215(?:1[02]|2\d|5[034679]|8[014-8])\d{4})$/],
    ['SY', '963', /^963(?:9(?:22|[3-589]\d|6[024-9])\d{6})$/],
    ['SZ', '268', /^268(?:7[6-9]\d{6})$/],
    ['TC', '1', /^1(?:649(?:2(?:3[129]|4[1-7])|3(?:3[1-389]|4[1-8])|4[34][1-3])\d{4})$/],
    ['TD', '235', /^235(?:(?:6[023568]|77|9\d)\d{6})$/],
    ['TG', '228', /^228(?:(?:7[09]|9[0-36-9])\d{6})$/],
    ['TH', '66', /^66(?:(?:14|6[1-6]|[89]\d)\d{7})$/],
    ['TJ', '992', /^992(?:(?:41[18]|(?:5[05]|77|88|9[0-35-9])\d)\d{6})$/],
    ['TK', '690', /^690(?:7[2-4]\d{2,5})$/],
    ['TL', '670', /^670(?:7[3-8]\d{6})$/],
    ['TM', '993', /^993(?:6[1-9]\d{6})$/],
    ['TN', '216', /^216(?:(?:(?:[259]\d|4[0-6])\d\d|3(?:001|1(?:[1-35]\d|40)|240|(?:6[0-4]|91)\d))\d{4})$/],
    ['TO', '676', /^676(?:(?:7[578]|8[46-9])\d{5})$/],
    ['TR', '90', /^90(?:5(?:(?:0[15-7]|1[06]|24|[34]\d|5[1-59]|9[46])\d\d|6161)\d{5})$/],
    ['TT', '1', /^1(?:868(?:2(?:6[6-9]|[7-9]\d)|[37](?:0[1-9]|1[02-9]|[2-9]\d)|4[6-9]\d|6(?:20|78|8\d))\d{4})$/],
    ['TV', '688', /^688(?:(?:7[01]\d|90)\d{4})$/],
    ['TW', '886', /^886(?:9[0-8]\d{7})$/],
    ['TZ', '255', /^255(?:(?:6[2-9]|7[13-9])\d{7})$/],
    ['UA', '380', /^380(?:(?:39|50|6[36-8]|7[1-3]|9[1-9])\d{7})$/],
    ['UG', '256', /^256(?:7(?:(?:[0157-9]\d|30|4[0-4])\d|2(?:[03]\d|60))\d{5})$/],
    ['US', '1', /^1(?:(?:2(?:0[1-35-9]|1[02-9]|2[03-589]|3[149]|4[08]|5[1-46]|6[0279]|7[0269]|8[13])|3(?:0[1-57-9]|1[02-9]|2[0135]|3[0-24679]|4[67]|5[12]|6[014]|8[056])|4(?:0[124-9]|1[02-579]|2[3-5]|3[0245]|4[0235]|58|6[39]|7[0589]|8[04])|5(?:0[1-57-9]|1[0235-8]|20|3[0149]|4[01]|5[19]|6[1-47]|7[013-5]|8[056])|6(?:0[1-35-9]|1[024-9]|2[03689]|[34][016]|5[017]|6[0-279]|78|8[0-2])|7(?:0[1-46-8]|1[2-9]|2[04-7]|3[1247]|4[037]|5[47]|6[02359]|7[02-59]|8[156])|8(?:0[1-68]|1[02-8]|2[08]|3[0-28]|4[3578]|5[046-9]|6[02-5]|7[028])|9(?:0[1346-9]|1[02-9]|2[0589]|3[0146-8]|4[0179]|5[12469]|7[0-389]|8[04-69]))[2-9]\d{6})$/],
    ['UY', '598', /^598(?:9[1-9]\d{6})$/],
    ['UZ', '998', /^998(?:(?:6(?:1(?:2(?:2[01]|98)|35[0-4]|50\d|61[23]|7(?:[01][017]|4\d|55|9[5-9]))|2(?:(?:11|7\d)\d|2(?:[12]1|9[01379])|5(?:[126]\d|3[0-4]))|5(?:19[01]|2(?:27|9[26])|(?:30|59|7\d)\d)|6(?:2(?:1[5-9]|2[0367]|38|41|52|60)|(?:3[79]|9[0-3])\d|4(?:56|83)|7(?:[07]\d|1[017]|3[07]|4[047]|5[057]|67|8[0178]|9[79]))|7(?:2(?:24|3[237]|4[5-9]|7[15-8])|5(?:7[12]|8[0589])|7(?:0\d|[39][07])|9(?:0\d|7[079]))|9(?:2(?:1[1267]|3[01]|5\d|7[0-4])|(?:5[67]|7\d)\d|6(?:2[0-26]|8\d)))|7(?:0\d{3}|1(?:13[01]|6(?:0[47]|1[67]|66)|71[3-69]|98\d)|2(?:2(?:2[79]|95)|3(?:2[5-9]|6[0-6])|57\d|7(?:0\d|1[17]|2[27]|3[37]|44|5[057]|66|88))|3(?:2(?:1[0-6]|21|3[469]|7[159])|(?:33|9[4-6])\d|5(?:0[0-4]|5[579]|9\d)|7(?:[0-3579]\d|4[0467]|6[67]|8[078]))|4(?:2(?:29|5[0257]|6[0-7]|7[1-57])|5(?:1[0-4]|8\d|9[5-9])|7(?:0\d|1[024589]|2[0-27]|3[0137]|[46][07]|5[01]|7[5-9]|9[079])|9(?:7[015-9]|[89]\d))|5(?:112|2(?:0\d|2[29]|[49]4)|3[1568]\d|52[6-9]|7(?:0[01578]|1[017]|[23]7|4[047]|[5-7]\d|8[78]|9[079]))|6(?:2(?:2[1245]|4[2-4])|39\d|41[179]|5(?:[349]\d|5[0-2])|7(?:0[017]|[13]\d|22|44|55|67|88))|9(?:22[128]|3(?:2[0-4]|7\d)|57[02569]|7(?:2[05-9]|3[37]|4\d|60|7[2579]|87|9[07])))|9[0-57-9]\d{3})\d{4})$/],
    ['VA', '39', /^39(?:33\d{9}|3[1-9]\d{8}|3[2-9]\d{7})$/],
    ['VC', '1', /^1(?:784(?:4(?:3[0-5]|5[45]|89|9[0-8])|5(?:2[6-9]|3[0-4]))\d{4})$/],
    ['VE', '58', /^58(?:4(?:1[24-8]|2[46])\d{7})$/],
    ['VG', '1', /^1(?:284(?:(?:3(?:0[0-3]|4[0-7]|68|9[34])|54[0-57])\d|4(?:(?:4[0-6]|68)\d|9(?:6[6-9]|9\d)))\d{3})$/],
    ['VI', '1', /^1(?:340(?:2(?:01|2[06-8]|44|77)|3(?:32|44)|4(?:22|7[34])|5(?:1[34]|55)|6(?:26|4[23]|77|9[023])|7(?:1[2-57-9]|27|7\d)|884|998)\d{4})$/],
    ['VN', '84', /^84(?:(?:(?:3\d|7[06-9])\d|5(?:2[238]|[689]\d)|8(?:[1-58]\d|6[5-9]|9[689])|9(?:[0-8]\d|9[013-9]))\d{6})$/],
    ['VU', '678', /^678(?:(?:5(?:[0-689]\d|7[2-5])|7[013-7]\d)\d{4})$/],
    ['WF', '681', /^681(?:(?:50|68|72|8[23])\d{4})$/],
    ['WS', '685', /^685(?:(?:7[25-7]|8(?:[3-7]|9\d{3}))\d{5})$/],
    ['XK', '383', /^383(?:4[3-79]\d{6})$/],
    ['YE', '967', /^967(?:7[0137]\d{7})$/],
    ['YT', '262', /^262(?:639(?:0[0-79]|1[019]|[267]\d|3[09]|[45]0|9[04-79])\d{4})$/],
    ['ZA', '27', /^27(?:(?:6\d|7[0-46-9]|8[1-5])\d{7}|8[1-4]\d{3,6})$/],
    ['ZM', '260', /^260(?:(?:76|9[5-8])\d{7})$/],
    ['ZW', '263', /^263(?:(?:7(?:1\d|3[2-9]|7[1-9]|8[2-5])|8644)\d{6})$/],
    ['001', '870', /^870(?:(?:[356]\d|7[6-8])\d{7})$/],
    ['001', '881', /^881(?:[67]\d{8})$/],
    ['001', '882', /^882(?:3(?:(?:(?:2|7\d{3})\d|37)\d\d|4(?:2|7\d{3}))\d{4})$/]
]
