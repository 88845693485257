import React, { FormEvent } from 'react'

import { observer } from 'mobx-react'

import actions from '~/actions'

import { Button, Form, FormItem, InputItem, NavLink } from '~/components'

import { CheckedIcon, EyeIcon } from '~/components/icon'

import { checked, checkedIcon, checkList } from '~/app.css'
import styles from '~/layouts/auth/styles.css'
import SuccessOperation from '~/layouts/auth/success-operation'

import res from '../res'

import SetPasswordStore from './set-password-store'

export default observer(({ store }: { store: SetPasswordStore }) => {
    function submit(e: FormEvent) {
        store.setPassword()
        e.preventDefault()
    }

    const { isPasswordShown, isPasswordRepeatShown } = store

    const getPasswordHelpPopover = () => {
        let visibility
        if (!store.hasPasswordErrors) visibility = { visible: false }

        const errors = store.passwordNotContainsErrors

        return {
            title: res().passwordHelp.title,
            content: (
                <ul className={checkList}>
                    {Object.entries(errors).map(([errorId, isError]) => (
                        <li
                            key={`not_contains_error_${errorId}`}
                            className={!isError ? checked : ''}
                        >
                            {!isError ? (
                                <CheckedIcon className={checkedIcon} />
                            ) : null}
                            {res().passwordHelp.notContainsErrors[errorId]}
                        </li>
                    ))}
                </ul>
            ),
            className: styles.inputTooltip,
            ...visibility
        }
    }
    const getPasswordErrors = () => {
        if (!store.hasPasswordErrors) return null

        const errors = store.passwordNotContainsErrors

        return {
            title: res().passwordHelp.title,
            content: (
                <ul className={checkList}>
                    {Object.entries(errors).map(([errorId, isError]) => (
                        <li
                            key={`not_contains_error_${errorId}`}
                            className={!isError ? checked : ''}
                        >
                            {res().passwordHelp.notContainsErrors[errorId]}
                        </li>
                    ))}
                </ul>
            ),
            className: styles.inputTooltip
        }
    }

    if (store.isPasswordSet) {
        return (
            <SuccessOperation
                title={res().passwordSetTitle}
                buttonText={res().continue}
                onButtonClick={() => actions.openHome()}
            />
        )
    }

    return (
        <div data-semantic-id="set_password_form">
            <div className={styles.header}>
                <span
                    data-semantic-id="set_password_title"
                    className={styles.title}
                >
                    {' '}
                    {res().setPasswordTitle}
                </span>
            </div>
            <div className={styles.main}>
                <Form onSubmit={submit}>
                    <InputItem
                        store={store.password}
                        type={isPasswordShown ? 'text' : 'password'}
                        trigger={'onBlur'}
                        popover={getPasswordHelpPopover()}
                        placeholder={res().newPassword}
                        inputProps={{
                            name: 'set_password_field',
                            suffix: (
                                <span
                                    data-semantic-id="show_password_button"
                                    className={`${styles.showPasswordButton} ${isPasswordShown ? styles.active : ''
                                        }`}
                                    onClick={() =>
                                        store.togglePasswordVisibility()
                                    }
                                >
                                    <EyeIcon theme="filled" />
                                </span>
                            ),
                            size: 'large'
                        }}
                    />
                    <InputItem
                        store={store.passwordRepeat}
                        type={isPasswordRepeatShown ? 'text' : 'password'}
                        placeholder={res().passwordRepeat}
                        trigger={'onBlur'}
                        inputProps={{
                            name: 'password_repeat_field',
                            suffix: (
                                <span
                                    data-semantic-id="show_password_button"
                                    className={`${styles.showPasswordButton} ${isPasswordRepeatShown
                                        ? styles.active
                                        : ''
                                        }`}
                                    onClick={() =>
                                        store.togglePasswordRepeatVisibility()
                                    }
                                >
                                    <EyeIcon theme="filled" />
                                </span>
                            ),
                            size: 'large'
                        }}
                    />
                    {store.error && (
                        <div className={styles.error}>{store.errorMessage}</div>
                    )}
                    <FormItem>
                        <Button
                            data-semantic-id="set_password_button"
                            disabled={
                                store.isSending || store.hasPasswordErrors
                            }
                            type="primary"
                            htmlType="submit"
                            size="large"
                        >
                            {res().setPasswordAction}
                        </Button>
                    </FormItem>
                    <FormItem className={styles.cancelButtonWrapper}>
                        <NavLink href="/login" className={styles.cancelButton}>
                            {res().cancel}
                        </NavLink>
                    </FormItem>
                </Form>
            </div>
        </div>
    )
})
