import React from 'react'
import res from './res'

res.add('kk', {
    title: 'Бөліп төлеу және несиелеуді белсендіру туралы өтініш',
    description: 'Жүйені одан әрі пайдалану үшін белсендіру туралы өтінішті толтырыңыз. Жүйенің функциялары өтінім бекітілгеннен кейін қол жетімді болады',
    sendOrder: 'Өтінімді жіберу',
    formItemTitles: {
        shop: 'Дүкен',
        email: 'Алушылардың E-mail'
    },
    shopPlaceholder: 'Выберите магазин',
    emailPlaceholder: 'E-mail енгізіңіз',
    emailRequired: 'Қатысушының электрондық пошта мекенжайын енгізіңіз',
    invalidEmail: 'дұрыс форматтағы электрондық поштаны сақтаңыз (example@email.co)',
    invalidShop: 'Дүкенді таңдаңыз',
    confirmationTitle: (orderNumber: string) => (
        <div>
            Өтінім №{orderNumber} қарауға жіберілді!
        </div>
    ),
    confirmationUpdateTitle: 'Өтінім мақұлданды',
    confirmationSubtitle: 'Жақын арада ол қаралатын болады. Өтінімнің күйін және модератордың пікірлерін қадағалаңыз',
    closeButton: 'Жабу',
    send: 'Жіберу'
})
