import React from 'react'
import { HintModal } from '../modal'
import InteractiveHintsStore from '../../../interactive-hints-store'
import { observer } from 'mobx-react'
import AppStore from '~/app-store'
import res from '../../../res'
import InteractiveHint from '~/utils/interactive-hint'

interface Props {
    store: InteractiveHintsStore
    appStore: typeof AppStore
}

const SkipAllModal = observer(({ store, appStore }: Props) => {
    const { isVisibleSkipAllModal } = store

    return (
        <HintModal
            visible={isVisibleSkipAllModal}
            onCancel={() => store.cancelSkipAllModal()}
            onOk={async () => {
                store.finish()
                await InteractiveHint.cancelAllFlags()
                setTimeout(() => appStore.hideInteractiveHints(), 250)
            }}
            title={res().modals.skipAll.title}
            description={res().modals.skipAll.description}
            okText={res().modals.okBtn}
            cancelText={res().modals.cancelBtn} />
    )
})

export default SkipAllModal