import 'reflect-metadata'

export function Type(type) { return Reflect.metadata('design:type', type) }

const PROPERTIES_KEY = 'refl'

export function property(target: object, propertyKey: string) {
    const columns: string[] = Reflect.getMetadata(PROPERTIES_KEY, target.constructor) || []
    columns.push(propertyKey)
    Reflect.defineMetadata(PROPERTIES_KEY, columns, target.constructor)
}

export function classDefinition(target: object, classType: string) {
    Reflect.defineMetadata('classDefinition', classType, target)
}

export function ReturnType(type) { return Reflect.metadata('design:returntype', type) }

export function logParameter(target: object, propertyName: string) {

    // property value
    let _val = target[propertyName]

    // property getter method
    const getter = () => {
        return _val
    }

    // property setter method
    const setter = newVal => {
        _val = newVal
    }

    // Delete property.
    if (delete target[propertyName]) {

        // Create new property with getter and setter
        Object.defineProperty(target, propertyName, {
            get: getter,
            set: setter,
            enumerable: true,
            configurable: true
        })
    }
}
