import { action, computed, observable, toJS } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { ContractStatementInfo } from '~/api/statements/contracts'

import appStyles from '~/app.css'
import { IconButton, List } from '~/components'
import { Grid } from '~/components/grid'
import {
    ArrowIcon,
    ContractIcon,
    ReloadIcon,
    SortByDescAIcon
} from '~/components/icon'
import { InfinitiveList } from '~/components/list/infinitive-list'
import Page from '~/layouts/main/page'

import { SortConfigSelect } from '~/components'
import MobileMenu, {
    MobileMenuConfig
} from '~/components/mobile-menu/mobile-menu'
import MobileHeader from '~/components/modal/mobile-header'
import ModalContainer from '~/components/modal/modal-container'
import BrowserStore from '~/services/browser/browser-state'
import constants from './constants'
import { renderSchedulerCardRecord } from './constants/data-fields'
import res from './res'
import StatementsStore from './statements-store'
import styles from './styles.css'
import InteractiveMobileHints from '~/components/interactive-mobile-hints'
import InteractiveHint from '~/utils/interactive-hint'

interface StatementsProps {
    store: StatementsStore
}

@observer
export default class Statements extends Component<StatementsProps, {}> {
    constructor(props) {
        super(props)

        setTimeout(() => {
            this.showHintOnMobile()
        }, 500)
    }

    @observable
    public menuVisible: boolean = false

    @action.bound
    public toggleMenu(selectAction?: () => void) {
        this.menuVisible = !this.menuVisible
        if (selectAction) {
            selectAction()
        }
    }

    public render() {
        const { store } = this.props

        return (
            <Page
                title={res().title}
                toolbarClassName={styles.mobileToolbar}
                toolbar={this.toolbar}
            >
                {!BrowserStore.isMobile && (
                    <div className={styles.baseControls}>
                        <div className={styles.sort}>
                            <label htmlFor="sort">
                                {res().sortBy}&nbsp;&nbsp;
                            </label>
                            <this.SortContainer onChange={store.setSort} />
                        </div>
                        {this.controls}
                    </div>
                )}
                {BrowserStore.isMobile && (
                    <div className={styles.clearElement}></div>
                )}
                <div className={styles.gridCnt}>{this.grid}</div>
                {BrowserStore.isMobile && !store.statementsLoading && store.showMobileHints &&
                    <InteractiveMobileHints store={store.mobileHintStore} />
                }
            </Page>
        )
    }

    private get toolbar() {
        const { store } = this.props

        return (
            <>
                <div>
                    <MobileMenu config={this.mobileMenuConfig}></MobileMenu>

                    <ModalContainer
                        visible={store.showSortingMobile}
                        onClose={store.toggleSortingMobile}
                        headerControl={
                            <MobileHeader
                                title={res().sortBy}
                                onCancel={store.toggleSortingMobile}
                            ></MobileHeader>
                        }
                        closable={true}
                    >
                        <this.SortContainer
                            asMenu={true}
                            onChange={(field, direction) => {
                                store.toggleSortingMobile()
                                store.setSort(field, direction)
                            }}
                        />
                    </ModalContainer>
                </div>
            </>
        )
    }

    @computed
    private get mobileMenuConfig(): MobileMenuConfig {
        const store = this.props.store

        const menuConfig: MobileMenuConfig = {
            buttons: [
                {
                    clickAction: () =>
                        this.toggleMenu(store.toggleSortingMobile),
                    icon: <SortByDescAIcon />
                }
            ]
        }
        menuConfig.visible = this.menuVisible
        menuConfig.disabled = false
        return menuConfig
    }

    private renderListItem = (item: ContractStatementInfo) => {
        const { titles } = res().dataFields
        const store = this.props.store

        return (
            <List.Item
                className={`${styles.listItem} mobile-hint-section-5-step-2`}
                key={item.shopId}
                onClick={() => store.openStatementDetail(item)}
            >
                <div className={styles.cardElement}>
                    <div className={appStyles.floatTwoCellRow}>
                        <div>
                            <div className={styles.textColorPrimary}>
                                {item.name}
                            </div>
                            <div className={styles.textColorMain}>
                                <span>
                                    <ContractIcon />
                                    &nbsp;
                                </span>
                                <span>{item.contract}</span>
                            </div>
                        </div>
                        <div className={styles.typeCell}>
                            <div className={styles.withBorder}>{item.type}</div>
                        </div>
                    </div>
                    <div className={styles.table}>
                        <div className={styles.cardSchedule}>
                            {renderSchedulerCardRecord(
                                item.daily,
                                titles.daily
                            )}
                            {renderSchedulerCardRecord(
                                item.weekly,
                                titles.weekly
                            )}
                            {renderSchedulerCardRecord(
                                item.monthly,
                                titles.monthly
                            )}
                        </div>
                        <div
                            className={`${styles.tableCell} ${styles.openStatementDetailsIcon}`}
                        >
                            <ArrowIcon
                                className={`${styles.collapsibleArrow} ${styles.leftArrow}`}
                            />
                        </div>
                    </div>
                </div>
            </List.Item>
        )
    }

    private showHintOnMobile() {
        if (BrowserStore.isMobile) {
            const hintSections = InteractiveHint.getPageFlags('statements')

            if (hintSections) {
                this.props.store.setMobileHints(hintSections)
            }
        }
    }

    private gridControls = observer(() => {
        const { store } = this.props

        return (
            <div className={styles.gridControls}>
                <IconButton
                    dataSemanticId="reload-data-icon"
                    id="reload-statement-icon"
                    icon={ReloadIcon}
                    onClick={store.reload}
                    tooltipTitle={res().tooltips.reload}
                />
                <span className={styles.pageInfo}>
                    {res().contracts}:{' '}
                    {store.pageInfo && !store.statementsLoading ? (
                        <span className={styles.contractsCount}>
                            {store.pageInfo.total}
                        </span>
                    ) : (
                        <span>...</span>
                    )}
                </span>
            </div>
        )
    })

    private gridContainer = observer(() => {
        const { store } = this.props
        const { statementsGridColumns: columns } = store

        return (
            <Grid<ContractStatementInfo>
                columns={columns}
                data={toJS(store.statements)}
                onRowClick={record => store.openStatementDetail(record)}
                rowKey={store.statementKey as keyof ContractStatementInfo}
                loading={store.statementsLoading}
                disableRowSelection={true}
            />
        )
    })

    private listContainer = observer(() => {
        const { store } = this.props

        return (
            <div
                className={styles.listInfiniteContainer}
                style={{ height: BrowserStore.windowHeight - 101 + 'px' }}
            >
                <InfinitiveList<ContractStatementInfo>
                    dataSource={store.statements}
                    loaded={!store.statementsLoading}
                    dataLoader={store.loadStatements}
                    itemRenderer={this.renderListItem}
                    pageSize={store.pageSize}
                ></InfinitiveList>
            </div>
        )
    })

    private SortContainer = observer(
        ({
            asMenu,
            onChange
        }: {
            asMenu?: boolean
            onChange?: (
                field: keyof ContractStatementInfo,
                direction: 'asc' | 'desc'
            ) => void
        }) => {
            const { store } = this.props
            return (
                <SortConfigSelect<ContractStatementInfo>
                    id="sort"
                    options={constants.sortOptions.get()}
                    asMenu={asMenu}
                    selectedOption={{
                        field: store.sortField,
                        direction: store.sortDirection
                    }}
                    onChange={onChange}
                />
            )
        }
    )

    private get grid() {
        if (BrowserStore.isMobile) {
            return <this.listContainer />
        }

        return <this.gridContainer />
    }

    private get controls() {
        return <this.gridControls />
    }
}
