import React from 'react'

const SvgLogoGreenDark = props => (
    <svg width="1em" height="1em" viewBox="0 0 96 32" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.565 9.843c-.041-2.77-1.826-4.398-4.138-4.398-2.637 0-4.504 1.75-4.99 4.398h9.128zm-4.017-8.308c5.923 0 9.169 4.358 9.169 9.856 0 .652-.081 1.344-.162 1.996H9.315c.324 3.625 3.083 5.498 6.207 5.498 2.11 0 4.26-.937 5.923-2.28l2.029 3.705c-2.11 1.874-5.356 2.974-8.317 2.974C8.3 23.284 4 18.314 4 12.409 4 6.015 8.34 1.535 14.548 1.535z"
            fill="#1C9D7A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.381 18.256c2.88 0 5.265-2.232 5.265-6.188 0-3.798-2.139-6.265-5.183-6.265-2.755 0-5.305 1.88-5.305 6.305 0 3.093 1.81 6.148 5.223 6.148zM28.1 2.005h4.771v1.488c0 .744-.083 1.41-.083 1.41h.083c1.357-2.154 3.908-3.368 6.745-3.368 5.677 0 9.296 4.268 9.296 10.456 0 6.343-4.072 10.455-9.583 10.455-2.386 0-4.566-1.018-6.047-2.819H33.2s.123.705.123 1.723v8.458h-5.223V2.005zM59.078 19.333c2.79 0 4.598-2.932 4.598-5.457v-.57h-.864c-2.515 0-6.76.366-6.76 3.298 0 1.385 1.022 2.729 3.026 2.729m3.694-9.57h.865v-.286c0-2.729-1.69-3.665-3.773-3.665-1.964 0-3.969.855-5.58 1.995L52.438 4.1c1.65-1.385 4.872-2.566 7.86-2.566 5.225 0 8.33 3.014 8.33 8.268v12.992H63.99v-1.751c0-.855.118-1.507.118-1.507h-.079c-1.218 2.199-3.615 3.747-6.327 3.747-3.615 0-6.601-2.444-6.601-6.232 0-6.23 7.23-7.29 11.67-7.29"
            fill="#061122"
        />
        <mask
            style={{
                maskType: 'alpha'
            }}
            maskUnits="userSpaceOnUse"
            x={70}
            y={1}
            width={22}
            height={30}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M70.088 1.936h21.34v28.96h-21.34V1.935z"
                fill="#fff"
            />
        </mask>
        <g mask="url(#logo-green-dark_svg__a)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M74.429 26.592c1.42 0 2.84-.845 3.61-2.655l.772-1.81-8.723-20.191h5.802l4.34 11.825c.406 1.086.772 2.775.772 2.775h.08s.366-1.609.69-2.695L85.83 1.936h5.599l-9.21 23.61c-1.42 3.74-4.422 5.35-7.466 5.35-2.474 0-4.3-1.328-4.3-1.328l1.704-3.821s1.136.845 2.272.845"
                fill="#061122"
            />
        </g>
    </svg>
)

export default SvgLogoGreenDark
